import { useEffect } from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { useObjectRoutes } from "../../../../../hooks/useObjectRoutes";
import { isEmptyArr, isEmptyObj } from "../../../../../services/helpers/etc";
import { request } from "../../../../../services/axios/axios";
import {
  TYPE_OF_EXAM_DISPLAY,
  UPDATE_ORDER,
} from "../../../../../services/constants/admin/pages/exam";
import { API } from "../../../../../services/constants/route/api";
import PartExam from "./PartExam";
import SectionExam from "./SectionExam";
import { useAuthoringExamContext } from "../../../../../context/Exam/AuthoringExamContext";
import ItemExam from "./ItemExam";
import { swalConfirm, swalError } from "../../../../../services/helpers/swal";
import ShowSettingProperties from "./ShowSettingProperties";
import {
  ExamItemType,
  ExamPartType,
  ExamSectionType,
} from "../../../../../types/admin/examAuthoring";

export const handleScoreExamItems = (examItems: ExamItemType[]) => {
  let totalScore = 0;
  examItems.forEach((examItem) => (totalScore += examItem.total_score || 0));

  return totalScore;
};

const ExamAuthoring = () => {
  const { getParamValue } = useObjectRoutes();
  const examId = getParamValue("exam_id");

  const {
    exam,
    questions,
    selectedItems,
    settingPropertyOrders,
    waitingAPI,
    setExam,
    setSelectedItems,
    setSettingPropertyOrders,
    setDeleteIds,
  } = useAuthoringExamContext();

  const handleAddExamPart = () => {
    if (isEmptyObj(exam) || waitingAPI) return;

    const totalExamPart = exam.exam_parts?.length || 0;
    const order = totalExamPart + 1;
    const name = "大問" + order;
    const newExamPart: ExamPartType = {
      exam_id: exam.id || 0,
      name: name,
      order: order,
      parent_id: null,
      exam_sections: [],
      setting_properties: {
        max_times: {
          hours: 1,
          minutes: 0,
          seconds: 0,
        },
      },
    };

    setExam((exam) => ({
      ...exam,
      exam_parts: [...exam.exam_parts, newExamPart],
    }));
  };

  const handleAddExamSection = (order: number) => {
    if (waitingAPI) return;

    try {
      const findExamPart = exam.exam_parts.find(
        (examPart) => examPart.order === order
      );
      if (!findExamPart) {
        throw new Error("Exam Part not found!");
      }

      setExam((exam) => {
        const newExamParts = exam.exam_parts.map((examPart) => {
          if (examPart.order === order) {
            const examSections = examPart.exam_sections || [];
            const totalExamSection = examSections.length;
            const orderExamSection = totalExamSection + 1;
            const newExamSection: ExamSectionType = {
              name: `グループ第${orderExamSection}階層`,
              exam_id: exam.id || 0,
              order: orderExamSection,
              exam_items: [],
              setting_properties: {
                item_session_controls: {
                  max_times_answer: 1,
                },
              },
            };

            return {
              ...examPart,
              exam_sections: [...examSections, newExamSection],
            };
          }

          return examPart;
        });

        return { ...exam, exam_parts: newExamParts };
      });
    } catch (error) {
      const message = (error as Error).message;
      swalError(message);
    }
  };

  const handleAddExamItem = (orderPart: number, orderSection: number) => {
    if (waitingAPI) return;

    try {
      const filterExamItems = questions.filter((question) =>
        selectedItems.includes(question.id || 0)
      );

      if (isEmptyArr(filterExamItems)) {
        throw new Error("Exam items not found!");
      }

      const findExamPart = exam.exam_parts.find(
        (examPart) => examPart.order === orderPart
      );

      if (isEmptyObj(findExamPart)) {
        throw new Error("Exam part not found!");
      }

      const findExamSection = findExamPart?.exam_sections.find(
        (examSection) => examSection.order === orderSection
      );

      if (isEmptyObj(findExamSection)) {
        throw new Error("Exam section not found!");
      }

      const totalExamItem = findExamSection?.exam_items.length || 0;

      let totalGroupScore = 0;
      const newExamItems: ExamItemType[] = filterExamItems.map(
        (examItem, index) => {
          totalGroupScore += examItem.total_score ?? 0;

          return {
            name: examItem.name || "",
            identifier: "item_" + (totalExamItem + 1 + index),
            question_id: examItem.id || 0,
            order: totalExamItem + 1 + index,
            question_resource_id: examItem.question_resource_id ?? 0,
            total_score: examItem.total_score ?? 0,
          };
        }
      );

      setExam((exam) => {
        const examParts = exam.exam_parts.map((examPart) => {
          let totalPartScore = examPart.total_score || 0;

          if (examPart.order === orderPart) {
            const newExamSections = examPart.exam_sections.map(
              (examSection) => {
                if (examSection.order === orderSection) {
                  const dataNewExamItems = [
                    ...examSection.exam_items,
                    ...newExamItems,
                  ];

                  const newTotalGroupScore = handleScoreExamItems(dataNewExamItems);
                  totalPartScore += totalGroupScore;

                  return {
                    ...examSection,
                    exam_items: dataNewExamItems,
                    total_score: newTotalGroupScore,
                  };
                }

                return examSection;
              }
            );

            return {
              ...examPart,
              exam_sections: newExamSections,
              total_score: totalPartScore,
            };
          }

          return examPart;
        });

        return { ...exam, exam_parts: examParts };
      });

      setSelectedItems([]);
    } catch (error) {
      const message = (error as Error).message;
      swalError(message);
    }
  };

  const handleDeletePart = (orderPart: number, examPartId?: number) => {
    swalConfirm(() => {
      deletePart(orderPart, examPartId);
    });
  };

  const deletePart = (orderPart: number, examPartId?: number) => {
    if (waitingAPI) return;
    setExam((exam) => {
      const newExamParts = exam.exam_parts
        .filter((examPart) => examPart.order !== orderPart)
        .map((examPart, index) => {
          return { ...examPart, order: index + 1 };
        });

      return { ...exam, exam_parts: newExamParts };
    });
    handleReset();

    if (examPartId) {
      setDeleteIds((pre) => ({
        ...pre,
        exam_part_ids: [...pre.exam_part_ids, examPartId],
      }));
    }
  };

  const handleDeleteExamSection = (
    orderPart: number,
    orderSection: number,
    examSectionId?: number
  ) => {
    swalConfirm(() => {
      deleteExamSection(orderPart, orderSection, examSectionId);
    });
  };

  const deleteExamSection = (
    orderPart: number,
    orderSection: number,
    examSectionId?: number
  ) => {
    if (waitingAPI) return;

    setExam((exam) => {
      const newExamParts = exam.exam_parts.map((examPart) => {
        if (examPart.order === orderPart) {
          let newTotalScoreExamGroup = 0;
          
          const newExamSections = examPart.exam_sections
            .filter((examSection) => examSection.order !== orderSection)
            ?.map((examSection, index) => {
              newTotalScoreExamGroup += examSection.total_score || 0;

              return {
                ...examSection,
                order: index + 1,
              };
            });

          return { ...examPart, exam_sections: newExamSections, total_score: newTotalScoreExamGroup };
        }

        return examPart;
      });

      return { ...exam, exam_parts: newExamParts };
    });
    handleReset();

    if (examSectionId) {
      setDeleteIds((pre) => ({
        ...pre,
        exam_section_ids: [...pre.exam_section_ids, examSectionId],
      }));
    }
  };

  const handleDeleteExamItem = (
    orderPart: number,
    orderSection: number,
    orderItem: number,
    examItemId?: number
  ) => {
    swalConfirm(() => {
      deleteExamItem(orderPart, orderSection, orderItem, examItemId);
    });
  };

  const deleteExamItem = (
    orderPart: number,
    orderSection: number,
    orderItem: number,
    examItemId?: number
  ) => {
    if (waitingAPI) return;

    setExam((exam) => {
      const newExamParts = exam.exam_parts.map((examPart) => {
        let totalScoreExamPart = examPart.total_score || 0;

        if (examPart.order === orderPart) {
          const newExamSections = examPart.exam_sections.map((examSection) => {
            if (examSection.order === orderSection) {
              let totalScoreExamGroup = examSection.total_score || 0;
              const newExamItems = examSection.exam_items
                ?.filter((examItem) => examItem.order !== orderItem)
                ?.map((examItem, index) => ({ ...examItem, order: index + 1 }));

              const newTotalScoreExamGroup = handleScoreExamItems(newExamItems);
              totalScoreExamPart = totalScoreExamPart - totalScoreExamGroup + newTotalScoreExamGroup;

              return {
                ...examSection,
                exam_items: newExamItems,
                total_score: newTotalScoreExamGroup,
              };
            }

            return examSection;
          });

          return {
            ...examPart,
            exam_sections: newExamSections,
            total_score: totalScoreExamPart,
          };
        }

        return examPart;
      });

      return { ...exam, exam_parts: newExamParts };
    });
    handleReset();

    if (examItemId) {
      setDeleteIds((pre) => ({
        ...pre,
        exam_item_ids: [...pre.exam_item_ids, examItemId],
      }));
    }
  };

  const handleUpdateOrderPart = (orderPart: number, orderType: number) => {
    if (waitingAPI) return;

    let newExamParts = exam.exam_parts || [];

    if (orderType === UPDATE_ORDER.UP && orderPart > 1) {
      newExamParts = exam.exam_parts.map((examPart) => {
        if (examPart.order === orderPart + UPDATE_ORDER.UP) {
          return { ...examPart, order: orderPart };
        }

        if (examPart.order === orderPart) {
          return { ...examPart, order: orderPart + UPDATE_ORDER.UP };
        }

        return examPart;
      });
    }

    if (orderType === UPDATE_ORDER.DOWN && orderPart < exam.exam_parts.length) {
      newExamParts = exam.exam_parts.map((examPart) => {
        if (examPart.order === orderPart + UPDATE_ORDER.DOWN) {
          return { ...examPart, order: orderPart };
        }

        if (examPart.order === orderPart) {
          return { ...examPart, order: orderPart + UPDATE_ORDER.DOWN };
        }

        return examPart;
      });
    }

    setExam((exam) => ({
      ...exam,
      exam_parts: newExamParts.sort((prevExamPart, nextExamPart) =>
        prevExamPart.order > nextExamPart.order
          ? UPDATE_ORDER.DOWN
          : UPDATE_ORDER.UP
      ),
    }));
    handleReset();
  };

  const handleUpdateOrderSection = (
    orderPart: number,
    orderSection: number,
    orderType: number
  ) => {
    if (waitingAPI) return;

    setExam((prevExam) => {
      const newExamParts = prevExam.exam_parts.map((examPart) => {
        if (examPart.order === orderPart) {
          const newExamSections = examPart.exam_sections || [];
          const maxOrder = newExamSections.length;

          let newOrder = orderSection;

          if (orderType === UPDATE_ORDER.UP && orderSection > 1) {
            newOrder = orderSection + UPDATE_ORDER.UP;
          } else if (
            orderType === UPDATE_ORDER.DOWN &&
            orderSection < maxOrder
          ) {
            newOrder = orderSection + UPDATE_ORDER.DOWN;
          } else {
            return examPart;
          }

          const updatedSections = newExamSections.map((examSection) => {
            if (examSection.order === newOrder) {
              return { ...examSection, order: orderSection };
            } else if (examSection.order === orderSection) {
              return { ...examSection, order: newOrder };
            }
            return examSection;
          });

          return {
            ...examPart,
            exam_sections: updatedSections.sort(
              (prevExamSection, nextExamSection) =>
                prevExamSection.order > nextExamSection.order
                  ? UPDATE_ORDER.DOWN
                  : UPDATE_ORDER.UP
            ),
          };
        }

        return examPart;
      });

      return { ...prevExam, exam_parts: newExamParts };
    });

    handleReset();
  };

  const handleUpdateOrderItem = (
    orderPart: number,
    orderSection: number,
    orderItem: number,
    orderType: number
  ) => {
    if (waitingAPI) return;

    let newExamParts = exam.exam_parts;
    newExamParts = newExamParts.map((examPart) => {
      if (examPart.order === orderPart) {
        let newExamSections = examPart.exam_sections || [];
        newExamSections = newExamSections.map((examSection) => {
          // Check orderSection
          if (examSection.order === orderSection) {
            let newExamItems = examSection.exam_items;
            newExamItems = newExamItems.map((examItem) => {
              // Check order type UP and order item must be greater than 1.
              if (orderType === UPDATE_ORDER.UP && orderItem > 1) {
                if (examItem.order === orderItem + UPDATE_ORDER.UP) {
                  return { ...examItem, order: orderItem };
                }

                if (examItem.order === orderItem) {
                  return { ...examItem, order: orderItem + UPDATE_ORDER.UP };
                }
              }

              // Check order type DOWN and order item must be smaller than total examItem in examSection.
              if (
                orderType === UPDATE_ORDER.DOWN &&
                orderItem < examSection.exam_items.length
              ) {
                if (examItem.order === orderItem + UPDATE_ORDER.DOWN) {
                  return { ...examItem, order: orderItem };
                }

                if (examItem.order === orderItem) {
                  return { ...examItem, order: orderItem + UPDATE_ORDER.DOWN };
                }
              }

              return examItem;
            });

            return {
              ...examSection,
              exam_items: newExamItems.sort((prevExamItem, nextExamItem) =>
                prevExamItem.order > nextExamItem.order
                  ? UPDATE_ORDER.DOWN
                  : UPDATE_ORDER.UP
              ),
            };
          }
          return examSection;
        });

        return { ...examPart, exam_sections: newExamSections };
      }

      return examPart;
    });

    setExam((exam) => ({ ...exam, exam_parts: newExamParts }));
    handleReset();
  };

  const isChooseExamType = (
    orderPart?: number,
    orderSection?: number,
    orderItem?: number
  ): boolean => {
    if (
      settingPropertyOrders.orderType === TYPE_OF_EXAM_DISPLAY.PART &&
      orderPart &&
      !orderSection &&
      !orderItem
    ) {
      return orderPart === settingPropertyOrders.orderPart;
    }

    if (
      settingPropertyOrders.orderType === TYPE_OF_EXAM_DISPLAY.SECTION &&
      orderSection &&
      !orderItem
    ) {
      return (
        orderPart === settingPropertyOrders.orderPart &&
        orderSection === settingPropertyOrders.orderSection
      );
    }

    if (
      settingPropertyOrders.orderType === TYPE_OF_EXAM_DISPLAY.ITEM &&
      orderItem
    ) {
      return (
        orderPart === settingPropertyOrders.orderPart &&
        orderSection === settingPropertyOrders.orderSection &&
        orderItem === settingPropertyOrders.orderItem
      );
    }

    return false;
  };

  const handleReset = () => {
    setSettingPropertyOrders({});
  };

  useEffect(() => {
    const fetchExamDetail = async () => {
      await request.get(
        `${API.ADMIN_EXAM.DETAIL}?exam_id=${examId}`,
        (exam) => {
          setExam(exam);
        }
      );
    };

    fetchExamDetail();
  }, []);

  return (
    <>
      <div className="flex basis-[80%]">
        <div className="basis-[70%] bg-white">
          <div className="flex-col p-[20px] ">
            <button
              type="button"
              className={`w-full rounded-[5px] h-[36px] px-[10px] text-white text-[18px] justify-start items-center flex ${
                settingPropertyOrders.orderType === TYPE_OF_EXAM_DISPLAY.EXAM
                  ? "bg-primary"
                  : "bg-secondary"
              }`}
              onClick={() =>
                setSettingPropertyOrders({
                  orderType: TYPE_OF_EXAM_DISPLAY.EXAM,
                })
              }
            >
              {exam.name}
            </button>
            <div className="flex items-center bg-white mt-[8px] gap-[3px]">
              <div className="bg-[#EFF1F0] text-[13px] h-[30px] flex justify-start items-center basis-[60%] px-[10px]">
                名称
              </div>
              <div className="bg-[#EFF1F0] text-[13px] h-[30px] flex justify-center items-center basis-[25%] px-[10px]">
                並び順
              </div>
              <div className="bg-[#EFF1F0] text-[13px] h-[30px] flex justify-center items-center basis-[15%] px-[10px]">
                削除
              </div>
              <div className="bg-[#EFF1F0] text-[13px] h-[30px] flex justify-center items-center basis-[15%] px-[10px]">
                スコア
              </div>
            </div>
            {exam.exam_parts &&
              exam.exam_parts.length > 0 &&
              exam.exam_parts.map((examPart) => (
                <PartExam
                  key={examPart.order}
                  partExam={examPart}
                  totalExamPart={exam.exam_parts.length}
                  handleAddExamSection={handleAddExamSection}
                  handleDelete={() =>
                    handleDeletePart(examPart.order, examPart.id)
                  }
                  handleUpdateOrder={handleUpdateOrderPart}
                  isChooseSetting={isChooseExamType(examPart.order)}
                >
                  {examPart.exam_sections &&
                    examPart.exam_sections.length > 0 &&
                    examPart.exam_sections.map((examSection) => (
                      <SectionExam
                        key={`${examPart.order}_${examSection.order}`}
                        totalExamSection={examPart.exam_sections.length}
                        examSection={examSection}
                        isChooseSetting={isChooseExamType(
                          examPart.order,
                          examSection.order
                        )}
                        handleAddExamItem={() =>
                          handleAddExamItem(examPart.order, examSection.order)
                        }
                        maxOrder={examPart.exam_sections?.length || 0}
                        handleDelete={() =>
                          handleDeleteExamSection(
                            examPart.order,
                            examSection.order,
                            examSection.id
                          )
                        }
                        handleUpdateOrderSection={(orderType: number) =>
                          handleUpdateOrderSection(
                            examPart.order,
                            examSection.order,
                            orderType
                          )
                        }
                        handleChooseSettingProperty={() =>
                          setSettingPropertyOrders({
                            orderPart: examPart.order,
                            orderSection: examSection.order,
                            orderType: TYPE_OF_EXAM_DISPLAY.SECTION,
                          })
                        }
                      >
                        {examSection.exam_items &&
                          examSection.exam_items.length > 0 &&
                          examSection.exam_items.map((examItem) => (
                            <ItemExam
                              key={`${examSection.order}_${examItem.order}`}
                              itemExam={examItem}
                              maxOrder={examSection.exam_items?.length || 0}
                              totalExamItem={examSection.exam_items.length}
                              isChooseSetting={isChooseExamType(
                                examPart.order,
                                examSection.order,
                                examItem.order
                              )}
                              handleDelete={() =>
                                handleDeleteExamItem(
                                  examPart.order,
                                  examSection.order,
                                  examItem.order,
                                  examItem.id
                                )
                              }
                              handleUpdateOrderItem={(orderType: number) =>
                                handleUpdateOrderItem(
                                  examPart.order,
                                  examSection.order,
                                  examItem.order,
                                  orderType
                                )
                              }
                              handleChooseSettingProperty={() =>
                                setSettingPropertyOrders({
                                  orderPart: examPart.order,
                                  orderSection: examSection.order,
                                  orderItem: examItem.order,
                                  orderType: TYPE_OF_EXAM_DISPLAY.ITEM,
                                })
                              }
                            />
                          ))}
                      </SectionExam>
                    ))}
                </PartExam>
              ))}
            <div className="mt-[10px]">
              <button
                className="w-[220px] h-[38px] bg-[#235FA9] px-[15px] py-[7px] rounded-[5px] flex justify-start items-center text-[13px] text-white gap-[10px]"
                onClick={handleAddExamPart}
              >
                <IoIosAddCircleOutline size={24} />
                新しいテストパートを追加
              </button>
            </div>
          </div>
        </div>
        <div className="min-w-[320px] flex-col basis-[30%] bg-[#EFF1F0] h-full border-l-2 border-[#BEBEBE] text-secondary-dark">
          <div className="w-full flex justify-start items-center gap-[10px] px-[10px] py-[5px] bg-success-extralight h-[35px]">
            <IoSettingsOutline className="w-[24px] h-[24px]" />
            <div className="text-[14px]">プロパティ</div>
          </div>
          <ShowSettingProperties
            type={settingPropertyOrders.orderType || null}
          />
        </div>
      </div>
    </>
  );
};

export default ExamAuthoring;
