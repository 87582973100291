import React, { useState, useContext, useEffect } from 'react';
import { ChildrenWithProps, SetStateAction } from '../../types/globals';
import {
  AuthoringData,
  ChoiceProblem,
  EditingProblemStatus,
  OrderProblem,
} from '../../types/admin/question';

interface AuthoringQuestionContextType {
  draggedDataTransfer: string;
  setDraggedDataTransfer: SetStateAction<string>;
  isDragging: boolean;
  setIsDragging: SetStateAction<boolean>;
  draggedName: string;
  setDraggedName: SetStateAction<string>;
  selectedProblem: ChoiceProblem | OrderProblem;
  setSelectedProblem: (problemId?: number) => void;
  authoringData: AuthoringData;
  setAuthoringData: SetStateAction<AuthoringData>;
  editingProblemStatuses: EditingProblemStatus[];
  setEditingProblemStatuses: SetStateAction<EditingProblemStatus[]>;
  draggingDroppedProblem: ChoiceProblem | OrderProblem;
  setDraggingDroppedProblem: SetStateAction<ChoiceProblem | OrderProblem>;
  isEditingProblem: boolean;
  setIsEditingProblem: SetStateAction<boolean>;
}

const AuthoringQuestionContext = React.createContext<AuthoringQuestionContextType>({
  draggedDataTransfer: '',
  setDraggedDataTransfer: () => {},
  isDragging: false,
  setIsDragging: () => {},
  draggedName: '',
  setDraggedName: () => {},
  selectedProblem: {},
  setSelectedProblem: () => {},
  authoringData: {},
  setAuthoringData: () => {},
  editingProblemStatuses: [],
  setEditingProblemStatuses: () => {},
  draggingDroppedProblem: {},
  setDraggingDroppedProblem: () => {},
  isEditingProblem: false,
  setIsEditingProblem: () => {}
});

const AuthoringQuestionProvider = ({ children }: ChildrenWithProps) => {
  const [draggedDataTransfer, setDraggedDataTransfer] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [draggedName, setDraggedName] = useState('');
  const [selectedProblem, _setSelectedProblem] = useState<ChoiceProblem | OrderProblem>({});
  const [editingProblemStatuses, setEditingProblemStatuses] = useState<EditingProblemStatus[]>([]);
  const [draggingDroppedProblem, setDraggingDroppedProblem] = useState<
    ChoiceProblem | OrderProblem
  >({});
  const [isEditingProblem, setIsEditingProblem] = useState<boolean>(false);

  const initAuthoringData: AuthoringData = {
    problems: [],
  };
  const [authoringData, setAuthoringData] = useState<AuthoringData>(initAuthoringData || {});

  const setSelectedProblem = (problemId?: number) => {
    if (!problemId) _setSelectedProblem({});
    _setSelectedProblem(authoringData?.problems?.find((problem) => problem.id === problemId) || {});
  };

  return (
    <AuthoringQuestionContext.Provider
      value={{
        draggedDataTransfer,
        setDraggedDataTransfer,
        isDragging,
        setIsDragging,
        draggedName,
        setDraggedName,
        selectedProblem,
        setSelectedProblem,
        authoringData,
        setAuthoringData,
        editingProblemStatuses,
        setEditingProblemStatuses,
        draggingDroppedProblem,
        setDraggingDroppedProblem,
        isEditingProblem,
        setIsEditingProblem
      }}
    >
      {children}
    </AuthoringQuestionContext.Provider>
  );
};

export default AuthoringQuestionProvider;

export const useAuthoringQuestionContext = () => useContext(AuthoringQuestionContext);
