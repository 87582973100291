import { FiFolderPlus, FiMove } from "react-icons/fi";
import { TbFilePlus } from "react-icons/tb";
import { PiCopy, PiCopySimple, PiTrashBold } from "react-icons/pi";
import { swalError, swalConfirm } from "../../../../services/helpers/swal";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { DELETE_CONFIRM_TITLE } from "../../../../services/constants/message";
import { NAME_ACTION_ASIDE_TEST } from "../../../../services/constants/admin/pages/exam";
import { useListExamContext } from "../../../../context/Exam/ListExamContext";
import { Exam, ExamForm } from "../../../../types/admin/exam";

const SideAction = () => {
  const actionData = [
    {
      icon: <FiFolderPlus />,
      label: "新規フォルダー",
      action: NAME_ACTION_ASIDE_TEST.CREATE_FOLDER,
    },
    {
      icon: <TbFilePlus />,
      label: "新規テスト",
      action: NAME_ACTION_ASIDE_TEST.CREATE_FILE,
    },
    {
      icon: <PiCopy />,
      label: "複製",
      action: NAME_ACTION_ASIDE_TEST.DUPLICATE,
    },
    {
      icon: <PiCopySimple />,
      label: "コピー",
      action: NAME_ACTION_ASIDE_TEST.COPY,
    },
    {
      icon: <PiTrashBold />,
      label: "削除",
      action: NAME_ACTION_ASIDE_TEST.DELETE,
    },
    {
      icon: <FiMove />,
      label: "移動",
      action: NAME_ACTION_ASIDE_TEST.MOVE,
    },
  ];

  const {
    selectedExam,
    setSelectedExam,
    exams,
    sideAction,
    setExams,
    setSideAction,
  } = useListExamContext();
  const unClickableClasses = (index: number) => {
    return index !== 0 && index !== 1 && !selectedExam?.id
      ? "!pointer-events-none"
      : "";
  };

  const handleAction = async (actionName: string) => {
    let currentParentId = null;
    if (selectedExam?.id) {
      if (!!selectedExam.is_file) {
        currentParentId = selectedExam.parent_id;
      } else {
        currentParentId = selectedExam.id;
      }
    }

    let submitData: ExamForm = {
      parent_id: currentParentId,
    };

    switch (actionName) {
      case NAME_ACTION_ASIDE_TEST.CREATE_FOLDER:
        submitData = {
          ...submitData,
          is_file: false,
        };
        await request.post(API.ADMIN_EXAM.CREATE, submitData, (res: Exam) =>
          setExams([...exams, res])
        );
        break;

      case NAME_ACTION_ASIDE_TEST.CREATE_FILE:
        submitData = {
          ...submitData,
          is_file: true,
        };
        await request.post(API.ADMIN_EXAM.CREATE, submitData, (res: Exam) =>
          setExams([...exams, res])
        );
        break;

      case NAME_ACTION_ASIDE_TEST.DUPLICATE:
        submitData = {
          id: selectedExam.id,
        };
        await request.post(
          API.ADMIN_EXAM.DUPLICATE,
          submitData,
          (res: Exam[]) => setExams([...res, ...exams])
        );
        break;

      case NAME_ACTION_ASIDE_TEST.COPY:
        setSideAction(actionName === sideAction ? "" : actionName);
        break;

      case NAME_ACTION_ASIDE_TEST.DELETE:
        submitData = { id: selectedExam.id };
        swalConfirm(
          async () => {
            await request.delete(
              API.ADMIN_EXAM.DELETE,
              submitData,
              (res) => {
                setExams(exams.filter((exam) => exam.id !== selectedExam.id));
                setSelectedExam(res?.id);
              }
            );
          },
          selectedExam.is_file
            ? DELETE_CONFIRM_TITLE.EXAM_FILE
            : DELETE_CONFIRM_TITLE.EXAM_FOLDER
        );
        break;

      case NAME_ACTION_ASIDE_TEST.MOVE:
        setSideAction(actionName === sideAction ? "" : actionName);
        break;

      default:
        swalError();
        break;
    }
  };

  return (
    <div className="flex flex-wrap justify-center gap-[5px] w-full max-w-[271px]">
      {actionData.map((item, index) => (
        <div
          key={index}
          className={`w-full max-w-[125px] h-0 min-h-[35px] text-[11px] text-secondary-dark leading-[120%] bg-white rounded-[5px] flex items-center justify-start gap-[4px] cursor-pointer pl-[7px] ${unClickableClasses(
            index
          )} ${sideAction === item.action ? "bg-[rgba(143,194,213,0.21)]" : ""}
          ${
            sideAction === item.action
              ? "hover:bg-[rgba(143,194,213,0.21)]"
              : "hover:bg-primary-extralight hover:shadow-md"
          }
          `}
          onClick={() => handleAction(item.action)}
        >
          <div className="text-secondary text-[20px]">{item.icon}</div>
          <div className="">{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export default SideAction;
