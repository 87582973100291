import React, { MouseEvent, PropsWithChildren } from "react";

interface KeyPadProps {
  onClick: (name: string) => void;
}

const Button = ({
  children,
  name,
  onClick,
}: {
  name: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
} & PropsWithChildren) => {
  return (
    <button
      className="w-[80px] h-[60px] text-[30px] m-[3px] bg-gray-300"
      name={name}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const KeyPadComponent: React.FC<KeyPadProps> = ({ onClick }) => {
  return (
    <div className="flex flex-wrap justify-center items-center pt-[10px]">
      <Button
        name="("
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        (
      </Button>
      <Button
        name="CE"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        CE
      </Button>
      <Button
        name=")"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        )
      </Button>
      <Button
        name="C"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        C
      </Button>
      <br />

      <Button
        name="1"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        1
      </Button>
      <Button
        name="2"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        2
      </Button>
      <Button
        name="3"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        3
      </Button>
      <Button
        name="+"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        +
      </Button>
      <br />

      <Button
        name="4"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        4
      </Button>
      <Button
        name="5"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        5
      </Button>
      <Button
        name="6"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        6
      </Button>
      <Button
        name="-"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        -
      </Button>
      <br />

      <Button
        name="7"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        7
      </Button>
      <Button
        name="8"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        8
      </Button>
      <Button
        name="9"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        9
      </Button>
      <Button
        name="*"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        x
      </Button>
      <br />

      <Button
        name="."
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        .
      </Button>
      <Button
        name="0"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        0
      </Button>
      <Button
        name="="
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        =
      </Button>
      <Button
        name="/"
        onClick={(e: MouseEvent<HTMLButtonElement>) =>
          onClick(e.currentTarget.name)
        }
      >
        ÷
      </Button>
      <br />
    </div>
  );
};

export default KeyPadComponent;
