import React from "react";
import Timer from "./Timer";
import { TestTakerRecordProblem } from "../../../../../../../../types/admin/question";
import { Controls } from "react-voice-visualizer/dist/types/types";
import { formattedMinutesFromMilliseconds } from "../../../../../../../../services/helpers/formatTime";

type UploaderTimerProps = {
  recording: boolean;
  pause: boolean;
  problemData: TestTakerRecordProblem;
  recorderControls: Controls;
  stopRecording: () => void;
};

const UploaderTimer = ({
  recording,
  pause,
  problemData,
  recorderControls,
  stopRecording,
}: UploaderTimerProps) => {
  return (
    <div className="text-[36px] font-[400] leading-[44px] text-secondary-dark">
      {recording ? (
        <Timer
          milliseconds={(problemData.file_minute_size_limit || 1) * 60 * 1000}
          isPause={pause}
          handleStop={stopRecording}
          isStop={!recording}
        />
      ) : (
        <Timer
          milliseconds={recorderControls.duration * 1000}
          isPause={pause}
          handleStop={stopRecording}
          isStop={!recording}
          currentTime={recorderControls.currentAudioTime}
        />
      )}
    </div>
  );
};

export default UploaderTimer;
