import { RouterProvider } from "react-router-dom";
import "./App.css";
import "./assests/styles/globals.css";
import "./assests/styles/modal.css";
import AuthProvider from "./context/AuthContext";
import { routes } from "./routes";
import SidebarContextProvider from "./context/SidebarContext";

function App() {
  return (
    <AuthProvider>
      <SidebarContextProvider>
        <RouterProvider router={routes} />
      </SidebarContextProvider>
    </AuthProvider>
  );
}
export default App;
