const getSubdomainFromUrl = () => {
  let currentUrl = window.location.hostname;
  let positionFirstDot = currentUrl.indexOf('.');

  if (positionFirstDot === -1) {
    return '';
  }

  return currentUrl.slice(0, positionFirstDot)
}

const checkIsSubdomain = () => {
  const domain = `${window.location.host}`;
  const mainDomain = `${process.env.REACT_APP_MAIN_DOMAIN}`;

  return domain !== mainDomain;
};

export { getSubdomainFromUrl, checkIsSubdomain }
