import { useEffect, useState } from "react";
import Logo from "../../components/commons/icons/Logo";
import Menu from "./Menu";
import { useAuthContext } from "../../context/AuthContext";
import { AiFillCaretDown } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";
import { request } from "../../services/axios/axios";
import { API } from "../../services/constants/route/api";
import { useStorage } from "../../hooks/useStorage";
import City from "../../components/commons/icons/City";

type Organization = {
  name?: string;
  logo_url?: string;
};

const Header = () => {
  const { user } = useAuthContext();
  const organizationStorageKey = "organization";
  const subdomain = window.location.hostname.split(".")[0];
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const { getLocalStorage, setLocalStorage } = useStorage();
  const [organization, setOrganization] = useState<Organization>({});

  useEffect(() => {
    const organizationStoraged: Organization = getLocalStorage(
      organizationStorageKey
    );
    if (organizationStoraged) {
      setOrganization(organizationStoraged);
      return;
    }

    const fetchOrganizationInfo = async () => {
      await request.get(
        `${API.ORGANIZATION.GET_ORGANIZATION_INFO}?sub_domain=${subdomain}`,
        (data: Organization) => {
          setLocalStorage(organizationStorageKey, data);
          setOrganization(data);
        },
        (errors) => console.log(errors)
      );
    };

    fetchOrganizationInfo();
  }, []);

  return (
    <>
      <section className="flex justify-between min-h-[60px] items-center pr-[50px]">
        <div className="flex items-center justify-between w-full max-w-[320px] cursor-pointer">
          <div className="flex items-center justify-center w-full max-w-[250px] cursor-pointer">
            <Logo otherStyle="w-[160px] h-[40px]" />
          </div>
          <div className=" text-[12px] w-[40px] h-[40px] flex justify-center items-center">
            {organization && organization.logo_url ? (
              <img
                src={organization.logo_url}
                alt=""
                className="w-full h-full"
              />
            ) : (
              <City color="#BEBEBE" />
            )}
          </div>
        </div>
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          <div className="mr-[11px] bg-success-lighter w-[30px] h-[30px] flex justify-center items-center">
            {user?.avatar_url ? (
              <img
                src={user.avatar_url}
                width={26.67}
                height={26.67}
                alt="avatar"
                className="object-cover w-[26.67px] h-[26.67px]"
              />
            ) : (
              <HiOutlineUserCircle color="#BEBEBE" size={26.67} />
            )}
          </div>
          <div className="text-secondary flex items-end hover:text-secondary-dark">
            <div className="text-[13px] font-[500] leading-[19px] tracking-[0.02em] mr-[3px]">
              {(user?.last_name || "") + (user?.first_name || "")}
            </div>
            <div className="text-[10px] leading-[14px] mr-[10px]">さん</div>
            <AiFillCaretDown />
            <Menu
              className={`absolute top-[60px] right-[10px] ${
                !isOpenMenu && "hidden"
              } z-[1000]`}
            />
          </div>
        </div>
        {isOpenMenu && (
          <div
            className="fixed top-0 left-0 w-full h-full"
            onClick={() => setIsOpenMenu(false)}
          />
        )}
      </section>
    </>
  );
};

export default Header;
