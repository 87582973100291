import React from "react";

const Triangle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 6.40192C15.5 7.55662 15.5 10.4434 13.5 11.5981L4.5 16.7942C2.5 17.9489 0 16.5056 0 14.1962V3.80385C0 1.49445 2.5 0.0510712 4.5 1.20577L13.5 6.40192Z"
        fill={`${props.color || "#235FA9"}`}
      />
    </svg>
  );
};

export default Triangle;
