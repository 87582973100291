import React from "react";

const OrangeBlock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="219"
      height="219"
      viewBox="0 0 219 219"
      fill="none"
    >
      <path
        opacity="0.8"
        d="M-0.000106812 0C-120.79 0 -219 98.2102 -219 219C-219 339.79 -120.79 438 -0.000106812 438C120.79 438 219 339.79 219 219C219 98.2102 120.79 0 -0.000106812 0Z"
        fill="#D6812C"
      />
    </svg>
  );
};

export default OrangeBlock;
