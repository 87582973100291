import { STATUS_DATA } from '../../../services/constants/globals';

type StatusBoxProps = {
  handleOnChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: number;
  data?: { [key: string]: any }[];
  className?: string;
};

const StatusBox = ({
  handleOnChange,
  value,
  data = STATUS_DATA,
  className = '',
}: StatusBoxProps) => {
  return (
    <div className={className}>
      {data.map((item, index) => (
        <label className="inline-flex items-center mr-[15px]" key={index}>
          <input
            type="radio"
            className="form-radio text-primary-light border border-success-extralight focus:border-[5px] focus:border-primary-light focus:bg-white"
            name="status"
            value={item.value}
            onChange={handleOnChange}
            checked={item.value === Number(value)}
          />
          <span className="ml-[8px] text-[12px] leading-[100%]">{item.label}</span>
        </label>
      ))}
    </div>
  );
};

export default StatusBox;
