import { makeRequest } from "../axios/axios";
import { API } from "../constants/route/api";

const socialLogin = async (provider: string, accessToken: string) => {
  return await makeRequest({
    method: "post",
    url: API.AUTH.SOCIAL_LOGIN,
    data: {
      provider,
      token: accessToken
    }
  });
}

export { socialLogin }
