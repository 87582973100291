/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { HiPlus } from 'react-icons/hi';
import { Folder } from '../../../../../../../types/admin/folder';
import { request } from '../../../../../../../services/axios/axios';
import { API } from '../../../../../../../services/constants/route/api';
import { SetStateAction } from '../../../../../../../types/globals';
import { TestTakerUploadProblem } from '../../../../../../../types/admin/question';
import { MODE_QUESTION_CATEGORY_SELECTOR } from '../../../../../../../services/constants/admin/pages/question';

type CategorySelectorProps = {
  editingData: TestTakerUploadProblem;
  selectorMode: string;
  selectingFolder: Folder | null;
  setSelectingFolder: SetStateAction<Folder | null>;
  setSelectorMode: SetStateAction<string>;
  setFolderName: SetStateAction<string>;
};

const CategorySelector = ({
  editingData,
  selectorMode,
  selectingFolder,
  setSelectingFolder,
  setSelectorMode,
  setFolderName,
}: CategorySelectorProps) => {
  const [folderList, setFolderList] = useState<Folder[]>([]);

  useEffect(() => {
    const fetchDetail = async () => {
      await request.get(`${API.ADMIN_FOLDER.LIST}?sortType=1&limit=1000`, (res) => {
        const folderRes = res?.resource_folders || [];
        setFolderList(folderRes);
        if (editingData?.upload_folder) {
          setSelectingFolder(editingData?.upload_folder);
          return;
        }

        if (folderRes.length) {
          const firstFolder = folderRes[0];
          setSelectingFolder(firstFolder);
        }
      });
    };

    fetchDetail();
  }, []);

  const changeCategoryHandle = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFolder =
      folderList.find(
        (folder) => `${folder.id}|${folder.code}` === String(e.currentTarget.value),
      ) || null;

    setSelectingFolder(selectedFolder);
  };

  return (
    <div>
      {selectorMode === MODE_QUESTION_CATEGORY_SELECTOR.SELECT ? (
        <div className="">
          <div className="text-[#227C9D] text-center text-[16px] font-[700] leading-[100%] tracking-[0.32px] mb-[20px]">
            ファイルアップロードするカテゴリを選択
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="py-[20px] mb-[10px] w-[500px] text-secondary-dark">
              <div className="text-[14px] leading-normal mb-[8px]">カテゴリを選択</div>
              <select
                name="group_ids"
                id=""
                className="text-[12px] leading-[100%] w-full h-[40px] px-[10px]"
                value={`${selectingFolder?.id}|${selectingFolder?.code}`}
                onChange={changeCategoryHandle}
              >
                <option value={0} disabled>
                  カテゴリを選択
                </option>
                {folderList.map((folder, index) => (
                  <option key={`${folder.id}_${index}`} value={`${folder.id}|${folder.code}`}>
                    {folder.name}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="bg-primary rounded-[10px] text-white flex items-center justify-center gap-[10px] w-full max-w-[248px] h-0 min-h-[34px] cursor-pointer"
              onClick={() => setSelectorMode(MODE_QUESTION_CATEGORY_SELECTOR.CREATE)}
            >
              <div className="">
                <HiPlus size={20} />
              </div>
              <div className="">新規カテゴリを登録する</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center mt-[15%]">
          <div className="mb-[24px] text-primary text-[16px] font-[700] leading-[100%] tracking-[0.32px]">
            カテゴリを新規登録する
          </div>
          <div className="mb-[12px] text-[14px] leading-normal">
            新規カテゴリ（フォルダ）を作成します
          </div>
          <input
            type="text"
            name=""
            id=""
            placeholder="カテゴリ名を入力してください。"
            className="w-full max-w-[550px]"
            onChange={(e) => setFolderName(e.currentTarget.value)}
          />
        </div>
      )}
    </div>
  );
};

export default CategorySelector;
