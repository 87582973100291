import React, { useCallback, useEffect, useState } from "react";
import DropHandler from "./DropHandler";
import { LiaSave } from "react-icons/lia";
import { useAuthoringQuestionContext } from "../../../../../context/Question/AuthoringQuestionContext";
import { request } from "../../../../../services/axios/axios";
import { API } from "../../../../../services/constants/route/api";
import { useObjectRoutes } from "../../../../../hooks/useObjectRoutes";
import { PATH_ADMIN_QUESTION } from "../../../../../services/constants/route/router";
import { swalError } from "../../../../../services/helpers/swal";
import LayoutWaitingApi from "../../../../commons/layouts/LayoutWaitingAPI";
import {
  NAME_TYPE_QUESTION,
  QUESTION_NAME_HAS_SUBMIT_ANSWERS,
} from "../../../../../services/constants/admin/pages/question";
import {
  ChoiceProblem,
  FillInBlankProblem,
  MappingProblem,
  MatrixProblem,
  OrderProblem,
  QuestionProblem,
  SliderProblem,
  TestTakerRecordProblem,
  TestTakerUploadProblem,
  WritingProblem,
} from "../../../../../types/admin/question";
import { isEmptyObj } from "../../../../../services/helpers/etc";
import Spinner from "../../../../commons/icons/Animations/Spinner";

export const handleProblemScoreCalculator = (
  problem: QuestionProblem
): number => {
  let totalScore: number = 0;

  switch (problem.name) {
    case NAME_TYPE_QUESTION.CHOICE:
      const choiceQuestion: ChoiceProblem = problem;

      const choiceIssues = choiceQuestion.issues || [];
      choiceIssues.forEach((issue) => (totalScore += Number(issue.point)));
      break;
    case NAME_TYPE_QUESTION.ORDER:
      const orderQuestin: OrderProblem = problem;

      const orderIssues = orderQuestin.issues || [];
      orderIssues.forEach((issue) => (totalScore += Number(issue.point)));

      break;

    case NAME_TYPE_QUESTION.MAPPING:
      const mappingQuestion: MappingProblem = problem;
      const mappingIssues = mappingQuestion.correct_pairs || [];
      mappingIssues.forEach((issue) => {
        if (!isEmptyObj(issue) && issue.id) {
          totalScore += Number(issue.point);
        }
      });

      break;
    case NAME_TYPE_QUESTION.MATRIX:
      const matrixQuestion: MatrixProblem = problem;
      const matrixIssues = matrixQuestion.correct_coordinates || [];
      matrixIssues.forEach((issue) => {
        if (!isEmptyObj(issue)) {
          totalScore += Number(issue.point);
        }
      });

      break;

    case NAME_TYPE_QUESTION.FILL_IN_BLANK:
      const fillInBlankQuestion: FillInBlankProblem = problem;
      const fillInBlankIssues = fillInBlankQuestion.correct_fills || [];
      fillInBlankIssues.forEach((issue) => {
        if (!isEmptyObj(issue)) {
          totalScore += Number(issue.point);
        }
      });

      break;

    case NAME_TYPE_QUESTION.SLIDER:
      const sliderQuestion: SliderProblem = problem;
      const sliderIssues = sliderQuestion.issues || [];
      sliderIssues.forEach((issue) => {
        if (!isEmptyObj(issue)) {
          totalScore += Number(issue.point);
        }
      });

      break;
    case NAME_TYPE_QUESTION.WRITTEN_QUESTION:
      const writingQuestion: WritingProblem = problem;
      totalScore = writingQuestion?.point || 0;
      break;
    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD:
      const uploadQuestion: TestTakerUploadProblem = problem;
      totalScore = uploadQuestion?.point || 0;

      break;
    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD:
      const recordQuestion: TestTakerRecordProblem = problem;
      totalScore = recordQuestion?.point || 0;

      break;
    default:
      break;
  }

  return totalScore;
};

const QuestionEditorHandler = () => {
  const [pathFiles, setPathFiles] = useState<string[]>([]);
  const [waitingAPI, setWaitingAPI] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { authoringData, isEditingProblem } = useAuthoringQuestionContext();
  const { getParamValue, navigate } = useObjectRoutes();
  const resourceId = getParamValue("resource_id");

  const handleScoreCalculator = () => {
    let totalScore: number = 0;
    authoringData.problems?.forEach(
      (problem) => (totalScore += handleProblemScoreCalculator(problem))
    );

    return totalScore;
  };

  const submitQuestionHandle = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!checkAuthoringQuestionHasAnswer()) {
      swalError("問題の内容をぜひ指定してください。");
      return;
    }

    const submitData = {
      resource_id: getParamValue("resource_id"),
      data: JSON.stringify(authoringData),
      score: handleScoreCalculator(),
    };

    setIsSubmitting(true);
    await request.patch(
      API.ADMIN_QUESTION_RESOURCE.UPDATE,
      submitData,
      () => {},
      () => {
        swalError();
      },
      { withSuccess: true }
    );
    setIsSubmitting(false);
  };

  const checkAuthoringQuestionHasAnswer = () => {
    if (authoringData.problems) {
      return (
        authoringData?.problems.filter((problem) =>
          QUESTION_NAME_HAS_SUBMIT_ANSWERS.includes(problem?.name || "")
        ).length > 0
      );
    }

    return false;
  };

  const getPathFileQuestion = async () => {
    setWaitingAPI(true);

    await request.get(
      `${API.ADMIN_QUESTION.GET_PATH_FILE}?resource_id=${resourceId}`,
      (data) => {
        setPathFiles(data);
      }
    );

    setWaitingAPI(false);
  };

  const handlePathFile = useCallback(() => {
    let pathFileQuestion = "";
    if (pathFiles.length > 1) {
      pathFiles.forEach((pathFile, index) => {
        pathFileQuestion +=
          index === pathFiles.length - 1 ? pathFile : `${pathFile}｜`;
      });
    } else {
      pathFileQuestion += pathFiles[0];
    }

    return pathFileQuestion;
  }, [pathFiles]);

  useEffect(() => {
    if (!resourceId) {
      navigate("/404");
      return;
    }

    getPathFileQuestion();
  }, []);

  return (
    <div className="w-full max-w-[80%] py-[40px] px-[20px] bg-white rounded-[10px] form_authoring">
      <LayoutWaitingApi
        className="w-full h-[30px] pb-[14px] border-b border-secondary-light"
        waitingApi={waitingAPI}
      >
        <div className="text-[16px] font-[500] leading-[100%] border-b border-secondary-light pb-[10px]">
          <div
            className="box-content md:max-w-[600px] xl:max-w-[750px] 2xl:max-w-[900px] truncate leading-[24px]"
            title={handlePathFile()}
          >
            {handlePathFile()}
          </div>
        </div>
      </LayoutWaitingApi>

      {!isEditingProblem ? (
        <div className="flex justify-center gap-[20px] text-white mt-[20px]">
          <button
            className="cursor-pointer flex items-center justify-center w-full max-w-[150px] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
            onClick={() => navigate(PATH_ADMIN_QUESTION.DEFAULT)}
          >
            キャンセル
          </button>
          <button
            className="cursor-pointer flex items-center justify-center w-full max-w-[150px] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
            onClick={submitQuestionHandle}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner />
            ) : (
              <>
                <LiaSave size={23} /> 保存する
              </>
            )}
          </button>
        </div>
      ) : (
        <></>
      )}

      <DropHandler />
    </div>
  );
};

export default QuestionEditorHandler;
