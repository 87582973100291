import { BiSearchAlt2 } from "react-icons/bi";
import { FILTER } from "../../../../services/constants/admin/main";
import { ChangeEvent, useRef } from "react";

const ExamVenueFilter = ({
   changeHandle,
   deleteSelected,
}: {
   changeHandle: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
   deleteSelected: () => void;
}) => {
   const selectRef = useRef<HTMLSelectElement | null>(null);
   return (
      <div>
         <section className="mt-[40px] pb-[40px] border-secondary-light">
            <div className="flex h-[30px] gap-[8px] relative">
               <div className="relative">
                  <input
                     type="text"
                     name="query"
                     className="h-full max-w-[130px] pl-[12px] placeholder-[#BEBEBE] text-[11px] leading-[100%] border-[#E1E3E2] rounded-[5px] pr-[30px]"
                     placeholder="会場の検索"
                     onChange={changeHandle}
                  />
                  <BiSearchAlt2
                     className="absolute top-[5px] right-[9.26px] text-secondary-light"
                     size={22}
                  />
               </div>
               <div className="relative">
                  <select
                     name="limit"
                     className="h-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] leading-[100%] w-[95px]"
                     onChange={changeHandle}
                     ref={selectRef}
                  >
                     {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
                        <option value={item.value} className="" key={index}>
                           {item.label}
                        </option>
                     ))}
                  </select>
               </div>
               <div className="relative flex items-center">
                  <label className="ml-[3px] inline-flex items-center ">
                     <input
                        type="checkbox"
                        onChange={changeHandle}
                        className="form-checkbox text-primary border-[1px] border-[#EBE4D8] rounded-[3px]"
                     />
                     <span className="ml-2 mr-[5px] font-400 text-[12px] whitespace-nowrap">
                        非公開会場を表示
                     </span>
                  </label>
               </div>
               <div className="whitespace-nowrap text-[13px] font-[400] absolute right-1">
                  チェックした会場を
                  <button
                     className="ml-[8px] min-w-[60px] min-h-[30px] text-[14px] px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-[#FE6D73] text-white"
                     onClick={deleteSelected}
                  >
                     削除
                  </button>
               </div>
            </div>
         </section>
      </div>
   )
};

export default ExamVenueFilter;