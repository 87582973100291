import { ChangeEvent, useEffect, useState } from "react";
import LayoutWaitingApi from "../../../commons/layouts/LayoutWaitingAPI";
import { API } from "../../../../services/constants/route/api";
import { makeRequest } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { swalClose } from "../../../../services/helpers/swal";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { isEmptyObj } from "../../../../services/helpers/etc";
import { useNavigate, useParams } from "react-router-dom";
import ExamResultListFilter from "./ExamResultListFilter";
import ExamResultListTable from "./ExamResultListTable";
import { useExamResultListContext } from "../../../../context/Admin/ExamResultListContext";
import { CandidateExamHistoryResult } from "../../../../types/admin/candidateExamHistory";

type FilterType = {
  search: string;
  limit: number;
  page: number;
};

const ResultList = () => {
  const { searchParamsObject } = useObjectRoutes();
  const { exam_id } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    search: "",
    page: 1,
  });
  const {
    waitingAPI,
    candidateExamHistories,
    setWaitingAPI,
    setTotalPages,
    setExaminationVenueInfo,
    setCandidateExamHistories,
  } = useExamResultListContext();
  const [_candidateExamHistories, _setCandidateExamHistories] = useState<
    CandidateExamHistoryResult[]
  >([]);

  const resetTable = () => {
    setCurrentPage(1);
  };

  const changeHandle = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };

  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setWaitingAPI(true);
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  useEffect(() => {
    const numberRegex = /^[0-9]*$/;
    if (exam_id == null || exam_id == undefined || !numberRegex.test(exam_id)) {
      navigate("/404");
    }
  }, [exam_id]);

  useEffect(() => {
    _setCandidateExamHistories(candidateExamHistories);
  }, [candidateExamHistories]);

  useEffect(() => {
    const fetch = async () => {
      setWaitingAPI(true);
      let endpoint = `${
        API.ADMIN_EXAM_RESULT.LIST_EXAM_RESULT
      }?examination_id=${exam_id}&${paramizeObject(filter)}`;

      if (!isEmptyObj(searchParamsObject)) {
        endpoint += `&${paramizeObject(searchParamsObject)}`;
      }

      const result = await makeRequest({
        method: "get",
        url: endpoint,
      });

      setWaitingAPI(false);

      if (!result.status) {
        navigate("/404");
      }

      const responses = result.data;

      setCandidateExamHistories(responses?.candidate_exam_histories || []);
      setTotalPages(responses?.total_page || 1);
      setExaminationVenueInfo({
        exam_name: responses.exam_name || "-",
        exam_type_name: responses.exam_type_name || "-",
        exam_venue_name: responses.exam_venue_name || "オンライン",
      });
      swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);

    return () => clearTimeout(timer);
  }, [filter]);

  return (
    <div>
      {/* Filter */}
      <ExamResultListFilter changeHandle={changeHandle} />
      <LayoutWaitingApi waitingApi={waitingAPI} className="w-full h-[100px]">
        <ExamResultListTable
          _examResults={_candidateExamHistories}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </LayoutWaitingApi>
    </div>
  );
};

export default ResultList;
