import React, { useRef, useState } from "react";
import { HiOutlineUserCircle } from "react-icons/hi";
import { SlCamera } from "react-icons/sl";
import { useAuthContext } from "../../../context/AuthContext";
import { API } from "../../../services/constants/route/api";
import { request } from "../../../services/axios/axios";

const SidebarAvatar = () => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  let { setUser, user } = useAuthContext();
  const [avatar, setAvatar] = useState<File | null>(null);

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!file) return;
    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024; // Size in MB

    // Check file type
    if (!fileType.startsWith("image/")) {
      return;
    }
    // Check file size
    if (fileSize > 2) {
      return;
    }
    await request.post(
      API.PROFILE.UPDATE_AVATAR,
      { avatar: file },
      (userData) => {
        setUser(userData || {});
      },
      () => {},
      { withSuccess: true, withLoading: true, hasFileRequest: true }
    );
  };
  return (
    <div className="w-full pb-[25px] border-b border-danger-light mb-[15px]">
      {avatar || user?.avatar_url ? (
        <div className="flex items-center justify-center mb-[10px]">
          <img
            className="w-[80px] h-[80px] object-cover border-gray-200 cursor-pointer"
            src={avatar ? URL.createObjectURL(avatar) : user?.avatar_url}
            alt="Profile Avatar"
            onClick={() => inputFileRef.current?.click()}
          />
        </div>
      ) : (
        <>
          <div
            className="w-full flex justify-center cursor-pointer"
            onClick={() => inputFileRef.current?.click()}
          >
            <div className="relative w-full max-w-[80px] h-full min-h-[80px] mb-[10px]">
              <div className="bg-secondary-morelighter w-full h-full flex justify-center items-center absolute top-0 left-0">
                <HiOutlineUserCircle color="#EBE4D8" size={55} />
              </div>

              <div className="w-[19px] h-[15px] bg-secondary-light flex justify-center items-center absolute bottom-0 right-0">
                <SlCamera color="#FAF7F0" size={11.46} />
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className="w-full flex justify-center cursor-pointer"
        onClick={() => inputFileRef.current?.click()}
      >
        <div className="font-[500] text-[11px] leading-[11px] text-center text-white w-full max-w-[145px] rounded-[5px] bg-primary pt-[5px] py-[7px]">
          保存する
        </div>
      </div>
      <input
        type="file"
        id="image"
        name="image"
        accept="image/*"
        className="hidden"
        ref={inputFileRef}
        onChange={handleAvatarChange}
        placeholder="ファイルを選択"
      />
    </div>
  );
};

export default SidebarAvatar;
