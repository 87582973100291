import React, { ChangeEvent } from "react";
import { TestTakerUploadProblemTest } from "../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import UploadQuestionFileItem from "../QuestionComponent/UploadQuestionFileItem";

type UploadQuestionProps = {
  question: TestTakerUploadProblemTest;
  numberQuestion: number;
  handleFillScoreAnswer: (e: ChangeEvent<HTMLInputElement>) => void;
};

const UploadQuestion = ({
  question,
  numberQuestion,
  handleFillScoreAnswer,
}: UploadQuestionProps) => {
  return (
    <div className="w-full flex items-stretch mb-[30px]">
      <div className="w-[40px]">
        <div className="w-full h-full flex items-center justify-center bg-warning-lighter">
          <span>{numberQuestion}</span>
        </div>
      </div>
      <div className="w-[calc(100%-40px)] flex items-center gap-x-[67px] border-b border-b-danger-light py-[10px]">
        <div className="flex-1">
          {question.answer_data?.map((answer) => (
            <UploadQuestionFileItem key={answer?.id || 0} answer={answer} />
          ))}
        </div>
        <div className="w-[60px] flex items-center gap-x-[5px]">
          <input
            type="text"
            name="score"
            className="h-[30px] !px-0 text-center font-[500] placeholder:text-secondary-light text-[14px]"
            value={question?.score ? question.score : ""}
            onChange={handleFillScoreAnswer}
          />
          <span className="text-[14px] font-[500]">/{question.point || 0}</span>
        </div>
      </div>
    </div>
  );
};

export default UploadQuestion;
