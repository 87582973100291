import React from "react";
import { BiEditAlt } from "react-icons/bi";
import { NAME_ACTION_DETAIL_EXAM } from "../../../../services/constants/admin/pages/exam";
import { useListExamContext } from "../../../../context/Exam/ListExamContext";
import { SlEye } from "react-icons/sl";

const DetailAction = () => {
  const actionData = [
    {
      icon: <BiEditAlt />,
      label: "編集",
      actionName: NAME_ACTION_DETAIL_EXAM.EDIT,
    },
    {
      icon: <SlEye />,
      label: "プレビュー",
      actionName: NAME_ACTION_DETAIL_EXAM.PREVIEW,
    },
    {
      icon: <BiEditAlt />,
      label: "オーサリング",
      actionName: NAME_ACTION_DETAIL_EXAM.AUTHORING,
    },
  ];

  const { detailAcionName, setDetailActionName } = useListExamContext();

  return (
    <div className="flex mb-[20px]">
      {actionData.map((action, index) => (
        <div
          key={index}
          className={`cursor-pointer hover:bg-primary hover:text-white flex items-center gap-[5px] text-[20px] w-full max-w-[120px] h-0 min-h-[50px] border border-success-extralight ${
            detailAcionName === action.actionName
              ? "bg-primary text-white"
              : "bg-success-lighter"
          }`}
          onClick={() => setDetailActionName(action.actionName)}
        >
          <div className="pl-[10px]">{action.icon}</div>
          <div className="text-[12px] font-[500] leading-[16px] tracking-[0.5px]">
            {action.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetailAction;
