import React from 'react';

const Question = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      fill="none"
      viewBox="0 0 18 22"
      {...props}
    >
      <path
        fill={props.color || '#BEBEBE'}
        fillRule="evenodd"
        d="M1.75 20.25V1.75h10.5V4.4c0 .746.604 1.35 1.35 1.35h2.65v14.5H1.75zm11.502-20l.053.001.049-.001c.292 0 .573.116.78.323l.072.072 3.149 3.149.072.072a1.104 1.104 0 01.322.83l.001.053V20.4a1.35 1.35 0 01-1.35 1.35H1.6A1.35 1.35 0 01.25 20.4V1.6C.25.854.854.25 1.6.25h11.652zM5 8.25a.75.75 0 000 1.5h8a.75.75 0 000-1.5H5zM4.25 17a.75.75 0 01.75-.75h8a.75.75 0 010 1.5H5a.75.75 0 01-.75-.75zM5 12.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5H5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Question;
