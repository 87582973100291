import { ChangeEvent, useEffect, useState } from "react";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import { isUsableArr } from "../../../../../services/helpers/etc";
import {
  ChoiceIssue,
  ChoiceProblemTest,
} from "../../../../../types/admin/question";
import {
  generateListStyleChoice,
  isListBoxStyle,
} from "../../../../../services/utils/admin/question";
import AuthoringTextEditor from "../../../../admin/Question/AuthoringQuestion/QuestionEditorHandler/AuthoringTextEditor";
import { useExaminationTestContext } from "../../../../../context/ExaminationTestContext";
import { candidateToolIsOpen } from "../../../../../services/utils/candidate/examinationTest";
import { SETTING_PROPERTY_NAMES } from "../../../../../services/constants/admin/pages/exam";
import { swalError } from "../../../../../services/helpers/swal";
import classNames from "classnames";

const ChoiceQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled,
}: {
  questionProblem: ChoiceProblemTest;
  handleUpdateProblem: (QuestionProblem: ChoiceProblemTest) => void;
  isDisabled?: boolean;
}) => {
  const { questionProblems, setQuestionProblems, openingTools } =
    useExaminationTestContext();
  const [editingData, setEditingData] = useState<ChoiceProblemTest>(
    questionProblem as ChoiceProblemTest
  );

  const checkSelectedChoiceQuestion = (problem: ChoiceProblemTest) => {
    if (problem.max_choice && problem.max_choice > 0) {
      let answerTimes = 0;
      problem.answer_data?.forEach((answer) => {
        if (answer.checked) {
          answerTimes += 1;
        }
      });

      if (answerTimes >= problem.max_choice) {
        throw new Error(
          `最大${problem.max_choice}個の選択肢を選択することができます。`
        );
      }
    }
  };

  const handleCheck = (
    e: ChangeEvent<HTMLInputElement>,
    id: ChoiceIssue["id"]
  ) => {
    try {
      const { type, checked } = e.target;
      if (type === "checkbox") {
        const answerDataCheck = () => {
          return editingData.answer_data?.map((answerData) => {
            if (answerData.id === id) {
              return { ...answerData, checked: checked };
            }

            return answerData;
          });
        };
        checkSelectedChoiceQuestion(editingData);
        setEditingData({ ...editingData, answer_data: answerDataCheck() });
        handleUpdateProblem({ ...editingData, answer_data: answerDataCheck() });
      }
    } catch (error) {
      swalError((error as Error).message);
    }
  };

  const handleMouseUp = () => {
    if (
      !candidateToolIsOpen(SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT, openingTools)
    )
      return;

    const selector = window.getSelection();
    const textSelected = selector?.toString();
    if (!textSelected) return;
    const span = document.createElement("span");
    span.setAttribute("style", "background-color: yellow; display:inline;");
    span.innerText = textSelected;
    const newText = questionProblem.title?.replace(
      textSelected,
      span.outerHTML
    );

    setQuestionProblems(
      questionProblems.map((question) => {
        if (question.id === questionProblem.id) {
          return { ...question, title: newText };
        }

        return question;
      })
    );
  };

  useEffect(() => {
    if (!questionProblem) return;
    setEditingData(questionProblem);
  }, [questionProblem]);

  useEffect(() => {
    if (!editingData) return;

    if (editingData.answer_data && editingData.answer_data.length) {
      return;
    }

    if (editingData.issues && editingData.issues.length) {
      let answerData: ChoiceProblemTest["answer_data"] = [];

      editingData.issues.forEach((issue) => {
        answerData?.push({
          id: issue.id,
          label: issue.label,
          checked: false,
        });
      });

      setEditingData({ ...editingData, answer_data: answerData });
    }
  }, [editingData]);

  return (
    <div className="form_authoring">
      <div
        className="p-[10px]"
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(questionProblem.title),
        }}
        onMouseUp={handleMouseUp}
      ></div>
      <div className="">
        <div className="bg-white pt-[10px] rounded-b-[10px]">
          <ul
            className={`min-h-[100px] pt-[10px] pb-[30px] px-[10px] ${generateListStyleChoice(
              editingData?.list_style
            )} ${!editingData?.is_vertical_arrange ? "flex flex-wrap" : ""}`}
          >
            {isUsableArr(editingData.answer_data) &&
              editingData.answer_data &&
              editingData.answer_data.map((issue, index) => (
                <div
                  className="mb-[15px] flex items-center gap-[10px] px-[10px]"
                  key={index}
                >
                  <div
                    className={`flex items-center ${
                      !isListBoxStyle(editingData?.list_style)
                        ? "mr-[25px]"
                        : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      name={`issue_data_checked_${index}`}
                      id=""
                      className=""
                      onChange={(e) => handleCheck(e, issue.id)}
                      checked={issue.checked}
                      disabled={isDisabled}
                    />
                  </div>
                  <li
                    className={classNames("w-[80%]", {
                      "!w-[150px]": !editingData?.is_vertical_arrange,
                      "bg-secondary-light pointer-events-none":
                        editingData?.is_excluded && issue.checked,
                    })}
                    dangerouslySetInnerHTML={{
                      __html: decodeHTMLEntities(issue.label || "<div></div"),
                    }}
                  >
                  </li>
                </div>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChoiceQuestion;
