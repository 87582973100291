import * as React from 'react';

const Audio = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      style={{
        fill: 'currentcolor',
      }}
      d="M21.75 5.25A.75.75 0 0121 6h-1.5v1.5a.75.75 0 11-1.5 0V6h-1.5a.75.75 0 110-1.5H18V3a.75.75 0 111.5 0v1.5H21a.75.75 0 01.75.75zm-2.25 5.175a.75.75 0 00-.75.75v2.147a3.374 3.374 0 101.5 2.803v-4.95a.75.75 0 00-.75-.75zm-4.275-1.097a.74.74 0 00.41-.872.779.779 0 00-.185-.337.844.844 0 00-.244-.16 3.028 3.028 0 01-1.678-3.15.74.74 0 00-.524-.853.76.76 0 00-.395-.01L7.322 5.27A.75.75 0 006.75 6v10.322a3.375 3.375 0 101.5 2.803v-8.044l6.825-1.706.15-.047z"
    ></path>
  </svg>
);
export default Audio;
