import { CSSProperties } from "react";
import { Event } from "../../types/candidate/event";
import { SCHEDULE_EVENT_TYPE } from "../constants/candidate/schedule";

/**
 * @deprecated since phase 2.0
 */

const getScheduleEventTitleColor = (
  type: Event["type"]
): CSSProperties["color"] => {
  switch (type) {
    case SCHEDULE_EVENT_TYPE.ASSESSMENT:
      return "#FA646A";

    default:
      return "#fff";
  }
};

/**
 * @deprecated since phase 2.0
 */

const getScheduleEventContentColor = (
  type: Event["type"]
): CSSProperties["color"] => {
  switch (type) {
    case SCHEDULE_EVENT_TYPE.ASSESSMENT:
      return "#FA646A";

    default:
      return "#7A7A7A";
  }
};

/**
 * @deprecated since phase 2.0
 */

const getScheduleEventLabelColor = (
  type: Event["type"]
): CSSProperties["color"] => {
  switch (type) {
    case SCHEDULE_EVENT_TYPE.ASSESSMENT:
      return "#FFEBEB";

    default:
      return "#227C9D";
  }
};

const removeECharacter = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'e' || e.key === 'E') {
     e.preventDefault();
  }
};

export {
  getScheduleEventTitleColor,
  getScheduleEventContentColor,
  getScheduleEventLabelColor,
  removeECharacter,
};
