import React, { ChangeEvent } from "react";
import InformationDetail from "./InformationDetail";

type ItemSettingInputProps = {
  label: string;
  messageDetail?: string;
  classNameLabel?: string;
  error?: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
} & React.HTMLProps<HTMLInputElement>;

const ItemSettingInput = ({
  label,
  messageDetail,
  classNameLabel = "basis-[51%]",
  error = "",
  handleChange,
  ...props
}: ItemSettingInputProps) => {
  return (
    <div className=" pb-[15px] ">
      <div className="flex justify-between items-center gap-[15px] pb-[5px]">
        <div className="flex w-full gap-[15px] items-center">
          <label
            htmlFor={props?.name || ""}
            className={`text-[14px] cursor-pointer ${classNameLabel}`}
          >
            {label}
          </label>
          <input id={props?.name || ""} {...props} onChange={handleChange} />
        </div>
        <InformationDetail text={messageDetail} />
      </div>
      <p className="text-[12px] text-danger">{error}</p>
    </div>
  );
};

export default ItemSettingInput;