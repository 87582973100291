/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import { LiaSave } from 'react-icons/lia';
import { PiTrash } from 'react-icons/pi';
import AuthoringTextEditor from '../../AuthoringTextEditor';
import {
  EditingProblemStatus,
  TextBlockProblem,
  WritingProblem,
} from '../../../../../../../types/admin/question';
import { swalConfirm, swalError, swalSuccess } from '../../../../../../../services/helpers/swal';
import { RenderDroppedQuestionTypeEditProps } from '../../DroppedQuestionType';
import { useAuthoringQuestionContext } from '../../../../../../../context/Question/AuthoringQuestionContext';
import { filterUniqueArrayByKey } from '../../../../../../../services/helpers/parseData';
import { htmlToString, countRows } from '../../../../../../../services/utils/admin/writingQuestion';

type TextBlockQuestionEditProps = {
  problemEdit?: WritingProblem;
} & Omit<RenderDroppedQuestionTypeEditProps, 'draggedName' | 'problemEdit'>;

const TextBlockQuestionEdit = ({ problemEdit }: TextBlockQuestionEditProps) => {
  const [editingNode, setEditingNode] = useState('');
  const [editingData, setEditingData] = useState<WritingProblem>(problemEdit || {});
  const [errors, setErrors] = useState<string[]>([]);
  const {
    authoringData,
    setAuthoringData,
    editingProblemStatuses,
    setEditingProblemStatuses,
    setSelectedProblem,
    setIsEditingProblem
  } = useAuthoringQuestionContext();

  //On change handle for text editor only
  const changeEditorHandle = useCallback(
    (identify: string, content: string) => {
      if (content === '<p><br></p>' || !editingData || identify !== editingNode) {
        return;
      }

      if (identify === 'title' || editingNode === 'title') {
        setEditingData((editingData) => ({ ...editingData, title: content }));
        return;
      }
      if (identify === 'answerValue' || editingNode === 'answerValue') {
        setEditingData({ ...editingData, answerValue: content });
        return;
      }
    },

    [editingNode],
  );

  const removeHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    swalConfirm(() => {
      setIsEditingProblem(false);
      setAuthoringData({
        ...authoringData,
        problems: authoringData.problems?.filter(
          (problem) => Number(problem.id) !== Number(editingData.id),
        ),
      });
    });
  };

  const hideEditorHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);
    const newEditingProblemStatus: EditingProblemStatus = {
      id: editingData.id || 0,
      status: false,
    };
    const newEditingProblemStatuses = [...editingProblemStatuses, newEditingProblemStatus];
    setEditingProblemStatuses(filterUniqueArrayByKey(newEditingProblemStatuses.reverse()));
    setSelectedProblem();
  };

  const submitEditingDataHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);

    let answerValueErrors = [];
    const textRaw = htmlToString(editingData?.answerValue!);
    if (textRaw.length > Number(editingData?.charactersAmount)) {
      answerValueErrors.push(`文字数が ${editingData?.charactersAmount} 文字の制限を超えています`);
    }
    const rowAmount = countRows(editingData?.answerValue!);
    if (rowAmount > Number(editingData?.rowsAmount)) {
      answerValueErrors.push(`行数は ${editingData?.rowsAmount} 行を超えないでください`);
    }
    setErrors(answerValueErrors);
    if (answerValueErrors.length) {
      setErrors(answerValueErrors);
      swalError();
      return;
    }

    const finalEditingData = {
      ...editingData,
      updated_at: new Date().getTime(),
    };

    const problemIndexed =
      authoringData.problems?.findIndex((problem) => problem.id === finalEditingData.id) || 0;

    let newProblems: TextBlockProblem[] = authoringData.problems || [];
    newProblems.splice(problemIndexed, 1, finalEditingData);

    setAuthoringData({ problems: newProblems });
    swalSuccess();
    setSelectedProblem();
  };

  return (
    <form>
      <div className="mb-[10px] bg-secondary-extralight min-h-[450px] w-full p-[20px] relative">
        <div className="mb-[30px]"></div>
        <div className="flex gap-[13px]">
          <div className="w-[80%]">
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設問内容
              </div>
              <div className="w-full bg-white rounded-b-[10px]">
                <div className="p-[10px]" onClick={() => setEditingNode('title')}>
                  <AuthoringTextEditor
                    className={'w-full h-[200px]'}
                    toolbarId={`letter_editor`}
                    isReadOnly={editingNode !== 'title' && editingNode !== ''}
                    changeHandler={(htmlContent) => changeEditorHandle('title', htmlContent)}
                    value={editingData?.title || ''}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-[20%] flex flex-col gap-y-[15px]">
            <div className="text-white">
              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
                  onClick={submitEditingDataHandle}
                >
                  <LiaSave size={23} /> 保存
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={hideEditorHandle}
                >
                  キャンセル
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer gap-[5px] flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={removeHandle}
                >
                  <PiTrash size={20} />
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TextBlockQuestionEdit;
