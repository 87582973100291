import { SidebarChildItem, SidebarItem } from '../../../types/admin/main';
import {
  PATH_ADMIN_FOLDER,
  PATH_ADMIN_GROUP,
  PATH_ADMIN_CANDIDATE,
  PATH_ADMIN_QUESTION,
  PATH_ADMIN_EXAM,
  PATH_ADMIN_HOME,
  PATH_ADMIN_EXAM_VENUE,
  PATH_ADMIN_EXAMINATION,
  PATH_ADMIN_EXAM_PARTICIPATE,
  PATH_ADMIN_EXAM_RESULT,
} from '../route/router';
import { GROUP_TAB_ITEM } from './pages/group';
import Candidate from '../../../components/commons/icons/Sidebar/Candidate';
import Resource from '../../../components/commons/icons/Sidebar/Resource';
// import Home from '../../../components/commons/icons/Sidebar/Home';
import Group from '../../../components/commons/icons/Sidebar/Group';
import { FOLDER_TAB_ITEM } from './pages/folder';
import { CANDIDATE_TAB_ITEM } from './pages/candidate';
import Question from '../../../components/commons/icons/Sidebar/Question';
import { EXAM_TAB_ITEM } from './pages/exam';
import Exam from '../../../components/commons/icons/Sidebar/Exam';
import Clipping from '../../../components/commons/icons/Sidebar/Clipping';
import Home from '../../../components/commons/icons/Sidebar/Home';
import { EXAM_MANAGEMENT_TAB_ITEM } from './pages/examination';
import Report from '../../../components/commons/icons/Sidebar/Report';
import { EXAM_PARTICIPATE_TAB_ITEM } from './pages/examParticipate';
import ELearning from '../../../components/commons/icons/Sidebar/ELearning';
import { EXAM_RESULT_TAB_ITEM } from './pages/examResult';

const child = (href: SidebarChildItem['href'], label: SidebarChildItem['label']) => ({
  href: href,
  label: label,
});

const item = (
  label: SidebarItem['label'],
  href: SidebarItem['href'],
  icon: SidebarItem['icon'],
  ...childs: SidebarItem['childs']
) => ({
  label: label,
  href: href,
  icon: icon,
  childs: childs.map(({ href, label }: SidebarChildItem) => child(href, label)),
});

const ADMIN_SIDEBAR_DATA = [
  // Sidebar Items hidden for later
  // item(
  //   'ホーム',
  //   PATH_ADMIN_HOME.DEFAULT,
  //   Home,
  // ),
  item(
    '受験者管理',
    '',
    Candidate,
    child(PATH_ADMIN_CANDIDATE.DEFAULT, CANDIDATE_TAB_ITEM.CANDIDATE_LIST),
    child(PATH_ADMIN_CANDIDATE.CREATE, CANDIDATE_TAB_ITEM.CANDIDATE_CREATE),
  ),
  item(
    'グループ管理',
    '',
    Group,
    child(PATH_ADMIN_GROUP.DEFAULT, GROUP_TAB_ITEM.LIST),
    child(PATH_ADMIN_GROUP.MANAGEMENT, GROUP_TAB_ITEM.MANAGEMENT),
    // child(PATH_ADMIN_GROUP.SETTING, GROUP_TAB_ITEM.SETTING),
  ),
  item(
    '問題作成',
    '',
    Question,
    child(PATH_ADMIN_QUESTION.DEFAULT, '問題一覧'),
    child(PATH_ADMIN_QUESTION.CREATE, '問題新規追加'),
  ),
  item(
    'ファイル管理',
    '',
    Resource,
    child(PATH_ADMIN_FOLDER.FOLDER, FOLDER_TAB_ITEM.FOLDER_LIST),
    child(PATH_ADMIN_FOLDER.FILE, FOLDER_TAB_ITEM.FILE_LIST),
  ),
  item(
    'テスト作成',
    '',
    Exam,
    child(PATH_ADMIN_EXAM.DEFAULT, EXAM_TAB_ITEM.EXAM_LIST),
    child(PATH_ADMIN_EXAM.CREATE, EXAM_TAB_ITEM.CREATE_NEW),
  ),
  item(
    '試験管理',
    '',
    Clipping,
    child(PATH_ADMIN_EXAMINATION.LIST, EXAM_MANAGEMENT_TAB_ITEM.LIST_EXAMINATION),
    child(PATH_ADMIN_EXAMINATION.CREATE, EXAM_MANAGEMENT_TAB_ITEM.ADD_NEW_EXAMINATION),
    child(PATH_ADMIN_EXAM_VENUE.VENUE_LIST, EXAM_MANAGEMENT_TAB_ITEM.VENUE_LIST),
    child(PATH_ADMIN_EXAM_VENUE.ADD_NEW_VENUE, EXAM_MANAGEMENT_TAB_ITEM.ADD_NEW_VENUE),
  ),
  item(
    '受験管理',
    '',
    ELearning,
    child(PATH_ADMIN_EXAM_PARTICIPATE.LIST, EXAM_PARTICIPATE_TAB_ITEM.LIST_EXAM_PARTICIPATE)
  ),
  item(
    '結果',
    '',
    Report,
    child(PATH_ADMIN_EXAM_RESULT.EXAM_LIST, EXAM_RESULT_TAB_ITEM.EXAM_LIST)
  )
  // item(
  //   '教材管理',
  //   Material,
  //   child(PATH_ADMIN_MATERIAL.DEFAULT, '教材一覧'),
  //   child(PATH_ADMIN_MATERIAL.UNIT_UPSERT, ' '),
  // ),
  // Sidebar Items hidden for later phases
  // item(
  //   'お知らせ',
  //   child('/#', 'お知らせ一覧'),
  //   child('/#', 'お知らせ新規作成'),
  //   child('/#', 'テンプレート一覧'),
  // ),
  // item(
  //   'スケジュール管理',
  //   child('/#', 'テキストダミーテキスト'),
  //   child('/#', 'テキストダミーテキスト'),
  //   child('/#', 'テキストダミーテキスト'),
  // ),
  // item(
  //   '各種設定',
  //   child('/#', 'ログインページ'),
  //   child('/#', '受講者画面設定'),
  //   child('/#', 'プロフィール設定'),
  //   child('/#', 'ローケル設定'),
  //   child('/#', 'IP制御'),
  //   child('/#', 'コンバージョン計測'),
  //   child('/#', 'ご利用規約設定'),
  // ),
  // item(
  //   'ヘルプ',
  //   child('/#', 'お知らせ'),
  //   child('/#', 'よくある質問'),
  //   child('/#', '各種マニュアル・テンプレート'),
  //   child('/#', 'マナビテへのお問い合わせ'),
  // ),
];

export { ADMIN_SIDEBAR_DATA };
