import React from "react";
import ExamBase from ".";
import ListTestProvider from "../../../context/ListTestContext";
import CreateExamination from "../../../components/admin/Examination/Upsert/Create";

const DetailExaminationPage = () => {
  return (
    <ExamBase title="試験管理 ｜試験追加">
      <ListTestProvider>
        <CreateExamination/>
      </ListTestProvider>
    </ExamBase>
  );
};

export default DetailExaminationPage;
