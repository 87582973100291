import React from "react";
import ExamResultBase from ".";
import ResultList from "../../../components/admin/ExamResult/ResultList";
import ExamResultListProvider from "../../../context/Admin/ExamResultListContext";

const ExamResultListPage = () => {
  return (
    <ExamResultListProvider>
      <ExamResultBase title="結果管理  ｜ 受験者一覧">
        <ResultList />
      </ExamResultBase>
    </ExamResultListProvider>
  );
};

export default ExamResultListPage;
