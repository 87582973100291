import { useEffect, useState } from "react";
import ItemExaminationRegisterInfo from "../RegisterExamination/RegisterExaminationComponent/ItemExaminationRegisterInfo";
import { PAYMENT_STATUS } from "../../../services/constants/candidate/payment";
import {
  PAYMENT_TYPE,
  TAX,
  TYPE_DATE_FORMAT,
  WEEKDAYS_ORDER,
} from "../../../services/constants/globals";
import { EXAMINATION_FORMAT } from "../../../services/constants/admin/pages/examination";
import {
  decodeHTMLEntities,
  formatNumberWithCommas,
} from "../../../services/helpers/parseData";
import { useStorage } from "../../../hooks/useStorage";
import { User } from "../../../types/user";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { Link, useNavigate } from "react-router-dom";
import { Examination } from "../../../types/admin/examination";
import {
  ExaminationVenueType,
  RegisterExaminationDetailType,
} from "../../../types/candidate/registerExamination";
import { isEmptyObj } from "../../../services/helpers/etc";
import {
  PATH_CANDIDATE_HOME,
  PATH_EXAMINATION,
} from "../../../services/constants/route/router";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { formatDateTime } from "../../../services/helpers/formatTime";
import {
  formatRemainingTime,
  getTimeBeforeEntryTime,
} from "../../../services/utils/candidate/registerExamination";
import classNames from "classnames";
import dayjs from "dayjs";
import LayoutWaitingApi from "../../commons/layouts/LayoutWaitingAPI";
import {
  EXAMINATION_TIME_STATUS,
  EXAMINATION_TIME_STATUS_NAME,
} from "../../../services/constants/candidate/home";
import { STATUS_EXAM_TEST_ITEM } from "../../../services/constants/candidate/examinationTest";
import ExaminationImageDefault from "../../commons/icons/ExaminationTest/ExaminationImageDefault";

type ButtonTimeType = {
  time: string;
  status: number;
};

const RegisterExaminationView = () => {
  const { getLocalStorage } = useStorage();
  const user: User = getLocalStorage("user");
  const { getParamValue } = useObjectRoutes();
  const [examination, setExamination] = useState<Examination>({});
  const [examinationVenue, setExaminationVenue] =
    useState<ExaminationVenueType>({});
  const [registerExamination, setRegisterExamination] =
    useState<RegisterExaminationDetailType>({});
  const [buttonTime, setButtonTime] = useState<ButtonTimeType>({
    status: EXAMINATION_TIME_STATUS.END,
    time: "",
  });
  const [isWaitingAPI, setIsWaitngAPI] = useState<boolean>(false);
  const compareDate = dayjs(registerExamination.exam_full_date);
  const navigate = useNavigate();

  const redirectEnterCodeView = () => {
    if (!isEmptyObj(buttonTime) && buttonTime.status === 1) {
      let url = "";

      if (registerExamination.exam_format === EXAMINATION_FORMAT.CBT) {
        url = `${PATH_EXAMINATION.ENTER_CODE}?id=${registerExamination.id}`;
      }

      if (registerExamination.exam_format === EXAMINATION_FORMAT.IBT) {
        url = `${PATH_EXAMINATION.EXAMINATION_PART_LIST}?id=${registerExamination.id}`;
      }

      navigate(url);
    }
  };

  const fetchRegisterExamination = async () => {
    const examinationId = getParamValue("id");
    if (!isEmptyObj(registerExamination) || !examinationId) return;

    setIsWaitngAPI(true);
    await request.get(
      `${API.REGISTER_EXAMINATION.GET_DETAIL}?register_examination_id=${examinationId}`,
      (data: RegisterExaminationDetailType) => {
        setRegisterExamination(data);

        if (data.examination && !isEmptyObj(data.examination)) {
          setExamination(data.examination);
        }

        if (data.exam_venue && !isEmptyObj(data.exam_venue)) {
          setExaminationVenue(data.exam_venue);
        }
      },
      (error) => {
        if (error) {
          navigate("/404");
        }
      }
    );
    setIsWaitngAPI(false);
  };

  const handleShowButtonTime = () => {
    switch (registerExamination.payment_status) {
      case PAYMENT_STATUS.WAITTING_PAID:
        return (
          <button
            className="w-[150px] py-[10px] rounded-[5px] text-white text-center text-[16px] font-[600] leading-[24px] bg-warning"
            disabled={true}
          >
            決済待ち中
          </button>
        );
      case PAYMENT_STATUS.FAILED:
        return (
          <button
            className="w-[150px] py-[10px] text-secondary-dark border border-secondary-light rounded-[5px] text-center text-[16px] font-[600] leading-[24px]"
            disabled={true}
          >
            決済失敗
          </button>
        );
      case PAYMENT_STATUS.PAID:
        return (
          <>
            {!!buttonTime.time ? (
              <button
                className={classNames(
                  `w-[150px] h-[40px] py-[8px] rounded-[5px] text-white text-center text-[16px] font-[600] leading-[24px] animate-[show_0.5s_ease-in-out]`,
                  {
                    "bg-secondary-light": buttonTime.status === 0,
                    "bg-danger": buttonTime.status === 1,
                    "bg-[#8BC0D3]": buttonTime.status === 2,
                  }
                )}
                onClick={redirectEnterCodeView}
                disabled={buttonTime.status !== EXAMINATION_TIME_STATUS.START}
              >
                {buttonTime.time}
              </button>
            ) : (
              <div className="w-5 h-5 border-2 border-secondary-dark border-t-2 border-t-transparent rounded-full animate-spin"></div>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (isEmptyObj(registerExamination)) return;
    setIsWaitngAPI(false);

    if (registerExamination.exam_status === STATUS_EXAM_TEST_ITEM.DONE) {
      setButtonTime({
        status: EXAMINATION_TIME_STATUS.END,
        time: EXAMINATION_TIME_STATUS_NAME.END,
      });
    } else {
      const intervalId = setInterval(() => {
        const now = dayjs();
        const difference = compareDate.diff(now, "millisecond");
        formatRemainingTime(difference, registerExamination, setButtonTime);
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [registerExamination]);

  useEffect(() => {
    fetchRegisterExamination();
  }, []);

  return (
    <div className="bg-white w-full px-[30px] py-[50px] font-sans">
      <div className="max-w-[1000px] mx-auto">
        <LayoutWaitingApi
          waitingApi={isWaitingAPI}
          className="w-full h-[226px]"
        >
          <div className="w-full p-[30px] border border-secondary-light rounded-[10px] flex gap-x-[20px] mb-[20px] animate-[show_0.5s_ease-in-out]">
            <div className="w-[150px]">
              {registerExamination.examination?.image_path ? (
                <img
                  src={`${process.env.REACT_APP_AWS_S3_URL}/${registerExamination.examination?.image_path}`}
                  alt=""
                  className="w-full h-fit object-contain my-auto animate-[show_0.5s_ease-in-out]"
                />
              ) : (
                <ExaminationImageDefault width={150} height={150} />
              )}
            </div>
            <div className="flex-1">
              <p className="text-[18px] text-primary-darker leading-[24px] border-b border-b-secondary-light">
                {registerExamination.exam_name}｜
                {registerExamination.exam_type_name}（
                {registerExamination.exam_format === EXAMINATION_FORMAT.CBT
                  ? "テストセンターで"
                  : "オンライン"}
                ）
              </p>
              <div className="w-full flex justify-center mt-[20px]">
                {handleShowButtonTime()}
              </div>
            </div>
          </div>
        </LayoutWaitingApi>
        <LayoutWaitingApi
          waitingApi={isWaitingAPI}
          className="w-full h-[500px]"
        >
          <div className="border-y-[1px] w-full border-[#BEBEBE] flex flex-col animate-[show_0.5s_ease-in-out]">
            <ItemExaminationRegisterInfo title="受験者名">
              <>
                {user.first_name}　{user.last_name}
              </>
            </ItemExaminationRegisterInfo>
            <ItemExaminationRegisterInfo title="メールアドレス">
              <>{user.email}</>
            </ItemExaminationRegisterInfo>
            <ItemExaminationRegisterInfo title="試験">
              <>{registerExamination.exam_name}</>
            </ItemExaminationRegisterInfo>
            <ItemExaminationRegisterInfo title="試験名">
              <>
                {examination.type}{" "}
                {Number(registerExamination.time) > 0 &&
                  `(${registerExamination.time}分)`}
              </>
            </ItemExaminationRegisterInfo>
            <ItemExaminationRegisterInfo title="試験番号">
              <>{examination.code}</>
            </ItemExaminationRegisterInfo>
            <ItemExaminationRegisterInfo title="決済方法">
              <>{registerExamination.payment_method_type ? "" : "無料"}</>
              <>
                {registerExamination.payment_method_type ===
                  PAYMENT_TYPE.CARD && "クレジットカード"}
              </>
              <>
                {registerExamination.payment_method_type ===
                  PAYMENT_TYPE.KONBINI && "コンビニ"}
              </>
            </ItemExaminationRegisterInfo>
            {registerExamination.payment_expires_at && (
              <ItemExaminationRegisterInfo title="コンビニでの支払い期限">
                {formatDateTime(
                  registerExamination.payment_expires_at,
                  TYPE_DATE_FORMAT.FULL_TO_MINUTE_DEFAULT
                )}
              </ItemExaminationRegisterInfo>
            )}
            <ItemExaminationRegisterInfo title="試験料金（税込）">
              <>
                {examination.is_free
                  ? "0円"
                  : formatNumberWithCommas(
                      Math.floor(Number(examination.price) * TAX)
                    ) + "円"}
                {registerExamination.payment_method_type ===
                  PAYMENT_TYPE.KONBINI &&
                  (registerExamination.payment_status ===
                    PAYMENT_STATUS.UNPAID ||
                    registerExamination.payment_status ===
                      PAYMENT_STATUS.WAITTING_PAID) &&
                  "（決済待ち中）"}
              </>
            </ItemExaminationRegisterInfo>
            {registerExamination.exam_format === EXAMINATION_FORMAT.CBT ? (
              <ItemExaminationRegisterInfo title="会場">
                <div className="text-[16px]">
                  <p>{examinationVenue.name}</p>
                  <div className="py-[20px]">
                    {examinationVenue.postal_code?.first &&
                      examinationVenue.postal_code?.last && (
                        <p>
                          〒{examinationVenue.postal_code.first}-
                          {examinationVenue.postal_code.last}
                        </p>
                      )}
                    <p className="text-[14px] mb-[20px]">
                      {examinationVenue.address?.prefecture},{" "}
                      {examinationVenue.address?.city},{" "}
                      {examinationVenue.address?.area},{" "}
                      {examinationVenue.address?.other}
                    </p>
                  </div>
                </div>
              </ItemExaminationRegisterInfo>
            ) : (
              <></>
            )}
            <ItemExaminationRegisterInfo title="試験日">
              <span className="">
                {formatDateTime(
                  registerExamination?.exam_full_date || "",
                  TYPE_DATE_FORMAT.SHORT_DEFAULT
                )}{" "}
                (
                {
                  WEEKDAYS_ORDER[
                    dayjs(registerExamination?.exam_full_date).day()
                  ]
                }
                )
              </span>
            </ItemExaminationRegisterInfo>
            {registerExamination.exam_format === EXAMINATION_FORMAT.CBT && (
              <ItemExaminationRegisterInfo title="入室時間">
                <span className="">
                  {getTimeBeforeEntryTime(
                    dayjs(registerExamination.exam_full_date).format(
                      "HH:mm:ss"
                    ) || "",
                    examination.entry_time || 0
                  )}{" "}
                  ~ {dayjs(registerExamination.exam_full_date).format("HH:mm")}
                </span>
              </ItemExaminationRegisterInfo>
            )}
            <ItemExaminationRegisterInfo title="試験開始時間">
              <span className="">
                {dayjs(registerExamination.exam_full_date).format("HH:mm")}
              </span>
            </ItemExaminationRegisterInfo>
            {registerExamination.exam_format === EXAMINATION_FORMAT.CBT && (
              <ItemExaminationRegisterInfo title="地図">
                <div
                  className="text-left basis-[60%] max-w-[600px] max-h-[360px] overflow-hidden"
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(examinationVenue.map_embed),
                  }}
                ></div>
              </ItemExaminationRegisterInfo>
            )}
          </div>
          <div className="w-full flex justify-end items-center mt-[20px]">
            <Link
              to={PATH_CANDIDATE_HOME.DEFAULT}
              className="w-[140px] h-[40px] py-[8px] flex justify-center items-center gap-[10px] bg-[#E0E0E0] rounded-[5px] text-[16px]"
            >
              ホームへ戻る
            </Link>
          </div>
        </LayoutWaitingApi>
      </div>
    </div>
  );
};

export default RegisterExaminationView;
