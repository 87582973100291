/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { isEmptyObj, isUsableArr } from '../../../../../../../services/helpers/etc';
import { MatrixProblem, MatrixCoordinate } from '../../../../../../../types/admin/question';
import { RenderDroppedQuestionTypeProps } from '../../DroppedQuestionType';
import { getIssueById } from '../../../../../../../services/utils/admin/question';
import {
  decodeHTMLEntities,
  groupArrayByProperty,
} from '../../../../../../../services/helpers/parseData';

type MatrixQuestionProps = { problemPreview?: MatrixProblem; actionable?: boolean } & Omit<
  RenderDroppedQuestionTypeProps,
  'draggedName' | 'problemPreview'
>;

const MatrixQuestion = ({ problemPreview, actionable }: MatrixQuestionProps) => {
  const [editingData, setEditingData] = useState<MatrixProblem>(problemPreview || {});
  const currentIssues = editingData.issues || [];
  const currentCoordinates = editingData?.correct_coordinates || [];
  const currentCoordinatesByX = groupArrayByProperty(editingData.correct_coordinates || [], 'x');
  const coordinateKeyXs = Object.keys(currentCoordinatesByX);
  const currentCoordinatesByY = groupArrayByProperty(editingData.correct_coordinates || [], 'y');

  useEffect(() => {
    setEditingData(problemPreview || {});
  }, [problemPreview]);

  const matrixChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    coordinate: MatrixCoordinate,
  ) => {
    if (!e || !e.currentTarget || !actionable) return;
    const { name, value, checked, type } = e.currentTarget;
    let parsedValue: boolean | string | number = Number(value);

    if (type === 'checkbox') {
      parsedValue = checked;
    }

    const coordinateIndex = currentCoordinates.findIndex(
      (crCoordinate) => crCoordinate.x === coordinate.x && crCoordinate.y === coordinate.y,
    );
    const newCoordinate = {
      ...coordinate,
      [name]: parsedValue,
    };
    currentCoordinates.splice(coordinateIndex, 1, newCoordinate);

    setEditingData({
      ...editingData,
      correct_coordinates: currentCoordinates,
    });
  };

  return (
    <div className={`text-secondary-dark ${actionable ? '' : 'pointer-events-none'}`}>
      <div className="">
        <div
          dangerouslySetInnerHTML={{
            __html: decodeHTMLEntities(editingData?.title || ''),
          }}
        ></div>
      </div>
      <div className="justify-center items-center p-[20px] bg-white flex flex-col gap-[28px]">
        <div className="h-full px-[20px] py-[20px] w-full">
          <div className="justify-center items-start flex flex-col overflow-auto">
            <div className="flex bg-success-lighter border-r border-[#C2C2C2]">
              <div className="border-l border-t border-[#C2C2C2] min-w-[108px] min-h-[52px]"></div>
              {!isEmptyObj(currentCoordinatesByY) &&
                Object.keys(currentCoordinatesByY).map((coordinateYId) => (
                  <div
                    className="border-l border-t border-[#C2C2C2] w-[108px] min-h-[52px] p-[10px]"
                    key={`${coordinateYId}_th`}
                  >
                    <div
                      className="break-words"
                      dangerouslySetInnerHTML={{
                        __html: decodeHTMLEntities(
                          getIssueById(currentIssues, Number(coordinateYId))?.label,
                        ),
                      }}
                    ></div>
                  </div>
                ))}
            </div>
            <div className="">
              <div className="bg-success-lighter">
                <div className="flex">
                  <div className="">
                    {coordinateKeyXs.map((coordinateXId, coordinateXIdIndex, coordinateXIdArr) => (
                      <div key={`${coordinateXId}_td`} className="flex">
                        <div
                          className={`flex border-[#C2C2C2] ${
                            coordinateXIdIndex === coordinateXIdArr.length - 1 ? 'border-b' : ''
                          }`}
                        >
                          <div className="border-l border-t border-[#C2C2C2] w-[108px] min-h-[52px] p-[10px]">
                            <div
                              className="break-words"
                              dangerouslySetInnerHTML={{
                                __html: decodeHTMLEntities(
                                  getIssueById(currentIssues, Number(coordinateXId))?.label,
                                ),
                              }}
                            ></div>
                          </div>
                          <div className="flex">
                            {isUsableArr(currentCoordinates) &&
                              currentCoordinates
                                ?.filter(
                                  (coordinateY) => Number(coordinateY.x) === Number(coordinateXId),
                                )
                                ?.map((coordinateY) => (
                                  <div className="relative" key={coordinateY.id + 'y'}>
                                    <div className="pl-[15px] border-l border-t bg-white border-[#C2C2C2] min-w-[108px] h-full flex gap-[10px] items-center">
                                      <input
                                        type="checkbox"
                                        name="checked"
                                        id=""
                                        onChange={(e) => matrixChangeHandle(e, coordinateY)}
                                      />
                                    </div>
                                  </div>
                                ))}
                          </div>
                        </div>
                        <div className="text-primary-darker border-l border-[#C2C2C2] bg-white cursor-pointer flex items-center p-[10px]"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatrixQuestion;
