import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  SliderProblemTest,
} from "../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import { getLocationOfThumb } from "../../../../../services/utils/admin/sliderQuestion";
import { useExaminationTestContext } from "../../../../../context/ExaminationTestContext";
import { candidateToolIsOpen } from "../../../../../services/utils/candidate/examinationTest";
import { SETTING_PROPERTY_NAMES } from "../../../../../services/constants/admin/pages/exam";

type SliderType = {
  answerValue?: number;
  progressLocation?: string;
  thumbLocation?: string;
  min?: number;
  max?: number;
  step?: number;
};

const SliderQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled
}: {
  questionProblem: SliderProblemTest;
  handleUpdateProblem: (questionProblem: SliderProblemTest) => void;
  isDisabled?: boolean;
}) => {
  const { questionProblems, setQuestionProblems, openingTools } = useExaminationTestContext();
  const [editingData, setEditingData] = useState<SliderProblemTest>(questionProblem);
  const [sliderValue, setSliderValue] = useState<SliderType>(
    editingData.answer_data || {}
  );
  // const sliderIssues = editingData.issues || [];
  const crMin = editingData?.min || 0;
  const crMax = editingData?.max || 100;
  const crStep = editingData?.step || 1;
  const rangeRef = useRef<HTMLInputElement>(null);

  const trackStyle = {
    background: `linear-gradient(to right, #235FA9 ${
      sliderValue.progressLocation || "0%"
    }, #C1D9F7 ${sliderValue.progressLocation || "0%"})`,
  };

  const onChangeValueSlider = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    let value = Number(e.currentTarget?.value);
    const pixelWidth = rangeRef.current?.offsetWidth;
    const { postionThumb, progressPosition } = getLocationOfThumb(
      value,
      crMin,
      crMax,
      pixelWidth || 0
    );

    const newSliderValue = {
      ...sliderValue,
      progressLocation: progressPosition,
      thumbLocation: postionThumb,
      min: crMin,
      max: crMax,
      step: crStep,
      [e.currentTarget.name]: value,
    }
    
    setSliderValue(newSliderValue);
    handleUpdateProblem({...editingData, answer_data: newSliderValue})
  };

  const handleWindowResize = () => {
    const pixelWidth = rangeRef.current?.offsetWidth;
    const { postionThumb, progressPosition } = getLocationOfThumb(
      Number(sliderValue?.answerValue),
      crMin,
      crMax,
      pixelWidth!
    );

    setSliderValue({
      ...sliderValue,
      progressLocation: progressPosition,
      thumbLocation: postionThumb,
    });
  };

  const handleMouseUp = () => {
    if(!candidateToolIsOpen(SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT, openingTools)) return;

    const selector = window.getSelection();
    const textSelected = selector?.toString();
    if (!textSelected) return;
    const span = document.createElement("span");
    span.setAttribute("style", "background-color: yellow; display:inline;");
    span.innerText = textSelected;
    const newText = questionProblem.title?.replace(
      textSelected,
      span.outerHTML
    );

    setQuestionProblems(
      questionProblems.map((question) => {
        if (question.id === questionProblem.id) {
          return { ...question, title: newText };
        }

        return question;
      })
    );
  };

  useEffect(() => {
    if(questionProblem) {
      setEditingData(questionProblem);
    }
  }, [questionProblem])

  useEffect(() => {
    if(!editingData) return;

    setSliderValue(editingData?.answer_data || {})
  }, [editingData])

  // useEffect(() => {
  //   if (!editingData) return;
  //   const newEditingData = {
  //     ...editingData,
  //     answer_data: {
  //       answerValue: sliderValue.answerValue,
  //       progressLocation: sliderValue.progressLocation,
  //       thumbLocation: sliderValue.thumbLocation,
  //       min: sliderValue.min,
  //       max: sliderValue.max,
  //       step: sliderValue.step,
  //     },
  //   };
  //   setEditingData(newEditingData);
  //   handleUpdateProblem(newEditingData);
  // }, [sliderValue]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(questionProblem.title),
        }}
        onMouseUp={handleMouseUp}
      ></div>
      <div className="flex justify-center items-center p-[20px] bg-white flex-col !gap-[28px] min-h-[100px] rounded-b-[10px]">
        <div className="w-[75%] flex gap-[10px] justify-center items-center">
          <div
            className={`w-[90%] flex-col items-center justify-center my-[10px]`}
          >
            <input
              type="range"
              className={`accent-primary-darker w-full h-[8px] appearance-none rounded-full border-0 cursor-pointer
          [&::-webkit-slider-thumb]:cursor-grab
          [&::-webkit-slider-thumb]:h-[24px]
          bg-gradient-to-r from-primary-darker from-${sliderValue.progressLocation} to-primary-light to-${sliderValue.progressLocation}`}
              style={trackStyle}
              ref={rangeRef}
              min={crMin}
              max={crMax}
              step={crStep}
              value={sliderValue.answerValue || 0}
              name="answerValue"
              onChange={onChangeValueSlider}
              disabled={isDisabled}
            />
            <div
              className={`relative flex justify-center items-center w-full text-[#7A7A7A] text-[12px]`}
            >
              <div
                style={{ left: `${sliderValue.thumbLocation}` }}
                className={` absolute top-[1px] bg-[white] h-[20px] w-[30px] text-center`}
              >
                {sliderValue.answerValue || ""}
              </div>
            </div>

            <div
              className={`w-full flex items-center justify-center gap-[15px]`}
            >
              <div
                className={`w-[100%] flex items-center justify-between text-[#7A7A7A] text-[12px]`}
              >
                <p>{crMin}</p>
                <p>{crMax}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SliderQuestion;
