import React from 'react';

const SemiSquareButton = ({
  children,
  isDisabled = false,
  onSave,
  ...props
}: {
  children: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
  onSave?: () => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      disabled={isDisabled}
      className={`bg-primary text-white text-[16px] font-[600] leading-[17px] flex items-center justify-center ml-[7px] rounded-[10px] min-h-[36px] min-w-[212px] cursor-pointer ${
        isDisabled ? 'bg-secondary-disabled' : 'bg-primary'
      }`}
      {...props}
      onClick={onSave}
    >
      {children}
    </button>
  );
};

export default SemiSquareButton;