import React from "react";

const BlueBlock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="189"
      height="411"
      viewBox="0 0 189 411"
      fill="none"
    >
      <path opacity="0.8" d="M189 0H0V424H189V0Z" fill="#6C9AD2" />
    </svg>
  );
};

export default BlueBlock;
