import SideAction from './SideAction';
import ListQuestion from './List';

const SideManagement = () => {
  return (
    <aside className="">
      <ListQuestion />
      <SideAction />
    </aside>
  );
};

export default SideManagement;
