import React from "react";
import { HiPlus } from "react-icons/hi";
import RoundedButton from "../../commons/RoundButton";
import { Link } from "react-router-dom";
import { PATH_ADMIN_EXAM_VENUE } from "../../../../services/constants/route/router";

const ExamVenueTableCommonAction = ({
   ...props
}: React.HTMLProps<HTMLElement>) => {
   return (
      <section {...props}>
         <div className="flex justify-end gap-[10px]">
            <Link to={PATH_ADMIN_EXAM_VENUE.ADD_NEW_VENUE}>
               <RoundedButton className="bg-primary-light">
                  <div className='flex items-center justify-center'>
                     <HiPlus size={20} />
                     <div className="ml-[12px]">新規会場登録</div>
                  </div>
               </RoundedButton>
            </Link>
         </div>
      </section>
   );
};

export default ExamVenueTableCommonAction;
