import React, { useState, useContext, useEffect } from "react";
import { ChildrenWithProps, SetStateAction } from "../../types/globals";
import { Group } from "../../types/admin/group";
import { sortArrayByKey } from "../../services/helpers/parseData";
import { Filter } from "../../types/admin/main";

interface CheckedObj {
  [key: string]: boolean;
}

interface GroupManagementContextType {
  groups: Group[];
  setGroups: (groups: Group[]) => void;
  mainGroups: Group[];
  subGroups: (parentGroupId?: number) => Group[];
  selectedGroup: Group;
  setSelectedGroup: (groupId: Group["id"]) => void;
  candidates: Group["candidates"];
  setCandidates: (candidates?: Group["candidates"]) => void;
  checkedCandidates: Group["candidates"];
  setCheckedCandidates: (candidates?: Group["candidates"]) => void;
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalStep: number;
  setModalStep: React.Dispatch<React.SetStateAction<number>>;
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  checkedCandidateIds: number[];
  setCheckedCandidateIds: React.Dispatch<React.SetStateAction<number[]>>;
  checkedObj: CheckedObj;
  setCheckedObj: React.Dispatch<React.SetStateAction<CheckedObj>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  triggerRefreshCourseManagement: number;
  setTriggerRefreshCourseManagement: SetStateAction<number>;
  totalCandidateAmount: number;
  setTotalCandidateAmount: SetStateAction<number>;
}

const GroupManagementContext =
  React.createContext<GroupManagementContextType>({
    groups: [],
    setGroups: () => {},
    mainGroups: [],
    subGroups: () => [],
    selectedGroup: {},
    setSelectedGroup: () => {},
    candidates: [],
    setCandidates: () => {},
    checkedCandidates: [],
    setCheckedCandidates: () => {},
    isOpenModal: false,
    setIsOpenModal: () => {},
    modalStep: 0,
    setModalStep: () => {},
    filter: {},
    setFilter: () => {},
    checkedCandidateIds: [],
    setCheckedCandidateIds: () => {},
    checkedObj: {},
    setCheckedObj: () => {},
    title: "",
    setTitle: () => {},
    triggerRefreshCourseManagement: 0,
    setTriggerRefreshCourseManagement: () => {},
    totalCandidateAmount: 0,
    setTotalCandidateAmount: () => {},
  });

const GroupManagementProvider = ({ children }: ChildrenWithProps) => {
  const [groups, _setGroups] = useState<Group[]>([]);
  const [selectedGroup, _setSelectedGroup] = useState<Group>({});
  const [candidates, _setCandidates] = useState<Group["candidates"]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({});
  const [checkedCandidates, setCheckedCandidates] = useState<Group["candidates"]>(
    []
  );
  const [modalStep, setModalStep] = useState<number>(0);
  const [checkedCandidateIds, setCheckedCandidateIds] = useState<number[]>([]);
  const [checkedObj, setCheckedObj] = useState<CheckedObj>({});
  const [title, setTitle] = useState<string>("");
  const [mainGroups, setMainGroups] = useState<Group[]>([]);
  const [triggerRefreshCourseManagement, setTriggerRefreshCourseManagement] =
    useState<number>(0);
  const [totalCandidateAmount, setTotalCandidateAmount] =
    useState<number>(0);

  useEffect(() => {
    setMainGroups(
      groups.filter((group) => group.parent_group_id === null)
    );
  }, [groups]);

  const setGroups = (groups: Group[]) => {
    const uniqueGroups = groups
      .reverse()
      .filter(
        (group, index, a) =>
          a.findIndex((_group) => _group.id === group.id) === index
      );
    _setGroups(sortArrayByKey(uniqueGroups, "order"));
  };

  const setCandidates = (candidates: Group["candidates"]) => {
    if (!candidates) return [];

    const uniqueCandidates = candidates.filter(
      (group, index, a) =>
        a.findIndex((_group) => _group.id === group.id) === index
    );
    _setCandidates(uniqueCandidates);
  };

  const setSelectedGroup = (groupId: Group["id"]) =>
    _setSelectedGroup(
      groups.find((group) => group.id === groupId) || {}
    );

  const subGroups = (parentGroupId?: number) => {
    if (parentGroupId)
      return groups.filter(
        (group) => group.parent_group_id === parentGroupId
      );
    return groups.filter((group) => group.parent_group_id !== null);
  };

  return (
    <GroupManagementContext.Provider
      value={{
        groups,
        setGroups,
        mainGroups,
        subGroups,
        selectedGroup,
        setSelectedGroup,
        candidates,
        setCandidates,
        isOpenModal,
        setIsOpenModal,
        filter,
        setFilter,
        checkedCandidates,
        setCheckedCandidates,
        modalStep,
        setModalStep,
        checkedCandidateIds,
        setCheckedCandidateIds,
        checkedObj,
        setCheckedObj,
        title,
        setTitle,
        triggerRefreshCourseManagement,
        setTriggerRefreshCourseManagement,
        totalCandidateAmount,
        setTotalCandidateAmount
      }}
    >
      {children}
    </GroupManagementContext.Provider>
  );
};

export default GroupManagementProvider;

export const useGroupManagementContext = () =>
  useContext(GroupManagementContext);
