/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import { LiaSave } from 'react-icons/lia';
import { PiArrowFatDown, PiGitCommit, PiTrash } from 'react-icons/pi';
import AuthoringTextEditor from '../../AuthoringTextEditor';
import { isUsableArr } from '../../../../../../../services/helpers/etc';
import {
  MappingProblem,
  OrderIssue,
  MappingIssue,
  MappingPair,
} from '../../../../../../../types/admin/question';
import { HiPlus } from 'react-icons/hi';
import { swalConfirm, swalError, swalSuccess } from '../../../../../../../services/helpers/swal';
import { RenderDroppedQuestionTypeEditProps } from '../../DroppedQuestionType';
import {
  checkMappingDuplicated,
  checkMappingFilled,
  displayStatusEditorHandle,
  generateOrderIssues,
  getIssueIndexById,
  initIssueByType,
  reformatMapping,
} from '../../../../../../../services/utils/admin/question';
import { NAME_TYPE_QUESTION } from '../../../../../../../services/constants/admin/pages/question';
import { useAuthoringQuestionContext } from '../../../../../../../context/Question/AuthoringQuestionContext';
import { LuXCircle } from 'react-icons/lu';
import AuthoringMessageBox from '../AuthoringMessageBox';
import { MESSAGE_SPECIFIED } from '../../../../../../../services/constants/message';

type MappingQuestionEditProps = {
  problemEdit?: MappingProblem;
} & Omit<RenderDroppedQuestionTypeEditProps, 'draggedName' | 'problemEdit'>;

const MappingQuestionEdit = ({ problemEdit }: MappingQuestionEditProps) => {
  const {
    authoringData,
    setAuthoringData,
    editingProblemStatuses,
    setEditingProblemStatuses,
    setSelectedProblem,
    setIsEditingProblem
  } = useAuthoringQuestionContext();

  const [editingNode, setEditingNode] = useState('');
  const [editingData, setEditingData] = useState<MappingProblem>(problemEdit || {});

  const currentIssues = editingData.issues || [];
  const currentPairs = editingData?.correct_pairs || [];

  const [checkingIssue, _setCheckingIssue] = useState<MappingIssue>({});
  const setCheckingIssue = (issueId?: number) => {
    if (!issueId) _setCheckingIssue({});
    _setCheckingIssue(currentIssues.find((issue) => Number(issue.id) === Number(issueId)) || {});
  };

  //On change handle for text editor only
  const changeEditorHandle = useCallback(
    (identify: string, content: string) => {
      if (
        content === '<p><br></p>' ||
        !editingData ||
        editingData[identify as keyof MappingProblem] === content ||
        identify !== editingNode
      ) {
        return;
      }

      if (identify === 'title' || editingNode === 'title') {
        setEditingData((editingData) => ({ ...editingData, title: content }));
        return;
      }

      const newIssues = generateOrderIssues(identify, content, editingData.issues || []);
      setEditingData({ ...editingData, issues: newIssues });
    },
    [editingNode],
  );

  const tabOptionChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget || !editingData) return;
    const { name, value, checked, type } = e.currentTarget;

    let parsedValue: string | number | boolean = value;

    if (type === 'checkbox') {
      parsedValue = checked;
    }

    if (type === 'radio') {
      parsedValue = !!Number(value);
    }

    if (type === 'number') {
      parsedValue = Number(value);
    }

    if (Number(value) > 100) {
      parsedValue = 100;
    }

    if (Number(value) < 0) {
      parsedValue = 0;
    }

    if (
      !editingData.hasOwnProperty(name) ||
      editingData[name as keyof MappingProblem] === parsedValue
    ) {
      return;
    }

    // Addition data for specific cases
    let additionData = {} as typeof editingData;
    if (name === 'default_score') {
      // update default score for all issues
      const newPairs = currentPairs?.map((pair) => ({
        ...pair,
        point: Number(parsedValue),
      }));

      additionData = {
        ...additionData,
        correct_pairs: [...(newPairs || [])],
      };
    }

    if (name === 'min_choice') {
      // Make min choice must <= max choice
      if (Number(parsedValue) > Number(editingData.max_choice)) {
        additionData = {
          ...additionData,
          max_choice: Number(parsedValue),
        };
      }

      // Generate empty pairs by min
      let newCurrentPairs = reformatMapping(currentPairs) || [];

      const newGenArrayLength =
        Number(additionData.max_choice || editingData.max_choice) || Number(parsedValue) || 1;
      const diffGenByActual = newGenArrayLength - newCurrentPairs.length;


      if (diffGenByActual !== 0) {
        if (diffGenByActual > 0) {
          newCurrentPairs.push(...new Array(diffGenByActual).fill({}));
        } else {
          newCurrentPairs = newCurrentPairs.slice(0, diffGenByActual);
        }

        additionData = {
          ...additionData,
          correct_pairs: newCurrentPairs,
        };
      }
    }

    if (name === 'max_choice') {
      // Make max choice must >= min choice
      if (Number(parsedValue) < Number(editingData.min_choice) && !!Number(parsedValue)) {
        additionData = {
          ...additionData,
          min_choice: Number(parsedValue),
        };
      }

      let newCurrentPairs = currentPairs;

      // Generate empty pairs by max
      if (!parsedValue) {
        newCurrentPairs = reformatMapping(newCurrentPairs) || [];
        if (Number(editingData.min_choice) > 0 && Number(parsedValue) == 0) {
          newCurrentPairs=[];
          newCurrentPairs.push(...new Array(Number(editingData.min_choice)).fill({}));
        }
        additionData = {
          ...additionData,
          correct_pairs: newCurrentPairs,
        };
      } else {
        const newGenArrayLength = Number(parsedValue) || 1;
        const diffGenByActual = newGenArrayLength - newCurrentPairs.length;
        if (diffGenByActual !== 0) {
          if (diffGenByActual > 0) {
            newCurrentPairs.push(...new Array(diffGenByActual).fill({}));
          } else {
            newCurrentPairs = newCurrentPairs.slice(0, diffGenByActual);
          }

          additionData = {
            ...additionData,
            correct_pairs: newCurrentPairs,
          };
        }
      }
    }

    setEditingData({ ...editingData, [name]: parsedValue, ...additionData });
  };

  const pairChangeHandle = (e: React.ChangeEvent<HTMLInputElement>, pairId?: number) => {
    if (!e.currentTarget) return;
    const { value } = e.currentTarget;

    let parsedValue: string | boolean = value;

    if (Number(value) > 100) {
      parsedValue = '100';
    }

    if (Number(value) < 0) {
      parsedValue = '0';
    }

    const currentPair = currentPairs?.find((pair) => pair.id === pairId);
    const newCurrentPair = {
      ...currentPair,
      point: Number(parsedValue),
    };
    const currentPairIndex = currentPairs?.findIndex((pair) => pair.id === pairId) || 0;
    currentPairs?.splice(currentPairIndex, 1, newCurrentPair);

    setEditingData({ ...editingData, correct_pairs: currentPairs });
  };

  const addIssueHandle = () => {
    const issueValue = currentIssues.length || 0;
    const newIssue = initIssueByType(
      NAME_TYPE_QUESTION.CHOICE,
      issueValue,
      editingData?.default_score || 0,
    );
    setEditingData({ ...editingData, issues: [...currentIssues, newIssue as OrderIssue] });
  };

  const removeIssueHandle = (targetId: number | string) => {
    if (!targetId) return;

    swalConfirm(() => {
      const newIssueData = currentIssues.filter((issue) => Number(issue?.id) !== Number(targetId));
      setEditingData({ ...editingData, issues: newIssueData });
      setEditingNode('');
    });
  };

  const submitEditingDataHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);

    const finalEditingData = {
      ...editingData,
      updated_at: new Date().getTime(),
    };

    const problemIndexed =
      authoringData.problems?.findIndex((problem) => problem.id === finalEditingData.id) || 0;

    let newProblems: MappingProblem[] = authoringData.problems || [];
    newProblems.splice(problemIndexed, 1, finalEditingData);

    const currentPaireds = currentPairs.filter((pair) => pair.left && pair.right);

    // Validating
    let errorMsgs = '';
    if (
      !!editingData.min_choice &&
      Number(currentPaireds.length) < Number(editingData.min_choice)
    ) {
      errorMsgs = `${editingData.min_choice}つの組合わせを指定してください`;
    }

    if (errorMsgs.length > 0) {
      return;
    }

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      'hide',
      Number(editingData.id),
      editingProblemStatuses,
    );
    setEditingProblemStatuses(newEditingProblemStatuses);

    setAuthoringData({ problems: newProblems });
    swalSuccess();
    setSelectedProblem();
  };

  const removeHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    swalConfirm(() => {
      setIsEditingProblem(false);
      setAuthoringData({
        ...authoringData,
        problems: authoringData.problems?.filter(
          (problem) => Number(problem.id) !== Number(editingData.id),
        ),
      });
    });
  };

  const hideEditorHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      'hide',
      Number(editingData.id),
      editingProblemStatuses,
    );
    setEditingProblemStatuses(newEditingProblemStatuses);
    setSelectedProblem();
  };

  const addPairHandle = (direction: 'left' | 'right', pairPosition: number) => {
    if (!checkingIssue?.id || !currentPairs) return;
    const backupCurrentPairs = Array.from(currentPairs);
    const pairValue: MappingPair = {
      ...currentPairs[pairPosition],
      [direction]: checkingIssue?.id,
      id: new Date().getTime(),
    };

    currentPairs.splice(pairPosition, 1);
    currentPairs.splice(pairPosition, 0, pairValue);

    if (!checkMappingDuplicated(currentPairs)) {
      swalError(MESSAGE_SPECIFIED.QUESTION.NO_DUPLICATE_ALLOWED);

      setEditingData({ ...editingData, correct_pairs: backupCurrentPairs });
      return;
    }

    // full filled, then add pair space
    if (checkMappingFilled(currentPairs) && !editingData.min_choice && !editingData.max_choice) {
      currentPairs.splice(pairPosition + 1, 0, {});
    }
    if (checkMappingFilled(currentPairs) && Number(editingData.min_choice)>0 && Number(editingData.max_choice)==0) {
      currentPairs.splice(pairPosition + 1, 0, {});
    }

    setEditingData({ ...editingData, correct_pairs: currentPairs });
    setCheckingIssue();
  };

  const removeCheckedIssueHandle = (direction: 'left' | 'right', pairPosition: number) => {
    if (!direction || pairPosition === undefined || !currentPairs) return;
    const pairValue: MappingPair = {
      ...currentPairs[pairPosition],
      [direction]: undefined,
      id: new Date().getTime(),
    };
    let newCurrentPairs=currentPairs ;
    if(currentPairs.length>Number(editingData.min_choice)){
      currentPairs.splice(pairPosition, 1);
      currentPairs.splice(pairPosition, 0, pairValue);
      newCurrentPairs = reformatMapping(currentPairs)|| [];
    }
    else{
      currentPairs.splice(pairPosition, 1);
      currentPairs.splice(pairPosition, 0, pairValue);
    }

    setEditingData({ ...editingData, correct_pairs: newCurrentPairs });
  };

  const checkingIssueHandle = (issueId?: number) => {
    let resultIssueId = issueId;
    if (issueId === checkingIssue.id) {
      resultIssueId = 0;
    }
    setCheckingIssue(resultIssueId);
  };

  const getIssueById = (issueId: number) => currentIssues.find((issue) => issue.id === issueId);

  const issueAppearLimitChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    issueId?: number,
  ) => {
    const { value } = e.currentTarget;
    if (!issueId || !e.currentTarget) return;
    const currentIssueIndex = getIssueIndexById(currentIssues, issueId);
    const newCurrentIssues = JSON.parse(JSON.stringify(currentIssues)) as typeof currentIssues;
    let newCurrentIssue = getIssueById(issueId);
    newCurrentIssue = {
      ...newCurrentIssue,
      appear_limit: Number(value),
    };
    newCurrentIssues.splice(currentIssueIndex, 1, newCurrentIssue);

    let newCurrentPairs = currentPairs;

    if (
      !!newCurrentIssue.appear_limit &&
      newCurrentIssue.appear_limit <= issueAppearedCount(issueId)
    ) {
      return;
    }

    setEditingData({
      ...editingData,
      issues: newCurrentIssues,
      correct_pairs: newCurrentPairs,
    });
  };

  const issueAppearedCount = (issueId?: number) => {
    const appearedLeftPairs = currentPairs.filter((pair) => pair.left === issueId);
    const appearedRightPairs = currentPairs.filter((pair) => pair.right === issueId);
    return appearedLeftPairs.length + appearedRightPairs.length;
  };

  const isIssueAppearLimitExceed = (issueId?: number) => {
    if (!issueId) return false;
    const appearedCount = issueAppearedCount(issueId);
    const currentIssue = getIssueById(issueId);

    if (
      !!appearedCount &&
      appearedCount >= Number(currentIssue?.appear_limit) &&
      !!Number(currentIssue?.appear_limit)
    ) {
      return true;
    }

    return false;
  };

  return (
    <form>
      <div className="mb-[10px] bg-secondary-extralight min-h-[780px] w-full p-[20px] relative">
        <div className="mb-[30px]"></div>
        <div className="flex gap-[13px]">
          <div className="w-[80%]">
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設問内容
              </div>
              <div className="w-full bg-white">
                <div className="p-[10px]" onClick={() => setEditingNode('title')}>
                  <AuthoringTextEditor
                    toolbarId={`toolbar_title`}
                    isReadOnly={editingNode !== 'title' && editingNode !== ''}
                    changeHandler={(htmlContent) => changeEditorHandle('title', htmlContent)}
                    value={editingData?.title || ''}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary h-0 min-h-[34px] flex items-center">
                正解・解答・配点
              </div>
              <div className="p-[20px] bg-white rounded-b-[10px]">
                <div className="mb-[20px]">
                  <AuthoringMessageBox problemData={editingData} />
                </div>
                <div className="justify-center items-center flex flex-col gap-[28px]">
                  <div className="border-[2px] border-[#E5E5E5] h-full px-[10px] py-[20px] w-[90%]">
                    <ul className="px-0 list-none listbox-dot h-full flex flex-wrap">
                      {isUsableArr(currentIssues) &&
                        currentIssues &&
                        currentIssues.map((issue, index) => (
                          <div
                            className={`mb-[15px] flex items-center gap-[10px] px-[10px] w-[190px] 
                          ${isIssueAppearLimitExceed(issue.id) ? 'pointer-events-none' : ''}`}
                            key={index}
                          >
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() => checkingIssueHandle(issue.id)}
                            >
                              <input
                                type="checkbox"
                                name={`issue_data_checked_${index}`}
                                className="pointer-events-none"
                                id=""
                                disabled
                              />
                            </div>
                            <li
                              className="w-full"
                              onClick={() => setEditingNode(`issue_data_label_${index}`)}
                            >
                              <AuthoringTextEditor
                                toolbarId={`toolbar_issue_${index}`}
                                isReadOnly={editingNode !== `issue_data_label_${index}`}
                                changeHandler={(htmlContent) =>
                                  changeEditorHandle(`issue_data_label_${index}`, htmlContent)
                                }
                                value={issue.label || ''}
                                isDisabled={
                                  isIssueAppearLimitExceed(issue.id) ||
                                  checkingIssue?.id === issue.id
                                }
                              />
                            </li>

                            <div
                              className="cursor-pointer text-secondary"
                              onClick={() => removeIssueHandle(issue?.id || 0)}
                            >
                              <PiTrash size={22} />
                            </div>
                          </div>
                        ))}
                      <div className="w-full flex justify-center mt-[20px]">
                        <div className="cursor-pointer" onClick={addIssueHandle}>
                          <div className="flex justify-center">
                            <div className="bg-primary rounded-[5px] w-[40px] h-[40px] flex justify-center items-center text-white cursor-pointer mb-[10px]">
                              <HiPlus size={40} />
                            </div>
                          </div>
                          <div className="text-center text-[12px] font-[500] leading-[100%] text-secondary-dark">
                            選択肢を追加
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                  <div className="text-[#EBE4D8]">
                    <PiArrowFatDown size={50} />
                  </div>
                  <div className="border-[2px] border-[#E5E5E5] h-full px-[15px] py-[20px] flex flex-wrap gap-[10px] items-center w-[90%] justify-start">
                    {isUsableArr(currentPairs) &&
                      currentPairs?.map((pair, index) => (
                        <div
                          className="flex items-center justify-center gap-[15px] w-full"
                          key={index}
                        >
                          <div className="flex justify-start gap-[10px] items-center w-[190px]">
                            <div
                              className={`w-full border border-dashed ${checkingIssue?.id
                                ? 'bg-primary-extralight border-primary-light'
                                : 'border-secondary-light'
                                } min-h-[50px] ${checkingIssue.id ? 'cursor-pointer' : 'cursor-default'
                                }`}
                              onClick={() => addPairHandle('left', index)}
                            >
                              {pair.left ? (
                                <div className="w-full flex items-center justify-start gap-[7px] p-[5px]">
                                  <div className="w-[67%] ml-[10px] mr-[5px]">
                                    <AuthoringTextEditor
                                      toolbarId={`toolbar_issue_${currentIssues.findIndex(
                                        (crIssue) => crIssue.id === pair.left,
                                      ) || 0
                                        }_${index}_left_disabled`}
                                      isReadOnly
                                      value={getIssueById(pair.left)?.label || ''}
                                    />
                                  </div>
                                  <div
                                    className="text-[20px] text-secondary-light cursor-pointer"
                                    onClick={() => removeCheckedIssueHandle('left', index)}
                                  >
                                    <LuXCircle />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <PiGitCommit size={26} />
                          <div className="flex justify-start gap-[10px] items-center w-[190px]">
                            <div
                              className={`w-full border border-dashed ${checkingIssue?.id
                                ? 'bg-primary-extralight border-primary-light'
                                : 'border-secondary-light'
                                } min-h-[50px] ${checkingIssue.id ? 'cursor-pointer' : 'cursor-default'
                                }`}
                              onClick={() => addPairHandle('right', index)}
                            >
                              {pair.right ? (
                                <div className="w-full flex items-center justify-start gap-[7px] p-[5px]">
                                  <div className="w-[67%] ml-[10px] mr-[5px]">
                                    <AuthoringTextEditor
                                      toolbarId={`toolbar_issue_${currentIssues.findIndex(
                                        (crIssue) => crIssue.id === pair.right,
                                      ) || 0
                                        }_${index}_right_disabled`}
                                      isReadOnly
                                      value={getIssueById(pair.right)?.label || ''}
                                    />
                                  </div>
                                  <div
                                    className="text-[20px] text-secondary-light cursor-pointer"
                                    onClick={() => removeCheckedIssueHandle('right', index)}
                                  >
                                    <LuXCircle />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="">
                            <input
                              type="number"
                              name="point"
                              id=""
                              className="w-[35px] h-[35px] !border-secondary-light !p-[5px]"
                              placeholder="0"
                              onChange={(e) => pairChangeHandle(e, pair.id)}
                              value={pair.point || ''}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[20%] flex flex-col gap-y-[15px]">
            <div className="flex flex-col">
              <div className="p-[10px] text-[14px] font-[500] leading-[100%] text-white w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設定
              </div>
              <div className="bg-white min-h-[100px] px-[10px]">
                <div className="mb-[25px] pt-[10px] m-[10px]">
                  <div className="mb-[25px] flex items-center gap-[10px] text-[14px] font-[500] leading-[100%]">
                    <label htmlFor="is_shuffled">選択肢をシャッフル</label>
                    <input
                      type="checkbox"
                      name="is_shuffled"
                      id="is_shuffled"
                      onChange={tabOptionChangeHandle}
                      checked={!!editingData?.is_shuffled}
                    />
                  </div>
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    配点初期値
                  </div>
                  <input
                    type="number"
                    name="default_score"
                    placeholder="0"
                    className="w-[71px] h-[32px]"
                    onChange={tabOptionChangeHandle}
                    value={Number(editingData?.default_score) || ''}
                  />
                </div>

                <div className="mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    組合せの回数
                  </div>
                  <div className="choice_limit flex items-center gap-[10px] mb-[5px]">
                    <div className="text-[12px] font-[400] leading-[100%]">最小</div>
                    <input
                      type="number"
                      name="min_choice"
                      placeholder="0"
                      className="w-[71px] h-[32px] !pr-0"
                      onChange={tabOptionChangeHandle}
                      value={editingData?.min_choice || ''}
                    />
                  </div>
                  <div className="choice_limit flex items-center gap-[10px]">
                    <div className="text-[12px] font-[400] leading-[100%]">最大</div>
                    <input
                      type="number"
                      name="max_choice"
                      placeholder="0"
                      className="w-[71px] h-[32px] !pr-0"
                      onChange={tabOptionChangeHandle}
                      value={editingData?.max_choice || ''}
                    />
                  </div>
                  {checkingIssue.id ? (
                    <div className="mt-[25px]">
                      <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                        選択肢の選択回数
                      </div>
                      <div className="choice_limit flex items-center gap-[10px]">
                        <div className="text-[12px] font-[400] leading-[100%]">最大</div>
                        <input
                          type="number"
                          name="appear_limit"
                          placeholder="0"
                          className="w-[71px] h-[32px] !pr-0"
                          onChange={(e) => issueAppearLimitChangeHandle(e, checkingIssue.id)}
                          value={getIssueById(Number(checkingIssue.id))?.appear_limit || ''}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="text-white">
              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
                  onClick={submitEditingDataHandle}
                >
                  <LiaSave size={23} /> 保存
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={hideEditorHandle}
                >
                  キャンセル
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer gap-[5px] flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={removeHandle}
                >
                  <PiTrash size={20} />
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MappingQuestionEdit;
