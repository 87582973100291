/* eslint-disable react-hooks/exhaustive-deps */
import { isUsableArr } from '../../../../../../../services/helpers/etc';
import { decodeHTMLEntities, shuffle } from '../../../../../../../services/helpers/parseData';
import { getIssueById } from '../../../../../../../services/utils/admin/question';
import { FillInBlankIssue, FillInBlankProblem } from '../../../../../../../types/admin/question';
import AuthoringTextEditor from '../../AuthoringTextEditor';
import { RenderDroppedQuestionTypeProps } from '../../DroppedQuestionType';
import { useState, useEffect, useMemo } from 'react';

type FillInBlankQuestionProps = {
  problemPreview?: FillInBlankProblem;
  actionable?: boolean;
} & Omit<RenderDroppedQuestionTypeProps, 'draggedName' | 'problemPreview'>;

const FillInBlankQuestion = ({ problemPreview, actionable = false }: FillInBlankQuestionProps) => {
  const currentIssues = useMemo(
    () =>
      problemPreview?.is_shuffled ? shuffle(problemPreview?.issues || []) : problemPreview?.issues,
    [problemPreview?.updated_at],
  );

  const [editingData, setEditingData] = useState<FillInBlankProblem>(problemPreview || {});
  const [selectingIssue, _setSelectingIssue] = useState<FillInBlankIssue>({});
  const setSelectingIssue = (issueId?: number) => {
    if (!issueId) return _setSelectingIssue({});
    _setSelectingIssue(getIssueById(currentIssues || [], issueId) || {});
  };
  const textEditorId = `fill_in_blank_content_editor_preview_${editingData.id}`;
  const [previewContent, setPreviewContent] = useState('');

  useEffect(() => {
    if (!problemPreview?.id) return;
    setEditingData(problemPreview || {});

    let newContent = problemPreview.content_origin || '';
    const currentFills = problemPreview.correct_fills || [];
    for (const fill of currentFills) {
      newContent = newContent?.replace(String(fill.content_consumed), String(fill.content));
    }

    setPreviewContent(newContent);
  }, [problemPreview]);

  const selectingIssueHandle = (issueId?: number, index?: number) => {
    if (!issueId || index === undefined) return;
    if (selectingIssue.id === issueId) {
      setSelectingIssue();
      return;
    }
    setSelectingIssue(issueId);
  };

  return (
    <div className={`text-secondary-dark ${actionable ? '' : 'pointer-events-none'}`}>
      <div className="">
        <div
          dangerouslySetInnerHTML={{
            __html: decodeHTMLEntities(editingData?.title || ''),
          }}
        ></div>
      </div>

      <div className="bg-white pt-[20px] text-secondary-dark">
        <div className="px-[10px] flex flex-wrap gap-[5px]">
          {isUsableArr(currentIssues) &&
            currentIssues &&
            currentIssues.map((issue, index) => (
              <div className="mb-[15px] flex items-center gap-[10px] px-[10px]" key={index}>
                <div
                  className={`${
                    selectingIssue.id === issue.id ? 'selecting_border_editor' : ''
                  }`}
                  onClick={() => selectingIssueHandle(issue.id, index)}
                >
                  <AuthoringTextEditor
                    toolbarId={`toolbar_issue_preview_${problemPreview?.id || 0}_${index}`}
                    isReadOnly={true}
                    value={issue.label || ''}
                    width="w-fit"
                  />
                </div>
              </div>
            ))}
        </div>

        <div className="px-[20px]">
          <div className="mb-[15px] border-t-[2px] border-secondary-light w-full text-[12px] leading-[190%] text-secondary-light"></div>
        </div>

        <div className="px-[4px] mb-[100px]">
          <div className="text-[12px] leading-[190%] no_border_editor">
            <div className="">
              <div
                className="ql-editor px-[15px] focus:outline-none focus:ring focus:border-primary focus:rounded-[5px] highlight_content"
                id={textEditorId}
                dangerouslySetInnerHTML={{
                  __html: decodeHTMLEntities(previewContent),
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FillInBlankQuestion;
