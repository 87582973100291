/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import katex from 'katex';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'katex/dist/katex.min.css';
import Quill from 'quill';
import BaseModal from '../../../commons/BaseModal';
import UploadQuestionImage from '../../../commons/UploadQuestionImage';
import { UNIT_FILE_TYPE } from '../../../../../services/constants/admin/pages/material';
import LatexSymbolModal from '../../../commons/LatexSymbolModal';
import AuthoringToolbarTextEditor from './AuthoringToolbarTextEditor';

(window as any).katex = katex;

type AuthoringTextEditorProps = {
  value?: string | null;
  changeHandler?: (html: string) => void;
  className?: string;
  toolbarId: string;
  placeholder?: string;
  width?: string;
  isReadOnly?: boolean;
  isCandidate?: boolean;
  isDisabled?: boolean;
  id?: string;
};

const AuthoringTextEditor = ({
  value,
  changeHandler,
  className,
  toolbarId,
  placeholder,
  width,
  isReadOnly,
  isDisabled,
  id,
}: AuthoringTextEditorProps) => {
  const quillRef = useRef<ReactQuill>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenFormulaModal, setIsOpenFormulaModal] = useState<boolean>(false);
  const [resourceLink, setResourceLink] = useState<string>('');
  const imageHandler = () => {
    setIsOpenModal(true);
  };
  const undoChange = () => {
    (quillRef.current?.getEditor() as any).history.undo();
  };
  const redoChange = () => {
    (quillRef.current?.getEditor() as any).history.redo();
  };

  const formulaHandler = () => {
    setIsOpenFormulaModal(true);
  };

  const insertFormula = (equation: string) => {
    // @ts-ignore
    const editor = quillRef?.current?.getEditor();
    // @ts-ignore
    const range = editor.getSelection(true);
    // @ts-ignore
    editor.insertEmbed(range.index, 'formula', equation, Quill.sources.USER);
    // @ts-ignore
    editor.setSelection(range.index + 1, Quill.sources.SILENT);
  };

  useEffect(() => {
    if (!resourceLink) return;
    // @ts-ignore
    const editor = quillRef?.current?.getEditor();
    // @ts-ignore
    const range = editor?.getSelection(true);
    // @ts-ignore
    editor.insertEmbed(range.index, 'image', resourceLink);
  }, [resourceLink]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: '#' + toolbarId,
        handlers: {
          undo: undoChange,
          redo: redoChange,
          image: imageHandler,
          formula: formulaHandler,
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
    }),
    [],
  );

  const formats = [
    'font',
    'header',
    'color',
    'align',
    'bold',
    'italic',
    'underline',
    'strike',
    'code',
    'list',
    'bullet',
    'link',
    'image',
    'code-block',
    'formula',
  ];

  return (
    <>
      <div className={`${isReadOnly ? 'hidden' : ''}`}>
        <AuthoringToolbarTextEditor id={toolbarId} className={`absolute top-1 left-1`} />
      </div>
      <div
        className={`${width || 'w-full'} ${
          isDisabled ? 'bg-[#C5C5C5] text-secondary rounded-[7px]' : ''
        }`}
      >
        <ReactQuill
          className={`${className} border-0 `}
          value={value as any}
          onChange={changeHandler}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          ref={quillRef}
          theme={'snow'}
          readOnly={isReadOnly || isDisabled}
        />
      </div>
      {isOpenModal && (
        <BaseModal setIsOpen={setIsOpenModal} width={1010} height={718} justifyDirection="end">
          <UploadQuestionImage
            fileType={UNIT_FILE_TYPE.TYPE_IMAGE}
            setIsOpen={setIsOpenModal}
            setResourceLink={setResourceLink}
          />
        </BaseModal>
      )}
      {isOpenFormulaModal && (
        <LatexSymbolModal
          isOpen={isOpenFormulaModal}
          onClose={() => setIsOpenFormulaModal(false)}
          onSubmit={insertFormula}
        />
      )}
    </>
  );
};

export default AuthoringTextEditor;
