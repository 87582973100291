import TabCard from '../../../components/admin/commons/TabCard';
import AdminLayout from '../../../layouts/admin';
import { WithChildren } from '../../../types/globals';
import RegisterExam from './RegisterExam';

type HomeMainProps = {
  title?: string;
};

const HomePageBase = ({ title, children }: WithChildren<HomeMainProps>) => {
  return (
    <AdminLayout>
      {/* <div className='my-[20px] w-full flex justify-start rounded-[10px] items-center gap-[20px] flex-wrap '>
        <RegisterExam label='アカウント情報変更' text='テキストダミーテキストタイトルダミーテキストこれは正式な文章の代わりに入れて使うダミーテキストです。テキストダミーテキストタイトルダミーテキストこれは正式な文章の代わりに入れて使うダ' />
        <RegisterExam label='受験申込' text='テキストダミーテキストタイトルダミーテキストこれは正式な文章の代わりに入れて使うダミーテキストです。テキストダミーテキストタイトルダミーテキストこれは正式な文章の代わりに入れて使うダ' />
        <RegisterExam label='アカウント情報変更' text='テキストダミーテキストタイトルダミーテキストこれは正式な文章の代わりに入れて使うダミーテキストです。テキストダミーテキストタイトルダミーテキストこれは正式な文章の代わりに入れて使うダ' />
        <RegisterExam label='受験申込' text='テキストダミーテキストタイトルダミーテキストこれは正式な文章の代わりに入れて使うダミーテキストです。テキストダミーテキストタイトルダミーテキストこれは正式な文章の代わりに入れて使うダ' />
      </div> */}
      {/* <TabCard tabData={[]} title={title}>
        {children}
      </TabCard> */}
    </AdminLayout>
  );
};

export default HomePageBase;
