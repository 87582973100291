import React from 'react';
import { NAME_ACTION_DETAIL_QUESTION } from '../../../../services/constants/admin/pages/question';
import { SlEye } from 'react-icons/sl';
import { BiEditAlt } from 'react-icons/bi';
import { useQuestionListContext } from '../../../../context/Question/ListQuestionContext';

const DetailAction = () => {
  const actionData = [
    {
      icon: <BiEditAlt />,
      label: '編集',
      actionName: NAME_ACTION_DETAIL_QUESTION.EDIT,
    },
    {
      icon: <SlEye />,
      label: 'プレビュー',
      actionName: NAME_ACTION_DETAIL_QUESTION.PREVIEW,
    },
    {
      icon: <BiEditAlt />,
      label: 'オーサリング',
      actionName: NAME_ACTION_DETAIL_QUESTION.AUTHORING,
    },
  ];
  const { setDetailActionName, detailActionName} = useQuestionListContext();

  return (
    <div className="flex mb-[20px]">
      {actionData.map((action, index) => (
        <div
          key={index}
          className={`cursor-pointer hover:bg-primary hover:text-white flex items-center gap-[5px] text-[20px] w-full max-w-[120px] h-0 min-h-[50px] border border-success-extralight ${
            detailActionName === action.actionName ? 'bg-primary text-white' : 'bg-success-lighter'
          }`}
          onClick={() => setDetailActionName(action.actionName)}
        >
          <div className="pl-[10px]">{action.icon}</div>
          <div className="text-[12px] font-[500] leading-[16px] tracking-[0.5px]">
            {action.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetailAction;
