import AdminLayout from "../../../layouts/admin";
import { WithChildren } from "../../../types/globals";
import "../../../assests/styles/course.css";
type ExamProp = {
  title?: string;
};
const ExamBase = ({ title, children }: WithChildren<ExamProp>) => {
  return <AdminLayout>{children}</AdminLayout>;
};
export default ExamBase;
