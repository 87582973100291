import React from 'react';
import { HiPlus } from 'react-icons/hi';
import { IoMdDownload, IoMdExit } from 'react-icons/io';
import { TbFileDescription } from 'react-icons/tb';
import RoundedButton from './RoundButton';

type TableCommonActionProps = {
  onCreate?: () => void;
  createBtnLabel?: string;
  onCsvTemplateDownload?: () => void;
  onCsvImport?: () => void;
  onCsvExport?: () => void;
};

const TableCommonAction = ({
  onCreate,
  onCsvTemplateDownload,
  onCsvImport,
  onCsvExport,
  createBtnLabel,
  ...props
}: TableCommonActionProps & React.HTMLProps<HTMLElement>) => {
  return (
    <section {...props}>
      <div className="flex justify-end gap-[10px] pr-[43px]">
        <RoundedButton onClick={onCreate}>
        <div className='flex items-center justify-center'>
          <HiPlus size={20} />
          <div className="ml-[12px]">{createBtnLabel || '新規グループ登録'}</div>
          </div>
        </RoundedButton>
        {/* TableActionButton hidden for later phases */}
        {/* <RoundedButton isDisabled>
          <IoMdDownload size={18} />
          <div className="ml-[7px]">CSVテンプレートをダウンロード</div>
        </RoundedButton>
        <RoundedButton>
          <TbFileDescription size={18} />
          <div className="ml-[10px]">CSVインポート</div>
        </RoundedButton>
        <RoundedButton>
          <IoMdExit size={20} />
          <div className="ml-[7px]">CSVエクスポート</div>
        </RoundedButton> */}
      </div>
    </section>
  );
};

export default TableCommonAction;
