import React from 'react';
import Setting from '../../../components/admin/Group/Setting/Setting';
import GroupBase from '.';

const GroupSettingPage = () => {
  return (
    <GroupBase title="グループ管理 ｜ グループ設定">
      <Setting />
    </GroupBase>
  );
};

export default GroupSettingPage;
