import QuestionPageBase from '.';
import CreateQuestion from '../../../components/admin/Question/CreateQuestion';

const QuestionCreatePage = () => {
  return (
    <QuestionPageBase>
      <CreateQuestion />
    </QuestionPageBase>
  );
};

export default QuestionCreatePage;
