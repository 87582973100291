import React from "react";
import {
  MappingPair,
  MappingProblemTest,
} from "../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import { PiGitCommit } from "react-icons/pi";
import { CHECK_ANSWER_STATUS_TYPE } from "../../../../../services/constants/globals";
import { handleShowIconCheckAnswer } from "../RenderQuestionType";

type MappingQuestionProps = {
  question: MappingProblemTest;
  numberQuestion: number;
};

const MappingQuestion = ({
  question,
  numberQuestion,
}: MappingQuestionProps) => {
  const correctPairs = question.correct_pairs || [];
  const answerData = question.answer_data || [];
  const issues = question.issues || [];

  const getLabelAnswerById = (issueId: number) => {
    const issue = issues.find((issue) => issue?.id === issueId);
    if (!issue) return "<div></div>";

    return issue?.label || "<div></div>";
  };

  const handleCheckCorrectAnswer = (answerPair: MappingPair) => {
    const findCorrectPair = correctPairs.find(
      (correctPair) =>
        (correctPair?.left === answerPair?.left &&
          correctPair?.right === answerPair?.right) ||
        (correctPair?.left === answerPair?.right &&
          correctPair?.right === answerPair?.left)
    );

    if (findCorrectPair && findCorrectPair.id) {
      return CHECK_ANSWER_STATUS_TYPE.CORRECT;
    }

    return CHECK_ANSWER_STATUS_TYPE.INCORRECT;
  };

  return (
    <div className="w-full flex items-stretch mb-[30px]">
      <div className="w-[40px]">
        <div className="w-full h-full flex items-center justify-center bg-warning-lighter">
          <span>{numberQuestion}</span>
        </div>
      </div>
      <div className="w-[calc(100%-40px)] flex gap-y-[20px] flex-col">
        {correctPairs.map((correctPair, index) => {
          if (Object.keys(correctPair).length === 0 || !correctPair.id) return;

          return (
            <div
              className="w-full flex items-center"
              key={correctPair?.id || index}
            >
              <div className="flex-1 flex items-center gap-x-[10px] px-[10px] pb-[10px] border-b border-b-danger-light">
                <div
                  className="flex-1 w-fit max-w-[150px] flex justify-center"
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(
                      getLabelAnswerById(answerData[index]?.left || 0)
                    ),
                  }}
                ></div>
                <div className="w-[50px] flex justify-center">
                  <PiGitCommit size={26} color="#FA646A" />
                </div>
                <div
                  className="flex-1 w-fit max-w-[150px] flex justify-center"
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(
                      getLabelAnswerById(answerData[index]?.right || 0)
                    ),
                  }}
                ></div>
              </div>
              <div className="flex-1 flex items-center gap-x-[10px] px-[10px] pb-[10px]">
                <div
                  className="flex-1 w-fit max-w-[150px] flex justify-center"
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(
                      getLabelAnswerById(correctPair?.left || 0)
                    ),
                  }}
                ></div>
                <div className="w-[30px] flex justify-center">
                  <PiGitCommit size={26} color="#FA646A" />
                </div>
                <div
                  className="flex-1 w-fit max-w-[150px] flex justify-center"
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(
                      getLabelAnswerById(correctPair?.right || 0)
                    ),
                  }}
                ></div>
              </div>
              <div className="w-[80px] flex justify-center">
                {handleShowIconCheckAnswer(
                  handleCheckCorrectAnswer(answerData[index])
                )}
              </div>
              <div className="w-[40px] text-center text-[14px]">
                {handleCheckCorrectAnswer(answerData[index]) ===
                CHECK_ANSWER_STATUS_TYPE.CORRECT
                  ? correctPair.point || 0
                  : 0}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MappingQuestion;
