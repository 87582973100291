import React from "react";
import { ChildrenWithProps } from "../../types/globals";
import Header from "./Header";
import Sidebar from "./Sidebar";
import RequiredAuth from "../../middleware/auth";
import ScrollToTop from "../../components/commons/etcs/ScrollToTop";
import CheckRoleAdmin from "../../middleware/checkRoleAdmin";
import { BiSolidRightArrow } from "react-icons/bi";
import { ADMIN_SIDEBAR_DATA } from "../../services/constants/admin/sidebar";
import { useObjectRoutes } from "../../hooks/useObjectRoutes";
import { Link } from "react-router-dom";
import {
  PATH_ADMIN_CANDIDATE,
  PATH_ADMIN_EXAM,
  PATH_ADMIN_EXAMINATION,
  PATH_ADMIN_EXAM_PARTICIPATE,
  PATH_ADMIN_EXAM_RESULT,
  PATH_ADMIN_EXAM_VENUE,
  PATH_ADMIN_FOLDER,
  PATH_ADMIN_GROUP,
  PATH_ADMIN_HOME,
  PATH_ADMIN_QUESTION,
} from "../../services/constants/route/router";

const AdminLayout = ({
  children,
  gap = false,
  ...props
}: { gap?: boolean } & ChildrenWithProps) => {
  const { pathname } = useObjectRoutes();
  let breadcrumbLabel = "";
    let childBreadcrumbLabel: string | React.ReactNode = "";
  let breadcrumbLabelHref = "";

  for (const sidebarItem of ADMIN_SIDEBAR_DATA) {
    const breadcrumb = sidebarItem.childs.find(
      (childSidebarItem) => childSidebarItem.href === pathname
    );
    childBreadcrumbLabel = breadcrumb?.label || "";
    breadcrumbLabel = sidebarItem.label;

    // specific rule
    // candidate
    if (pathname === PATH_ADMIN_HOME.DEFAULT) breadcrumbLabel = "";
    if (pathname === PATH_ADMIN_CANDIDATE.DEFAULT) breadcrumbLabel = "";
    if (pathname.includes("/admin/candidate/details")) {
      breadcrumbLabel = "受験者管理";
      breadcrumbLabelHref = PATH_ADMIN_CANDIDATE.DEFAULT;
      childBreadcrumbLabel = "受験者更新";
    }

    if (pathname === PATH_ADMIN_CANDIDATE.CREATE) {
      breadcrumbLabel = "受験者一覧";
      breadcrumbLabelHref = PATH_ADMIN_CANDIDATE.DEFAULT;
      childBreadcrumbLabel = "受講者新規追加";
    }
    // resource-management
    if (pathname === PATH_ADMIN_FOLDER.FOLDER) breadcrumbLabel = "";
    if (pathname.includes("/admin/resource-management/folder/file")) {
      breadcrumbLabel = "カテゴリ一覧 ";
      breadcrumbLabelHref = PATH_ADMIN_FOLDER.FOLDER;
      childBreadcrumbLabel = "ファイル管理";
    }
    if (pathname === PATH_ADMIN_FOLDER.FILE) breadcrumbLabel = "";

    // group
    if (pathname.includes("/admin/group")) {
      breadcrumbLabel = "グループ一覧";
      breadcrumbLabelHref = PATH_ADMIN_GROUP.DEFAULT;
      childBreadcrumbLabel = "";

      if (pathname.includes("setting")) childBreadcrumbLabel = "グループ設定";
      if (pathname.includes("detail")) childBreadcrumbLabel = "グループ管理";
    }

    if (pathname.includes("/admin/question")) {
      breadcrumbLabel = "問題一覧";
      breadcrumbLabelHref = PATH_ADMIN_QUESTION.DEFAULT;
      childBreadcrumbLabel = "";

      if (pathname.includes(PATH_ADMIN_QUESTION.AUTHORING)) {
        childBreadcrumbLabel = "オーサリング";
      }
    }

    if (pathname.includes("/admin/exam")) {
      breadcrumbLabel = "テスト一覧";
      breadcrumbLabelHref = PATH_ADMIN_EXAM.DEFAULT;
      childBreadcrumbLabel = "";

      if (pathname.includes(PATH_ADMIN_EXAM.AUTHORING)) {
        childBreadcrumbLabel = "オーサリング";
      }
    }

    if (pathname.includes("/admin/exam/")) {
      breadcrumbLabel = "テスト一覧";
      breadcrumbLabelHref = PATH_ADMIN_EXAM.DEFAULT;
      childBreadcrumbLabel = "";

      if (pathname.includes(PATH_ADMIN_EXAM.AUTHORING)) {
        childBreadcrumbLabel = "オーサリング";
      }
    }

    if (pathname.includes("/admin/venue-management")) {
      breadcrumbLabel = "試験管理";
      breadcrumbLabelHref = PATH_ADMIN_EXAM_VENUE.VENUE_LIST;
      childBreadcrumbLabel = "";

      switch (pathname) {
        case PATH_ADMIN_EXAM_VENUE.ADD_NEW_VENUE:
          childBreadcrumbLabel = "会場追加";
          break;

        case PATH_ADMIN_EXAM_VENUE.VENUE_LIST:
          childBreadcrumbLabel = "会場一覧";
          break;

        case PATH_ADMIN_EXAM_VENUE.DETAIL_VENUE:
          childBreadcrumbLabel = "アップデート情報";
          break;
      }
    }

    if (pathname.includes("/admin/examination/")) {
      breadcrumbLabel = "試験管理";
      breadcrumbLabelHref = PATH_ADMIN_EXAMINATION.LIST;
      childBreadcrumbLabel = "";

      switch (pathname) {
        case PATH_ADMIN_EXAMINATION.CREATE:
        case PATH_ADMIN_EXAMINATION.DETAIL:
          childBreadcrumbLabel = "新規試験追加 ";
          break;
      }
    }

    if (pathname.includes("/admin/exam-participate/")) {
      breadcrumbLabel = "受験管理";
      breadcrumbLabelHref = PATH_ADMIN_EXAM_PARTICIPATE.LIST;
      childBreadcrumbLabel = "";
    }

    if (pathname.includes("/admin/exam-result/")) {
      breadcrumbLabel = "受験一覧";
      breadcrumbLabelHref = PATH_ADMIN_EXAM_RESULT.EXAM_LIST;
      childBreadcrumbLabel = "";
      if (pathname.includes('list-candidate')) {
        childBreadcrumbLabel = "受験者一覧";
      }
      if (pathname.includes('candidate-exam')) {
        const parentPath = adjustPath(pathname, "candidate-exam", "list-candidate")
        childBreadcrumbLabel = 
          <div className="text-secondary text-[14px] leading-[20px] tracking-[0.25px] gap-[10px] flex items-center justify-start">
            <div className="">
              <Link to={parentPath}>{"受験者一覧"}</Link>
            </div>
            <BiSolidRightArrow />
            <div className="">{"結果詳細"}</div>
          </div>
      }
    }

    if (breadcrumb) break;
  }

  function adjustPath(path: string, textToAdjust: string, adjustText: string): string {
      const segments = path.split('/');

    let adjustedSegments = [...segments]; // Make a copy of segments

    // Find the index of the segment containing the 'fromText'
    const index = segments.findIndex(segment => segment === textToAdjust);

    // If the 'fromText' is found, replace subsequent segments
    if (index !== -1) {
        // Replace segments from 'fromText' to the end with 'toText'
        adjustedSegments = adjustedSegments.slice(0, index).concat([adjustText]);
    }

    // Join the adjusted segments back into a path string
    const adjustedPath = adjustedSegments.join('/');

    return adjustedPath;
  }


  return (
    <>
      <ScrollToTop />
      <RequiredAuth>
        <CheckRoleAdmin>
          <section className="min-h-screen noto-sans" {...props}>
            <div
              className=""
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                position: "relative",
                zIndex: "10",
              }}
            >
              <Header />
            </div>
            <div className="w-full flex min-h-screen bg-success-lighter">
              <div className="w-full flex justify-between">
                <div className="flex w-full items-start">
                  <Sidebar />
                  <div className="w-full px-[32px]">
                    <div className="text-secondary text-[14px] leading-[20px] tracking-[0.25px] gap-[10px] flex items-center justify-start mt-[10px]">
                      <div className="">HOME</div>
                      {breadcrumbLabel && <BiSolidRightArrow />}
                      <div className="">
                        <Link to={breadcrumbLabelHref}>{breadcrumbLabel}</Link>
                      </div>
                      {childBreadcrumbLabel && <BiSolidRightArrow />}
                      <div className="">{childBreadcrumbLabel}</div>
                    </div>
                    <div className="mt-[30px]">{children}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CheckRoleAdmin>
      </RequiredAuth>
    </>
  );
};

export default AdminLayout;
