import { Object } from '../types/globals';

const useStorage = () => {
  const getLocalStorage = (name: string) => JSON.parse(localStorage.getItem(name) || '""');
  const setLocalStorage = (name: string, data: Object | string) =>
    localStorage.setItem(name, JSON.stringify(data));

  const removeLocalStorage = (names: string[]) => {
    names.forEach(element => {
      localStorage.removeItem(element)
    });
  }
  return { getLocalStorage, setLocalStorage, removeLocalStorage };
};

export { useStorage };
