import React, { useContext, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { ChildrenWithProps } from '../types/globals';

import type { IUser, User } from '../types/user';

interface AuthContextType {
  user: User;
  setUser: (user: User) => void;
}

export const AuthContext = React.createContext<AuthContextType>({
  user: {},
  setUser: () => {},
});

const AuthProvider = ({ children }: ChildrenWithProps) => {
  const [user, setUser] = useState<User>({})
  return (
    <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuthContext = () => useContext(AuthContext);
