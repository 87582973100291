import React from "react";
import { ChildrenWithProps } from "../../types/globals";
import Header from "./Header";
import RequiredAuth from "../../middleware/auth";
import CheckRoleCandidate from "../../middleware/checkRoleCandidate";
import { useObjectRoutes } from "../../hooks/useObjectRoutes";
import { PATH_CANDIDATE_HOME } from "../../services/constants/route/router";
import { BiSolidRightArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { CANDIDATE_SIDEBAR_DATA } from "../../services/constants/candidate/sidebar";

const CandidateLayout = ({
  sidebar,
  children,
  gap = false,
  cover = false,
  ...props
}: {
  sidebar?: JSX.Element;
  gap?: boolean;
  cover?: boolean;
} & ChildrenWithProps) => {
  const { pathname } = useObjectRoutes();
  let breadcrumbLabel = "";
  let childBreadcrumbLabel = "";
  let breadcrumbLabelHref = "";

  for (const sidebarItem of CANDIDATE_SIDEBAR_DATA) {
    const breadcrumb = sidebarItem.childs.find(
      (childSidebarItem) => childSidebarItem.href === pathname
    );
    childBreadcrumbLabel = breadcrumb?.label || "";
    breadcrumbLabel = sidebarItem.label;

    // Home
    if (pathname === PATH_CANDIDATE_HOME.DEFAULT) breadcrumbLabel = "";
    //Register Examination
    if (pathname.includes("/home/register-examination")) {
      breadcrumbLabel = "試験申込";
      breadcrumbLabelHref = PATH_CANDIDATE_HOME.REGISTER_EXAMINATION;
    }
 
    if (breadcrumb) break;
  }


  return (
    <RequiredAuth>
      <CheckRoleCandidate>
        <section className="min-h-screen" {...props}>
          <div
            className=""
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              position: "relative",
              zIndex: "10",
            }}
          >
            <Header />
          </div>
          <div className="w-full flex min-h-screen bg-success-lighter">
            <div className="w-full flex justify-between">
              <div className="flex w-full items-start">
                <Sidebar />
                <div className="w-full px-[32px]">
                  <div className="text-secondary text-[14px] leading-[20px] tracking-[0.25px] gap-[10px] flex items-center justify-start mt-[10px]">
                    <div className="">HOME</div>
                    {breadcrumbLabel && <BiSolidRightArrow />}
                    <div className="">
                      <Link to={breadcrumbLabelHref}>{breadcrumbLabel}</Link>
                    </div>
                    {childBreadcrumbLabel && <BiSolidRightArrow />}
                    <div className="">{childBreadcrumbLabel}</div>
                  </div>
                  <div className="mt-[30px]">{children}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CheckRoleCandidate>
    </RequiredAuth>
  );
};

export default CandidateLayout;
