import { useLocation } from 'react-router';
import { useSearchParams, useNavigate } from 'react-router-dom';
import type { Object } from '../types/globals';
import qs from 'qs';

export const useObjectRoutes = () => {
  const location = useLocation();
  const stringSearchParam = location.search.substring(1);
  const stringHashParam = location.hash.substring(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const getParamValue = (field: string) => {
    return searchParams.get(field);
  };

  const searchParamsObject = qs.parse(stringSearchParam);

  const paramStringByObject = (paramsObj: Object) => {
    const searchParams = new URLSearchParams();
    Object.keys(paramsObj).forEach((key) => searchParams.append(key, paramsObj[key]));
  };
  const pathname = location.pathname;

  return {
    navigate,
    getParamValue,
    paramStringByObject,
    stringSearchParam,
    searchParamsObject,
    pathname,
    stringHashParam,
    searchParams,
    setSearchParams,
  };
};
