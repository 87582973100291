import React, { useState } from "react";
import {
  candidateToolIsOpen,
  isPropertyExists,
} from "../../../../services/utils/candidate/examinationTest";
import { SETTING_PROPERTY_NAMES } from "../../../../services/constants/admin/pages/exam";
import { useExaminationTestContext } from "../../../../context/ExaminationTestContext";
import Calculator from "../../../commons/icons/ExaminationTest/Calculator";
import MagnifyingGlassTool from "./ExaminationTools/MagnifyingGlassTool";
import Brusher from "../../../commons/icons/ExaminationTest/Brusher";
import {
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
} from "react-icons/io";
import { BiLeftArrowCircle, BiRightArrowCircle } from "react-icons/bi";
import { isEmptyObj, isUsableArr } from "../../../../services/helpers/etc";
import {
  CANDIDATE_EXAM_MESSAGE,
  STATUS_EXAM_TEST_ITEM,
} from "../../../../services/constants/candidate/examinationTest";
import { swalMessage } from "../../../../services/helpers/swal";
import CalculatorTool from "./ExaminationTools/Calculator";
import classNames from "classnames";

const ExaminationTestFooter = () => {
  const {
    examPartProperties,
    examSectionProperties,
    examPartSelected,
    examItemSelected,
    examSectionSelected,
    filterQuestionKey,
    examPartQuestions,
    textSize,
    setTextSize,
    setFilterQuestionKey,
    setExamItemSelected,
    setExamSectionSelected,
    setQuestionProblems,
    isLastQuestionOfSection,
    isFirstQuestionOfSection,
    openingTools,
    setOpeningTools,
    isChangeAnswer,
    setExamPartQuestions,
    isFirstSectionOfExamPart,
    isLastSectionOfExamPart
  } = useExaminationTestContext();

  const isQuestionUnanswered = () => {
    if (
      isPropertyExists(
        SETTING_PROPERTY_NAMES.UNANSWERED_WARNING,
        examSectionProperties.navigation_warning || []
      )
    ) {
      examPartQuestions.forEach((question) => {
        if (
          question.exam_part_id === filterQuestionKey.sectionId &&
          question.status !== STATUS_EXAM_TEST_ITEM.DONE
        ) {
          throw new Error(CANDIDATE_EXAM_MESSAGE.UNANSWER_IN_EXAM_SECTION);
        }
      });
    }
  };

  const handleClickNextQuestion = () => {
    try {
      if (
        isEmptyObj(examSectionSelected) &&
        filterQuestionKey.sectionId === 0 &&
        filterQuestionKey.itemId === 0
      ) {

        if (!isUsableArr(examPartQuestions)) return;

        const firstQuestion = examPartQuestions[0];
        setFilterQuestionKey({
          sectionId: firstQuestion.exam_part_id || 0,
          itemId: firstQuestion.id || 0,
        });
        const findExamSection = examPartSelected.exam_sections?.find(
          (examSection) => examSection.id === firstQuestion.exam_part_id
        );

        if (!findExamSection) return;

        setExamSectionSelected(findExamSection);
        return;
      }

      if (
        !examPartSelected.exam_sections?.length ||
        !examSectionSelected.exam_items?.length
      )
        return;

      if (
        (isEmptyObj(examSectionProperties) ||
          isEmptyObj(examSectionProperties.item_session_controls) ||
          !examSectionProperties.item_session_controls?.is_skip) &&
        examItemSelected.status !== STATUS_EXAM_TEST_ITEM.DONE
      ) {
        throw new Error(CANDIDATE_EXAM_MESSAGE.CHOOOSE_ANSWER);
      }

      const findIndexExamSection = examPartSelected.exam_sections?.findIndex(
        (examSection) => examSection.id === examSectionSelected.id
      );

      const findIndexExamItem = examSectionSelected.exam_items?.findIndex(
        (examItem) => examItem.id === examItemSelected.id
      );

      if (
        findIndexExamItem === undefined ||
        findIndexExamItem === -1 ||
        findIndexExamSection === undefined ||
        findIndexExamSection === -1
      )
        return;

      if (
        findIndexExamItem === examSectionSelected.exam_items?.length - 1 &&
        findIndexExamSection < examPartSelected.exam_sections?.length - 1
      ) {
        const nextExamSection =
          examPartSelected.exam_sections[findIndexExamSection + 1];
        if (nextExamSection && nextExamSection.exam_items?.length) {
          const examItemFirst = nextExamSection.exam_items[0];
          if (
            isLastQuestionOfSection(
              filterQuestionKey.sectionId,
              filterQuestionKey.itemId
            )
          ) {
            isQuestionUnanswered();
          }
          setFilterQuestionKey({
            sectionId: nextExamSection.id || 0,
            itemId: examItemFirst.id || 0,
          });
          setExamSectionSelected(nextExamSection);
          setExamItemSelected(examItemFirst);
          setQuestionProblems(examItemFirst.question_data?.problems || []);
        }
      }

      if (findIndexExamItem < examSectionSelected.exam_items?.length - 1) {
        const nextExamItem =
          examSectionSelected.exam_items[findIndexExamItem + 1];
        if (nextExamItem) {
          setFilterQuestionKey({
            ...filterQuestionKey,
            itemId: nextExamItem.id || 0,
          });
          setExamItemSelected(nextExamItem);
          setQuestionProblems(nextExamItem.question_data?.problems || []);
        }
      }

      if (isChangeAnswer) {
        setExamPartQuestions(
          examPartQuestions.map((question) => {
            if (
              question.exam_part_id === filterQuestionKey.sectionId &&
              question.id === filterQuestionKey.itemId
            ) {
              const timesAnswer = (question.times_answer || 0) + 1;
              return { ...question, times_answer: timesAnswer };
            }

            return question;
          })
        );
      }
    } catch (error) {
      const message = (error as Error).message;
      swalMessage("", message, "warning", {
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const handleClickBackQuestion = () => {
    if (
      !examPartSelected.exam_sections?.length ||
      !examSectionSelected.exam_items?.length
    )
      return;

    const findIndexExamSection = examPartSelected.exam_sections?.findIndex(
      (examSection) => examSection.id === examSectionSelected.id
    );

    const findIndexExamItem = examSectionSelected.exam_items?.findIndex(
      (examItem) => examItem.id === examItemSelected.id
    );

    if (
      findIndexExamItem === undefined ||
      findIndexExamItem === -1 ||
      findIndexExamSection === undefined ||
      findIndexExamSection === -1
    )
      return;

    if (
      findIndexExamItem === 0 &&
      findIndexExamSection <= examPartSelected.exam_sections?.length - 1
    ) {
      const nextExamSection =
        examPartSelected.exam_sections[findIndexExamSection - 1];
      if (nextExamSection && nextExamSection.exam_items?.length) {
        const examItem =
          nextExamSection.exam_items[nextExamSection.exam_items.length - 1];
        setFilterQuestionKey({
          sectionId: nextExamSection.id || 0,
          itemId: examItem.id || 0,
        });
        setExamSectionSelected(nextExamSection);
        setExamItemSelected(examItem);
        setQuestionProblems(examItem.question_data?.problems || []);
      }
    }

    if (findIndexExamItem <= examSectionSelected.exam_items?.length - 1) {
      const backExamItem =
        examSectionSelected.exam_items[findIndexExamItem - 1];

      if (backExamItem) {
        setFilterQuestionKey({
          ...filterQuestionKey,
          itemId: backExamItem.id || 0,
        });
        setExamItemSelected(backExamItem);
        setQuestionProblems(backExamItem.question_data?.problems || []);
      }
    }

    if (isChangeAnswer) {
      setExamPartQuestions(
        examPartQuestions.map((question) => {
          if (
            question.exam_part_id === filterQuestionKey.sectionId &&
            question.id === filterQuestionKey.itemId
          ) {
            const timesAnswer = (question.times_answer || 0) + 1;
            return { ...question, times_answer: timesAnswer };
          }

          return question;
        })
      );
    }
  };

  // Candidate tools
  const candidateToggleTool = (toolName: string) => {
    if (candidateToolIsOpen(toolName, openingTools)) {
      setOpeningTools(openingTools.filter((tool) => tool !== toolName));
      return;
    }

    setOpeningTools([...openingTools, toolName]);
  };

  return (
    <>
      <div className="flex items-center justify-between mt-[80px] pb-[40px]">
        <div className="flex items-center gap-x-[10px]">
          {/* Calculator tool */}
          {isPropertyExists(
            SETTING_PROPERTY_NAMES.SHOW_CALCULATOR,
            examPartProperties.candidate_tools || []
          ) ? (
            <button
              className={classNames(
                `p-[10px] flex items-center gap-x-[5px] border border-secondary-light rounded-[5px] bg-white`,
                {
                  "!bg-secondary-light": candidateToolIsOpen(
                    SETTING_PROPERTY_NAMES.SHOW_CALCULATOR,
                    openingTools
                  ),
                }
              )}
              onClick={() =>
                candidateToggleTool(SETTING_PROPERTY_NAMES.SHOW_CALCULATOR)
              }
            >
              <span className="text-[14px] leading-[20px]">計算機</span>
              <Calculator />
            </button>
          ) : (
            <></>
          )}
          {/* Magnifying glass tool */}
          {/* {isPropertyExists(
            SETTING_PROPERTY_NAMES.SHOW_ZOOM_GLASS,
            examPartProperties.candidate_tools || []
          ) ? (
            <MagnifyingGlassTool />
          ) : (
            <></>
          )} */}

          {/* Hight light tool */}
          {isPropertyExists(
            SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT,
            examPartProperties.candidate_tools || []
          ) ? (
            <button
              className={classNames(
                "p-[10px] flex items-center gap-x-[5px] border border-secondary-light rounded-[5px] bg-white",
                {
                  "!bg-secondary-light": candidateToolIsOpen(
                    SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT,
                    openingTools
                  ),
                }
              )}
              onClick={() =>
                candidateToggleTool(SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT)
              }
            >
              <span className="text-[14px] leading-[20px]">
                ハイライトマーカー
              </span>
              <Brusher />
            </button>
          ) : (
            <></>
          )}
        </div>

        {/* Zoom tool */}
        {/* {isPropertyExists(
          SETTING_PROPERTY_NAMES.SHOW_ZOOM_TOOL,
          examPartProperties.candidate_tools || []
        ) ? (
          <div className="flex items-center gap-x-[15px]">
            <div className="flex items-center gap-x-[5px]">
              <span className="text-[20px] font-[600] leading-[36px] cursor-default">
                A
              </span>
              <IoIosRemoveCircleOutline size={24} color="#7A7A7A" />
            </div>
            <input
              type="range"
              name=""
              id=""
              className="w-[200px] text-primary-synch bg-primary-synch"
              min={12}
              max={25}
              value={textSize}
              onChange={(e) => setTextSize(Number(e.target.value))}
            />
            <div className="flex items-center gap-x-[5px]">
              <IoIosAddCircleOutline size={24} color="#7A7A7A" />
              <span className="text-[35px] font-[600] leading-[36px] cursor-default">
                A
              </span>
            </div>
          </div>
        ) : (
          <></>
        )} */}

        <div className="flex items-center gap-x-[10px]">
          {(isFirstSectionOfExamPart() && isFirstQuestionOfSection(filterQuestionKey.sectionId, filterQuestionKey.itemId)) ?
            <></> :
            <button
              className="w-[150px] h-[40px] py-[10px] flex items-center justify-center gap-x-[5px] bg-secondary-light text-secondary-dark rounded-[5px]"
              onClick={handleClickBackQuestion}
            >
              <BiLeftArrowCircle size={20} />
              <span className="text-[16px] font-[500] leading-[24px]">
                前の問題
              </span>
            </button>
          }
          {(isLastSectionOfExamPart() && isLastQuestionOfSection(filterQuestionKey.sectionId, filterQuestionKey.itemId)) ?
            <></> :
            <button
              className="w-[150px] h-[40px] py-[10px] flex items-center justify-center  gap-x-[5px] text-[16px] text-white font-[600] leading-[24px] bg-danger rounded-[5px]"
              onClick={handleClickNextQuestion}
            >
              <span className="text-[16px] font-[600] leading-[24px]">
                次の問題
              </span>
              <BiRightArrowCircle color="#fff" size={20} />
            </button>
          }
          
          
        </div>
      </div>
      {candidateToolIsOpen(
        SETTING_PROPERTY_NAMES.SHOW_CALCULATOR,
        openingTools
      ) ? (
        <CalculatorTool />
      ) : (
        <></>
      )}
    </>
  );
};

export default ExaminationTestFooter;
