import { FiFolderPlus, FiMove } from "react-icons/fi";
import { TbFilePlus } from "react-icons/tb";
import { PiCopy, PiCopySimple, PiTrashBold } from "react-icons/pi";
import { useQuestionListContext } from "../../../../context/Question/ListQuestionContext";
import { NAME_ACTION_ASIDE_QUESTION } from "../../../../services/constants/admin/pages/question";
import { swalError, swalConfirm } from "../../../../services/helpers/swal";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { Question, QuestionForm } from "../../../../types/admin/question";
import { DELETE_CONFIRM_TITLE } from "../../../../services/constants/message";
import { useState } from "react";

const SideAction = () => {
  const actionData = [
    {
      icon: <FiFolderPlus />,
      label: "新規フォルダー",
      action: NAME_ACTION_ASIDE_QUESTION.CREATE_FOLDER,
    },
    {
      icon: <TbFilePlus />,
      label: "新規問題",
      action: NAME_ACTION_ASIDE_QUESTION.CREATE_FILE,
    },
    {
      icon: <PiCopy />,
      label: "複製",
      action: NAME_ACTION_ASIDE_QUESTION.DUPLICATE,
    },
    {
      icon: <PiCopySimple />,
      label: "コピー",
      action: NAME_ACTION_ASIDE_QUESTION.COPY,
    },
    {
      icon: <PiTrashBold />,
      label: "削除",
      action: NAME_ACTION_ASIDE_QUESTION.DELETE,
    },
    {
      icon: <FiMove />,
      label: "移動",
      action: NAME_ACTION_ASIDE_QUESTION.MOVE,
    },
  ];

  const {
    selectedQuestion,
    setSelectedQuestion,
    questions,
    sideAction,
    setQuestions,
    setSideAction,
  } = useQuestionListContext();
  const [waitingAPI, setWaitingAPI] = useState<boolean>(false);

  const unClickableClasses = (index: number) => {
    return index !== 0 && index !== 1 && !selectedQuestion?.id
      ? "!pointer-events-none"
      : "";
  };

  const handleAction = async (actionName: string) => {
    if (waitingAPI) return;

    let currentParentId = null;
    if (selectedQuestion?.id) {
      if (!!selectedQuestion.is_file) {
        currentParentId = selectedQuestion.parent_id;
      } else {
        currentParentId = selectedQuestion.id;
      }
    }

    let submitData: QuestionForm = {
      parent_id: currentParentId,
    };
    switch (actionName) {
      case NAME_ACTION_ASIDE_QUESTION.CREATE_FOLDER:
        setWaitingAPI(true); 
        submitData = {
          ...submitData,
          is_file: false,
        };
        await request.post(
          API.ADMIN_QUESTION.CREATE,
          submitData,
          (res: Question) => setQuestions([...questions, res])
        );
        setWaitingAPI(false);

        break;

      case NAME_ACTION_ASIDE_QUESTION.CREATE_FILE:
        setWaitingAPI(true); 
        submitData = {
          ...submitData,
          is_file: true,
        };
        await request.post(
          API.ADMIN_QUESTION.CREATE,
          submitData,
          (res: Question) => setQuestions([...questions, res])
        );
        setWaitingAPI(false);

        break;

      case NAME_ACTION_ASIDE_QUESTION.DUPLICATE:
        setWaitingAPI(true); 
        submitData = {
          id: selectedQuestion.id,
        };
        await request.post(
          API.ADMIN_QUESTION.DUPLICATE,
          submitData,
          (res: Question[]) => setQuestions([...res, ...questions])
        );
        setWaitingAPI(false);

        break;

      case NAME_ACTION_ASIDE_QUESTION.COPY:
        setSideAction(actionName === sideAction ? "" : actionName);
        break;

      case NAME_ACTION_ASIDE_QUESTION.DELETE:
        setWaitingAPI(true); 
        submitData = { id: selectedQuestion.id };
        swalConfirm(
          async () => {
            await request.delete(
              API.ADMIN_QUESTION.DELETE,
              submitData,
              (res) => {
                setQuestions(
                  questions.filter(
                    (question) => question.id !== selectedQuestion.id
                  )
                );
                setSelectedQuestion(res?.id);
              },
              () => {},
              { withError: true }
            );
          },
          selectedQuestion.is_file
            ? DELETE_CONFIRM_TITLE.QUESTION_FILE
            : DELETE_CONFIRM_TITLE.QUESTION_FOLDER
        );
        setWaitingAPI(false);
        break;

      case NAME_ACTION_ASIDE_QUESTION.MOVE:
        setSideAction(actionName === sideAction ? "" : actionName);
        break;

      default:
        swalError();
        break;
    }
  };

  return (
    <div className="flex flex-wrap justify-center gap-[5px] w-full max-w-[271px]">
      {actionData.map((item, index) => (
        <div
          key={index}
          className={`w-full max-w-[125px] h-0 min-h-[35px] text-[11px] text-secondary-dark leading-[120%] bg-white rounded-[5px] flex items-center justify-start gap-[4px] cursor-pointer pl-[7px] ${unClickableClasses(
            index
          )} ${sideAction === item.action ? "bg-[rgba(143,194,213,0.21)]" : ""}
          ${
            sideAction === item.action
              ? "hover:bg-[rgba(143,194,213,0.21)]"
              : "hover:bg-primary-extralight hover:shadow-md"
          }
          `}
          onClick={() => handleAction(item.action)}
        >
          <div className="text-secondary text-[20px]">{item.icon}</div>
          <div className="">{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export default SideAction;
