import React from "react";
import { WithChildren } from "../../../../types/globals";

type InputItemProps = {
  label: string;
  required?: boolean;
  className?: string;
} & WithChildren;

const InputItem = ({
  label,
  required = true,
  className,
  children,
}: InputItemProps) => {
  return (
    <div className={`w-full flex items-center justify-between gap-x-[9px] ${className}`}>
      <div
        className={`flex items-start ${required ? "justify-between" : "justify-start"
          } px-[10px] py-[12px] bg-success-lighter basis-[25%] h-full`}
      >
        <span>{label}</span>
        {required && (
          <span className="bg-danger px-[6px] py-[3px] text-[9px] text-white text-center rounded-[3px]">
            必須
          </span>
        )}
      </div>
      <div className="py-[3px] flex-1">{children}</div>
    </div>
  );
};

export default InputItem;
