import React from "react";

const ELearning = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M19.25 0.5H2.75C2.15326 0.5 1.58097 0.737053 1.15901 1.15901C0.737053 1.58097 0.5 2.15326 0.5 2.75V13.25C0.5 13.8467 0.737053 14.419 1.15901 14.841C1.58097 15.2629 2.15326 15.5 2.75 15.5H19.25C19.8467 15.5 20.419 15.2629 20.841 14.841C21.2629 14.419 21.5 13.8467 21.5 13.25V2.75C21.5 2.15326 21.2629 1.58097 20.841 1.15901C20.419 0.737053 19.8467 0.5 19.25 0.5ZM20 13.25C20 13.4489 19.921 13.6397 19.7803 13.7803C19.6397 13.921 19.4489 14 19.25 14H2.75C2.55109 14 2.36032 13.921 2.21967 13.7803C2.07902 13.6397 2 13.4489 2 13.25V2.75C2 2.55109 2.07902 2.36032 2.21967 2.21967C2.36032 2.07902 2.55109 2 2.75 2H19.25C19.4489 2 19.6397 2.07902 19.7803 2.21967C19.921 2.36032 20 2.55109 20 2.75V13.25Z"
        fill={props.color || "#5A5A5A"}
      />
      <path
        d="M9.5675 7.36219C9.88876 7.05021 10.1096 6.64946 10.2018 6.21123C10.294 5.77301 10.2533 5.31724 10.085 4.90227C9.91665 4.48729 9.62831 4.13199 9.25688 3.88183C8.88545 3.63168 8.44782 3.49805 8 3.49805C7.55218 3.49805 7.11455 3.63168 6.74312 3.88183C6.37169 4.13199 6.08335 4.48729 5.91502 4.90227C5.74668 5.31724 5.706 5.77301 5.79819 6.21123C5.89038 6.64946 6.11124 7.05021 6.4325 7.36219C4.76 7.81219 3.5 9.60469 3.5 11.7497C3.5 11.9486 3.57902 12.1394 3.71967 12.28C3.86032 12.4207 4.05109 12.4997 4.25 12.4997C4.44891 12.4997 4.63968 12.4207 4.78033 12.28C4.92098 12.1394 5 11.9486 5 11.7497C5 10.0922 6.0125 8.74969 7.25 8.74969H8.75C9.9875 8.74969 11 10.0922 11 11.7497C11 11.9486 11.079 12.1394 11.2197 12.28C11.3603 12.4207 11.5511 12.4997 11.75 12.4997C11.9489 12.4997 12.1397 12.4207 12.2803 12.28C12.421 12.1394 12.5 11.9486 12.5 11.7497C12.5 9.60469 11.24 7.81219 9.5675 7.36219ZM8 4.99969C8.14834 4.99969 8.29334 5.04367 8.41668 5.12608C8.54001 5.20849 8.63614 5.32563 8.69291 5.46267C8.74968 5.59972 8.76453 5.75052 8.73559 5.896C8.70665 6.04149 8.63522 6.17513 8.53033 6.28002C8.42544 6.3849 8.2918 6.45634 8.14632 6.48527C8.00083 6.51421 7.85003 6.49936 7.71299 6.4426C7.57594 6.38583 7.45881 6.2897 7.3764 6.16636C7.29399 6.04303 7.25 5.89802 7.25 5.74969C7.25 5.55077 7.32902 5.36001 7.46967 5.21936C7.61032 5.0787 7.80109 4.99969 8 4.99969Z"
        fill={props.color || "#5A5A5A"}
      />
      <path
        d="M17.75 3.5H15.5C15.3011 3.5 15.1103 3.57902 14.9697 3.71967C14.829 3.86032 14.75 4.05109 14.75 4.25C14.75 4.44891 14.829 4.63968 14.9697 4.78033C15.1103 4.92098 15.3011 5 15.5 5H17.75C17.9489 5 18.1397 4.92098 18.2803 4.78033C18.421 4.63968 18.5 4.44891 18.5 4.25C18.5 4.05109 18.421 3.86032 18.2803 3.71967C18.1397 3.57902 17.9489 3.5 17.75 3.5Z"
        fill={props.color || "#5A5A5A"}
      />
      <path
        d="M17.75 6.5H13.25C13.0511 6.5 12.8603 6.57902 12.7197 6.71967C12.579 6.86032 12.5 7.05109 12.5 7.25C12.5 7.44891 12.579 7.63968 12.7197 7.78033C12.8603 7.92098 13.0511 8 13.25 8H17.75C17.9489 8 18.1397 7.92098 18.2803 7.78033C18.421 7.63968 18.5 7.44891 18.5 7.25C18.5 7.05109 18.421 6.86032 18.2803 6.71967C18.1397 6.57902 17.9489 6.5 17.75 6.5Z"
        fill={props.color || "#5A5A5A"}
      />
      <path
        d="M17.75 9.5H15.5C15.3011 9.5 15.1103 9.57902 14.9697 9.71967C14.829 9.86032 14.75 10.0511 14.75 10.25C14.75 10.4489 14.829 10.6397 14.9697 10.7803C15.1103 10.921 15.3011 11 15.5 11H17.75C17.9489 11 18.1397 10.921 18.2803 10.7803C18.421 10.6397 18.5 10.4489 18.5 10.25C18.5 10.0511 18.421 9.86032 18.2803 9.71967C18.1397 9.57902 17.9489 9.5 17.75 9.5Z"
        fill={props.color || "#5A5A5A"}
      />
      <path
        d="M19.25 17H2.75C2.15326 17 1.58097 17.2371 1.15901 17.659C0.737053 18.081 0.5 18.6533 0.5 19.25C0.5 19.8467 0.737053 20.419 1.15901 20.841C1.58097 21.2629 2.15326 21.5 2.75 21.5H19.25C19.8467 21.5 20.419 21.2629 20.841 20.841C21.2629 20.419 21.5 19.8467 21.5 19.25C21.5 18.6533 21.2629 18.081 20.841 17.659C20.419 17.2371 19.8467 17 19.25 17ZM19.25 20H2.75C2.55109 20 2.36032 19.921 2.21967 19.7803C2.07902 19.6397 2 19.4489 2 19.25C2 19.0511 2.07902 18.8603 2.21967 18.7197C2.36032 18.579 2.55109 18.5 2.75 18.5H19.25C19.4489 18.5 19.6397 18.579 19.7803 18.7197C19.921 18.8603 20 19.0511 20 19.25C20 19.4489 19.921 19.6397 19.7803 19.7803C19.6397 19.921 19.4489 20 19.25 20Z"
        fill={props.color || "#5A5A5A"}
      />
    </svg>
  );
};
export default ELearning;
