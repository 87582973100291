import React from "react";

const City = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.color || "#BEBEBE"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.51173 8.44254C7.81961 8.71963 7.84457 9.19385 7.56748 9.50173L7.55748 9.51284C7.28038 9.82072 6.80616 9.84568 6.49828 9.56859C6.1904 9.29149 6.16544 8.81728 6.44254 8.50939L6.45254 8.49828C6.72963 8.1904 7.20385 8.16544 7.51173 8.44254Z"
        fill={props.color || "#BEBEBE"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5117 8.44254C11.8196 8.71963 11.8446 9.19385 11.5675 9.50173L11.5575 9.51284C11.2804 9.82072 10.8062 9.84568 10.4983 9.56859C10.1904 9.29149 10.1654 8.81728 10.4425 8.50939L10.4525 8.49828C10.7296 8.1904 11.2038 8.16544 11.5117 8.44254Z"
        fill={props.color || "#BEBEBE"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.51173 12.4425C7.81961 12.7196 7.84457 13.1938 7.56748 13.5017L7.55748 13.5128C7.28038 13.8207 6.80616 13.8457 6.49828 13.5686C6.1904 13.2915 6.16544 12.8173 6.44254 12.5094L6.45254 12.4983C6.72963 12.1904 7.20385 12.1654 7.51173 12.4425Z"
        fill={props.color || "#BEBEBE"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5117 12.4425C11.8196 12.7196 11.8446 13.1938 11.5675 13.5017L11.5575 13.5128C11.2804 13.8207 10.8062 13.8457 10.4983 13.5686C10.1904 13.2915 10.1654 12.8173 10.4425 12.5094L10.4525 12.4983C10.7296 12.1904 11.2038 12.1654 11.5117 12.4425Z"
        fill={props.color || "#BEBEBE"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.51173 16.4425C7.81961 16.7196 7.84457 17.1938 7.56748 17.5017L7.55748 17.5128C7.28038 17.8207 6.80616 17.8457 6.49828 17.5686C6.1904 17.2915 6.16544 16.8173 6.44254 16.5094L6.45254 16.4983C6.72963 16.1904 7.20385 16.1654 7.51173 16.4425Z"
        fill={props.color || "#BEBEBE"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5117 16.4425C11.8196 16.7196 11.8446 17.1938 11.5675 17.5017L11.5575 17.5128C11.2804 17.8207 10.8062 17.8457 10.4983 17.5686C10.1904 17.2915 10.1654 16.8173 10.4425 16.5094L10.4525 16.4983C10.7296 16.1904 11.2038 16.1654 11.5117 16.4425Z"
        fill={props.color || "#BEBEBE"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.25 20.25V9.75H15.75V12.25H17C17.4142 12.25 17.75 12.5858 17.75 13C17.75 13.4142 17.4142 13.75 17 13.75H15.75V16.25H17C17.4142 16.25 17.75 16.5858 17.75 17C17.75 17.4142 17.4142 17.75 17 17.75H15.75V20.25H20.25ZM14.25 20.25V3.75H9.75V5C9.75 5.41421 9.41421 5.75 9 5.75H3.75V20.25H14.25ZM15.75 8.25H20.4C21.1456 8.25 21.75 8.85442 21.75 9.6V20.4C21.75 21.1456 21.1456 21.75 20.4 21.75H3.6C2.85442 21.75 2.25 21.1456 2.25 20.4V5.6C2.25 4.85442 2.85442 4.25 3.6 4.25H8.25V3.6C8.25 2.85442 8.85442 2.25 9.6 2.25H14.4C15.1456 2.25 15.75 2.85442 15.75 3.6V8.25Z"
        fill={props.color || "#BEBEBE"}
      />
    </svg>
  );
};

export default City;
