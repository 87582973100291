import GroupTable from './Table';
import GroupTitle from '../Title';
import TableCommonAction from '../../commons/TableCommonAction';
import { scrollToTop } from '../../../../services/helpers/effect';
import { useState } from 'react';

const GroupList = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  scrollToTop();

  return (
    <>
      <TableCommonAction onCreate={() => setIsOpenModal(true)}/>
      <GroupTitle content="すべてのグループ" isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} withButton />
      <GroupTable className="mt-[15px] text-secondary-dark animate-[show_0.5s_ease-in-out]" />
    </>
  );
};

export default GroupList;
