import React from "react";
import { WithChildren } from "../../../../types/globals";

const ItemExaminationRegisterInfo = ({
  title,
  children,
}: { title: string } & WithChildren) => {
  return (
    <div className="w-full h-auto flex items-start">
      <div className="w-[40%] h-auto min-h-[60px] px-[24px] py-[20px] text-[14px] font-[700]">
        {title}
      </div>
      <div className="w-[60%] h-auto min-h-[60px] px-[24px] py-[20px] text-[14px]">
        {children}
      </div>
    </div>
  );
};

export default ItemExaminationRegisterInfo;
