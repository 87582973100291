import React, { useState, useContext } from "react";
import { ChildrenWithProps, SetStateAction } from "../types/globals";
import { Exam } from "../types/admin/exam";
import { useStorage } from "../hooks/useStorage";
import { sortArrayByKey } from "../services/helpers/parseData";
import { GroupGroup } from "../types/admin/examination";

interface ListTestContextType {
  tests: Exam[];
  setTests: (tests: Exam[]) => void;
  selectedTest: Exam;
  setSelectedTest: (examId?: Exam["id"]) => void;
  selectedTargetTest: Exam;
  setSelectedTargetTest: (examId?: Exam["id"]) => void;
  sideAction: string;
  setSideAction: SetStateAction<string>;
  groupGroups: GroupGroup[];
  setGroupGroups: React.Dispatch<React.SetStateAction<GroupGroup[]>>;
}

const ListTestContext = React.createContext<ListTestContextType>({
  tests: [],
  setTests: () => {},
  selectedTest: {},
  setSelectedTest: () => {},
  selectedTargetTest: {},
  setSelectedTargetTest: () => {},
  sideAction: "",
  setSideAction: () => {},
  groupGroups: [],
  setGroupGroups: () => {},
});

const ListTestProvider = ({ children }: ChildrenWithProps) => {
  const [groupGroups, setGroupGroups] = useState<GroupGroup[]>([
    { mainGroupId: 0, subGroupId: 0 },
  ]);
  const [tests, _setTests] = useState<Exam[]>([]);
  const [selectedTest, _setSelectedTest] = useState<Exam>({});
  const [selectedTargetTest, _setSelectedTargetTest] = useState<Exam>({});
  const [sideAction, setSideAction] = useState<string>("");
  const { setLocalStorage } = useStorage();
  const storageKey = "selectedTest";

  const setTests = (tests: Exam[]) => {
    const uniqueTests = tests.filter(
      (test, index, a) => a.findIndex((_test) => _test.id === test.id) === index
    );
    _setTests(sortArrayByKey(uniqueTests));
  };

  const setSelectedTest = (testId?: Exam["id"]) => {
    setLocalStorage(storageKey, String(testId) || "");

    if (!testId) {
      _setSelectedTest({});
      return;
    }
    _setSelectedTest(tests.find((test) => test.id === testId) || {});
  };

  const setSelectedTargetTest = (testId?: Exam["id"]) => {
    if (!testId) {
      _setSelectedTest({});
      return;
    }
    _setSelectedTargetTest(tests.find((test) => test.id === testId) || {});
  };

  return (
    <ListTestContext.Provider
      value={{
        tests,
        setTests,
        selectedTest,
        setSelectedTest,
        selectedTargetTest,
        setSelectedTargetTest,
        sideAction,
        setSideAction,
        groupGroups,
        setGroupGroups,
      }}
    >
      {children}
    </ListTestContext.Provider>
  );
};

export default ListTestProvider;

export const useListTestContext = () => useContext(ListTestContext);
