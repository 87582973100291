import React, { ChangeEvent } from "react";
import { PiArrowFatDown, PiArrowFatRight } from "react-icons/pi";
import { isUsableArr } from "../../../../../../services/helpers/etc";
import {
  OrderIssue,
  OrderProblem,
  QuestionProblem,
} from "../../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../../services/helpers/parseData";
import { handleProblemScoreCalculator } from "../../../../Question/AuthoringQuestion/QuestionEditorHandler";

const OrderQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled
}: {
  questionProblem: QuestionProblem;
  handleUpdateProblem: (problem: QuestionProblem) => void;
  isDisabled: boolean;
}) => {
  const orderQuestion: OrderProblem = questionProblem;
  const orderIssues: OrderIssue[] = orderQuestion?.issues || [];
  const correctIssues: OrderIssue[] = orderQuestion.correct_issues || [];
  const isVerticalArrange = orderQuestion.is_vertical_arrange;

  const getIssueById = (issueId: number) =>
    orderIssues.find((issue) => issue.id === issueId);

  const getCorrectIssueById = (issueId: number) =>
    correctIssues.find((issue) => issue.id === issueId);

  const handleChangePoint = (
    e: ChangeEvent<HTMLInputElement>,
    correctIssueId: number
  ) => {
    const point = Number(e.target.value);
    if(isNaN(point)) return;

    const newOrderQuestion = {
      ...orderQuestion,
      correct_issues: correctIssues.map((correctIssue) => {
        if (correctIssue.id === correctIssueId) {
          return { ...correctIssue, point: point };
        }

        return correctIssue;
      }),
    };
    const handleScore = handleProblemScoreCalculator(newOrderQuestion);
    handleUpdateProblem({...newOrderQuestion, total_score: handleScore});
  };

  return (
    <div
      className={`flex justify-center items-center gap-[5%] p-[20px] bg-white rounded-b-[10px] ${
        isVerticalArrange ? "" : "flex-col !gap-[28px]"
      }`}
    >
      <div
        className={`border-[2px] border-[#E5E5E5] h-full p-[15px] ${
          isVerticalArrange ? "w-[270px]" : "w-[90%]"
        }`}
      >
        <ul className="px-0 py-[10px] list-none listbox-dot h-full flex flex-wrap gap-y-[20px]">
          {isUsableArr(orderIssues) &&
            orderIssues &&
            orderIssues.map((issue, index) => (
              <div
                className={`flex items-center gap-[10px] px-[10px] ${
                  isVerticalArrange ? "w-full" : "w-[190px]"
                }
              `}
                key={index}
              >
                <div className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    name={`issue_data_checked_${index}`}
                    id=""
                    className="pointer-events-none rounded-full bg-secondary-light w-[20px] h-[20px]"
                    disabled={isDisabled}
                  />
                </div>
                <label
                  className="w-full"
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(issue.label || ""),
                  }}
                ></label>
              </div>
            ))}
        </ul>
      </div>
      <div className="text-[#EBE4D8]">
        {isVerticalArrange ? (
          <PiArrowFatRight size={50} />
        ) : (
          <PiArrowFatDown size={50} />
        )}
      </div>
      <div
        className={`border-[2px] border-[#E5E5E5] h-full px-[15px] pt-[40px] pb-[40px] flex flex-wrap gap-[10px] items-center ${
          isVerticalArrange
            ? "w-[270px] flex-col justify-center"
            : "w-[90%] justify-start"
        }`}
      >
        {isUsableArr(correctIssues) &&
          correctIssues.map((issue, index) => (
            <React.Fragment key={index}>
              {issue.id ? (
                <div
                  className={`flex justify-start gap-[10px] items-center ${
                    isVerticalArrange ? "w-full" : "w-[190px]"
                  }`}
                >
                  <div className="w-full border border-dashed border-secondary-light min-h-[50px]">
                    <div className="w-full flex items-center justify-start gap-[7px] p-[5px]">
                      <div
                        className="w-[67%] ml-[10px] mr-[5px] border px-[15px] py-[10px] rounded-[7px]"
                        dangerouslySetInnerHTML={{
                          __html: decodeHTMLEntities(
                            getIssueById(issue.id)?.label || ""
                          ),
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="">
                    <input
                      type="text"
                      name="point"
                      className="w-[45px] h-[40px] text-[14px] !border-secondary-light"
                      placeholder="0"
                      onChange={(e) =>
                        handleChangePoint(e, issue?.id || 0)
                      }
                      value={Number(getCorrectIssueById(issue.id)?.point) || ""}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={`flex justify-start gap-[10px] items-center w-[190px]`}
                >
                  <div
                    className={`w-full border border-dashed border-secondary-light pointer-events-none min-h-[50px]`}
                  ></div>
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default OrderQuestion;
