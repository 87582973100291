import React from "react";
import type { FC } from "react";
// import logo from "../../../assests/images/logo.webp";
import logo from "../../../assests/images/logo_CBT.webp";
import { Link } from "react-router-dom";

interface LogoProps {
  otherStyle: string | undefined;
  children?: JSX.Element;
}

const Logo: FC<LogoProps> = ({ otherStyle }) => {
  return (
    <Link to={"/"}>
      <div className={otherStyle}>
        <img className="w-full h-full" src={logo} alt="None" />
      </div>
    </Link>
  );
};

export default Logo;
