import { BiSearchAlt2 } from "react-icons/bi";
import { FILTER } from "../../../services/constants/admin/main";
import { useState, ChangeEvent, useEffect, useRef } from "react";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalClose, swalError } from "../../../services/helpers/swal";
import { Folder } from "../../../types/admin/folder";

const AllFileFilter = ({
  changeHandle,
}: {
  changeHandle: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}) => {
  const selectRef = useRef<HTMLSelectElement | null>(null);
  const [folderList, setFolderList] = useState<Folder[]>([]);
  useEffect(() => {
    const fetch = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_FOLDER.LIST}?sortType=1&limit=1000`,
      });

      if (!result.data) {
        swalError();
      }

      setFolderList(result.data.resource_folders);
      swalClose();
    };
    fetch();
  }, []);
  return (
    <section className="my-[30px] border-secondary-light">
      <div className="flex h-[30px] gap-[8px] relative">
        <div className="relative">
          <select
            name="resource_folder_id"
            className="h-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] leading-[100%] min-w-[170px] overflow-y-auto"
            onChange={changeHandle}
          >
            <option value="">カテゴリを選択</option>
            {folderList.map((folder) => (
              <option key={folder.id} value={folder.id}>
                {folder.name}
              </option>
            ))}
          </select>
        </div>
        <div className="relative">
          <input
            type="text"
            name="origin_filename"
            id=""
            className="h-full max-w-[200px] pl-[12px] placeholder-[#BEBEBE] text-[11px] leading-[100%] border-[#E1E3E2] rounded-[5px]"
            placeholder="ファイルの検索"
            onChange={changeHandle}
          />
          <BiSearchAlt2
            className="absolute top-[5px] right-[9.26px] text-secondary-light"
            size={22}
          />
        </div>

        <div className="relative">
          <select
            name="limit"
            id=""
            className="h-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] leading-[100%] w-[95px]"
            onChange={changeHandle}
            ref={selectRef}
          >
            {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
              <option value={item.value} className="" key={index}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </section>
  );
};

export default AllFileFilter;
