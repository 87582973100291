import React, { useState, useContext } from "react";
import { ChildrenWithProps } from "../../types/globals";
import { ExamParticipateCandidateType } from "../../types/admin/examParticipate";

type ExaminationVenueInfoType = {
  exam_name?: string;
  exam_type_name?: string;
  exam_venue_name?: string;
}

interface ExamParticipateCandidateTypes {
  examParticipateCandidates: ExamParticipateCandidateType[];
  setExamParticipateCandidates: React.Dispatch<
    React.SetStateAction<ExamParticipateCandidateType[]>
  >;
  totalPages: number;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
  examinationVenueInfo: ExaminationVenueInfoType;
  setExaminationVenueInfo: React.Dispatch<React.SetStateAction<ExaminationVenueInfoType>>;
  waitingAPI: boolean;
  setWaitingAPI: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExamParticipateCandidateContext = React.createContext<ExamParticipateCandidateTypes>({
  examParticipateCandidates: [],
  setExamParticipateCandidates: () => {},
  totalPages: 1,
  setTotalPages: () => {},
  examinationVenueInfo: {},
  setExaminationVenueInfo: () => {},
  waitingAPI: true,
  setWaitingAPI: () => {}
});

const ExamParticipateCandidateProvider = ({ children }: ChildrenWithProps) => {
  const [examParticipateCandidates, setExamParticipateCandidates] = useState<ExamParticipateCandidateType[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [examinationVenueInfo, setExaminationVenueInfo] = useState<ExaminationVenueInfoType>({});
  const [waitingAPI, setWaitingAPI] = useState<boolean>(true);

  return (
    <ExamParticipateCandidateContext.Provider
      value={{
        examParticipateCandidates,
        setExamParticipateCandidates,
        totalPages,
        setTotalPages,
        examinationVenueInfo,
        setExaminationVenueInfo,
        waitingAPI,
        setWaitingAPI
      }}
    >
      {children}
    </ExamParticipateCandidateContext.Provider>
  );
};

export default ExamParticipateCandidateProvider;

export const useExamParticipateCandidateContext = () => useContext(ExamParticipateCandidateContext);
