import React from "react";

const Unknown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="2"
      viewBox="0 0 15 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1H13.5"
        stroke="#BEBEBE"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Unknown;
