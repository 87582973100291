import { SETTING_PROPERTY_NAMES } from "../exam";

const DETAIL_ITEM_MESSAGES = {
    NAME: "問題参照用識別子",
    FIXED_LOCATION: 'シャッフルされません。位置は固定されます。',
    SCORE_EDIT: '問題のスコアが編集できます。',

    HIDE_TIME_WARNING: '受験者ががタイム・セクションを終了するときに、警告を自動的に表示しない。',
    SHOW_UNANSWERED_WARNING: '受験者がテストパートの終了時に、未回答やレビュー用のマークが残っているときには、警告を表示します。',
    SHOW_TIMEOUT_WARNING: '時間が20％残っている時に警告を表示します。',

    REMOVE_ANSWER: '消去法での回答/取り消し線を受験者に許可します。',
    SHOW_CALCULATOR: '受験者の計算機の使用を許可',
    SHOW_HIGHT_LIGHT: '受験者がアイテムのテキストの一部をハイライトすることを許可',
    SHOW_ZOOM_GLASS: '受験者の拡大鏡の使用を許可',
    SHOW_ZOOM_TOOL: '受験者のズームの使用を許可',

    TIMES_ANSWER: '試行回数の最大値を制御します。0の場合は無制限となります。',
    IS_SKIP: '受験者は回答を提出せずに、スキップできます。',
    MIN_ANSWERS: '受験者は無効な回答を提出することはできません。',

    MAX_TIME: 'このアイテムの最大時間',
    MIN_TIME: 'このアイテムの最小時間',
    IS_SUBMISSION_AFTER_DEADLINE: '験者の回答でセクションで設定された制限時間を超えてもそれを許可する。',
}

const ITEM_SETTING_ALERTS = [
    {
        title: "時間の警告を隠す",
        name: SETTING_PROPERTY_NAMES.HIDE_TIME_WARNING,
        detail: DETAIL_ITEM_MESSAGES.HIDE_TIME_WARNING,
    },
    {
        title: "未回答警告を表示",
        name: SETTING_PROPERTY_NAMES.SHOW_UNANSWERED_WARNING,
        detail: DETAIL_ITEM_MESSAGES.SHOW_UNANSWERED_WARNING,
    },
    {
        title: "時間切れ警告を表示",
        name: SETTING_PROPERTY_NAMES.SHOW_TIMEOUT_WARNING,
        detail: DETAIL_ITEM_MESSAGES.SHOW_TIMEOUT_WARNING,
    },
];

const ITEM_SETTING_TOOLS = [
    {
        title: "回答の除去",
        name: SETTING_PROPERTY_NAMES.REMOVE_ANSWER,
        detail: DETAIL_ITEM_MESSAGES.REMOVE_ANSWER,
    },
    {
        title: "計算機",
        name: SETTING_PROPERTY_NAMES.SHOW_CALCULATOR,
        detail: DETAIL_ITEM_MESSAGES.SHOW_CALCULATOR,
    },
    {
        title: "ハイライトマーカー",
        name: SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT,
        detail: DETAIL_ITEM_MESSAGES.SHOW_HIGHT_LIGHT,
    },
    {
        title: "拡大鏡",
        name: SETTING_PROPERTY_NAMES.SHOW_ZOOM_GLASS,
        detail: DETAIL_ITEM_MESSAGES.SHOW_ZOOM_GLASS,
    },
    {
        title: "Zoom",
        name: SETTING_PROPERTY_NAMES.SHOW_ZOOM_TOOL,
        detail: DETAIL_ITEM_MESSAGES.SHOW_ZOOM_TOOL,
    },
];

export { DETAIL_ITEM_MESSAGES, ITEM_SETTING_ALERTS, ITEM_SETTING_TOOLS }