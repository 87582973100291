import React from "react";
import { ChoiceProblemTest } from "../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import {
  generateListStyleChoice,
  isListBoxStyle,
} from "../../../../../services/utils/admin/question";
import { CHECK_ANSWER_STATUS_TYPE } from "../../../../../services/constants/globals";
import { handleShowIconCheckAnswer } from "../RenderQuestionType";

type ChoiceQuestionProps = {
  question: ChoiceProblemTest;
  numberQuestion: number;
};

const ChoiceQuestion = ({ question, numberQuestion }: ChoiceQuestionProps) => {
  const issues = question.issues || [];
  const answerData = question.answer_data || [];
  console.log(answerData);

  const handleCheckCorrectAnswer = (issueId: number): number => {
    const findIssue = issues.find((issue) => issue?.id === issueId);
    const findAnswerIssue = answerData.find((answer) => answer?.id === issueId);

    if (!findIssue || !findAnswerIssue) return CHECK_ANSWER_STATUS_TYPE.UNKNOWN;

    if (!findIssue.checked && !findAnswerIssue.checked) {
      return CHECK_ANSWER_STATUS_TYPE.UNKNOWN;
    }

    if (findIssue.checked && findAnswerIssue.checked) {
      return CHECK_ANSWER_STATUS_TYPE.CORRECT;
    }

    return CHECK_ANSWER_STATUS_TYPE.INCORRECT;
  };

  return (
    <div className="w-full flex items-stretch mb-[30px]">
      <div className="w-[40px]">
        <div className="w-full h-full flex items-center justify-center bg-warning-lighter">
          <span>{numberQuestion}</span>
        </div>
      </div>
      <div className="w-[calc(100%-40px)]">
        {issues.map((issue, index) => (
          <div
            key={issue?.id || index}
            className="w-full flex items-center border-b border-b-danger-light py-[20px]"
          >
            <div className="flex-1 flex items-center gap-x-[10px]">
              <ul
                className={`${generateListStyleChoice(
                  question?.list_style
                )} !p-0`}
              >
                <div
                  className="flex items-center gap-[10px] p-[10px] !pt-0 mb-[10px]"
                  key={answerData[index]?.id || 0}
                >
                  <div
                    className={`flex items-center${
                      !isListBoxStyle(question?.list_style) ? "mr-[25px]" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      name={`issue_data_checked_${index}`}
                      className=""
                      checked={answerData[index]?.checked || false}
                      onChange={() => {}}
                    />
                  </div>
                  <li
                    className="set-img"
                    dangerouslySetInnerHTML={{
                      __html: decodeHTMLEntities(
                        answerData[index]?.label || "<div></div>"
                      ),
                    }}
                  ></li>
                </div>
              </ul>
            </div>
            <div className="flex-1 flex items-center gap-x-[10px]">
              <ul
                className={`${generateListStyleChoice(
                  question?.list_style
                )} !p-0`}
              >
                <div
                  className="flex items-center gap-[10px] p-[10px] !pt-0 mb-[10px]"
                  key={issue?.id || 0}
                >
                  <div
                    className={`flex items-center  ${
                      !isListBoxStyle(question?.list_style) ? "mr-[25px]" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      name={`issue_data_checked_${index}`}
                      className=""
                      checked={issue?.checked || false}
                      onChange={() => {}}
                    />
                  </div>
                  <li
                    className="set-img"
                    dangerouslySetInnerHTML={{
                      __html: decodeHTMLEntities(issue?.label || "<div></div>"),
                    }}
                  ></li>
                </div>
              </ul>
            </div>
            <div className="w-[80px] flex justify-center">
              {handleShowIconCheckAnswer(
                handleCheckCorrectAnswer(issue?.id || 0)
              )}
            </div>
            <div className="w-[40px] text-center text-[14px]">
              {handleCheckCorrectAnswer(issue?.id || 0) ===
              CHECK_ANSWER_STATUS_TYPE.CORRECT
                ? issue.point
                : 0}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChoiceQuestion;
