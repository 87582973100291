import React, { ChangeEvent, useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { TestTakerUploadProblem } from "../../../../../../types/admin/question";

const UploadQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled,
}: {
  questionProblem: TestTakerUploadProblem;
  handleUpdateProblem: (problem: TestTakerUploadProblem) => void;
  isDisabled: boolean;
}) => {
  const [uploadQuestion, setUploadQuestion] =
    useState<TestTakerUploadProblem>(questionProblem);

  const handleChangePoint = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const point = Number(value);
    if (isNaN(point)) return;

    const newUploadQuestion = {
      ...uploadQuestion,
      [name]: point,
      total_score: point,
    };

    setUploadQuestion(newUploadQuestion);
    handleUpdateProblem(newUploadQuestion);
  };

  // useEffect(() => {
  //   handleUpdateProblem(uploadQuestion);
  // }, [uploadQuestion]);

  return (
    <div className="flex items-center justify-center gap-x-[30px] mt-[50px]">
      <div className="max-w-[531px] border border-dashed border-secondary-light rounded-[10px] mt-[16px] py-[16px] px-[10px]">
        <div className="flex gap-[56px] items-center">
          <div className="flex gap-[13px] items-center ml-[22px]">
            <div className="">
              <FiUploadCloud size={39} className="text-secondary font-[100]" />
            </div>
            <div className="font-[500] leading-[16px] tracking-[0.5px]">
              <div className="text-[11px]">
                ファイルを選択するか、ここにドラッグ＆ドロップしてください。
              </div>
              <div className="text-[12px] text-secondary-light">
                ファイル容量は{uploadQuestion.file_size_limit}
                MB以下をアップロードしてください。
              </div>
            </div>
          </div>
          <div className="">
            <div
              className={` text-primary-dark border border-primary-dark rounded-[5px] w-[117px] flex items-center justify-center h-[44px] text-[14px] font-[500] leading-[20px] tracking-[0.1px] cursor-default`}
            >
              ファイル選択
            </div>
          </div>
        </div>
      </div>
      <div>
        <input
          type="text"
          name="point"
          className="w-[45px] h-[40px] text-[14px] !border-secondary-light"
          value={uploadQuestion.point}
          onChange={handleChangePoint}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default UploadQuestion;
