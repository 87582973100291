import * as React from "react";

const ArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 12L18.59 10.59L13 16.17L13 4L11 4L11 16.17L5.42 10.58L4 12L12 20L20 12Z"
      fill={`${props.color || "#7A7A7A"}`}
    />
  </svg>
);

export default ArrowDown;
