import React from "react";
const SettingsConfigRow = ({
  name,
  isDisplay,
  onRadioChange,
  column,
}: {
  name?: string;
  isDisplay?: number;
  onRadioChange: (newValue: number, column?: string | undefined) => void;
  column?: string | undefined;
}) => {
  return (
    <div className="flex w-full min-h-[40px] ml-[15px] shadow-sm mb-[2px]">
      <div className="flex items-center bg-[#EFF1F0] w-full max-w-[500px] pl-[20px]">
        {name}
      </div>
      <div className="flex items-center pl-[40px] bg-white w-full max-w-[675px]">
        <label className="inline-flex items-center mr-[15px]">
          <input
            type="radio"
            className="form-radio text-primary-light border border-success-extralight"
            name={name}
            value="1"
            checked={isDisplay === 1}
            onChange={() => onRadioChange(1, column)}
          />
          <span className="ml-[8px] text-[12px] leading-[100%]">公開</span>
        </label>
        <label className="inline-flex items-center mr-[15px]">
          <input
            type="radio"
            className="form-radio text-primary-light border border-success-extralight"
            name={name}
            value="0"
            checked={isDisplay === 0}
            onChange={() => onRadioChange(0, column)}
          />
          <span className="ml-[8px] text-[12px] leading-[100%]">非公開</span>
        </label>
      </div>
    </div>
  );
};

export default SettingsConfigRow;
