import { TbFolderOpen } from 'react-icons/tb';
import { Question } from '../../../../types/admin/question';
import { PiFolderBold } from 'react-icons/pi';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { useQuestionListContext } from '../../../../context/Question/ListQuestionContext';
import { isUsableArr } from '../../../../services/helpers/etc';
import HalfPlus from '../../../commons/icons/HalfPlus';
import { FiFileText } from 'react-icons/fi';
type ListItemProps = {
  question: Question;
};

const QuestionItem = ({ question }: ListItemProps) => {
  const [isExpand, setIsExpand] = useState(false);
  const { questions, selectedQuestion, setSelectedQuestion } = useQuestionListContext();
  const childQuestions = questions.filter(
    (childQuestion) => Number(childQuestion.parent_id) === Number(question?.id),
  );

  const handleClick = () => {
    setIsExpand(!isExpand);
    setSelectedQuestion(question.id);
  };

  return (
    <div className="">
      <div
        className={`hover:bg-primary-extralight px-[5px] text-secondary-light text-[22px] flex gap-[5px] cursor-pointer items-center h-[30px] ${
          selectedQuestion.id === question.id ? 'bg-primary-extralight rounded-[2px]' : ''
        }`}
        onClick={handleClick}
      >
        {question.is_file ? (
          <HalfPlus className="mx-[5px] shrink-0" />
        ) : (
          <div className="text-[18px]">{isExpand ? <IoIosArrowDown /> : <IoIosArrowForward />}</div>
        )}

        {question.is_file ? (
          <FiFileText className="ml-[0.4px] mr-[1.5px] text-[21px] text-primary shrink-0" />
        ) : (
          <div className="text-[22px]">{isExpand ? <TbFolderOpen /> : <PiFolderBold />}</div>
        )}
        <div className="text-[12px] text-secondary-dark font-[500] leading-[100%] whitespace-nowrap pr-[15px]">
          {question.name}
        </div>
      </div>
      <div className="pl-[20px]">
        {isExpand &&
          isUsableArr(childQuestions) &&
          childQuestions.map((question, index) => (
            <QuestionItem question={question} key={question.id || index} />
          ))}
      </div>
    </div>
  );
};

export default QuestionItem;
