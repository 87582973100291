import React from "react";
import Pagination from "../../commons/Pagination";
import dayjs from "dayjs";
import { ExamParticipateCandidateType } from "../../../../types/admin/examParticipate";
import { getCommonDateSlash } from "../../../../services/helpers/date";
import { PAYMENT_STATUS } from "../../../../services/constants/candidate/payment";
import { useExamParticipateCandidateContext } from "../../../../context/Admin/ExamParticipateCandidateContext ";

const ExamParticipateCandidateTable = ({
  handlePageChange,
  currentPage,
  _candidates,
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  _candidates: ExamParticipateCandidateType[];
}) => {
  const { totalPages } = useExamParticipateCandidateContext();
  const handleStatusExam = (status: number) => {
    switch (status) {
      case 0:
        return (
          <button className="text-[12px] text-danger font-[500] p-[5px] flex items-center justify-center rounded-[5px] border border-danger bg-white pointer-events-none">
            試験前
          </button>
        );
      case 1:
        return (
          <button className="text-[12px] text-white font-[500] p-[5px] flex items-center justify-center rounded-[5px] border bg-danger pointer-events-none">
            試験中
          </button>
        );
      case 2:
        return (
          <button className="text-[12px] text-white font-[500] p-[5px] flex items-center justify-center rounded-[5px] bg-primary pointer-events-none">
            受験終了
          </button>
        );
      case 3:
        return (
          <button className="text-[12px] font-[500] p-[5px] flex items-center justify-center rounded-[5px] bg-success-extralight pointer-events-none">
            未受験
          </button>
        );
    }
  };

  const handleStatusOrder = (paymentStatus: number) => {
    switch (paymentStatus) {
      case PAYMENT_STATUS.WAITTING_PAID:
        return (
          <button className="text-white text-[12px] font-[500] p-[5px] rounded-[5px] bg-primary-light pointer-events-none">
            決済待ち中
          </button>
        );
      case PAYMENT_STATUS.PAID:
        return (
          <button className="text-[12px] font-[500] p-[5px] rounded-[5px] bg-success-extralight pointer-events-none">
            決済済み
          </button>
        );
      case PAYMENT_STATUS.FAILED:
        return (
          <button className="text-[12px] text-white font-[500] p-[5px] rounded-[5px] bg-danger pointer-events-none">
            決済失敗
          </button>
        );
      default:
        break;
    }
  };

  return (
    <>
      {_candidates && _candidates.length ? (
        <>
          <div className="relative overflow-x-auto animate-[show_0.5s_ease-in-out]">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
                <tr className="min-h-[50px] text-[13px] font-[500]">
                  <th
                    scope="col"
                    className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    注文番号
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    申込日
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    決済日
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    受験者コード
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[60px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    受験者名
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[60px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    受験者メール
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[43px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    決済ステータス
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[43px] xl:px-[10px] lg:px-[5px] px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    決済方法
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    受験ステータス
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    試験日
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(_candidates) &&
                  _candidates &&
                  !!_candidates.length &&
                  _candidates.map((candidate, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b w-full max-h-[50px] text-[14px] font-[400] text-[#2E2E2E] leading-[20px]"
                    >
                      <th className="px-[6px] font-[500] text-primary-synch text-center whitespace-nowrap">
                        {candidate.order_code || "-"}
                      </th>
                      <th className="px-[6px] font-[500] text-primary-synch text-center whitespace-nowrap">
                        {(candidate.register_date &&
                          dayjs(candidate.register_date).format(
                            "YY-MM-DD HH:mm"
                          )) ||
                          "-"}
                      </th>
                      <th className="px-[6px] font-[500] text-primary-synch text-center whitespace-nowrap">
                        {(candidate.payment_date &&
                          dayjs(candidate.payment_date).format(
                            "YY-MM-DD HH:mm"
                          )) ||
                          "-"}
                      </th>
                      <th className="font-[500] text-primary-synch text-center whitespace-nowrap">
                        {candidate.code || "-"}
                      </th>
                      <th className="px-[6px] font-[500] text-primary-synch whitespace-nowrap">
                        {candidate.fullname}
                      </th>
                      <td className="px-[6px] ont-[500] whitespace-nowrap text-primary-synch">
                        {candidate.email}
                      </td>
                      <td className="text-center">
                        {handleStatusOrder(candidate.order_status || 0)}
                      </td>
                      <td className="text-[12px] my-[10px] font-[500] flex justify-center">
                        {candidate.payment_method_type}
                      </td>
                      <td className="">
                        <div className="flex items-center justify-center">
                          {handleStatusExam(candidate.exam_status || 0)}
                        </div>
                      </td>
                      <td className="px-[6px] flex flex-col items-start justify-center">
                        <span>
                          {candidate.exam_date
                            ? getCommonDateSlash(candidate.exam_date)
                            : "-"}
                        </span>
                        <span>
                          {candidate.exam_date
                            ? dayjs(candidate.exam_date).format("HH:mm")
                            : "-"}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {totalPages && totalPages !== 0 && (
            <Pagination
              totalItems={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          )}
        </>
      ) : (
        <p className="text-[16px] text-center">データがありません。</p>
      )}
    </>
  );
};

export default ExamParticipateCandidateTable;
