import React from 'react';
import TabCard from '../../../components/admin/commons/TabCard';
import AdminLayout from '../../../layouts/admin';
import { CANDIDATE_TAB_DATA } from '../../../services/constants/admin/pages/candidate';
import { PATH_ADMIN_CANDIDATE } from '../../../services/constants/route/router';
import { useTabFinder } from '../../../hooks/useTabFinder';

type CandidateMainProp = {
  children: React.ReactNode;
  title?: string;
};

const CandidateBase = ({ title, children }: CandidateMainProp) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_ADMIN_CANDIDATE));

  return (
    <AdminLayout>
      <TabCard tabData={CANDIDATE_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </AdminLayout>
  );
}; 

export default CandidateBase;
