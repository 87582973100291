import { Navigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";

type CheckRoleAdminProps = {
  children: JSX.Element | JSX.Element[]
}

const CheckRoleAdmin = ({ children }: CheckRoleAdminProps) => {

  let user = localStorage.getItem('user');
  let is_candidate = false;
  if (user) {
    is_candidate = JSON.parse(user)?.is_candidate;
  }
  return (
    <>
      {
        !is_candidate ? children : <Navigate to={ROUTE.MY_PAGE.DEFAULT} replace={true} />
      }
    </>
  )
}

export default CheckRoleAdmin;
