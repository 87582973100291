import { ChangeEvent, useEffect, useState } from "react";
import { WithChildren } from "../../../types/globals";
import CandidateLayout from "../../../layouts/candidate";
import { Link } from "react-router-dom";
import { RegisterExaminationType } from "../../../types/candidate/registerExamination";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import RegisterExaminationBlock from "../../../components/candidate/Home/RegisterExaminationBlock";
import LayoutWaitingApi from "../../../components/commons/layouts/LayoutWaitingAPI";
import classNames from "classnames";
import {
  EXAMINATION_HOME_STATUS,
  REDIRECT_BUTTONS,
} from "../../../services/constants/candidate/home";
import { PAYMENT_STATUS } from "../../../services/constants/candidate/payment";
import dayjs from "dayjs";

type HomeMainProps = {
  title?: string;
};

type StatusRegsiterExaminationType = {
  id: string;
  label: string;
  status: number;
};

const HomePage = ({ title, children }: WithChildren<HomeMainProps>) => {
  const [registerExaminations, setRegisterExaminations] = useState<
    RegisterExaminationType[]
  >([]);
  const [isWaitingAPI, setIsWaitngAPI] = useState<boolean>(false);
  const [statusREs, setStatusREs] = useState<number[]>([]);
  const [_registerExaminations, _setRegisterExaminations] = useState<
    RegisterExaminationType[]
  >([]);

  const statusRegsiterExaminations: StatusRegsiterExaminationType[] = [
    // { id: "all", label: "全て", status: PAYMENT_STATUS.ALL },
    { id: "paid", label: "決済済み", status: PAYMENT_STATUS.PAID },
    {
      id: "waiting_payment",
      label: "決済待ち中",
      status: PAYMENT_STATUS.WAITTING_PAID,
    },
    { id: "payment_failed", label: "決済失敗", status: PAYMENT_STATUS.FAILED },
    {
      id: "examination_incoming",
      label: "試験前",
      status: EXAMINATION_HOME_STATUS.INCOMING,
    },
    {
      id: "examination_finished",
      label: "試験終了",
      status: EXAMINATION_HOME_STATUS.FINISHED,
    },
  ];

  const handleChangeStatusRegisterExamination = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const valueStatus = Number(value);
    let statuses: number[] = [];

    if (valueStatus === PAYMENT_STATUS.ALL) {
      if (!checkStatusExist(valueStatus)) {
        statuses = statusRegsiterExaminations.map(
          (statusRegsiterExamination) => statusRegsiterExamination.status
        );
      }
    } else {
      if (checkStatusExist(valueStatus)) {
        statuses = statusREs.filter((status) => status !== valueStatus);
      } else {
        statuses = [...statusREs, valueStatus];
      }
    }

    setStatusREs(statuses);
  };

  const checkStatusExist = (value: number) => {
    return statusREs.includes(value);
  };

  useEffect(() => {
    setIsWaitngAPI(true);
    const fetch = async () => {
      await request.get(
        API.REGISTER_EXAMINATION.GET_LIST_REGISTER_EXAMINATION,
        (response) => {
          setRegisterExaminations(response);
          setIsWaitngAPI(false);
        }
      );
    };

    fetch();
  }, []);

  useEffect(() => {
    if (!registerExaminations.length) return;

    const newRegisterExaminations = registerExaminations.filter(
      (registerExamination) => {
        if (
          !statusREs.length ||
          statusREs.length === statusRegsiterExaminations.length
        ) {
          return registerExamination;
        }

        if (
          checkStatusExist(PAYMENT_STATUS.PAID) &&
          registerExamination.order_status === PAYMENT_STATUS.PAID
        ) {
          return registerExamination;
        }

        if (
          checkStatusExist(PAYMENT_STATUS.WAITTING_PAID) &&
          registerExamination.order_status === PAYMENT_STATUS.WAITTING_PAID
        ) {
          return registerExamination;
        }

        if (
          checkStatusExist(PAYMENT_STATUS.FAILED) &&
          registerExamination.order_status === PAYMENT_STATUS.FAILED
        ) {
          return registerExamination;
        }

        if (
          checkStatusExist(EXAMINATION_HOME_STATUS.INCOMING) &&
          dayjs(registerExamination.exam_full_date).diff(dayjs(), "minutes") +
            Number(registerExamination.time) >=
            0 &&
          registerExamination.order_status === PAYMENT_STATUS.PAID
        ) {
          return registerExamination;
        }

        if (
          checkStatusExist(EXAMINATION_HOME_STATUS.FINISHED) &&
          dayjs(registerExamination.exam_full_date).diff(dayjs(), "minutes") +
            Number(registerExamination.time) <
            0 &&
          registerExamination.order_status === PAYMENT_STATUS.PAID
        ) {
          return registerExamination;
        }
      }
    );

    _setRegisterExaminations(newRegisterExaminations);
  }, [statusREs, registerExaminations]);

  return (
    <CandidateLayout>
      <div className="font-sans">
        <div className="my-[20px] w-full grid grid-cols-3 max-md:grid-cols-1 rounded-[10px] gap-[20px]">
          {REDIRECT_BUTTONS.map((redirectButton, index) => (
            <Link
              to={redirectButton.href}
              className="bg-white w-full min-h-[160px] px-[25px] py-[30px] rounded-[10px] gap-[20px] flex hover:bg-slate-300"
              key={index}
            >
              <div className="flex-1">
                <div className="w-fit flex justify-start items-center text-primary-darker text-[18px] border-b-[1px] mb-[10px]">
                  {redirectButton.title}
                </div>
                <div className="w-full max-w-full flex justify-start items-center text-[12px] overflow-hidden">
                  {redirectButton.content}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="w-full p-[30px] bg-white rounded-[10px] mb-[30px]">
          <div className="px-[50px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-[10px]">
                <div className="text-[16px] text-secondary-dark font-[600]">
                  申し込んだ試験
                </div>
                {statusRegsiterExaminations.map(
                  (statusRegsiterExamination, index) => (
                    <div className="flex items-center gap-x-[5px]" key={index}>
                      <input
                        id={statusRegsiterExamination.id}
                        type="checkbox"
                        value={statusRegsiterExamination.status}
                        className="cursor-pointer"
                        onChange={handleChangeStatusRegisterExamination}
                        checked={checkStatusExist(
                          statusRegsiterExamination.status
                        )}
                      />
                      <label
                        htmlFor={statusRegsiterExamination.id}
                        className="cursor-pointer text-[12px]"
                      >
                        {statusRegsiterExamination.label}
                      </label>
                    </div>
                  )
                )}
              </div>

              <div className="text-[16px] text-primary-darker font-[500] leading-[24px]">
                もっと見る
              </div>
            </div>
            {isWaitingAPI ? (
              <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-[53px] gap-y-[20px] mt-[25px]">
                <LayoutWaitingApi
                  waitingApi={isWaitingAPI}
                  className="h-[234px]"
                ></LayoutWaitingApi>
                <LayoutWaitingApi
                  waitingApi={isWaitingAPI}
                  className="h-[234px]"
                ></LayoutWaitingApi>
                <LayoutWaitingApi
                  waitingApi={isWaitingAPI}
                  className="h-[234px]"
                ></LayoutWaitingApi>
              </div>
            ) : (
              <div
                className={classNames("mt-[25px] max-h-[850px] pr-[3px]", {
                  "overflow-y-auto": _registerExaminations.length > 9,
                })}
              >
                <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-[53px] gap-y-[20px]">
                  {_registerExaminations && _registerExaminations.length ? (
                    _registerExaminations.map((registerExamination) => (
                      <RegisterExaminationBlock
                        registerExamination={registerExamination}
                        key={registerExamination.id}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </CandidateLayout>
  );
};

export default HomePage;
