import { SETTING_PROPERTY_NAMES } from "../exam";

const  DETAIL_SECTION_MESSAGES = {
    NAME: 'グループのタイトル',
    SHOW_REVIEW: 'テスト全体レビュー（回答済・未回答など）',
    END_TEST_WARNING: '受験者がテストを終了する前に警告を表示します。',
    REDIRECT_WARNING: '受験者がテストパートを終了する前に警告を表示します。',
    NAVIGATE_PART_WARNING: '受験者がセクションをスキップする前に警告を表示します。セクションスキップオプションが必要です。',
    NAVIGATE_SECTION_WARNING: '受験者が時間切れで次のセクションに自動で移るときに警告を表示する',
    UNANSWERED_WARNING: '受験者がテストパートの終了時に、未回答やレビュー用のマークが残っているときには、警告を表示します。',
    TIMES_UP_WARNING: '時間が20％残っている時に警告を表示します。',
    IS_EDIT: '消去法での回答/取り消し線を受験者に許可します。',
    SHOW_CALCULATOR: '受験者の計算機の使用を許可',
    SHOW_HIGHT_LIGHT: '受験者がアイテムのテキストの一部をハイライトすることを許可',
    SHOW_ZOOM_GLASS: '受験者の拡大鏡の使用を許可', 
    SHOW_ZOOM_TOOL: '受験者のズームの使用を許可',
    MAX_TIMES_ANSWER: '試行回数の最大値を制御します。0の場合は無制限となります。',
    IS_SKIP: '受験者は回答を提出せずに、スキップできます',
    MIN_ANSWERS: '受験者は無効な回答を提出することはできません',
    IS_SHUFFLE: '設定するとこの階層の問題はランダムに出題されます。設定しない場合は、入力された順番で出題されます。',
    MAX_PART_TIME: 'このセクションの最大時間',
    IS_SUBMISSION_AFTER_DEADLINE: '受験者の回答でセクションで設定された制限時間を超えてもそれを許可する。',
}

const SECTION_SETTING_ALERTS = [
    // {
    //     title: "テスト終了警告を表示",
    //     name: SETTING_PROPERTY_NAMES.END_TEST_WARNING,
    //     detail: DETAIL_SECTION_MESSAGES.END_TEST_WARNING,
    // },
    // {
    //     title: "次パートへの移る警告を表示",
    //     name: SETTING_PROPERTY_NAMES.REDIRECT_WARNING,
    //     detail: DETAIL_SECTION_MESSAGES.REDIRECT_WARNING,
    // },
    // {
    //     title: "次セクションへ移る警告を表示",
    //     name: SETTING_PROPERTY_NAMES.NAVIGATE_PART_WARNING,
    //     detail: DETAIL_SECTION_MESSAGES.NAVIGATE_PART_WARNING,
    // },
    {
        title: "次セクションへ移る前に警告を表示",
        name: SETTING_PROPERTY_NAMES.NAVIGATE_SECTION_WARNING,
        detail: DETAIL_SECTION_MESSAGES.NAVIGATE_SECTION_WARNING,
    },
    {
        title: "未回答警告を表示",
        name: SETTING_PROPERTY_NAMES.UNANSWERED_WARNING,
        detail: DETAIL_SECTION_MESSAGES.UNANSWERED_WARNING,
    },
    // {
    //     title: "時間切れ警告を表示",
    //     name: SETTING_PROPERTY_NAMES.TIMES_UP_WARNING,
    //     detail: DETAIL_SECTION_MESSAGES.TIMES_UP_WARNING,
    // },
];

const SECTION_SETTING_TOOLS = [
    {
        title: "回答の除去",
        name: SETTING_PROPERTY_NAMES.REMOVE_ANSWER,
        detail: DETAIL_SECTION_MESSAGES.IS_EDIT,
    },
    {
        title: "計算機",
        name: SETTING_PROPERTY_NAMES.SHOW_CALCULATOR,
        detail: DETAIL_SECTION_MESSAGES.SHOW_CALCULATOR,
    },
    {
        title: "ハイライトマーカー",
        name: SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT,
        detail: DETAIL_SECTION_MESSAGES.SHOW_HIGHT_LIGHT,
    },
    // {
    //     title: "拡大鏡",
    //     name: SETTING_PROPERTY_NAMES.SHOW_ZOOM_GLASS,
    //     detail: DETAIL_SECTION_MESSAGES.SHOW_ZOOM_GLASS,
    // },
    // {
    //     title: "Zoom",
    //     name: SETTING_PROPERTY_NAMES.SHOW_ZOOM_TOOL,
    //     detail: DETAIL_SECTION_MESSAGES.SHOW_ZOOM_TOOL,
    // },
];



export { DETAIL_SECTION_MESSAGES, SECTION_SETTING_ALERTS, SECTION_SETTING_TOOLS }