import React from 'react';
import CandidateCSV from '../../../components/admin/Candidate/CandidateCSV';
import CandidateBase from '.';

const CandidateCSVPage = () => {
  return (
    <CandidateBase title="受講者管理 ｜ 受講者CSV登録">
      <CandidateCSV/>
    </CandidateBase>
  );
};

export default CandidateCSVPage;
