import ExaminationRegisterProvider from "../../../context/Candidate/ExaminationRegisterContext";
import CandidateLayout from "../../../layouts/candidate";


const RegisterExamBase = ({ children }: { children: React.ReactNode }) => {
   return (
      <CandidateLayout>
         <ExaminationRegisterProvider>
            {children}
         </ExaminationRegisterProvider>
      </CandidateLayout >
   );
};

export default RegisterExamBase;
