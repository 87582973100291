import * as React from 'react';

const TestTakerUpload = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      style={{
        fill: 'currentcolor',
      }}
      d="M6.966 7.285a.76.76 0 010-1.07l4.5-4.5a.76.76 0 011.068 0l4.5 4.5a.76.76 0 010 1.07.769.769 0 01-1.068 0L12.75 4.06V12a.75.75 0 11-1.5 0V4.06L8.034 7.285a.76.76 0 01-1.068 0zM22.5 12.75v6a1.5 1.5 0 01-1.5 1.5H3a1.5 1.5 0 01-1.5-1.5v-6a1.5 1.5 0 011.5-1.5h6.375a.375.375 0 01.375.375v.328c0 1.257 1.031 2.325 2.297 2.297A2.25 2.25 0 0014.25 12v-.375a.375.375 0 01.375-.375H21a1.5 1.5 0 011.5 1.5zm-3.75 3a1.125 1.125 0 10-2.25 0 1.125 1.125 0 002.25 0z"
    ></path>
  </svg>
);
export default TestTakerUpload;
