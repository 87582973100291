import QuestionPageBase from '.';
import QuestionAuthoring from '../../../components/admin/Question/AuthoringQuestion';
import AuthoringQuestionProvider from '../../../context/Question/AuthoringQuestionContext';

const QuestionAuthoringPage = () => {
  return (
    <QuestionPageBase>
      <AuthoringQuestionProvider>
        <QuestionAuthoring />
      </AuthoringQuestionProvider>
    </QuestionPageBase>
  );
};

export default QuestionAuthoringPage;
