import React, { useState, useContext } from "react";
import { ChildrenWithProps } from "../../types/globals";
import { ExamParticipateType } from "../../types/admin/examParticipate";

interface ExamResultExamListTypes {
  examParticipates: ExamParticipateType[];
  setExamParticipates: React.Dispatch<
    React.SetStateAction<ExamParticipateType[]>
  >;
  totalPages: number;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
}

const ExamResultExamListContext = React.createContext<ExamResultExamListTypes>({
  examParticipates: [],
  setExamParticipates: () => {},
  totalPages: 1,
  setTotalPages: () => {},
});

const ExamResultExamListProvider = ({ children }: ChildrenWithProps) => {
  const [examParticipates, setExamParticipates] = useState<ExamParticipateType[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);

  return (
    <ExamResultExamListContext.Provider
      value={{
        examParticipates,
        setExamParticipates,
        totalPages,
        setTotalPages,
      }}
    >
      {children}
    </ExamResultExamListContext.Provider>
  );
};

export default ExamResultExamListProvider;

export const useExamResultExamListContext = () => useContext(ExamResultExamListContext);
