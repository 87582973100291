import AdminLayout from "../../../layouts/admin";
import { WithChildren } from "../../../types/globals";
import "../../../assests/styles/course.css";
import TabCard from "../../../components/admin/commons/TabCard";
import { useTabFinder } from "../../../hooks/useTabFinder";
import { PATH_ADMIN_EXAMINATION } from "../../../services/constants/route/router";
import { EXAM_MANAGEMENT_TAB_DATA } from "../../../services/constants/admin/pages/examination";

type ExamProp = {
  title?: string;
};

const ExamBase = ({ title, children }: WithChildren<ExamProp>) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_ADMIN_EXAMINATION));
  return (
    <AdminLayout>
      <TabCard tabData={EXAM_MANAGEMENT_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </AdminLayout>
  );
};
export default ExamBase;
