import Pagination from "../../commons/Pagination";
import dayjs from "dayjs";
import { useExamResultListContext } from "../../../../context/Admin/ExamResultListContext";
import { CandidateExamHistoryResult } from "../../../../types/admin/candidateExamHistory";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PATH_ADMIN_EXAM_RESULT } from "../../../../services/constants/route/router";
import { useEffect } from "react";
import classNames from "classnames";

const ExamResultListTable = ({
  handlePageChange,
  currentPage,
  _examResults,
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  _examResults: CandidateExamHistoryResult[];
}) => {
  const { totalPages } = useExamResultListContext();
  const { exam_id } = useParams();
  const navigate = useNavigate();

  const redirectDetailExam = (candidateExamHistoryId: number) => {
    navigate(
      `${PATH_ADMIN_EXAM_RESULT.EXAM_RESULT_LIST}/${exam_id}/candidate-exam/${candidateExamHistoryId}`
    );
  };

  const calculatorScorePercent = (
    score: number | undefined,
    totalScore: number
  ) => {
    if (!score) {
      return 0;
    }

    return Math.round((score / totalScore) * 110);
  };

  useEffect(() => {
    if (!exam_id) navigate("404");
  }, [exam_id]);

  return (
    <>
      {_examResults && _examResults.length ? (
        <>
          <div className="relative overflow-x-auto animate-[show_0.5s_ease-in-out]">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
                <tr className="min-h-[50px] text-[13px] font-[500]">
                  <th
                    scope="col"
                    className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    受験者コード
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[110px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    受験者名
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[100px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    受験者メール
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[43px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    問題数
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    正解数
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    配点
                  </th>
                  <th
                    scope="col"
                    className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                  >
                    詳細
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(_examResults) &&
                  _examResults &&
                  !!_examResults.length &&
                  _examResults.map((examResult, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b w-full text-[14px] font-[400] text-[#2E2E2E] leading-[20px]"
                    >
                      <th className="px-6 py-4 font-[500] text-primary-synch whitespace-nowrap">
                        {examResult.code || "-"}
                      </th>
                      <th className="px-6 py-4 font-[500] text-primary-synch whitespace-nowrap">
                        {examResult.fullname}
                      </th>
                      <td className="px-12 py-4 font-[500] whitespace-nowrap text-primary-synch">
                        {examResult.email}
                      </td>
                      <td className="text-center p-[16px]">
                        {examResult.question_quantity}
                      </td>
                      <td className="text-center p-[16px]">
                        {examResult.correct_question_quantity}
                      </td>
                      <td className="flex items-center justify-center gap-x-[5px] py-[16px] px-[5px]">
                        <div className="w-[110px] h-[8px] bg-success-extralight rounded-[10px] flex items-center justify-start">
                          <div
                            style={{
                              width:
                                calculatorScorePercent(
                                  examResult.score,
                                  examResult?.total_score || 0
                                ) > 0
                                  ? `${calculatorScorePercent(
                                      examResult.score,
                                      examResult?.total_score || 0
                                    )}px`
                                  : "0px",
                            }}
                            className={classNames(
                              `h-[8px] bg-primary-synch rounded-[10px]`
                            )}
                          ></div>
                        </div>
                        <div className="text-[12px] leading-[16px]">
                          {examResult.score}/{examResult.total_score}点
                        </div>
                      </td>
                      <td className="w-[120px] text-center p-[16px]">
                        <button
                          className="bg-primary rounded-[5px] w-fit px-[8px] py-[3px] text-white"
                          onClick={() => redirectDetailExam(examResult.id || 0)}
                        >
                          結果詳細
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {totalPages && totalPages !== 0 && (
            <Pagination
              totalItems={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          )}
        </>
      ) : (
        <p className="text-[16px] text-center">データがありません。</p>
      )}
    </>
  );
};

export default ExamResultListTable;
