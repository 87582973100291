import { DragEvent, useState } from 'react';
import { PiArrowFatRight } from 'react-icons/pi';
import { useAuthoringQuestionContext } from '../../../../../context/Question/AuthoringQuestionContext';
import { isEmptyObj, isUsableArr } from '../../../../../services/helpers/etc';
import DroppedQuestionType from './DroppedQuestionType';
import { initProblemByType } from '../../../../../services/utils/admin/question';
import { QuestionProblem } from '../../../../../types/admin/question';
import { useObjectRoutes } from '../../../../../hooks/useObjectRoutes';

type DraggingElementProps = {
  fakeProblemIndex?: number;
};

const DropHandler = () => {
  const {
    isDragging,
    draggedName,
    setDraggedName,
    authoringData,
    setAuthoringData,
    draggingDroppedProblem,
    setDraggingDroppedProblem,
    setSelectedProblem,
    editingProblemStatuses,
  } = useAuthoringQuestionContext();

  const currentProblems = authoringData.problems || [];
  const initDraggableArea = 100;
  const [draggingHeight, setDraggingHeight] = useState<number>(initDraggableArea);
  const {getParamValue} = useObjectRoutes();
  const resourceId = getParamValue('resource_id')

  const handleDrop = (e: DragEvent<HTMLElement>, problemIndex?: number) => {
    e.preventDefault();
    if (problemIndex === undefined) return;
    if (!draggedName) return;

    const problemCounter = currentProblems?.length || 0;
    const prepareProblem: QuestionProblem = initProblemByType(draggedName, problemCounter, Number(resourceId));

    let newProblemData = currentProblems?.filter((problem) => !problem.is_fake) || [];
    // add item to desired location
    newProblemData.splice(problemIndex || 0, 0, prepareProblem);

    setAuthoringData({
      ...authoringData,
      problems: newProblemData,
    });
    setDraggedName('');
  };

  function dragOverHandle(e: DragEvent<HTMLElement>) {
    e.preventDefault();
  }

  const DraggingElement = ({ fakeProblemIndex }: DraggingElementProps) => {
    return (
      <div
        className={`my-[20px] w-full h-0 min-h-[150px] border-dashed border-primary flex justify-center items-center ${
          isDragging ? 'border-[3px]' : 'border'
        }`}
        onDrop={(e) => handleDrop(e, fakeProblemIndex)}
        onDragOver={dragOverHandle}
      >
        {!isDragging ? (
          <div className="text-secondary flex items-center justify-center gap-[10px]">
            <PiArrowFatRight size={40} />
            <div className="text-[14px] font-[500] leading-[20px] tracking-[0.1px]">
              ここにドラッグ＆ドロップして、内容を追加することができます
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const dragOverDroppedHandle = (index: number) => {
    if (isEmptyObj(draggingDroppedProblem)) {
      return;
    }

    const draggingOverDroppedProblem = currentProblems[index] || {};
    if (
      isEmptyObj(draggingOverDroppedProblem) ||
      draggingOverDroppedProblem.id === draggingDroppedProblem.id
    ) {
      return;
    }

    // filter out the currently dragged item
    let problems = currentProblems.filter((problem) => problem.id !== draggingDroppedProblem.id);

    // add the dragged item after the dragged over item
    problems?.splice(index, 0, draggingDroppedProblem);
    setAuthoringData({ ...authoringData, problems });
  };

  const dragStartDroppedHandle = (
    e: DragEvent<HTMLElement>,
    elementIndex: number,
    problemId?: number,
  ) => {
    // Prevent for Editing sections
    let isPrevent = false;
    for (const status of editingProblemStatuses) {
      if (status.id === problemId && !!status.status) {
        isPrevent = true;
        break;
      }
    }
    if (isPrevent) {
      e.preventDefault();
      return;
    }

    const currentProblem = currentProblems[elementIndex] || {};
    if (isEmptyObj(currentProblem)) return;

    setDraggingHeight(e.currentTarget.clientHeight);
    setDraggingDroppedProblem(currentProblem);
  };

  const dragEndDroppedHandle = () => {
    setDraggingDroppedProblem({});
    setDraggingHeight(initDraggableArea);
    setAuthoringData({ ...authoringData, problems: currentProblems });
  };

  return (
    <div className="">
      {/* Dragged element will append */}
      {isUsableArr(currentProblems) ? (
        <>
          <div className="my-[20px]">
            {currentProblems?.map((problem, index) => (
              <div
                key={index}
                className={`${problem.id === draggingDroppedProblem?.id ? 'opacity-25' : ''}`}
              >
                {problem.is_fake ? (
                  <DraggingElement fakeProblemIndex={index} />
                ) : (
                  <div
                    className="relative"
                    draggable
                    onDragStart={(e) => dragStartDroppedHandle(e, index, problem.id)}
                    onDragEnd={() => dragEndDroppedHandle()}
                  >
                    <div
                      className="absolute w-[95%] top-[50%] left-[50%] hover:cursor-move"
                      style={{ transform: 'translate(-50%, -50%)', height: draggingHeight }}
                      onDragOver={() => dragOverDroppedHandle(index)}
                      onClick={() => setSelectedProblem(problem.id)}
                    ></div>
                    <DroppedQuestionType
                      draggedName={problem.name || ''}
                      problemId={problem.id || 0}
                      currentProblem={problem}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <DraggingElement />
      )}
    </div>
  );
};

export default DropHandler;
