import React, { ChangeEvent, useEffect, useState } from "react";
import { useAuthoringExamContext } from "../../../../context/Exam/AuthoringExamContext";
import { ARRANGE_TYPE } from "../../../../services/constants/admin/pages/exam";
import { isEmptyObj } from "../../../../services/helpers/etc";
import { FiFileText } from "react-icons/fi";
import { Question } from "../../../../types/admin/question";
import QuestionItem from "./QuestionItem";

type QuestionListProps = {
  handleSelected: (e: ChangeEvent<HTMLInputElement>) => void;
  isSelected: (questionId: Question["id"]) => boolean;
  parentQuestions?: Question[];
  searchQuestion: string;
};

const QuestionList = ({
  handleSelected,
  isSelected,
  parentQuestions,
  searchQuestion,
}: QuestionListProps) => {
  const { questions, selectedItems, showDisplayType, selectedFolderQuestion } =
    useAuthoringExamContext();

  return (
    <>
      <div
        className={`bg-white w-full mt-[10px] h-[600px] p-[5px] animate-[show_0.5s_ease-in-out] ${
          questions.length >= 20 ? "overflow-y-scroll" : ""
        }`}
      >
        {showDisplayType === ARRANGE_TYPE.LIST
          ? questions
              ?.filter((question) => question.is_file)
              .filter((question) => {
                if (!isEmptyObj(selectedFolderQuestion)) {
                  return question.chain_parent_id?.includes(
                    String(selectedFolderQuestion.id)
                  );
                }

                return question;
              })
              .filter((question) => {
                if (searchQuestion) {
                  return question.name?.includes(searchQuestion);
                }

                return question;
              })
              .map((question) => {
                return (
                  <div
                    key={question.id}
                    className={`hover:bg-primary-extralight px-[5px] text-secondary-light text-[22px] flex gap-[5px] cursor-pointer items-center h-[30px] border-b border-secondary-extralight`}
                  >
                    <FiFileText className="ml-[0.4px] mr-[1.5px] text-[21px] text-primary shrink-0" />
                    <div className="w-full flex justify-between items-center text-[12px] text-secondary-dark font-[500] leading-[100%] whitespace-nowrap truncate">
                      <div className="w-[170px] truncate">{question.name}</div>
                      <input
                        className="input_check_red checked:ring-transparent focus:ring-transparent"
                        type="checkbox"
                        value={question.id}
                        onChange={handleSelected}
                        checked={isSelected(question.id)}
                      />
                    </div>
                  </div>
                );
              })
          : parentQuestions?.map((question, index) => (
              <QuestionItem question={question} key={index} />
            ))}
      </div>
      <div className="bg-success-extralight w-full h-[35px] px-[10px] py-[5px] text-[14px] text-center font-[400]">
        選択したアイテム : {selectedItems.length}
      </div>
    </>
  );
};

export default QuestionList;
