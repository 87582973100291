import React, { useEffect, useState } from "react";
import { RegisterExaminationDetailType } from "../../../types/candidate/registerExamination";
import { formatDateTime } from "../../../services/helpers/formatTime";
import {
  TYPE_DATE_FORMAT,
  WEEKDAYS_ORDER,
} from "../../../services/constants/globals";
import { EXAMINATION_FORMAT } from "../../../services/constants/admin/pages/examination";
import classNames from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Link, useNavigate } from "react-router-dom";
import { PATH_EXAMINATION } from "../../../services/constants/route/router";
import { formatRemainingTime } from "../../../services/utils/candidate/registerExamination";
import { isEmptyObj } from "../../../services/helpers/etc";
import { STATUS_EXAM_TEST_ITEM } from "../../../services/constants/candidate/examinationTest";
import {
  EXAMINATION_TIME_STATUS,
  EXAMINATION_TIME_STATUS_NAME,
} from "../../../services/constants/candidate/home";
import ExaminationImageDefault from "../../commons/icons/ExaminationTest/ExaminationImageDefault";
import { PAYMENT_STATUS } from "../../../services/constants/candidate/payment";
dayjs.extend(utc);

type RegisterExaminationBlockProps = {
  registerExamination: RegisterExaminationDetailType;
};

type ButtonTimeType = {
  time: string;
  status: number;
};

const RegisterExaminationBlock = ({
  registerExamination,
}: RegisterExaminationBlockProps) => {
  const [buttonTime, setButtonTime] = useState<ButtonTimeType>({
    status: EXAMINATION_TIME_STATUS.END,
    time: "",
  });
  const compareDate = dayjs(registerExamination.exam_full_date);
  const navigate = useNavigate();

  const handleRedirect = () => {
    let url = "";
    if (registerExamination.exam_format === EXAMINATION_FORMAT.CBT) {
      url = `${PATH_EXAMINATION.ENTER_CODE}?id=${registerExamination.id}`;
    }

    if (registerExamination.exam_format === EXAMINATION_FORMAT.IBT) {
      url = `${PATH_EXAMINATION.EXAMINATION_PART_LIST}?id=${registerExamination.id}`;
    }

    if (buttonTime.status === 0 || buttonTime.status === 2 || !url) {
      return;
    }

    return navigate(url);
  };

  const handleButton = () => {
    if (registerExamination.order_status === PAYMENT_STATUS.WAITTING_PAID) {
      return (
        <button
          className="w-[150px] py-[10px] rounded-[5px] text-white text-center text-[16px] font-[600] leading-[24px] bg-warning"
          disabled={true}
        >
          決済待ち中
        </button>
      );
    }

    if (registerExamination.order_status === PAYMENT_STATUS.FAILED) {
      return (
        <button
          className="w-[150px] py-[10px] text-secondary-dark border border-secondary-light rounded-[5px] text-center text-[16px] font-[600] leading-[24px]"
          disabled={true}
        >
          決済失敗
        </button>
      );
    }

    if (registerExamination.order_status === PAYMENT_STATUS.PAID) {
      return (
        <button
          onClick={handleRedirect}
          className={classNames(
            `w-[150px] py-[10px] rounded-[5px] text-white text-center text-[16px] font-[600] leading-[24px] animate-[show_0.5s_ease-in-out]`,
            {
              "bg-secondary-light cursor-default": buttonTime.status === 0,
              "bg-danger": buttonTime.status === 1,
              "bg-[#8BC0D3] cursor-default": buttonTime.status === 2,
            }
          )}
        >
          {buttonTime.time}
        </button>
      );
    }
  };

  useEffect(() => {
    if (registerExamination.exam_status === STATUS_EXAM_TEST_ITEM.DONE) {
      setButtonTime({
        status: EXAMINATION_TIME_STATUS.END,
        time: EXAMINATION_TIME_STATUS_NAME.END,
      });
    } else {
      const intervalId = setInterval(() => {
        const now = dayjs();
        const difference = compareDate.diff(now, "millisecond");

        formatRemainingTime(difference, registerExamination, setButtonTime);
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, []);

  return (
    <div className="p-[20px] border border-secondary-light rounded-[10px] animate-[show_0.5s_ease-in-out] flex flex-col justify-between">
      <div>
        <div>
          <span className="text-[18px] text-primary-darker leading-[24px] border-b border-b-secondary-light">
            {registerExamination.exam_name}｜
            {registerExamination.exam_type_name}（
            {registerExamination.exam_format === EXAMINATION_FORMAT.CBT
              ? "テストセンターで"
              : "オンライン"}
            ）
          </span>
        </div>
        <div className="flex justify-center gap-x-[20px] mt-[10px] text-[12px] text-secondary-dark">
          <div className="w-[100px] h-[100px]">
            {registerExamination.examination?.image_path ? (
              <img
                src={`${process.env.REACT_APP_AWS_S3_URL}/${registerExamination.examination?.image_path}`}
                alt=""
                className="w-full h-fit max-w-[100px] max-h-[100px] object-contain my-auto animate-[show_0.5s_ease-in-out]"
              />
            ) : (
              <ExaminationImageDefault width={100} height={100} />
            )}
          </div>
          <div className="flex-1">
            <p className="">{registerExamination.examination?.summary}</p>
            <div className="mt-[20px] text-[12px]">
              {isEmptyObj(registerExamination.exam_venue) ? (
                ""
              ) : (
                <p className="font-[700] leading-[16px]">
                  会場: {registerExamination.exam_venue?.name}
                </p>
              )}
              <p className="font-[700] leading-[16px]">
                試験日：
                {formatDateTime(
                  registerExamination.exam_full_date,
                  TYPE_DATE_FORMAT.SHORT_DEFAULT
                )}
                （
                {
                  WEEKDAYS_ORDER[
                    dayjs(registerExamination.exam_full_date).locale("ja").day()
                  ]
                }
                ）
              </p>
              <p className="font-[700] leading-[16px]">
                試験開始時間：
                {dayjs(registerExamination.exam_full_date).format("HH:mm")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[20px] flex items-center justify-center gap-x-[10px]">
        {handleButton()}
        <Link
          to={`${PATH_EXAMINATION.EXAMINATION_VIEW}?id=${registerExamination.id}`}
          className="w-[150px] py-[10px] border border-secondary-light rounded-[5px] text-center text-secondary-dark text-[16px] font-[600] leading-[24px]"
        >
          見る
        </Link>
      </div>
    </div>
  );
};

export default RegisterExaminationBlock;
