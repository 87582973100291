import { ReactNode } from "react";
import { IoIosArrowForward } from "react-icons/io";

const ButtonNextStep = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="w-full flex justify-end items-center">
      <button
        onClick={onClick}
        className="w-[140px] h-[40px] flex justify-end items-center bg-danger rounded-[5px] text-white"
      >
        <div className="w-[70%] flex justify-between items-center px-[15px]">
          次へ
          <IoIosArrowForward />
        </div>
      </button>
    </div>
  );
};

export default ButtonNextStep;
