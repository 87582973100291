import React from "react";
import { ChildrenWithProps } from "../../../types/globals";
import ExaminationTestProvider from "../../../context/ExaminationTestContext";
import LayoutExaminationDetail from "../../../layouts/candidate/LayoutExaminationDetail";

const ExaminationTestBase = ({ children }: ChildrenWithProps) => {
  return (
    <ExaminationTestProvider>
      <LayoutExaminationDetail classNameChild="w-full h-[calc(100vh-60px)]">
        {children}
      </LayoutExaminationDetail>
    </ExaminationTestProvider>
  );
};

export default ExaminationTestBase;
