import React from "react";
const Clipping = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3057 7.25C14.9168 7.25 15.4383 6.80823 15.5387 6.2055L15.7396 5L15.5387 3.7945C15.4383 3.19177 14.9168 2.75 14.3057 2.75H9.69423C9.08318 2.75 8.56169 3.19177 8.46124 3.7945L8.26032 5L8.46124 6.2055C8.56169 6.80823 9.08318 7.25 9.69423 7.25H14.3057ZM17.0183 6.4521C16.7973 7.77811 15.65 8.75 14.3057 8.75H9.69423C8.34992 8.75 7.20265 7.77811 6.98165 6.4521L6.86463 5.75H3.75V20.25H20.25V5.75H17.1353L17.0183 6.4521ZM6.98165 3.5479L6.86463 4.25H3.6C2.85442 4.25 2.25 4.85442 2.25 5.6V20.4C2.25 21.1456 2.85442 21.75 3.6 21.75H20.4C21.1456 21.75 21.75 21.1456 21.75 20.4V5.6C21.75 4.85442 21.1456 4.25 20.4 4.25H17.1353L17.0183 3.5479C16.7973 2.22189 15.65 1.25 14.3057 1.25H9.69423C8.34992 1.25 7.20265 2.22189 6.98165 3.5479ZM7 16.25C6.58579 16.25 6.25 16.5858 6.25 17C6.25 17.4142 6.58579 17.75 7 17.75L17 17.75C17.4142 17.75 17.75 17.4142 17.75 17C17.75 16.5858 17.4142 16.25 17 16.25L7 16.25ZM6.25 13.75C6.25 13.3358 6.58579 13 7 13L17 13C17.4142 13 17.75 13.3358 17.75 13.75C17.75 14.1642 17.4142 14.5 17 14.5L7 14.5C6.58579 14.5 6.25 14.1642 6.25 13.75ZM7 10C6.58579 10 6.25 10.3358 6.25 10.75C6.25 11.1642 6.58579 11.5 7 11.5L17 11.5C17.4142 11.5 17.75 11.1642 17.75 10.75C17.75 10.3358 17.4142 10 17 10L7 10Z"
        fill={props.color || "#5A5A5A"}
      />
    </svg>
  );
};
export default Clipping;
