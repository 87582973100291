import { Navigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import { checkIsSubdomain } from "../services/helpers/domain";


type RequiredAuthProps = {
  children: JSX.Element | JSX.Element[]
}

const CheckSubDomain = ({ children }: RequiredAuthProps) => {
  const isSubdomain = checkIsSubdomain();

  return (
    <>
      {
        isSubdomain ? <Navigate to={ROUTE.LOGIN} replace={true}/> : children
      }
    </>
  )
}

export default CheckSubDomain;
