import React from "react";
import { OrderProblemTest } from "../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import { CHECK_ANSWER_STATUS_TYPE } from "../../../../../services/constants/globals";
import { handleShowIconCheckAnswer } from "../RenderQuestionType";

type OrderQuestionProps = {
  question: OrderProblemTest;
  numberQuestion: number;
};

const OrderQuestion = ({ question, numberQuestion }: OrderQuestionProps) => {
  const correctIssues = question.correct_issues || [];
  const answerData = question.answer_data || [];
  const issues = question.issues || [];

  const handleCheckCorrectAnswer = (indexIssue: number): number => {
    const findCorrectIssue = correctIssues[indexIssue];
    const findAnswerIssue = answerData[indexIssue];

    if (!findCorrectIssue || !findAnswerIssue) {
      return CHECK_ANSWER_STATUS_TYPE.UNKNOWN;
    }

    if (findCorrectIssue?.id === findAnswerIssue?.id) {
      return CHECK_ANSWER_STATUS_TYPE.CORRECT;
    }

    return CHECK_ANSWER_STATUS_TYPE.INCORRECT;
  };

  return (
    <div className="w-full flex items-stretch mb-[30px]">
      <div className="w-[40px]">
        <div className="w-full h-full flex items-center justify-center bg-warning-lighter">
          <span>{numberQuestion}</span>
        </div>
      </div>
      <div className="w-[calc(100%-40px)]">
        {issues.map((issue, index) => {
          return (
            <div
              key={issue?.id || index}
              className="w-full flex items-center border-b border-b-danger-light py-[20px]"
            >
              <div className="flex-1 flex items-center gap-x-[10px]">
                <div className="text-[12px] font-[700] px-[10px]">
                  {index + 1}:
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(
                      answerData[index]?.label || "<div></div>"
                    ),
                  }}
                ></div>
              </div>
              <div className="flex-1 flex items-center gap-x-[10px]">
                <div className="text-[12px] font-[700] px-[10px]">
                  {index + 1}:
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(
                      correctIssues[index]?.label || "<div></div>"
                    ),
                  }}
                ></div>
              </div>
              <div className="w-[80px] flex justify-center">
                {handleShowIconCheckAnswer(handleCheckCorrectAnswer(index))}
              </div>
              <div className="w-[40px] text-center text-[14px]">
                {handleCheckCorrectAnswer(index) ===
                CHECK_ANSWER_STATUS_TYPE.CORRECT
                  ? issue.point
                  : 0}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderQuestion;
