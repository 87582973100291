import ExamBase from ".";
import CreateAuthoringExam from "../../../components/admin/Exam/CreateExam";

const CreateExamPage = () => {
  return (
    <ExamBase>
      <CreateAuthoringExam />
    </ExamBase>
  );
};

export default CreateExamPage;
