import { ChangeEvent } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { FILTER } from "../../../../services/constants/admin/main";
import LayoutWaitingApi from "../../../commons/layouts/LayoutWaitingAPI";
import { useExamResultListContext } from "../../../../context/Admin/ExamResultListContext";
import { isEmptyObj } from "../../../../services/helpers/etc";

const ExamResultListFilter = ({
  changeHandle,
}: {
  changeHandle: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}) => {
  const { examinationVenueInfo, waitingAPI } = useExamResultListContext();

  return (
    <>
      <LayoutWaitingApi waitingApi={waitingAPI && isEmptyObj(examinationVenueInfo)} className="w-full h-[60px]">
        <section className="pb-[20px]">
          <div className="text-[13px] text-secondary-dark font-[500] px-[10px]">
            選択中の試験
          </div>
          <div className="w-full mt-[8px] pl-[21px] pt-[16px] pb-[19px] text-white text-[18px] font-[700] bg-primary-light rounded-[5px]">
            {examinationVenueInfo.exam_name}｜
            {examinationVenueInfo.exam_type_name}｜
            {examinationVenueInfo.exam_venue_name}
          </div>
        </section>
      </LayoutWaitingApi>
      <section className="py-[20px] border-secondary-light">
        <div className="flex h-[30px] gap-[8px] relative">
          <div className="relative">
            <input
              type="text"
              name="search"
              className="h-full w-full max-w-[151px] pl-[12px] placeholder-[#BEBEBE] text-[11px] leading-[100%] border-success-extralight rounded-[5px] pr-[30px]"
              placeholder="受験者の検索"
              onChange={changeHandle}
            />
            <BiSearchAlt2
              className="absolute top-[5px] right-[9.26px] text-secondary-light"
              size={22}
            />
          </div>
          <div className="relative">
            <select
              name="limit"
              className="h-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] leading-[100%] w-[95px]"
              onChange={changeHandle}
            >
              {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
                <option value={item.value} className="" key={index}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExamResultListFilter;
