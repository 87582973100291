import React from 'react';
import { TestTakerRecordProblem } from '../../../../../../../../types/admin/question';
import { SetStateAction } from '../../../../../../../../types/globals';

type MinuteDropdownProps = {
    editingData: TestTakerRecordProblem;
    setEditingData: SetStateAction<TestTakerRecordProblem>;
}

const MinuteSelectDropdown = ({editingData, setEditingData}: MinuteDropdownProps) => {
    const minutes = [];
    for (let i = 1; i <= 60; i++) {
      const minute = i < 10 ? `0${i}` : `${i}`;
      minutes.push(
        <option key={i} value={i}>
          {minute}
        </option>
      );
    }

    return (
      <select
        name="file_minute_size_limit"
        className="w-[78px] h-[32px] border border-secondary-light text-center cursor-pointer text-[14px] font-[400] leading-[100%]"
        defaultValue={editingData.file_minute_size_limit}
        onChange={(e) =>
          setEditingData({
            ...editingData,
            [e.target.name]: parseInt(e.target.value),
          })
        }
      >
        {minutes}
      </select>
    );
};

export default MinuteSelectDropdown;