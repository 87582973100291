import * as React from 'react';

const TextBlock = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      style={{
        fill: 'currentcolor',
      }}
      fillRule="evenodd"
      d="M12 4.5a.75.75 0 01.75.75v13.5a.75.75 0 01-1.5 0V5.25A.75.75 0 0112 4.5z"
      clipRule="evenodd"
    ></path>
    <path
      style={{
        fill: 'currentcolor',
      }}
      fillRule="evenodd"
      d="M3.75 5.25a.75.75 0 01.75-.75h15a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0V6H5.25v2.25a.75.75 0 01-1.5 0v-3zM8.25 18.75A.75.75 0 019 18h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z"
      clipRule="evenodd"
    ></path>
  </svg>
);
export default TextBlock;
