import React from 'react';

const Document = (props: React.SVGProps<SVGSVGElement>) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
         <path fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8379 7.02439C15.59 7.02439 16.2318 6.5072 16.3554 5.80156L16.6027 4.39024L16.3554 2.97893C16.2318 2.27329 15.59 1.7561 14.8379 1.7561H9.16222C8.41016 1.7561 7.76833 2.27329 7.64469 2.97893L7.39741 4.39024L7.64469 5.80156C7.76833 6.5072 8.41016 7.02439 9.16222 7.02439H14.8379ZM18.1765 6.09026C17.9045 7.64267 16.4924 8.78049 14.8379 8.78049H9.16222C7.50769 8.78049 6.09566 7.64267 5.82366 6.09026L5.67964 5.26829H1.84615V22.2439H22.1538V5.26829H18.3205L18.1765 6.09026ZM5.82366 2.69023L5.67964 3.5122H1.66154C0.743896 3.5122 0 4.2198 0 5.09268V22.4195C0 23.2924 0.743896 24 1.66154 24H22.3385C23.2561 24 24 23.2924 24 22.4195V5.09268C24 4.2198 23.2561 3.5122 22.3385 3.5122H18.3205L18.1765 2.69023C17.9045 1.13782 16.4924 0 14.8379 0H9.16222C7.50769 0 6.09566 1.13782 5.82366 2.69023ZM5.84617 17.561C5.33637 17.561 4.9231 17.9541 4.9231 18.439C4.9231 18.924 5.33637 19.3171 5.84617 19.3171H18.1539C18.6637 19.3171 19.0769 18.924 19.0769 18.439C19.0769 17.9541 18.6637 17.561 18.1539 17.561L5.84617 17.561ZM4.9231 14.6341C4.9231 14.1492 5.33637 13.7561 5.84617 13.7561L18.1539 13.7561C18.6637 13.7561 19.0769 14.1492 19.0769 14.6341C19.0769 15.1191 18.6637 15.5122 18.1539 15.5122L5.84617 15.5122C5.33637 15.5122 4.9231 15.1191 4.9231 14.6341ZM5.84617 10.2439C5.33637 10.2439 4.9231 10.637 4.9231 11.122C4.9231 11.6069 5.33637 12 5.84617 12L18.1539 12C18.6637 12 19.0769 11.6069 19.0769 11.122C19.0769 10.637 18.6637 10.2439 18.1539 10.2439L5.84617 10.2439Z"
            fill={props.color || "#BEBEBE"}
         />
      </svg>
   );
};

export default Document;
