import React from 'react';

const TextI = () => {
  return (
    <div className="w-[20px] flex justify-center items-center">
      <svg width="2" height="14" viewBox="0 0 2 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 13L1 1"
          style={{
            stroke: 'currentcolor',
          }}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default TextI;
