import SideManagement from "./SideManagement";
import DetailExam from "../DetailExam";

const ListExam = () => {
  return (
    <section className="flex justify-start gap-[20px]">
      <SideManagement />
      <DetailExam />
    </section>
  );
};

export default ListExam;
