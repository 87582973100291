import React, { useState, useContext } from 'react';
import { ChildrenWithProps, SetStateAction } from '../../types/globals';
import { Question } from '../../types/admin/question';
import { sortArrayByKey } from '../../services/helpers/parseData';
import { useStorage } from '../../hooks/useStorage';
import { NAME_ACTION_DETAIL_QUESTION } from '../../services/constants/admin/pages/question';

interface QuestionListContextType {
  questions: Question[];
  setQuestions: (questions: Question[]) => void;
  selectedQuestion: Question;
  setSelectedQuestion: (questionId?: Question['id']) => void;
  selectedTargetQuestion: Question;
  setSelectedTargetQuestion: (questionId?: Question['id']) => void;
  sideAction: string;
  setSideAction: SetStateAction<string>;
  detailActionName: string;
  setDetailActionName: SetStateAction<string>;
}

const QuestionListContext = React.createContext<QuestionListContextType>({
  questions: [],
  setQuestions: () => {},
  selectedQuestion: {},
  setSelectedQuestion: () => {},
  selectedTargetQuestion: {},
  setSelectedTargetQuestion: () => {},
  sideAction: '',
  setSideAction: () => {},
  detailActionName: NAME_ACTION_DETAIL_QUESTION.EDIT,
  setDetailActionName: () => {},
});

const QuestionListProvider = ({ children }: ChildrenWithProps) => {
  const [questions, _setQuestions] = useState<Question[]>([]);
  const [selectedQuestion, _setSelectedQuestion] = useState<Question>({});
  const [selectedTargetQuestion, _setSelectedTargetQuestion] = useState<Question>({});
  const [sideAction, setSideAction] = useState<string>('');
  const [detailActionName, setDetailActionName] = useState<string>(
    NAME_ACTION_DETAIL_QUESTION.EDIT
  );
  const { setLocalStorage } = useStorage();
  const storageKey = 'selectedQuestion';

  const setQuestions = (questions: Question[]) => {
    const uniqueQuestions = questions.filter(
      (question, index, a) => a.findIndex((_question) => _question.id === question.id) === index,
    );
    _setQuestions(sortArrayByKey(uniqueQuestions));
  };

  const setSelectedQuestion = (questionId?: Question['id']) => {
    setLocalStorage(storageKey, String(questionId) || '');

    if (!questionId) {
      _setSelectedQuestion({});
      return;
    }
    _setSelectedQuestion(questions.find((question) => question.id === questionId) || {});
  };

  const setSelectedTargetQuestion = (questionId?: Question['id']) => {
    if (!questionId) {
      _setSelectedQuestion({});
      return;
    }
    _setSelectedTargetQuestion(questions.find((question) => question.id === questionId) || {});
  };

  return (
    <QuestionListContext.Provider
      value={{
        questions,
        setQuestions,
        selectedQuestion,
        setSelectedQuestion,
        selectedTargetQuestion,
        setSelectedTargetQuestion,
        sideAction,
        setSideAction,
        detailActionName,
        setDetailActionName
      }}
    >
      {children}
    </QuestionListContext.Provider>
  );
};

export default QuestionListProvider;

export const useQuestionListContext = () => useContext(QuestionListContext);
