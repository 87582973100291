import { TYPE_OF_EXAM_DISPLAY } from "../../../../../services/constants/admin/pages/exam";
import ItemSetting from "../PropertiesSetting/ItemSetting";
import PartSetting from "../PropertiesSetting/PartSetting";
import SectionSetting from "../PropertiesSetting/SectionSetting";
import TestSetting from "../PropertiesSetting/ExamSetting";

const ShowSettingProperties = ({ type }: { type: string | null }) => {
   const displaySettings = () => {
      switch (type) {
         case TYPE_OF_EXAM_DISPLAY.ITEM:
            return <ItemSetting />

         case TYPE_OF_EXAM_DISPLAY.SECTION:
            return <SectionSetting />

         case TYPE_OF_EXAM_DISPLAY.PART:
            return <PartSetting />

         case TYPE_OF_EXAM_DISPLAY.EXAM: 
            return <TestSetting />
            
         default: <></>
      }
   }

   return (
      <>
         {displaySettings()}
      </>
   )
}

export default ShowSettingProperties;