import { ChangeEvent, useEffect, useState } from "react";
import ExaminationCommonAction from "./ExaminationCommonAction";
import ExaminationFilter from "./ExaminationFilter";
import ExaminationTable from "./ExaminationTable";
import LayoutWaitingApi from "../../../commons/layouts/LayoutWaitingAPI";
import {
  swalConfirm,
  swalError,
  swalSuccess,
} from "../../../../services/helpers/swal";
import { API } from "../../../../services/constants/route/api";
import { makeRequest } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { EXAMINATION_STATUS } from "../../../../services/constants/admin/pages/examination";
import { useExaminationContext } from "../../../../context/ExaminationContext";

type FilterType = {
  query: string;
  limit: number;
  page: number;
  status?: number;
};

const ListExamination = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const { examinations, setExaminations, setTotalPages } =
    useExaminationContext();
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    query: "",
    page: 1,
    status: EXAMINATION_STATUS.PUBLIC
  });

  const resetTable = () => {
    setSelectedIds([]);
    setSelectAll(false);
    setCurrentPage(1);
  };

  const handleChangeFilter = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const { checked } = e.target as HTMLInputElement;
      let status: number = checked
        ? EXAMINATION_STATUS.PRIVATE
        : EXAMINATION_STATUS.PUBLIC;
      setFilter({
        ...filter,
        status: status,
        page: 1,
      });
      resetTable();
      return;
    }

    setFilter({ ...filter, [name]: value, page: 1 });
  };

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setWaitingApi(true);
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };
  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };

  const toggleSelect = (id: number) => {
    if (selectedIds?.includes(id)) {
      setSelectedIds(selectedIds?.filter((sId) => sId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedIds(examinations?.map((examination) => examination?.id || 0));
    } else {
      setSelectedIds([]);
    }
  };

  const isExaminationSelected = (id: number) => selectedIds?.includes(id);

  const deleteSelected = async () => {
    if (selectedIds.length === 0) {
      return;
    }
    swalConfirm(async () => {
      const result = await makeRequest({
        method: "delete",
        url: API.ADMIN_EXAMINATION.DELETE_EXAMINATION,
        data: { examination_ids: selectedIds },
      });
      if (!result.status) {
        return swalError();
      }

      resetTable();
      swalSuccess();
      changePaginate(1);
    }, "消去してもよろしいですか？");
  };

  useEffect(() => {
    const fetch = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_EXAMINATION.LIST_EXAMINATION}?${paramizeObject(
          filter
        )}`,
      });

      setExaminations(result.data.examinations);
      setTotalPages(result.data.total_page);
      setSelectAll(false);
      setWaitingApi(false);
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  
  return (
    <div>
      <ExaminationCommonAction />
      <ExaminationFilter
        changeHandle={handleChangeFilter}
        deleteSelected={deleteSelected}
      />
      <LayoutWaitingApi waitingApi={waitingApi} className="w-full h-[100px]">
        <ExaminationTable
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          toggleSelectAll={toggleSelectAll}
          toggleSelect={toggleSelect}
          selectAll={selectAll}
          isExaminationSelected={isExaminationSelected}
        />
      </LayoutWaitingApi>
    </div>
  );
};

export default ListExamination;
