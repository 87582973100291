import AdminLayout from "../../../layouts/admin";
import { WithChildren } from "../../../types/globals";
import "../../../assests/styles/course.css";
import TabCard from "../../../components/admin/commons/TabCard";
import { EXAM_MANAGEMENT_TAB_DATA } from "../../../services/constants/admin/pages/examination";
import { PATH_ADMIN_EXAM_VENUE } from "../../../services/constants/route/router";
import { useTabFinder } from "../../../hooks/useTabFinder";

type ExamManagementProp = {
  title?: string;
};
const ExamManagementBase = ({
  title,
  children,
}: WithChildren<ExamManagementProp>) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_ADMIN_EXAM_VENUE));
  return (
    <AdminLayout>
      <TabCard tabData={EXAM_MANAGEMENT_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </AdminLayout>
  );
};
export default ExamManagementBase;
