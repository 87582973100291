import { TestTakerRecordProblem } from "../../../../../../../types/admin/question";
import TestTakerRecord from "../../../../../../commons/icons/QuestionType/TestTakerRecord";
import AuthoringTextEditor from "../../AuthoringTextEditor";
import { RenderDroppedQuestionTypeProps } from "../../DroppedQuestionType";

type TestTakerUploadQuestionProps = {
  problemPreview?: TestTakerRecordProblem;
  actionable?: boolean;
} & Omit<RenderDroppedQuestionTypeProps, "draggedName" | "problemPreview">;

const TestTakerRecordVoiceQuestion = ({
  problemPreview,
}: TestTakerUploadQuestionProps) => {
  if (!problemPreview || !problemPreview.id) return <></>;

  return (
    <div className="">
      <div className="no_border_editor">
        <AuthoringTextEditor
          toolbarId={`problem_preview_${problemPreview.resource_id}_${problemPreview.id}`}
          isReadOnly
          value={problemPreview?.title || ""}
        />
      </div>
      <div className="flex justify-center z-[100]">
        <div className="text-[#235FA9] border border-[#235FA9] rounded-full w-[44px] h-[44px] flex justify-center items-center shadow-[2px_2px_2px_0_rgba(0, 0, 0, 0.10)]">
          <TestTakerRecord />
        </div>
      </div>
    </div>
  );
};

export default TestTakerRecordVoiceQuestion;
