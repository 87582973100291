import QuestionPageBase from '.';
import ListQuestion from '../../../components/admin/Question/ListQuestion';
import QuestionListProvider from '../../../context/Question/ListQuestionContext';

const QuestionListPage = () => {
  return (
    <QuestionPageBase>
      <QuestionListProvider>
        <ListQuestion />
      </QuestionListProvider>
    </QuestionPageBase>
  );
};

export default QuestionListPage;
