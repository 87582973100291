import React, { useState } from "react";
import ResultComponent from "./ResultComponent";
import KeyPadComponent from "./KeyPadComponent";

const CalculatorTool = () => {
  const [result, setResult] = useState<string>("");
  const operators = ["(", ")", "+", "-", "*", "/"];
  
  const onClick = (button: string) => {
    if (button === "=") {
      calculate();
    } else if (button === "C") {
      reset();
    } else if (button === "CE") {
      backspace();
    } else {
      const resultLength = result.length;
      if (!resultLength && Number(button) === 0) return;
      if (
        operators.includes(result.split("")[resultLength - 1]) &&
        Number(button) === 0
      ) {
        return;
      }

      setResult((prevResult) => prevResult + button);
    }
  };

  const calculate = () => {
    let checkResult = "";
    if (result.includes("--")) {
      checkResult = result.replace("--", "+");
    } else {
      checkResult = result;
    }

    try {
      setResult((eval(checkResult) || "") + "");
    } catch (e) {
      setResult("error");
    }
  };

  const reset = () => {
    setResult("");
  };

  const backspace = () => {
    setResult((prevResult) => prevResult.slice(0, -1));
  };

  return (
    <div className="">
      <div className="absolute top-[40%] left-[10%] w-full max-w-[360px] pb-[10px] m-auto shadow-md bg-gray-500 py-[10px]">
        <ResultComponent result={result} />
        <KeyPadComponent onClick={onClick} />
      </div>
    </div>
  );
};

export default CalculatorTool;
