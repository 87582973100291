import React, { ChangeEvent } from "react";
import {
  ChoiceProblemTest,
  MappingProblemTest,
  MatrixProblemTest,
  OrderProblemTest,
  QuestionProblemTest,
  SliderProblemTest,
  TestTakerRecordProblemTest,
  TestTakerUploadProblemTest,
  WritingProblemTest,
} from "../../../../types/admin/question";
import { NAME_TYPE_QUESTION } from "../../../../services/constants/admin/pages/question";
import ChoiceQuestion from "./QuestionTypes/ChoiceQuestion";
import OrderQuestion from "./QuestionTypes/OrderQuestion";
import MappingQuestion from "./QuestionTypes/MappingQuestion";
import MatrixQuestion from "./QuestionTypes/MatrixQuestion";
import FillInBlankQuestion from "./QuestionTypes/FillInBlankQuestion";
import SliderQuestion from "./QuestionTypes/SliderQuestion";
import WritingQuestion from "./QuestionTypes/WritingQuestion";
import RecordVoiceQuestion from "./QuestionTypes/RecordVoiceQuestion";
import UploadQuestion from "./QuestionTypes/UploadQuestion";
import TextBlockQuestion from "./QuestionTypes/TextBlockQuestion";
import MediaToolQuestion from "./QuestionTypes/MediaToolQuestion";

type TestItemQuestionTypeProps = {
  questionProblem: QuestionProblemTest;
  handleUpdateProblem: (problem: QuestionProblemTest) => void;
  isDisabled?: boolean;
};

const TestItemQuestionType = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled = false
}: TestItemQuestionTypeProps) => {
  const questionType = questionProblem.name;

  switch (questionType) {
    case NAME_TYPE_QUESTION.CHOICE:
      return (
        <ChoiceQuestion
          questionProblem={questionProblem as ChoiceProblemTest}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.ORDER:
      return (
        <OrderQuestion
          questionProblem={questionProblem as OrderProblemTest}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.MAPPING:
      return (
        <MappingQuestion
          questionProblem={questionProblem as MappingProblemTest}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.MATRIX:
      return (
        <MatrixQuestion
          questionProblem={questionProblem as MatrixProblemTest}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.FILL_IN_BLANK:
      return (
        <FillInBlankQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.SLIDER:
      return (
        <SliderQuestion
          questionProblem={questionProblem as SliderProblemTest}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.WRITTEN_QUESTION:
      return (
        <WritingQuestion
          questionProblem={questionProblem as WritingProblemTest}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.TEXT_BLOCK:
      return (
        <TextBlockQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD:
      return (
        <RecordVoiceQuestion
          questionProblem={questionProblem as TestTakerRecordProblemTest}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD:
      return (
        <UploadQuestion
          questionProblem={questionProblem as TestTakerUploadProblemTest}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.AUDIO:
    case NAME_TYPE_QUESTION.IMAGE:
    case NAME_TYPE_QUESTION.VIDEO:
      return (
        <MediaToolQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
        />
      );
    default:
      return <></>;
  }
};

export default TestItemQuestionType;
