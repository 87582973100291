import React from 'react';
import CandidateBase from '.';
import CandidateUpdate from '../../../components/admin/Candidate/CandidateUpdate';

const CandidateUpdatePage = () => {
  return (
    <CandidateBase title="受験者管理 ｜ 受験者情報の編集">
      <CandidateUpdate/>
    </CandidateBase>
  );
};

export default CandidateUpdatePage;