import { getTabObj } from '../../../helpers/parseData';
import { PATH_ADMIN_EXAM } from '../../route/router';

const EXAM_TAB_ITEM = {
  EXAM_LIST: 'テスト一覧',
  CREATE_NEW: "テスト新規追加",
};

const EXAM_TAB_DATA = [
  getTabObj(EXAM_TAB_ITEM.EXAM_LIST, PATH_ADMIN_EXAM.DEFAULT),
  getTabObj(EXAM_TAB_ITEM.CREATE_NEW, PATH_ADMIN_EXAM.CREATE),
];

const NAME_ACTION_DETAIL_EXAM = {
  EDIT: 'edit',
  AUTHORING: 'authoring',
  PREVIEW: 'preview',
};

const NAME_ACTION_ASIDE_TEST = {
  CREATE_FILE: 'create-file',
  CREATE_FOLDER: 'create-folder',
  DUPLICATE: 'duplicate',
  COPY: 'copy',
  DELETE: 'delete',
  MOVE: 'move',
};

const UPDATE_ORDER = {
  UP: -1,
  DOWN: 1
};

const ARRANGE_TYPE = {
  LIST: "list",
  TREE: "tree",
};

const TYPE_OF_EXAM_DISPLAY = {
  ITEM: "item",
  SECTION: "section",
  PART: "part",
  EXAM: "exam",
}

const SETTING_FEATURES = {
  NAVIGATION_WARNING: "ナビゲーション警告",
  TEST_NAVIGATION: "テストナビゲーション",
  EXAMINEE_TOOLS: "受験者ツール",
  ITEM_SESSION_CONTROL: "アイテムセッションコントロール",
  TIME_LIMIT: "制限時間",
  SCORING: "スコア付け",
  ORDER_OF_QUESTIONS: "出題順"
}

const SETTING_PROPERTY_NAMES = {
  NAME: "name",
  IS_FIXED_LOCATION: "is_fixed_location",
  ANSWER_IN_ORDER: "answer_in_order",
  SCORING_TYPE: "scoring_type",

  // Setting alert
  HIDE_TIME_WARNING: "hide_time_warning",
  SHOW_UNANSWERED_WARNING: "show_unanswered_warning",
  SHOW_TIMEOUT_WARNING: "show_timeout_warning",
  END_TEST_WARNING: "end_test_warning",
  REDIRECT_WARNING: "redirect_warning",
  NAVIGATE_PART_WARNING: "navigate_part_warning",
  NAVIGATE_SECTION_WARNING: "navigate_section_warning",
  UNANSWERED_WARNING: "unanswered_warning",
  TIMES_UP_WARNING: "times_up_warning",

  // Examinee tool
  REMOVE_ANSWER: 'remove_answer',
  SHOW_CALCULATOR: 'show_calculator',
  SHOW_HIGH_LIGHT: 'show_high_light',
  SHOW_ZOOM_GLASS: 'show_magnifying_glass',
  SHOW_ZOOM_TOOL: 'show_zoom',

  // Item session control
  MAX_TIME_ANSWER: "max_times_answer",
  IS_SKIP: "is_skip",
  IS_MIN_ANSWER: "is_min_answer",

  // Time limit
  MIN_HOUR: "min_hours",
  MIN_MINUTE: "min_minutes",
  MIN_SECOND: "min_seconds",
  MAX_HOUR: "max_hours",
  MAX_MINUTE: "max_minutes",
  MAX_SECOND: "max_seconds",
  IS_SUBMISSION_AFTER_DEADLINE: "is_submission_after_deadline",

  // OTHER
  SHOW_REVIEW: "show_review",
  IS_SHUFFLE: "is_shuffle",

  // Score 
  RESULT_PROCESSING: "result_processing"
}

const ANSWER_IN_ORDER_VALUE = {
  SEQUENTIAL: 'sequential',
  NON_SEQUENTIAL: 'non-sequential'
}

export { EXAM_TAB_ITEM, EXAM_TAB_DATA, NAME_ACTION_ASIDE_TEST, NAME_ACTION_DETAIL_EXAM, UPDATE_ORDER, ARRANGE_TYPE, TYPE_OF_EXAM_DISPLAY, SETTING_FEATURES, SETTING_PROPERTY_NAMES, ANSWER_IN_ORDER_VALUE };