import { BiSearchAlt2 } from "react-icons/bi";
import { FILTER } from "../../../services/constants/admin/main";
import { useState, ChangeEvent, useEffect, useRef } from "react";

const FolderFileFilter = ({
  changeHandle,
  deleteSelected,
  setIsOpenMoveModal
}: {
  changeHandle: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  deleteSelected: () => void;
  setIsOpenMoveModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const selectRef = useRef<HTMLSelectElement | null>(null);

  return (
    <section className="my-[30px] border-secondary-light">
      <div className="flex h-[30px] gap-[8px] relative">
        <div className="relative">
          <input
            type="text"
            name="origin_filename"
            id=""
            className="h-full max-w-[200px] pl-[12px] placeholder-[#BEBEBE] text-[11px] leading-[100%] border-[#E1E3E2] rounded-[5px]"
            placeholder="ファイルを検索"
            onChange={changeHandle}
          />
          <BiSearchAlt2
            className="absolute top-[5px] right-[9.26px] text-secondary-light"
            size={22}
          />
        </div>

        <div className="relative">
          <select
            name="limit"
            id=""
            className="h-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] leading-[100%] w-[95px]"
            onChange={changeHandle}
            ref={selectRef}
          >
            {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
              <option value={item.value} className="" key={index}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="whitespace-nowrap text-[13px] font-[400] absolute right-1">
        チェックしたファイルを
          <button
            className="ml-[8px] min-w-[60px] min-h-[30px] text-[14px] px-[15px] py-[8px] rounded-[5px] bg-[#FE6D73] text-white"
            onClick={deleteSelected}
          >
            削除
          </button>
          <button
            className="ml-[8px] min-w-[102px] min-h-[30px] text-[14px] px-[15px] py-[8px] rounded-[5px] bg-secondary-light text-white"
            onClick={() => setIsOpenMoveModal(true)}
          >
            カテゴリ移動
          </button>
        </div>
      </div>
    </section>
  );
};

export default FolderFileFilter;
