import React from "react";
import {
  FILL_IN_BLANK_BUTTON_TYPE,
  FILL_IN_BLANK_CONTENT_RENDER_TYPE,
} from "../../../../../services/constants/admin/pages/question";
import { GrFormClose } from "react-icons/gr";

type FilledFillRenderProps = {
  identifier: string;
  content: string;
  defaultPoint: number;
};
type PreviewBlankFillRenderProps = { identifier: string };
type BlankFillRenderProps = {
  identifier: string;
  content: string;
  defaultPoint: number;
};

const FilledFillRenderTest = ({
  identifier,
  content,
}: FilledFillRenderProps) => {
  return (
    <span
      className="highlight_content mx-[2px] inline"
      id={identifier}
      contentEditable={false}
    >
      <span className="relative">
        <button
          className="border border-secondary-light min-w-[88px] h-[32px] p-[3px] !pt-0 rounded-[5px] fill_in_blank_target text-danger button_hover_none focus:ring focus:ring-primary"
          data-target-identifier={identifier}
          data-target-content={content}
          data-render-type={FILL_IN_BLANK_CONTENT_RENDER_TYPE.FILLED}
          data-button-type={FILL_IN_BLANK_BUTTON_TYPE.SETTING}
        >
          <span className="pr-[15px]">{content}</span>
        </button>

        <button
          className="absolute right-[2.5px] top-[4px] rounded-[2px]"
          data-target-identifier={identifier}
          data-target-content={content}
          data-button-type={FILL_IN_BLANK_BUTTON_TYPE.CLOSE}
        >
          <GrFormClose size={15} className="text-secondary" />
        </button>
      </span>
    </span>
  );
};

const PreviewBlankFillRenderTest = ({
  identifier,
}: PreviewBlankFillRenderProps) => {
  return (
    // <span
    //   className="highlight_content mx-[2px] inline"
    //   id={identifier}
    //   contentEditable={false}
    // >
    <button
      className="cursor-pointer border border-secondary-light w-[88px] h-[32px] rounded-[5px] fill_in_blank_target button_hover_none focus:ring focus:ring-primary"
      data-target-identifier={identifier}
      data-render-type={FILL_IN_BLANK_CONTENT_RENDER_TYPE.BLANKED}
      data-button-type={FILL_IN_BLANK_BUTTON_TYPE.SETTING}
    ></button>
    //</span>
  );
};

const BlankFillRenderTest = ({ identifier, content, defaultPoint }: BlankFillRenderProps) => {
    return (
      <span className="highlight_content mx-[2px] inline" id={identifier} contentEditable={false}>
        <button
          className="cursor-pointer border border-secondary-light w-[88px] h-[32px] rounded-[5px] fill_in_blank_target button_hover_none focus:ring focus:ring-primary"
          data-target-identifier={identifier}
          data-render-type={FILL_IN_BLANK_CONTENT_RENDER_TYPE.BLANKED}
          data-button-type={FILL_IN_BLANK_BUTTON_TYPE.SETTING}
        ></button>
      </span>
    );
  };

export {
  FilledFillRenderTest,
  PreviewBlankFillRenderTest,
  BlankFillRenderTest
};
