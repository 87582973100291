import React from 'react';
import { PiMagicWand, PiTrash } from 'react-icons/pi';
import { GrFormClose } from 'react-icons/gr';
import {
  FILL_IN_BLANK_BUTTON_TYPE,
  FILL_IN_BLANK_CONTENT_RENDER_TYPE,
} from '../../../../../../../services/constants/admin/pages/question';

type MagicHighlightRenderProps = {
  highlightContent: string;
  identifier: string;
};

type BlankFillRenderProps = { identifier: string; content: string; defaultPoint: number };

type FilledFillRenderProps = { identifier: string; content: string; defaultPoint: number };

type PreviewBlankFillRenderProps = { identifier: string };

const MagicHighlightRender = ({ highlightContent, identifier }: MagicHighlightRenderProps) => {
  return (
    <span id={identifier}>
      <span className="bg-success-lighter highlight_content inline">{highlightContent}</span>

      <button
        className="highlighted-content"
        type="submit"
        data-identifier={identifier}
        data-content={highlightContent}
        data-render-type={FILL_IN_BLANK_CONTENT_RENDER_TYPE.HIGHLIGHTED}
        data-button-type={FILL_IN_BLANK_BUTTON_TYPE.CREATE}
      >
        <PiMagicWand className="text-danger cursor-pointer" size={22} />
      </button>
    </span>
  );
};

const PreviewBlankFillRender = ({ identifier }: PreviewBlankFillRenderProps) => {
  return (
    <span className="highlight_content mx-[2px] inline" id={identifier} contentEditable={false}>
      <button
        className="cursor-pointer border border-secondary-light w-[88px] h-[32px] rounded-[5px] fill_in_blank_target button_hover_none focus:ring focus:ring-primary"
        data-target-identifier={identifier}
        data-render-type={FILL_IN_BLANK_CONTENT_RENDER_TYPE.BLANKED}
        data-button-type={FILL_IN_BLANK_BUTTON_TYPE.SETTING}
      ></button>
    </span>
  );
};

const BlankFillRender = ({ identifier, content, defaultPoint }: BlankFillRenderProps) => {
  return (
    <span className="highlight_content mx-[2px] inline" id={identifier} contentEditable={false}>
      <button
        className="cursor-pointer border border-secondary-light w-[88px] h-[32px] rounded-[5px] fill_in_blank_target button_hover_none focus:ring focus:ring-primary"
        data-target-identifier={identifier}
        data-render-type={FILL_IN_BLANK_CONTENT_RENDER_TYPE.BLANKED}
        data-button-type={FILL_IN_BLANK_BUTTON_TYPE.SETTING}
      ></button>
      <span className="mt-[-24px]">
        <input
          type="number"
          name="point"
          placeholder="0"
          className="w-[35px] h-[32px] !p-[5px] mx-[3px] !border-secondary-light"
          defaultValue={defaultPoint || ''}
          data-identifier={identifier}
        />
      </span>
      <button
        className="cursor-pointer text-secondary blank-highlighted-content hover:bg-none"
        data-identifier={identifier}
        data-content={content}
        data-button-type={FILL_IN_BLANK_BUTTON_TYPE.REMOVE}
      >
        <PiTrash size={22} className="rounded-[5px] hover:bg-secondary-lighter mt-[-24px]" />
      </button>
    </span>
  );
};

const FilledFillRender = ({ identifier, content, defaultPoint }: FilledFillRenderProps) => {
  return (
    <span className="highlight_content mx-[2px] inline" id={identifier} contentEditable={false}>
      <span className="relative">
        <button
          className="border border-secondary-light min-w-[88px] h-[32px] p-[3px] !pt-0 rounded-[5px] fill_in_blank_target text-danger button_hover_none focus:ring focus:ring-primary"
          data-target-identifier={identifier}
          data-target-content={content}
          data-render-type={FILL_IN_BLANK_CONTENT_RENDER_TYPE.FILLED}
          data-button-type={FILL_IN_BLANK_BUTTON_TYPE.SETTING}
        >
          <span className="pr-[15px]">{content}</span>
        </button>

        <button
          className="absolute right-[2.5px] top-[4px] rounded-[2px]"
          data-target-identifier={identifier}
          data-target-content={content}
          data-button-type={FILL_IN_BLANK_BUTTON_TYPE.CLOSE}
        >
          <GrFormClose size={15} className="text-secondary" />
        </button>
      </span>
      <span className="mt-[-2px]">
        <input
          type="number"
          name="point"
          placeholder="0"
          className="w-[35px] h-[32px] !p-[5px] mx-[3px] !border-secondary-light"
          defaultValue={defaultPoint || ''}
          data-identifier={identifier}
        />
      </span>
      <button
        className="cursor-pointer text-secondary blank-highlighted-content hover:bg-none"
        data-identifier={identifier}
        data-content={content}
        data-button-type={FILL_IN_BLANK_BUTTON_TYPE.REMOVE}
      >
        <PiTrash size={22} className="rounded-[5px] hover:bg-secondary-lighter mt-[-2px]" />
      </button>
    </span>
  );
};

export { MagicHighlightRender, PreviewBlankFillRender, BlankFillRender, FilledFillRender };
