import {
  PATH_CANDIDATE_HOME,
  PATH_EXAMINATION,
  PATH_MY_PROFILE,
  ROUTE,
} from "../services/constants/route/router";
import { RouteObject } from "react-router";
import HomePage from "../pages/candidate/home/Home";
import RegisterExamPage from "../pages/candidate/register-exam/RegisterExam";
import Profile from "../pages/candidate/profile";
import MailChanging from "../pages/candidate/profile/mail-changing";
import PasswordChanging from "../pages/candidate/profile/password-changing";
import PrintPage from "../components/candidate/RegisterExamination/PrintPage";
import ExaminationEnterCodePage from "../pages/candidate/examination/ExaminationEnterCode";
import ExaminationViewPage from "../pages/candidate/examination/ExaminationView";
import ExaminationPartListPage from "../pages/candidate/examination/ExaminationPartList";
import ExaminationTestPartPage from "../pages/candidate/examination/ExaminationTestPart";

type CustomRouterObject = {};
const candidateRouter: Array<RouteObject & CustomRouterObject> = [
  {
    path: ROUTE.CANDIDATE_PREFIX,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: PATH_CANDIDATE_HOME.REGISTER_EXAMINATION,
        children: [
          { index: true, element: <RegisterExamPage /> },
          {
            path: PATH_CANDIDATE_HOME.PRINT_REGISTER_EXAMINATION,
            element: <PrintPage />,
          },
        ],
      },
      {
        path: PATH_MY_PROFILE.DEFAULT,
        children: [
          { index: true, element: <Profile /> },
          { path: PATH_MY_PROFILE.MAIL_CHANGING, element: <MailChanging /> },
          {
            path: PATH_MY_PROFILE.PASSWORD_CHANGING,
            element: <PasswordChanging />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTE.EXAMINATION.DEFAULT,
    children: [
      {
        path: PATH_EXAMINATION.EXAMINATION_VIEW,
        element: <ExaminationViewPage />,
      },
      {
        path: PATH_EXAMINATION.ENTER_CODE,
        element: <ExaminationEnterCodePage />,
      },
      {
        path: PATH_EXAMINATION.EXAMINATION_PART_LIST,
        element: <ExaminationPartListPage />,
      },
      {
        path: PATH_EXAMINATION.EXAMINATION_TEST_PART,
        element: <ExaminationTestPartPage />,
      },
    ],
  },
];

export default candidateRouter;
