import React, { useState, useContext } from "react";
import { ChildrenWithProps } from "../../types/globals";
import { ExamParticipateType } from "../../types/admin/examParticipate";

interface ExamParticipateTypes {
  examParticipates: ExamParticipateType[];
  setExamParticipates: React.Dispatch<
    React.SetStateAction<ExamParticipateType[]>
  >;
  totalPages: number;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
}

const ExamParticipateContext = React.createContext<ExamParticipateTypes>({
  examParticipates: [],
  setExamParticipates: () => {},
  totalPages: 1,
  setTotalPages: () => {},
});

const ExamParticipateProvider = ({ children }: ChildrenWithProps) => {
  const [examParticipates, setExamParticipates] = useState<ExamParticipateType[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);

  return (
    <ExamParticipateContext.Provider
      value={{
        examParticipates,
        setExamParticipates,
        totalPages,
        setTotalPages,
      }}
    >
      {children}
    </ExamParticipateContext.Provider>
  );
};

export default ExamParticipateProvider;

export const useExamParticipateContext = () => useContext(ExamParticipateContext);
