import ButtonNextStep from "./RegisterExaminationComponent/ButtonNextStep";
import RegisterExaminationStep1 from "./RegisterExaminationStep1";
import StepRange from "./RegisterExaminationComponent/StepRange";
import RegisterExaminationStep2 from "./RegisterExaminationStep2";
import { useExaminationRegisterContext } from "../../../context/Candidate/ExaminationRegisterContext";
import { useCallback, useEffect } from "react";
import ButtonBackStep from "./RegisterExaminationComponent/ButtonBackStep";
import {
  ERROR_MESSAGE_REGISTER_EXAMINATIONS,
  INDEX_STEP_REGISTER_EXAMINATIONS,
  TITLE_STEP_REGISTER_EXAMINATIONS,
} from "../../../services/constants/candidate/registerExamination";
import classNames from "classnames";
import RegisterExaminationStep3 from "./RegisterExaminationStep3";
import ErrorMessage from "./RegisterExaminationComponent/ErrorMessage";
import { EXAMINATION_FORMAT } from "../../../services/constants/admin/pages/examination";
import RegisterExaminationStep4 from "./RegisterExaminationStep4";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { isEmptyObj } from "../../../services/helpers/etc";
import dayjs from "dayjs";

const RegisterExamination = () => {
  const {
    errors,
    currentStep,
    examinationType,
    registerExaminationData,
    setErrors,
    setStepCurrent,
  } = useExaminationRegisterContext();
  const { searchParamsObject } = useObjectRoutes();

  const displayStep = useCallback(() => {
    switch (currentStep.step) {
      case INDEX_STEP_REGISTER_EXAMINATIONS.STEP_1:
        return <RegisterExaminationStep1 />;
      case INDEX_STEP_REGISTER_EXAMINATIONS.STEP_2:
        return <RegisterExaminationStep2 />;
      case INDEX_STEP_REGISTER_EXAMINATIONS.STEP_3:
        return <RegisterExaminationStep3 />;
      case INDEX_STEP_REGISTER_EXAMINATIONS.STEP_4:
        return <RegisterExaminationStep4 />;
      default:
        <></>;
    }
  }, [currentStep]);

  const getCurrentStepTitle = (step: number) => {
    switch (step) {
      case INDEX_STEP_REGISTER_EXAMINATIONS.STEP_1:
        return TITLE_STEP_REGISTER_EXAMINATIONS.STEP1;
      case 2:
        return examinationType === EXAMINATION_FORMAT.IBT
          ? TITLE_STEP_REGISTER_EXAMINATIONS.STEP2_IBT
          : TITLE_STEP_REGISTER_EXAMINATIONS.STEP2_CBT;
      case 3:
        return TITLE_STEP_REGISTER_EXAMINATIONS.STEP3;
      case 4:
        return TITLE_STEP_REGISTER_EXAMINATIONS.STEP4;
      default:
        return TITLE_STEP_REGISTER_EXAMINATIONS.STEP1;
    }
  };

  const handleBackStep = () => {
    if (
      !currentStep.step ||
      currentStep.step === INDEX_STEP_REGISTER_EXAMINATIONS.STEP_1
    )
      return;
    let stepBack = currentStep.step - 1;

    setStepCurrent({
      ...currentStep,
      step: stepBack,
      name: getCurrentStepTitle(stepBack),
    });
    setErrors([]);
  };

  const handleValidateStep1 = () => {
    let err = [];
    if (!registerExaminationData.exam_name_id) {
      err.push(ERROR_MESSAGE_REGISTER_EXAMINATIONS.EXAM_NAME_ID);
    }
    if (!registerExaminationData.examination_id) {
      err.push(ERROR_MESSAGE_REGISTER_EXAMINATIONS.EXAMINATION_ID);
    }

    setErrors(err);

    if (!err.length) {
      setStepCurrent({
        step: INDEX_STEP_REGISTER_EXAMINATIONS.STEP_2,
        name:
          examinationType === EXAMINATION_FORMAT.IBT
            ? TITLE_STEP_REGISTER_EXAMINATIONS.STEP2_IBT
            : TITLE_STEP_REGISTER_EXAMINATIONS.STEP2_CBT,
      });
    }
  };

  const handleValidateStep2 = () => {
    let err = [];
    if (!registerExaminationData.selected_date) {
      err.push(ERROR_MESSAGE_REGISTER_EXAMINATIONS.SELECTED_DATE);
    }
    if (!registerExaminationData.selected_time) {
      err.push(ERROR_MESSAGE_REGISTER_EXAMINATIONS.SELECTED_TIME);
    }

    if(registerExaminationData.selected_date) {
      const customDate = dayjs(registerExaminationData.selected_date + " " + registerExaminationData.selected_time);
      const today = dayjs();
      
      if(customDate.isBefore(today)) {
        err.push(ERROR_MESSAGE_REGISTER_EXAMINATIONS.SELECTED_DATE_AFTER_NOW);
      }
    }

    setErrors(err);

    if (!err.length) {
      setStepCurrent({
        step: INDEX_STEP_REGISTER_EXAMINATIONS.STEP_3,
      });
    }
  };

  const handleSubmitValidate = () => {
    switch (currentStep.step) {
      case INDEX_STEP_REGISTER_EXAMINATIONS.STEP_1:
        handleValidateStep1();
        break;
      case INDEX_STEP_REGISTER_EXAMINATIONS.STEP_2:
        handleValidateStep2();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isEmptyObj(searchParamsObject)) return;

    if (searchParamsObject.step) {
      setStepCurrent({
        step: Number(searchParamsObject.step),
        name: TITLE_STEP_REGISTER_EXAMINATIONS.STEP4,
      });
    }
  }, []);
  
  return (
    <>
      <div className="w-full pb-[50px]">
        <div className="text-[24px] w-full flex justify-start items-center my-[29px]">
          試験申込
        </div>
        <StepRange stepCurrent={currentStep?.step || 1} />
        {errors.length > 0 ? <ErrorMessage errors={errors} /> : <></>}
        {displayStep()}
        <div
          className={classNames("flex justify-between items-center w-full", {
            "!justify-end":
              currentStep.step === INDEX_STEP_REGISTER_EXAMINATIONS.STEP_1,
          })}
        >
          {currentStep.step &&
            currentStep.step !== INDEX_STEP_REGISTER_EXAMINATIONS.STEP_1 &&
            currentStep.step <= INDEX_STEP_REGISTER_EXAMINATIONS.STEP_2 && (
              <ButtonBackStep onClick={handleBackStep} />
            )}
          {currentStep.step &&
            currentStep.step <= INDEX_STEP_REGISTER_EXAMINATIONS.STEP_2 && (
              <ButtonNextStep onClick={handleSubmitValidate} />
            )}
        </div>
      </div>
    </>
  );
};

export default RegisterExamination;
