import React from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { ElementProps } from '../../../types/globals';

type TooltipDisplayProps = {
  content: string;
  className?: string;
} & ElementProps<HTMLDivElement>;

const TooltipDisplay = ({ content, className = '', ...props }: TooltipDisplayProps) => {
  return (
    <div className={`w-[280px] ${className}`} {...props}>
      <div
        className="w-full p-[20px] bg-[#5A5A5A] text-white rounded-[10px] text-[12px] font-[500] leading-[140%]"
        style={{
          boxShadow: '0px 4px 5px 0px rgba(117, 117, 117, 0.25)',
        }}
      >
        {content}
      </div>
      <div className="w-full flex justify-center">
        <AiFillCaretDown size={22} color="#5A5A5A" className="mt-[-7px]" />
      </div>
    </div>
  );
};

export default TooltipDisplay;
