import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { ChildrenWithProps, WithChildren } from "../types/globals";

type SidebarContextType = {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
};

const SidebarContext = React.createContext<SidebarContextType>({
  showSidebar: true,
  setShowSidebar: () => {},
});

const SidebarContextProvider = ({ children }: ChildrenWithProps) => {
  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <SidebarContext.Provider value={{ showSidebar, setShowSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
export default SidebarContextProvider;
