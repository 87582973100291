import React, { useState, useContext } from 'react';
import { ChildrenWithProps } from '../../types/globals';
import { Group } from '../../types/admin/group';
import { sortArrayByKey } from '../../services/helpers/parseData';

interface GroupListContextType {
  groups: Group[];
  setGroups: (groups: Group[]) => void;
  mainGroups: Group[];
  subGroups: (parentGroupId?: number) => Group[];
  selectedGroup: Group;
  setSelectedGroup: (groupId: Group['id']) => void;
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const GroupListContext = React.createContext<GroupListContextType>({
  groups: [],
  setGroups: () => {},
  mainGroups: [],
  subGroups: () => [],
  selectedGroup: {},
  setSelectedGroup: () => {},
  isOpenModal: false,
  setIsOpenModal: () => {},
});

const GroupListProvider = ({ children }: ChildrenWithProps) => {
  const [groups, _setGroups] = useState<Group[]>([]);
  const [selectedGroup, _setSelectedGroup] = useState<Group>({});
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const setGroups = (groups: Group[]) => {
    const uniqueGroups = groups.filter(
      (group, index, a) => a.findIndex((_group) => _group.id === group.id) === index,
    );
    _setGroups(sortArrayByKey(uniqueGroups, 'order'));
  };

  const setSelectedGroup = (groupId: Group['id']) =>
    _setSelectedGroup(groups.find((group) => group.id === groupId) || {});

  const mainGroups = groups.filter((group) => group.parent_group_id === null);
  const subGroups = (parentGroupId?: number) => {
    if (parentGroupId)
      return groups.filter((group) => group.parent_group_id === parentGroupId);
    return groups.filter((group) => group.parent_group_id !== null);
  };

  return (
    <GroupListContext.Provider
      value={{
        groups,
        setGroups,
        mainGroups,
        subGroups,
        selectedGroup,
        setSelectedGroup,
        isOpenModal,
        setIsOpenModal,
      }}
    >
      {children}
    </GroupListContext.Provider>
  );
};

export default GroupListProvider;

export const useGroupListContext = () => useContext(GroupListContext);
