import React from "react";
import RegisterExaminationView from "../../../components/candidate/Home/RegisterExaminationView";
import LayoutExaminationDetail from "../../../layouts/candidate/LayoutExaminationDetail";

const ExaminationViewPage = () => {
  return (
    <LayoutExaminationDetail classNameChild="min-h-screen">
      <RegisterExaminationView />
    </LayoutExaminationDetail>
  );
};

export default ExaminationViewPage;
