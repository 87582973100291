import GroupManagement from '../../../components/admin/Group/Management/Management';
import GroupBase from '.';
import GroupManagementProvider from '../../../context/Group/Management';

const GroupManagementPage = () => {
  return (
    <GroupBase title="グループ管理｜ 受験者管理">
      <GroupManagementProvider>
        <GroupManagement />
      </GroupManagementProvider>
    </GroupBase>
  );
};

export default GroupManagementPage;
