import {
  IoMdInformationCircleOutline,
  IoMdRemoveCircleOutline,
} from "react-icons/io";
import { EXAMINATION_STATUS } from "../../../../services/constants/admin/pages/examination";
import Pagination from "../../commons/Pagination";
import { PATH_ADMIN_EXAMINATION } from "../../../../services/constants/route/router";
import { useNavigate } from "react-router-dom";
import { useExaminationContext } from "../../../../context/ExaminationContext";
import { useState } from "react";
import TooltipDisplay from "../../../commons/etcs/ToolTipDisplay";

const ExaminationTable = ({
  currentPage,
  handlePageChange,
  toggleSelect,
  toggleSelectAll,
  selectAll,
  isExaminationSelected,
}: {
  currentPage: number;
  handlePageChange: (number: number) => void;
  toggleSelect: (id: number) => void;
  toggleSelectAll: () => void;
  selectAll: boolean;
  isExaminationSelected: (id: number) => boolean;
}) => {
  const [displayTooltipStatus, setDisplayTooltipStatus] =
    useState<boolean>(false);
  const { totalPages, examinations } = useExaminationContext();
  const navigate = useNavigate();

  const redirectDetailExamVenue = (id: number) => {
    const examination = examinations.find(
      (examination) => examination.id === id
    );
    if (!examination) return;

    navigate(`${PATH_ADMIN_EXAMINATION.DETAIL}?id=${examination.id}`);
  };

  return (
    <>
      <div className="relative overflow-x-auto animate-[show_0.5s_ease-in-out]">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <th
                scope="col"
                className="py-[2px] w-[40px] border-x-4 border-white whitespace-nowrap "
              >
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                />
              </th>
              <th
                scope="col"
                className="2xl:px-[50px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                試験
              </th>
              <th
                scope="col"
                className="2xl:px-[50px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                試験（種類）
              </th>
              <th
                scope="col"
                className="2xl:px-[43px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                試験番号
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                形式
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-white whitespace-nowrap flex items-center justify-center"
              >
                {displayTooltipStatus && (
                  <div className="fixed">
                    <TooltipDisplay
                      content={"購入者側には、税込価格で表示されます。"}
                      className="absolute top-[-80px] left-[-90px]"
                    />
                  </div>
                )}
                販売価格（税抜）
                <IoMdInformationCircleOutline
                  size={20}
                  className="cursor-pointer text-secondary"
                  onMouseEnter={() => setDisplayTooltipStatus(true)}
                  onMouseLeave={() => setDisplayTooltipStatus(false)}
                />
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                ステータス
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              ></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(examinations) &&
              examinations &&
              !!examinations.length &&
              examinations.map((examination) => (
                <tr
                  key={examination?.id}
                  className="bg-white border-b w-full text-[14px] text-[#2E2E2E]"
                >
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={isExaminationSelected(Number(examination?.id))}
                      onChange={() => toggleSelect(Number(examination?.id))}
                    />
                  </td>
                  <td className="px-6 py-4 font-[400] whitespace-nowrap text-primary-synch leading-[100%] max-w-[200px] truncate">
                    {examination.exam_name?.name}
                  </td>
                  <th className="px-6 py-4 font-[400] text-primary-synch whitespace-nowrap leading-[100%] max-w-[200px] truncate">
                    {examination.type}
                  </th>
                  <th className="px-6 py-4 font-[400] whitespace-nowrap leading-[20px]">
                    {examination.code}
                  </th>
                  <th className="px-6 py-4 font-[400] whitespace-nowrap leading-[20px]">
                    {examination.format}
                  </th>
                  <th className="px-6 py-4 font-[400] whitespace-nowrap leading-[20px] text-center">
                    {examination.is_free ? "無料" : `¥${examination.price}`}
                  </th>
                  <td className="py-4 text-center m-auto">
                    {examination.status == EXAMINATION_STATUS.PUBLIC && ( // button for Public = 1
                      <div className="flex justify-center">
                        <div className="max-w-[50px] max-h-[25px] px-[6px] pb-[5px] text-danger border border-danger rounded-[5px] whitespace-nowrap cursor-default">
                          公開
                        </div>
                      </div>
                    )}
                    {examination.status == EXAMINATION_STATUS.PRIVATE && ( // button for Private = 0
                      <div className="min-w-[100px] px-[2px] pt-[3px] pb-[5px] text-[#7A7A7A] bg-neutral-100 rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <IoMdRemoveCircleOutline
                          className="absolute top-[2px] left-[4px]"
                          size={18}
                        />
                        <p className="pl-[20px]">非公開</p>
                      </div>
                    )}
                  </td>
                  <td className="text-center">
                    <button
                      className="min-w-[50px] text-[14px] px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-primary-light text-white"
                      onClick={() =>
                        redirectDetailExamVenue(Number(examination?.id))
                      }
                    >
                      編集
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {totalPages && totalPages !== 0 && (
        <Pagination
          totalItems={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default ExaminationTable;
