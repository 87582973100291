import React from "react";

const YellowBlock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="288"
      height="181"
      viewBox="0 0 288 181"
      fill="none"
    >
      <path
        opacity="0.8"
        d="M287.647 0H-118V180.706H197.512C247.226 180.706 287.647 140.197 287.647 90.3736C287.647 89.0137 287.606 87.6949 287.524 86.3762C287.524 85.7169 287.524 85.0575 287.647 84.4393V0.0412503V0Z"
        fill="#D1AE37"
      />
    </svg>
  );
};

export default YellowBlock;
