import React, { ChangeEvent } from "react";
import {
  ChoiceProblemTest,
  FillInBlankProblemTest,
  MappingProblemTest,
  MatrixProblemTest,
  OrderProblemTest,
  QuestionProblemTest,
  SliderProblemTest,
  TestTakerRecordProblemTest,
  TestTakerUploadProblemTest,
  WritingProblemTest,
} from "../../../../types/admin/question";
import { NAME_TYPE_QUESTION } from "../../../../services/constants/admin/pages/question";
import ChoiceQuestion from "./QuestionTypes/ChoiceQuestion";
import OrderQuestion from "./QuestionTypes/OrderQuestion";
import MappingQuestion from "./QuestionTypes/MappingQuestion";
import MatrixQuestion from "./QuestionTypes/MatrixQuestion";
import SliderQuestion from "./QuestionTypes/SliderQuestion";
import WritingQuestion from "./QuestionTypes/WritingQuestion";
import RecordVoiceQuestion from "./QuestionTypes/RecordVoiceQuestion";
import FillInBlankQuestion from "./QuestionTypes/FillInBlankQuestion";
import UploadQuestion from "./QuestionTypes/UploadQuestion";
import { CHECK_ANSWER_STATUS_TYPE } from "../../../../services/constants/globals";
import Unknown from "../../../commons/icons/Learning/Unknown";
import Correct from "../../../commons/icons/Learning/Correct";
import Incorrect from "../../../commons/icons/Learning/Incorrect";
import FillInBlankQuestionResult from "./QuestionTypes/FillInBlackQuestionResult";

type RenderQuestionTypeProps = {
  question: QuestionProblemTest;
  numberQuestion: number;
  handleFillScoreForAnswer: (e: ChangeEvent<HTMLInputElement>) => void;
};

const RenderQuestionType = ({
  question,
  numberQuestion,
  handleFillScoreForAnswer,
}: RenderQuestionTypeProps) => {
  switch (question.name) {
    case NAME_TYPE_QUESTION.CHOICE:
      return (
        <ChoiceQuestion
          question={question as ChoiceProblemTest}
          numberQuestion={numberQuestion}
        />
      );
    case NAME_TYPE_QUESTION.ORDER:
      return (
        <OrderQuestion
          question={question as OrderProblemTest}
          numberQuestion={numberQuestion}
        />
      );
    case NAME_TYPE_QUESTION.MAPPING:
      return (
        <MappingQuestion
          question={question as MappingProblemTest}
          numberQuestion={numberQuestion}
        />
      );
    case NAME_TYPE_QUESTION.MATRIX:
      return (
        <MatrixQuestion
          question={question as MatrixProblemTest}
          numberQuestion={numberQuestion}
        />
      );
    case NAME_TYPE_QUESTION.SLIDER:
      return (
        <SliderQuestion
          question={question as SliderProblemTest}
          numberQuestion={numberQuestion}
        />
      );
    case NAME_TYPE_QUESTION.WRITTEN_QUESTION:
      return (
        <WritingQuestion
          question={question as WritingProblemTest}
          numberQuestion={numberQuestion}
          handleFillScoreAnswer={handleFillScoreForAnswer}
        />
      );
    case NAME_TYPE_QUESTION.FILL_IN_BLANK:
      return (
        <FillInBlankQuestionResult
          question={question as FillInBlankProblemTest}
          numberQuestion={numberQuestion}
        />
      );
    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD:
      return (
        <RecordVoiceQuestion
          question={question as TestTakerRecordProblemTest}
          numberQuestion={numberQuestion}
          handleFillScoreAnswer={handleFillScoreForAnswer}
        />
      );
    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD:
      return (
        <UploadQuestion
          question={question as TestTakerUploadProblemTest}
          numberQuestion={numberQuestion}
          handleFillScoreAnswer={handleFillScoreForAnswer}
        />
      );
    default:
      return <div></div>;
  }
};

export const handleShowIconCheckAnswer = (answerStatus: number | null) => {
  switch (answerStatus) {
    case CHECK_ANSWER_STATUS_TYPE.UNKNOWN:
      return <Unknown />;
    case CHECK_ANSWER_STATUS_TYPE.CORRECT:
      return <Correct />;
    case CHECK_ANSWER_STATUS_TYPE.INCORRECT:
      return <Incorrect />;
    default:
      return;
  }
};

export default RenderQuestionType;
