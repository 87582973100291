import React, { useState } from 'react'
import { FormulaGroup } from '../../../types/admin/formula'
import { InlineMath } from 'react-katex'

const SymbolGroupItem = ({handleSymbolSelect, symbolGroup}: {handleSymbolSelect: (symbol: string) => void, symbolGroup: FormulaGroup}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const expandRows = () => {
        setIsExpanded(true);
      };
    
      const collapseRows = () => {
        setIsExpanded(false);
      };
  return (
    <div
    className="relative inline-block mr-[10px] h-[40px] bg-primary mb-[10px]"
    onMouseEnter={expandRows}
    onMouseLeave={collapseRows}
  >
    {/* Single row of buttons */}
    <div
      className={`flex text-center w-full`}
    >
      {symbolGroup.MAIN_SYMBOLS.map((symbol, index) => (
        <button
          key={index}
          type="button"
          className="w-[45px] h-[45px] bg-primary hover:bg-primary-light text-white"
          onClick={() => handleSymbolSelect(symbol.value)}
        >
          <InlineMath math={symbol.label}/>
        </button>
      ))}
    </div>

    {/* Expanded menu */}
    {isExpanded && (
    <div className="absolute top-10 left-0 z-10 bg-primary">
      {symbolGroup.SUB_SYMBOLS?.map((subSymbol, index) => (
        <button
          key={index}
          type='button'
          className="w-[45px] h-[45px] bg-primary hover:bg-primary-light text-white inline-block"
          onClick={() => handleSymbolSelect(subSymbol.value)}
        >
          <InlineMath math={subSymbol.label} />
        </button>
      ))}
    </div>
  )}
  </div>
  )
}

export default SymbolGroupItem