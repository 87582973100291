import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
} from "react-icons/io";
import ChildrenStep from "../ChildrenStep";
import { CiClock2 } from "react-icons/ci";
import { useEffect, useState } from "react";
import { RegionType } from "../../../../../types/admin/examManagement";
import japanGeography from "../../../../../japanGeography.json";
import { useExaminationRegisterContext } from "../../../../../context/Candidate/ExaminationRegisterContext";
import CalendarDatePicker from "../../../../admin/Examination/Upsert/UpsertComponent/CalendarDatePicker";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { formatDateTime } from "../../../../../services/helpers/formatTime";
import {
  TYPE_DATE_FORMAT,
  WEEKDAYS_ORDER,
} from "../../../../../services/constants/globals";
import classNames from "classnames";
import Search from "../../../../commons/icons/Search";
import { makeRequest } from "../../../../../services/axios/axios";
import { API } from "../../../../../services/constants/route/api";
import { paramizeObject } from "../../../../../services/helpers/parseData";
import dayjs from "dayjs";
import {
  ExaminationVenueTimeType,
  ExaminationVenueType,
} from "../../../../../types/candidate/registerExamination";
import { ErrorData } from "../../../../../types/globals";
import ErrorBox from "../../../../commons/form/ErrorBox";

registerLocale("ja", ja);

const CBTComponent = () => {
  const {
    registerExaminationData,
    setRegisterExaminationData,
    examinationVenues,
    setExaminationVenues,
    filterSearchCBT,
    setFilterSearchCBT,
    setExaminationVenueSelected,
  } = useExaminationRegisterContext();
  const [regions] = useState<RegionType[]>(japanGeography.data);
  const [prefectures, setPrefectures] = useState<string[]>([]);
  const [date, setDate] = useState<Date | null>(new Date());
  const [showSelectName, setShowSelectName] = useState<string>("");
  const [errors, setErrors] = useState<ErrorData>({});
  const [examinationVenue, setExaminationVenue] =
    useState<ExaminationVenueType>({});
  const [isWaitingAPI, setIsWaitngAPI] = useState<boolean>(false);
  const [chooseDays, setChooseDays] = useState<
    {
      date: string;
      dayOfWeek: number;
    }[]
  >([]);

  const filterName = {
    region: "region",
    prefecture: "prefecture",
    startDate: "startDate",
    endDate: "endDate",
  };

  const handleChangeShowSelectName = (name: string) => {
    if (showSelectName === name) {
      setShowSelectName("");
      return;
    }

    setShowSelectName(name);
  };

  const handleChooseRegistrationDate = (date: Date | null, name: string) => {
    setFilterSearchCBT({
      ...filterSearchCBT,
      [name]: dayjs(date).format("YYYY-MM-DD"),
    });
    setShowSelectName("");
  };

  const handleRegionFilterChange = (value: string) => {
    if (filterSearchCBT.region === value) return;

    let filterSearchNew = { ...filterSearchCBT, region: value };
    let prefectures: string[] = [];

    if (value === "All") {
      filterSearchNew = { ...filterSearchNew, prefecture: value };
    } else {
      const selectedRegion = regions.find((region) => region.name === value);
      if (!selectedRegion) return;

      prefectures = selectedRegion.prefectures;
      filterSearchNew = { ...filterSearchNew, prefecture: "" };
    }

    setFilterSearchCBT(filterSearchNew);
    setPrefectures(prefectures);
  };

  const handleChangeFilterSearch = (value: string, name: string) => {
    setShowSelectName("");

    if (name === filterName.region) {
      handleRegionFilterChange(value);
      return;
    }

    setFilterSearchCBT({ ...filterSearchCBT, [name]: value });
  };

  const handleFilterSearch = async () => {
    setIsWaitngAPI(true);
    const result = await makeRequest({
      method: "get",
      url: `${
        API.REGISTER_EXAMINATION.FILTER_SERACH_GET_LIST_EXAM_VENUE
      }?${paramizeObject(filterSearchCBT)}&examination_id=${
        registerExaminationData.examination_id
      }`,
    });

    setIsWaitngAPI(false);
    if (!result.status) {
      setErrors(result.error || {});
      return;
    }

    setExaminationVenues(result.data);

    delete registerExaminationData.selected_date;
    delete registerExaminationData.exam_venue_id;
    setRegisterExaminationData(registerExaminationData);

    setChooseDays(
      getDaysWithWeekdayNumberBetweenDates(
        String(filterSearchCBT.start_date),
        String(filterSearchCBT.end_date)
      )
    );
    setErrors({});
  };

  const getDaysWithWeekdayNumberBetweenDates = (
    startDate: string,
    endDate: string
  ): { date: string; dayOfWeek: number }[] => {
    const datesArray: { date: string; dayOfWeek: number }[] = [];
    let currentDate = dayjs(startDate);

    while (currentDate.isBefore(dayjs(endDate))) {
      datesArray.push({
        date: currentDate.format("YYYY-MM-DD"),
        dayOfWeek: currentDate.day(),
      });
      currentDate = currentDate.add(1, "day");
    }

    datesArray.push({
      date: dayjs(endDate).format("YYYY-MM-DD"),
      dayOfWeek: dayjs(endDate).day(),
    });

    return datesArray;
  };

  const checkDayExist = (
    examinationVenueTimes: ExaminationVenueTimeType[],
    date: string
  ) => {
    const isExist = examinationVenueTimes.find((examinationVenueTime) => {
      return examinationVenueTime.date.includes(date);
    });

    return !!isExist;
  };

  const handleSelectExaminationVenueTime = (
    examinationVenueId: number,
    date: string
  ) => {
    setExaminationVenueSelected(examinationVenueId);

    if (
      dayjs(date).format("YYYY-MM-DD") !== registerExaminationData.selected_date
    ) {
      setRegisterExaminationData({
        ...registerExaminationData,
        exam_venue_id: examinationVenueId,
        selected_date: date,
        selected_time: "",
      });
    } else {
      setRegisterExaminationData({
        ...registerExaminationData,
        exam_venue_id: examinationVenueId,
        selected_date: date,
      });
    }

    const findExaminationVenue = examinationVenues.find(
      (examinationVenue) => examinationVenue.id === examinationVenueId
    );
    if (findExaminationVenue) {
      setExaminationVenue(findExaminationVenue);
    }
  };

  const isSelectedDate = (
    examinationVenueTimeId: number,
    date: string
  ): boolean => {
    return (
      examinationVenueTimeId === registerExaminationData.exam_venue_id &&
      date === registerExaminationData.selected_date
    );
  };

  const isSelectedTime = (
    examinationVenueTimeId: number,
    date: string,
    time: string
  ): boolean => {
    const result =
      examinationVenueTimeId === registerExaminationData.exam_venue_id &&
      time === registerExaminationData.selected_time &&
      dayjs(date).format("YYYY-MM-DD") ===
        registerExaminationData.selected_date;
    return result;
  };

  useEffect(() => {
    if (registerExaminationData.exam_venue_id) {
      const examinationVenue = examinationVenues.find(
        (examinationVenue) =>
          examinationVenue.id === registerExaminationData.exam_venue_id
      );

      if (examinationVenue) {
        setExaminationVenue(examinationVenue);
      }
    }

    if (filterSearchCBT.region) {
      const prefecturesFilter = regions.find(
        (region) => region.name === filterSearchCBT.region
      );

      if (prefecturesFilter) {
        setPrefectures(prefecturesFilter.prefectures);
      }
    }

    if (filterSearchCBT.start_date && filterSearchCBT.end_date) {
      setChooseDays(
        getDaysWithWeekdayNumberBetweenDates(
          String(filterSearchCBT.start_date),
          String(filterSearchCBT.end_date)
        )
      );
    }
  }, []);

  return (
    <div>
      <div className="h-full min-w-[155px] text-[black] flex justify-start items-center mb-[10px] mt-[20px] gap-[20px]">
        <div className="w-[30px] h-[30px] bg-primary-synch text-[22px] text-[white] flex justify-center items-center">
          2
        </div>
        日時・会場の選択
      </div>
      {errors && Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}
      <ChildrenStep step={1} title="エリア・日時設定">
        <div className="text-[14px] mb-[20px]">
          ご希望のエリアと日程をすべて選択し、「検索」ボタンを押してください
        </div>
        <div className="w-full flex justify-between items-start gap-[20px]">
          {/* Region */}
          <div className="w-[18%] flex flex-col gap-y-[10px]">
            <div
              className={`w-full h-[40px] flex items-center justify-between px-[10px] border border-[#ebe4d8] rounded-[5px] cursor-pointer text-[12px]`}
              onClick={() => handleChangeShowSelectName(filterName.region)}
            >
              {filterSearchCBT.region ? (
                <span>
                  {filterSearchCBT.region === "All"
                    ? "全て"
                    : filterSearchCBT.region}
                </span>
              ) : (
                <span className="text-secondary-light">選択してください</span>
              )}

              {showSelectName === filterName.region ? (
                <IoIosArrowDown
                  color={filterSearchCBT.region ? "#5A5A5A" : "#BEBEBE"}
                />
              ) : (
                <IoIosArrowUp
                  color={filterSearchCBT.region ? "#5A5A5A" : "#BEBEBE"}
                />
              )}
            </div>
            {showSelectName === filterName.region && (
              <div className="w-full border border-secondary-extralight shadow-md">
                <div
                  className="w-full h-[30px] px-[10px] py-[5px] hover:bg-secondary-lighter cursor-pointer text-[14px]"
                  onClick={() =>
                    handleChangeFilterSearch("All", filterName.region)
                  }
                >
                  全て
                </div>
                {regions.map((region, index) => {
                  return (
                    <div
                      className="w-full h-[30px] px-[10px] py-[5px] hover:bg-secondary-lighter cursor-pointer text-[14px]"
                      key={index}
                      onClick={() =>
                        handleChangeFilterSearch(region.name, filterName.region)
                      }
                    >
                      {region.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {/* Prefecture */}
          <div className="w-[18%] flex flex-col gap-y-[10px]">
            <div
              className={`w-full h-[40px] flex items-center justify-between px-[10px] border border-[#ebe4d8] rounded-[5px] cursor-pointer text-[12px]`}
              onClick={() => handleChangeShowSelectName(filterName.prefecture)}
            >
              {filterSearchCBT.prefecture ? (
                <span>
                  {filterSearchCBT.prefecture === "All"
                    ? "全て"
                    : filterSearchCBT.prefecture}
                </span>
              ) : (
                <span className="text-secondary-light">選択してください</span>
              )}

              {showSelectName === filterName.prefecture ? (
                <IoIosArrowDown
                  color={filterSearchCBT.prefecture ? "#5A5A5A" : "#BEBEBE"}
                />
              ) : (
                <IoIosArrowUp
                  color={filterSearchCBT.prefecture ? "#5A5A5A" : "#BEBEBE"}
                />
              )}
            </div>
            {showSelectName === filterName.prefecture && (
              <div className="w-full border border-secondary-extralight shadow-md">
                {filterSearchCBT.region === "All" ? (
                  <div className="w-full h-[30px] px-[10px] py-[5px] hover:bg-secondary-lighter cursor-pointer text-[14px]">
                    全て
                  </div>
                ) : (
                  <>
                    {prefectures.map((prefecture, index) => {
                      return (
                        <div
                          className="w-full h-[30px] px-[10px] py-[5px] hover:bg-secondary-lighter cursor-pointer text-[14px]"
                          key={index}
                          onClick={() =>
                            handleChangeFilterSearch(
                              prefecture,
                              filterName.prefecture
                            )
                          }
                        >
                          {prefecture}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </div>

          {/* Start date */}
          <div className="w-[18%] flex flex-col gap-y-[10px]">
            <div
              className={`w-full h-[40px] flex items-center justify-between px-[10px] border border-[#ebe4d8] rounded-[5px] cursor-pointer text-[12px]`}
              onClick={() => handleChangeShowSelectName(filterName.startDate)}
            >
              {filterSearchCBT?.start_date ? (
                <span>
                  {formatDateTime(
                    filterSearchCBT?.start_date,
                    TYPE_DATE_FORMAT.SHORT_DEFAULT
                  )}
                </span>
              ) : (
                <span className="text-secondary-light">選択してください</span>
              )}

              {showSelectName === filterName.startDate ? (
                <IoIosArrowDown
                  color={filterSearchCBT.start_date ? "#5A5A5A" : "#BEBEBE"}
                />
              ) : (
                <IoIosArrowUp
                  color={filterSearchCBT.start_date ? "#5A5A5A" : "#BEBEBE"}
                />
              )}
            </div>
            {showSelectName === filterName.startDate ? (
              <CalendarDatePicker
                name="start_date"
                date={date}
                setDate={setDate}
                value={String(filterSearchCBT?.start_date)}
                showTimeInput={false}
                handleChange={handleChooseRegistrationDate}
              />
            ) : (
              <></>
            )}
          </div>

          {/* End date */}
          <div className="w-[18%] flex flex-col gap-y-[10px]">
            <div
              className={`w-full h-[40px] flex items-center justify-between px-[10px] border border-[#ebe4d8] rounded-[5px] cursor-pointer text-[12px]`}
              onClick={() => handleChangeShowSelectName(filterName.endDate)}
            >
              {filterSearchCBT?.end_date ? (
                <span>
                  {formatDateTime(
                    filterSearchCBT?.end_date,
                    TYPE_DATE_FORMAT.SHORT_DEFAULT
                  )}
                </span>
              ) : (
                <span className="text-secondary-light">選択してください</span>
              )}
              {showSelectName === filterName.endDate ? (
                <IoIosArrowDown
                  color={filterSearchCBT.end_date ? "#5A5A5A" : "#BEBEBE"}
                />
              ) : (
                <IoIosArrowUp
                  color={filterSearchCBT.end_date ? "#5A5A5A" : "#BEBEBE"}
                />
              )}
            </div>
            {showSelectName === filterName.endDate ? (
              <CalendarDatePicker
                name="end_date"
                date={date}
                setDate={setDate}
                value={String(filterSearchCBT?.start_date)}
                showTimeInput={false}
                handleChange={handleChooseRegistrationDate}
              />
            ) : (
              <></>
            )}
          </div>

          <div className="flex justify-end items-center">
            <button
              className="w-[140px] h-[40px] flex justify-end items-center bg-primary-synch rounded-[5px] text-white"
              onClick={handleFilterSearch}
              disabled={isWaitingAPI}
            >
              <Search color="#ffffff" />
              <div className="w-[70%] flex justify-between items-center px-[15px]">
                {isWaitingAPI ? (
                  <div className="w-4 h-4 border-2 border-white border-t-2 border-t-transparent rounded-full animate-spin"></div>
                ) : (
                  "検索"
                )}
                <IoIosArrowForward />
              </div>
            </button>
          </div>
        </div>
      </ChildrenStep>

      {examinationVenues && examinationVenues.length ? (
        <ChildrenStep step={2} title="会場選択">
          <div className="flex flex-col gap-y-[20px] text-secondary-dark">
            <div>希望する会場欄の受験可能日を選択してください</div>
            <div>
              <ul>
                <li>
                  時間が表示されない場合はその日は空きがありませんので、日付を選び直してください
                </li>
                <li>予約は受験日の3日前までで、空席がある場合に可能です。</li>
                <li className="text-danger">
                  時間が表示された場合でも、選択した時間によっては受付できない場合がございますのでご了承ください。
                </li>
              </ul>
              <span>
                （その場合はお手数ではございますが、他の会場、または他の時間帯をお選びください）
              </span>
            </div>
            <div className="w-full h-auto flex items-start overflow-x-hidden">
              <div className="basis-[40%] w-full h-auto flex flex-col">
                <div className="h-[64px] px-[24px] py-[20px] text-left">
                  会場名（クリックで住所表示）
                </div>
                {examinationVenues.length &&
                  examinationVenues?.map((examinationVenueTime) => (
                    <div
                      className="max-lg:max-w-[200px] lg:max-w-[300px] xl:max-w-[350px] h-[50px] px-[24px] py-[20px] text-left truncate"
                      key={examinationVenueTime.id}
                    >
                      {examinationVenueTime.name}
                    </div>
                  ))}
              </div>
              <div
                className={classNames(
                  "flex-1 w-full flex flex-col overflow-hidden overflow-x-clip max-w-[610px] pb-[1px]",
                  { "overflow-x-scroll": chooseDays.length > 10 }
                )}
              >
                <div className="flex items-center w-full h-[64px] ">
                  {chooseDays.map((day, index) => (
                    <div
                      key={index}
                      className={classNames(
                        `text-[16px] w-[61px] h-[64px] flex flex-col justify-center box-border px-[30px] border-t`,
                        {
                          "!bg-[#56B4D933]": day.dayOfWeek === 6,
                          "!bg-[#FA646A33]": day.dayOfWeek === 0,
                        }
                      )}
                    >
                      <div className="flex justify-center items-center ">
                        {dayjs(day.date).format("MM/DD")}
                      </div>
                      <div className="flex justify-center items-center">
                        ({WEEKDAYS_ORDER[day.dayOfWeek]})
                      </div>
                    </div>
                  ))}
                </div>

                {examinationVenues.length ? (
                  examinationVenues.map((examinationVenueTime) => {
                    return (
                      <div
                        className="flex justify-start items-center w-full h-[50px]"
                        key={examinationVenueTime.id}
                      >
                        {chooseDays.map((day, index) => {
                          return (
                            <div
                              key={index}
                              className={classNames(
                                `min-h-[50px] text-[16px] min-w-[61px] flex flex-col justify-center`,
                                {
                                  "bg-danger": isSelectedDate(
                                    examinationVenueTime.id || 0,
                                    day.date
                                  ),
                                }
                              )}
                            >
                              <div className="flex justify-center items-center ">
                                {checkDayExist(
                                  examinationVenueTime?.examination_venue_times ||
                                    [],
                                  day.date
                                ) ? (
                                  <CiClock2
                                    size={24}
                                    className={classNames(
                                      `text-primary-synch scale-200 cursor-pointer`,
                                      {
                                        "text-white": isSelectedDate(
                                          examinationVenueTime.id || 0,
                                          day.date
                                        ),
                                      }
                                    )}
                                    onClick={() =>
                                      handleSelectExaminationVenueTime(
                                        examinationVenueTime.id || 0,
                                        day.date
                                      )
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </ChildrenStep>
      ) : (
        <p className="text-center">データがありません。</p>
      )}

      {registerExaminationData.selected_date &&
        registerExaminationData.exam_venue_id && (
          <ChildrenStep step={3} title="時間選択">
            <div className="w-full">
              <p className="text-[14px] mb-[20px]">{examinationVenue.name}</p>
              {examinationVenue.postal_code?.first &&
                examinationVenue.postal_code.last && (
                  <p className="text-[14px] ">
                    〒{examinationVenue?.postal_code?.first}-
                    {examinationVenue?.postal_code?.last}
                  </p>
                )}

              <p className="text-[14px] mb-[20px]">
                {examinationVenue.address?.prefecture},{" "}
                {examinationVenue.address?.city},{" "}
                {examinationVenue.address?.area},{" "}
                {examinationVenue?.postal_code?.first}-
                {examinationVenue?.postal_code?.last},{" "}
                {examinationVenue.address?.other}
              </p>
              <p className="text-[14px] "></p>
              <p className="text-[14px] mb-[20px]">
                {examinationVenue.exam_venue_info}
              </p>
              <p className="text-[14px]">
                受験日：{String(registerExaminationData.selected_date)}
              </p>
              <p className="text-[14px] mb-[20px]">
                受験可能な時間は下記のとおりです。
              </p>

              <p className="text-[14px] mb-[20px] px-[15px] py-[9px] ">
                ご希望の受験時間を選択してください
              </p>
              <div className="w-full max-w-full flex gap-x-[20px] overflow-auto">
                {examinationVenue &&
                  examinationVenue?.examination_venue_times?.length &&
                  examinationVenue?.examination_venue_times
                    ?.filter((examinationVenueTime) =>
                      examinationVenueTime.date.includes(
                        String(registerExaminationData.selected_date)
                      )
                    )
                    .map((examinationVenueTime) => {
                      const time = formatDateTime(examinationVenueTime.time, TYPE_DATE_FORMAT.TIME_HOUR_MINUTE_24H);
                      return (
                        <div
                          key={examinationVenueTime.id}
                          className={classNames(
                            "h-[50px] px-[50px] text-[14px] text-center py-[15px] bg-secondary-extralight text-[#777676] cursor-pointer",
                            {
                              "!bg-danger !text-white": isSelectedTime(
                                examinationVenue.id || 0,
                                examinationVenueTime.date,
                                time
                              ),
                            }
                          )}
                          onClick={() =>
                            setRegisterExaminationData({
                              ...registerExaminationData,
                              selected_time: time,
                            })
                          }
                        >
                          {/* {examinationVenueTime.time.slice(0, 5)} */}
                          {time}
                        </div>
                      );
                    })}
              </div>
            </div>
          </ChildrenStep>
        )}
    </div>
  );
};

export default CBTComponent;
