import { BiTrash } from "react-icons/bi";
import ArrowDown from "../../../../commons/icons/Arrow/ArrowDown";
import { FiFileText } from "react-icons/fi";
import { UPDATE_ORDER } from "../../../../../services/constants/admin/pages/exam";
import { ExamItemType } from "../../../../../types/admin/examAuthoring";

type ItemExamProps = {
  totalExamItem: number;
  itemExam: ExamItemType;
  maxOrder: number;
  isChooseSetting: boolean;
  handleDelete: () => void;
  handleUpdateOrderItem: (orderType: number) => void;
  handleChooseSettingProperty: () => void;
};

const ItemExam = ({
  totalExamItem,
  itemExam,
  maxOrder,
  isChooseSetting,
  handleDelete,
  handleUpdateOrderItem,
  handleChooseSettingProperty,
}: ItemExamProps) => {
  const { order, name, total_score } = itemExam;

  return (
    <div
      className={`${
        isChooseSetting ? "bg-primary-light" : "bg-white"
      } w-full flex items-center h-[50px] mt-[5px] gap-[3px] border-[#BEBEBE] border-[1px] rounded-[5px]`}
    >
      <div
        className="text-[13px] h-[30px] flex justify-start items-center basis-[60%] px-[10px] cursor-pointer"
        onClick={handleChooseSettingProperty}
      >
        <FiFileText
          className={`${
            isChooseSetting ? "text-white" : "text-primary"
          } ml-[0.4px] mr-[1.5px] text-[21px] shrink-0`}
        />
        <div
          className={`${
            isChooseSetting ? "text-white" : ""
          } text-[13px] px-[10px] line-clamp-1`}
        >
          {name || "テスト名テキストダミー"}
        </div>
      </div>
      <div className="text-[13px] h-[30px] flex justify-center items-center gap-x-[30px] basis-[25%] px-[10px] gap-[10px] ml-[-9px]">
        <div className="border-[1px] border-[#BEBEBE] h-[24px] w-[30px] bg-[white] text-[14px] flex justify-end items-center p-[5px] rounded-[2px]">
          {order}
        </div>
        <ArrowDown
          className={`rotate-180 ${order === 1 ? "" : "cursor-pointer"}`}
          color={
            order === 1
              ? "#BEBEBE"
              : "" || isChooseSetting
              ? "white"
              : "#7A7A7A"
          }
          onClick={() => {
            if ((order || 0) > 1) {
              handleUpdateOrderItem(UPDATE_ORDER.UP);
            }
          }}
        />
        <ArrowDown
          className={order === maxOrder ? "" : "cursor-pointer"}
          color={
            order === maxOrder
              ? "#BEBEBE"
              : "" || isChooseSetting
              ? "white"
              : "#7A7A7A"
          }
          onClick={() => {
            if ((order || 0) < totalExamItem) {
              handleUpdateOrderItem(UPDATE_ORDER.DOWN);
            }
          }}
        />
      </div>
      <div className="text-[13px] h-[30px] flex justify-center items-center basis-[15%] px-[10px]">
        <BiTrash
          size={24}
          color={isChooseSetting ? "white" : "#7A7A7A"}
          className={`cursor-pointer`}
          onClick={handleDelete}
        />
      </div>
      <div className="text-[16px] h-[30px] flex justify-center items-center basis-[15%] px-[10px]">
        {total_score ?? 0}
      </div>
    </div>
  );
};

export default ItemExam;
