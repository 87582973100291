import React from 'react';

const AuthoringErrorBox = ({ errors }: { errors: string[] }) => {
  if (!errors || !errors.length) return <></>;

  return (
    <div className="w-full bg-danger-lighter border border-danger rounded-[7px] py-[10px] px-[15px] my-[7px] text-danger">
      <ul>
        {errors.map((errorText, index) => (
          <li key={index} className="mb-[3px]">
            {errorText}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AuthoringErrorBox;