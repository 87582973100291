import React from "react";
const Exam = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5806 2.46967L20.5303 7.41942C20.8232 7.71231 20.8232 8.18718 20.5303 8.48008L12.0953 16.9151C11.7671 17.2433 11.322 17.4277 10.8579 17.4277L6.32234 17.4277C5.90813 17.4277 5.57234 17.0919 5.57234 16.6777L5.57234 12.1421C5.57234 11.678 5.75671 11.2329 6.0849 10.9047L14.5199 2.46967C14.8128 2.17678 15.2877 2.17678 15.5806 2.46967ZM17.1716 9.71751L18.9393 7.94975L15.0503 4.06066L13.2825 5.82843L17.1716 9.71751ZM16.1109 10.7782L12.2218 6.88909L7.14556 11.9654C7.09868 12.0122 7.07234 12.0758 7.07234 12.1421L7.07234 15.9277L10.8579 15.9277C10.9242 15.9277 10.9878 15.9013 11.0347 15.8544L16.1109 10.7782ZM3 20.25C2.58579 20.25 2.25 20.5858 2.25 21C2.25 21.4143 2.58579 21.75 3 21.75H21C21.4142 21.75 21.75 21.4143 21.75 21C21.75 20.5858 21.4142 20.25 21 20.25H3Z"
        fill={props.color || "#BEBEBE"}
      />
    </svg>
  );
};
export default Exam;
