import React, { useEffect, useState } from "react";
import { UNIT_FILE_TYPE } from "../../../services/constants/admin/pages/material";
import VideoPlayer from "./VideoPlayer";
import AudioPlayer from "./AudioPlayer";
import PDFViewer from "./PDFViewer";
import TextEditorDisplayWithPlainContent from "./TextEditorDisplayWithPlainContent";

type ResourceContentDisplayProps = {
  url?: string;
  resourceType?: number | null;
  downloadable?: number | boolean | null;
  textContent?: string | null;
  sub?: boolean;
  setResourceProgressTime?: React.Dispatch<React.SetStateAction<number>>;
};
const ResourceContentDisplay = ({
  url = "",
  resourceType,
  downloadable,
  textContent,
  sub,
  setResourceProgressTime
}: ResourceContentDisplayProps) => {
  const [blobUrl, setBlobUrl] = useState<string>(url);

  const handleContextMenu = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.preventDefault();
  };

  const handleOnLoad = () => {
    URL.revokeObjectURL(blobUrl);
  };

  useEffect(() => {
    if (!url) return;
    setBlobUrl(url);
    // const resourceLink = url || "";

    // fetch(resourceLink)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const blobUrl = URL.createObjectURL(blob);
    //     setBlobUrl(blobUrl);
    //   })
    //   .catch((error) => {});
  }, [url]);
  switch (resourceType) {
    case UNIT_FILE_TYPE.TYPE_EDITOR:
      // return <TextEditorDisplay htmlData={textContent || ""} className={sub ? "w-full h-[380px]" : "max-w-[500px] w-full max-h-[283px]"}/>;
      return <TextEditorDisplayWithPlainContent htmlData={textContent || ""} className={sub ? "w-full h-[380px]" : "max-w-[500px] w-full max-h-[283px]"}/>;
    case UNIT_FILE_TYPE.TYPE_VIDEO:
      return <VideoPlayer height={283} width={500} source={blobUrl} setResourceProgressTime={setResourceProgressTime}/>;
    case UNIT_FILE_TYPE.TYPE_AUDIO:
      return <AudioPlayer src={blobUrl} setResourceProgressTime={setResourceProgressTime}/>;
    case UNIT_FILE_TYPE.TYPE_PDF:
      return (
        <PDFViewer
          src={blobUrl}
          onLoad={handleOnLoad}
          downloadable={downloadable}
          className={sub ? "w-full h-[380px]" : "w-[500px] h-[283px]"}
        />
      );
    case UNIT_FILE_TYPE.TYPE_IMAGE:
      return (
        <img
          width={500}
          alt=""
          className={sub ? "w-full h-[380px]" : "max-h-[283px]"}
          src={blobUrl}
          onContextMenu={downloadable ? () => {} : handleContextMenu }
          onLoad={handleOnLoad}
        />
      );
    default:
      return <div className="w-full max-w-[500px] h-[283px]"></div>;
  }
};

export default ResourceContentDisplay;
