import { getTabObj } from "../../../helpers/parseData";
import { PATH_ADMIN_EXAM_PARTICIPATE } from "../../route/router";

const EXAM_PARTICIPATE_TAB_ITEM = {
  LIST_EXAM_PARTICIPATE: '受験一覧',
};

const EXAM_PARTICIPATE_TAB_DATA = [
  getTabObj(EXAM_PARTICIPATE_TAB_ITEM.LIST_EXAM_PARTICIPATE, PATH_ADMIN_EXAM_PARTICIPATE.LIST),
];


export { EXAM_PARTICIPATE_TAB_ITEM, EXAM_PARTICIPATE_TAB_DATA}
