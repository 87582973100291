import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import Sidebar from "../../../components/candidate/Profile/Sidebar";
import CandidateLayout from "../../../layouts/candidate";
import EditButton from "../../../components/candidate/Profile/EditButton";
import UserInformation from "../../../components/candidate/Profile/UserInformation";
import ProfileSetting from "../../../components/candidate/Profile/ProfileSetting";
import { User } from "../../../types/user";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import Swal from "sweetalert2";
import {
  swalError,
  swalMessage,
  swalSuccess,
} from "../../../services/helpers/swal";
import { Link, useNavigate } from "react-router-dom";
import { PATH_MY_PROFILE, ROUTE } from "../../../services/constants/route/router";
import { CiCamera } from "react-icons/ci";
import defaultLogo from "../../../assests/images/default_avatar.webp";
import {
  PREFIX_PATH_S3,
  SERVICE_ID,
} from "../../../services/constants/globals";
import { useAuthContext } from "../../../context/AuthContext";
import LayoutWaitingApi from "../../../components/commons/layouts/LayoutWaitingAPI";
import {
  deleteFile,
  uploadFileToS3,
} from "../../../services/utils/upload/s3Upload";
import ImageInternalButton from "../../../components/commons/buttons/ImageInternalButton";
import { StatusCodes } from "http-status-codes";

const Profile = () => {
  const [isEditUserInformation, setIsEditUserInformation] =
    useState<boolean>(false);
  const [isEditProfileSetting, setIsEditProfileSetting] =
    useState<boolean>(false);
  const [userData, setUserData] = useState<User>({} as any);
  const [avatarPath, setAvatarPath] = useState<string>(defaultLogo);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const [avatar, setAvatar] = useState<File>();
  const [avatarWatingDelete, setAvatarWatingDelete] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { setUser } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useAuthContext();
  const oz_id = user.organization_id;
  const navigate = useNavigate();
  
  useEffect(() => {
    const asyncFn = async () => {
      const userResult = await makeRequest({
        method: "get",
        url: API.USER.INFO,
      });

      if (userResult.code === StatusCodes.UNAUTHORIZED || userResult.code === StatusCodes.FORBIDDEN) {
        localStorage.clear();
        swalError(userResult.message);
        setTimeout(() => {
          navigate(ROUTE.LOGIN);
        }, 1500);
        return;
      }

      if (!userResult.data) {
        return swalMessage("error", "error", "error");
      }

      setUserData(userResult.data);
      setWaitingApi(false);
      if (userResult?.data?.avatar_url) {
        setAvatarPath(userResult?.data?.avatar_url);
      }
      if (userResult?.data?.avatar) {
        setAvatarWatingDelete(userResult?.data?.avatar);
      }
      Swal.close();
    };
    asyncFn();
  }, []);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;
    
    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024; // Size in MB
    const mimes = ["png", "jpg", "jpeg"];

    // Check file type
    if (!fileType.startsWith("image/")) {
      return swalError("無効なファイルタイプです。画像を選択してください。");
    }

    if (!mimes.includes(fileType.split("image/")[1])) {
      return swalError(
        "会社ロゴにはjpeg, png, jpgタイプのファイルを指定してください。"
      );
    }

    // Check file size
    if (fileSize > 2) {
      return swalError("ファイルサイズが2MBの最大制限を超えています。");
    }

    if (file) {
      const picturePath = URL.createObjectURL(file);
      setAvatarPath(picturePath);
      setAvatar(file);
    }
  };

  const handleUpdateAvatar = async () => {
    setIsLoading(true);
    let filePathUrl = "";
    let filePath = "";

    if (avatar) {
      if (avatarWatingDelete && avatarWatingDelete !== null) {
        await deleteFile(avatarWatingDelete);
      }
      const typeFile = avatar?.name.split(".").pop()?.toLowerCase();
      const nameFile = Date.now();
      filePath =
        PREFIX_PATH_S3.FEATURE +
        SERVICE_ID +
        "/avatar/" +
        PREFIX_PATH_S3.OZ +
        oz_id +
        "/" +
        nameFile +
        "." +
        typeFile;
      filePathUrl = `${process.env.REACT_APP_AWS_S3_URL}/${filePath}`;
      await uploadFileToS3(avatar, filePath);
    } else {
      if (avatarWatingDelete && avatarWatingDelete !== null) {
        await deleteFile(avatarWatingDelete);
      }
    }

    const dataUpdate = {
      ...userData,
      avatar_url: filePathUrl,
      avatar: filePath,
    };

    const result = await makeRequest({
      method: "patch",
      url: API.CANDIDATE.UPDATE_INFORMATION,
      data: dataUpdate,
    });

    setIsLoading(false);
    setUser(dataUpdate);
    setUserData(dataUpdate);
    setAvatar(undefined);
    if (!result.status) {
      await deleteFile(filePath);
      return swalError();
    }
    return swalSuccess();
  };

  const removeImage = () => {
    setUserData({ ...userData, avatar: undefined, avatar_url: undefined });
    setAvatarPath(defaultLogo);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <CandidateLayout sidebar={<Sidebar data={userData} />} cover>
      <div className="w-full text-secondary-dark bg-white p-[40px] rounded-[10px]">
        {/* title */}
        <div className="border-b h-full border-secondary-light pb-[14px] w-full mb-[60px] text-[14px] font-[500] leading-[100%]">
          アカウント情報変更｜会員情報
        </div>
        <LayoutWaitingApi className="w-full h-screen" waitingApi={waitingApi}>
          {/* gmail */}
          <div className="w-full mb-[50px]">
            <div className="font-[700] text-[16px] leading-[100%] mb-[18px]">
              登録メールアドレス
            </div>
            <div className="flex items-center h-[40px] border-b-[1px] border-success-extralight">
              <div className="w-full max-w-[220px] pl-[14px] py-[12px] bg-success-lighter font-[500] text-[14px] leading-[100%] h-full">
                メールアドレス
              </div>
              <div className="w-full flex justify-between pl-[13px]">
                <div className="mb-[-4px] text-[13px]">{userData.email}</div>
                <Link to={`${PATH_MY_PROFILE.MAIL_CHANGING}`}>
                  <EditButton label="メールアドレスの変更はこちら" />
                </Link>
              </div>
            </div>
          </div>
          {/* avatar */}
          <div className="w-full mb-[50px]">
            <div className="font-[700] text-[16px] leading-[100%] mb-[18px]">
              プロフィール写真
            </div>
            <p className="text-[12px] my-[18px]">
              「保存する」ボタンをクリックしてください。
            </p>
            <div className="flex flex-col gap-y-[13px] justify-center items-center">
              <div className="relative inline-block">
                <ImageInternalButton
                  removeImage={removeImage}
                  editImage={() => fileInputRef.current?.click()}
                  editButtonOtherStyle="!top-[5px] !right-[25px]"
                  removeButtonOtherStyle="!top-[5px] !right-[3px]"
                />
                <img
                  src={avatarPath}
                  alt=""
                  className="w-[150px] h-[150px] object-cover"
                />
                <input
                  ref={fileInputRef}
                  id="file-input"
                  type="file"
                  className="hidden"
                  onChange={handleImageChange}
                  accept={"image/*"}
                />
              </div>
              {isLoading ? (
                <span className="w-5 h-5 border-[3px] border-secondary border-t-transparent rounded-full animate-spin"></span>
              ) : (
                <EditButton
                  label="保存する"
                  onClick={handleUpdateAvatar}
                  showIcon={false}
                />
              )}
            </div>
          </div>

          {/* user information */}
          <div className="w-full">
            <div className="font-[700] text-[16px] leading-[100%] mb-[18px]">
              会員情報
            </div>
            <div className="flex gap-[10px] items-center justify-between w-full">
              <div className="text-[12px] leading-[190%]">
                変更箇所を入力し、「保存する」ボタンをクリックしてください。
              </div>
              {!isEditUserInformation && (
                <EditButton
                  label="お客様情報を編集する"
                  onClick={() => setIsEditUserInformation(true)}
                />
              )}
            </div>
            <UserInformation
              data={userData}
              isEdit={isEditUserInformation}
              setIsEdit={setIsEditUserInformation}
            />
          </div>

          {/* profile settings */}
          <div className="">
            <div className="font-[700] text-[16px] leading-[100%] mb-[18px]">
              プロフィール設定
            </div>
            <div className="flex gap-[10px] items-center justify-between w-full ">
              <div className="text-[12px] leading-[190%]">
                変更箇所を入力し、「保存する」ボタンをクリックしてください。
              </div>
              {!isEditProfileSetting && (
                <EditButton
                  label="プロフィールを編集する"
                  onClick={() => setIsEditProfileSetting(true)}
                />
              )}
            </div>
            <ProfileSetting
              data={userData}
              isEdit={isEditProfileSetting}
              setIsEdit={setIsEditProfileSetting}
            />
          </div>
        </LayoutWaitingApi>
      </div>
    </CandidateLayout>
  );
};

export default Profile;
