import { TbFolderOpen } from "react-icons/tb";
import { Question } from "../../../../types/admin/question";
import { PiFolderBold } from "react-icons/pi";
import { ChangeEvent, useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { isUsableArr } from "../../../../services/helpers/etc";
import { FiFileText } from "react-icons/fi";
import { useAuthoringExamContext } from "../../../../context/Exam/AuthoringExamContext";
import { ExamItemType } from "../../../../types/admin/exam";

type ListItemProps = {
  question: Question;
};

const QuestionItem = ({ question }: ListItemProps) => {
  const [isExpand, setIsExpand] = useState(false);
  const { questions, selectedItems, setSelectedItems } =
    useAuthoringExamContext();
  let childQuestions = questions.filter(
    (childQuestion) => Number(childQuestion.parent_id) === Number(question?.id)
  );

  const handleClick = () => {
    setIsExpand(!isExpand);
  };

  const handleSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = Number(target.value);
    if (target.checked) {
      setSelectedItems([...selectedItems, value]);
    }

    if (!target.checked) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    }
  };

  const isSelected = (questionId: ExamItemType["question_id"]) => {
    return selectedItems.includes(questionId || 0);
  };

  return (
    <div className="">
      <div
        className={`hover:bg-primary-extralight px-[5px] text-secondary-light text-[22px] flex gap-[5px] cursor-pointer items-center h-[30px] ${
          question.is_file ? "border-b border-secondary-extralight" : ""
        }`}
        onClick={handleClick}
      >
        {question.is_file ? (
          <></>
        ) : (
          <div className="text-[18px]">
            {isExpand ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </div>
        )}

        {question.is_file ? (
          <FiFileText className="ml-[0.4px] mr-[1.5px] text-[21px] text-primary shrink-0" />
        ) : (
          <div className="text-[22px]">
            {isExpand ? <TbFolderOpen /> : <PiFolderBold />}
          </div>
        )}
        <div className="w-full h-[30px] flex justify-between items-center text-[12px] text-secondary-dark font-[500] leading-[100%] whitespace-nowrap truncate">
          <div className="w-[170px] h-[30px] truncate  flex items-center">{question.name}</div>
          {question.is_file ? (
            <input
              className="input_check_red checked:ring-transparent focus:ring-transparent w-[20px] h-[20px] rounded-[4px] cursor-pointer"
              type="checkbox"
              value={question.id}
              onChange={handleSelected}
              checked={isSelected(question.id)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="pl-[20px]">
        {isExpand &&
          isUsableArr(childQuestions) &&
          childQuestions.map((question, index) => (
            <QuestionItem question={question} key={question.id || index} />
          ))}
      </div>
    </div>
  );
};

export default QuestionItem;
