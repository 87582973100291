import React from "react";

const LoadingCircle = ({ classNames }: { classNames?: string }) => {
  return (
    <div
      className={`w-[16px] h-[16px] border-[3px] border-secondary border-t-[3px] border-t-transparent animate-spin rounded-full ${classNames}`}
    ></div>
  );
};

export default LoadingCircle;
