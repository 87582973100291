import React from "react";
import { useListExamContext } from "../../../../context/Exam/ListExamContext";
import { Exam } from "../../../../types/admin/exam";
import { QuestionProblem } from "../../../../types/admin/question";
import { swalLoading } from "../../../../services/helpers/swal";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { decodeHTMLEntities } from "../../../../services/helpers/parseData";
import ModalEditQuestionType from "../Create/PropertiesSetting/PropertiesSettingComponent/ModalEditQuestionType";
import { isUsableArr } from "../../../../services/helpers/etc";
import { NAME_ACTION_DETAIL_EXAM } from "../../../../services/constants/admin/pages/exam";
import TestItemQuestionType from "../../../candidate/Examination/ExaminationTestComponent/TestItemQuestionType";

const ExamPreview = () => {
  const { selectedExam, setDetailActionName } = useListExamContext();
  const [exam, setExam] = React.useState<Exam>({});

  const handleUpdateExamItem = (examItem: QuestionProblem) => {};
  React.useEffect(() => {
    if (!selectedExam.is_file) {
      setDetailActionName(NAME_ACTION_DETAIL_EXAM.EDIT);
      return;
    }

    swalLoading();
    request.get(
      `${API.ADMIN_EXAM.DETAIL}?exam_id=${selectedExam.id}`,
      (data) => {
        setExam(data);
      }
    );
    document.getElementById("exam_preview")?.scrollTo(0, 0);
  }, [selectedExam]);

  return (
    <div className="">
      <div className="mb-[17px]">テストプレビュー</div>
      <div
        id="exam_preview"
        className="max-h-[700px] overflow-auto shadow-xl p-[20px] animate-[show_0.5s_ease-in-out]"
      >
        {isUsableArr(exam.exam_parts) ? (
          exam.exam_parts?.map((examPart) => {
            return (
              <div
                className="my-[10px] border-b-2 border-secondary-light"
                key={examPart.id}
              >
                <div className="text-[18px] font-[600]">{examPart.name}</div>
                <div className="ml-[10px]">
                  {examPart.exam_sections &&
                    examPart.exam_sections.length &&
                    examPart.exam_sections.map((examSection) => {
                      return (
                        <div className="my-[10px]" key={examSection.id}>
                          <div className="text-[16px] font-[500]">
                            {examSection.name}
                          </div>
                          <div>
                            {examSection.exam_items &&
                              examSection.exam_items.length &&
                              examSection.exam_items.map((examItem) => {
                                const questionData = examItem.question_data;

                                return (
                                  <div
                                    className="ml-[20px] py-[10px] border-t border-secondary-light"
                                    key={examItem.id}
                                  >
                                    <div className="text-[15px]">
                                      {examItem.name}
                                    </div>
                                    {questionData &&
                                      questionData.problems?.length &&
                                      questionData.problems.map((problem) => {
                                        return (
                                          <div
                                            className="py-[20px] pl-[20px]"
                                            key={problem.id}
                                          >
                                            <TestItemQuestionType
                                              key={examItem.id}
                                              questionProblem={problem}
                                              handleUpdateProblem={
                                                handleUpdateExamItem
                                              }
                                              isDisabled={true}
                                            />
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center text-[16px]">データがありません。</div>
        )}
      </div>
    </div>
  );
};

export default ExamPreview;
