import React from "react";

const BeigeBlock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="180"
      viewBox="0 0 180 180"
      fill="none"
    >
      <path opacity="0.8" d="M180 0H0V180H180V0Z" fill="#DBD5C2" />
    </svg>
  );
};

export default BeigeBlock;
