import React from "react";
import ExamParicipateBase from ".";
import ExamParticipateCandidate from "../../../components/admin/ExamParticipate/ListCandidate";
import ExamParticipateCandidateProvider from "../../../context/Admin/ExamParticipateCandidateContext ";

const ExamParticipateCandidatePage = () => {
  return (
    <ExamParticipateCandidateProvider>
      <ExamParicipateBase title="受験管理 ｜ 受験者一覧">
        <ExamParticipateCandidate />
      </ExamParicipateBase>
    </ExamParticipateCandidateProvider>
  );
};

export default ExamParticipateCandidatePage;
