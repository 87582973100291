import { subMinutes, format } from "date-fns";
import dayjs from "dayjs";
import { RegisterExaminationType } from "../../../types/candidate/registerExamination";
import { Dispatch } from "react";
import { EXAMINATION_TIME_STATUS, EXAMINATION_TIME_STATUS_NAME } from "../../constants/candidate/home";

const getTimeBeforeEntryTime = (
    inputTime: string,
    entryTime: number
): string => {
    const currentTime = new Date(`1970-01-01T${inputTime}`);
    const timeBefore30Minutes = subMinutes(currentTime, entryTime);

    return format(timeBefore30Minutes, "HH:mm");
};

const parseExamTimeToMinute = (examTime?: {
    hours?: number;
    minutes?: number;
    seconds?: number;
}): number => {
    if (!examTime) return 0;
    let minutes = 0;
    if (examTime.hours && examTime.hours > 0) {
        minutes += examTime.hours * 60;
    }

    if (examTime.minutes && examTime.minutes > 0) {
        minutes += examTime.minutes;
    }

    return minutes;
};

type ButtonTimeType = {
    time: string;
    status: number;
};

const formatRemainingTime = (
    remainingTime: number | null,
    registerExamination: RegisterExaminationType,
    setState: Dispatch<React.SetStateAction<ButtonTimeType>>
) => {
    const compareDate = dayjs(registerExamination.exam_full_date);

    if (remainingTime === null) {
        return;
    }
    
    const milisInSecond = 1000;
    const secondsInMinute = 60;
    const minutesInHour = 60;
    const hoursInDay = 24;

    const days = Math.floor(remainingTime / (hoursInDay * minutesInHour * secondsInMinute * milisInSecond));
    const hours = Math.floor(
        (remainingTime % (hoursInDay * minutesInHour * secondsInMinute * milisInSecond)) / (minutesInHour * secondsInMinute * milisInSecond)
    );
    const minutes = Math.floor(
        (remainingTime % (minutesInHour * secondsInMinute * milisInSecond)) / (secondsInMinute * milisInSecond)
    );
    const seconds = Math.floor((remainingTime % (secondsInMinute * milisInSecond)) / milisInSecond);

    let time = "";
    let status = EXAMINATION_TIME_STATUS.END;
    if (days < -1) {
        status = EXAMINATION_TIME_STATUS.END;
        time = EXAMINATION_TIME_STATUS_NAME.END
    } else if (days >= 1) {
        status = EXAMINATION_TIME_STATUS.REMAINING;
        time = `あと${days}日`;
    } else {
        const examinationTime = Number(registerExamination.time);
        const examinationEntryTime = registerExamination?.examination?.entry_time || 0;
        const remainingMinutes = examinationTime + compareDate.diff(dayjs(), "minutes") - examinationEntryTime;

        if (examinationTime === 0 && days === -1) {
            status = EXAMINATION_TIME_STATUS.START;
            time = EXAMINATION_TIME_STATUS_NAME.START;
        } else if (
            days === -1 &&
            remainingMinutes <= 0
        ) {
            status = EXAMINATION_TIME_STATUS.END;
            time = EXAMINATION_TIME_STATUS_NAME.END;
        } else if (
            days === -1 &&
            remainingMinutes > 0
        ) {
            status = EXAMINATION_TIME_STATUS.START;
            time = EXAMINATION_TIME_STATUS_NAME.START;
        } else if (
            examinationTime > 0 &&
            compareDate.diff(dayjs(), "minutes") - examinationEntryTime < 0 &&
            remainingMinutes > 0
        ) {
            status = EXAMINATION_TIME_STATUS.START;
            time = EXAMINATION_TIME_STATUS_NAME.START;
        } else if (minutes > 0 || seconds > 0) {
            status = EXAMINATION_TIME_STATUS.REMAINING;
            time = `あと${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes
                }:${seconds < 10 ? `0${seconds}` : seconds}`;
        }
    }

    setState({
        time: time,
        status: status,
    });
};

export { getTimeBeforeEntryTime, parseExamTimeToMinute, formatRemainingTime }