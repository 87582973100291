import { PATH_CANDIDATE_HOME, PATH_MY_PROFILE, ROUTE } from '../route/router';
import { SidebarChildItem, SidebarItem } from '../../../types/admin/main';
import Candidate from '../../../components/commons/icons/Sidebar/Candidate';
import Document from '../../../components/commons/icons/Sidebar/Document';
import Computer from '../../../components/commons/icons/Sidebar/Computer';
import ClockRewind from '../../../components/commons/icons/Sidebar/ClockRewind';

const child = (href: SidebarChildItem['href'], label: SidebarChildItem['label']) => ({
  href: href,
  label: label,
});

const item = (
  label: SidebarItem['label'],
  href: SidebarItem['href'],
  icon: SidebarItem['icon'],
  ...childs: SidebarItem['childs']
) => ({
  label: label,
  href: href,
  icon: icon,
  childs: childs.map(({ href, label }: SidebarChildItem) => child(href, label)),
});

const CANDIDATE_SIDEBAR_DATA = [
  item(
    'ホーム',
    PATH_CANDIDATE_HOME.DEFAULT,
    Computer,
  ),
  item(
    '受験申込',
    PATH_CANDIDATE_HOME.REGISTER_EXAMINATION,
    Document,
  ),
  // item(
  //   '試験履歴',
  //   '',
  //   ClockRewind,
  // ),
  item(
    'アカウント情報変更',
    '',
    Candidate,
    child(PATH_MY_PROFILE.DEFAULT, '会員情報'),
    child(PATH_MY_PROFILE.MAIL_CHANGING, 'メールアドレス変更'),
    child(PATH_MY_PROFILE.PASSWORD_CHANGING, 'パスワード変更'),
  ),
];

const PROFILE_SIDEBAR_DATA = [
  {
    label: '基本情報設定',
    child: [
      { href: `${ROUTE.PROFILE.DEFAULT}`, label: 'お客様情報' },
      {
        href: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.MAIL_CHANGING}`,
        label: 'メールアドレス変更',
      },
      {
        href: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.PASSWORD_CHANGING}`,
        label: 'パスワード変更',
      },
      // SidebarItem hidden for future phases
      // { href: '/#', label: '外部アカウント連携・解除' },
      // { href: '/#', label: 'コミュニティ一覧' },
      // { href: '/#', label: '目標設定' },
      // { href: '/#', label: '保有資格登録' },
      // { href: '/#', label: '受講修了証' },
      // { href: '/#', label: '購入履歴・領収書' },
      // { href: '/#', label: '通知設定' },
      // { href: '/#', label: '操作履歴' },
    ],
  },
  // SidebarItem hidden for future phases
  {
    label: '機能設定',
    child: [
      // { href: '/#', label: 'コミュニティ設定' },
      // { href: '/#', label: 'zoom設定' },
      // { href: '/#', label: '出席管理設定' },
      // { href: '/#', label: 'シラバス設定' },
      // { href: '/#', label: '講座購入設定' },
      // { href: '/#', label: '課題提出設定' },
      // { href: '/#', label: '課題評価設定' },
      // { href: '/#', label: '受信ボックス設定' },
    ],
  },
];

const PROFILE_SIDEBAR_DATA_MENU = [
  { href: `${ROUTE.PROFILE.DEFAULT}`, label: 'お客様情報' },
  {
    href: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.MAIL_CHANGING}`,
    label: 'メールアドレス変更',
  },
  {
    href: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.PASSWORD_CHANGING}`,
    label: 'パスワード変更',
  },
  // SidebarItem hidden for future phases
  { href: '/#', label: '外部アカウント連携・解除' },
  { href: '/#', label: 'コミュニティ一覧' },
  { href: '/#', label: '目標設定' },
  { href: '/#', label: '保有資格登録' },
  { href: '/#', label: '受講修了証' },
  { href: '/#', label: '購入履歴・領収書' },
  { href: '/#', label: '通知設定' },
  { href: '/#', label: '操作履歴' }
];

export { PROFILE_SIDEBAR_DATA, PROFILE_SIDEBAR_DATA_MENU, CANDIDATE_SIDEBAR_DATA };
