import ListExam from "./ListExam";
import SideAction from "./SideAction";

const SideManagement = () => {
  return (
    <aside className="">
      <ListExam />
      <SideAction />
    </aside>
  );
};

export default SideManagement;
