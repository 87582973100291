import { useEffect, useState } from "react";
import ItemExaminationRegisterInfo from "./RegisterExaminationComponent/ItemExaminationRegisterInfo";
import { useNavigate } from "react-router-dom";
import { PATH_CANDIDATE_HOME } from "../../../services/constants/route/router";
import { useStorage } from "../../../hooks/useStorage";
import { User } from "../../../types/user";
import {
  PAYMENT_TYPE,
  TYPE_DATE_FORMAT,
} from "../../../services/constants/globals";
import { EXAMINATION_FORMAT } from "../../../services/constants/admin/pages/examination";
import {
  decodeHTMLEntities,
  formatNumberWithCommas,
  paramizeObject,
} from "../../../services/helpers/parseData";
import { formatDateTime } from "../../../services/helpers/formatTime";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { Examination } from "../../../types/admin/examination";
import {
  ExaminationVenueType,
  RegisterExaminationType,
} from "../../../types/candidate/registerExamination";
import { isEmptyObj } from "../../../services/helpers/etc";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import {
  getTimeBeforeEntryTime,
  parseExamTimeToMinute,
} from "../../../services/utils/candidate/registerExamination";
import { PAYMENT_STATUS } from "../../../services/constants/candidate/payment";

const PrintPage = () => {
  const { getLocalStorage } = useStorage();
  const { searchParamsObject } = useObjectRoutes();
  const user: User = getLocalStorage("user");
  const navigate = useNavigate();
  const [examination, setExamination] = useState<Examination>({});
  const [examinationVenue, setExaminationVenue] =
    useState<ExaminationVenueType>({});
  const [registerExamination, setRegisterExamination] =
    useState<RegisterExaminationType>({});

  useEffect(() => {
    if (isEmptyObj(searchParamsObject)) return;

    const fetchRegisterExamination = async () => {
      await request.get(
        `${API.REGISTER_EXAMINATION.GET_DETAIL}?${paramizeObject(
          searchParamsObject
        )}`,
        (data: RegisterExaminationType) => {
          setTimeout(async () => {
            window.print();
          }, 500);
          setRegisterExamination(data);

          if (data.examination && !isEmptyObj(data.examination)) {
            setExamination(data.examination);
          }

          if (data.exam_venue && !isEmptyObj(data.exam_venue)) {
            setExaminationVenue(data.exam_venue);
          }
        }
      );
    };

    fetchRegisterExamination();
    window.onafterprint = function () {
      navigate(
        `${PATH_CANDIDATE_HOME.REGISTER_EXAMINATION}?${paramizeObject(
          searchParamsObject
        )}`
      );
    };
  }, []);

  return (
    <div>
      <p className="py-[20px] text-[16px] font-bold">予約内容</p>

      <div className="border-y-[1px] w-full border-[#BEBEBE] flex flex-col">
        <ItemExaminationRegisterInfo title="受験者名">
          <>
            {user.first_name}　{user.last_name}
          </>
        </ItemExaminationRegisterInfo>
        <ItemExaminationRegisterInfo title="メールアドレス">
          <>{user.email}</>
        </ItemExaminationRegisterInfo>
        <ItemExaminationRegisterInfo title="試験">
          <>{registerExamination.exam_name}</>
        </ItemExaminationRegisterInfo>
        <ItemExaminationRegisterInfo title="試験名">
          <>
            {examination.type}{" "}
            {Number(registerExamination.time) > 0 &&
              `(${registerExamination.time}分)`}
          </>
        </ItemExaminationRegisterInfo>
        <ItemExaminationRegisterInfo title="試験番号">
          <>
          {examination.code}
          </>
        </ItemExaminationRegisterInfo>
        <ItemExaminationRegisterInfo title="試験料金（税込）">
          <>
            {examination.is_free? "無料" : formatNumberWithCommas(Number(examination.price)) + "円"}            {registerExamination.payment_method_type === PAYMENT_TYPE.KONBINI &&
              (registerExamination.payment_status === PAYMENT_STATUS.UNPAID ||
                registerExamination.payment_status ===
                  PAYMENT_STATUS.WAITTING_PAID) &&
              "（決済待ち中）"}
          </>
        </ItemExaminationRegisterInfo>
        {registerExamination.exam_type_name === EXAMINATION_FORMAT.CBT ? (
          <ItemExaminationRegisterInfo title="会場">
            <div className="text-[16px]">
              <p>{examinationVenue.name}</p>
              <div className="py-[20px]">
                {examinationVenue.postal_code?.first &&
                  examinationVenue.postal_code?.last && (
                    <p>
                      〒{examinationVenue.postal_code.first}-
                      {examinationVenue.postal_code.last}
                    </p>
                  )}
                <p className="text-[14px] mb-[20px]">
                  {examinationVenue.address?.prefecture},{" "}
                  {examinationVenue.address?.city},{" "}
                  {examinationVenue.address?.area},{" "}
                  {examinationVenue.address?.other}
                </p>
              </div>
              <p>
                これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。
              </p>
            </div>
          </ItemExaminationRegisterInfo>
        ) : (
          <></>
        )}
        <ItemExaminationRegisterInfo title="試験日">
          <span className="text-[16px]">
            {formatDateTime(
              registerExamination?.exam_full_date || "",
              TYPE_DATE_FORMAT.SHORT_DEFAULT
            )}
          </span>
        </ItemExaminationRegisterInfo>
        {registerExamination.exam_format === EXAMINATION_FORMAT.CBT && (
          <ItemExaminationRegisterInfo title="入室時間">
            <span className="text-[16px]">
              {getTimeBeforeEntryTime(
                registerExamination.exam_time || "",
                examination.entry_time || 0
              )}{" "}
              ~ {registerExamination.exam_time?.slice(0, 5)}
            </span>
          </ItemExaminationRegisterInfo>
        )}
        <ItemExaminationRegisterInfo title="試験開始時間">
          <span className="text-[16px]">
            {registerExamination.exam_time?.slice(0, 5)}
          </span>
        </ItemExaminationRegisterInfo>
        {registerExamination.exam_format === EXAMINATION_FORMAT.CBT && (
          <ItemExaminationRegisterInfo title="地図">
            <div
              className="px-[6px] py-[20px] text-left basis-[60%] max-w-[500px]"
              dangerouslySetInnerHTML={{
                __html: decodeHTMLEntities(examinationVenue.map_embed),
              }}
            ></div>
          </ItemExaminationRegisterInfo>
        )}
      </div>
    </div>
  );
};

export default PrintPage;
