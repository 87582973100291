import FolderBase from '.';
import FolderList from '../../../components/admin/Folder/List/FolderList';

const FolderListPage = () => {
  return (
    <FolderBase title='ファイル管理 ｜ カテゴリ一覧'>
        <FolderList/>
    </FolderBase>
  );
};

export default FolderListPage;