import { ChangeEvent, useEffect, useState } from "react";
import { uploadFileToS3 } from "../../../../../../../services/utils/upload/s3Upload";
import { TestTakerRecordProblem } from "../../../../../../../types/admin/question";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import "../../../../../../../assests/styles/recordVoice.css";
import UploaderButton from "./TestTakerRecordVoiceComponent/UploaderButton";
import UploaderTimer from "./TestTakerRecordVoiceComponent/UploaderTimer";
import TestTakerRecord from "../../../../../../commons/icons/QuestionType/TestTakerRecord";
import { SetStateAction } from "../../../../../../../types/globals";

type TestTakerRecordVoiceUploaderProps = {
  problemData: TestTakerRecordProblem;
  actionable?: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const TestTakerRecordVoiceUploader = ({
  problemData,
  actionable = false,
  handleChange,
}: TestTakerRecordVoiceUploaderProps) => {
  const primaryDarkColor: string = "#235FA9";
  const dangerColor: string = "#FA646A";
  const primaryColor: string = "#75A1D3";
  const secondaryColor: string = "#B2B2B2";
  const [recording, setRecording] = useState(true);
  const [color, setColor] = useState<string>(dangerColor);
  const [pause, setPause] = useState<boolean>(true);
  const [isShowRecording, setIsShowRecording] = useState<boolean>(false);
  const recorderControls = useVoiceVisualizer();
  const { recordedBlob, error, audioRef, currentAudioTime, duration } =
    recorderControls;

  const startRecording = () => {
    recorderControls.startRecording();
    setIsShowRecording(true);
    setPause(false);
  };

  const stopRecording = () => {
    setColor(primaryDarkColor);
    setRecording(false);
    setPause(true);
    recorderControls.stopRecording();
  };

  const togglePauseResume = () => {
    setPause(!pause);
    recorderControls.togglePauseResume();
  };

  const resetRecord = () => {
    recorderControls.clearCanvas();
    setRecording(true);
    setIsShowRecording(false);
    setColor(dangerColor);
  };

  useEffect(() => {
    if (
      recordedBlob &&
      problemData.upload_folder &&
      problemData.upload_folder.path
    ) {
      const fileName = new Date().getTime() + ".mp3";
      const pathFile = problemData.upload_folder.path + fileName;
      uploadFileToS3(recordedBlob, pathFile);
    }
  }, [recordedBlob, error]);

  return (
    <>
      <div className="flex items-center justify-center gap-x-[30px]">
        <div className="text-[#235FA9] border border-[#235FA9] rounded-full w-[44px] h-[44px] flex justify-center items-center gap-x-[10px] shadow-[2px_2px_2px_0_rgba(0, 0, 0, 0.10)]">
          <TestTakerRecord
            className="cursor-pointer"
            onClick={startRecording}
          />
        </div>
        <div>
          <input
            type="number"
            name="point"
            className="!px-0 w-[34px] !h-[32px] text-center text-[12px] !rounded-none"
            onChange={handleChange}
            value={problemData?.point}
          />
        </div>
      </div>
      {isShowRecording && (
        <div className="w-full text-white border border-primary px-[30px] py-[15px] rounded-[10px] flex items-center justify-between gap-x-[40px]">
          <UploaderButton
            recording={recording}
            pause={pause}
            color={color}
            recorderControls={recorderControls}
            togglePauseResume={togglePauseResume}
            stopRecording={stopRecording}
            resetRecord={resetRecord}
          />
          <VoiceVisualizer
            ref={audioRef}
            controls={recorderControls}
            isControlPanelShown={false}
            width={363}
            height={103}
            barWidth={6}
            gap={3}
            speed={3}
            rounded={5}
            mainBarColor={primaryColor}
            secondaryBarColor={secondaryColor}
            progressIndicatorClassName="progress_indicator"
            canvasContainerClassName="canvas_container"
            isProgressIndicatorTimeShown={true}
          />
          <UploaderTimer
            recording={recording}
            pause={pause}
            problemData={problemData}
            recorderControls={recorderControls}
            stopRecording={stopRecording}
          />
        </div>
      )}
    </>
  );
};

export default TestTakerRecordVoiceUploader;
