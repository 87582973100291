import { ChangeEvent, useEffect, useState } from "react";
import ChildrenStep from "./RegisterExaminationComponent/ChildrenStep";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalClose, swalError } from "../../../services/helpers/swal";
import { ExamName } from "../../../types/admin/examName";
import { Examination } from "../../../types/admin/examination";
import { TAX, TYPE_DATE_FORMAT } from "../../../services/constants/globals";
import { formatDateTime } from "../../../services/helpers/formatTime";
import { EXAMINATION_FORMAT } from "../../../services/constants/admin/pages/examination";
import { useExaminationRegisterContext } from "../../../context/Candidate/ExaminationRegisterContext";
import { parseExamTimeToMinute } from "../../../services/utils/candidate/registerExamination";
import classNames from "classnames";
import { formatNumberWithCommas } from "../../../services/helpers/parseData";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const RegisterExaminationStep1 = () => {
  const {
    setFilterSearchCBT,
    registerExaminationData,
    setRegisterExaminationData,
    setExaminationType,
    setExaminationNameSelected,
    setExaminationSelected,
  } = useExaminationRegisterContext();

  const [listExamName, setListExamName] = useState<ExamName[]>([]);
  const [examinations, setExaminations] = useState<Examination[]>([]);
  const [organizationName, setOrganizationName] = useState<String>("");
  const [showExamName, setShowExamName] = useState<boolean>(false);

  const handleChooseExamName = (examNameId: number) => {
    delete registerExaminationData.examination_id;
    setRegisterExaminationData({
      ...registerExaminationData,
      exam_name_id: Number(examNameId),
    });

    const examName = listExamName.find(
      (examName) => examName.id === Number(examNameId)
    );
    if (!examName) return;

    setExaminationNameSelected(examName);
    setExaminations(examName?.examinations || []);
    setShowExamName(false);
  };

  const handleChooseExamination = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const examination = examinations.find(
      (examination) => examination.id === Number(value)
    );
    if (!examination) return;
    setExaminationSelected(examination);
    setRegisterExaminationData({
      ...registerExaminationData,
      examination_id: examination.id,
    });
    setExaminationType(examination?.format || "");
    setFilterSearchCBT({});
  };

  useEffect(() => {
    const getListExamName = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.CANDIDATE.GET_LIST_EXAM_NAME}`,
      });

      if (!result.data) return swalError();
      const response = result.data;
      const examNames: ExamName[] = response.exam_names || [];
      swalClose();
      setListExamName(examNames);
      setOrganizationName(response?.organization_name || "-");

      if (!registerExaminationData.exam_name_id) return;

      const findExamName = examNames?.find(
        (examName) => examName.id === registerExaminationData.exam_name_id
      );
      if (!findExamName || !findExamName.examinations) return;

      setExaminations(findExamName?.examinations);
    };

    const timer = setTimeout(() => {
      getListExamName();
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <div className="h-full min-w-[155px] text-[black] flex justify-start items-center mb-[10px] mt-[20px] gap-[20px]">
        <div className="w-[30px] h-[30px] bg-primary-synch text-[22px] text-[white] flex justify-center items-center">
          1
        </div>
        試験選択
      </div>
      <div>
        <ChildrenStep step={1} title="受験予約を行いたい試験を選択してください">
          <div
            className={classNames(
              "w-[350px] h-[40px] px-[15px] py-[7px] border border-secondary-light rounded-[5px] text-secondary-light cursor-pointer flex items-center justify-between",
              {
                "!text-secondary-dark": !!registerExaminationData.exam_name_id,
              }
            )}
            onClick={() => setShowExamName((showExamName) => !showExamName)}
          >
            <span>
              {registerExaminationData.exam_name_id
                ? listExamName.find(
                    (examName) =>
                      registerExaminationData.exam_name_id === examName.id
                  )?.name || "選択してください"
                : "選択してください"}
            </span>
            {showExamName ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </div>
          {showExamName ? (
            <div className="mt-[10px] max-h-[250px] w-[350px] border border-secondary-light rounded-[5px] overflow-y-auto">
              {listExamName.map((examName) => (
                <div
                  key={examName.id}
                  className={classNames(
                    "px-[15px] py-[7px] cursor-pointer hover:bg-secondary-extralight",
                    {
                      "bg-secondary-lighter":
                        examName.id === registerExaminationData.exam_name_id,
                    }
                  )}
                  onClick={() => handleChooseExamName(examName.id || 0)}
                >
                  {examName.name}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </ChildrenStep>
        {examinations && examinations.length ? (
          <ChildrenStep step={2} title="試験の種類を選択してください">
            <table className="w-full border-t-[1px] border-[#BEBEBE]">
              <thead className="text-[16px]">
                <tr className="font-[400]">
                  <th className="py-[20px] text-center font-[400]">選択</th>
                  <th className="font-[400]">試験番号</th>
                  <th className="text-left font-[400]">試験名</th>
                  <th className="font-[400]">実施会社名</th>
                  <th className="font-[400]">形式</th>
                  <th className="font-[400]">申し込み期限</th>
                  <th className="font-[400]">料金（税込）</th>
                </tr>
              </thead>
              <tbody>
                {examinations &&
                  examinations.length > 0 &&
                  examinations?.map((examination) => {
                    let isRegister: boolean = true;
                    let examinationDate: string = `${formatDateTime(
                      examination?.start_registration_time,
                      TYPE_DATE_FORMAT.FULL_TO_MINUTE
                    )} ~
                        ${formatDateTime(
                          examination?.end_registration_time,
                          TYPE_DATE_FORMAT.FULL_TO_MINUTE
                        )}`;
                    const currentDate = Date.now();
                    const startDate = new Date(
                      examination?.start_registration_time || ""
                    );
                    const endDate = new Date(
                      examination?.end_registration_time || ""
                    );

                    if (currentDate > endDate.getTime()) {
                      examinationDate = "終了";
                      isRegister = false;
                    }

                    if (currentDate < startDate.getTime()) {
                      isRegister = false;
                    }

                    return (
                      <tr
                        key={examination?.id}
                        className={classNames("h-[50px] py-[15px]", {
                          "bg-secondary-extralight":
                            examination.id ===
                            registerExaminationData.examination_id,
                        })}
                      >
                        <td className="text-center">
                          <input
                            onChange={handleChooseExamination}
                            type="radio"
                            name="examinationChoosen"
                            disabled={!isRegister}
                            value={examination?.id}
                            className={`${
                              !isRegister
                                ? "bg-[#BEBEBE] border-[#BEBEBE]"
                                : "checked:bg-primary focus:bg-primary cursor-pointer"
                            }`}
                            checked={
                              registerExaminationData.examination_id ===
                              examination.id
                            }
                          />
                        </td>
                        <td className="text-center">{examination?.code}</td>
                        <td className="text-left">
                          {examination?.type}{" "}
                          {!!parseExamTimeToMinute(examination?.exam_times)
                            ? `(${parseExamTimeToMinute(
                                examination?.exam_times
                              )}分)`
                            : ""}
                        </td>
                        <td className="text-center">{organizationName}</td>
                        <td className="text-center">
                          {examination?.format === EXAMINATION_FORMAT.IBT
                            ? "オンライン"
                            : "テストセンターで"}
                        </td>
                        <td className="text-center w-[20%]">
                          {examinationDate}
                        </td>
                        <td className="text-right px-[24px]">
                          {examination.is_free
                            ? "無料"
                            : formatNumberWithCommas(
                                Math.floor(Number(examination?.price) * TAX)
                              ) + "円"
                            }
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </ChildrenStep>
        ) : (
          <p className="text-center">データがありません。</p>
        )}
      </div>
    </div>
  );
};

export default RegisterExaminationStep1;
