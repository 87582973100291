import {
  ChoiceProblem,
  ChoiceIssue,
  OrderProblem,
  OrderIssue,
  MappingProblem,
  QuestionProblem,
  QuestionIssue,
  MappingIssue,
  SliderIssue,
  SliderProblem,
  MatrixProblem,
  MatrixIssue,
  EditingProblemStatus,
  FillInBlankProblem,
  FillInBlankIssue,
  WritingIssue,
  WritingProblem,
  TextBlockProblem,
  TextBlockIssue,
  MediaToolProblem,
  TestTakerUploadProblem,
  TestTakerRecordProblem,
} from '../../../types/admin/question';
import { UNIT_FILE_TYPE } from '../../constants/admin/pages/material';
import {
  NAME_LIST_STYLE_CHOICE_QUESTION,
  NAME_TYPE_QUESTION,
  RESTRICTED_TYPE_DATA,
  RESTRICTED_TYPE_NAME,
} from '../../constants/admin/pages/question';
import { MESSAGE_SPECIFIED } from '../../constants/message';
import { isUsableArr } from '../../helpers/etc';
import { sortArrayByKey } from '../../helpers/parseData';
import { swalError } from '../../helpers/swal';
import { getLocationOfThumb } from './sliderQuestion';

const initMultiProblemsByType = (typeName: string, count = 3): Array<ChoiceProblem> => {
  switch (typeName) {
    case NAME_TYPE_QUESTION.CHOICE:
      return new Array(count).fill(0).map((_, index) => initProblemByType(typeName, index));

    default:
      return [];
  }
};

const initMatrixCoordinate = (uniqueSignal = 0, x: number, y: number, defaultPoint = 0) => ({
  id: Number(`${uniqueSignal}${new Date().getTime()}`),
  x,
  y,
  checked: false,
  point: defaultPoint,
});

type QuestionIssueOption = {
  label?: string;
};

const initIssueByType = (
  typeName: string,
  counter = 0,
  point = 0,
  option: QuestionIssueOption = {},
): QuestionIssue => {
  const baseData: QuestionIssue = {
    id: Number(`${counter}${new Date().getTime()}`) || new Date().getTime(),
    label: !!option?.label ? option?.label : `解答 ${counter + 1}`,
    updated_at: new Date().getTime(),
  };

  switch (typeName) {
    case NAME_TYPE_QUESTION.CHOICE:
    case NAME_TYPE_QUESTION.ORDER:
      return {
        ...baseData,
        checked: false,
        point,
      } as ChoiceIssue | OrderIssue;

    case NAME_TYPE_QUESTION.MAPPING:
      return {
        ...baseData,
        appear_limit: 0, // as unlimited
      } as MappingIssue;

    case NAME_TYPE_QUESTION.SLIDER:
      return {
        ...baseData,
      } as SliderIssue;

    case NAME_TYPE_QUESTION.WRITTEN_QUESTION:
      return {
        ...baseData,
      } as WritingIssue;

    case NAME_TYPE_QUESTION.TEXT_BLOCK:
      return {
        ...baseData,
      } as TextBlockIssue;

    case NAME_TYPE_QUESTION.MATRIX:
      return {
        ...baseData,
      } as MatrixIssue;

    case NAME_TYPE_QUESTION.FILL_IN_BLANK:
      return {
        ...baseData,
        identifier: genFillInBlankIdentify(Number(baseData.id)),
        point,
      } as FillInBlankIssue;

    default:
      return {};
  }
};

const initProblemByType = (typeName: string, counter = 0, resourceId = 0): QuestionProblem => {
  const baseData: QuestionProblem = {
    id: Number(`${resourceId}${counter}${new Date().getTime()}`) || new Date().getTime(),
    name: typeName,
    updated_at: new Date().getTime(),
  };

  switch (typeName) {
    case NAME_TYPE_QUESTION.CHOICE:
      return {
        ...baseData,
        issues: new Array(3).fill(0).map((_, index) => initIssueByType(typeName, index)),
        title: `テキストダミー (${counter})`,
        is_shuffled: false,
        is_excluded: false,
        min_choice: 0,
        max_choice: 0,
        default_score: 0,
        is_vertical_arrange: false,
      } as ChoiceProblem;
    case NAME_TYPE_QUESTION.ORDER:
      return {
        ...baseData,
        issues: new Array(4).fill(0).map((_, index) => initIssueByType(typeName, index)),
        title: `テキストダミーテキストタイトル (${counter})`,
        default_score: 0,
        is_vertical_arrange: false,
        correct_issues: new Array(4).fill({}),
      } as OrderProblem;
    case NAME_TYPE_QUESTION.MAPPING:
      return {
        ...baseData,
        issues: new Array(4).fill(0).map((_, index) => initIssueByType(typeName, index)),
        title: `テキストダミーテキストタイトル (${counter})`,
        default_score: 0,
        is_shuffled: false,
        min_choice: 0,
        max_choice: 0,
        correct_pairs: [{}],
      } as MappingProblem;
    case NAME_TYPE_QUESTION.SLIDER:
      return {
        ...baseData,
        issues: new Array(1).fill(0).map((_, index) => initIssueByType(typeName, index)),
        title: `テキストダミーテキストタイトル (${counter + 1})`,
        default_score: 0,
        min: 0,
        max: 100,
        step: 1,
      } as SliderProblem;
    case NAME_TYPE_QUESTION.WRITTEN_QUESTION:
      return {
        ...baseData,
        title: `テキストダミーテキストタイトル (${counter + 1})`,
        answerValue: '',
        rowsAmount: 10,
        charactersAmount: 100,
      } as WritingProblem;
    case NAME_TYPE_QUESTION.TEXT_BLOCK:
      return {
        ...baseData,
        title: `テキストダミーテキストタイトル (${counter + 1})`,
        answerValue: '',
        rowsAmount: 10,
        charactersAmount: 100,
      } as TextBlockProblem;
    case NAME_TYPE_QUESTION.MATRIX:
      const issues = new Array(5).fill(0).map((_, index) => initIssueByType(typeName, index));
      let correctCoordinates: MatrixProblem['correct_coordinates'] = [];

      const issuesX = issues.slice(3, 5); // get 解答 4, 5
      const issuesY = issues.slice(0, 3); // get 解答 1, 2, 3

      issuesX.forEach((issueX, indexX) => {
        issuesY.forEach((issueY, indexY) => {
          correctCoordinates?.push({
            id: Number(`${indexX}${indexY}${new Date().getTime()}`),
            x: Number(issueX.id),
            y: Number(issueY.id),
            checked: false,
            point: 0,
          });
        });
      });

      return {
        ...baseData,
        issues,
        title: `テキストダミーテキストタイトル (${counter})`,
        default_score: 0,
        min_choice: 0,
        max_choice: 0,
        correct_coordinates: correctCoordinates,
      } as MatrixProblem;

    case NAME_TYPE_QUESTION.FILL_IN_BLANK:
      const fillInBlankIssues = new Array(1)
        .fill(0)
        .map((_, index) => initIssueByType(typeName, index));
      const fillInBlankContent = `<p>テキストダミーテキストタイトル <br>ダミーテキスト約250 文字。これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍や時によく使われます。ダミーテキストはダミー文書やダミー文章とも呼ばれることがあります。</p>`;

      return {
        ...baseData,
        issues: fillInBlankIssues,
        title: `テキストダミーテキストタイトル (${counter})`,
        default_score: 0,
        is_shuffled: false,
        content: fillInBlankContent,
        content_origin: fillInBlankContent,
        correct_fills: [],
      } as FillInBlankProblem;

    case NAME_TYPE_QUESTION.IMAGE:
    case NAME_TYPE_QUESTION.AUDIO:
    case NAME_TYPE_QUESTION.VIDEO:
      return {
        ...baseData,
        media_name: typeName,
      } as MediaToolProblem;

    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD:
      const recordBlankContent = `<p>テキストダミーテキストタイトル <br>ダミーテキスト約250 文字。これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍や時によく使われます。ダミーテキストはダミー文書やダミー文章とも呼ばれることがあります。</p>`;
      return {
        ...baseData,
        title: recordBlankContent,
        file_minute_size_limit: 1,
      } as TestTakerRecordProblem;
    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD:
      return {
        ...baseData,
        title: `設問内容 (${counter})`,
        is_restricted_file_type: false,
        restricted_file_types: getAllRestrictedTypeNames(),
        file_quantity_limit: 0,
        file_size_limit: 10,
      } as TestTakerUploadProblem;

    default:
      return {};
  }
};

const genFillInBlankIdentify = (uniqueSignal: number) => {
  return `$$$fill_in_blank_${uniqueSignal}$$$`;
};

const initFakeProblem = (typeName: string) => {
  return {
    id: Number(`${new Date().getTime()}`) || new Date().getTime(),
    name: typeName,
    is_fake: true,
  };
};

const generateChoiceIssues = (
  inputName: string,
  contentValue: string | boolean | number,
  originArr: ChoiceIssue[],
) => {
  if (!isUsableArr(originArr)) return [];

  const splitKeyword = 'issue_data_';
  const splittedNameData = inputName.split(splitKeyword);
  if (splittedNameData.length === 1) {
    return originArr;
  }

  const splittedPosition = splittedNameData[1].split('_');

  if (splittedPosition.length === 1) {
    return originArr;
  }
  const [field, arrIndex] = splittedPosition;
  const crIndex = Number(arrIndex);
  if (Number(arrIndex) > originArr.length - 1) return originArr;

  let newObject = originArr.find((_, index) => index === crIndex);
  newObject = { ...newObject, [field]: contentValue };

  let newArr = originArr.filter((_, index) => index !== crIndex);
  newArr = [...newArr, newObject];

  return sortArrayByKey(newArr);
};

const generateOrderIssues = (
  inputName: string,
  contentValue: string | boolean,
  originArr: OrderIssue[],
  isCheckAction = false,
) => {
  if (!isUsableArr(originArr)) return [];

  const splitKeyword = 'issue_data_';
  const splittedNameData = inputName.split(splitKeyword);
  if (splittedNameData.length === 1) {
    return originArr;
  }

  const splittedPosition = splittedNameData[1].split('_');
  if (splittedPosition.length === 1) {
    return originArr;
  }
  const [field, arrIndex] = splittedPosition;
  const crIndex = Number(arrIndex);
  if (Number(arrIndex) > originArr.length - 1) return originArr;

  let newObject = originArr.find((_, index) => index === crIndex);
  newObject = {
    ...newObject,
    ...(isCheckAction ? { checked_at: new Date().getTime() } : {}),
    [field]: contentValue,
  };

  let newArr = originArr.filter((_, index) => index !== crIndex);
  newArr = [...newArr, newObject];

  return sortArrayByKey(newArr);
};

const generateSliderIssues = (
  inputName: string,
  contentValue: string | boolean | number,
  originArr: SliderIssue[],
  min?: number,
  max?: number,
) => {
  let newObject = originArr.find((_, index) => index === 0);
  newObject = {
    ...newObject,
    [inputName]: contentValue,
  };
  let newArr = [newObject];
  return newArr;
};

const generateListStyleChoice = (styleType?: string) => {
  switch (styleType) {
    // list style
    case NAME_LIST_STYLE_CHOICE_QUESTION.LIST.ALPHA:
      return 'list-alpha listbox-dot';
    case NAME_LIST_STYLE_CHOICE_QUESTION.LIST.ALPHA_UPPERCASE:
      return 'list-upperalpha listbox-dot';
    case NAME_LIST_STYLE_CHOICE_QUESTION.LIST.NUMBER:
      return 'list-decimal listbox-dot';
    case NAME_LIST_STYLE_CHOICE_QUESTION.LIST.ROMAN_NUMBER:
      return 'list-roman listbox-dot';
    case NAME_LIST_STYLE_CHOICE_QUESTION.LIST.ROMAN_NUMBER_UPPERCASE:
      return 'list-upperroman listbox-dot';

    // box list style
    case NAME_LIST_STYLE_CHOICE_QUESTION.CHECKBOX:
      return 'list-none';
    case NAME_LIST_STYLE_CHOICE_QUESTION.DOT:
      return 'list-none listbox-dot';
    case NAME_LIST_STYLE_CHOICE_QUESTION.RADIO:
      return 'list-none listbox-radio';

    default:
      return 'list-none';
  }
};

const isListBoxStyle = (styleType?: string) => {
  if (!styleType) return true;

  const boxStyle = [
    NAME_LIST_STYLE_CHOICE_QUESTION.CHECKBOX,
    NAME_LIST_STYLE_CHOICE_QUESTION.DOT,
    NAME_LIST_STYLE_CHOICE_QUESTION.RADIO,
  ];

  return boxStyle.includes(styleType);
};

const checkLimitChoice = (checkedCount = 0, minAllowed = 0, maxAllowed = 0) => {
  let crErrors: string[] = [];

  if (
    (!!minAllowed && Number(checkedCount) < Number(minAllowed)) ||
    (!!maxAllowed && Number(checkedCount) > Number(maxAllowed))
  ) {
    crErrors.push(MESSAGE_SPECIFIED.QUESTION.LIMIT_CHOSEN_REACHED);
  }

  if (crErrors.length) {
    swalError();
    return {
      passed: false,
      errors: crErrors,
    };
  }

  return { passed: true, errors: [] };
};

// full filled is true, otherwise false
const checkMappingFilled = (mappingPairs: MappingProblem['correct_pairs']): boolean => {
  if (!mappingPairs || !mappingPairs.length) return true;

  for (const pair of mappingPairs) {
    if (!pair.right || !pair.left) return false;
  }

  return true;
};

// return false as duplicate, otherwise true
const checkMappingDuplicated = (mappingPairs: MappingProblem['correct_pairs']): boolean => {
  if (!mappingPairs || !mappingPairs.length) return true;

  for (const pair of mappingPairs) {
    if (!pair.right || !pair.left) continue;

    if (
      mappingPairs
        .filter((mappingPair) => mappingPair.id !== pair.id)
        .find(
          (mappingPair) =>
            (mappingPair.left === pair.left && mappingPair.right === pair.right) ||
            (mappingPair.left === pair.right && mappingPair.right === pair.left),
        )
    ) {
      return false;
    }
  }

  return true;
};

const clearEmptyMapping = (
  mappingPairs: MappingProblem['correct_pairs'],
): MappingProblem['correct_pairs'] => {
  if (!mappingPairs || !mappingPairs.length) return [];

  const newMappingPairs = mappingPairs.filter(
    (mappingPair) => mappingPair.left || mappingPair.right,
  );

  return newMappingPairs;
};

const reformatMapping = (
  mappingPairs: MappingProblem['correct_pairs'],
): MappingProblem['correct_pairs'] => {
  if (!mappingPairs || !mappingPairs.length) return [{}];
  const newMappingPairs = mappingPairs.filter(
    (mappingPair) => mappingPair.left || mappingPair.right,
  );

  if (!newMappingPairs || !newMappingPairs.length) return [{}];

  const lastMappingPair = newMappingPairs[newMappingPairs.length - 1];
  if (!lastMappingPair) return newMappingPairs;

  if (lastMappingPair.left && lastMappingPair.right) return [...newMappingPairs, {}];

  return newMappingPairs;
};

const getIssueById = (currentIssues: QuestionIssue[], issueId: number) =>
  currentIssues.find((issue) => issue.id === issueId);

const getIssueIndexById = (currentIssues: QuestionIssue[], issueId: number) =>
  currentIssues.findIndex((issue) => issue.id === issueId);

const displayStatusEditorHandle = (
  displayStatus: 'open' | 'hide',
  problemId: number,
  statuses: EditingProblemStatus[],
) => {
  const currentEditingProblemStatusIndex = statuses.findIndex((status) => status.id === problemId);
  const newEditingProblemStatus: EditingProblemStatus = {
    id: problemId,
    status: displayStatus === 'open',
  };

  statuses.splice(currentEditingProblemStatusIndex, 1, newEditingProblemStatus);

  return statuses;
};

const getSelectedText = () => {
  const selector = window?.getSelection();
  const text = selector?.toString() || '';
  const offset = selector?.anchorOffset || 0;

  return { text, offset, selector };
};

const extractHTMLTextToText = (htmlString: string) => {
  const span = document.createElement('span');
  span.innerHTML = htmlString;
  return span.textContent || span.innerText;
};

const extractIdByIdentifier = (identifier?: string) => {
  if (!identifier) return 0;

  const splitted = identifier.split('_');
  if (splitted.length !== 2) return 0;

  return Number(splitted[1]);
};

const getFileTypeRestricted = (questionTypeName: string) => {
  let fileTypeRestricted = 0;
  switch (questionTypeName) {
    case NAME_TYPE_QUESTION.IMAGE:
      fileTypeRestricted = UNIT_FILE_TYPE.TYPE_IMAGE;
      break;
    case NAME_TYPE_QUESTION.AUDIO:
      fileTypeRestricted = UNIT_FILE_TYPE.TYPE_AUDIO;
      break;
    case NAME_TYPE_QUESTION.VIDEO:
      fileTypeRestricted = UNIT_FILE_TYPE.TYPE_VIDEO;
      break;
    default:
      break;
  }
  return fileTypeRestricted;
};

const getAllRestrictedTypeNames = () => RESTRICTED_TYPE_DATA.map((type) => type.name);

const getAllowedFileType = (typeArr: TestTakerUploadProblem['restricted_file_types']) => {
  const newTypeArr = [];
  const newUploadFileInputTypeArr = [];

  // Document
  if (typeArr?.includes(RESTRICTED_TYPE_NAME.DOCUMENT)) {
    newTypeArr.push(
      '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    );
    newUploadFileInputTypeArr.push('word');
  }
  // Spread sheet
  if (typeArr?.includes(RESTRICTED_TYPE_NAME.SPREAD_SHEET)) {
    newTypeArr.push(
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    );
    newUploadFileInputTypeArr.push('excel', 'spreadsheet', 'sheet', 'csv');
  }
  // Pdf
  if (typeArr?.includes(RESTRICTED_TYPE_NAME.PDF)) {
    newTypeArr.push('.pdf');
    newUploadFileInputTypeArr.push('pdf');
  }
  // Movie
  if (typeArr?.includes(RESTRICTED_TYPE_NAME.MOVIE)) {
    newTypeArr.push('video/*');
    newUploadFileInputTypeArr.push('video');
  }
  // Image
  if (typeArr?.includes(RESTRICTED_TYPE_NAME.IMAGE)) {
    newTypeArr.push('image/*');
    newUploadFileInputTypeArr.push('image');
  }
  // Audio
  if (typeArr?.includes(RESTRICTED_TYPE_NAME.AUDIO)) {
    newTypeArr.push('audio/*');
    newUploadFileInputTypeArr.push('audio');
  }

  const acceptedFileTypeArr = typeArr || [];
  const acceptedFileTypeString = newTypeArr?.join(', ');
  const acceptedUploadFileInputTypeArr = newUploadFileInputTypeArr || [];

  return { acceptedFileTypeArr, acceptedUploadFileInputTypeArr, acceptedFileTypeString };
};

const isValidFileType = (currentType?: string, currentRestricted?: string[]) => {
  const { acceptedUploadFileInputTypeArr } = getAllowedFileType(currentRestricted);

  if (!acceptedUploadFileInputTypeArr.find((typeName) => currentType?.includes(typeName))) {
    return false;
  }

  return true;
};

const getFileExtensionFromName = (fileName: string) => {
  return fileName.split('.').pop()?.toLowerCase();
};

export {
  initMultiProblemsByType,
  initProblemByType,
  generateChoiceIssues,
  initIssueByType,
  generateListStyleChoice,
  isListBoxStyle,
  generateOrderIssues,
  initFakeProblem,
  checkLimitChoice,
  checkMappingFilled,
  checkMappingDuplicated,
  clearEmptyMapping,
  generateSliderIssues,
  reformatMapping,
  getIssueById,
  getIssueIndexById,
  initMatrixCoordinate,
  displayStatusEditorHandle,
  genFillInBlankIdentify,
  getSelectedText,
  extractHTMLTextToText,
  extractIdByIdentifier,
  getFileTypeRestricted,
  getAllRestrictedTypeNames,
  getAllowedFileType,
  isValidFileType,
  getFileExtensionFromName,
};
