import { TbFolderOpen } from "react-icons/tb";
import { Question } from "../../../../types/admin/question";
import { PiFolderBold } from "react-icons/pi";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { isUsableArr } from "../../../../services/helpers/etc";
import { useAuthoringExamContext } from "../../../../context/Exam/AuthoringExamContext";

type ListItemProps = {
  question: Question;
  handleChangeFolderQuestion: (question: Question) => void;
};

const QuestionFolder = ({
  question,
  handleChangeFolderQuestion,
}: ListItemProps) => {
  const [isExpand, setIsExpand] = useState(false);
  const { questions } = useAuthoringExamContext();
  const childQuestions = questions.filter(
    (childQuestion) =>
      Number(childQuestion.parent_id) === Number(question?.id) &&
      !childQuestion.is_file
  );

  const handleClick = () => {
    setIsExpand(!isExpand);
  };

  return (
    <div className="">
      <div
        className={`hover:bg-primary-extralight px-[5px] text-secondary-light text-[22px] flex gap-[5px] cursor-pointer items-center h-[30px] $`}
        onClick={handleClick}
      >
        <div className="text-[18px]">
          {isExpand ? <IoIosArrowDown /> : <IoIosArrowForward />}
        </div>
        <div className="text-[22px]">
          {isExpand ? <TbFolderOpen /> : <PiFolderBold />}
        </div>
        <div
          className="w-full h-[30px] flex justify-between items-center text-[12px] text-secondary-dark font-[500] leading-[100%] whitespace-nowrap truncate"
          onClick={() => handleChangeFolderQuestion(question)}
        >
          <div className="w-[170px] h-[30px] flex items-center truncate">
            {question.name}
          </div>
        </div>
      </div>
      <div className="pl-[40px]">
        {isExpand &&
          isUsableArr(childQuestions) &&
          childQuestions.map((question, index) => (
            <QuestionFolder
              question={question}
              key={question.id || index}
              handleChangeFolderQuestion={handleChangeFolderQuestion}
            />
          ))}
      </div>
    </div>
  );
};

export default QuestionFolder;
