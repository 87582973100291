import React from "react";
import { User } from "../../../../types/user";
import { USER_STATUS } from "../../../../services/constants/user";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoIosRemoveCircleOutline } from "react-icons/io";

type CandidateStatusButtonProps = {
  status: User["status"];
};

const CandidateStatusButton = ({ status }: CandidateStatusButtonProps) => {
  switch (status) {
    case USER_STATUS.ACTIVE:
      return (
        <div className="w-full max-w-[45px] flex items-center justify-center cursor-pointer">
          <div className="w-full text-[12px] font-[500] leading-[100%] text-danger bg-white border border-danger rounded-[5px] text-center pt-[3px] pb-[5px]">
            有効
          </div>
        </div>
      );
    case USER_STATUS.CONFIRMING:
      return (
        <div className="w-full max-w-[84px] flex items-center justify-center cursor-pointer">
          <div className="w-full text-[10px] font-[500] leading-[14px] text-danger bg-danger-lighter rounded-[5px] flex items-center justify-center gap-[5px] px-[7px] py-[2px]">
            <RiErrorWarningLine size={15} />
            確認中
          </div>
        </div>
      );
    default:
      return (
        <div className="w-full max-w-[84px] flex items-center justify-center cursor-pointer">
          <div className="w-full text-[10px] font-[500] leading-[14px] text-secondary bg-success-lighter rounded-[5px] flex items-center justify-center gap-[5px] px-[7px] py-[2px]">
            <IoIosRemoveCircleOutline size={15} />
            停止中
          </div>
        </div>
      );
  }
};

export default CandidateStatusButton;
