import { SETTING_PROPERTY_NAMES } from "../exam"

const SETTING_PROPERTY_MESSAGES = {
    TITLE: 'テストタイトル',
    RESULT_PROCESSING: "テストの結果処理について有無を選択します。",
    MAXIMUM_TIME: 'すべてのテストの最大時間',
    SUBMISSION_AFTER_DEADLINE: '受験者の回答で設定された制限時間を超えてもそれを許可する。',
}

const SETTING_PROPERTIES = {
    MAXIMUM_TIME: {
        TITLE: "最大時間",
        NAME: "max_times",
        SECOND_NAME: SETTING_PROPERTY_NAMES.MAX_SECOND,
        MINUTE_NAME: SETTING_PROPERTY_NAMES.MAX_MINUTE,
        HOUR_NAME: SETTING_PROPERTY_NAMES.MAX_HOUR,
    },
    SUBMISSION_AFTER_DEADLINE: {
        TITLE: "締切後の送信を許可",
        NAME: SETTING_PROPERTY_NAMES.IS_SUBMISSION_AFTER_DEADLINE,
    },
    RESULT_PROCESSING: {
        TITLE: "結果処理",
        NAME: SETTING_PROPERTY_NAMES.RESULT_PROCESSING
    },
}

export {SETTING_PROPERTY_MESSAGES, SETTING_PROPERTIES}