import React from 'react';

const ErrorBox = ({ errors }: { errors: string[] }) => {
   return (
      <div className="w-full bg-danger-lighter border border-danger rounded-[7px] py-[10px] px-[15px] my-[7px] text-danger">
         <ul>
            {errors.map((error, index) => (
               <li key={index} className="mb-[3px]">
                  {error}
               </li>
            ))}
         </ul>
      </div>
   );
};

export default ErrorBox;