import * as React from 'react';

const Order = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      style={{
        stroke: 'currentcolor',
      }}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
      d="M6 6l2-2m0 0L6 2m2 2H6a4 4 0 00-4 4m16 10l-2 2m0 0l2 2m-2-2h2a4 4 0 004-4M10.189 6.5a6 6 0 117.311 7.311M14 16a6 6 0 11-12 0 6 6 0 0112 0z"
    ></path>
  </svg>
);
export default Order;
