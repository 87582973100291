import dayjs from "dayjs";

const getDaysInMonth = (month: number, year: number) =>
  Array.from(
    { length: new Date(year, month, 0).getDate() },
    (_, i) => new Date(year, month - 1, i + 1)
  );

const getCommonDate = (date?: Date | string | null) =>
  date ? dayjs(date).format("YYYY-MM-DD") : "";

const getCommonDateSlash = (date?: Date | string | null) => date ? dayjs(date).format("YYYY/MM/DD") : "";
const getCommonDateTimeSlash = (date?: Date | string | null) => date ? dayjs(date).format("YYYY/MM/DD HH:mm") : "";

const isToday = (dateInput?: string) => {
  if (!dateInput || !dayjs(dateInput).isValid()) return false;

  return dayjs(dateInput).isToday();
};

const isValidDate = (date: Date | string | null): boolean => dayjs(date).isValid();

const getFirstCalendarDate = (date: Date | string): string => {
  // get first date in calendar
  if (!isValidDate(date)) return dayjs(new Date()).format();
  return dayjs(date).startOf("month").startOf("week").add(1, "days").format();
};

const getLastCalendarDate = (date: Date | string): string => {
  // get end date in calendar
  if (!isValidDate(date)) return dayjs(new Date()).format();
  return dayjs(date).add(6, "weeks").format();
};

const getDate = (date: Date | string): string => {
  // get end date in calendar
  if (!isValidDate(date)) return new Date(new Date()).toISOString();
  return new Date(date).toISOString();
};

export {
  getDaysInMonth,
  getCommonDate,
  isToday,
  isValidDate,
  getFirstCalendarDate,
  getLastCalendarDate,
  getDate,
  getCommonDateSlash,
  getCommonDateTimeSlash
};
