import ExamBase from ".";
import AuthoringExam from "../../../components/admin/Exam/Create";
import AuthoringExamProvider from "../../../context/Exam/AuthoringExamContext";

const AuthoringExamPage = () => {
  return (
    <ExamBase>
      <AuthoringExamProvider>
        <AuthoringExam />
      </AuthoringExamProvider>
    </ExamBase>
  );
};

export default AuthoringExamPage;
