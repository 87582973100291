import type { Object } from '../../types/globals';
import { BASIC_PASSPHRASE } from '../constants/globals';
import CryptoJS from 'crypto-js';
import { isEmptyObj } from './etc';

const sortArrayByKey = <T extends Object>(array: T[], key = 'id'): T[] => {
  if (!array) return [];
  const readPlicateArr = JSON.parse(JSON.stringify(array)) as T[];

  return readPlicateArr.sort((a, b) => {
    if (typeof a[key] === 'number' && typeof b[key] === 'number')
      return Number(a[key]) - Number(b[key]);

    const textA = String(a[key]).toUpperCase(); // ignore upper and lowercase
    const textB = String(b[key]).toUpperCase(); // ignore upper and lowercase
    if (textA < textB) {
      return -1;
    }
    if (textA > textB) {
      return 1;
    }

    //data must be equal
    return 0;
  });
};

/**
 * @deprecated since phase 1 - 2023/10/19
 */
const filterUniqueArray = <T extends Object>(array: Array<T>): Array<T> => {
  if (!array) return [];
  return array.filter(
    (course, index, a) => a.findIndex((_course) => _course.id === course.id) === index,
  );
};

const filterUniqueArrayByKey = <T extends Object>(array: Array<T>, key?: string): Array<T> => {
  if (!array) return [];
  const keyName = key || 'id';
  return array.filter(
    (object, index, a) =>
      a.findIndex((_object) => _object[`${keyName}`] === object[`${keyName}`]) === index,
  );
};

const paramizeObject = (object: Object) => new URLSearchParams(object);

const getTabObj = (label: string, href: string) => ({ label, href });

const getRemovedArrayItemByKey = <T extends Object>(
  array?: Array<T>,
  keyValue?: number | string | boolean,
  key: string | undefined = 'id',
): Array<T> => {
  if (!array) return [];
  return array.filter((item) => item[key] !== keyValue);
};

const bindingSortedArray = <T extends Object>(
  array: T[],
  callbackFn: (response: any) => any,
  key?: string,
) => {
  const uniqueArray = filterUniqueArray(array);
  callbackFn(sortArrayByKey(uniqueArray, key || 'order'));
};

const groupArrayByProperty = <T extends Object, K extends keyof T>(
  array: T[],
  property: K,
): Record<T[K], T[]> => {
  return array.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) acc[key] = [];

    acc[key].push(obj);
    return acc;
  }, {} as Record<T[K | string], T[]>);
};

const getPercent = (number?: number, total?: number): number => {
  if (!number || !total) return 0;
  if (total < number) return 100;
  return (number / total) * 100;
};

const parsePercent = (percent?: number | string): number => {
  if (!percent || !Number(percent)) return 0;
  if (Number(percent) > 100) return 100;
  return Number(percent);
};

const extractDateStringIntoObject = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return {
    year: year,
    month: month,
    day: day,
  };
};

const decodeHTMLEntities = (text?: string) => {
  if (!text) return '';
  const entities = [
    ['amp', '&'],
    ['apos', "'"],
    ['#x27', "'"],
    ['#x2F', '/'],
    ['#39', "'"],
    ['#47', '/'],
    ['lt', '<'],
    ['gt', '>'],
    ['nbsp', ' '],
    ['quot', '"'],
  ];

  for (let i = 0, max = entities.length; i < max; ++i)
    text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

  return text;
};

const encryptWithAES = (text?: string, passphrase?: string) => {
  if (!text) return '';
  const crPassphrase = passphrase || BASIC_PASSPHRASE;

  return CryptoJS.AES.encrypt(text, crPassphrase).toString();
};

const decryptWithAES = (ciphertext?: string, passphrase?: string) => {
  if (!ciphertext) return '';

  const crPassphrase = passphrase || BASIC_PASSPHRASE;
  const bytes = CryptoJS.AES.decrypt(ciphertext, crPassphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const getArrayByObject = (obj: Object) => {
  if (!obj || isEmptyObj(obj)) return [];

  const res = [];
  for (const key of Object.keys(obj || {})) {
    if (!obj || !obj[key]) continue;
    res.push(obj[key]);
  }

  return res;
};

const truncate = (input?: string, truncatedLimit = 10) => {
  if (!input || typeof input !== 'string') return '';

  if (input.length > truncatedLimit) {
    return `${input.substring(0, truncatedLimit)}...`;
  }
  return input;
};

const shuffle = <AType>(array: AType[]): AType[] => {
  if (!array || !array.length) return [];

  const readPlicateArr = JSON.parse(JSON.stringify(array)) as AType[];

  let currentIndex = readPlicateArr.length,
    randomIndex: number;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [readPlicateArr[currentIndex], readPlicateArr[randomIndex]] = [
      readPlicateArr[randomIndex],
      readPlicateArr[currentIndex],
    ];
  }

  return readPlicateArr;
};

function formatNumberWithCommas(value: number): string {
  const formatter = new Intl.NumberFormat('ja-JP');
  return formatter.format(value);
}

export {
  sortArrayByKey,
  paramizeObject,
  getTabObj,
  filterUniqueArray,
  getRemovedArrayItemByKey,
  bindingSortedArray,
  groupArrayByProperty,
  getPercent,
  parsePercent,
  extractDateStringIntoObject,
  filterUniqueArrayByKey,
  decodeHTMLEntities,
  encryptWithAES,
  decryptWithAES,
  getArrayByObject,
  truncate,
  shuffle,
  formatNumberWithCommas
};
