import { INDEX_STEP_REGISTER_EXAMINATIONS } from "../../../../services/constants/candidate/registerExamination";
import { useExaminationRegisterContext } from "../../../../context/Candidate/ExaminationRegisterContext";
import { EXAMINATION_FORMAT } from "../../../../services/constants/admin/pages/examination";
import { TITLE_STEP_REGISTER_EXAMINATIONS } from "../../../../services/constants/candidate/registerExamination";

const StepRange = ({ stepCurrent }: { stepCurrent: number }) => {
  const { examinationType } = useExaminationRegisterContext();
  return (
    <div className="w-full h-[70px] flex items-center mb-[40px]">
      <div className="bg-primary-synch h-full w-[120px] text-[white] flex justify-center items-center">
        STEP
      </div>
      <div className="w-full h-[70px] bg-white flex items-center">
        <div className="flex-1 h-[70px]">
          <div className="h-full flex justify-end items-center">
            <div className="flex basis-[70%] items-center justify-center gap-x-[20px]">
              <span
                className={`${
                  stepCurrent === INDEX_STEP_REGISTER_EXAMINATIONS.STEP_1
                    ? "bg-primary-synch"
                    : "bg-[#BEBEBE]"
                } text-white w-[30px] h-[30px] text-[22px] flex justify-center items-center`}
              >
                {INDEX_STEP_REGISTER_EXAMINATIONS.STEP_1}
              </span>
              <span
                className={`${
                  stepCurrent === INDEX_STEP_REGISTER_EXAMINATIONS.STEP_1
                    ? ""
                    : "text-[#BEBEBE]"
                }`}
              >
                試験選択
              </span>
            </div>
            <div className="w-[50px] h-[50px] border-[1px] border-[#E0E0E0] rotate-45 border-l-0 border-b-0"></div>
          </div>
        </div>
        <div className="flex-1 h-[70px]">
          <div className="h-full flex justify-end items-center">
            <div className="flex basis-[70%] items-center justify-center gap-x-[20px]">
              <span
                className={`${
                  stepCurrent === INDEX_STEP_REGISTER_EXAMINATIONS.STEP_2
                    ? "bg-primary-synch"
                    : "bg-[#BEBEBE]"
                } text-white w-[30px] h-[30px] text-[22px] flex justify-center items-center`}
              >
                {INDEX_STEP_REGISTER_EXAMINATIONS.STEP_2}
              </span>
              <span className={`${stepCurrent === 2 ? "" : "text-[#BEBEBE]"}`}>
                {examinationType === EXAMINATION_FORMAT.IBT ? TITLE_STEP_REGISTER_EXAMINATIONS.STEP2_IBT : TITLE_STEP_REGISTER_EXAMINATIONS.STEP2_CBT}
              </span>
            </div>
            <div className="w-[50px] h-[50px] border-[1px] border-[#E0E0E0] rotate-45 border-l-0 border-b-0"></div>
          </div>
        </div>
        <div className="flex-1 h-[70px]">
          <div className="h-full flex justify-end items-center">
            <div className="flex basis-[70%] items-center justify-center gap-x-[20px]">
              <span
                className={`${
                  stepCurrent === INDEX_STEP_REGISTER_EXAMINATIONS.STEP_3
                    ? "bg-primary-synch"
                    : "bg-[#BEBEBE]"
                } text-white w-[30px] h-[30px] text-[22px] flex justify-center items-center`}
              >
                {INDEX_STEP_REGISTER_EXAMINATIONS.STEP_3}
              </span>
              <span
                className={`${
                  stepCurrent === INDEX_STEP_REGISTER_EXAMINATIONS.STEP_3
                    ? ""
                    : "text-[#BEBEBE]"
                }`}
              >
                予約確認・お支払い
              </span>
            </div>
            <div className="w-[50px] h-[50px] border-[1px] border-[#E0E0E0] rotate-45 border-l-0 border-b-0"></div>
          </div>
        </div>
        <div className="flex-1 h-[70px]">
          <div className="w-full h-full flex justify-center items-center">
            <div className="flex items-center justify-center gap-x-[20px]">
              <span
                className={`${
                  stepCurrent === INDEX_STEP_REGISTER_EXAMINATIONS.STEP_4
                    ? "bg-primary-synch"
                    : "bg-[#BEBEBE]"
                } text-white w-[30px] h-[30px] text-[22px] flex justify-center items-center`}
              >
                {INDEX_STEP_REGISTER_EXAMINATIONS.STEP_4}
              </span>
              <span
                className={`${
                  stepCurrent === INDEX_STEP_REGISTER_EXAMINATIONS.STEP_4
                    ? ""
                    : "text-[#BEBEBE]"
                }`}
              >
                予約完了
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepRange;
