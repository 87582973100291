/* eslint-disable react-hooks/exhaustive-deps */
import QuestionItem from "./QuestionItem";
import { useQuestionListContext } from "../../../../context/Question/ListQuestionContext";
import { useEffect, useState } from "react";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import LayoutWaitingApi from "../../../commons/layouts/LayoutWaitingAPI";

const ListQuestion = () => {
  const {
    questions,
    setQuestions,
    selectedQuestion,
    setSelectedQuestion,
    setSideAction,
  } = useQuestionListContext();
  const primaryQuestions = questions.filter((question) => !question.parent_id);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);

  useEffect(() => {
    if (questions.length > 0) return;
    const fetchQuestions = async () =>
      await request.get(API.ADMIN_QUESTION.LIST, (data) => {
        setQuestions(data);
        setWaitingApi(false);
      });
    fetchQuestions();
  }, [questions.length]);

  const unselectHandle = () => {
    setSelectedQuestion();
    setSideAction("");
  };

  return (
    <>
      {selectedQuestion && selectedQuestion.id ? (
        <div className="flex justify-between text-[12px] bg-primary-extralight px-[10px] py-[3px] rounded-t-[5px]">
          <div className="text-secondary">選択中</div>
          <div
            className="text-blue-400 cursor-pointer"
            onClick={unselectHandle}
          >
            選択を外す
          </div>
        </div>
      ) : (
        <></>
      )}
      <LayoutWaitingApi waitingApi={waitingApi} className="w-[265px] h-[400px]">
        <div
          className={`pl-[10px] pt-[5px] w-[265px] bg-white h-[400px] overflow-auto mb-[10px] rounded-[2px] animate-[show_0.5s_ease-in-out]`}
        >
          {primaryQuestions.map((question, index) => (
            <QuestionItem question={question} key={index} />
          ))}
        </div>
      </LayoutWaitingApi>
    </>
  );
};

export default ListQuestion;
