import { useEffect, useState } from "react";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { Link, useNavigate } from "react-router-dom";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import {
  PATH_CANDIDATE_HOME,
  PATH_EXAMINATION,
} from "../../../services/constants/route/router";
import LayoutWaitingApi from "../../commons/layouts/LayoutWaitingAPI";
import { useExaminationTestContext } from "../../../context/ExaminationTestContext";
import classNames from "classnames";
import { RegisterExaminationType } from "../../../types/candidate/registerExamination";
import {
  CandidateExamHistoryBase,
  PartialCandidateExamHistory,
} from "../../../types/candidate/candidateExamHistory";
import dayjs from "dayjs";
import {
  EXAMINATION_STATUS,
  STATUS_EXAM_TEST_ITEM,
} from "../../../services/constants/candidate/examinationTest";
import { swalMessage, swalSuccess } from "../../../services/helpers/swal";
import {
  EXAMINATION_TIME_STATUS,
  EXAMINATION_TIME_STATUS_NAME,
} from "../../../services/constants/candidate/home";
import { ButtonTimeType } from "../../../types/globals";
import { formatRemainingTime } from "../../../services/utils/candidate/registerExamination";

type ExaminationTestType = {
  register_examination: RegisterExaminationType;
  candidate_exam_parts: CandidateExamHistoryBase[];
  status: number;
};

const TestPartList = () => {
  const { getParamValue } = useObjectRoutes();
  const navigate = useNavigate();
  const registerExaminationId = getParamValue("id");
  const [isWaitingAPI, setIsWaitingAPI] = useState<boolean>(true);
  const {
    setCandidateExamParts,
    candidateExamParts,
    setExamPartSelected,
    setRegisterExamination,
    registerExamination,
    examinationStatus,
    setExaminationStatus,
  } = useExaminationTestContext();
  const [buttonTime, setButtonTime] = useState<ButtonTimeType>({
    status: EXAMINATION_TIME_STATUS.END,
    time: "",
  });

  const handleClickExamSelected = (
    candidateExamPartId: PartialCandidateExamHistory["exam_type_id"]
  ) => {
    const indexCandidateExamPart = candidateExamParts.findIndex(
      (candidateExamPart) =>
        candidateExamPart.exam_type_id === candidateExamPartId
    );

    if (indexCandidateExamPart === -1) return;

    if (indexCandidateExamPart === 0 && !handleCheckTimeJoinExamPart()) return;

    if (candidateExamParts && candidateExamParts.length) {
      const findCandidateExamPart = candidateExamParts.find(
        (candidateExamPart) =>
          candidateExamPart.exam_type_id === candidateExamPartId
      );
      if (!findCandidateExamPart) return;

      navigate(
        `${PATH_EXAMINATION.EXAMINATION_TEST_PART}?register_examination_id=${registerExaminationId}&test_part_id=${candidateExamPartId}`
      );
    }
  };

  const handleCheckTimeJoinExamPart = () => {
    return dayjs(registerExamination.exam_date).isBefore(dayjs(), "seconds");
  };

  useEffect(() => {
    if (!registerExaminationId) {
      navigate("/404");
      return;
    }

    setIsWaitingAPI(true);
    setExamPartSelected({});
    const fetch = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.CANDIDATE_EXAM_HISTORY.GET_CANDIDATE_EXAM_PART_LIST}?register_examination_id=${registerExaminationId}`,
      });

      setIsWaitingAPI(false);
      if (!result.status) {
        return navigate("/404");
      }

      const data: ExaminationTestType = result.data;
      let message = "";
      if (data.status === 1) {
        message = "おめでとうございます。テストが完了しました！";
      }

      if (data.status === 4) {
        message = "試験は終了しました。";
      }
      setExaminationStatus({
        status: data.status,
        message: message,
      });
      setRegisterExamination(data.register_examination);
      setCandidateExamParts(data.candidate_exam_parts);
    };

    fetch();
  }, []);

  useEffect(() => {
    if (registerExamination.exam_date !== undefined) {
      let exam_date = dayjs(registerExamination.exam_date);
      if (registerExamination.exam_status === STATUS_EXAM_TEST_ITEM.DONE) {
        setButtonTime({
          status: EXAMINATION_TIME_STATUS.END,
          time: EXAMINATION_TIME_STATUS_NAME.END,
        });
      } else {
        const intervalId = setInterval(() => {
          const now = dayjs();
          const difference = exam_date.diff(now, "millisecond");

          formatRemainingTime(difference, registerExamination, setButtonTime);
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
      }
    }
  }, [registerExamination.exam_date]);

  return (
    <div className="w-full bg-white px-[30px] py-[50px] rounded-[10px]">
      {isWaitingAPI ? (
        <LayoutWaitingApi
          waitingApi={isWaitingAPI}
          className="w-full h-[120px]"
        ></LayoutWaitingApi>
      ) : (
        <>
          {examinationStatus.status === EXAMINATION_STATUS.DONE ? (
            <div className="w-full h-full flex flex-col items-center justify-center text-[24px]">
              <div>{examinationStatus.message}</div>
              <Link
                to={PATH_CANDIDATE_HOME.DEFAULT}
                className="w-[200px] h-[34px] mt-[20px] px-[30px] py-[7px] bg-primary text-white text-center text-[14px] font-bold rounded-[10px]"
              >
                ホームへ戻る
              </Link>
            </div>
          ) : (
            <>
              {candidateExamParts && candidateExamParts.length ? (
                candidateExamParts.map(
                  (candidateExamPart: PartialCandidateExamHistory, index) => (
                    <div
                      className="border border-secondary-light rounded-[10px] p-[30px] flex items-center justify-between gap-x-[148px] mb-[30px] animate-[show_0.5s_ease-in-out]"
                      key={candidateExamPart.id}
                    >
                      <div className="w-full">
                        <p className="text-[18px] font-[600] leading-[24px] border-b border-b-secondary-light pb-[3px]">
                          {candidateExamPart.name}
                        </p>
                        <p className="text-[12px] leading-[16px] tracking-[0.4px] text-justify mt-[10px]">
                          時間：
                          {candidateExamPart.limit_time}分
                        </p>
                      </div>
                      <div>
                        {index === 0 ? (
                          <button
                            className={classNames(
                              `w-[150px] py-[10px] rounded-[5px] text-white text-center text-[16px] font-[600] leading-[24px] animate-[show_0.5s_ease-in-out]`,
                              {
                                "bg-black cursor-default":
                                  candidateExamPart.status === 1,
                                "bg-danger": candidateExamPart.status === 2,
                                "bg-secondary-light cursor-default":
                                  candidateExamPart.status === 0,
                                "bg-[#8BC0D3] cursor-default":
                                  buttonTime.status === 2,
                              }
                            )}
                            disabled={
                              candidateExamPart.status === 0 ||
                              candidateExamPart.status === 1
                            }
                            onClick={() =>
                              handleClickExamSelected(
                                candidateExamPart.exam_type_id
                              )
                            }
                          >
                            {candidateExamPart.status === 2 ||
                            candidateExamPart.status === 0
                              ? buttonTime.time
                              : "終了"}
                          </button>
                        ) : (
                          <button
                            className={classNames(
                              `w-[150px] py-[10px] rounded-[5px] text-white text-center text-[16px] font-[600] leading-[24px] animate-[show_0.5s_ease-in-out]`,
                              {
                                "bg-black cursor-default":
                                  candidateExamPart.status === 1,
                                "bg-danger": candidateExamPart.status === 2,
                                "bg-secondary-light cursor-default":
                                  candidateExamPart.status === 0,
                              }
                            )}
                            disabled={
                              candidateExamPart.status === 0 ||
                              candidateExamPart.status === 1
                            }
                            onClick={() =>
                              handleClickExamSelected(
                                candidateExamPart.exam_type_id
                              )
                            }
                          >
                            {candidateExamPart.status === 2 ||
                            candidateExamPart.status === 0
                              ? "スタート"
                              : "終了"}
                          </button>
                        )}
                      </div>
                    </div>
                  )
                )
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TestPartList;
