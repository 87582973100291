import { FC } from "react";
import CheckSubDomain from "../middleware/checkSubDomain";
import YellowBlock from "../components/Login/YellowBlock";
import BeigeBlock from "../components/Login/BeigeBlock";
import OrangeBlock from "../components/Login/OrangeBlock";
import BlueBlock from "../components/Login/BlueBlock";
import GreenBlock from "../components/Login/GreenBlock";
import PurpleBlock from "../components/Login/PurpleBlock";
import HorizontalLogo from "../components/Login/HorizontalLogo";

interface LayoutProps {
  children?: JSX.Element;
}

const LayoutLandingPage: FC<LayoutProps> = (props) => {
  return (
    <CheckSubDomain>
      <div
        className="w-full min-h-screen text-center flex justify-center items-center flex-col h-full bg-white"
      >
        <HorizontalLogo
          otherStyle={
            "mt-[70px] mx-auto mb-[30px] w-[300px] h-[67px] max-sm:w-[100px] max-sm:h-[74px] max-sm:mt-[35px]"
          }
        />
        {props.children}
        <div className="fixed top-0 left-0 z-10">
          <YellowBlock />
        </div>
        <div className="fixed top-[91px] left-[146px]">
          <BeigeBlock />
        </div>
        <div className="fixed bottom-0 left-0">
          <OrangeBlock />
        </div>
        <div className="fixed bottom-[-13px] right-[133px]">
          <BlueBlock />
        </div>
        <div className="fixed bottom-[38%] right-0">
          <GreenBlock />
        </div>
        <div className="fixed bottom-[27%] right-0">
          <PurpleBlock />
        </div>
      </div>
    </CheckSubDomain>
  );
};

export default LayoutLandingPage;
