import { useState } from "react";
import { WithChildren } from "../../../../../../types/globals";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { DISPLAY_TAILWIND } from '../../../../../../services/constants/globals';

type LayoutSettingProps = {
   icon:  JSX.Element,
   label?: string;
} & WithChildren;


const LayoutSetting = ({ icon, label, children }: LayoutSettingProps) => {
   const [isExpand, setIsExpand] = useState(false);

   return (
      <>
         <div className="w-full flex justify-between items-center gap-[10px] px-[10px] py-[5px] bg-[#E1E3E2] h-[35px]">
            <div className='flex justify-start items-center gap-[10px]'>
               {icon}
               <div className='text-[14px]'>{label}</div>
            </div>
            <div>
               <button type='button' onClick={() => setIsExpand(!isExpand)} >
                  {isExpand ? <IoIosArrowDown /> : < IoIosArrowUp />}
               </button>
            </div>
         </div>
         <div className={`${isExpand ? DISPLAY_TAILWIND.HIDDEN: DISPLAY_TAILWIND.SHOW}`}>
         {children}
         </div>
      </>
   );
};

export default LayoutSetting;
