import { useEffect, useState } from "react";
import LayoutSetting from "./PropertiesSettingComponent/LayoutSetting";
import { swalSuccess } from "../../../../../services/helpers/swal";
import { ExamSettingData } from "../../../../../types/admin/exam";
import ItemSettingInput from "./PropertiesSettingComponent/ItemSettingInput";
import "../../../../../assests/styles/settingProperties.css";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useAuthoringExamContext } from "../../../../../context/Exam/AuthoringExamContext";
import {
  SETTING_FEATURES,
  SETTING_PROPERTY_NAMES,
} from "../../../../../services/constants/admin/pages/exam";
import ButtonSubmit from "./PropertiesSettingComponent/ButtonSubmit";
import {
  SETTING_PROPERTIES,
  SETTING_PROPERTY_MESSAGES,
} from "../../../../../services/constants/admin/pages/examSetting/examSetting";

const ExamSetting = () => {
  const { exam, setExam } = useAuthoringExamContext();
  const [error, setError] = useState<string>("");
  const [settingExamData, setSettingExamData] = useState<ExamSettingData>({
    max_times: {},
    is_submission_after_deadline: false,
    result_processing: false,
  });

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let currentTarget = e.target;
    const { name, value, type } = currentTarget;

    switch (name) {
      case SETTING_PROPERTY_NAMES.NAME:
        setExam({ ...exam, [name]: value });
        if (!value) {
          setError("タイトルを指定してください。");
        } else {
          setError("");
        }
        break;
        
      case SETTING_PROPERTY_NAMES.RESULT_PROCESSING:
        if (type === "checkbox") {
          setSettingExamData({
            ...settingExamData,
            [name]: currentTarget.checked,
          });
        }

        break;
    }
  };

  //  ***** V1
  // const handleSubmitUpdate = async () => {
  //   const examData = {
  //     ...exam,
  //     setting_properties: settingExamData,
  //   };

  //   await request.patch(
  //     API.ADMIN_EXAM.UPDATE,
  //     examData,
  //     (response) => {
  //       if (!response.status) swalError();
  //       setExam(response);
  //       setSettingExamData(response?.setting_properties || {});
  //       setError("");
  //     },
  //     (errors) => {
  //       setError(errors.name);
  //     },
  //     { withSuccess: true }
  //   );
  // };

  // ***** V2
  const handleSubmitUpdate = () => {
    setExam((exam) => ({ ...exam, setting_properties: settingExamData }));

    swalSuccess();
  };

  useEffect(() => {
    setSettingExamData(exam?.setting_properties || {});
  }, []);

  return (
    <>
      <div className="flex-col  bg-[#EFF1F0] h-auto border-[#BEBEBE] font-[400] min-w-[320px]">
        <div className="w-full flex-col gap-[10px] p-[20px] bg-[#F4F4F4] ">
          <div className="text-[14px] pb-[15px]">{exam.name}</div>
          <ItemSettingInput
            label="タイトル*"
            messageDetail={SETTING_PROPERTY_MESSAGES.TITLE}
            classNameLabel="basis-[28%]"
            handleChange={handleChangeValue}
            name={SETTING_PROPERTY_NAMES.NAME}
            type="text"
            className="basis-[60%] rounded-none"
            value={exam?.name}
            placeholder={exam?.name}
            error={error}
          />
        </div>
        <LayoutSetting
          icon={<IoIosCheckmarkCircleOutline className="w-[24px] h-[24px]" />}
          label={SETTING_FEATURES.SCORING}
        >
          <div className={`w-full flex-col gap-[10px] p-[20px] bg-[#F4F4F4] `}>
            <ItemSettingInput
              label={SETTING_PROPERTIES.RESULT_PROCESSING.TITLE}
              messageDetail={SETTING_PROPERTY_MESSAGES.RESULT_PROCESSING}
              handleChange={handleChangeValue}
              name={SETTING_PROPERTIES.RESULT_PROCESSING.NAME}
              type="checkbox"
              className="input_check_red checked:ring-transparent focus:ring-transparent cursor-pointer w-[24px] h-[24px] rounded-[5px]"
              checked={settingExamData.result_processing}
            />
          </div>
        </LayoutSetting>
        <ButtonSubmit error={error} handleSubmitUpdate={handleSubmitUpdate} />
      </div>
    </>
  );
};
export default ExamSetting;
