import React from "react";
import { WithChildren } from "../../../../types/globals";
import { CandidateExamGroupHistory } from "../../../../types/admin/candidateExamHistory";

type DetailExamGroupType = {
  candidateExamGroup: CandidateExamGroupHistory;
} & WithChildren;

const DetailExamGroup = ({
  candidateExamGroup,
  children,
}: DetailExamGroupType) => {
  return (
    <div className="mb-[18px]">
      <div>
        <div className="px-[10px] text-[13px] font-[500] leading-[100%] mb-[20px]">
          {candidateExamGroup.name}
        </div>
        <div className="w-full h-[30px] bg-white flex items-center gap-x-[4px] text-[12px] font-[500]">
          <div className="w-[40px] h-[30px] bg-success-lighter text-center py-[8px] leading-[100%]">
            問
          </div>
          <div className="flex-1 h-[30px] bg-success-lighter py-[8px] px-[15px] leading-[100%]">
            受験者の解答
          </div>
          <div className="flex-1 h-[30px] bg-success-lighter py-[8px] px-[15px] leading-[100%]">
            正解
          </div>
          <div className="w-[80px] h-[30px] bg-success-lighter text-center py-[8px] leading-[100%]">
            正誤
          </div>
          <div className="w-[40px] h-[30px] bg-success-lighter text-center py-[8px] leading-[100%]">
            得点
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default DetailExamGroup;
