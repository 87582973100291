import React from "react";
import { HiPlus } from "react-icons/hi";
import { IoMdDownload, IoMdExit } from "react-icons/io";
import { TbFileDescription } from "react-icons/tb";
import RoundedButton from "../commons/RoundButton";
import { Link, useLocation } from "react-router-dom";
import { PATH_ADMIN_CANDIDATE } from "../../../services/constants/route/router";

const CandidateTableCommonAction = ({
  ...props
}: React.HTMLProps<HTMLElement>) => {
  const currentLocation = useLocation();
  return (
    <section {...props}>
      <div className="flex justify-end gap-[10px]">
        {currentLocation.pathname === "/admin/candidate/create" ? (
          <RoundedButton isDisabled>
            <div className='flex items-center justify-center'>
              <HiPlus size={20} />
              <div className="ml-[12px]">新規受験者登録</div>
            </div>
          </RoundedButton>
        ) : (
          <Link to={PATH_ADMIN_CANDIDATE.CREATE}>
            <RoundedButton>
              <div className='flex items-center justify-center'>
                <HiPlus size={20} />
                <div className="ml-[12px]">新規受験者登録</div>
              </div>
            </RoundedButton>
          </Link>
        )}
        {/* TableActionButton hidden for later phases */}
        {/* <RoundedButton isDisabled>
          <IoMdDownload size={18} />
          <div className="ml-[7px]">CSVテンプレートをダウンロード</div>
        </RoundedButton>
        <RoundedButton>
          <TbFileDescription size={18} />
          <div className="ml-[10px]">CSVインポート</div>
        </RoundedButton>
        <RoundedButton>
          <IoMdExit size={20} />
          <div className="ml-[7px]">CSVエクスポート</div>
        </RoundedButton> */}
      </div>
    </section>
  );
};

export default CandidateTableCommonAction;
