import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSidebarContext } from '../../context/SidebarContext';
import Hamburger from '../../components/commons/icons/Sidebar/Hamburger';
import SidebarItem from './SidebarItem';
import { CANDIDATE_SIDEBAR_DATA } from '../../services/constants/candidate/sidebar';

const Sidebar = () => {
   const location = useLocation();
   const { showSidebar, setShowSidebar } = useSidebarContext();
   const storedOpenItem = localStorage.getItem("openItem");
   const [openItem, setOpenItem] = useState<number | null>(
      storedOpenItem ? Number(storedOpenItem) : null
   );

   useEffect(() => {
      localStorage.setItem("openItem", openItem?.toString() || "");
   }, [openItem]);

   const handleItemClick = (index: number) => {
      if (openItem === index) {
         setOpenItem(null);
      } else {
         setOpenItem(index);
      }
   };

   return (
         <section
            className="w-full text-secondary-light h-full transition-all"
            style={{
               maxWidth: showSidebar ? "60px" : "250px",
               backgroundColor: "#FBFDFC",
            }}
         >
            <div className="h-0 min-h-[38px] flex justify-end items-center cursor-pointer">
               <Hamburger
                  onClick={() => setShowSidebar(!showSidebar)}
                  isOpen={!showSidebar}
               />
            </div>
            <div className="whitespace-nowrap">
               {CANDIDATE_SIDEBAR_DATA.map((data, index) => (
                  <SidebarItem
                     data={data}
                     key={index}
                     currentPath={location.pathname}
                     isOpen={index === openItem}
                     onItemClick={() => {
                        handleItemClick(index);
                        setShowSidebar(false);
                     }}
                     isMinimize={!!showSidebar}
                  />
               ))}
            </div>
         </section>
   );
};

export default Sidebar;
