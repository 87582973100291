/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import { LiaSave } from 'react-icons/lia';
import { PiTrash } from 'react-icons/pi';
import AuthoringTextEditor from '../../AuthoringTextEditor';
import { isUsableArr } from '../../../../../../../services/helpers/etc';
import {
  CorrectFillInBlankIssue,
  FillInBlankIssue,
  FillInBlankProblem,
} from '../../../../../../../types/admin/question';
import { HiPlus } from 'react-icons/hi';
import { swalConfirm, swalError, swalSuccess } from '../../../../../../../services/helpers/swal';
import { RenderDroppedQuestionTypeEditProps } from '../../DroppedQuestionType';
import {
  generateOrderIssues,
  displayStatusEditorHandle,
  getSelectedText,
  initIssueByType,
  getIssueById,
  extractHTMLTextToText,
  getIssueIndexById,
  extractIdByIdentifier,
} from '../../../../../../../services/utils/admin/question';
import { useAuthoringQuestionContext } from '../../../../../../../context/Question/AuthoringQuestionContext';
import { renderToString } from 'react-dom/server';
import { decodeHTMLEntities } from '../../../../../../../services/helpers/parseData';
import {
  FILL_IN_BLANK_BUTTON_TYPE,
  FILL_IN_BLANK_CONTENT_RENDER_TYPE,
  NAME_TYPE_QUESTION,
} from '../../../../../../../services/constants/admin/pages/question';
import {
  BlankFillRender,
  FilledFillRender,
  MagicHighlightRender,
  PreviewBlankFillRender,
} from './FillInBlankContentRender';
import { MESSAGE_SPECIFIED } from '../../../../../../../services/constants/message';
import { DOM_NODE_TYPE } from '../../../../../../../services/constants/globals';

type FillInBlankQuestionEditProps = {
  problemEdit?: FillInBlankProblem;
} & Omit<RenderDroppedQuestionTypeEditProps, 'draggedName' | 'problemEdit'>;

const FillInBlankQuestionEdit = ({ problemEdit }: FillInBlankQuestionEditProps) => {
  const [editingNode, setEditingNode] = useState('');
  const [editingData, setEditingData] = useState<FillInBlankProblem>(problemEdit || {});
  const [originProblemContent, setOriginProblemContent] = useState(problemEdit?.content);
  const [selectingIssue, _setSelectingIssue] = useState<FillInBlankIssue>({});
  const currentIssues = editingData.issues || [];
  const currentFills = editingData.correct_fills || [];
  const [highlightingBlanked, setHighlightingBlanked] = useState<CorrectFillInBlankIssue>({});
  const [selectingBlanked, setSelectingBlanked] = useState<CorrectFillInBlankIssue>({});
  const textEditorId = `fill_in_blank_content_editor_${editingData.id}`;
  const formId = `fill_in_blank_content_form_${editingData.id}`;

  const setSelectingIssue = (issueId?: number) => {
    if (!issueId) return _setSelectingIssue({});
    _setSelectingIssue(getIssueById(currentIssues, issueId) || {});
  };

  const {
    authoringData,
    setAuthoringData,
    editingProblemStatuses,
    setEditingProblemStatuses,
    setSelectedProblem,
    setIsEditingProblem,
  } = useAuthoringQuestionContext();

  //On change handle for text editor only
  const changeEditorHandle = useCallback(
    (identify: string, content: string) => {
      if (
        content === '<p><br></p>' ||
        !editingData ||
        editingData[identify as keyof FillInBlankProblem] === content ||
        identify !== editingNode
      ) {
        return;
      }

      if (identify === 'title' || editingNode === 'title') {
        setEditingData((editingData) => ({ ...editingData, title: content }));
        return;
      }

      const newIssues = generateOrderIssues(identify, content, editingData.issues || []);
      setEditingData({ ...editingData, issues: newIssues });
    },
    [editingNode],
  );

  const tabOptionChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget || !editingData) return;
    const { name, value, checked, type } = e.currentTarget;

    let parsedValue: string | number | boolean = value;

    if (type === 'checkbox') {
      parsedValue = checked;
    }

    if (type === 'number') {
      parsedValue = Number(value);
    }

    if (Number(value) > 100) {
      parsedValue = 100;
    }

    if (Number(value) < 0) {
      parsedValue = 0;
    }

    if (
      !editingData.hasOwnProperty(name) ||
      editingData[name as keyof FillInBlankProblem] === parsedValue
    ) {
      return;
    }

    let additionData = {} as typeof editingData;
    if (name === 'default_score') {
      if (isNaN(Number(parsedValue))) return;

      // update default score for all issues
      const newFills = editingData.correct_fills?.map((fill) => ({
        ...fill,
        point: Number(parsedValue),
      }));
      additionData = { correct_fills: newFills };

      const currentFormInputs = document.querySelectorAll(`#${formId} input[name='point']`);
      currentFormInputs.forEach((input) => {
        (input as HTMLInputElement).value = String(parsedValue);
      });
    }

    setEditingData({ ...editingData, [name]: parsedValue, ...additionData });
  };

  const submitEditingDataHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);

    //Validates
    let error = '';
    for (const fill of currentFills) {
      if (fill.is_required && !fill.issueId) {
        error = MESSAGE_SPECIFIED.QUESTION.REQUIRED_FILLED;
        swalError(error);
        return;
      }
    }

    let newCorrectFills = currentFills;
    const currentFormInputs = document.querySelectorAll(`#${formId} input[name='point']`);
    currentFormInputs.forEach((input) => {
      const identifier = input.getAttribute('data-identifier');
      const point = Number((input as HTMLInputElement).value);
      (input as HTMLInputElement).value = String(point);

      newCorrectFills = newCorrectFills?.map((fill) => {
        if (identifier === fill.identifier) {
          return {
            ...fill,
            point,
          };
        }

        return fill;
      });
    });

    const finalEditingData: FillInBlankProblem = {
      ...editingData,
      content: originProblemContent,
      correct_fills: newCorrectFills,
      updated_at: new Date().getTime(),
    };

    const problemIndexed =
      authoringData.problems?.findIndex((problem) => problem.id === finalEditingData.id) || 0;

    let newProblems: FillInBlankProblem[] = authoringData.problems || [];
    newProblems.splice(problemIndexed, 1, finalEditingData);

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      'hide',
      Number(editingData.id),
      editingProblemStatuses,
    );
    setEditingProblemStatuses(newEditingProblemStatuses);

    setAuthoringData({ problems: newProblems });
    swalSuccess();
    setSelectedProblem();
  };

  const removeHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    swalConfirm(() => {
      setIsEditingProblem(false);
      setAuthoringData({
        ...authoringData,
        problems: authoringData.problems?.filter(
          (problem) => Number(problem.id) !== Number(editingData.id),
        ),
      });
    });
  };

  const hideEditorHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      'hide',
      Number(editingData.id),
      editingProblemStatuses,
    );
    setEditingProblemStatuses(newEditingProblemStatuses);
    setSelectedProblem();
  };

  const removeIssueHandle = (targetId: number) => {
    if (!targetId) return;

    swalConfirm(() => {
      const newIssueData = currentIssues.filter((issue) => Number(issue?.id) !== Number(targetId));

      // clear issueId in correct_fills
      let newCorrectFills = currentFills;
      let newContent = document.querySelector(`#${textEditorId}`)?.innerHTML;

      newCorrectFills = newCorrectFills.map((fill) => {
        if (fill.issueId === targetId) {
          const submitTargetWrapper = document.querySelector(`#${fill.identifier}`);
          const replaceTargetContent = renderToString(
            <BlankFillRender
              identifier={String(fill.identifier)}
              content={String(fill.content_consumed)}
              defaultPoint={editingData.default_score || 0}
            />,
          );

          newContent =
            newContent?.replace(String(submitTargetWrapper?.outerHTML), replaceTargetContent) || '';

          return {
            ...fill,
            issueId: undefined,
            content: replaceTargetContent,
          };
        }
        return fill;
      });

      setOriginProblemContent(newContent);
      setEditingData({
        ...editingData,
        issues: newIssueData,
        correct_fills: newCorrectFills,
        content: newContent,
      });
      setEditingNode('');
    });
  };

  const addIssueHandle = (
    inspiredLabel?: string,
    embeddedContent?: string,
    embeddedPreviewContent?: string,
  ) => {
    const issueValue = currentIssues.length || 0;
    const newIssue = initIssueByType(
      NAME_TYPE_QUESTION.CHOICE,
      issueValue,
      editingData?.default_score || 0,
      {
        label: inspiredLabel || '',
      },
    );

    let newHighlightBlanked: CorrectFillInBlankIssue = {};

    if (!!inspiredLabel && !!embeddedContent && !!embeddedPreviewContent) {
      setOriginProblemContent(embeddedContent);
      newHighlightBlanked = {
        ...highlightingBlanked,
        content: embeddedPreviewContent || '',
        point: editingData.default_score,
      };
    }

    setEditingData({
      ...editingData,
      content: embeddedContent || originProblemContent,
      issues: [...currentIssues, newIssue],
      ...(newHighlightBlanked.id
        ? { correct_fills: [...(editingData.correct_fills || []), newHighlightBlanked] }
        : {}),
    });
  };

  const highlightHandle = () => {
    const { text: selectedText, selector } = getSelectedText();
    if (!selectedText) return;

    if (selector?.anchorNode?.nodeType === DOM_NODE_TYPE.ELEMENT) return;

    const targets = document.querySelectorAll('.fill_in_blank_target');
    targets.forEach((target) => {
      target.classList.remove('fill_in_blank_selecting_target');
    });

    let currentContent = originProblemContent || '';

    document.querySelectorAll(`#${formId} [id^='highlighted_']`).forEach((existingHighlighted) => {
      const replaceTarget = existingHighlighted.outerHTML;
      const replaceValue = (existingHighlighted.childNodes?.[0] as Element)?.innerHTML;
      currentContent = currentContent.replace(replaceTarget, replaceValue);
    });

    const uniqueId = new Date().getTime();
    const identifier = `highlighted_${uniqueId}`;

    const replaceContent = renderToString(
      <MagicHighlightRender highlightContent={selectedText} identifier={identifier} />,
    );

    const replacer = (match: string, offset: number, string: string) => {
      const newCorrectFillInBlank: CorrectFillInBlankIssue = {
        id: uniqueId,
        identifier: `blanked_${uniqueId}`,
        offset,
        content: replaceContent,
        content_consumed: selectedText,
      };
      setHighlightingBlanked(newCorrectFillInBlank);

      return replaceContent;
    };

    const newContent = currentContent.replace(selectedText, replacer);

    setEditingData({
      ...editingData,
      content: newContent,
    });
    setOriginProblemContent(currentContent);
  };

  const submitHandle = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentSubmitter = (e.nativeEvent as SubmitEvent)?.submitter;
    if (!currentSubmitter) return;

    const submitContent = currentSubmitter.getAttribute('data-content');
    const submitIdentifier = currentSubmitter.getAttribute('data-identifier');
    const submitTargetIdentifier = currentSubmitter.getAttribute('data-target-identifier');
    const submitTargetContent = currentSubmitter.getAttribute('data-target-content');
    const submitButtonType = currentSubmitter.getAttribute('data-button-type');
    const submitRenderType = currentSubmitter.getAttribute('data-render-type');
    const submitWrapper = document.querySelector(`#${submitIdentifier}`);

    const blanked_identifier = 'blanked';
    let newContent: string | undefined;

    if (submitButtonType === FILL_IN_BLANK_BUTTON_TYPE.SETTING && !selectingIssue.id) {
      const blankedId = extractIdByIdentifier(String(submitTargetIdentifier));
      const selectingTarget = getIssueById(
        currentFills,
        Number(blankedId),
      ) as CorrectFillInBlankIssue;

      setSelectingBlanked(selectingTarget);

      return;
    }

    if (submitRenderType === FILL_IN_BLANK_CONTENT_RENDER_TYPE.BLANKED && !selectingIssue.id) {
      return;
    }

    // In case: Filled to Blank section
    if (submitTargetIdentifier) {
      let newCorrectFills = JSON.parse(JSON.stringify(currentFills)) as typeof currentFills;
      const currentFilledId = extractIdByIdentifier(submitTargetIdentifier);
      const currentFilledIndex = getIssueIndexById(currentFills, currentFilledId);
      const currentFilled = getIssueById(currentFills, currentFilledId) as CorrectFillInBlankIssue;
      let newFilled: CorrectFillInBlankIssue = {
        point: editingData.default_score,
      };

      let replaceTargetContent = renderToString(
        <FilledFillRender
          identifier={submitTargetIdentifier || ''}
          content={extractHTMLTextToText(selectingIssue.label || '') || ''}
          defaultPoint={editingData.default_score || 0}
        />,
      );
      const submitTargetWrapper = document.querySelector(`#${submitTargetIdentifier}`);

      if (submitButtonType === FILL_IN_BLANK_BUTTON_TYPE.CLOSE && !selectingIssue.id) {
        replaceTargetContent = renderToString(
          <BlankFillRender
            identifier={submitTargetIdentifier || ''}
            content={submitTargetContent || ''}
            defaultPoint={editingData.default_score || 0}
          />,
        );

        newFilled = {
          ...currentFilled,
          issueId: undefined,
        };
      } else {
        newFilled = {
          ...currentFilled,
          issueId: Number(selectingIssue.id),
        };
      }
      newCorrectFills.splice(currentFilledIndex, 1, newFilled);

      const currentTextEditorContent = document.querySelector(`#${textEditorId}`)?.innerHTML;
      newContent = currentTextEditorContent?.replace(
        String(submitTargetWrapper?.outerHTML),
        replaceTargetContent,
      );

      setOriginProblemContent(newContent);
      setSelectingIssue();

      setEditingData({
        ...editingData,
        content: newContent,
        correct_fills: newCorrectFills,
      });
      return;
    }

    // In case: Remove blanked section
    if (submitIdentifier?.includes(blanked_identifier)) {
      newContent = document
        .querySelector(`#${textEditorId}`)
        ?.innerHTML?.replace(String(submitWrapper?.outerHTML), submitContent || '');

      let newCorrectFills = JSON.parse(JSON.stringify(currentFills)) as typeof currentFills;
      const currentFilledId = extractIdByIdentifier(submitIdentifier);
      const currentFilledIndex = getIssueIndexById(currentFills, currentFilledId);
      newCorrectFills.splice(currentFilledIndex, 1);

      setOriginProblemContent(newContent);

      setEditingData({
        ...editingData,
        content: newContent,
        correct_fills: newCorrectFills,
      });
      return;
    }

    // In case: Create Blank section
    const replaceContent = renderToString(
      <BlankFillRender
        identifier={String(highlightingBlanked.identifier)}
        content={submitContent || ''}
        defaultPoint={editingData.default_score || 0}
      />,
    );

    const replacePreviewContent = renderToString(
      <PreviewBlankFillRender identifier={`preview_${String(highlightingBlanked.identifier)}`} />,
    );

    newContent = document
      .querySelector(`#${textEditorId}`)
      ?.innerHTML?.replace(String(submitWrapper?.outerHTML), replaceContent);
    addIssueHandle(String(submitContent), String(newContent), replacePreviewContent);
  };

  const selectingIssueHandle = (issueId?: number, index?: number) => {
    if (!issueId || index === undefined) return;

    setSelectingBlanked({});
    setEditingNode(`issue_data_label_${index}`);
    if (selectingIssue.id === issueId) {
      setSelectingIssue();
      return;
    }
    setSelectingIssue(issueId);
  };

  const contentProblemBlurHandle = (e: React.FocusEvent<HTMLDivElement, Element>) => {};

  const editorChangeHandle = (e: React.KeyboardEvent<HTMLDivElement>) => {
    let originContent = e.currentTarget.innerHTML || '';

    document.querySelectorAll(`#${formId} [id^='highlighted_']`).forEach((existingFillInBlank) => {
      const replaceTarget = existingFillInBlank.outerHTML;
      const replaceValue = (existingFillInBlank.childNodes?.[0] as Element)?.innerHTML;
      originContent = originContent.replace(replaceTarget, replaceValue);
    });

    document
      .querySelectorAll(`#${formId} p > span.highlight_content`)
      .forEach((existingFillInBlank) => {
        if (existingFillInBlank.childNodes.length === 1) return;

        const replaceTarget = existingFillInBlank.outerHTML;
        const replaceValue =
          (
            existingFillInBlank.childNodes?.[existingFillInBlank.childNodes.length - 1] as Element
          )?.getAttribute('data-content') || '';

        originContent = originContent.replace(replaceTarget, replaceValue);
      });

    setEditingData({
      ...editingData,
      content_origin: originContent,
    });
    setOriginProblemContent(e.currentTarget.innerHTML);
  };

  const issueAppearedCount = (issueId?: number) => {
    const appearedFilleds = currentFills.filter((fill) => fill.issueId === issueId);
    return appearedFilleds.length;
  };

  const isIssueAppearLimitExceed = (issueId?: number) => {
    if (!issueId) return false;
    const appearedCount = issueAppearedCount(issueId);
    const currentIssue = getIssueById(currentIssues, issueId) as FillInBlankIssue;

    if (
      !!appearedCount &&
      appearedCount >= Number(currentIssue?.appear_limit) &&
      !!Number(currentIssue?.appear_limit)
    ) {
      return true;
    }

    return false;
  };

  const issueAppearLimitChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    issueId?: number,
  ) => {
    const { value } = e.currentTarget;
    if (!issueId || !e.currentTarget) return;
    const currentIssueIndex = getIssueIndexById(currentIssues, issueId);
    const newCurrentIssues = JSON.parse(JSON.stringify(currentIssues)) as typeof currentIssues;
    let newCurrentIssue = getIssueById(currentIssues, issueId);

    newCurrentIssue = {
      ...newCurrentIssue,
      appear_limit: Number(value),
    };
    newCurrentIssues.splice(currentIssueIndex, 1, newCurrentIssue);

    let newCorrectFills = currentFills;

    if (
      !!newCurrentIssue.appear_limit &&
      newCurrentIssue.appear_limit <= issueAppearedCount(issueId)
    ) {
      return;
    }

    setEditingData({
      ...editingData,
      issues: newCurrentIssues,
      correct_fills: newCorrectFills,
    });
  };

  const blankedIsRequiredChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    targetId?: number,
  ) => {
    const { checked } = e.currentTarget;
    if (!targetId || !e.currentTarget) return;

    let newCorrectFills = currentFills;
    newCorrectFills = newCorrectFills?.map((fill) => {
      if (targetId === fill.id) {
        return {
          ...fill,
          is_required: checked,
        };
      }

      return fill;
    });

    setEditingData({
      ...editingData,
      correct_fills: newCorrectFills,
    });
  };

  useEffect(() => {
    const targets = document.querySelectorAll('.fill_in_blank_target');

    if (!selectingIssue.id) {
      targets.forEach((target) => {
        target.classList.remove('fill_in_blank_selecting_target');
      });

      return;
    }

    targets.forEach((target) => {
      target.classList.add('fill_in_blank_selecting_target');
    });
  }, [selectingIssue.id]);

  return (
    <form onSubmit={submitHandle} id={formId}>
      <div className="mb-[10px] bg-secondary-extralight min-h-[780px] w-full p-[20px] relative">
        <div className="mb-[30px]"></div>
        <div className="flex gap-[13px]">
          <div className="w-[80%]">
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設問内容
              </div>
              <div className="w-full bg-white">
                <div className="p-[10px]" onClick={() => setEditingNode('title')}>
                  <AuthoringTextEditor
                    toolbarId={`toolbar_title`}
                    isReadOnly={editingNode !== 'title' && editingNode !== ''}
                    changeHandler={(htmlContent) => changeEditorHandle('title', htmlContent)}
                    value={editingData?.title || ''}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary h-0 min-h-[34px] flex items-center">
                正解・解答・配点
              </div>
              <div className="bg-white py-[20px] text-secondary-dark rounded-b-[10px]">
                <div className="pt-[20px] px-[10px] flex flex-wrap gap-[5px]">
                  {isUsableArr(currentIssues) &&
                    currentIssues &&
                    currentIssues.map((issue, index) => (
                      <div className="mb-[15px] flex items-center gap-[10px] px-[10px]" key={index}>
                        <div
                          className={`${
                            selectingIssue.id === issue.id ? 'selecting_border_editor' : ''
                          } ${
                            isIssueAppearLimitExceed(issue.id)
                              ? 'pointer-events-none bg-secondary-light rounded-[5px]'
                              : ''
                          }`}
                          onClick={() => selectingIssueHandle(issue.id, index)}
                        >
                          <AuthoringTextEditor
                            toolbarId={`toolbar_issue_${index}`}
                            isReadOnly={editingNode !== `issue_data_label_${index}`}
                            changeHandler={(htmlContent) =>
                              changeEditorHandle(`issue_data_label_${index}`, htmlContent)
                            }
                            value={issue.label || ''}
                            width="w-fit"
                          />
                        </div>
                        <div
                          className={`cursor-pointer ${
                            selectingIssue.id === issue.id ? 'text-primary ' : 'text-secondary'
                          }`}
                          onClick={() => removeIssueHandle(issue?.id || 0)}
                        >
                          <PiTrash size={22} />
                        </div>
                      </div>
                    ))}
                  <div className="cursor-pointer" onClick={() => addIssueHandle()}>
                    <div className="flex justify-center">
                      <div className="bg-primary rounded-[5px] w-[40px] h-[40px] flex justify-center items-center text-white cursor-pointer mb-[10px]">
                        <HiPlus size={40} />
                      </div>
                    </div>
                    <div className="text-center text-[12px] font-[500] leading-[100%] text-secondary-dark">
                      選択肢を追加
                    </div>
                  </div>
                </div>

                <div className="px-[20px]">
                  <div className="my-[32px] border-t-[2px] border-secondary-light w-full text-[12px] leading-[190%] text-secondary-light">
                    問題文を入力してから、問題文の一部を空白にするために、問題文の一部を選択して赤いアイコンをクリックしてください。
                  </div>
                </div>

                <div className="px-[4px] mb-[100px]">
                  <div
                    className="text-[12px] leading-[190%] no_border_editor"
                    onClick={() => setEditingNode(textEditorId)}
                    onMouseUp={highlightHandle}
                    onBlur={contentProblemBlurHandle}
                  >
                    {/* <AuthoringTextEditor
                      toolbarId={`fill_in_blank_content_${editingData.id}`}
                      isReadOnly={editingNode !== `issue_data_label_${editingData.id}`}
                      // changeHandler={(htmlContent) =>
                      //   changeEditorHandle(`issue_data_label_${editingData.id}`, htmlContent)
                      // }
                      value={editingData.content || ''}
                      className="border-0"
                    /> */}
                    <div className="">
                      <div
                        className="px-[15px] focus:outline-none focus:ring focus:border-primary focus:rounded-[5px] highlight_content"
                        id={textEditorId}
                        contentEditable
                        suppressContentEditableWarning
                        dangerouslySetInnerHTML={{
                          __html: decodeHTMLEntities(editingData.content),
                        }}
                        onKeyUp={editorChangeHandle}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[20%] flex flex-col gap-y-[15px]">
            <div className="flex flex-col">
              <div className="p-[10px] text-[14px] font-[500] leading-[100%] text-white w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設定
              </div>
              <div className="bg-white min-h-[100px] px-[10px]">
                <div className="mb-[25px] m-[10px] flex items-center gap-[10px] text-[14px] font-[500] leading-[100%]">
                  <label htmlFor="is_shuffled">選択肢をシャッフル</label>
                  <input
                    type="checkbox"
                    name="is_shuffled"
                    id="is_shuffled"
                    onChange={tabOptionChangeHandle}
                    checked={!!editingData?.is_shuffled}
                  />
                </div>

                <div className="mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    配点（目安）
                  </div>
                  <div className="choice_limit">
                    <input
                      type="text"
                      name="default_score"
                      className="w-[71px] h-[32px] !pr-0"
                      onChange={tabOptionChangeHandle}
                      value={Number(editingData?.default_score) || ''}
                    />
                  </div>
                </div>

                {selectingIssue.id ? (
                  <div className="m-[10px] mb-[20px]">
                    <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                      選択肢の選択回数
                    </div>
                    <div className="choice_limit flex items-center gap-[10px]">
                      <div className="text-[12px] font-[400] leading-[100%]">最大</div>
                      <input
                        type="number"
                        name="appear_limit"
                        placeholder="0"
                        className="w-[71px] h-[32px] !pr-0"
                        onChange={(e) => issueAppearLimitChangeHandle(e, selectingIssue.id)}
                        value={
                          (
                            getIssueById(
                              currentIssues,
                              Number(selectingIssue.id),
                            ) as FillInBlankIssue
                          )?.appear_limit || ''
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {selectingBlanked.id ? (
                  <div className="m-[10px] mb-[20px]">
                    <div className="choice_limit flex items-center gap-[10px]">
                      <div className="text-[12px] font-[400] leading-[100%]">必須</div>
                      <input
                        type="checkbox"
                        name="is_required"
                        placeholder="0"
                        className="w-[71px] h-[32px] !pr-0"
                        onChange={(e) => blankedIsRequiredChangeHandle(e, selectingBlanked.id)}
                        checked={
                          (
                            getIssueById(
                              currentFills,
                              Number(selectingBlanked.id),
                            ) as CorrectFillInBlankIssue
                          )?.is_required
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="text-white">
              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
                  onClick={submitEditingDataHandle}
                >
                  <LiaSave size={23} /> 保存
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={hideEditorHandle}
                >
                  キャンセル
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer gap-[5px] flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={removeHandle}
                >
                  <PiTrash size={20} />
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FillInBlankQuestionEdit;
