/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import { LiaSave } from "react-icons/lia";
import { PiTrash } from "react-icons/pi";
import { RenderDroppedQuestionTypeEditProps } from "../../DroppedQuestionType";
import {
  TestTakerUploadProblem,
  TestTakerRecordProblem,
} from "../../../../../../../types/admin/question";
import AuthoringTextEditor from "../../AuthoringTextEditor";
import { useAuthoringQuestionContext } from "../../../../../../../context/Question/AuthoringQuestionContext";
import { displayStatusEditorHandle } from "../../../../../../../services/utils/admin/question";
import {
  swalConfirm,
  swalMessage,
  swalSuccess,
} from "../../../../../../../services/helpers/swal";
import { MODE_QUESTION_CATEGORY_SELECTOR } from "../../../../../../../services/constants/admin/pages/question";
import { BiEditAlt } from "react-icons/bi";
import BaseModal from "../../../../../commons/BaseModal";
import { Folder } from "../../../../../../../types/admin/folder";
import { API } from "../../../../../../../services/constants/route/api";
import {
  makeRequest,
  request,
} from "../../../../../../../services/axios/axios";
import CategorySelector from "../TestTakerUploadQuestion/CategorySelector";
import TestTakerRecordVoiceUploader from "./TestTakerRecordVoiceUploader";
import MinuteSelectDropdown from "./TestTakerRecordVoiceComponent/MinuteSelectDropdown";

type TestTakerRecordVoiceQuestionEditProps = {
  problemEdit?: TestTakerRecordProblem;
} & Omit<RenderDroppedQuestionTypeEditProps, "draggedName" | "problemEdit">;

const TestTakerRecordVoiceQuestionEdit = ({
  problemEdit,
}: TestTakerRecordVoiceQuestionEditProps) => {
  const {
    authoringData,
    setAuthoringData,
    editingProblemStatuses,
    setEditingProblemStatuses,
    setSelectedProblem,
    setIsEditingProblem
  } = useAuthoringQuestionContext();

  const [editingNode, setEditingNode] = useState("");
  const [editingData, setEditingData] = useState<TestTakerRecordProblem>(
    problemEdit || {}
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectingFolder, setSelectingFolder] = useState<Folder | null>(null);
  const [selectorMode, setSelectorMode] = useState(
    MODE_QUESTION_CATEGORY_SELECTOR.SELECT
  );
  const [folderName, setFolderName] = useState("");
  const [showRecord, setShowRecord] = useState<boolean>(false);

  const changeEditorHandle = useCallback(
    (identify: string, content: string) => {
      if (
        content === "<p><br></p>" ||
        !editingData ||
        editingData[identify as keyof TestTakerRecordProblem] === content ||
        identify !== editingNode
      ) {
        return;
      }

      if (identify === "title" || editingNode === "title") {
        setEditingData({ ...editingData, title: content });
        return;
      }

      setEditingData({ ...editingData });
    },
    [editingNode]
  );

  const hideEditorHandle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsEditingProblem(false);
    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      "hide",
      Number(editingData.id),
      editingProblemStatuses
    );
    setEditingProblemStatuses(newEditingProblemStatuses);
    setSelectedProblem();
  };

  const submitEditingDataHandle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsEditingProblem(false);

    if (!editingData.upload_folder) {
      swalMessage("", "Please choose folder upload", "error", { timer: 1500 });
      return;
    }

    const finalEditingData = {
      ...editingData,
      updated_at: new Date().getTime(),
    };

    const problemIndexed =
      authoringData.problems?.findIndex(
        (problem) => problem.id === finalEditingData.id
      ) || 0;

    let newProblems: TestTakerUploadProblem[] = authoringData.problems || [];
    newProblems.splice(problemIndexed, 1, finalEditingData);

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      "hide",
      Number(editingData.id),
      editingProblemStatuses
    );
    setEditingProblemStatuses(newEditingProblemStatuses);

    setAuthoringData({ problems: newProblems });
    swalSuccess();
    setSelectedProblem();
  };

  const removeHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    swalConfirm(() => {
      setIsEditingProblem(false);
      setAuthoringData({
        ...authoringData,
        problems: authoringData.problems?.filter(
          (problem) => Number(problem.id) !== Number(editingData.id)
        ),
      });
    });
  };

  const changeCategoryHandle = () => {
    setIsOpenModal(true);
  };

  const submitModalHandle = async () => {
    let uploadFolder: Folder | null = null;
    if (selectorMode === MODE_QUESTION_CATEGORY_SELECTOR.CREATE) {
      if (!folderName || !folderName.trim()) return;
      const folderCreator: Folder = await request.post(
        API.ADMIN_FOLDER.CREATE,
        {
          name: folderName,
        }
      );
      if (!folderCreator || !folderCreator.id) return;

      uploadFolder = folderCreator;
    } else {
      if (!selectingFolder) return;
      uploadFolder = selectingFolder;
    }

    setEditingData({
      ...editingData,
      upload_folder: uploadFolder,
    });
    setSelectorMode(MODE_QUESTION_CATEGORY_SELECTOR.SELECT);
    setIsOpenModal(false);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;

    switch (type) {
      case "number":
        setEditingData({ ...editingData, [name]: Number(value) });
        break;

      default:
        setEditingData({ ...editingData, [name]: value });
        break;
    }
  };

  useEffect(() => {
    const fetchFolderResource = async () => {
      const result = await makeRequest({
        method: "get",
        url: API.ADMIN_FOLDER.LATEST,
      });

      if (!result.status) return;

      setEditingData({ ...editingData, upload_folder: result.data });
    };

    if (!editingData.upload_folder) {
      fetchFolderResource();
    }
  }, []);
  
  return (
    <>
      <form>
        <div className="mb-[10px] bg-secondary-extralight min-h-[780px] w-full p-[20px] relative">
          <div className="mb-[30px]"></div>
          <div className="flex gap-[13px]">
            <div className="w-[80%]">
              <div className="">
                <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                  設問内容
                </div>
                <div className="w-full bg-white">
                  <div
                    className="p-[10px]"
                    onClick={() => setEditingNode("title")}
                  >
                    <AuthoringTextEditor
                      toolbarId={`toolbar_title`}
                      isReadOnly={editingNode !== "title" && editingNode !== ""}
                      changeHandler={(htmlContent) =>
                        changeEditorHandle("title", htmlContent)
                      }
                      value={editingData?.title || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary h-0 min-h-[34px] flex items-center">
                  正解・解答
                </div>
                <div className="bg-white pt-[10px] rounded-b-[10px]">
                  <div className="px-[10px] py-[70px]">
                    <div className="flex flex-col items-center justify-center mb-[15px]">
                      <TestTakerRecordVoiceUploader
                        problemData={editingData}
                        actionable
                        handleChange={handleChangeInput}
                      />
                    </div>
                    <div className="text-center text-[12px] font-[500] leading-[190%]">
                      ファイルはファイル管理にアップロードされます。アップロードするフォルダーを設定してください。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[20%] flex flex-col gap-y-[15px]">
              <div className="flex flex-col">
                <div className="p-[10px] text-[14px] font-[500] leading-[100%] text-white w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                  設定
                </div>
                <div className="bg-white min-h-[100px] px-[10px]">
                  <div className="mb-[25px] m-[10px] text-[14px] font-[500] leading-[100%] gap-[10px] pr-[8px]">
                    <div
                      className="flex items-center w-full gap-[10px] h-0 text-[12px] min-h-[32px] rounded-[5px] border border-success-lighter px-[10px] cursor-pointer"
                      onClick={changeCategoryHandle}
                    >
                      <BiEditAlt size={20} />
                      {editingData?.upload_folder?.name || "Image Category"}
                    </div>
                    <div className="mt-[10px]">へアップロードする</div>
                  </div>

                  <div className="mx-[10px]">
                    <div className="my-[25px]">
                      <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                        最大録音容量（分)
                      </div>
                      <div className="flex items-center gap-[10px] max-h-[100px] overflow-y-auto">
                        <MinuteSelectDropdown
                          editingData={editingData}
                          setEditingData={setEditingData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-white">
                <div className="flex justify-center w-full mb-[20px]">
                  <button
                    className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
                    onClick={submitEditingDataHandle}
                  >
                    <LiaSave size={23} /> 保存
                  </button>
                </div>

                <div className="flex justify-center w-full mb-[20px]">
                  <button
                    className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                    onClick={hideEditorHandle}
                  >
                    キャンセル
                  </button>
                </div>

                <div className="flex justify-center w-full mb-[20px]">
                  <button
                    className="cursor-pointer gap-[5px] flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                    onClick={removeHandle}
                  >
                    <PiTrash size={20} />
                    削除
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="">
        <BaseModal
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          width={1010}
          height={740}
          justifyDirection="end"
          submitLabel="決定する"
          onClick={submitModalHandle}
          setSelectorMode={setSelectorMode}
        >
          <div className="pt-[120px]">
            <CategorySelector
              editingData={editingData}
              selectingFolder={selectingFolder}
              setSelectingFolder={setSelectingFolder}
              selectorMode={selectorMode}
              setSelectorMode={setSelectorMode}
              setFolderName={setFolderName}
            />
          </div>
        </BaseModal>
      </div>
    </>
  );
};

export default TestTakerRecordVoiceQuestionEdit;
