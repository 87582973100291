/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { Question, QuestionForm } from "../../../../types/admin/question";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import DetailFormField from "./DetailFormField";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { useQuestionListContext } from "../../../../context/Question/ListQuestionContext";
import { swalError, swalMessage } from "../../../../services/helpers/swal";
import SpinnerButton from "../../commons/SpinnerButton";

type DetailFormProps = {
  question: Question;
};

const DetailForm = ({ question }: DetailFormProps) => {
  const { questions, setQuestions, setSelectedQuestion } =
    useQuestionListContext();
  useEffect(() => {
    setFormData(question);
  }, [question.id]);
  const [waitingAPI, setWaitingAPI] = useState<boolean>(false);
  const [formData, setFormData] = useState<QuestionForm>(question || {});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setWaitingAPI(true);
    const result = await makeRequest({
      method: "patch",
      url: API.ADMIN_QUESTION.UPDATE,
      data: formData,
    });
    setWaitingAPI(false);

    if (!result.status) {
      swalError(result.message);
      return;
    }

    const data = result.data;
    setFormData(data);
    setQuestions([data, ...questions]);

    swalMessage("保存しました", "", "success", {
      timer: 1000,
      showConfirmButton: false,
    });
  };

  const handleClick = () => {
    setSelectedQuestion(0);
  };

  return (
    <div>
      <div className="mb-[17px]">
        {question.is_file ? "問題編集" : "フォルダ名の編集"}
      </div>
      <form onSubmit={handleSubmit}>
        <DetailFormField label={question.is_file ? "問題名" : "フォルダ名"}>
          <input
            type="text"
            name="name"
            id=""
            placeholder={
              question.is_file
                ? "問題タイトルを入力してください"
                : "フォルダ名を入力してください"
            }
            className="h-[30px]"
            value={formData.name}
            onChange={handleChange}
          />
        </DetailFormField>

        {question.is_file ? (
          <>
            {/* <DetailFormField label="URL">
              <div className="flex relative">
                <input
                  type="text"
                  name="url"
                  id=""
                  placeholder="http://d4d7-117-6-130-156.ngrok-free.app/tao.rdf#i168966997677412"
                  className="h-[30px] !rounded-r-none !border-r-0"
                />
                <div
                  className={`hover:bg-success-extralight cursor-pointer text-secondary bg-success-lighter w-[40px] h-[30px] rounded-r-[5px] flex items-center justify-center ${!formData.question_resource_id && 'pointer-events-none'
                    }`}
                >
                  <LuClipboardList />
                </div>
              </div>
            </DetailFormField> */}

            <DetailFormField label="最終更新日">
              <div className="pl-[7px]">
                {formatDateTime(
                  formData.updated_at,
                  TYPE_DATE_FORMAT.REGULAR_GLOBAL_FULL
                )}
              </div>
            </DetailFormField>
          </>
        ) : (
          <></>
        )}

        <div className="flex justify-center w-full mt-[40px] gap-[27px] text-white text-[16px] font-[500] leading-[24px] tracking-[0.1px]">
          {question.is_file ? (
            <button
              onClick={handleClick}
              type="button"
              className="cursor-pointer flex items-center justify-center w-full max-w-[150px] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
            >
              キャンセル
            </button>
          ) : (
            <></>
          )}
          <SpinnerButton
            name="保存する"
            onSubmit={() => {}}
            waitingAPI={waitingAPI}
            className="!rounded-[10px] !bg-danger"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default DetailForm;
