import CandidateBase from '.';
import CandidateList from '../../../components/admin/Candidate/CandidateList';
import CandidateProvider from '../../../context/CandidateContext';

const CandidateListPage = () => {
  return (
    <CandidateBase title="受験者管理 ｜ 受験者一覧">
      <CandidateProvider>
        <CandidateList />
      </CandidateProvider>
    </CandidateBase>
  );
};

export default CandidateListPage;
