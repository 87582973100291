import {
  BsFileEarmarkImage,
  BsFileEarmarkMusicFill,
  BsFileEarmarkPdfFill,
  BsFileEarmarkPlayFill,
  BsFileEarmarkSpreadsheetFill,
  BsFileEarmarkTextFill,
} from 'react-icons/bs';
import { AiFillFileUnknown } from 'react-icons/ai';

type RenderUploadedIconProps = {
  extension: string;
};

const RenderUploadedIcon = ({ extension }: RenderUploadedIconProps) => {
  switch (extension) {
    case 'dot':
    case 'doc':
    case 'docx':
    case 'xml':
      return <BsFileEarmarkTextFill />;
    case 'xlsx':
    case 'xls':
    case 'csv':
      return <BsFileEarmarkSpreadsheetFill />;
    case 'pdf':
      return <BsFileEarmarkPdfFill />;
    case 'ogm':
    case 'wmv':
    case 'mpg':
    case 'webm':
    case 'ogv':
    case 'mov':
    case 'asx':
    case 'mpeg':
    case 'mp4':
    case 'm4v':
    case 'avi':
      return <BsFileEarmarkPlayFill />;
    case 'xbm':
    case 'tif':
    case 'jfif':
    case 'ico':
    case 'tiff':
    case 'gif':
    case 'svg':
    case 'jpeg':
    case 'svgz':
    case 'jpg':
    case 'webp':
    case 'png':
    case 'bmp':
    case 'pjp':
    case 'apng':
    case 'pjpeg':
    case 'avif':
      return <BsFileEarmarkImage />;
    case 'opus':
    case 'flac':
    case 'weba':
    case 'wav':
    case 'ogg':
    case 'm4a':
    case 'oga':
    case 'mid':
    case 'mp3':
    case 'aiff':
    case 'wma':
    case 'au':
      return <BsFileEarmarkMusicFill />;

    default:
      return <AiFillFileUnknown />;
  }
};

export default RenderUploadedIcon;
