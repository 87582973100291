import React, { useState, useContext } from "react";
import { ChildrenWithProps } from "../types/globals";
import { Candidate } from "../types/admin/candidate";

interface CandidateContextType {
  candidates: Candidate[];
  setCandidates: React.Dispatch<React.SetStateAction<Candidate[]>>;
  totalPages: number;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
}

const CandidateContext = React.createContext<CandidateContextType>({
  candidates: [],
  setCandidates: () => {},
  totalPages: 1,
  setTotalPages: () => {},
});

const CandidateProvider = ({ children }: ChildrenWithProps) => {
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  return (
    <CandidateContext.Provider
      value={{ candidates, setCandidates, totalPages, setTotalPages }}
    >
      {children}
    </CandidateContext.Provider>
  );
};

export default CandidateProvider;

export const useCandidateContext = () => useContext(CandidateContext);
