/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import { LiaSave } from 'react-icons/lia';
import { PiTrash } from 'react-icons/pi';
import ChoiceQuestionListDisplay from './ChoiceQuestionListDisplay';
import AuthoringTextEditor from '../../AuthoringTextEditor';
import { isUsableArr } from '../../../../../../../services/helpers/etc';
import { ChoiceProblem } from '../../../../../../../types/admin/question';
import { HiPlus } from 'react-icons/hi';
import { swalConfirm, swalSuccess } from '../../../../../../../services/helpers/swal';
import { RenderDroppedQuestionTypeEditProps } from '../../DroppedQuestionType';
import {
  checkLimitChoice,
  displayStatusEditorHandle,
  generateChoiceIssues,
  generateListStyleChoice,
  initIssueByType,
  isListBoxStyle,
} from '../../../../../../../services/utils/admin/question';
import { NAME_TYPE_QUESTION } from '../../../../../../../services/constants/admin/pages/question';
import { useAuthoringQuestionContext } from '../../../../../../../context/Question/AuthoringQuestionContext';
import AuthoringMessageBox from '../AuthoringMessageBox';

type ChoiceQuestionEditProps = {
  problemEdit?: ChoiceProblem;
} & Omit<RenderDroppedQuestionTypeEditProps, 'draggedName' | 'problemEdit'>;

const ChoiceQuestionEdit = ({ problemEdit }: ChoiceQuestionEditProps) => {
  const [editingNode, setEditingNode] = useState('');
  const [editingData, setEditingData] = useState<ChoiceProblem>(problemEdit || {});
  const currentIssues = editingData.issues || [];
  const {
    authoringData,
    setAuthoringData,
    editingProblemStatuses,
    setEditingProblemStatuses,
    setSelectedProblem,
    setIsEditingProblem
  } = useAuthoringQuestionContext();

  //On change handle for text editor only
  const changeEditorHandle = useCallback(
    (identify: string, content: string) => {
      if (
        content === '<p><br></p>' ||
        !editingData ||
        editingData[identify as keyof ChoiceProblem] === content ||
        identify !== editingNode
      ) {
        return;
      }

      if (identify === 'title' || editingNode === 'title') {
        setEditingData((editingData) => ({ ...editingData, title: content }));
        return;
      }

      const newIssues = generateChoiceIssues(identify, content, editingData.issues || []);
      setEditingData({ ...editingData, issues: newIssues });
    },
    [editingNode],
  );

  const tabOptionChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget || !editingData) return;
    const { name, value, checked, type } = e.currentTarget;

    let parsedValue: string | number | boolean = value;

    if (type === 'checkbox') {
      parsedValue = checked;
    }

    if (type === 'radio') {
      parsedValue = !!Number(value);
    }

    if (type === 'number') {
      parsedValue = Number(value);
    }

    if (Number(value) > 100) {
      parsedValue = 100;
    }

    if (Number(value) < 0) {
      parsedValue = 0;
    }

    if (
      !editingData.hasOwnProperty(name) ||
      editingData[name as keyof ChoiceProblem] === parsedValue
    ) {
      return;
    }

    let additionData = {} as typeof editingData;
    if (name === 'default_score') {
      // update default score for all issues
      const newIssues = editingData.issues?.map((issue) => ({
        ...issue,
        point: Number(parsedValue),
      }));
      additionData = { issues: newIssues };
    }

    if (name === 'min_choice') {
      // Make min choice must =< max choice
      if (Number(parsedValue) > Number(editingData.max_choice)) {
        additionData = {
          ...additionData,
          max_choice: Number(parsedValue),
        };
      }
    }

    if (name === 'max_choice') {
      // Make max choice must >= min choice
      if (Number(parsedValue) < Number(editingData.min_choice) && !!Number(parsedValue)) {
        additionData = {
          ...additionData,
          min_choice: Number(parsedValue),
        };
      }
    }

    setEditingData({ ...editingData, [name]: parsedValue, ...additionData });
  };

  const issueChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget) return;
    const { name, value, type, checked } = e.currentTarget;

    let parsedValue: string | boolean = String(Number(value));
    if (type === 'checkbox') {
      parsedValue = checked;
    }

    if (Number(value) > 100) {
      parsedValue = '100';
    }

    // if (Number(value) < 0) {
    //   parsedValue = '0';
    // }
    const newIssues = generateChoiceIssues(name, parsedValue, editingData.issues || []);
    setEditingData({ ...editingData, issues: newIssues });
  };

  const addIssueHandle = () => {
    const issueValue = currentIssues.length || 0;
    const newIssue = initIssueByType(
      NAME_TYPE_QUESTION.CHOICE,
      issueValue,
      editingData?.default_score || 0,
    );
    setEditingData({ ...editingData, issues: [...currentIssues, newIssue] });
  };

  const removeIssueHandle = (targetId: number | string) => {
    if (!targetId) return;

    swalConfirm(() => {
      const newIssueData = currentIssues.filter((issue) => Number(issue?.id) !== Number(targetId));
      setEditingData({ ...editingData, issues: newIssueData });
      setEditingNode('');
    });
  };

  const submitEditingDataHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);
    const finalEditingData = {
      ...editingData,
      updated_at: new Date().getTime(),
    };

    const problemIndexed =
      authoringData.problems?.findIndex((problem) => problem.id === finalEditingData.id) || 0;

    let newProblems: ChoiceProblem[] = authoringData.problems || [];
    newProblems.splice(problemIndexed, 1, finalEditingData);

    const checkedCount = editingData.issues?.filter((issue) => !!issue.checked).length;
    const { passed } = checkLimitChoice(
      checkedCount,
      editingData.min_choice,
      editingData.max_choice,
    );

    if (!passed) {
      return;
    }

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      'hide',
      Number(editingData.id),
      editingProblemStatuses,
    );
    setEditingProblemStatuses(newEditingProblemStatuses);

    setAuthoringData({ problems: newProblems });
    swalSuccess();
    setSelectedProblem();
  };

  const removeHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    swalConfirm(() => {
      setIsEditingProblem(false);
      setAuthoringData({
        ...authoringData,
        problems: authoringData.problems?.filter(
          (problem) => Number(problem.id) !== Number(editingData.id),
        ),
      });
    });
  };

  const hideEditorHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);
    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      'hide',
      Number(editingData.id),
      editingProblemStatuses,
    );
    setEditingProblemStatuses(newEditingProblemStatuses);
    setSelectedProblem();
  };

  const listStyleChangeHandle = (selectedType: string) => {
    setEditingData({ ...editingData, list_style: selectedType });
  };

  return (
    <form>
      <div className="mb-[10px] bg-secondary-extralight min-h-[780px] w-full p-[20px] relative">
        <div className="mb-[30px]"></div>
        <div className="flex gap-[13px]">
          <div className="w-[80%]">
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設問内容
              </div>
              <div className="w-full bg-white">
                <div className="p-[10px]" onClick={() => setEditingNode('title')}>
                  <AuthoringTextEditor
                    toolbarId={`toolbar_title`}
                    isReadOnly={editingNode !== 'title' && editingNode !== ''}
                    changeHandler={(htmlContent) => changeEditorHandle('title', htmlContent)}
                    value={editingData?.title || ''}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary h-0 min-h-[34px] flex items-center">
                正解・解答・配点
              </div>
              <div className="bg-white pt-[10px] rounded-b-[10px]">
                <div className="p-[10px]">
                  <AuthoringMessageBox problemData={editingData} />
                </div>
                <ul
                  className={`min-h-[100px] pt-[10px] pb-[30px] px-[10px] ${generateListStyleChoice(
                    editingData?.list_style,
                  )} ${!editingData?.is_vertical_arrange ? 'flex flex-wrap' : ''}`}
                >
                  {isUsableArr(currentIssues) &&
                    currentIssues &&
                    currentIssues.map((issue, index) => (
                      <div className="mb-[15px] flex items-center gap-[10px] px-[10px]" key={index}>
                        <div
                          className={`flex items-center ${
                            !isListBoxStyle(editingData?.list_style) ? 'mr-[25px]' : ''
                          }`}
                        >
                          <input
                            type="checkbox"
                            name={`issue_data_checked_${index}`}
                            id=""
                            className=""
                            onChange={issueChangeHandle}
                            checked={issue.checked}
                          />
                        </div>
                        <li
                          className={`${
                            !editingData?.is_vertical_arrange ? 'w-[150px]' : 'w-[80%]'
                          } `}
                          onClick={() => setEditingNode(`issue_data_label_${index}`)}
                        >
                          <AuthoringTextEditor
                            toolbarId={`toolbar_issue_${index}`}
                            isReadOnly={editingNode !== `issue_data_label_${index}`}
                            changeHandler={(htmlContent) =>
                              changeEditorHandle(`issue_data_label_${index}`, htmlContent)
                            }
                            value={issue.label || ''}
                            isDisabled={editingData?.is_excluded && issue.checked}
                          />
                        </li>
                        <div className="">
                          <input
                            type="number"
                            name={`issue_data_point_${index}`}
                            id=""
                            className="w-[45px] h-[40px] !border-secondary-light"
                            placeholder="0"
                            onChange={issueChangeHandle}
                            value={issue.point || ''}
                          />
                        </div>
                        <div
                          className="cursor-pointer text-secondary"
                          onClick={() => removeIssueHandle(issue?.id || 0)}
                        >
                          <PiTrash size={22} />
                        </div>
                      </div>
                    ))}
                  <div className="w-full flex justify-center mt-[20px]">
                    <div className="cursor-pointer" onClick={addIssueHandle}>
                      <div className="flex justify-center">
                        <div className="bg-primary rounded-[5px] w-[40px] h-[40px] flex justify-center items-center text-white cursor-pointer mb-[10px]">
                          <HiPlus size={40} />
                        </div>
                      </div>
                      <div className="text-center text-[12px] font-[500] leading-[100%] text-secondary-dark">
                        選択肢を追加
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-[20%] flex flex-col gap-y-[15px]">
            <div className="flex flex-col">
              <div className="p-[10px] text-[14px] font-[500] leading-[100%] text-white w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設定
              </div>
              <div className="bg-white min-h-[100px] px-[10px]">
                <div className="mb-[25px] m-[10px] flex items-center gap-[10px] text-[14px] font-[500] leading-[100%]">
                  <label htmlFor="is_shuffled">選択肢をシャッフル</label>
                  <input
                    type="checkbox"
                    name="is_shuffled"
                    id="is_shuffled"
                    onChange={tabOptionChangeHandle}
                    checked={!!editingData?.is_shuffled}
                  />
                </div>
                <div className="mb-[25px] m-[10px] flex items-center gap-[10px] text-[14px] font-[500] leading-[100%]">
                  <label htmlFor="is_excluded">選択肢を除く</label>
                  <input
                    type="checkbox"
                    name="is_excluded"
                    id="is_excluded"
                    onChange={tabOptionChangeHandle}
                    checked={!!editingData?.is_excluded}
                  />
                </div>

                <div className="mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    選択肢の選択回数
                  </div>
                  <div className="choice_limit flex items-center gap-[10px] mb-[5px]">
                    <div className="text-[12px] font-[400] leading-[100%]">最小</div>
                    <input
                      type="number"
                      name="min_choice"
                      placeholder="0"
                      className="w-[71px] h-[32px] !pr-0"
                      onChange={tabOptionChangeHandle}
                      value={editingData?.min_choice || ''}
                    />
                  </div>
                  <div className="choice_limit flex items-center gap-[10px]">
                    <div className="text-[12px] font-[400] leading-[100%]">最大</div>
                    <input
                      type="number"
                      name="max_choice"
                      placeholder="0"
                      className="w-[71px] h-[32px] !pr-0"
                      onChange={tabOptionChangeHandle}
                      value={editingData?.max_choice || ''}
                    />
                  </div>
                </div>

                <div className="mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    配点初期値
                  </div>
                  <input
                    type="number"
                    name="default_score"
                    placeholder="0"
                    className="w-[71px] h-[32px]"
                    onChange={tabOptionChangeHandle}
                    value={Number(editingData?.default_score) || ''}
                  />
                </div>

                <div className="mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    リストのスタイル
                  </div>
                  <ChoiceQuestionListDisplay
                    onChangeHandle={listStyleChangeHandle}
                    selected={editingData?.list_style}
                  />
                </div>
                <div className="mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    選択肢の並び方
                  </div>
                  <div className="flex gap-[8px] items-center font-[400] text-[14px] leading-[20px] mb-[5px]">
                    <input
                      type="radio"
                      name="is_vertical_arrange"
                      value={1}
                      onChange={tabOptionChangeHandle}
                      checked={!!editingData.is_vertical_arrange}
                      id="arrange_type_vertical"
                    />
                    <label htmlFor="arrange_type_vertical">縦</label>
                  </div>

                  <div className="flex gap-[8px] items-center font-[400] text-[14px] leading-[20px]">
                    <input
                      type="radio"
                      name="is_vertical_arrange"
                      value={0}
                      onChange={tabOptionChangeHandle}
                      checked={!editingData.is_vertical_arrange}
                      id="arrange_type_horizontal"
                    />
                    <label htmlFor="arrange_type_horizontal">横</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-white">
              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
                  onClick={submitEditingDataHandle}
                >
                  <LiaSave size={23} /> 保存
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={hideEditorHandle}
                >
                  キャンセル
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer gap-[5px] flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={removeHandle}
                >
                  <PiTrash size={20} />
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChoiceQuestionEdit;
