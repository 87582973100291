import * as React from "react";

const Trash = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 6.25C3.08579 6.25 2.75 6.58579 2.75 7C2.75 7.41421 3.08579 7.75 3.5 7.75L21.5 7.75C21.9142 7.75 22.25 7.41421 22.25 7C22.25 6.58579 21.9142 6.25 21.5 6.25L17.25 6.25V3.6C17.25 2.85442 16.6456 2.25 15.9 2.25L9.1 2.25C8.35442 2.25 7.75 2.85442 7.75 3.6L7.75 6.25L3.5 6.25ZM15.75 3.75L15.75 6.25L9.25 6.25V3.75L15.75 3.75ZM6.25 11C6.25 10.5858 5.91421 10.25 5.5 10.25C5.08579 10.25 4.75 10.5858 4.75 11L4.75 20.4C4.75 21.1456 5.35441 21.75 6.1 21.75L18.9 21.75C19.6456 21.75 20.25 21.1456 20.25 20.4V11C20.25 10.5858 19.9142 10.25 19.5 10.25C19.0858 10.25 18.75 10.5858 18.75 11L18.75 20.25L6.25 20.25L6.25 11ZM11.25 11C11.25 10.5858 10.9142 10.25 10.5 10.25C10.0858 10.25 9.75 10.5858 9.75 11L9.75 17C9.75 17.4142 10.0858 17.75 10.5 17.75C10.9142 17.75 11.25 17.4142 11.25 17L11.25 11ZM14.5 10.25C14.9142 10.25 15.25 10.5858 15.25 11L15.25 17C15.25 17.4142 14.9142 17.75 14.5 17.75C14.0858 17.75 13.75 17.4142 13.75 17L13.75 11C13.75 10.5858 14.0858 10.25 14.5 10.25Z"
      fill={`${props.color || "#2E2E2E"}`}
    />
  </svg>
);

export default Trash;
