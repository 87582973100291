import React, { ChangeEvent, useState } from "react";
import { ExaminationVenueInfoType } from "../../../../types/admin/examVenue";
import { useStorage } from "../../../../hooks/useStorage";
import { useParams } from "react-router-dom";
import {
  CandidateExamGroupHistory,
  CandidateExamHistory,
  CandidateExamInfo,
  CandidateItemHistory,
} from "../../../../types/admin/candidateExamHistory";
import {
  swalError,
  swalLoading,
} from "../../../../services/helpers/swal";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { EXAMINATION_FORMAT } from "../../../../services/constants/admin/pages/examination";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import DetailExamPart from "./DetailExamPart";
import SpinnerButton from "../../commons/SpinnerButton";
import {
  TestTakerRecordProblemTest,
  TestTakerUploadProblemTest,
  WritingProblemTest,
} from "../../../../types/admin/question";
import { NAME_TYPE_QUESTION } from "../../../../services/constants/admin/pages/question";

type FetchCandidateExamDetail = {
  candidate_exam: CandidateExamHistory;
  candidate_info: CandidateExamInfo;
};

const DetailExamResult = () => {
  const [examinationVenueInfo, setExaminationVenueInfo] =
    useState<ExaminationVenueInfoType>({});
  const [candidateExam, setCandidateExam] = useState<CandidateExamHistory>({});
  const [candidateInfo, setCandidateInfo] = useState<CandidateExamInfo>({});
  const [waitingAPI, setWaitingAPI] = useState<boolean>(false);
  const storageExaminationVenueKey = "examination_venue_info";
  const { getLocalStorage } = useStorage();
  const { exam_id, exam_result_id } = useParams();

  const fetchCandidateExamDetail = async () => {
    swalLoading();
    await request.get(
      `${API.ADMIN_EXAM_RESULT.GET_CANDIDATE_EXAM_DETAIL}?candidate_exam_id=${exam_result_id}`,
      (data: FetchCandidateExamDetail) => {
        setCandidateExam(data.candidate_exam);
        setCandidateInfo(data.candidate_info);
      },
      (errors) => console.log(errors)
    );
  };

  const handleCalculatorTotalScore = (
    candidateExamPartId: number,
    problemId: number
  ) => {
    let totalScore = 0;
    const findCandidateExamPart = candidateExam.candidate_exam_parts?.find(
      (candidateExamPart) => candidateExamPart.id === candidateExamPartId
    );
    if (!findCandidateExamPart) return 0;

    findCandidateExamPart.candidate_exam_sections?.forEach(
      (candidateExamGroup) => {
        candidateExamGroup?.candidate_exam_items?.forEach(
          (candidateExamItem) => {
            candidateExamItem.problems?.forEach((problem) => {
              if (problem.id !== problemId) {
                totalScore += problem?.score || 0;
              }
            });
          }
        );
      }
    );

    return totalScore;
  };

  const handleMapNewAnswer = (
    candidateExamItem: CandidateItemHistory,
    questionId: number,
    score: number
  ) => {
    return candidateExamItem.problems?.map((answerQuestion) => {
      if (answerQuestion.id === questionId) {
        if (answerQuestion.name === NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD) {
          const parseQuestion = answerQuestion as TestTakerUploadProblemTest;

          if (parseQuestion.point && parseQuestion.point < score) {
            return answerQuestion;
          }
        }

        if (
          answerQuestion.name === NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD
        ) {
          const parseQuestion = answerQuestion as TestTakerRecordProblemTest;

          if (parseQuestion.point && parseQuestion.point < score) {
            return answerQuestion;
          }
        }

        if (answerQuestion.name === NAME_TYPE_QUESTION.WRITTEN_QUESTION) {
          const parseQuestion = answerQuestion as WritingProblemTest;

          if (parseQuestion.point && parseQuestion.point < score) {
            return answerQuestion;
          }
        }

        return { ...answerQuestion, score: score };
      }

      return answerQuestion;
    });
  };

  const handleFillScoreForAnswer = (
    examPartId: number,
    examSectionId: number,
    examItemId: number,
    questionId: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const score = Number(e.target.value);
    let totalScorePart =
      handleCalculatorTotalScore(examPartId, questionId) + score;
    let totalScoreExam = 0;

    if (isNaN(score)) return;

    const newCandidateExamParts = candidateExam.candidate_exam_parts?.map(
      (candidateExamPart) => {
        if (candidateExamPart.id === examPartId) {
          const candidateExamSections =
            candidateExamPart?.candidate_exam_sections?.map(
              (candidateExamSection) => {
                if (candidateExamSection.id === examSectionId) {
                  const candidateExamItems =
                    candidateExamSection?.candidate_exam_items?.map(
                      (candidateExamItem) => {
                        if (candidateExamItem.id === examItemId) {
                          const newFullAnswerQuestion = handleMapNewAnswer(
                            candidateExamItem,
                            questionId,
                            score
                          );

                          return {
                            ...candidateExamItem,
                            problems: newFullAnswerQuestion,
                          };
                        }

                        return candidateExamItem;
                      }
                    );

                  return {
                    ...candidateExamSection,
                    candidate_exam_items: candidateExamItems,
                  };
                }

                return candidateExamSection;
              }
            );

          totalScoreExam += totalScorePart;
          return {
            ...candidateExamPart,
            candidate_exam_sections: candidateExamSections,
            score: totalScorePart,
          };
        }

        totalScoreExam += candidateExamPart.score;
        return candidateExamPart;
      }
    );

    setCandidateExam((candidateExam) => ({
      ...candidateExam,
      candidate_exam_parts: newCandidateExamParts,
      score: totalScoreExam,
    }));
  };
  const handleSubmitSaveCandidateExam = async () => {
    setWaitingAPI(true);
    await request.patch(
      API.ADMIN_EXAM_RESULT.SAVE_RESULT_CANDIDATE_EXAM,
      {
        result_exam_id: exam_result_id,
        candidate_exam: candidateExam,
      },
      () => {},
      (errors) => {
        swalError(errors);
      },
      { withSuccess: true }
    );
    setWaitingAPI(false);
  };

  React.useEffect(() => {
    const storageExaminationVenue = getLocalStorage(storageExaminationVenueKey);
    if (!storageExaminationVenue) return;

    setExaminationVenueInfo(storageExaminationVenue);
    fetchCandidateExamDetail();
  }, []);

  return (
    <div>
      <div className="mb-[42px]">
        <div className="pb-[24px]">
          <div className="text-[13px] text-secondary-dark font-[500] px-[10px]">
            選択中の試験
          </div>
          <div className="w-full mt-[8px] pl-[21px] pt-[16px] pb-[19px] text-white text-[18px] font-[700] bg-primary-light rounded-[5px]">
            {examinationVenueInfo.exam_name}｜
            {examinationVenueInfo.exam_type_name}｜
            {examinationVenueInfo.exam_venue_name}
          </div>
        </div>
        <div className="px-[10px] text-[13px] font-[500] leading-[100%]">
          <div className="mb-[10px]">受験者名：{candidateInfo.fullname}</div>
          {candidateInfo.exam_type === EXAMINATION_FORMAT.CBT && (
            <div className="mb-[10px]">受験者コード：{candidateInfo.code}</div>
          )}

          <div className="">
            受験日付：
            {formatDateTime(
              candidateInfo.exam_date,
              TYPE_DATE_FORMAT.FULL_TO_MINUTE_DEFAULT
            )}{" "}
          </div>
        </div>
      </div>
      <div className="px-[50px]">
        {candidateExam.candidate_exam_parts &&
        candidateExam.candidate_exam_parts.length > 0 ? (
          <>
            {candidateExam.candidate_exam_parts.map((candidateExamPart) => (
              <DetailExamPart
                candidateExamPart={candidateExamPart}
                handleFillScoreAnswer={handleFillScoreForAnswer}
                key={candidateExamPart.id}
              />
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="flex justify-center">
        <SpinnerButton
          onSubmit={handleSubmitSaveCandidateExam}
          waitingAPI={waitingAPI}
        />
      </div>
    </div>
  );
};

export default DetailExamResult;
