import React from 'react';
import { Link } from 'react-router-dom';

type TabCardType = {
  tabData: {
    label: string;
    href: string;
  }[];
  tabIndex?: number;
  setTabIndex?: React.Dispatch<React.SetStateAction<number>>;
  children: React.ReactNode;
  title?: string;
  isDisplay?: boolean;
};

const TabCard = ({ tabData, tabIndex, setTabIndex, children, title, isDisplay }: TabCardType) => {

  return (
    <section className="w-full">
      <div className="flex gap-[10px] pl-[30px]">
        {!!isDisplay &&
          tabData.map((tab, index) => (
            <Link
              to={tab.href}
              className={`w-full max-w-[160px] text-center min-h-[40px] flex items-end justify-center px-[15px] rounded-t-[10px] cursor-pointer ${
                tabIndex === index
                  ? 'bg-white text-primary-darker'
                  : 'bg-success-extralight text-secondary'
              } hover:bg-white`}
              key={index}
              onClick={() => setTabIndex?.(index)}
            >
              <div
                className={`w-full border-b-[3px] ${
                  tabIndex === index ? 'border-primary-darker' : 'border-secondary-light'
                } text-[12px] font-[500] leading-[100%] tracking-[0.02] pb-[10px]`}
              >
                {tab.label}
              </div>
            </Link>
          ))}
      </div>

      <div className="bg-white rounded-[10px] w-full min-h-[100px] pb-[50px]">
        <div className="pt-[30px] px-[28px] mb-[20px]">
          <div className="border-b border-secondary-light text-secondary-dark pb-[14px] font-[500] text-[16px] leading-[100%]">
            {title}
          </div>
        </div>

        <div className="mx-[43px]">{children}</div>
      </div>
    </section>
  );
};

export default TabCard;
