import { ChangeEvent, useEffect, useRef, useState } from "react";
import Page from "../../../commons/icons/Page";
import List from "../../../commons/icons/List";
import Share from "../../../commons/icons/Share";
import Search from "../../../commons/icons/Search";
import { Question } from "../../../../types/admin/question";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import LayoutWaitingApi from "../../../commons/layouts/LayoutWaitingAPI";
import { useAuthoringExamContext } from "../../../../context/Exam/AuthoringExamContext";
import QuestionFolder from "./QuestionFolder";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { ARRANGE_TYPE } from "../../../../services/constants/admin/pages/exam";
import { ExamItemType } from "../../../../types/admin/exam";
import { PiFolderBold } from "react-icons/pi";
import { isEmptyObj } from "../../../../services/helpers/etc";
import QuestionList from "./QuestionList";

const Sidebar = () => {
  const {
    questions,
    setQuestions,
    selectedItems,
    setSelectedItems,
    showDisplayType,
    setShowDisplayType,
    selectedFolderQuestion,
    setSelectedFolderQuestion,
  } = useAuthoringExamContext();
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const [folderQuestions, setFolderQuestions] = useState<Question[]>([]);
  const [parentQuestions, setParentQuestions] = useState<Question[]>([]);
  const [showFolder, setShowFolder] = useState<boolean>(false);
  const [allFiles, setAllFiles] = useState<boolean>(false);
  const [totalFile, setTotalFile] = useState<number>(0);
  const [filterQuestion, setFilterQuestion] = useState<{
    parent_id?: Number;
    name: string;
  }>({
    name: "",
  });
  const allFilesText = "全てのファイル";

  const questionFolderRef = useRef<HTMLDivElement>(null);

  const filterQuestionByFolderId = (
    questions: Question[],
    questionId: number
  ) => {
    const filteredQuestions: Question[] = [];
    const parentQuestion = questions.find(
      (question) => question.id === questionId
    );
    if (!parentQuestion) return;

    filteredQuestions.push(parentQuestion);
    const filterQuestionsRecursively = (parentId: number | null) => {
      let childQuestions = questions.filter(
        (question) => question.parent_id === parentId
      );

      if (showDisplayType === ARRANGE_TYPE.LIST) {
        childQuestions = childQuestions.filter((question) => !question.is_file);
      }

      childQuestions.forEach((child) => {
        filteredQuestions.push(child);
        if (!child.is_file && child.id) {
          filterQuestionsRecursively(child.id);
        }
      });
    };

    filterQuestionsRecursively(questionId);
    return filteredQuestions.filter(
      (question) => question.parent_id == questionId
    );
  };

  const handleSelectedAll = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      let questionIds: number[] = [];
      let questionFiles = questions.filter((question) => question.is_file);

      if (!isEmptyObj(selectedFolderQuestion)) {
        questionFiles = questionFiles.filter((question) => {
          if (
            question.chain_parent_id?.includes(
              String(selectedFolderQuestion.id)
            )
          ) {
            return question;
          }
        });
      }

      if (filterQuestion.name) {
        questionFiles = questionFiles.filter((question) => {
          if (question.name?.includes(filterQuestion.name)) {
            return question;
          }
        });
      }

      questionFiles.forEach((question) => {
        if (!isEmptyObj(selectedFolderQuestion)) {
          if (question.parent_id !== null) questionIds.push(question.id || 0);
        } else {
          questionIds.push(question.id || 0);
        }
      });

      setSelectedItems(questionIds);
      setAllFiles(true);
    } else {
      setSelectedItems([]);
      setAllFiles(false);
    }
  };

  const handleSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const value = Number(target.value);
    if (target.checked) {
      setSelectedItems([...selectedItems, value]);
    }

    if (!target.checked) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    }
  };

  const isSelected = (questionId: ExamItemType["question_id"]) => {
    return selectedItems.includes(questionId || 0);
  };

  const handleChangeFolderQuestion = (folderQuestion: Question) => {
    setSelectedFolderQuestion(folderQuestion || {});
    setSelectedItems([]);
    setAllFiles(false);
  };

  const fetchQuestions = async () => {
    await request.get(API.ADMIN_QUESTION.LIST, (data: Question[]) => {
      setWaitingApi(false);
      setQuestions(data);
    });
  };

  useEffect(() => {
    if (questions.length > 0 && !isEmptyObj(selectedFolderQuestion)) {
      setFilterQuestion({
        ...filterQuestion,
        parent_id: selectedFolderQuestion.id || 0,
      });
      setParentQuestions(
        filterQuestionByFolderId(questions, selectedFolderQuestion?.id || 0) ||
          []
      );

      return;
    }

    if (isEmptyObj(selectedFolderQuestion)) {
      setFilterQuestion({
        ...filterQuestion,
        parent_id: 0,
      });
      setParentQuestions(questions.filter((question) => !question.parent_id));
      return;
    }
  }, [selectedFolderQuestion]);

  useEffect(() => {
    if (!questions.length) return;

    const searchEmpty = () => {
      if (isEmptyObj(selectedFolderQuestion)) {
        return questions.filter((question) => !question.parent_id);
      } else {
        return (
          filterQuestionByFolderId(
            questions,
            selectedFolderQuestion?.id || 0
          ) || []
        );
      }
    };

    const searchQuestions = () => {
      const questionFilters: Question[] = [];

      if (isEmptyObj(selectedFolderQuestion)) {
        questions.forEach((question) => {
          if (
            question.name?.includes(filterQuestion.name) &&
            question.is_file
          ) {
            questionFilters.push(question);
          }
        });
      } else {
        questions
          .filter((question) => {
            if (
              question.chain_parent_id?.includes(
                String(selectedFolderQuestion.id)
              )
            ) {
              return question;
            }
          })
          .forEach((question) => {
            if (
              question.name?.includes(filterQuestion.name.trim()) &&
              question.is_file
            ) {
              questionFilters.push(question);
            }
          });
      }

      return questionFilters;
    };

    const timer = setTimeout(() => {
      const questionsFilter: Question[] = filterQuestion.name
        ? searchQuestions()
        : searchEmpty();
      const totalQuestionFiles =
        filterQuestion.name || !isEmptyObj(selectedFolderQuestion)
          ? questionsFilter
          : questions.filter((question) => question.is_file);
      setParentQuestions(questionsFilter);
      setTotalFile(totalQuestionFiles.length);
      setSelectedItems([]);
    }, 500);

    return () => clearTimeout(timer);
  }, [filterQuestion.name, selectedFolderQuestion, questions]);

  useEffect(() => {
    fetchQuestions();

    const fetchFolderQuestions = async () => {
      await request.get(API.ADMIN_QUESTION.LIST_FOLDER, (data) => {
        setFolderQuestions(data);
      });
    };

    fetchFolderQuestions();

    const handleOutsideClick = (event: MouseEvent) => {
      if (
        questionFolderRef.current &&
        !questionFolderRef.current.contains(event.target as Node)
      ) {
        setShowFolder(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!questions.length || !selectedItems.length) {
      setAllFiles(false);
      return;
    }

    setAllFiles(totalFile === selectedItems.length);
  }, [selectedItems]);

  return (
    <div className="sidebar_exam flex-col h-auto basis-[20%] ">
      <div className="w-full">
        <div className="flex w-full gap-[10px] bg-success-extralight h-[35px] py-[5px] px-[10px] text-[14px] justify-start items-center ">
          <Page />
          問題を選択
        </div>
      </div>
      <div className="relative w-full">
        <div className="flex justify-between mt-[10px] w-full pr-[8px]">
          <div
            className={`!w-[190px] h-[38px] px-[8px] py-[7px] bg-white flex justify-between items-center cursor-pointer rounded-[3px] border border-secondary-light`}
            onClick={() => setShowFolder(!showFolder)}
          >
            <span className="max-w-[150px] text-[12px] text-secondary-dark truncate">
              {selectedFolderQuestion && selectedFolderQuestion.id
                ? selectedFolderQuestion.name
                : allFilesText}
            </span>
            <IoIosArrowDown color="#2E2E2E" />
          </div>
          <div className="flex justify-end items-center w-auto gap-[7px] ">
            <List
              className="cursor-pointer"
              color={
                showDisplayType === ARRANGE_TYPE.LIST ? "#75A1D3" : "#2E2E2E"
              }
              onClick={() => setShowDisplayType(ARRANGE_TYPE.LIST)}
            />
            <div className="w-[1px] h-[25px] border-[1px] border-secondary-light"></div>
            <Share
              className="cursor-pointer"
              color={
                showDisplayType === ARRANGE_TYPE.TREE ? "#75A1D3" : "#2E2E2E"
              }
              onClick={() => setShowDisplayType(ARRANGE_TYPE.TREE)}
            />
          </div>
        </div>
        {showFolder && (
          <div
            className="absolute top-[38px] left-0 w-full max-w-[260px] bg-white z-[100] shadow-md border"
            ref={questionFolderRef}
          >
            <div className="bg-white">
              <div
                className={`hover:bg-primary-extralight px-[5px] text-secondary-light text-[22px] flex gap-[5px] cursor-pointer items-center h-[30px]`}
              >
                <div className="text-[18px]">
                  <IoIosArrowForward />
                </div>
                <div className="text-[22px]">
                  <PiFolderBold />
                </div>
                <div
                  className="w-full flex justify-between items-center text-[12px] text-secondary-dark font-[500] leading-[100%] whitespace-nowrap truncate"
                  onClick={() => handleChangeFolderQuestion({})}
                >
                  <div className="w-[170px] truncate">{allFilesText}</div>
                </div>
              </div>
              {folderQuestions.map((question, index) => (
                <QuestionFolder
                  question={question}
                  key={index}
                  handleChangeFolderQuestion={handleChangeFolderQuestion}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between items-center w-full mt-[10px]">
        <div className="w-[190px] relative input_search_exam border border-secondary-light rounded-[5px]">
          <div className="absolute inset-y-0 left-[-8px] pl-3 flex items-center pointer-events-none">
            <Search />
          </div>
          <input
            type="text"
            className="!w-[188px] !pl-[30px] !border-none !rounded-[2px] !shadow-none !ring-1 ring-secondary-light"
            onChange={(e) =>
              setFilterQuestion({ ...filterQuestion, name: e.target.value })
            }
          />
        </div>
        <div className="w-auto pr-[10px]">
          <input
            type="checkbox"
            className="input_check_red checked:ring-transparent focus:ring-transparent w-[24px] h-[24px] rounded-[4px] cursor-pointer"
            onChange={handleSelectedAll}
            checked={allFiles}
          />
        </div>
      </div>
      {showDisplayType === ARRANGE_TYPE.LIST && <div></div>}
      <LayoutWaitingApi
        waitingApi={waitingApi}
        className="w-[270px] h-[600px] mt-[10px]"
      >
        <QuestionList
          handleSelected={handleSelected}
          isSelected={isSelected}
          parentQuestions={parentQuestions}
          searchQuestion={filterQuestion.name}
        />
      </LayoutWaitingApi>
    </div>
  );
};

export default Sidebar;
