import React from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import Pagination from '../../commons/Pagination';
import { Link } from 'react-router-dom';
import { Folder } from '../../../../types/admin/folder';
import { RiFileAddLine } from 'react-icons/ri';

const FolderTable = ({
  handlePageChange,
  currentPage,
  toggleSelect,
  selectAll,
  toggleSelectAll,
  isFolderSelected,
  folders,
  totalPages,
  setIsOpenUpdateModal,
  setUpdateFolderId,
  setUpdateFolderName,
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  toggleSelect: (id: number) => void;
  selectAll: boolean;
  toggleSelectAll: () => void;
  isFolderSelected: (id: number) => boolean;
  folders: Folder[];
  totalPages: number;
  setIsOpenUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateFolderId: React.Dispatch<React.SetStateAction<number | null>>;
  setUpdateFolderName: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  return (
    <>
      <div className="flex items-center justify-center overflow-hidden animate-[show_0.5s_ease-in-out]">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <th
                scope="col"
                className="2xl:px-[10px] xl:px-[5px] lg:px-0 px-0 py-[2px] border-x-4 border-white whitespace-nowrap "
              >
                <input type="checkbox" checked={selectAll} onChange={toggleSelectAll} />
              </th>
              <th
                scope="col"
                className="2xl:px-[180px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                カテゴリ名
              </th>
              <th
                scope="col"
                className="2xl:px-[10px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                「ファイル追加・管理」
              </th>
              <th
                scope="col"
                className="2xl:px-[43px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                カテゴリID
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                登録ファイル数
              </th>
              <th
                scope="col"
                className="2xl:px-[10px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                編集
              </th>
            </tr>
          </thead>
          <tbody>
            {folders.map((folder) => (
              <tr
                key={folder.id}
                className="bg-white border-b w-full text-[12px] font-[400] text-[#2E2E2E]"
              >
                <td className="text-center">
                  <input
                    type="checkbox"
                    checked={isFolderSelected(folder.id)}
                    onChange={() => toggleSelect(folder.id)}
                  />
                </td>
                <th className="px-6 py-4 text-[14px] font-[400] whitespace-nowrap">
                  {folder.name}
                </th>
                <td className="">
                  <div className="flex justify-center items-center">
                    <Link
                      to={`./file?folder_id=${folder.id}`}
                      className="flex justify-center items-center text-primary-darker gap-[3px] border border-primary-darker rounded-[5px] w-fit h-[30px] px-[3px]"
                    >
                      <div className="">
                        <RiFileAddLine size={22} />
                      </div>
                      <div className="text-[12px] font-[500] leading-[17.38px] tracking-[2%]">
                        「ファイル追加・管理」
                      </div>
                    </Link>
                  </div>
                </td>
                <td className="px-6 py-4 text-center">{folder.code}</td>
                <td className="px-6 py-4 text-right">{folder.file_quantity}</td>
                <td className="text-center">
                  <button
                    className="min-w-[50px] text-[14px] px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-primary-darker text-white"
                    onClick={() => {
                      setUpdateFolderId(folder.id);
                      setUpdateFolderName(folder.name);
                      setIsOpenUpdateModal(true);
                    }}
                  >
                    編集
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {totalPages !== 0 && (
        <Pagination
          totalItems={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default FolderTable;
