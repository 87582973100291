import Sidebar from "./Sidebar";
import ExamAuthoring from "./ExamAuthoring/ExamAuthoring";
import { useAuthoringExamContext } from "../../../../context/Exam/AuthoringExamContext";
import SpinnerButton from "../../commons/SpinnerButton";

const CreateAuthoringExam = () => {
  const { handleSubmit, waitingAPI } = useAuthoringExamContext();
  
  return (
    <div>
      <div className="exam flex gap-[20px] w-full">
        <Sidebar />
        <ExamAuthoring />
      </div>
      <div className="w-full my-[20px] flex justify-center">
        <SpinnerButton waitingAPI={waitingAPI} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default CreateAuthoringExam;
