import { NAME_TYPE_QUESTION } from "../../../../../../services/constants/admin/pages/question";
import { swalError } from "../../../../../../services/helpers/swal";
import { getFileTypeRestricted } from "../../../../../../services/utils/admin/question";
import { MediaToolProblem } from "../../../../../../types/admin/question";
import ResourceContentDisplay from "../../../../../candidate/common/ResourceContentDisplay";
import Audio from "../../../../../commons/icons/QuestionType/Audio";
import Image from "../../../../../commons/icons/QuestionType/Image";
import Video from "../../../../../commons/icons/QuestionType/Video";


type MediaToolProblemType = {
  questionProblem: MediaToolProblem;
  handleUpdateProblem: (problem: MediaToolProblem) => void;
};

const MediaToolQuestion = ({
  questionProblem,
  handleUpdateProblem,
}: MediaToolProblemType) => {
  let fileTypeRestricted: number = getFileTypeRestricted(
    questionProblem?.name || ""
  );
  let icon = <></>;
  let label = "";
  let previewRender = <></>;

  switch (questionProblem.name) {
    case NAME_TYPE_QUESTION.IMAGE:
      icon = <Image />;
      label = "画像を追加";

      if (!questionProblem?.media_url) break;

      previewRender = (
        <div className="media_tool flex justify-center">
          <img
            src={questionProblem?.media_url}
            alt={questionProblem?.media_name}
            style={{
              maxWidth: `${
                questionProblem?.media_width
                  ? `${questionProblem?.media_width}px`
                  : "100%"
              }`,
              width: `${
                questionProblem?.media_width
                  ? `${questionProblem?.media_width}px`
                  : "100%"
              }`,
              height: `${
                questionProblem?.media_height
                  ? `${questionProblem?.media_height}px`
                  : "100%"
              }`,
            }}
          />
        </div>
      );
      break;

    case NAME_TYPE_QUESTION.AUDIO:
      icon = <Audio />;
      label = "音声を追加";

      if (!questionProblem?.media_url) break;
      previewRender = (
        <div className="flex justify-center my-[30px]">
          <ResourceContentDisplay
            url={questionProblem?.media_url}
            resourceType={fileTypeRestricted}
          />
        </div>
      );
      break;

    case NAME_TYPE_QUESTION.VIDEO:
      icon = <Video />;
      label = "映像を追加";

      if (!questionProblem?.media_url) break;
      previewRender = (
        <div className="flex justify-center my-[20px] mb-[40px]">
          <ResourceContentDisplay
            url={questionProblem?.media_url}
            resourceType={fileTypeRestricted}
          />
        </div>
      );
      break;
    default:
      swalError();
      return <></>;
  }
  
  return (
    <div className="">
      {!questionProblem?.media_url ? (
        <div
          className={`flex flex-col justify-center items-center gap-[7px] my-[8px] text-[#6A6D6F]`}
        >
          <div className="">{icon}</div>
          <div className="text-[12px] font-[500] leading-[16px] tracking-[0.5px] z-[999] cursor-pointer">
            {label}
          </div>
        </div>
      ) : (
        <div className={""}>{previewRender}</div>
      )}
    </div>
  );
};

export default MediaToolQuestion;
