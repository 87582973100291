import React from "react";
import ExamResultBase from ".";
import ExamList from "../../../components/admin/ExamResult/ExamList";
import ExamResultExamListProvider from "../../../context/Admin/ExamResultExamListContext";

const ResultExamListPage = () => {
  return (
    <ExamResultExamListProvider>
      <ExamResultBase title="結果管理 ｜ 受験一覧">
        <ExamList />
      </ExamResultBase>
    </ExamResultExamListProvider>
  );
};

export default ResultExamListPage;
