const ADMIN_MENU = {
    LOGOUT: 'ログアウト',
  };
  
  const ADMIN_MENU_DATA = [
    {
      label: ADMIN_MENU.LOGOUT, 
      href: '/logout'
    },
  ]
  
  const ADMIN_MENU_BORDER_INDEX = [0, 3, 7, 10];
  
  export { ADMIN_MENU, ADMIN_MENU_BORDER_INDEX, ADMIN_MENU_DATA };