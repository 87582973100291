import { WritingProblem } from '../../../../../../../types/admin/question';
import { RenderDroppedQuestionTypeProps } from '../../DroppedQuestionType';

type TextBlockQuestionProps = {
  problemPreview?: WritingProblem;
} & Omit<RenderDroppedQuestionTypeProps, 'draggedName' | 'problemPreview'>;

const TextBlockQuestion = ({ problemPreview }: TextBlockQuestionProps) => {
  return (
    <div id="letter_question" className={`w-[100%] h-fit flex items-center gap-y-[20px]`}>
      <div className={`flex items-center justify-center w-full`}>
        <div
          className={`w-full`}
          dangerouslySetInnerHTML={{
            __html: problemPreview?.title || '',
          }}
        ></div>
      </div>
    </div>
  );
};
export default TextBlockQuestion;
