import React from "react";

const GreenBlock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="194"
      height="205"
      viewBox="0 0 194 205"
      fill="none"
    >
      <path
        opacity="0.8"
        d="M205 0H0V205H102.477C158.998 205 204.953 159.044 204.953 102.523C204.953 100.84 204.907 99.2041 204.813 97.5679C204.813 96.7731 204.813 96.0251 204.953 95.2303V0.0467246L205 0Z"
        fill="#86B45F"
      />
    </svg>
  );
};

export default GreenBlock;
