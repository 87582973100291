import React from "react";
import { IoMdDownload } from "react-icons/io";
import SemiSquareButton from "../commons/SemiSquareButton";
const CandidateCSV = () => {
  return (
    <div>
      <div className="ml-[40px] font-[500] text-[14px]">登録手順</div>
      <section className="w-full pl-[55px] pt-[40px] mb-[60px]">
        <div className="font-[700] text-[16px] text-primary">
          STEP１　変更用CSVファイルのダウンロード
        </div>
        <ul className="list-disc list-outside list-inside py-2 pl-[20px] sm:pl-[30px] max-w-[50%] leading-[190%] mb-[30px] ">
          <li className="text-[12px] font-[400]">
            これは正式な文章の代わりに入れて使うダミーテキストです。
          </li>
          <li className="text-[12px] font-[400]">
            主に書籍やウェブページなどのデザインを作成する時によく使われます。
          </li>
          <li className="text-[12px] font-[400]">
            これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。ダミーテキストはダミー文書やダミー文章とも呼ばれることがあります。このダミーテキストは自由に改変出来ます。
          </li>
        </ul>
        <div className="flex max-w-[50%] gap-x-[200px] pl-[20px] mb-[30px]">
          <div>
            <div className="text-[14px] font-[700] text-[#7A7A7A] mb-[5px]">
              ●新規の受講者を追加登録する場合
            </div>
            <button
              className={`bg-primary text-white text-[12px] font-[700] leading-[17px] flex items-end px-[20px] rounded-[20px] min-h-[28px] cursor-pointer bg-primary text-[14px] py-[10px]`}
            >
              {" "}
              <IoMdDownload size={18} />
              <div className="ml-[7px] whitespace-nowrap">
                テンプレートCSVをダウンロード
              </div>
            </button>
          </div>
          <div>
            <div className="text-[14px] font-[700] text-[#7A7A7A] mb-[5px]">
              ●登録済みの受講者情報を一括編集する場合
            </div>
            <button
              className={`bg-primary text-white text-[12px] font-[700] leading-[17px] flex items-end px-[20px] rounded-[20px] min-h-[28px] cursor-pointer bg-primary text-[14px] py-[10px]`}
            >
              {" "}
              <IoMdDownload size={18} />
              <div className="ml-[7px] whitespace-nowrap">
                形式を指定して受講者CSVをダウンロード
              </div>
            </button>
          </div>
        </div>
      </section>
      <hr className="w-[97%] m-auto border-b bg-black" />
      <section className="w-full pl-[55px] mt-[40px] mb-[40px]">
        <div className="font-[700] text-[16px] text-primary">
          STEP２　CSVファイルの編集
        </div>
        <ul className="list-disc list-outside list-inside py-2 pl-[20px] sm:pl-[30px] max-w-[50%] leading-[190%]">
          <li className="text-[12px] font-[400]">
            これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。ダミーテキストはダミー文書やダミー文章とも呼ばれることがあります。このダミーテキストは自由に改変出来ます。
          </li>
          <li className="text-[12px] font-[400]">
            これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。ダミーテキストはダミー文書やダミー文章とも呼ばれることがあります。このダミーテキストは自由に改変出来ます。
          </li>
        </ul>
      </section>
      <hr className="w-[97%] m-auto border-b bg-black" />
      <section className="w-full pl-[55px] mt-[40px]">
        <div className="font-[700] text-[16px] text-primary">
          STEP３　CSVファイルをアップロード
        </div>
        <ul className="list-disc list-outside list-inside py-2 pl-[20px] sm:pl-[30px] max-w-[50%] leading-[190%]">
          <li className="text-[12px] font-[400]">
            これは正式な文章の代わりに入れて使うダミーテキストです。
          </li>
          <li className="text-[12px] font-[400]">
            主に書籍やウェブページなどのデザインを作成する時によく使われます。
          </li>
          <li className="text-[12px] font-[400]">
            これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。ダミーテキストはダミー文書やダミー文章とも呼ばれることがあります。このダミーテキストは自由に改変出来ます。
          </li>
        </ul>
        <div className="max-w-[50%] pl-[15px] mb-[5px]">
          <div className="text-[14px] font-[700] text-[#7A7A7A] mb-[5px]">
            CSVファイルを選択
          </div>
        </div>
        <div className="h-[60px] w-[400px] bg-[#FAF7F0] flex items-center px-2 gap-x-[10px] mb-[15px]">
          <button className="w-[105px] h-[38px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-[#BEBEBE]">
            ファイルを選択
          </button>
          <p className="text-[10px] font-[400]">選択されていません</p>
        </div>
        <SemiSquareButton>一括登録を実行</SemiSquareButton>
      </section>
    </div>
  );
};

export default CandidateCSV;
