import React from 'react';
import type { FC } from 'react';
import classNames from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  otherButtonStyle: string;
  children?: JSX.Element;
}

const ButtonHome: FC<ButtonProps> = ({
                                       otherButtonStyle,
                                       ...props
                                     }) => {
  return (
    <button
      {...props}
      className={
        classNames({
          "font-semibold": true,
          [`${otherButtonStyle}`]: otherButtonStyle !== null
        })
      }
    >
      <span className="align-middle flex justify-center items-center">
        {props.children}
      </span>
    </button>
  )
}

export default ButtonHome
