import React, { useEffect, useRef, useState } from "react";
import cloud_upload from "../../../assests/images/icons/cloud_upload.svg";
import { BiSearchAlt2 } from "react-icons/bi";
import { UNIT_FILE_TYPE } from "../../../services/constants/admin/pages/material";
import { request } from "../../../services/axios/axios";
import { Folder, File as FolderFile } from "../../../types/admin/folder";
import { API } from "../../../services/constants/route/api";
import FileSearchItem from "../Material/Upsert/FileSearchItem";
import { S3 } from "aws-sdk";
import { swalError, swalLoading } from "../../../services/helpers/swal";
import { getSubdomainFromUrl } from "../../../services/helpers/domain";
import { Question, QuestionAnswers } from "../../../types/admin/unit";
import {
  MESSAGE_COMMON,
  MESSAGE_ERROR,
} from "../../../services/constants/message";
import ProgressBar from "../../candidate/common/ProgressBar";
import { Resource } from "../../../types/admin/resource";
import { useAuthContext } from "../../../context/AuthContext";
import { getUploadFilePath } from "../../../services/helpers/uploadFIle";

const bytesToMegabytes = (bytes: number) => {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2);
};
type UploadQuestionImageProps = {
  fileType?: number | null;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAnswers?: React.Dispatch<React.SetStateAction<QuestionAnswers[]>>;
  setQuestionForm?: React.Dispatch<React.SetStateAction<Question>>;
  isAttatchment?: boolean;
  index?: number;
  setResourceLink?: React.Dispatch<React.SetStateAction<string>>;
  setResource?: React.Dispatch<React.SetStateAction<Resource>>;
};
type FileTypeConfig = {
  label: string;
  mimeTypes: string[];
  maxSize: number;
};
const fileTypes: { [key: string]: FileTypeConfig } = {
  4: {
    label: "pdf",
    mimeTypes: ["application/pdf"],
    maxSize: 100 * 1024 * 1024,
  }, // 100MB
  5: {
    label: "image",
    mimeTypes: ["image/jpeg", "image/png"],
    maxSize: 10 * 1024 * 1024,
  }, // 10MB
  2: {
    label: "video",
    mimeTypes: ["video/mp4", "video/quicktime"],
    maxSize: 2 * 1024 * 1024 * 1024,
  }, // 2GB
  3: {
    label: "mp3",
    mimeTypes: ["audio/mpeg"],
    maxSize: 2 * 1024 * 1024 * 1024,
  }, // 1GB
};

const UploadQuestionImage = ({
  fileType,
  setIsOpen,
  setAnswers,
  setQuestionForm,
  isAttatchment,
  index,
  setResourceLink,
  setResource,
}: UploadQuestionImageProps) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [error, setError] = useState<string | null>("");
  const [folderList, setFolderList] = useState<Folder[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [searchData, setSearchData] = useState<FolderFile[]>([]);
  const [originFilename, setOriginFilename] = useState<string>("");
  const [folderId, setFolderId] = useState<string | number | null>("");
  const [folderCode, setFolderCode] = useState<number | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { user } = useAuthContext();
  const oz_id = user.organization_id;
  const [typeFile, setTypeFile] = useState<number>(() => {
    switch (fileType) {
      case UNIT_FILE_TYPE.TYPE_PDF:
        return UNIT_FILE_TYPE.TYPE_PDF;
      case UNIT_FILE_TYPE.TYPE_IMAGE:
        return UNIT_FILE_TYPE.TYPE_IMAGE;
      case UNIT_FILE_TYPE.TYPE_VIDEO:
        return UNIT_FILE_TYPE.TYPE_VIDEO;
      case UNIT_FILE_TYPE.TYPE_AUDIO:
        return UNIT_FILE_TYPE.TYPE_AUDIO;
      default:
        return UNIT_FILE_TYPE.TYPE_AUDIO;
    }
  });

  useEffect(() => {
    const fetchDetail = async () => {
      await request.get(
        `${API.ADMIN_FOLDER.LIST}?sortType=1&limit=1000`,
        (folderResults) =>
          setFolderList([...folderList, ...folderResults.resource_folders])
      );
    };

    fetchDetail();
  }, []);

  useEffect(() => {
    if (!originFilename) return;

    const fetchFileData = async () => {
      await request.get(
        `${
          API.ADMIN_FOLDER.FILE
        }?resource_folder_id=${folderId}&origin_filename=${originFilename}&resource_type=${
          fileType ? fileTypes[fileType].label : ""
        }`,
        (fileResults) => setSearchData(fileResults.resources)
      );
    };

    const timer = setTimeout(() => {
      fetchFileData();
    }, 500);
    return () => clearTimeout(timer);
  }, [originFilename, folderId]);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setError(null);
    const files = event.dataTransfer.files;
    handleFiles(files);
  };
  const handleFolderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") setFolderId("");
    const [id, code] = e.target.value.split("|");
    setFolderId(id);
    setFolderCode(Number(code));
  };
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleFiles = async (files: FileList | null) => {
    if (!files) return;
    if (!folderCode) {
      setError(`ファイルをアップロードする用カテゴリを選択してください。`);
      return;
    }
    const file = files[0];

    const fileTypeConfig = fileType ? fileTypes[fileType] : null;
    if (!fileTypeConfig) {
      setError(MESSAGE_COMMON.INVALID_FILE_TYPE);
      return;
    }
    const allowedMimeTypes = fileTypeConfig.mimeTypes;
    const maxSize = fileTypeConfig.maxSize;

    if (!allowedMimeTypes.includes(file.type)) {
      setError(MESSAGE_COMMON.INVALID_FILE_TYPE);
      return;
    }
    if (file.size > maxSize) {
      setError(MESSAGE_ERROR.FILE_SIZE_EXCEEDS_THE_ALLOWED_LIMIT);
      return;
    }

    const s3 = new S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    const fileName = `${Date.now()}.${fileExtension}`;
    const folderPath = getUploadFilePath(oz_id, folderCode);
    let contentType = "";
    switch (fileExtension) {
      case "pdf":
        contentType = "application/pdf";
        break;
      case "mp3":
        contentType = "audio/mpeg";
        break;
      case "mp4":
        contentType = "video/mp4";
        break;
      case "jpg":
      case "jpeg":
        contentType = "image/jpeg";
        break;
      case "png":
        contentType = "image/png";
        break;
      default:
        contentType = "application/octet-stream";
        break;
    }

    setError("");

    const uploadS3 = await s3
      .putObject({
        Bucket: process.env.REACT_APP_AWS_BUCKET || "",
        Key: folderPath + fileName,
        Body: file,
        ContentType: contentType,
        ACL: "public-read",
      })
      .on("httpUploadProgress", (progress) => {
        if (progress && progress.loaded && progress.total) {
          setUploadProgress(
            Math.round((progress.loaded / progress.total) * 100)
          );
        }
      })
      .promise();

    let time = null;
    if (fileExtension === "mp3" || fileExtension === "mp4") {
      const mediaElement = document.createElement("audio");
      mediaElement.src = URL.createObjectURL(file);

      await new Promise<void>((resolve, reject) => {
        mediaElement.addEventListener("loadedmetadata", () => {
          time = Math.round(mediaElement.duration * 1000);
          resolve();
        });
        mediaElement.addEventListener("error", () => {
          reject();
        });
      });

      URL.revokeObjectURL(mediaElement.src);
    }
    const data = {
      resource_folder_id: folderId,
      filename: fileName,
      origin_filename: file.name,
      content_type: fileExtension,
      size: bytesToMegabytes(file.size),
      path: `${folderPath}${fileName}`,
    };

    if (time) {
      (data as any).time = time;
    }

    await request.post(
      API.ADMIN_FOLDER.UPLOAD_FILE,
      data,
      (resourceFile) => {
        if (setResource) {
          setResource(resourceFile);
        }

        if (isAttatchment && setQuestionForm) {
          setQuestionForm((prevQuestion) => ({
            ...prevQuestion,
            origin_filename: resourceFile.origin_filename,
            question_resource_id: resourceFile.id,
            resource_data: { resource_link: resourceFile.resource_link },
          }));
          return;
        }

        if (setAnswers && index !== undefined) {
          setAnswers((prevAnswers) => {
            const updatedAnswers = [...prevAnswers];
            updatedAnswers[index] = {
              ...updatedAnswers[index],
              origin_filename: resourceFile.origin_filename,
              answer_resource_link: resourceFile.resource_link,
              answer_resource_id: resourceFile.id,
            };
            return updatedAnswers;
          });
        }
        if (setResourceLink) {
          setResourceLink(resourceFile.resource_link);
        }
      },
      async () => {
        await s3
          .deleteObject({
            Bucket: process.env.REACT_APP_AWS_BUCKET || "",
            Key: folderPath + fileName,
          })
          .promise();
        return swalError();
      },
      { withSuccess: true, withLoading: false }
    );

    setIsOpen(false);
  };

  return (
    <section className="w-full px-[245px] relative z-[1000]">
      <div
        className={`flex justify-center  gap-[10px] ${
          tabIndex === 0 ? "pt-[75px]" : "pt-[145px]"
        }`}
      >
        {["ファイルをアップロード", "ファイル管理から選択"].map(
          (tab, index) => (
            <div
              className={`w-full min-w-[250px] text-center min-h-[40px] flex items-end justify-center px-[15px] rounded-t-[10px] cursor-pointer ${
                tabIndex === index
                  ? "bg-primary text-white"
                  : "bg-success-extralight text-secondary"
              }`}
              key={index}
              onClick={() => setTabIndex?.(index)}
            >
              <div
                className={`w-full border-b-[3px] ${
                  tabIndex === index
                    ? "border-primary"
                    : "border-secondary-light"
                } border-primary text-[14px] font-[500] leading-[100%] tracking-[0.02] pb-[10px]`}
              >
                {tab}
              </div>
            </div>
          )
        )}
      </div>
      {tabIndex === 0 && (
        <div className="w-full py-[30px]">
          <div className="font-[700] text-[16px] text-primary flex justify-center mb-[20px]">
            ファイルをアップロード
          </div>
          <div className="font-[400] text-[14px] mb-[8px]">
            ファイルを格納する【ファイル管理のカテゴリ】を選択
          </div>
          <select
            className="h-[40px] pr-[33px] pl-[15.5px] mb-[20px] appearance-none text-secondary-dark font-[400] text-[12px] leading-[100%] w-full "
            name="group_ids"
            onChange={handleFolderChange}
          >
            <option value="">選択してください</option>
            {folderList.map((folder) => (
              <option key={folder.id} value={`${folder.id}|${folder.code}`}>
                {folder.name}
              </option>
            ))}
          </select>
          <div
            className="min-h-[170px] w-full flex flex-col items-center border border-dashed border-secondary-light py-[20px] mb-[20px]"
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
          >
            <img
              src={cloud_upload}
              width={44}
              className="max-h-[30.78px] mb-[26px]"
              alt=""
            />
            <input
              ref={fileInputRef}
              id="file-input"
              type="file"
              className="hidden"
              onChange={handleFileInputChange}
              accept={fileTypes[typeFile].mimeTypes.join(",")}
            />
            <div className="text-[14px] font-[400] text-center mb-[15px]">
              ファイルを選択、またはドラッグ＆ドロップ
            </div>
            {uploadProgress ? (
              <>
                <div className="text-center mb-[5px]">
                  アップロード中: {uploadProgress}%
                </div>
                <div className="flex justify-center items-center">
                  <ProgressBar
                    width={470}
                    height={15}
                    progress={uploadProgress}
                  />
                </div>
              </>
            ) : (
              <button
                className={`w-full max-w-[116px] min-h-[37px] text-white rounded-[5px] px-[16px] py-[10px] text-[12px] font-[700] ${
                  !!folderList.length ? "bg-primary" : "bg-secondary-light"
                }`}
                type="button"
                onClick={() => fileInputRef.current?.click()}
              >
                ファイルを選択
              </button>
            )}
            {/* <button
              type="button"
              className={`w-full max-w-[116px] min-h-[37px] text-white rounded-[5px] px-[16px] py-[10px] text-[12px] font-[700] ${
                !!folderList.length ? 'bg-primary' : 'bg-secondary-light'
              }`}
              onClick={() => fileInputRef.current?.click()}
            >
              ファイルを選択
            </button> */}
            {error !== "" && (
              <div className="text-danger mt-[10px]">{error}</div>
            )}
          </div>
          <div className="text-[12px] font-[400] mb-[94px]">
            ・アップロードできる動画、音声ファイル容量の上限は2GBです。
            <br />
            ・スライドPDFのファイル容量は3MB以下を推奨しています（上限100MB）
            <br />
            ・視聴動画の推奨サイズは509x300px以下です（上限10MB）
            <br />
            ・ファイルは1つずつ選択してアップロードをしてください（複数選択不可）
            <br />
          </div>
        </div>
      )}
      {tabIndex === 1 && (
        <div className="w-full py-[30px]">
          <div className="font-[700] text-[16px] text-primary flex justify-center mb-[40px]">
            「ファイル管理」にアップロードされた教材ファイルから選択
          </div>
          <div className="font-[400] text-[14px] mb-[8px]">カテゴリを選択</div>
          <select
            className="h-[40px] pr-[33px] pl-[15.5px] mb-[20px] appearance-none text-secondary-dark font-[400] text-[12px] leading-[100%] w-full "
            name="group_ids"
            onChange={handleFolderChange}
          >
            <option value="">選択してください</option>
            {folderList.map((folder) => (
              <option key={folder.id} value={`${folder.id}|${folder.code}`}>
                {folder.name}
              </option>
            ))}
          </select>
          <div className="font-[400] text-[14px] mb-[8px]">
            ファイル名で検索
          </div>
          <div className="relative">
            <input
              type="text"
              name="originFilename"
              id=""
              className="w-full h-[40px] pl-[12px] placeholder-[#BEBEBE] text-[14px] font-[400] leading-[100%] border-[#E1E3E2] rounded-[5px]"
              placeholder="ファイル名を入力"
              onChange={(e) => setOriginFilename(e.target.value)}
              value={originFilename}
            />
            <BiSearchAlt2
              className="absolute top-[7px] right-[9.26px] text-secondary-light"
              size={22}
            />
            <div className="absolute top-12 left-0 z-[1000] max-h-[250px] overflow-y-auto w-full bg-white shadow-2xl rounded-[5px] text-left pl-[10px]">
              {originFilename &&
                searchData &&
                searchData.map((file) => (
                  <FileSearchItem
                    key={file.id}
                    file={file}
                    className="!max-w-[493px] !min-h-[50px] rounded-[5px] mb-[5px] !justify-start !cursor-pointer hover:bg-secondary-lighter"
                    onClick={() => {
                      if (isAttatchment && setQuestionForm) {
                        setQuestionForm((prevQuestion) => ({
                          ...prevQuestion,
                          origin_filename: file.origin_filename,
                          question_resource_id: file.id,
                          resource_data: { resource_link: file.resource_link },
                        }));
                      } else if (setResource) {
                        setResource(file || {});
                      } else {
                        if (!setResourceLink || !file?.resource_link) return;

                        setResourceLink(file?.resource_link);
                      }
                      setOriginFilename("");
                      setSearchData([]);
                      setIsOpen(false);
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default UploadQuestionImage;
