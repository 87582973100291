import { useState } from 'react';
import GroupTitle from '../Title';
import Form from './Form';

const Setting = () => {
  const [title, setTitle] = useState<string>('');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  return (
    <section>
      <GroupTitle
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        content={title}
        withSubtitle
      />
      <div className="">
        <Form setTitle={setTitle} />
      </div>
    </section>
  );
};

export default Setting;
