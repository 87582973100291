import React from "react";

const Microphone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 16.9102C13.1935 16.9102 14.3381 16.4361 15.182 15.5921C16.0259 14.7482 16.5 13.6036 16.5 12.4102V6.41016C16.5 5.21668 16.0259 4.07209 15.182 3.22818C14.3381 2.38426 13.1935 1.91016 12 1.91016C10.8065 1.91016 9.66193 2.38426 8.81802 3.22818C7.97411 4.07209 7.5 5.21668 7.5 6.41016V12.4102C7.5 13.6036 7.97411 14.7482 8.81802 15.5921C9.66193 16.4361 10.8065 16.9102 12 16.9102Z"
        fill={`${props.color || "#FA646A"}`}
      />
      <path
        d="M18.7888 12.4184C18.5926 12.3972 18.396 12.4543 18.2416 12.5771C18.0871 12.7 17.9873 12.8787 17.9638 13.0746C17.7991 14.5407 17.1 15.8948 16.0001 16.878C14.9002 17.8613 13.4766 18.4049 12.0013 18.4049C10.526 18.4049 9.10237 17.8613 8.00247 16.878C6.90257 15.8948 6.20349 14.5407 6.0388 13.0746C6.01527 12.8787 5.91547 12.7 5.76104 12.5771C5.60661 12.4543 5.40999 12.3972 5.2138 12.4184C5.11547 12.4277 5.01999 12.4566 4.93298 12.5033C4.84598 12.5501 4.76921 12.6138 4.70718 12.6907C4.64516 12.7675 4.59914 12.856 4.57184 12.951C4.54453 13.0459 4.53648 13.1453 4.54818 13.2434C4.73999 14.9451 5.5105 16.5292 6.73073 17.7307C7.95096 18.9321 9.54686 19.678 11.2513 19.8434V22.159C11.2513 22.3579 11.3303 22.5487 11.471 22.6893C11.6116 22.83 11.8024 22.909 12.0013 22.909C12.2002 22.909 12.391 22.83 12.5316 22.6893C12.6723 22.5487 12.7513 22.3579 12.7513 22.159V19.8434C14.4557 19.678 16.0516 18.9321 17.2719 17.7307C18.4921 16.5292 19.2626 14.9451 19.4544 13.2434C19.4661 13.1453 19.4581 13.0459 19.4308 12.951C19.4035 12.856 19.3574 12.7675 19.2954 12.6907C19.2334 12.6138 19.1566 12.5501 19.0696 12.5033C18.9826 12.4566 18.8871 12.4277 18.7888 12.4184Z"
        fill={`${props.color || "#FA646A"}`}
      />
    </svg>
  );
};

export default Microphone;
