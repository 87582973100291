/* eslint-disable react-hooks/exhaustive-deps */
import { TbFolderOpen } from 'react-icons/tb';
import { Question } from '../../../../types/admin/question';
import { PiFolderBold } from 'react-icons/pi';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { useQuestionListContext } from '../../../../context/Question/ListQuestionContext';
import { isUsableArr } from '../../../../services/helpers/etc';
import HalfPlus from '../../../commons/icons/HalfPlus';
import { FiFileText } from 'react-icons/fi';

type ListItemProps = {
  question: Question;
};

const DetailTargetFinderItem = ({ question }: ListItemProps) => {
  const [isExpand, setIsExpand] = useState(false);
  const { questions, selectedQuestion, selectedTargetQuestion, setSelectedTargetQuestion } =
    useQuestionListContext();

  const childQuestions = questions.filter(
    (childQuestion) =>
      childQuestion.parent_id === question.id && childQuestion.id !== selectedQuestion.id,
  );

  const handleClick = () => {
    setIsExpand(!isExpand);
    setSelectedTargetQuestion(question.id);
  };

  return (
    <div className="">
      <div
        className={`hover:bg-primary-extralight px-[5px] text-secondary-light text-[22px] flex gap-[5px] cursor-pointer items-center ${
          !question.parent_id ? 'mb-[14px]' : 'mb-[4px]'
        } h-[30px] ${
          selectedTargetQuestion.id === question.id ? 'bg-primary-extralight rounded-[2px]' : ''
        }`}
        onClick={handleClick}
      >
        {question.is_file ? (
          <HalfPlus className="mx-[5px] shrink-0" />
        ) : (
          <div className="text-[18px]">{isExpand ? <IoIosArrowDown /> : <IoIosArrowForward />}</div>
        )}

        {question.is_file ? (
          <FiFileText className="mx-[2px] text-[19px] text-primary shrink-0" />
        ) : (
          <div className="">{isExpand ? <TbFolderOpen /> : <PiFolderBold />}</div>
        )}
        <div className="text-[12px] text-secondary-dark font-[500] leading-[100%] whitespace-nowrap pr-[15px]">
          {question.name}
        </div>
      </div>
      <div className="pl-[20px]">
        {isExpand &&
          isUsableArr(childQuestions) &&
          childQuestions.map((question, index) => (
            <DetailTargetFinderItem question={question} key={question.id || index} />
          ))}
      </div>
    </div>
  );
};

export default DetailTargetFinderItem;
