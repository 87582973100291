import { IoIosAddCircleOutline } from "react-icons/io";
import { BiTrash } from "react-icons/bi";
import ArrowDown from "../../../../commons/icons/Arrow/ArrowDown";
import { PropsWithChildren, useState } from "react";
import { useAuthoringExamContext } from "../../../../../context/Exam/AuthoringExamContext";
import {
  TYPE_OF_EXAM_DISPLAY,
  UPDATE_ORDER,
} from "../../../../../services/constants/admin/pages/exam";
import { ExamPartType } from "../../../../../types/admin/examAuthoring";
import ButtonOpen from "./ButtonOpen";

type PartExamProps = {
  partExam: ExamPartType;
  totalExamPart: number;
  isChooseSetting: boolean;
  handleDelete: () => void;
  handleAddExamSection: (order: number) => void;
  handleUpdateOrder: (orderPath: number, orderType: number) => void;
} & PropsWithChildren;

const PartExam = ({
  partExam,
  totalExamPart,
  isChooseSetting,
  children,
  handleDelete,
  handleAddExamSection,
  handleUpdateOrder,
}: PartExamProps) => {
  const { setSettingPropertyOrders } = useAuthoringExamContext();
  const { name, order, total_score } = partExam;
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <div className={`flex-col w-full mt-[20px]`}>
      <div
        className={`${
          isChooseSetting ? "bg-primary-light" : "bg-white"
        } flex items-center h-[50px] gap-[3px] border-[#BEBEBE] border-[1px] rounded-[5px] mb-[5px] `}
      >
        <div className="text-[13px] h-[30px] flex justify-start items-center basis-[60%] px-[10px]">
          <ButtonOpen
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            color={isChooseSetting ? "#fff" : ""}
          />
          <div
            onClick={() => {
              setSettingPropertyOrders(() => ({
                orderPart: order,
                orderType: TYPE_OF_EXAM_DISPLAY.PART,
              }));
            }}
            className={`${
              isChooseSetting ? "text-white" : ""
            } text-[13px] px-[10px] w-full h-full flex items-center cursor-pointer`}
          >
            {name}
          </div>
        </div>
        <div className="text-[13px] h-[30px] flex justify-center items-center gap-x-[30px] basis-[30%] px-[10px] gap-[10px]">
          <div className="border-[1px] border-[#BEBEBE] h-[24px] w-[30px] bg-[white] text-[14px] flex justify-end items-center p-[5px] rounded-[2px]">
            {order}
          </div>
          <ArrowDown
            className={`rotate-180 ${order === 1 ? "" : "cursor-pointer"}`}
            color={
              order === 1
                ? "#BEBEBE"
                : "" || isChooseSetting
                ? "white"
                : "#7A7A7A"
            }
            onClick={() => {
              if ((order || 0) >= 1) {
                handleUpdateOrder(order, UPDATE_ORDER.UP);
              }
            }}
          />
          <ArrowDown
            className={order === totalExamPart ? "" : "cursor-pointer"}
            color={
              order === totalExamPart
                ? "#BEBEBE"
                : "" || isChooseSetting
                ? "white"
                : "#7A7A7A"
            }
            onClick={() => {
              if ((order || 0) < totalExamPart) {
                handleUpdateOrder(order, UPDATE_ORDER.DOWN);
              }
            }}
          />
        </div>
        <div className="text-[13px] h-[30px] flex justify-center items-center basis-[15%] px-[10px] ">
          <BiTrash
            size={24}
            color={isChooseSetting ? "white" : "#7A7A7A"}
            className={`cursor-pointer`}
            onClick={handleDelete}
          />
        </div>
        <div className="text-[16px] h-[30px] flex justify-center items-center basis-[15%] px-[10px] ">
          {total_score ?? 0}
        </div>
      </div>
      {isOpen ? (
        <div className="w-full flex items-center justify-end pl-[5px]">
          <div className="w-full flex items-center border-l-[4px] border-[#BEBEBE]">
            <div className="ml-[10px] w-full">
              {children}
              <div className="mt-[10px]">
                <button
                  className="w-[170px] h-[38px] bg-primary px-[15px] py-[7px] rounded-[5px] flex justify-start items-center text-[13px] text-white gap-[10px]"
                  onClick={() => handleAddExamSection(partExam.order)}
                >
                  <IoIosAddCircleOutline size={24} />
                  新規グループ追加
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PartExam;
