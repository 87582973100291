import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { swalError, swalSuccess } from "../services/helpers/swal";
import { ROUTE } from "../services/constants/route/router";
import { ROLE_TOKITE } from "../services/constants/globals";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      let url = `http://${process.env.REACT_APP_MAIN_DOMAIN}`;
      const userLocal = localStorage.getItem("user");
      if (userLocal === null) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        return navigate(ROUTE.LOGIN);
      }
      const user = JSON.parse(userLocal);

      if (user.role_id && Number(user?.role_id) !== ROLE_TOKITE.ROLE_STUDENT) {
        url = ROUTE.LOGIN;
      }
      if (
        user.user_type &&
        Number(user?.user_type) !== ROLE_TOKITE.ROLE_STUDENT
      ) {
        url = ROUTE.LOGIN;
      }

      let result = await makeRequest({
        method: "post",
        url: API.AUTH.LOGOUT,
      });

      if (result.status) {
        localStorage.clear();
        swalSuccess();
      }

      if (!result.status) {
        navigate(ROUTE.MY_PAGE.DEFAULT);
        return swalError();
      }

      window.location.href = url;
    };

    handleLogout();
  }, [navigate]);

  return <div></div>;
};

export default Logout;
