import React, { ChangeEvent } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { FILTER } from "../../../../services/constants/admin/main";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { PATH_ADMIN_EXAMINATION } from "../../../../services/constants/route/router";

const ExamListFilter = ({
  changeHandle,
}: {
  changeHandle: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}) => {

  return (
    <>
      <section className="w-full flex justify-end pb-[20px]">
        <Link
          className="bg-primary-light flex items-center justify-center gap-x-[10px] px-[25px] py-[5px] rounded-[20px] text-white text-[14px]"
          to={PATH_ADMIN_EXAMINATION.CREATE}
        >
          <HiPlus size={24} />
          <span>新規試験登録</span>
        </Link>
      </section>
      <section className="py-[20px] border-secondary-light">
        <div className="flex h-[30px] gap-[8px] relative">
          <div className="relative">
            <input
              type="text"
              name="search"
              className="h-full w-full max-w-[151px] pl-[12px] placeholder-[#BEBEBE] text-[11px] leading-[100%] border-[#E1E3E2] rounded-[5px] pr-[30px]"
              placeholder="試験の検索"
              onChange={changeHandle}
            />
            <BiSearchAlt2
              className="absolute top-[5px] right-[9.26px] text-secondary-light"
              size={22}
            />
          </div>
          <div className="relative">
            <select
              name="limit"
              className="h-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] leading-[100%] w-[95px]"
              onChange={changeHandle}
            >
              {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
                <option value={item.value} className="" key={index}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExamListFilter;
