import React, { ChangeEvent, useEffect, useState } from "react";
import ExamParticipateCandidateFilter from "./ExamParticipateCandidateFilter";
import LayoutWaitingApi from "../../../commons/layouts/LayoutWaitingAPI";
import { ExamParticipateCandidateType } from "../../../../types/admin/examParticipate";
import { API } from "../../../../services/constants/route/api";
import { makeRequest } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { swalClose, swalError } from "../../../../services/helpers/swal";
import { useExamParticipateCandidateContext } from "../../../../context/Admin/ExamParticipateCandidateContext ";
import ExamParticipateCandidateTable from "./ExamParticipateCandidateTable";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { isEmptyObj } from "../../../../services/helpers/etc";
import { useNavigate } from "react-router-dom";

type FilterType = {
  search: string;
  limit: number;
  page: number;
};

const ExamParticipateCandidate = () => {
  const { searchParamsObject } = useObjectRoutes();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    page: 1,
    search: "",
  });
  const {
    examParticipateCandidates,
    setExamParticipateCandidates,
    totalPages,
    setTotalPages,
    setExaminationVenueInfo,
    waitingAPI,
    setWaitingAPI,
  } = useExamParticipateCandidateContext();
  const [_examParticipateCandidates, _setExamParticipateCandidates] = useState<
    ExamParticipateCandidateType[]
  >([]);

  const resetTable = () => {
    setCurrentPage(1);
  };

  const changeHandle = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };

  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setWaitingAPI(true);
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  useEffect(() => {
    if (isEmptyObj(searchParamsObject) || !searchParamsObject.examination_id) {
      navigate("/404");
    }
  }, [searchParamsObject]);

  useEffect(() => {
    _setExamParticipateCandidates(examParticipateCandidates);
  }, [examParticipateCandidates]);

  useEffect(() => {
    const fetch = async () => {
      setWaitingAPI(true);

      const result = await makeRequest({
        method: "get",
        url: `${
          API.ADMIN_EXAM_PARTICIPATE.GET_LIST_EXAMINATION_CANDIDATE
        }?${paramizeObject(filter)}&${paramizeObject(searchParamsObject)}`,
      });

      setWaitingAPI(false);

      if (!result.data) {
        navigate("/404");
      }

      const responses = result.data;
      setExamParticipateCandidates(responses.candidates);
      setTotalPages(responses.total_page);
      setExaminationVenueInfo({
        exam_name: responses.exam_name || "-",
        exam_type_name: responses.exam_type_name || "-",
        exam_venue_name: responses.exam_venue_name || "-",
      });
      swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);

    return () => clearTimeout(timer);
  }, [filter]);

  return (
    <div>
      {/* Filter */}
      <ExamParticipateCandidateFilter changeHandle={changeHandle} />

      <LayoutWaitingApi waitingApi={waitingAPI} className="w-full h-[100px]">
        <ExamParticipateCandidateTable
          _candidates={_examParticipateCandidates}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </LayoutWaitingApi>
    </div>
  );
};

export default ExamParticipateCandidate;
