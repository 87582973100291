import React from "react";
import ExamBase from ".";
import ListExamination from "../../../components/admin/Examination/List";
import ExaminationProvider from "../../../context/ExaminationContext";

const ListExaminationPage = () => {
  return (
    <ExamBase title="試験管理 ｜ 試験一覧">
      <ExaminationProvider>
        <ListExamination />
      </ExaminationProvider>
    </ExamBase>
  ); 
};

export default ListExaminationPage;
