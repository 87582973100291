import AdminLayout from '../../../layouts/admin';
import { WithChildren } from '../../../types/globals';

type QuestionMainProps = {
  title?: string;
};

const QuestionPageBase = ({ title, children }: WithChildren<QuestionMainProps>) => {
  return <AdminLayout>{children}</AdminLayout>;
};

export default QuestionPageBase;
