import * as React from "react";

const RefreshDouble = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29966 11.2248C4.68814 7.31211 7.99033 4.25581 12.0056 4.25581C14.8278 4.25581 17.2985 5.76556 18.6523 8.02327H16.1916C15.8448 8.02327 15.5637 8.3044 15.5637 8.65118C15.5637 8.99796 15.8448 9.27909 16.1916 9.27909H19.6681C19.6769 9.27927 19.6858 9.27928 19.6946 9.27909H19.8753C20.4995 9.27909 21.0056 8.77307 21.0056 8.14886V4.46513C21.0056 4.11835 20.7244 3.83723 20.3777 3.83723C20.0309 3.83723 19.7498 4.11835 19.7498 4.46513V7.4119C18.182 4.77126 15.3012 3 12.0056 3C7.33839 3 3.50161 6.55209 3.04999 11.1008C3.01573 11.4458 3.2677 11.7534 3.61279 11.7876C3.95788 11.8219 4.2654 11.5699 4.29966 11.2248ZM12.0469 19.7442C16.0622 19.7442 19.3644 16.6879 19.7529 12.7752C19.7871 12.4301 20.0946 12.1781 20.4397 12.2124C20.7848 12.2466 21.0368 12.5542 21.0025 12.8992C20.5509 17.4479 16.7141 21 12.0469 21C8.75133 21 5.87053 19.2287 4.30274 16.5881V19.5349C4.30274 19.8816 4.02162 20.1628 3.67484 20.1628C3.32805 20.1628 3.04693 19.8816 3.04693 19.5349V15.8511C3.04693 15.2269 3.55295 14.7209 4.17716 14.7209H4.3579C4.36675 14.7207 4.37559 14.7207 4.38442 14.7209H7.86088C8.20767 14.7209 8.48879 15.002 8.48879 15.3488C8.48879 15.6956 8.20767 15.9767 7.86088 15.9767H5.40026C6.75402 18.2344 9.22472 19.7442 12.0469 19.7442Z"
      fill={`${props.color || "white"}`}
    />
  </svg>
);

export default RefreshDouble;
