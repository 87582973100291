import React from "react";
import ExamResultBase from ".";
import DetailExamResult from "../../../components/admin/ExamResult/DetailExamResult";

const DetailExamResultPage = () => {
  return (
    <ExamResultBase title="結果管理｜結果詳細">
      <DetailExamResult />
    </ExamResultBase>
  );
};

export default DetailExamResultPage;
