import { ExamVenue } from "../../../../types/admin/examManagement";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { EXAM_VENUE_STATUS } from "../../../../services/constants/admin/pages/examVenue";
import { useExamManagementContext } from "../../../../context/ExamManagementContext";
import Pagination from "../../commons/Pagination";
import dayjs from "dayjs";
import { PATH_ADMIN_EXAM_VENUE } from "../../../../services/constants/route/router";
import { useNavigate } from "react-router-dom";

const ExamVenueTable = ({
   currentPage,
   _exam_venues,
   handlePageChange,
   toggleSelect,
   toggleSelectAll,
   selectAll,
   isExamVenueSelected,
}: {
   currentPage: number;
   _exam_venues: ExamVenue[];
   handlePageChange: (number: number) => void;
   toggleSelect: (id: number) => void;
   toggleSelectAll: () => void;
   selectAll: boolean;
   isExamVenueSelected: (id: number) => boolean;
}) => {
   const { totalPages } = useExamManagementContext();
   const navigate = useNavigate();

   const formatLastLoginTime = (lastLoginTime?: string) => {
      if (!lastLoginTime) return "";
      return dayjs(lastLoginTime).format("YYYY/MM/DD HH:mm");
   };

   const redirectDetailExamVenue = (id: number) => {
      const examVenue = _exam_venues.find((examVenue) => examVenue.id === id);
      if (!examVenue) return;

      navigate(`${PATH_ADMIN_EXAM_VENUE.DETAIL_VENUE}?id=${examVenue.id}`);
   }

   return (
      <>
         <div className="relative overflow-x-auto animate-[show_0.5s_ease-in-out]">
            <table className="w-full text-sm text-left text-gray-500">
               <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
                  <tr className="min-h-[50px] text-[13px] font-[500]">
                     <th
                        scope="col"
                        className="px-[10px] py-[2px] border-x-4 border-white whitespace-nowrap "
                     >
                        <input type="checkbox"
                           checked={selectAll}
                           onChange={toggleSelectAll}
                        />
                     </th>
                     <th
                        scope="col"
                        className="2xl:px-[50px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                     >
                        エリア
                     </th>
                     <th
                        scope="col"
                        className="2xl:px-[130px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
                     >
                        会場名
                     </th>
                     <th
                        scope="col"
                        className="2xl:px-[43px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                     >
                        ステータス
                     </th>
                     <th
                        scope="col"
                        className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                     >
                        最終更新日
                     </th>
                     <th
                        scope="col"
                        className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                     ></th>
                  </tr>
               </thead>
               <tbody>
                  {Array.isArray(_exam_venues) &&
                     _exam_venues &&
                     !!_exam_venues.length &&
                     _exam_venues.map((exam_venue) => (
                        <tr
                           key={exam_venue?.id}
                           className="bg-white border-b w-full text-[12px] font-[400] text-[#2E2E2E]"
                        >
                           <td className="text-center">
                              <input
                                 type="checkbox"
                                 checked={isExamVenueSelected(Number(exam_venue?.id))}
                                 onChange={() => toggleSelect(Number(exam_venue?.id))}
                              />
                           </td>
                           <td className="px-6 py-4 whitespace-nowrap text-center">{exam_venue.region}</td>
                           <th className="px-6 py-4 text-[14px] font-[500] text-primary-dark break-all " title={exam_venue?.name} >
                              {exam_venue?.name}
                           </th>
                           <td className="py-4 text-center m-auto">
                              {exam_venue.status == EXAM_VENUE_STATUS.PUBLIC && ( // button for isActive=1
                                 <div className="flex justify-center">
                                    <div className="max-w-[50px] max-h-[25px] px-[6px] pb-[5px] text-danger border border-danger rounded-[5px] whitespace-nowrap cursor-default">
                                       公開
                                    </div>
                                 </div>
                              )}
                              {exam_venue.status == EXAM_VENUE_STATUS.PRIVATE && ( // button for isActive=2
                                 <div className="min-w-[100px] px-[2px] pt-[3px] pb-[5px] text-[#7A7A7A] bg-neutral-100 rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                                    <IoMdRemoveCircleOutline
                                       className="absolute top-[2px] left-[4px]"
                                       size={18}
                                    />
                                    <p className="pl-[20px]">非公開</p>
                                 </div>
                              )}
                           </td>
                           <td className="px-6 py-4 text-center">
                              {formatLastLoginTime(exam_venue?.updated_at || '')}
                           </td>
                           <td className="text-center">
                              <button
                                 className="min-w-[50px] text-[14px] px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-primary-light text-white"
                                 onClick={() => redirectDetailExamVenue(Number(exam_venue?.id))}
                              >
                                 編集
                              </button>
                           </td>
                        </tr>
                     ))}
               </tbody>
            </table>
         </div>
         {totalPages && totalPages !== 0 && (
            <Pagination
               totalItems={totalPages}
               currentPage={currentPage}
               handlePageChange={handlePageChange}
            />
         )}
      </>
   )
};

export default ExamVenueTable;