import React from "react";
import { SliderProblemTest } from "../../../../../types/admin/question";
import SliderQuestionInputRange from "../../../Question/AuthoringQuestion/QuestionEditorHandler/QuestionTypes/SliderQuestion/SliderQuestionInputRange";
import { CHECK_ANSWER_STATUS_TYPE } from "../../../../../services/constants/globals";
import { handleShowIconCheckAnswer } from "../RenderQuestionType";

type SliderQuestionProps = {
  question: SliderProblemTest;
  numberQuestion: number;
};

const SliderQuestion = ({ question, numberQuestion }: SliderQuestionProps) => {
  const currentIssues = question.issues || [];
  const answerData = question.answer_data || {};

  const handleCheckCorrectAnswer = (): number => {
    if (currentIssues?.[0].answerValue === answerData.answerValue) {
      return CHECK_ANSWER_STATUS_TYPE.CORRECT;
    }

    return CHECK_ANSWER_STATUS_TYPE.INCORRECT;
  };

  return (
    <div className="w-full flex items-stretch mb-[30px]">
      <div className="w-[40px]">
        <div className="w-full h-full flex items-center justify-center bg-warning-lighter">
          <span>{numberQuestion}</span>
        </div>
      </div>
      <div className="w-[calc(100%-40px)] flex items-center border-b border-b-danger-light py-[10px]">
        <div className="flex-1 w-full flex items-center gap-x-[15px] px-[15px]">
          <SliderQuestionInputRange
            className={"basis-[90%]"}
            answerValue={answerData.answerValue || 0}
            isDisabled={true}
          />
        </div>
        <div className="flex-1 w-full flex items-center gap-x-[15px] px-[15px]">
          <SliderQuestionInputRange
            className={"basis-[90%]"}
            answerValue={currentIssues[0].answerValue || 0}
            isDisabled={true}
          />
        </div>
        <div className="w-[80px] flex justify-center">
          {handleShowIconCheckAnswer(handleCheckCorrectAnswer())}
        </div>
        <div className="w-[40px] text-center text-[14px]">
          {handleCheckCorrectAnswer() === CHECK_ANSWER_STATUS_TYPE.CORRECT
            ? currentIssues[0].point
            : 0}
        </div>
      </div>
    </div>
  );
};

export default SliderQuestion;
