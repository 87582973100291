import { useEffect, useState } from "react";
import { TestTakerRecordProblemTest } from "../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import TestTakerRecordVoiceUploader from "../QuestionTypeComponent/TestTakerRecordVoiceUploader";
import { deleteFile } from "../../../../../services/utils/upload/s3Upload";
import { useExaminationTestContext } from "../../../../../context/ExaminationTestContext";
import { candidateToolIsOpen } from "../../../../../services/utils/candidate/examinationTest";
import { SETTING_PROPERTY_NAMES } from "../../../../../services/constants/admin/pages/exam";
import { request } from "../../../../../services/axios/axios";
import { API } from "../../../../../services/constants/route/api";

const RecordVoiceQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled
}: {
  questionProblem: TestTakerRecordProblemTest;
  handleUpdateProblem: (problem: TestTakerRecordProblemTest) => void;
  isDisabled?: boolean;
}) => {
  const { questionProblems, setQuestionProblems, openingTools } =
    useExaminationTestContext();
  const [editingData, setEditingData] = useState<TestTakerRecordProblemTest>(questionProblem);

  const updateAnswerQuestionVoice = async (pathFile: string) => {
    if (editingData.answer_data) {
      await deleteFile(editingData.answer_data);
      await handleDeleteAnswer(editingData.answer_data)
    }

    const newEditingData = { ...editingData, answer_data: pathFile };
    setEditingData(newEditingData);
    handleUpdateProblem(newEditingData);
  };

  const handleDeleteAnswer = async (pathFile: string) => {
    await request.delete(
      API.EXAMINATION.DELETE_FILE_ANSWER_BY_PATH,
      {
        path_file: pathFile,
      },
      (data) => {
        console.log("Delete file success");
      },
      (errors) => {
        console.log("Delete file had an error: ", errors);
      }
    );
  };

  const handleMouseUp = () => {
    if (
      !candidateToolIsOpen(SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT, openingTools)
    ) {
      return;
    }

    const selector = window.getSelection();
    const textSelected = selector?.toString();
    if (!textSelected) return;
    const span = document.createElement("span");
    span.setAttribute("style", "background-color: yellow; display:inline;");
    span.innerText = textSelected;
    const newText = questionProblem.title?.replace(
      textSelected,
      span.outerHTML
    );

    setQuestionProblems(
      questionProblems.map((question) => {
        if (question.id === questionProblem.id) {
          return { ...question, title: newText };
        }

        return question;
      })
    );
  };

  useEffect(() => {
    if(questionProblem) {
      setEditingData(questionProblem)
    }
  }, [questionProblem])

  return (
    <>
      <div
        className="leading-[24px] font-[500] mb-[80px]"
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(editingData.title),
        }}
        onMouseUp={handleMouseUp}
      ></div>
      <form>
        <div className="mb-[10px] min-h-[300px] w-full relative mx-auto">
          <div className="mb-[30px]"></div>
          <div className="flex gap-[13px] justify-center">
            <div className="bg-white pt-[10px] rounded-b-[10px]">
              <div className="px-[10px] py-[70px]">
                <div className="flex flex-col items-center justify-center mb-[15px]">
                  <TestTakerRecordVoiceUploader
                    updateAnswerQuestionVoice={updateAnswerQuestionVoice}
                    problemData={editingData}
                    actionable={isDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default RecordVoiceQuestion;
