import React, { useEffect, useState } from "react";
import {
  MatrixCoordinateOmit,
  MatrixIssue,
  MatrixProblemTest,
} from "../../../../../types/admin/question";
import {
  decodeHTMLEntities,
  groupArrayByProperty,
} from "../../../../../services/helpers/parseData";
import { isEmptyObj, isUsableArr } from "../../../../../services/helpers/etc";
import { getIssueById } from "../../../../../services/utils/admin/question";
import { useExaminationTestContext } from "../../../../../context/ExaminationTestContext";
import { candidateToolIsOpen } from "../../../../../services/utils/candidate/examinationTest";
import { SETTING_PROPERTY_NAMES } from "../../../../../services/constants/admin/pages/exam";

const MatrixQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled
}: {
  questionProblem: MatrixProblemTest;
  handleUpdateProblem: (problem: MatrixProblemTest) => void;
  isDisabled?: boolean;
}) => {
  const { questionProblems, setQuestionProblems, openingTools } =
    useExaminationTestContext();
  const matrixQuestion: MatrixProblemTest = questionProblem;
  const matrixIssues = matrixQuestion.issues || [];
  const [editingData, setEditingData] = useState<MatrixProblemTest>(
    questionProblem || {}
  );
  const [editingNode, setEditingNode] = useState("");
  const currentIssues = editingData.issues || [];
  const correctCoordinates = editingData.correct_coordinates || [];
  const answerCoordinates: MatrixCoordinateOmit[] =
    editingData.answer_data || [];
  const currentCoordinatesByX = groupArrayByProperty(answerCoordinates, "x");
  const coordinateKeyXs = Object.keys(currentCoordinatesByX);
  const currentCoordinatesByY = groupArrayByProperty(answerCoordinates, "y");

  const getMatrixIssueById = (fillInIssues: MatrixIssue[], issueId: number) => {
    return matrixIssues.find((issue) => issue.id === issueId) ?? {};
  };

  const getCoordinateByAxis = (x: number, y: number) => {
    return answerCoordinates.find(
      (coordinate) => coordinate.x === x && coordinate.y === y
    );
  };

  const matrixChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    coordinate: MatrixCoordinateOmit
  ) => {
    if (!e || !e.currentTarget) return;
    const { name, value, checked, type } = e.currentTarget;
    let parsedValue: boolean | string | number = Number(value);

    if (type === "checkbox") {
      parsedValue = checked;
    }

    // const newCurrentCoordinates = JSON.parse(
    //   JSON.stringify(currentCoordinates),
    // ) as typeof currentCoordinates;

    const coordinateIndex = answerCoordinates.findIndex(
      (crCoordinate) =>
        crCoordinate.x === coordinate.x && crCoordinate.y === coordinate.y
    );
    const newCoordinate = {
      ...coordinate,
      [name]: parsedValue,
    };

    answerCoordinates.splice(coordinateIndex, 1, newCoordinate);
    const newEditingData = {
      ...editingData,
      answer_data: answerCoordinates,
    };
    setEditingData(newEditingData);
    handleUpdateProblem(newEditingData);
  };

  const handleMouseUp = () => {
    if (
      !candidateToolIsOpen(SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT, openingTools)
    )
      return;

    const selector = window.getSelection();
    const textSelected = selector?.toString();
    if (!textSelected) return;
    const span = document.createElement("span");
    span.setAttribute("style", "background-color: yellow; display:inline;");
    span.innerText = textSelected;
    const newText = questionProblem.title?.replace(
      textSelected,
      span.outerHTML
    );

    setQuestionProblems(
      questionProblems.map((question) => {
        if (question.id === questionProblem.id) {
          return { ...question, title: newText };
        }

        return question;
      })
    );
  };

  useEffect(() => {
    if (questionProblem) {
      setEditingData(questionProblem);
    }
  }, [questionProblem]);

  useEffect(() => {
    if (editingData.answer_data && editingData.answer_data.length) return;

    let answerData: MatrixProblemTest["answer_data"] = [];
    if (correctCoordinates.length) {
      correctCoordinates.forEach((correctCoordinate) => {
        if (correctCoordinate) {
          answerData?.push({
            id: correctCoordinate.id,
            x: correctCoordinate.x,
            y: correctCoordinate.y,
            checked: false,
          });
        }

        setEditingData({ ...editingData, answer_data: answerData });
      });
    }
  }, [editingData]);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(matrixQuestion.title),
        }}
        onMouseUp={handleMouseUp}
      ></div>
      <div className="justify-center items-center px-[20px] py-[55px] flex flex-col overflow-auto">
        <div className="flex bg-success-lighter border-r border-[#C2C2C2]">
          <div className="border-l border-t border-[#C2C2C2] min-w-[108px] min-h-[52px]"></div>
          {!isEmptyObj(currentCoordinatesByY) &&
            Object.keys(currentCoordinatesByY).map((coordinateYId) => (
              <div
                className="flex items-center justify-center border-l border-t border-[#C2C2C2] w-[108px] min-h-[52px] px-[15px] py-[10px]"
                key={`${coordinateYId}_th`}
                dangerouslySetInnerHTML={{
                  __html: decodeHTMLEntities(
                    getMatrixIssueById(matrixIssues, Number(coordinateYId))
                      ?.label
                  ),
                }}
              ></div>
            ))}
        </div>
        <div className="">
          <div className="bg-success-lighter">
            <div className="flex">
              <div className="">
                {coordinateKeyXs.map(
                  (coordinateXId, coordinateXIdIndex, coordinateXIdArr) => (
                    <div key={`${coordinateXId}_td`} className="flex">
                      <div
                        className={`flex border-[#C2C2C2] ${
                          coordinateXIdIndex === coordinateXIdArr.length - 1
                            ? "border-b"
                            : ""
                        }`}
                      >
                        <div
                          className="border-l border-t border-[#C2C2C2] min-w-[108px] min-h-[52px] flex items-center justify-center"
                          onClick={() => setEditingNode(`${coordinateXId}`)}
                          dangerouslySetInnerHTML={{
                            __html: decodeHTMLEntities(
                              getIssueById(currentIssues, Number(coordinateXId))
                                ?.label
                            ),
                          }}
                        ></div>
                        <div className="flex">
                          {isUsableArr(answerCoordinates) &&
                            answerCoordinates
                              ?.filter(
                                (coordinateY) =>
                                  Number(coordinateY.x) ===
                                  Number(coordinateXId)
                              )
                              ?.map((coordinateY) => (
                                <div className="answer_x" key={coordinateY.id + "y"}>
                                  <div className="pl-[15px] border-l border-t border-r bg-white border-[#C2C2C2] min-w-[108px] h-full flex gap-[10px] items-center">
                                    <input
                                      type="checkbox"
                                      name="checked"
                                      id=""
                                      checked={
                                        !!getCoordinateByAxis(
                                          coordinateY.x,
                                          coordinateY.y
                                        )?.checked
                                      }
                                      onChange={(e) =>
                                        matrixChangeHandle(e, coordinateY)
                                      }
                                      disabled={isDisabled}
                                    />
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatrixQuestion;
