import React from "react";
import { useQuestionListContext } from "../../../../context/Question/ListQuestionContext";
import {
  NAME_ACTION_ASIDE_QUESTION,
  NAME_ACTION_DETAIL_QUESTION,
} from "../../../../services/constants/admin/pages/question";
import DetailAction from "./DetailAction";
import DetailForm from "./DetailForm";
import DetailTargetFinder from "./DetailTargetFinder";
import { useNavigate } from "react-router-dom";
import { PATH_ADMIN_QUESTION } from "../../../../services/constants/route/router";
import DetailPreview from "./DetailPreview";

const DetailQuestion = () => {
  const { selectedQuestion, sideAction, detailActionName } =
    useQuestionListContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (detailActionName === NAME_ACTION_DETAIL_QUESTION.AUTHORING) {
      return navigate(
        `${PATH_ADMIN_QUESTION.AUTHORING}?resource_id=${selectedQuestion.question_resource_id}`
      );
    }
  }, [detailActionName]);

  return (
    <>
      <div className="w-full h-0 min-h-[897px] bg-white rounded-[2px] px-[20px] py-[40px]">
        {[
          NAME_ACTION_ASIDE_QUESTION.COPY,
          NAME_ACTION_ASIDE_QUESTION.MOVE,
        ].includes(sideAction) ? (
          <>
            <DetailTargetFinder sideAction={sideAction} />
          </>
        ) : (
          <>
            {selectedQuestion.id ? (
              <>
                {selectedQuestion.is_file ? <DetailAction /> : <></>}
                {detailActionName === NAME_ACTION_DETAIL_QUESTION.EDIT ? (
                  <DetailForm question={selectedQuestion} />
                ) : (
                  <></>
                )}
                {detailActionName === NAME_ACTION_DETAIL_QUESTION.PREVIEW ? (
                  <DetailPreview />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div className=""></div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DetailQuestion;
