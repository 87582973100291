import {
  PATH_ADMIN_CHAPTER,
  PATH_ADMIN_COURSE,
  PATH_ADMIN_LECTURE,
  PATH_ADMIN_GROUP,
  PATH_ADMIN_CANDIDATE,
  PATH_ADMIN_UNIT,
  PATH_ADMIN_FOLDER,
  PATH_ADMIN_QUESTION,
  PATH_ADMIN_QUESTION_RESOURCE,
  PATH_ADMIN_EXAM,
  ROUTE,
  PATH_ADMIN_EXAM_ITEM,
  PATH_ADMIN_EXAM_NAME,
  PATH_ADMIN_EXAM_VENUE,
  PATH_ADMIN_EXAMINATION,
  PATH_ADMIN_EXAM_PARTICIPATE,
  PATH_ADMIN_EXAM_RESULT,
} from './router';

const DEFAULT_PREFIX_ROUTE = {
  MYPAGE: '/my-page',
  CANDIDATE: '/candidate',
  ADMIN: '/admin',
  GROUP: '/group',
  COURSE: '/course',
  LECTURE: '/lecture',
  CHAPTER: '/chapter',
  UNIT: '/unit',
  TEST: '/test',
  MEMO: '/memo',
  REPORT: '/report',
  SCHEDULE: '/schedule-event',
  EVENT_TYPE: '/event-type',
  CANDIDATE_ACTIVITY_LOG: '/candidate-activity-log',
  REVISION_REVIEW: '/revision-review',
  ORGANIZATION: '/organization',
  EXAM_NAME: '/exam-name',
  EXAM_VENUE: '/exam-venue',
  PAYMENT: '/payment',
  REGISTER_EXAMINATION: '/register-examination',
  EXAMINATION: '/examination',
  RESOURCE: '/resource',
  CANDIDATE_EXAM_HISTORY: '/candidate-exam-history',
};

const API = {
  AUTH: {
    VERIFY_DOMAIN: '/verify-domain',
    REGISTER: '/register',
    LOGIN: '/login',
    LOGOUT: '/logout',
    SEND_EMAIL_FORGOT_PASSWORD: '/send-email-forgot-password',
    SOCIAL_LOGIN: '/social-login',
    CHANGE_PWD: '/update-pwd',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_EMAIL_REGISTER: '/verify-email-for-register',
    RESEND_VERIFY_EMAIL: '/resend-verify-email-for-register',
    VERIFY_PALETTE_LOGIN: '/login-with-palette-token',
    VERIFY_CHANGE_EMAIL: '/verify-change-email',
    RESEND_VERIFY_CHANGE_EMAIL: '/resend-verify-change-email'
  },
  USER: {
    INFO: '/user',
  },

  // Delete
  MYPAGE: {
    COURSE_LIST: `${DEFAULT_PREFIX_ROUTE.COURSE}/list-course-of-current-user`,
    GET_MYPAGE_STATISTICS: `${DEFAULT_PREFIX_ROUTE.MYPAGE}/statistical`,
    GET_MYPAGE_UNIT_DOING: `${DEFAULT_PREFIX_ROUTE.MYPAGE}/get-list-unit-doing-status-of-user`,
    LATEST_COURSE_LIST: `${DEFAULT_PREFIX_ROUTE.MYPAGE}/get-list-course-in-my-page-top`,
  },
  // End Delete

  PROFILE: {
    UPDATE_AVATAR: `${DEFAULT_PREFIX_ROUTE.CANDIDATE}/update-profile-avatar`,
  },

  // Delete
  UNIT: {
    DETAIL: `${DEFAULT_PREFIX_ROUTE.UNIT}/get-unit-data`,
    LIST_UNIT_IN_CHAPTER: `${DEFAULT_PREFIX_ROUTE.UNIT}/get-list-unit-of-chapter`,
    UNIT_PROGRESS: `${DEFAULT_PREFIX_ROUTE.CHAPTER}/get-chapter-progress-in-a-lecture`,
    UPDATE_USER_HISTORY: `${DEFAULT_PREFIX_ROUTE.UNIT}/update-user-study-history`,
  },
  // End delete

  CANDIDATE: {
    // /admin/candidate/...
    UPDATE_INFORMATION: `${DEFAULT_PREFIX_ROUTE.CANDIDATE}/update-candidate-information`,
    UPDATE_PROFILE_SETTING: `${DEFAULT_PREFIX_ROUTE.CANDIDATE}/update-candidate-profile-setting`,
    UPDATE_EMAIL: `${DEFAULT_PREFIX_ROUTE.CANDIDATE}/update-email`,
    UPDATE_PASSWORD: `${DEFAULT_PREFIX_ROUTE.CANDIDATE}/update-pwd`,
    UPDATE_PROFILE: `${DEFAULT_PREFIX_ROUTE.CANDIDATE}/update-profile`,

    GET_LIST_EXAM_NAME: `${DEFAULT_PREFIX_ROUTE.EXAM_NAME}/get-list-exam-name`,
  },

  // Delete
  COURSE: {
    // /course/....
    GET_TEST: `${DEFAULT_PREFIX_ROUTE.TEST}/get-test-content`,
    SUBMIT_FINISH_TEST: `${DEFAULT_PREFIX_ROUTE.TEST}/submit-test`,
    SUBMIT_PAUSE_TEST: `${DEFAULT_PREFIX_ROUTE.TEST}/save-unfinished-test`,
    GET_PAUSED_TEST: `${DEFAULT_PREFIX_ROUTE.TEST}/get-continue-test`,
    GET_STATISTIC_TEST: `${DEFAULT_PREFIX_ROUTE.TEST}/get-test-round-data`,
    GET_LIST_TEST_HISTORY: `${DEFAULT_PREFIX_ROUTE.TEST}/get-list-test-round`,
    GET_LIST_TEST_HISTORY_ON_PAUSE: `${DEFAULT_PREFIX_ROUTE.TEST}/get-list-test-round-on-pause`,
    DELETE_TEST_CONTINUOUS_HISTORY: `${DEFAULT_PREFIX_ROUTE.TEST}/delete-test-round-on-pause`,
    GET_EXPLANATION: `${DEFAULT_PREFIX_ROUTE.TEST}/get-explanation-for-test`,
    COURSE_DETAIL: `${DEFAULT_PREFIX_ROUTE.COURSE}/detail-course`,
    COURSE_STATISTICS: `${DEFAULT_PREFIX_ROUTE.COURSE}/get-course-top-statistical`,
    LECTURE_OF_CURRENT_COURSE: `${DEFAULT_PREFIX_ROUTE.COURSE}/get-list-lecture-in-course-top`,
    LIST_UNIT_DOING: `${DEFAULT_PREFIX_ROUTE.UNIT}/get-list-unit-doing-status-in-a-course`,
    DELETE_TEST_HISTORY: `${DEFAULT_PREFIX_ROUTE.TEST}/delete-candidate-test-history`,
    UPDATE_RATE_DETAIL_TEST_HISTORY: `${DEFAULT_PREFIX_ROUTE.TEST}/update-rate-detail-test-history`,
    GET_LIST_STUDY_HISTORY: `${DEFAULT_PREFIX_ROUTE.COURSE}/list-study-history-of-user`,
    GET_LIST_COURSE_STUDY_TOOL: `${DEFAULT_PREFIX_ROUTE.COURSE}/get-list-course-study-tool`,
  },

  LECTURE: {
    CHAPTER_OF_CURRENT_LECTURE: `${DEFAULT_PREFIX_ROUTE.LECTURE}/get-list-chapter-in-lecture-top`,
    LECTURE_DETAIL: `${DEFAULT_PREFIX_ROUTE.LECTURE}/detail-lecture`,
    LECTURE_STATISTICS: `${DEFAULT_PREFIX_ROUTE.LECTURE}/get-lecture-top-statistical`,
    GET_UNIT_IN_CHAPTER: `${DEFAULT_PREFIX_ROUTE.LECTURE}/get-list-unit-in-chapter`,
  },

  SCHEDULE: {
    UPSERT: `${DEFAULT_PREFIX_ROUTE.SCHEDULE}/upsert-event`,
    LIST: `${DEFAULT_PREFIX_ROUTE.SCHEDULE}/list-events`,
    LIST_EVENT_TYPE: `${DEFAULT_PREFIX_ROUTE.SCHEDULE}/list-event-type`,
    LIST_SCHEDULE_CALENDAR: `${DEFAULT_PREFIX_ROUTE.SCHEDULE}/list-schedule-event-calendar`,
    DELETE_SCHEDULE_EVENT: `${DEFAULT_PREFIX_ROUTE.SCHEDULE}/delete`,
  },

  MEMO: {
    GET_LIST_MEMO_FOLDER: `${DEFAULT_PREFIX_ROUTE.MEMO}/list-folder`,
    UPSERT_MEMO_FOLDER: `${DEFAULT_PREFIX_ROUTE.MEMO}/upsert-folder`,
    DELETE_MEMO_FOLDER: `${DEFAULT_PREFIX_ROUTE.MEMO}/delete-folder`,
    UPSERT_MEMO: `${DEFAULT_PREFIX_ROUTE.MEMO}/upsert-memo`,
    GET_MEMO_DETAIL: `${DEFAULT_PREFIX_ROUTE.MEMO}/get-memo`,
    GET_MEMO_IN_A_FOLDER: `${DEFAULT_PREFIX_ROUTE.MEMO}/get-memo-in-a-folder`,
    DELETE_MEMO: `${DEFAULT_PREFIX_ROUTE.MEMO}/delete-memo`,
  },

  REPORT: {
    GET_STUDY_TIME: `${DEFAULT_PREFIX_ROUTE.REPORT}/get-time-learned-statistical`,
    GET_STUDY_PROCESS: `${DEFAULT_PREFIX_ROUTE.REPORT}/get-study-process-statistical`,
    GET_STUDY_RATIO: `${DEFAULT_PREFIX_ROUTE.REPORT}/get-study-course-ratio`,
  },

  REVISION_REVIEW: {
    GET_STUDY_REVISION_LIST: `${DEFAULT_PREFIX_ROUTE.REVISION_REVIEW}/get-study-revision-list`,
    GET_REVISE_HISTORY_LIST: `${DEFAULT_PREFIX_ROUTE.REVISION_REVIEW}/get-revise-history-list`,
  },
  // End delete

  CANDIDATE_ACTIVITY_LOG: {
    GET_CANDIDATE_ACTIVITY_LOG: `${DEFAULT_PREFIX_ROUTE.CANDIDATE_ACTIVITY_LOG}/list`,
  },

  ORGANIZATION: {
    GET_ORGANIZATION_INFO: `${DEFAULT_PREFIX_ROUTE.ORGANIZATION}/get-organization-info`
  },

  // Tokite API
  REGISTER_EXAMINATION: {
    GET_LIST_REGISTER_EXAMINATION: `${DEFAULT_PREFIX_ROUTE.REGISTER_EXAMINATION}/get-list-register-examination`,
    FILTER_SERACH_GET_LIST_EXAM_VENUE: `${DEFAULT_PREFIX_ROUTE.EXAM_VENUE}/get-list-exam-venue`,
    GET_DETAIL: `${DEFAULT_PREFIX_ROUTE.REGISTER_EXAMINATION}/get-register-examination-info`
  },

  EXAMINATION: {
    GET_TEST_PART_DETAIL: `${DEFAULT_PREFIX_ROUTE.EXAMINATION}/get-test-part-detail`,
    CBT_ENTER_CODE: `${DEFAULT_PREFIX_ROUTE.EXAMINATION}/cbt-enter-code`,
    GET_EXAMINATION_TEST: `${DEFAULT_PREFIX_ROUTE.EXAMINATION}/get-examination-test`,
    UPLOAD_FILE_ANSWER: `${DEFAULT_PREFIX_ROUTE.RESOURCE}/save-upload-answer-resource`,
    DELETE_FILE_ANSWER: `${DEFAULT_PREFIX_ROUTE.RESOURCE}/delete-answer-resource`,
    DELETE_FILE_ANSWER_BY_PATH: `${DEFAULT_PREFIX_ROUTE.RESOURCE}/delete-answer-resource-by-path`,
  },

  PAYMENT: {
    GET_URL_PAYMENT_LINK: `${DEFAULT_PREFIX_ROUTE.PAYMENT}/get-url-payment-link`
  },

  CANDIDATE_EXAM_HISTORY: {
    GET_CANDIDATE_EXAM_PART_LIST: `${DEFAULT_PREFIX_ROUTE.CANDIDATE_EXAM_HISTORY}/get-candidate-exam-part-list`,
    SAVE_CANDIDATE_EXAM_PART_HISTORY: `${DEFAULT_PREFIX_ROUTE.CANDIDATE_EXAM_HISTORY}/save-candidate-exam-part-history`,
    SAVE_CANDIDATE_EXAM_HISTORY: `${DEFAULT_PREFIX_ROUTE.CANDIDATE_EXAM_HISTORY}/save-candidate-exam-history`,
  },

  ADMIN_GROUP: {
    // /admin/group/...
    GET_GROUP_LIST: `${PATH_ADMIN_GROUP.DEFAULT}/list-group`,
    GET_TOTAL_CANDIDATE_AMOUNT_OF_OZ: `${PATH_ADMIN_GROUP.DEFAULT}/get-candidate-amount`,
    GET_MAINGROUP_LIST: `${PATH_ADMIN_GROUP.DEFAULT}/list-main-group`,
    GET_SUBGROUP_LIST: `${PATH_ADMIN_GROUP.DEFAULT}/list-sub-group`,
    UPDATE_GROUP: `${PATH_ADMIN_GROUP.DEFAULT}/update-group-order`,
    GET_GROUP_CANDIDATE: `${PATH_ADMIN_GROUP.DEFAULT}/group-candidate-info`,
    CREATE_GROUP: `${PATH_ADMIN_GROUP.DEFAULT}/create-main-group`,
    CREATE_SUBGROUP: `${PATH_ADMIN_GROUP.DEFAULT}/create-sub-group`,
    GET_GROUP_INFO: `${PATH_ADMIN_GROUP.DEFAULT}/group-info`,
    UPDATE_GROUP_INFO: `${PATH_ADMIN_GROUP.DEFAULT}/update-main-group`,
    UPDATE_SUBGROUP_INFO: `${PATH_ADMIN_GROUP.DEFAULT}/update-sub-group`,
    GET_MAIN_GROUP: `${PATH_ADMIN_GROUP.DEFAULT}/list-main-group`,
    DELETE_GROUP: `${PATH_ADMIN_GROUP.DEFAULT}/delete-group`,
    ADD_USERS_TO_GROUP: `${PATH_ADMIN_GROUP.DEFAULT}/add-users-to-group`,
    MOVE_USERS_TO_GROUP: `${PATH_ADMIN_GROUP.DEFAULT}/change-group-of-users`,
    REMOVE_USERS_TO_GROUP: `${PATH_ADMIN_GROUP.DEFAULT}/remove-users-from-group`,
    SEARCH_CANDIDATE: `${PATH_ADMIN_CANDIDATE.DEFAULT}/list-candidate-not-in-specific-group`,
  },

  ADMIN_CANDIDATE: {
    // /admin/candidate/...
    GET_CANDIDATE_LIST: `${PATH_ADMIN_CANDIDATE.DEFAULT}/list-candidates`,
    GET_CANDIDATE_DETAILS: `${PATH_ADMIN_CANDIDATE.DEFAULT}/detail-candidate`,
    CREATE_CANDIDATE: `${PATH_ADMIN_CANDIDATE.DEFAULT}/create-candidate`,
    UPDATE_CANDIDATE: `${PATH_ADMIN_CANDIDATE.DEFAULT}/update-candidate`,
    GET_SETTINGS: `${PATH_ADMIN_CANDIDATE.DEFAULT}/list-setting-candidate-info-display`,
    UPDATE_SETTINGS: `${PATH_ADMIN_CANDIDATE.DEFAULT}/update-setting-display-info`,
    DELETE_CANDIDATE: `${PATH_ADMIN_CANDIDATE.DEFAULT}/delete-user`,
  },

  // Delete
  ADMIN_COURSE: {
    // /admin/course/...
    LIST: `${PATH_ADMIN_COURSE.DEFAULT}/list-course`,
    DELETE: `${PATH_ADMIN_COURSE.DEFAULT}/delete-course`,
    CREATE: `${PATH_ADMIN_COURSE.DEFAULT}/create-course`,
    DETAIL: `${PATH_ADMIN_COURSE.DEFAULT}/detail-course`,
    UPDATE: `${PATH_ADMIN_COURSE.DEFAULT}/update-course`,
    UPDATE_ORDER: `${PATH_ADMIN_COURSE.DEFAULT}/update-order-course`,
  },

  ADMIN_LECTURE: {
    // /admin/lecture/...
    LIST: `${PATH_ADMIN_LECTURE.DEFAULT}/list-lecture`,
    DELETE: `${PATH_ADMIN_LECTURE.DEFAULT}/delete-lecture`,
    CREATE: `${PATH_ADMIN_LECTURE.DEFAULT}/create-lecture`,
    DETAIL: `${PATH_ADMIN_LECTURE.DEFAULT}/detail-lecture`,
    UPDATE: `${PATH_ADMIN_LECTURE.DEFAULT}/update-lecture`,
    UPDATE_ORDER: `${PATH_ADMIN_LECTURE.DEFAULT}/update-order-lecture`,
  },

  ADMIN_CHAPTER: {
    // /admin/chapter/...
    LIST: `${PATH_ADMIN_CHAPTER.DEFAULT}/list-chapter`,
    DELETE: `${PATH_ADMIN_CHAPTER.DEFAULT}/delete-chapter`,
    CREATE: `${PATH_ADMIN_CHAPTER.DEFAULT}/create-chapter`,
    DETAIL: `${PATH_ADMIN_CHAPTER.DEFAULT}/detail-chapter`,
    UPDATE: `${PATH_ADMIN_CHAPTER.DEFAULT}/update-chapter`,
    UPDATE_ORDER: `${PATH_ADMIN_CHAPTER.DEFAULT}/update-order-chapter`,
  },

  ADMIN_UNIT: {
    // /admin/unit/...
    LIST: `${PATH_ADMIN_UNIT.DEFAULT}/list-unit`,
    DELETE: `${PATH_ADMIN_UNIT.DEFAULT}/delete-unit`,
    CREATE: `${PATH_ADMIN_UNIT.DEFAULT}/create-unit`,
    CREATE_UNIT_TEST: `${PATH_ADMIN_UNIT.DEFAULT}/test/create-test`,
    UPDATE_UNIT_TEST: `${PATH_ADMIN_UNIT.DEFAULT}/test/update-test`,
    CREATE_UNIT_LESSON: `${PATH_ADMIN_UNIT.DEFAULT}/lesson/create-lesson`,
    UPDATE_UNIT_LESSON: `${PATH_ADMIN_UNIT.DEFAULT}/lesson/update-lesson`,
    DETAIL: `${PATH_ADMIN_UNIT.DEFAULT}/detail-unit`,
    UPDATE: `${PATH_ADMIN_UNIT.DEFAULT}/update-unit`,
    UPDATE_ORDER: `${PATH_ADMIN_UNIT.DEFAULT}/update-order-unit`,
    CREATE_TEST_QUESTION: `${PATH_ADMIN_UNIT.DEFAULT}/test/create-question`,
    UPDATE_TEST_QUESTION: `${PATH_ADMIN_UNIT.DEFAULT}/test/update-question`,
    QUESTION_DETAIL: `${PATH_ADMIN_UNIT.DEFAULT}/test/get-question-info`,
    DELETE_QUESTION: `${PATH_ADMIN_UNIT.DEFAULT}/test/delete-question`,
    UPDATE_QUESTION_ORDER: `${PATH_ADMIN_UNIT.DEFAULT}/test/update-order-question`,
  },

  // End delete

  ADMIN_FOLDER: {
    LIST: `${PATH_ADMIN_FOLDER.FOLDER}/list-folder`,
    LATEST: `${PATH_ADMIN_FOLDER.FOLDER}/folder-latest`,
    FILE: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/list-resource`,
    CAPACITY: `${PATH_ADMIN_FOLDER.FOLDER}/total-capacity`,
    CREATE: `${PATH_ADMIN_FOLDER.FOLDER}/add-new-folder`,
    DELETE_FOLDER: `${PATH_ADMIN_FOLDER.FOLDER}/delete-resource-folder`,
    UPLOAD_FILE: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/save-upload-resource-info`,
    UPLOAD_FILE_QUESTION: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/save-upload-question-resource`,
    UPDATE_FILE: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/update-content-resource`,
    DELETE_FILE: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/delete-resource`,
    UPDATE: `${PATH_ADMIN_FOLDER.FOLDER}/update-resource-folder`,
    MOVE_FILE: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/change-folder-of-resource`,
  },

  ADMIN_QUESTION: {
    LIST: `${PATH_ADMIN_QUESTION.DEFAULT}/list-question`,
    LIST_FOLDER: `${PATH_ADMIN_QUESTION.DEFAULT}/get-folder-question`,
    FILTER: `${PATH_ADMIN_QUESTION.DEFAULT}/filter-question`,
    DETAIL: `${PATH_ADMIN_QUESTION.DEFAULT}/detail-question`,
    GET_PATH_FILE: `${PATH_ADMIN_QUESTION.DEFAULT}/get-path-file-question`,
    CREATE: `${PATH_ADMIN_QUESTION.DEFAULT}/create-question`,
    DUPLICATE: `${PATH_ADMIN_QUESTION.DEFAULT}/duplicate-question`,
    COPY: `${PATH_ADMIN_QUESTION.DEFAULT}/copy-question`,
    MOVE: `${PATH_ADMIN_QUESTION.DEFAULT}/move-question`,
    UPDATE: `${PATH_ADMIN_QUESTION.DEFAULT}/update-question`,
    DELETE: `${PATH_ADMIN_QUESTION.DEFAULT}/delete-question`,
  },

  ADMIN_QUESTION_RESOURCE: {
    DETAIL: `${PATH_ADMIN_QUESTION_RESOURCE.DEFAULT}/detail-question-resource`,
    UPDATE: `${PATH_ADMIN_QUESTION_RESOURCE.DEFAULT}/update-question-resource`,
    GET_QUESTION_RESOURCE: `${PATH_ADMIN_QUESTION_RESOURCE.DEFAULT}/get-question-resource`
  },

  ADMIN_EXAM: {
    LIST: `${PATH_ADMIN_EXAM.DEFAULT}/list-exam`,
    DETAIL: `${PATH_ADMIN_EXAM.DEFAULT}/detail-exam`,
    CREATE: `${PATH_ADMIN_EXAM.DEFAULT}/create-exam`,
    DUPLICATE: `${PATH_ADMIN_EXAM.DEFAULT}/duplicate-exam`,
    COPY: `${PATH_ADMIN_EXAM.DEFAULT}/copy-exam`,
    MOVE: `${PATH_ADMIN_EXAM.DEFAULT}/move-exam`,
    UPDATE: `${PATH_ADMIN_EXAM.DEFAULT}/update-exam`,
    DELETE: `${PATH_ADMIN_EXAM.DEFAULT}/delete-exam`,
  },
  
  ADMIN_EXAM_AUTHORING: {
    UPDATE_AUTHORING_EXAM: `${PATH_ADMIN_EXAM.DEFAULT}/update-authoring-exam`
  },
  
  ADMIN_EXAM_NAME: {
    CREATE: PATH_ADMIN_EXAM_NAME.CREATE,
    UPDATE: PATH_ADMIN_EXAM_NAME.UPDATE,
    LIST: PATH_ADMIN_EXAM_NAME.LIST,
    DELETE: PATH_ADMIN_EXAM_NAME.DELETE,
  },

  ADMIN_EXAM_VENUE: {
    LIST_EXAM_VENUE: `${PATH_ADMIN_EXAM_VENUE.DEFAULT}/list-exam-venue`,
    LIST_EXAM_MANAGEMENT: `${PATH_ADMIN_EXAM_VENUE.DEFAULT}/get-list-exam-venue`,
    DETAILS_EXAM_VENUE: `${PATH_ADMIN_EXAM_VENUE.DEFAULT}/get-exam-venue-detail`,
    CREATE_EXAM_VENUE: `${PATH_ADMIN_EXAM_VENUE.DEFAULT}/create-exam-venue`,
    DELETE_EXAM_MANAGEMENT: `${PATH_ADMIN_EXAM_VENUE.DEFAULT}/delete-exam-venue`,
    UPDATE_EXAM_VENUE: `${PATH_ADMIN_EXAM_VENUE.DEFAULT}/update-exam-venue`,
  },

  ADMIN_EXAMINATION: {
    GET_DATA_CREATE_EXAMINATION: `${PATH_ADMIN_EXAMINATION.DEFAULT}/get-data-create-examination`,
    LIST_EXAMINATION: `${PATH_ADMIN_EXAMINATION.DEFAULT}/get-list-examination`,
    DETAIL_EXAMINATION: `${PATH_ADMIN_EXAMINATION.DEFAULT}/detail`,
    CREATE_EXAMINATION: `${PATH_ADMIN_EXAMINATION.DEFAULT}/create-examination`,
    UPDATE_EXAMINATION: `${PATH_ADMIN_EXAMINATION.DEFAULT}/update-examination`,
    DELETE_EXAMINATION: `${PATH_ADMIN_EXAMINATION.DEFAULT}/delete-examination`
  },

  ADMIN_EXAM_PARTICIPATE: {
    GET_LIST_EXAMINATION: `${PATH_ADMIN_EXAM_PARTICIPATE.DEFAULT}/get-list-examination`,
    GET_LIST_EXAMINATION_CANDIDATE: `${PATH_ADMIN_EXAM_PARTICIPATE.DEFAULT}/get-list-examination-candidate`
  },

  ADMIN_EXAM_RESULT: {
    LIST_EXAM_RESULT: `${PATH_ADMIN_EXAM_RESULT.DEFAULT}/get-list-exam-result`,
    GET_CANDIDATE_EXAM_DETAIL:`${PATH_ADMIN_EXAM_RESULT.DEFAULT}/get-candidate-exam-detail`,
    SAVE_RESULT_CANDIDATE_EXAM: `${PATH_ADMIN_EXAM_RESULT.DEFAULT}/save-update-result-candidate-exam`
  }
};

export { API };
