import React from 'react';
import HomePageBase from '.';

const HomePage = () => {
  return (
    <HomePageBase>
    </HomePageBase>
  );
};

export default HomePage;
