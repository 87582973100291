import React, { useEffect, useRef, useState } from "react";
import SelectCandidateBirthDate from "./SelectCandidateBirthDate";
import {
  swalClose,
  swalError,
  swalLoading,
  swalSuccess,
  swalConfirm,
} from "../../../services/helpers/swal";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { Candidate } from "../../../types/admin/candidate";
import { TbTrash } from "react-icons/tb";
import { RiErrorWarningLine } from "react-icons/ri";
import { BsCheck2Circle } from "react-icons/bs";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../commons/form/ErrorBox";
import postal_code from "japan-postal-code";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATH_ADMIN_CANDIDATE } from "../../../services/constants/route/router";
import { USER_STATUS } from "../../../services/constants/user";
import {
  CANDIDATE_GENDER,
  CANDIDATE_STATUS_DATA,
} from "../../../services/constants/admin/pages/candidate";
import PenEdit from "../../commons/icons/PenEdit";
import ImageInternalButton from "../../commons/buttons/ImageInternalButton";
import { MESSAGE_COMMON } from "../../../services/constants/message";
import { PICTURE_TYPE } from "../../../services/constants/globals";

type MainGroup = {
  code: string;
  id: number;
  name: string;
  order: number;
  parent_group_id: null;
  status: number;
  candidate_count: number;
  sub_groups: SubGroup[];
};

type SubGroup = {
  code: string;
  id: number;
  name: string;
  order: number;
  parent_group_id: number;
  status: number;
  candidate_count: number;
};

type GroupGroup = {
  mainGroupId: number;
  subGroupId: number;
};
const CandidateUpdate = () => {
  const [candidateFormData, setCandidateFormData] = useState<
    Candidate & {
      new_email?: string;
      delete_avatar?: number;
    }
  >({
    status: 0,
  } as any);
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [errors, setErrors] = useState<ErrorData>({});
  const [mainGroups, setMainGroups] = useState<MainGroup[]>([]);
  const [groupGroups, setGroupGroups] = useState<GroupGroup[]>([
    { mainGroupId: 0, subGroupId: 0 },
  ]);
  const [zipCode, setZipCode] = useState<string>("");
  const [zipCodePrefix, setZipCodePrefix] = useState<string>("");
  const [isChangeEmail, setIsChangeEmail] = useState<boolean>(false);
  const params = useParams();
  const navigate = useNavigate();
  const inputPrefixRef = useRef<HTMLInputElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchMainGroup = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_GROUP.GET_GROUP_LIST}`,
      });

      if (!result.data) return swalError();

      const mainGroups = result.data.map((mainGroupData: any) => {
        const subGroups = mainGroupData.sub_group.map((subGroupData: any) => ({
          id: subGroupData.id,
          name: subGroupData.name,
        }));
        return {
          id: mainGroupData.id,
          name: mainGroupData.name,
          sub_groups: subGroups,
        };
      });
      setMainGroups(mainGroups);
      swalClose();
    };

    const fetchExistingCandidateDetails = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_CANDIDATE.GET_CANDIDATE_DETAILS}?candidate_id=${params.id}`,
      });

      if (!result.data) return swalError();

      const candidate = result.data;
      if (candidate.new_email) {
        setIsChangeEmail(true);
      }

      candidate["candidate_id"] = candidate["id"];
      if (!candidate["birthday"]) {
        delete candidate["birthday"];
      }
      delete candidate["id"];
      delete candidate["avatar"];
      for (let key in candidate) {
        if (candidate[key] === null) {
          delete candidate[key];
        }
      }

      if (candidate["zip_code"] && candidate["zip_code"].length === 7) {
        setZipCode(candidate["zip_code"].substring(0, 3));
        setZipCodePrefix(candidate["zip_code"].substring(3));
      }

      const existingGroups = candidate.groups || [];
      let newGroupGroups = existingGroups.map((existingGroup: any) => {
        if (existingGroup.parent_group_id === null) {
          // Only main group exists
          return {
            mainGroupId: existingGroup.id,
            subGroupId: 0,
          };
        } else {
          // Main group and sub group both exist
          return {
            mainGroupId: existingGroup.parent_group_id,
            subGroupId: existingGroup.id,
          };
        }
      });

      if (existingGroups.length === 0) {
        newGroupGroups = [
          {
            mainGroupId: 0,
            subGroupId: 0,
          },
        ];
      }
      setCandidateFormData(candidate);
      setGroupGroups(newGroupGroups);
      swalClose();
    };

    fetchExistingCandidateDetails();
    fetchMainGroup();
  }, []);

  const handleMainGroupChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const newGroupGroups = [...groupGroups];
    newGroupGroups[index].mainGroupId = parseInt(event.target.value);
    newGroupGroups[index].subGroupId = 0;
    setGroupGroups(newGroupGroups);
  };
  const handleSubGroupChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const newGroupGroups = [...groupGroups];
    newGroupGroups[index].subGroupId = parseInt(event.target.value);
    setGroupGroups(newGroupGroups);
  };

  const handleAddGroupGroup = () => {
    setGroupGroups([...groupGroups, { mainGroupId: 0, subGroupId: 0 }]);
  };

  const handleDeleteGroupGroup = (index: number) => {
    const newGroupGroups = [...groupGroups];
    newGroupGroups.splice(index, 1);
    setGroupGroups(newGroupGroups);
  };

  const isGroupGroupValid = (index: number) => {
    const groupGroup = groupGroups[index];
    return (
      groupGroup.mainGroupId !== 0 &&
      groupGroup.subGroupId !== 0 &&
      !groupGroups.some(
        (sg, i) =>
          i !== index &&
          sg.mainGroupId === groupGroup.mainGroupId &&
          sg.subGroupId === groupGroup.subGroupId
      )
    );
  };
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (/^\d{0,3}$/.test(input)) {
      setZipCode(input);
      if (input.length === 3 && inputPrefixRef.current) {
        inputPrefixRef.current.focus();
      }
    }
  };
  const handleZipCodePrefixChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = e.target.value;
    if (/^\d{0,4}$/.test(input)) {
      setZipCodePrefix(input);
    }
  };
  const handleZipCodePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData("text/plain");
    const matches = pastedData.match(/^(\d{3})-(\d{4})$/);
    if (matches) {
      setZipCode(matches[1]);
      setZipCodePrefix(matches[2]);
      e.preventDefault();
    }
  };
  const emailValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { name, value } = e.target;
    setCandidateFormData({ ...candidateFormData, [name]: value });
    if (value.match(re)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const file =
      (e.target as HTMLInputElement).files &&
      (e.target as HTMLInputElement).files?.[0];
    if (file) {
      const byteSize = 2097152;
      if (file.size > byteSize) {
        setErrors({
          maxSize: ["ファイルサイズが2MBの最大制限を超えています。"],
        });
        return;
      }
      if (!PICTURE_TYPE.includes(file.type)) {
        setErrors({
          type: [MESSAGE_COMMON.INVALID_FILE_TYPE],
        });
        return;
      }
      setCandidateFormData({ ...candidateFormData, [name]: file });
      return;
    }

    switch (name) {
      case "status":
      case "gender":
        setCandidateFormData({ ...candidateFormData, [name]: parseInt(value) });
        break;
      default:
        setCandidateFormData({ ...candidateFormData, [name]: value });
        break;
    }
  };

  const handleGenderReset = (value: number) => {
    if (candidateFormData.gender === value) {
      setCandidateFormData({
        ...candidateFormData,
        gender: CANDIDATE_GENDER.OTHER,
      });
    }
  };

  const syncingZipCode = () => {
    postal_code.get(`${zipCode}${zipCodePrefix}`, (object) =>
      setCandidateFormData({
        ...candidateFormData,
        province: object.prefecture,
        ward: object.city,
        address: object.area,
        building: object.street,
      })
    );
  };
  const handleDelete = async () => {
    swalConfirm(async () => {
      const result = await makeRequest({
        method: "delete",
        url: API.ADMIN_CANDIDATE.DELETE_CANDIDATE,
        data: { candidate_ids: [candidateFormData.candidate_id] },
      });
      if (!result.status) {
        return swalError();
      }
      swalSuccess();
      navigate(PATH_ADMIN_CANDIDATE.DEFAULT);
    }, "この受講者を削除して<br>よろしいですか？");
  };

  const handleSubmit = async () => {
    let zipcode = `${zipCode}${zipCodePrefix}`;
    const filteredGroupGroups = groupGroups
      .flatMap(({ mainGroupId, subGroupId }) =>
        subGroupId !== 0 ? [subGroupId] : mainGroupId !== 0 ? [mainGroupId] : []
      )
      .filter((num) => num !== 0)
      .filter((num, index, self) => self.indexOf(num) === index);
    const result = await makeRequest({
      method: "post",
      url: API.ADMIN_CANDIDATE.UPDATE_CANDIDATE,
      data: {
        ...candidateFormData,
        group_ids: filteredGroupGroups,
        zip_code: zipcode,
      },
      hasFileRequest: true,
    });

    if (!result.status) {
      setErrors(result.error as ErrorData);
      return swalError();
    }

    swalSuccess();
    navigate(PATH_ADMIN_CANDIDATE.DEFAULT);
  };

  const removeImage = () => {
    const newData: Candidate & { new_email?: string; delete_avatar?: number } =
      {
        ...candidateFormData,
        avatar: undefined,
        avatar_url: undefined,
        delete_avatar: 1,
      };
    setCandidateFormData(newData);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  return (
    <>
      <div className="mt-[40px]">基本情報</div>
      <div className="mb-[25px] mt-[10px]">
        {errors && Object.keys(errors).length > 0 && (
          <ErrorBox errors={errors} />
        )}
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">ログインID（半角英数のみ８〜20）</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mx-[5px] mr-[30px]">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] max-w-[550px]"
              name="username"
              value={candidateFormData.username}
              onChange={emailValidation}
            />
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">メールアドレス</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] mx-[5px] mr-[30px] py-[10px]">
            <div className="flex items-center">
              <div className="basis-[50%] text-[13px] leading-[100%] flex items-center pl-[12px] h-[30px] max-w-[550px]">
                {candidateFormData.email}
              </div>
              <div className="basis-[50%]">
                {candidateFormData.status === USER_STATUS.CONFIRMING ||
                !candidateFormData.status ? (
                  <div className="w-fit flex items-center gap-x-[5px] ml-[16px] px-[7px] py-[2px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap text-xs">
                    <RiErrorWarningLine size={18} />
                    <p>確認中</p>
                  </div>
                ) : (
                  <div className="w-fit flex items-center gap-x-[5px] ml-[5px] px-[7px] py-[2px] text-[#749F91] bg-[#D7F5F2] rounded-[5px] whitespace-nowrap text-xs ">
                    <BsCheck2Circle size={18} />
                    <p>確認済み</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full mt-[12px]">
              {isChangeEmail ? (
                <div className="flex items-center gap-x-[12px]">
                  <div className="basis-[50%] ">
                    <input
                      className="text-[13px] leading-[100%] flex items-center pl-[12px] h-[30px] max-w-[550px"
                      name="new_email"
                      value={candidateFormData.new_email || ""}
                      onChange={emailValidation}
                    />
                  </div>
                  <div className="basis-[50%] flex items-center gap-x-[16px]">
                    <div className="flex items-center gap-x-[5px] w-fit px-[7px] py-[2px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap text-xs">
                      <RiErrorWarningLine size={18} />
                      <p>確認中</p>
                    </div>
                    <span className="text-[12px]">
                      メールは確認されるまで、古いメールで利用可能
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className="w-fit max-h-[24px] flex items-center ml-[12px] px-[10px] py-[6px] bg-primary text-white text-[12px] font-[500] leading-[100%] rounded-[5px] cursor-pointer"
                  onClick={() => setIsChangeEmail(true)}
                >
                  <PenEdit />
                  メールアドレスを変更
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">ステータス</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mx-[5px]">
            <label className="inline-flex items-center mr-[15px]">
              <input
                type="radio"
                className="form-radio text-primary-light border border-success-extralight"
                name="status"
                value={CANDIDATE_STATUS_DATA.ACTIVE}
                checked={
                  candidateFormData.status === CANDIDATE_STATUS_DATA.ACTIVE
                }
                onChange={handleChange}
              />
              <span className="ml-[8px] text-[12px] leading-[100%]">有効</span>
            </label>
            <label className="inline-flex items-center mr-[15px]">
              <input
                type="radio"
                className="form-radio text-primary-light border border-success-extralight"
                name="status"
                value={CANDIDATE_STATUS_DATA.DEACTIVE}
                checked={
                  candidateFormData.status === CANDIDATE_STATUS_DATA.DEACTIVE
                }
                onChange={handleChange}
              />
              <span className="ml-[8px] text-[12px] leading-[100%]">
                停止中
              </span>
            </label>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">受講者氏名</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full mr-[21px]">
            <div className="text-secondary-dark w-full flex items-center mx-[5px]">
              <input
                className="text-[13px] leading-[100%] ml-[8px] flex items-center pl-[15.5px] h-[30px]"
                name="first_name"
                value={candidateFormData.first_name}
                onChange={handleChange}
              />
            </div>
            <div className="text-secondary-dark] w-full flex items-center mx-[5px]">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]"
                name="last_name"
                value={candidateFormData.last_name}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="">受講者氏名（カナ）</div>
          </div>
          <div className="flex justify-between w-full mr-[21px]">
            <div className="text-secondary-dark w-full flex items-center mx-[5px]">
              <input
                className="text-[13px] leading-[100%] ml-[8px] flex items-center pl-[15.5px] h-[30px]"
                name="first_name_kana"
                value={candidateFormData.first_name_kana}
                onChange={handleChange}
              />
            </div>
            <div className="text-secondary-dark] w-full flex items-center mx-[5px]">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]"
                name="last_name_kana"
                value={candidateFormData.last_name_kana}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pl-[14px] pt-[10px]">
            <div className="w-full flex justify-between">
              <div className="">グループ</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            {groupGroups.map((groupGroup, index) => (
              <div className="flex justify-between w-full max-h-[40px] mx-[5px]">
                <div className="text-secondary-dark w-full flex items-center mx-[5px] relative">
                  <select
                    className="h-[30px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark text-[13px] leading-[20px] w-full !py-0"
                    name="group_ids"
                    onChange={(event) => handleMainGroupChange(event, index)}
                    value={groupGroup.mainGroupId}
                  >
                    <option value=""></option>
                    {mainGroups.map((mainGroup) => (
                      <option
                        className="text-[13px] leading-[100%]"
                        key={mainGroup.id}
                        value={mainGroup.id}
                      >
                        {mainGroup.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-secondary-dark w-full flex items-center mx-[5px] relative">
                  <select
                    className="h-[30px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark text-[13px] leading-[20px] w-full !py-0"
                    name="group_ids"
                    onChange={(event) => handleSubGroupChange(event, index)}
                    value={groupGroup.subGroupId}
                  >
                    <option value="0"></option>
                    {mainGroups
                      .find((ms) => ms.id === groupGroup.mainGroupId)
                      ?.sub_groups.map((subGroup) => (
                        <option
                          className="text-[13px] leading-[100%]"
                          key={subGroup.id}
                          value={subGroup.id}
                        >
                          {subGroup.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="text-secondary-dark flex items-center w-[25px]">
                  {index > 0 ? (
                    <TbTrash
                      className="mb-[4px] text-[#7A7A7A]"
                      onClick={() => handleDeleteGroupGroup(index)}
                      size={50}
                    />
                  ) : (
                    <TbTrash
                      className="mb-[4px] text-secondary-light invisible"
                      size={50}
                    />
                  )}
                </div>
              </div>
            ))}
            <button
              className={`bg-primary-darker text-white text-[12px] font-[500] leading-[17px] flex items-center justify-center ml-[10px] rounded-[5px] min-h-[25px] w-full max-w-[180px] cursor-pointer mb-[5px]`}
              onClick={handleAddGroupGroup}
            >
              <span className="text-[23px] mb-[3px] mr-[8px]">+</span>{" "}
              所属グループを追加
            </button>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">性別</div>
              {/* <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div> */}
            </div>
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mx-[5px]">
            <label className="inline-flex items-center mr-[15px]">
              <input
                type="radio"
                className="form-radio text-primary-light border border-success-extralight"
                name="gender"
                value={CANDIDATE_GENDER.MALE}
                checked={candidateFormData.gender === CANDIDATE_GENDER.MALE}
                onChange={handleChange}
                onClick={() => handleGenderReset(CANDIDATE_GENDER.MALE)}
              />
              <span className="ml-[8px] text-[12px] leading-[100%]">男性</span>
            </label>
            <label className="inline-flex items-center mr-[15px]">
              <input
                type="radio"
                className="form-radio text-primary-light border border-success-extralight"
                name="gender"
                value={CANDIDATE_GENDER.FEMALE}
                checked={candidateFormData.gender === CANDIDATE_GENDER.FEMALE}
                onChange={handleChange}
                onClick={() => handleGenderReset(CANDIDATE_GENDER.FEMALE)}
              />
              <span className="ml-[8px] text-[12px] leading-[100%]">女性</span>
            </label>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            生年月日
          </div>

          <div className="text-secondary-dark ml-[13px] w-[85%] text-[12px] leading-[100%]">
            <SelectCandidateBirthDate
              candidateFormData={candidateFormData}
              setBirthDate={setCandidateFormData}
            />
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            郵便番号
          </div>

          <div className="text-secondary-dark ml-[13px] w-[40%] flex gap-[7px] items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[80px]"
              name="zip_code"
              value={zipCode}
              onChange={handleZipCodeChange}
              onPaste={handleZipCodePaste}
            />
            <div className="w-[12px] bg-secondary-light h-[1px]"></div>
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[80px]"
              name="zip_code_prefix"
              value={zipCodePrefix}
              onChange={handleZipCodePrefixChange}
              onPaste={handleZipCodePaste}
              ref={inputPrefixRef}
            />
            <div
              onClick={syncingZipCode}
              className={`px-[3.5px] ${
                zipCode || zipCodePrefix ? "bg-primary" : "bg-[#BEBEBE]"
              } text-white text-[9px] font-[500] leading-[100%] w-[52px] h-[22px] flex items-center justify-center rounded-[5px] cursor-pointer ml-[5px]`}
            >
              自動入力
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pt-[12px] pl-[14px]">
            住所
          </div>

          <div className="text-secondary-dark ml-[13px] items-center text-[13px] leading-[100%] w-[85%] mr-[30px]">
            <div className="relative w-[300px]">
              <input
                name="province"
                className="text-[13px] h-[30px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark  leading-[100%] min-w-[300px]"
                onChange={handleChange}
                value={candidateFormData.province}
              />
            </div>
            <input
              type="text"
              name="ward"
              value={candidateFormData.ward}
              id=""
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[30px]"
              onChange={handleChange}
            />
            <input
              type="text"
              name="address"
              value={candidateFormData.address}
              id=""
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[30px]"
              onChange={handleChange}
            />
            <input
              type="text"
              name="building"
              value={candidateFormData.building}
              id=""
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            電話番号
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mr-[30px]">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]"
              name="phone_number"
              value={candidateFormData.phone_number}
              onChange={handleChange}
              placeholder="03-1234-5678"
            />
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pt-[10px] pl-[14px]">
            プロフィール画像
          </div>

          <div className="text-secondary-dark ml-[13px] w-[85%] text-[12px] leading-[100%]">
            <div className="flex items-center">
              {candidateFormData.avatar || candidateFormData.avatar_url ? (
                <div className="relative max-w-[330px] object-cover mr-4 border-gray-200 my-[5px]">
                  <ImageInternalButton
                    removeImage={removeImage}
                    editImage={() => inputFileRef.current?.click()}
                  />
                  <img
                    src={
                      candidateFormData.avatar
                        ? URL.createObjectURL(candidateFormData.avatar)
                        : candidateFormData.avatar_url
                    }
                    alt="Profile Avatar"
                  />
                </div>
              ) : (
                <div
                  className="w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
                  onClick={() => inputFileRef.current?.click()}
                ></div>
              )}
              <label
                htmlFor="avatar"
                className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-[#BEBEBE]"
              >
                ファイルを選択
              </label>
              <input
                type="file"
                id="avatar"
                name="avatar"
                accept="image/*"
                className="hidden"
                ref={inputFileRef}
                onChange={handleChange}
                placeholder="ファイルを選択"
              />
              {!candidateFormData.avatar && !candidateFormData.avatar_url && (
                <div className="ml-[10px]">選択されていません</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center mb-[60px] mt-[60px] gap-10">
        <Link to={PATH_ADMIN_CANDIDATE.DEFAULT}>
          <button className="bg-[#BEBEBE] px-[64px] pt-[9px] pb-[11px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]">
            一覧画面へ戻る
          </button>
        </Link>
        <button
          className="bg-primary px-[64px] pt-[9px] pb-[11px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]"
          onClick={handleSubmit}
        >
          入力内容を登録
        </button>
        <button
          className="bg-secondary px-[25px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%] max-h-[38px] flex items-center"
          onClick={handleDelete}
        >
          <div className="">このアカウントを削除</div>
          <TbTrash className="ml-[4px] text-white " size={30} />
        </button>
      </div>
    </>
  );
};

export default CandidateUpdate;
