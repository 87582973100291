import React, { HTMLProps } from "react";
import { MdOutlineCreate } from "react-icons/md";

const EditButton = ({
  label,
  showIcon = true,
  ...props
}: {
  label: string;
  showIcon?: boolean;
} & React.HTMLProps<HTMLDivElement>) => {
  return (
    <div {...props}>
      <div className="cursor-pointer text-white font-[700] text-[12px] leading-[17px] tracking-[0.02em] bg-primary-synch rounded-[20px] flex justify-center items-center w-fit pl-[22px] pr-[20px] h-[28px] gap-[9px]">
        {showIcon && <MdOutlineCreate size={17} />}
        {label}
      </div>
    </div>
  );
};

export default EditButton;
