import React, { useEffect } from 'react'
import LayoutLandingPage from "../layouts/LayoutLandingPage";

const Home = () => {
  useEffect(() => {
    window.location.href = '/site-login';
  }, []);

  return (
    <LayoutLandingPage>
      <div className="max-w-[1440px] mx-auto bg-warning-extralight pt-[300px] h-[600px] text-center mt-[20px]">
        HOME PAGE
      </div>
    </LayoutLandingPage> 
  )
}

export default Home
