import DetailQuestion from '../DetailQuestion';
import SideManagement from './SideManagement';

const ListQuestion = () => {
  
  return (
    <section className="flex justify-start gap-[20px]">
      <SideManagement />
      <DetailQuestion />
    </section>
  );
};

export default ListQuestion;
