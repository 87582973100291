import React from "react";
import ReactPlayer from "react-player";
const VideoPlayer = ({
  source,
  width,
  height,
  onLoad,
  setResourceProgressTime
}: {
  source?: string;
  width?: number;
  height?: number;
  onLoad?: () => void;
  setResourceProgressTime?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const handleContextMenu = (
    e: React.MouseEvent<HTMLVideoElement, MouseEvent>
  ) => {
    e.preventDefault();
  };
  return (
    <ReactPlayer
      url={source}
      controls={true}
      muted={false}
      width={width}
      height={height}
      progressInterval={500}
      onProgress={(progress) => {
        setResourceProgressTime?.(progress.playedSeconds * 1000);
      }}
      onContextMenu={handleContextMenu}
      onReady={onLoad}
      config={{
        file: {
          attributes: {
            controlsList: "nodownload",
          },
        },
      }}
    />
  );
};

export default VideoPlayer;
