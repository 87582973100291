import { TestTakerUploadProblem } from '../../../../../../../types/admin/question';
import AuthoringTextEditor from '../../AuthoringTextEditor';
import { RenderDroppedQuestionTypeProps } from '../../DroppedQuestionType';
import TestTakerUploader from './TestTakerUploader';

type TestTakerUploadQuestionProps = {
  problemPreview?: TestTakerUploadProblem;
  actionable?: boolean;
} & Omit<RenderDroppedQuestionTypeProps, 'draggedName' | 'problemPreview'>;

const TestTakerUploadQuestion = ({ problemPreview }: TestTakerUploadQuestionProps) => {
  if (!problemPreview || !problemPreview.id) return <></>;

  return (
    <div className="">
      <div className="no_border_editor">
        <AuthoringTextEditor
          toolbarId={`problem_preview_${problemPreview.resource_id}_${problemPreview.id}`}
          isReadOnly
          value={problemPreview?.title || ''}
        />
      </div>
      <div className="flex justify-center items-center z-[100] pb-[20px]">
        <TestTakerUploader problemData={problemPreview} />
      </div>
    </div>
  );
};

export default TestTakerUploadQuestion;
