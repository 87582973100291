import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import FieldInput from "./UpsertComponent/FieldInput";
import SubmitButton from "../../commons/SubmitButton";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Trash from "../../../commons/icons/Trash";
import { makeRequest, request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { swalError, swalMessage } from "../../../../services/helpers/swal";
import { TbCirclePlus } from "react-icons/tb";
import { ExamName } from "../../../../types/admin/examName";
import TestItem from "./UpsertComponent/TestItem";
import { useListTestContext } from "../../../../context/ListTestContext";
import { ExamVenue } from "../../../../types/admin/examManagement";
import SelectGroup from "./UpsertComponent/SelectGroup";
import SelectExamVenue from "./UpsertComponent/SelectExamVenue";
import {
  ExamVenueSelected,
  Examination,
  ExaminationVenueTime,
  MainGroup,
} from "../../../../types/admin/examination";
import {
  SERVICE_FEATURE,
  TYPE_DATE_FORMAT,
} from "../../../../services/constants/globals";
import { FOLDER_UPLOAD } from "../../../../services/constants/folderUpload";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import dayjs from "dayjs";
import {
  deleteFile,
  uploadFileToS3,
} from "../../../../services/utils/upload/s3Upload";
import { ErrorData } from "../../../../types/globals";
import ErrorBox from "../../../commons/form/ErrorBox";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import {
  EXAMINATION_FORMAT,
  EXAMINATION_STATUS,
} from "../../../../services/constants/admin/pages/examination";
import FieldInputRadio from "./UpsertComponent/FieldInputRadio";
import CalendarDatePicker from "./UpsertComponent/CalendarDatePicker";
import { useNavigate } from "react-router-dom";
import { PATH_ADMIN_EXAMINATION } from "../../../../services/constants/route/router";
import classNames from "classnames";
import Exam from "../../../commons/icons/Sidebar/Exam";
import { isEmptyObj } from "../../../../services/helpers/etc";
import ImageInternalButton from "../../../commons/buttons/ImageInternalButton";

const CreateExamination = () => {
  const navigate = useNavigate();
  const { getParamValue } = useObjectRoutes();
  const examinationId = getParamValue("id");
  const subdomain = window.location.hostname.split(".")[0];
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [examination, setExamination] = useState<Examination>({});
  const [examNames, setExamNames] = useState<ExamName[]>([]);
  const [examNameInput, setExamNameInput] = useState<string>("");
  const [date, setDate] = useState<Date | null>(new Date());
  const [startDateRegistration, setStartDateRegistration] =
    useState<Date | null>(new Date());
  const [endDateRegistration, setEndDateRegistration] = useState<Date | null>(
    new Date()
  );
  const [examVenues, setExamVenues] = useState<ExamVenue[]>([]);
  const [mainGroups, setMainGroups] = useState<MainGroup[]>();
  const [previewImage, setPreviewImage] = useState<string>("");
  const [examinationImagePath, setExamiantionImagePath] = useState<string>("");
  const [errors, setErrors] = useState<ErrorData>({});
  const {
    tests,
    setTests,
    selectedTest,
    setSelectedTest,
    groupGroups,
    setGroupGroups,
  } = useListTestContext();
  const [examVenueSelecteds, setExamVenueSelecteds] = useState<
    ExamVenueSelected[]
  >([
    {
      exam_venue_id: 0,
      time: [],
    },
  ]);
  const [showSelectName, setShowSelectName] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [examNameId, setExamNameId] = useState<ExamName["id"]>();
  const examNameModalRef = useRef<HTMLDivElement>(null);
  const startDateCalendarRef = useRef<HTMLDivElement>(null);
  const endDateCalendarRef = useRef<HTMLDivElement>(null);
  const examModalRef = useRef<HTMLDivElement>(null);
  const [deleteImage, setDeleteImage] = useState<boolean>(false)

  const handleChangeShowSelectName = (name: string) => {
    if (showSelectName === name) {
      setShowSelectName("");
      return;
    }

    setShowSelectName(name);
  };

  const handleCreateExamName = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      await request.post(
        API.ADMIN_EXAM_NAME.CREATE,
        { name: examNameInput },
        (data) => {
          setExamNames([data, ...examNames]);
          setExamNameInput("");
        }
      );
    }
  };

  const handleUpdateExamName = async (
    e: React.KeyboardEvent<HTMLInputElement>,
    examNameId: number
  ) => {
    if (e.key !== "Enter") return;

    await request.patch(
      API.ADMIN_EXAM_NAME.UPDATE,
      {
        id: examNameId,
        name: examNameInput,
      },
      (data) => {
        setExamNames((examNames) =>
          examNames.map((examName) => {
            if (examName.id !== examNameId) return examName;

            return { ...examName, name: examNameInput };
          })
        );
        setExamNameId(undefined);
        setExamNameInput("");
      },
      () => {}
    );
  };

  const handleDeleteExamName = async (id: number) => {
    await request.delete(API.ADMIN_EXAM_NAME.DELETE, { id: id }, (data) =>
      setExamNames((examNames) =>
        examNames.filter((examName) => examName.id !== id)
      )
    );
  };

  const handleChangeExamination = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, type, value } = e.target;

    switch (name) {
      case "status":
      case "price":
      case "entry_time":
        setExamination({ ...examination, [name]: Number(value) });
        break;
      case "code":
        if (value.length > 8) return;

        setExamination((examination) => ({
          ...examination,
          [name]: value.replace(/\D/g, ""),
        }));
        break;
      case "is_free":
        const checked = (e.target as HTMLInputElement).checked;
        setExamination((examination) => ({ ...examination, [name]: checked }));
        break;
      default:
        setExamination({ ...examination, [name]: value });
        break;
    }
  };

  const handleChooseRegistrationDate = (date: Date | null, name: string) => {
    setExamination({
      ...examination,
      [name]: date,
    });
  };

  // For CBT
  const handleAddExamVenue = () => {
    setExamVenueSelecteds([
      ...examVenueSelecteds,
      {
        exam_venue_id: 0,
        time: [],
      },
    ]);
  };

  const handleDeleteExamVenue = (index: number) => {
    setExamVenueSelecteds(() =>
      examVenueSelecteds.filter((examVenueSelected, key) => key !== index)
    );
  };

  // Handle Change
  const handleSelectExamVanue = (
    e: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const examVenueSelectedNew = examVenueSelecteds.map(
      (examVenueSelected, key) => {
        if (key === index) {
          return {
            ...examVenueSelected,
            exam_venue_id: Number(e.target.value),
          };
        }

        return examVenueSelected;
      }
    );

    setExamVenueSelecteds(examVenueSelectedNew);
  };

  const handleChooseDatetime = (datetime: Date | null, index: number) => {
    if (!datetime) return;

    const parseDayjs = dayjs(datetime);
    const parseDate = parseDayjs.startOf("minute").toDate();
    const date = parseDayjs.format("MM/DD");
    const time = parseDayjs.format("HH:mm");
    const day = parseDayjs.day();

    const newExamVenueSelect = examVenueSelecteds.map(
      (examVenueSelect, examVenueSelectIndex) => {
        if (examVenueSelectIndex !== index) return examVenueSelect;

        const examVenueSelectTimes = examVenueSelect?.time || [];
        const existingDayIndex = examVenueSelectTimes.findIndex(
          (examVenueSelectTime) => examVenueSelectTime.date === date
        );

        if (existingDayIndex !== -1) {
          const existingTimeIndex = examVenueSelectTimes[
            existingDayIndex
          ].time.findIndex(
            (examVenueSelectTime) =>
              dayjs(examVenueSelectTime).format("HH:mm") === time
          );

          if (existingTimeIndex === -1) {
            examVenueSelectTimes[existingDayIndex].time.push(parseDate);
          }
        } else {
          examVenueSelectTimes.push({
            day: day || 0,
            date: date,
            time: [parseDate],
          });
        }

        return {
          ...examVenueSelect,
          time: examVenueSelectTimes,
        };
      }
    );

    setExamVenueSelecteds(newExamVenueSelect);
  };

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || !files[0]) return;
    const file = files[0];

    if (!file.type.includes("image")) {
      swalMessage(
        "",
        "試験画像には画像ファイルを指定してください。",
        "warning",
        { timer: 1500, showConfirmButton: false }
      );
      return;
    }

    if (file.size > 1024 * 1024 * 10) {
      swalMessage(
        "",
        "試験画像には、10MB以下のファイルを指定してください。",
        "warning",
        { timer: 1500, showConfirmButton: false }
      );
      return;
    }

    const datetime = new Date().getTime();
    const imageType = file.name.split(".")[1];
    const previewUrl = URL.createObjectURL(file);
    const pathFile = `${SERVICE_FEATURE}/${subdomain}/${FOLDER_UPLOAD.EXAMINATION}/${datetime}.${imageType}`;

    setExamination({
      ...examination,
      image_path: pathFile,
    });
    setPreviewImage(previewUrl);
    setFile(file);
    setDeleteImage(false);
  };

  const handleFileInputClear = () => {
    setDeleteImage(true);
    if(fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setExamination({
      ...examination,
      image_path: "",
    });
    setPreviewImage("")
  }

  const handleRemoveImage = () => {
    if (examinationImagePath) {
      deleteFile(examinationImagePath);
    }
    setExamiantionImagePath("");
  }

  const generateMinutes = () => {
    const minutes = [];
    for (let i = 0; i <= 60; i += 10) {
      minutes.push(
        <option key={i} value={i}>
          {i < 10 ? `${i}分前` : `${i}分前`}
        </option>
      );
    }
    return minutes;
  };

  const handleUploadFile = (file: File) => {
    if (!file || !examination.image_path) return;
    uploadFileToS3(file, examination.image_path);

    if (examinationImagePath) {
      deleteFile(examinationImagePath);
    }

    if (examination.image_path) {
      setExamiantionImagePath(examination.image_path);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmit(true);
      if (file) handleUploadFile(file);
      if(deleteImage) handleRemoveImage();

      const filteredGroupGroups = groupGroups
        .flatMap(({ mainGroupId, subGroupId }) =>
          subGroupId !== 0
            ? [subGroupId]
            : mainGroupId !== 0
            ? [mainGroupId]
            : []
        )
        .filter((num) => num !== 0)
        .filter((num, index, self) => self.indexOf(num) === index);

      const data = {
        ...examination,
        exam_venue_address_times: examVenueSelecteds,
        group_ids: filteredGroupGroups,
      };

      if (examinationId) {
        await request.patch(
          API.ADMIN_EXAMINATION.UPDATE_EXAMINATION,
          data,
          (data) => {
            navigate(PATH_ADMIN_EXAMINATION.LIST);
          },
          (errors) => {
            setErrors(errors);
            swalError();
          },
          { withSuccess: true }
        );
      } else {
        await request.post(
          API.ADMIN_EXAMINATION.CREATE_EXAMINATION,
          data,
          (data) => {
            navigate(PATH_ADMIN_EXAMINATION.LIST);
          },
          (errors) => {
            setErrors(errors);
            swalError();
          },
          { withSuccess: true }
        );
      }

      setIsSubmit(false);
    } catch (error) {
      swalError();
      return;
    }
  };

  const updateExamVenueTimes = (
    existingExaminationVenueTimes: ExaminationVenueTime[]
  ) => {
    setExamVenueSelecteds((examVenueSelecteds: ExamVenueSelected[]) => {
      const newExamVenueSelecteds: ExamVenueSelected[] = [];
      existingExaminationVenueTimes.forEach(
        (examinationVenueTime: ExaminationVenueTime) => {
          const parseDayjs = dayjs(examinationVenueTime.time);
          const date: string = parseDayjs.format("MM/DD");
          // const time: string = examinationVenueTime.time?.slice(0, 5) || "";
          // const time: string = parseDayjs.format("HH:mm");
          const parseDate = parseDayjs.toDate();

          const index: number = newExamVenueSelecteds.findIndex(
            (examVenueSelected) =>
              examVenueSelected.exam_venue_id ===
              examinationVenueTime.exam_venue_id
          );

          if (index === -1) {
            newExamVenueSelecteds.push({
              exam_venue_id: examinationVenueTime.exam_venue_id,
              time: [
                {
                  day: examinationVenueTime?.day || 0,
                  date: date,
                  time: [parseDate],
                },
              ],
            });
          } else {
            const examVenueSelected = newExamVenueSelecteds[index];
            const examVenueSelectedDatetime = examVenueSelected.time || [];

            if (!examVenueSelectedDatetime?.length) {
              examVenueSelectedDatetime.push({
                day: examinationVenueTime?.day || 0,
                date: date,
                time: [parseDate],
              });
            }

            const existingDateIndex = examVenueSelectedDatetime.findIndex(
              (examVenueSelected) => examVenueSelected.date === date
            );

            if (existingDateIndex === -1) {
              examVenueSelectedDatetime.push({
                day: examinationVenueTime?.day || 0,
                date: date,
                time: [parseDate],
              });
            } else {
              if (
                !examVenueSelectedDatetime[existingDateIndex].time.includes(
                  parseDate
                )
              ) {
                examVenueSelectedDatetime[existingDateIndex].time.push(
                  parseDate
                );
              }
            }

            newExamVenueSelecteds[index] = examVenueSelected;
          }
        }
      );

      return newExamVenueSelecteds;
    });
  };

  const handleOutsideClick = (e: MouseEvent) => {
    // Hide modal if clicked outside of it
    switch (showSelectName) {
      case "exam":
        if (
          examNameModalRef.current &&
          !examNameModalRef.current.contains(e.target as Node)
        ) {
          setShowSelectName("");
        }
        break;
      case "start_registration_time":
        if (
          startDateCalendarRef.current &&
          !startDateCalendarRef.current.contains(e.target as Node)
        ) {
          setShowSelectName("");
        }
        break;
      case "end_registration_time":
        if (
          endDateCalendarRef.current &&
          !endDateCalendarRef.current.contains(e.target as Node)
        ) {
          setShowSelectName("");
        }
        break;
      case "test":
        if (
          examModalRef.current &&
          !examModalRef.current.contains(e.target as Node)
        ) {
          setShowSelectName("");
        }
        break;
      default:
        break;
    }
  };

  const handleDeleteExamVenueTime = (
    examVenueId: number,
    dateIndex: number,
    timeIndex: number | undefined
  ) => {
    const newExamVenueSelecteds: ExamVenueSelected[] = examVenueSelecteds.map(
      (examVenueSelected) => {
        let newExamVenueSelect: ExamVenueSelected;
        if (examVenueSelected.exam_venue_id === examVenueId) {
          if (timeIndex === undefined) {
            const filterExamDate = examVenueSelected.time?.filter(
              (date, index) => index !== dateIndex
            );

            newExamVenueSelect = { ...examVenueSelected, time: filterExamDate };
          } else {
            const newDateTimeSelected = examVenueSelected.time?.map(
              (date, index) => {
                if (index === dateIndex) {
                  const newExamVenueTime = date.time.filter(
                    (time, index) => index !== timeIndex
                  );

                  return { ...date, time: newExamVenueTime };
                }

                return date;
              }
            );

            newExamVenueSelect = {
              ...examVenueSelected,
              time: newDateTimeSelected,
            };
          }

          return newExamVenueSelect;
        }

        return examVenueSelected;
      }
    );

    setExamVenueSelecteds(newExamVenueSelecteds);
  };

  const handleDataGroups = (groups: Examination["groups"]) => {
    const groupDefault = [
      {
        mainGroupId: 0,
        subGroupId: 0,
      },
    ];

    if (!groups) return groupDefault;

    let newGroups = groups.map((existingGroup: any) => {
      if (existingGroup.parent_group_id === null) {
        // Only main group exists
        return {
          mainGroupId: existingGroup.id,
          subGroupId: 0,
        };
      } else {
        // Main group and sub group both exist
        return {
          mainGroupId: existingGroup.parent_group_id,
          subGroupId: existingGroup.id,
        };
      }
    });

    if (groups.length === 0) {
      newGroups = groupDefault;
    }

    return newGroups;
  };

  const handleChangeExamTest = (examId: number) => {
    setExamination((examination) => ({ ...examination, exam_id: examId }));
    setShowSelectName("");
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await makeRequest({
        method: "get",
        url: API.ADMIN_EXAMINATION.GET_DATA_CREATE_EXAMINATION,
      });

      if (!result.status) {
        swalError();
        return;
      }

      const response = result.data;

      setExamNames(response.exam_names);
      setTests(response.exams);
      setExamVenues(response.exam_venues);

      // handle data setMainGroups
      const mainGroups = response.groups.map((mainGroupData: any) => {
        const subGroups = mainGroupData.sub_group.map((subGroupData: any) => ({
          id: subGroupData.id,
          name: subGroupData.name,
        }));
        return {
          id: mainGroupData.id,
          name: mainGroupData.name,
          sub_groups: subGroups,
        };
      });
      setMainGroups(mainGroups);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!examinationId) return;

    const fetchExminationDetail = async () => {
      await request.get(
        `${API.ADMIN_EXAMINATION.DETAIL_EXAMINATION}?id=${examinationId}`,
        (examination: Examination) => {
          // Handle data groups.
          const existingGroups = examination.groups || [];
          const newGroups = handleDataGroups(existingGroups);

          const existingExaminationVenueTimes: ExaminationVenueTime[] =
            examination.venue_times || [];
          updateExamVenueTimes(existingExaminationVenueTimes);
          setGroupGroups(newGroups);
          setExamination(examination);
          setStartDateRegistration(
            examination?.start_registration_time
              ? new Date(examination?.start_registration_time)
              : new Date()
          );
          setEndDateRegistration(
            examination?.end_registration_time
              ? new Date(examination?.end_registration_time)
              : new Date()
          );
          if (examination.image_path) {
            setExamiantionImagePath(examination.image_path);
          }

          if (examination.image_path) {
            setPreviewImage(
              `${process.env.REACT_APP_AWS_S3_URL}/${examination.image_path}`
            );
          }
        }
      );
    };

    fetchExminationDetail();
  }, [examinationId]);

  useEffect(() => {
    if (!isEmptyObj(selectedTest)) return;

    if (tests && examination.exam_id) {
      setSelectedTest(examination.exam_id);
    }
  }, [tests, examination.exam_id]);

  useEffect(() => {
    if (
      showSelectName === "exam" ||
      showSelectName === "start_registration_time" ||
      showSelectName === "end_registration_time" ||
      showSelectName === "test"
    ) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showSelectName]);

  return (
    <div className="flex flex-col gap-y-[20px] pr-[50px]">
      <div>
        {errors && Object.keys(errors).length > 0 && (
          <ErrorBox errors={errors} />
        )}
        <p className="text-[14px]">基本情報</p>
        <div className="mt-[5px]" ref={examNameModalRef}>
          <FieldInput label="試験" hasChildren={true}>
            <div
              className="w-full h-[30px] border border-[#ebe4d8] rounded-[5px] px-[10px] py-[7px] flex items-center justify-between cursor-pointer"
              onClick={() => handleChangeShowSelectName("exam")}
            >
              <span
                className={`text-[12px] ${
                  examination.exam_name_id
                    ? "text-secondary-dark"
                    : "text-secondary-lighter"
                }`}
              >
                {(examination?.exam_name_id &&
                  examNames.find(
                    (examName) => examName.id === examination.exam_name_id
                  )?.name) ||
                  "試験を選択してください"}
              </span>
              <IoIosArrowDown
                color="#5A5A5A"
                className={`${showSelectName === "exam" ? "rotate-180" : ""}`}
              />
            </div>
          </FieldInput>
          {showSelectName === "exam" ? (
            <div className="flex w-full h-auto">
              <div
                className="basis-[25%]"
                onClick={() => setShowSelectName("")}
              ></div>
              <div className="basis-[75%] flex-1 w-full px-[5px] ml-[5px] mt-[10px] bg-white shadow-lg pb-[20px]">
                <div>
                  <input
                    type="text"
                    className="w-full h-[30px] rounded-[5px] placeholder:text-secondary-light placeholder:text-[12px]"
                    placeholder="＋試験を入力して追加する"
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (examNameId) {
                        handleUpdateExamName(e, examNameId);
                        return;
                      }

                      handleCreateExamName(e);
                    }}
                    onChange={(e) => setExamNameInput(e.target.value)}
                    value={examNameInput}
                  />
                </div>
                <div className="max-h-[200px] overflow-y-scroll mt-[10px]">
                  {examNames.map((examName) => (
                    <div
                      className="h-[30px] flex items-center justify-between mt-[10px] px-[10px]"
                      key={examName.id}
                    >
                      <span
                        className="text-[12px] font-[400] cursor-pointer"
                        onClick={() => {
                          setExamination({
                            ...examination,
                            exam_name_id: examName.id,
                          });
                          setShowSelectName("");
                        }}
                      >
                        {examName.name}
                      </span>
                      <div className="flex items-center gap-x-[10px]">
                        <Exam
                          color="#2E2E2E"
                          className="cursor-pointer"
                          onClick={() => {
                            setExamNameId(examName.id);
                            setExamNameInput(examName?.name || "");
                          }}
                        />
                        <Trash
                          className="cursor-pointer"
                          onClick={() =>
                            handleDeleteExamName(Number(examName.id))
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <FieldInput
            label="試験種類"
            name="type"
            placeholder="試験種類を入力してください"
            value={examination?.type || ""}
            handleChange={handleChangeExamination}
          />
          <FieldInput
            label="概略"
            name="summary"
            required={false}
            placeholder="概略を入力してください"
            value={examination?.summary || ""}
            handleChange={handleChangeExamination}
          />
          <FieldInput label="ステータス" hasChildren={true}>
            <div className="flex items-center gap-x-[15px] px-[12px] mt-[8px]">
              <FieldInputRadio
                label="公開"
                id="public"
                name="status"
                value={EXAMINATION_STATUS.PUBLIC}
                checked={examination.status === EXAMINATION_STATUS.PUBLIC}
                handleChange={handleChangeExamination}
              />
              <FieldInputRadio
                label="非公開"
                id="private"
                name="status"
                value={EXAMINATION_STATUS.PRIVATE}
                checked={examination.status === EXAMINATION_STATUS.PRIVATE}
                handleChange={handleChangeExamination}
              />
            </div>
          </FieldInput>
          <FieldInput label="試験の形式" hasChildren={true}>
            <div className="flex items-center gap-x-[15px] px-[12px] mt-[8px]">
              <FieldInputRadio
                label={EXAMINATION_FORMAT.IBT}
                id="ibt"
                name="format"
                value={EXAMINATION_FORMAT.IBT}
                checked={examination.format === EXAMINATION_FORMAT.IBT}
                handleChange={handleChangeExamination}
              />
              <FieldInputRadio
                label={EXAMINATION_FORMAT.CBT}
                id="cbt"
                name="format"
                value={EXAMINATION_FORMAT.CBT}
                checked={examination.format === EXAMINATION_FORMAT.CBT}
                handleChange={handleChangeExamination}
              />
            </div>
          </FieldInput>
          <FieldInput
            label="試験番号"
            name="code"
            placeholder="試験番号を入力してください"
            value={examination?.code || ""}
            handleChange={handleChangeExamination}
          />
          <FieldInput label="受験対象グループ" hasChildren={true}>
            <SelectGroup mainGroupData={mainGroups || []}></SelectGroup>
          </FieldInput>
          <FieldInput label="無料試験" hasChildren={true}>
            <input
              type="checkbox"
              name="is_free"
              className="w-[24px] h-[24px] cursor-pointer"
              checked={examination.is_free || false}
              onChange={handleChangeExamination}
            />
          </FieldInput>
          {!examination.is_free && (
            <FieldInput
              label="料金（税抜）"
              type="number"
              name="price"
              placeholder="（例）1000"
              value={String(examination?.price || "") || ""}
              handleChange={handleChangeExamination}
              hasChildren={true}
            >
              <div className="flex-1 pt-[5px]">
                <input
                  type="number"
                  name="price"
                  className="w-full h-[30px] text-[12px] placeholder:text-secondary-light placeholder:text-[12px]"
                  placeholder="（例）1000"
                  value={String(examination?.price || "") || ""}
                  onChange={handleChangeExamination}
                />
              </div>
              <p className="py-[5px] text-[10px]">
                試験を販売するためには、パレットエデュケーションの管理画面「販売決済方法」にて必要項目を入力する必要があります
              </p>
            </FieldInput>
          )}

          <FieldInput
            label="試験画像"
            hasChildren={true}
            name="path_image"
            required={false}
          >
            <div className="flex items-center gap-x-[10px]">
              <div
                className="px-[7px] text-[12px] font-[400] bg-success-lighter border border-secondary-light rounded-[2px] cursor-pointer"
                onClick={() => fileInputRef.current?.click()}
              >
                ファイルを選択
              </div>
              {/* <span className="text-[10px]">選択されていません</span> */}
              <input
                hidden
                type="file"
                name="image"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileInputChange}
              />
            </div>
            <div className="flex justify-center my-[10px] max-w-[500px]">
              {previewImage && (
                <div className="relative">
                  <ImageInternalButton 
                    removeImage={handleFileInputClear}
                    editImage={() => fileInputRef.current?.click()}
                  />
                  <img
                    src={previewImage || ""}
                    alt="Examination image"
                    className="w-auto"
                  />
                </div>
              )}
            </div>
          </FieldInput>
        </div>
      </div>
      <div>
        <p className="text-[14px]">テスト配信</p>
        <div className="mt-[5px]" ref={examModalRef}>
          <FieldInput label="テスト" hasChildren={true}>
            <div
              className="w-full h-[30px] border border-[#ebe4d8] rounded-[5px] px-[10px] py-[7px] flex items-center justify-between cursor-pointer"
              onClick={() => handleChangeShowSelectName("test")}
            >
              <span
                className={`text-[12px] ${
                  selectedTest
                    ? "text-secondary-dark"
                    : "text-secondary-lighter"
                }`}
              >
                {selectedTest?.name || "試験を選択してください"}
              </span>
              <IoIosArrowDown
                color="#5A5A5A"
                className={`${showSelectName === "test" ? "rotate-180" : ""}`}
              />
            </div>
          </FieldInput>
          {showSelectName === "test" ? (
            <div className="flex items-center">
              <div className="basis-[25%]"></div>
              <div className="flex flex-col flex-1 bg-white shadow-xl px-[5px] border border-transparent rounded-[5px] max-h-[240px] overflow-y-scroll">
                {tests
                  .filter((test) => !test.parent_id)
                  .map((test, index) => (
                    <TestItem
                      key={test.id}
                      test={test}
                      // setExamination={setExamination}
                      handleChangeExamTest={handleChangeExamTest}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <p className="text-[14px]">日時・会場の情報</p>
        <div className="mt-[5px]">
          {examination.format === EXAMINATION_FORMAT.CBT && (
            <div className="flex border-b border-secondary-lighter">
              <div className="flex items-start justify-between basis-[25%] py-[6px] px-[10px] bg-success-lighter">
                <span className="text-[14px] font-medium">会場（CBTのみ）</span>
                <span className="text-white text-[9px] px-[6px] py-[3px] bg-danger rounded-[3px]">
                  必須
                </span>
              </div>
              <div className="flex-1 px-[10px] mt-[5px]">
                {examVenueSelecteds.map((examVenueSelected, index) => (
                  <SelectExamVenue
                    key={index}
                    date={date}
                    examVenues={examVenues}
                    examVenueIndex={index}
                    examVenueSelected={examVenueSelected}
                    setDate={setDate}
                    handleDeleteExamVenue={handleDeleteExamVenue}
                    handleSelectExamVanue={handleSelectExamVanue}
                    handleChooseDateTime={handleChooseDatetime}
                    handleDeleteExamVenueTime={handleDeleteExamVenueTime}
                  />
                ))}
                <div className="flex justify-center my-[10px]">
                  <button
                    className="flex items-center gap-x-[10px] bg-primary-light text-white text-[13px] px-[50px] py-[7px] rounded-[5px]"
                    onClick={handleAddExamVenue}
                  >
                    <TbCirclePlus color="#FFFFFF" size={24} />
                    <span>会場追加</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          <FieldInput label="入室時間" hasChildren={true}>
            <div className="flex items-center gap-x-[10px]">
              <select
                name="entry_time"
                onChange={handleChangeExamination}
                className="w-[288px] h-[30px] pl-[15px] pr-[8px] rounded-[5px] border border-success-lighter py-0 text-[12px] cursor-pointer"
                value={examination.entry_time}
              >
                {generateMinutes()}
              </select>
              <span className="text-[14px]">試験開始時間前</span>
            </div>
          </FieldInput>
          <div className="flex min-h-[40px] gap-x-[10px] border-b border-secondary-lighte mt-[3px]">
            <div className="flex items-start justify-between basis-[25%] py-[6px] px-[10px] bg-success-lighter">
              <span className="text-[14px] font-medium">申し込み期限</span>
              <span className="text-white text-[9px] px-[6px] py-[3px] bg-danger rounded-[3px]">
                必須
              </span>
            </div>
            <div className="flex items-start gap-x-[10px] pt-[5px]">
              <div
                className="w-[288px] flex flex-col gap-y-[10px]"
                ref={startDateCalendarRef}
              >
                <div
                  className={`w-full flex items-center justify-between px-[10px] h-[30px] border border-success-lighter rounded-[5px] cursor-pointer ${
                    examination.start_registration_time
                      ? "justify-between"
                      : "justify-end"
                  }`}
                  onClick={() =>
                    handleChangeShowSelectName("start_registration_time")
                  }
                >
                  <span className="text-[12px]">
                    {examination.start_registration_time
                      ? formatDateTime(
                          examination.start_registration_time,
                          TYPE_DATE_FORMAT.FULL_TO_MINUTE_DEFAULT
                        )
                      : "開始"}
                  </span>
                  <IoIosArrowDown
                    color="#5A5A5A"
                    className={`${
                      showSelectName === "start_registration_time"
                        ? "rotate-180"
                        : ""
                    }`}
                  />
                </div>
                {showSelectName === "start_registration_time" ? (
                  <CalendarDatePicker
                    name="start_registration_time"
                    date={startDateRegistration}
                    setDate={setStartDateRegistration}
                    value={examination.start_registration_time || ""}
                    handleChange={handleChooseRegistrationDate}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div
                className="w-[288px] flex flex-col gap-y-[10px]"
                ref={endDateCalendarRef}
              >
                <div
                  className={`w-full flex items-center justify-between px-[10px] h-[30px] border border-success-lighter rounded-[5px] cursor-pointer ${
                    examination.end_registration_time
                      ? "justify-between"
                      : "justify-end"
                  }`}
                  onClick={() =>
                    handleChangeShowSelectName("end_registration_time")
                  }
                >
                  <span className="text-[12px]">
                    {examination.end_registration_time
                      ? formatDateTime(
                          examination.end_registration_time,
                          TYPE_DATE_FORMAT.FULL_TO_MINUTE_DEFAULT
                        )
                      : "終了"}
                  </span>
                  <IoIosArrowDown
                    color="#5A5A5A"
                    className={`${
                      showSelectName === "end_registration_time"
                        ? "rotate-180"
                        : ""
                    }`}
                  />
                </div>
                {showSelectName === "end_registration_time" ? (
                  <CalendarDatePicker
                    name="end_registration_time"
                    date={endDateRegistration}
                    setDate={setEndDateRegistration}
                    value={examination.end_registration_time || ""}
                    handleChange={handleChooseRegistrationDate}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="text-[14px]">注意文</p>
        <div className="mt-[5px]">
          <FieldInput
            label="注意文"
            name="info_attendance"
            value={examination.info_attendance}
            handleChange={handleChangeExamination}
            required={false}
          />
        </div>
      </div>
      <div className="mt-[20px] flex items-center justify-center gap-x-[20px]">
        <SubmitButton
          label="一覧画面へ戻る"
          className="bg-secondary-light !rounded-[10px]"
          onSubmit={() => navigate(PATH_ADMIN_EXAMINATION.LIST)}
        />
        <SubmitButton
          label="入力内容を登録"
          className={classNames("bg-primary-light !rounded-[10px]", {
            "opacity-80 hover:bg-none cursor-default": isSubmit,
          })}
          onSubmit={handleSubmit}
          isSubmit={isSubmit}
        />
      </div>
    </div>
  );
};

export default CreateExamination;
