import React, { useState, useEffect, useRef } from "react";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { GENDER_DATA } from "../../../services/constants/candidate/pages/profile";
import { getCommonDate } from "../../../services/helpers/date";
import {
  swalLoading,
  swalMessage,
  swalSuccess,
} from "../../../services/helpers/swal";
import { User } from "../../../types/user";
import SelectBoxBirthDate from "../../commons/form/SelectBoxBirthDate";
import postal_code from "japan-postal-code";
import ErrorBox from "../../commons/form/ErrorBox";
import { ErrorData } from "../../../types/globals";
import { MESSAGE_ERROR } from "../../../services/constants/message";

export type ProfileSettingData = {
  nickname: string;
  gender: number;
  birthday: string | null;
  phone_number: string;
  zip_code: string;
  province: string;
  ward: string;
  address: string;
  building: string;
  job: string;
  residence: string;
  other: string;
  display_info_setting: {
    gender: number;
    birthday: number;
    other: number;
    avatar: number;
    job: number;
    residence: number;
  };
};

type ProfileSettingProps = {
  data: User;
  isEdit?: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileSetting = ({
  data,
  isEdit = false,
  setIsEdit,
}: ProfileSettingProps) => {
  const [profileSetting, setProfileSetting] = useState<ProfileSettingData>(
    data as ProfileSettingData
  );
  const [zipCode, setZipCode] = useState<string>("");
  const [zipCodePrefix, setZipCodePrefix] = useState<string>("");
  const [errors, setErrors] = useState<ErrorData>({});
  const inputPrefixRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (data["zip_code"]) {
      setZipCode(data["zip_code"].substring(0, 3));
      setZipCodePrefix(data["zip_code"].substring(3));
    }
    setProfileSetting(data as ProfileSettingData);
  }, [data?.zip_code]);

  const genderLabel = GENDER_DATA.slice(0, 2).find(
    (gender) => Number(gender.value) === Number(profileSetting?.gender)
  )?.label || "-";

  const addressDisplay = () => {
    const addressFields = [
      profileSetting.province,
      profileSetting.ward,
      profileSetting.address,
      profileSetting.building,
    ];
    const filteredAddressFields = addressFields.filter((field) => !!field);

    return filteredAddressFields.join(", ");
  };
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (/^\d{0,3}$/.test(input)) {
      setZipCode(input);
      if (input.length === 3 && inputPrefixRef.current) {
        inputPrefixRef.current.focus();
      }
    }
  };
  const handleZipCodePrefixChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = e.target.value;
    if (/^\d{0,4}$/.test(input)) {
      setZipCodePrefix(input);
    }
  };
  const handleZipCodePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData("text/plain");
    const matches = pastedData.match(/^(\d{3})-(\d{4})$/);
    if (matches) {
      setZipCode(matches[1]);
      setZipCodePrefix(matches[2]);
      e.preventDefault();
    }
  };
  const syncingZipCode = () => {
    postal_code.get(`${zipCode}${zipCodePrefix}`, (object) =>
      setProfileSetting({
        ...profileSetting,
        province: object.prefecture,
        ward: object.city,
        address: object.area,
        building: object.street,
      })
    );
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProfileSetting({
      ...profileSetting,
      [e.target.name]: e.target.value,
    });
  };

  const handleGenderReset = (value: number) => {
    if(Number(profileSetting.gender) === Number(value)) {
      setProfileSetting({
        ...profileSetting,
        gender: GENDER_DATA[2].value
      })
    }
  }

  const onSubmitHandle = async () => {
    let zipcode = `${zipCode}${zipCodePrefix}`;
    setProfileSetting({
      ...profileSetting,
      zip_code: zipcode,
    });
    const result = await makeRequest({
      method: "patch",
      url: API.CANDIDATE.UPDATE_PROFILE_SETTING,
      data: { ...profileSetting, zip_code: zipcode },
    });

    if (!result.data) {
      setErrors(result.error as ErrorData);
      return swalMessage("", "", "error", {
        timer: 1000,
        showConfirmButton: false,
        footer: MESSAGE_ERROR.SOMETHINGS_WENT_WRONG,
      });
    }

    swalSuccess();
    setIsEdit(false);
    setErrors({});
  };

  return !isEdit ? (
    <div className="mt-[20.5px]">

      <div className="flex min-h-[40px] mb-[5px] border-b-[1px] border-success-extralight">
        <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px]">
          性別
        </div>
        <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
          {genderLabel}
        </div>
      </div>

      <div className="flex min-h-[40px] mb-[5px] border-b-[1px] border-success-extralight">
        <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px]">
          生年月日
        </div>
        <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
          {getCommonDate(profileSetting.birthday)}
        </div>
      </div>

      <div className="flex min-h-[40px] mb-[5px] border-b-[1px] border-success-extralight">
        <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px]">
          電話番号
        </div>
        <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
          {profileSetting.phone_number}
        </div>
      </div>

      <div className="flex min-h-[40px] mb-[5px] border-b-[1px] border-success-extralight">
        <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px]">
          郵便番号
        </div>
        <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
          {profileSetting.zip_code}
        </div>
      </div>
      <div className="flex min-h-[40px] mb-[5px] border-b-[1px] border-success-extralight">
        <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px]">
          住所
        </div>
        <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
          {addressDisplay()}
        </div>
      </div>


    </div>
  ) : (
    <>
      {Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}

      <div className="mb-[80px] mt-[20.5px]">

        <div className="flex min-h-[40px] mb-[5px] border-b-[1px] border-success-extralight">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px]">
            性別
          </div>

          <div className="text-secondary-dark ml-[13px] w-[55%] flex items-center">
            {GENDER_DATA.slice(0, 2).map((item, index) => (
              <label className="inline-flex items-center mr-[15px]" key={index}>
                <input
                  type="radio"
                  className="form-radio text-primary-light border border-success-extralight"
                  name="gender"
                  value={item.value}
                  checked={typeof profileSetting.gender !== 'object' && typeof profileSetting.gender !== 'undefined' && Number(profileSetting.gender) === Number(item.value)}
                  onChange={handleOnChange}
                  onClick={() => {
                    handleGenderReset(item.value)
                  }}
                />
                <span className="ml-[8px] text-[12px] leading-[100%]">
                  {item.label}
                </span>
              </label>
            ))}
          </div>
        </div>

        <div className="flex min-h-[40px] mb-[5px] border-b-[1px] border-success-extralight">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px]">
            生年月日
          </div>

          <div className="text-secondary-dark ml-[13px] w-[55%] text-[12px] leading-[100%]">
            <SelectBoxBirthDate
              profileSetting={profileSetting}
              setProfileSetting={setProfileSetting}
            />
          </div>
        </div>

        <div className="flex min-h-[40px] mb-[5px]">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px]">
            電話番号
          </div>

          <div className="text-secondary-dark ml-[13px] w-full">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[34px] mb-[3px]"
              name="phone_number"
              value={profileSetting.phone_number || ''}
              onChange={handleOnChange}
            />

          </div>
        </div>
        <div className="flex min-h-[40px] mb-[5px] border-b-[1px] border-success-extralight">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px]">
            郵便番号
          </div>

          <div className="text-secondary-dark ml-[13px] w-[40%] flex gap-[7px] items-center pb-[5px]">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[34px] max-w-[80px]"
              name="zip_code"
              value={zipCode}
              onChange={handleZipCodeChange}
              onPaste={handleZipCodePaste}
            />
            <div className="w-[12px] bg-secondary-light h-[1px]"></div>
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[34px] max-w-[80px]"
              name="zip_code_prefix"
              value={zipCodePrefix}
              onChange={handleZipCodePrefixChange}
              onPaste={handleZipCodePaste}
              ref={inputPrefixRef}
            />
            <div
              className="px-[3.5px] bg-primary-light text-white text-[9px] font-[500] leading-[100%] w-[52px] h-[22px] flex items-center justify-center rounded-[5px] cursor-pointer ml-[5px]"
              onClick={syncingZipCode}
            >
              自動入力
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[5px] border-b-[1px] border-success-extralight">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px]">
            住所
          </div>

          <div className="text-secondary-dark ml-[13px] items-center text-[13px] leading-[100%] w-[100%]">
            <input
              type="text"
              name="province"
              value={profileSetting.province || ''}
              onChange={handleOnChange}
              id=""
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[34px]"
              placeholder="例）都道府県"
            />
            <input
              type="text"
              name="ward"
              value={profileSetting.ward || ''}
              onChange={handleOnChange}
              id=""
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[34px]"
              placeholder="例）港区"
            />
            <input
              type="text"
              name="address"
              value={profileSetting.address || ''}
              onChange={handleOnChange}
              id=""
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[34px]"
              placeholder="例）赤坂1-2-34"
            />
            <input
              type="text"
              name="building"
              value={profileSetting.building || ''}
              onChange={handleOnChange}
              id=""
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[34px] mb-[5px]"
              placeholder="例）パレットビル3階"
            />
          </div>
        </div>

        <div className="w-full flex justify-center items-center mb-[146px] mt-[18px]">
          <button
            className="bg-primary-synch px-[64px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]"
            onClick={onSubmitHandle}
          >
            保存する
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfileSetting;
