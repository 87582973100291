import React from 'react';

const TextA = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5002 4C11.778 4 12.033 4.15356 12.163 4.39908L18.913 17.1491C19.1068 17.5152 18.9671 17.969 18.6011 18.1628C18.235 18.3566 17.7811 18.217 17.5873 17.8509L11.5002 6.35295L5.41299 17.8509C5.21919 18.217 4.76531 18.3566 4.39923 18.1628C4.03316 17.969 3.8935 17.5152 4.08731 17.1491L10.8373 4.39908C10.9673 4.15356 11.2223 4 11.5002 4Z"
        style={{
          fill: 'currentcolor',
        }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 13.75C6 13.3358 6.33579 13 6.75 13H16.275C16.6892 13 17.025 13.3358 17.025 13.75C17.025 14.1642 16.6892 14.5 16.275 14.5H6.75C6.33579 14.5 6 14.1642 6 13.75Z"
        style={{
          fill: 'currentcolor',
        }}
      />
    </svg>
  );
};

export default TextA;
