import React, { useState, useContext } from "react";
import { ChildrenWithProps } from "../../types/globals";
import { Question } from "../../types/admin/question";
import { sortArrayByKey } from "../../services/helpers/parseData";
import { ARRANGE_TYPE } from "../../services/constants/admin/pages/exam";
import { request } from "../../services/axios/axios";
import { API } from "../../services/constants/route/api";
import { DeleteIdsType, ExamPartType, ExamType, SettingPropertyOrderType } from "../../types/admin/examAuthoring";
import { swalError } from "../../services/helpers/swal";

type AuthoringExamContextType = {
  exam: ExamType;
  setExam: React.Dispatch<React.SetStateAction<ExamType>>;
  questions: Question[];
  setQuestions: (questions: Question[]) => void;
  selectedFolderQuestion: Question;
  setSelectedFolderQuestion: React.Dispatch<React.SetStateAction<Question>>;
  selectedItems: number[];
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>;
  showDisplayType: string;
  setShowDisplayType: React.Dispatch<React.SetStateAction<string>>;
  totalFile: Number;
  setTotalFile: React.Dispatch<React.SetStateAction<number>>;
  settingPropertyOrders: SettingPropertyOrderType;
  setSettingPropertyOrders: React.Dispatch<
    React.SetStateAction<SettingPropertyOrderType>
  >;
  deleteIds: DeleteIdsType;
  setDeleteIds: React.Dispatch<React.SetStateAction<DeleteIdsType>>;
  waitingAPI: boolean;
  setWaitingAPI: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
};

const AuthoringExamContext = React.createContext<AuthoringExamContextType>({
  exam: {
    exam_parts: [],
  },
  setExam: () => {},
  questions: [],
  setQuestions: () => {},
  selectedFolderQuestion: {},
  setSelectedFolderQuestion: () => {},
  selectedItems: [],
  setSelectedItems: () => {},
  showDisplayType: "",
  setShowDisplayType: () => {},
  totalFile: 0,
  setTotalFile: () => {},
  handleSubmit: () => {},
  settingPropertyOrders: {},
  setSettingPropertyOrders: () => {},
  deleteIds: {
    exam_part_ids: [],
    exam_section_ids: [],
    exam_item_ids: [],
  },
  setDeleteIds: () => {},
  waitingAPI: false,
  setWaitingAPI: () => {},
});

const AuthoringExamProvider = ({ children }: ChildrenWithProps) => {
  const [exam, setExam] = useState<ExamType>({
    exam_parts: [],
  });
  const [questions, _setQuestions] = useState<Question[]>([]);
  const [selectedFolderQuestion, setSelectedFolderQuestion] = useState<Question>({});
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [showDisplayType, setShowDisplayType] = useState<string>(ARRANGE_TYPE.TREE);
  const [totalFile, setTotalFile] = useState<number>(0);
  const [settingPropertyOrders, setSettingPropertyOrders] = useState<SettingPropertyOrderType>({});
  const [waitingAPI, setWaitingAPI] = useState<boolean>(false);
  const [deleteIds, setDeleteIds] = useState<DeleteIdsType>({
    exam_part_ids: [],
    exam_section_ids: [],
    exam_item_ids: [],
  });

  const setQuestions = (questions: Question[]) => {
    const uniqueQuestions = questions.filter(
      (question, index, a) =>
        a.findIndex((_question) => _question.id === question.id) === index
    );
    _setQuestions(sortArrayByKey(uniqueQuestions));
  };

  const handleScoreExamPart = (exam: ExamPartType) => {
    
  }

  const handleSubmit = async () => {
    setWaitingAPI(true);
    await request.patch(
      API.ADMIN_EXAM_AUTHORING.UPDATE_AUTHORING_EXAM,
      {
        exam: exam,
        exam_delete_ids: deleteIds,
      },
      (data) => {
        setExam(data);
        setWaitingAPI(false)
      },
      (error) => {
        swalError();
        setWaitingAPI(false)
      }, {
        withSuccess: true
      }
    );
  };

  return (
    <AuthoringExamContext.Provider
      value={{
        exam,
        setExam,
        questions,
        setQuestions,
        selectedFolderQuestion,
        setSelectedFolderQuestion,
        selectedItems,
        setSelectedItems,
        showDisplayType,
        setShowDisplayType,
        totalFile,
        setTotalFile,
        settingPropertyOrders,
        setSettingPropertyOrders,
        deleteIds,
        setDeleteIds,
        waitingAPI,
        setWaitingAPI,
        handleSubmit,
      }}
    >
      {children}
    </AuthoringExamContext.Provider>
  );
};

export default AuthoringExamProvider;

export const useAuthoringExamContext = () => useContext(AuthoringExamContext);
