const SufferIcon = ({ color }: { color?: string }) => {
   return (
      <>
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M6 6.5L8 4.5M8 4.5L6 2.5M8 4.5H6C3.79086 4.5 2 6.29086 2 8.5M18 18.5L16 20.5M16 20.5L18 22.5M16 20.5H18C20.2091 20.5 22 18.7091 22 16.5M10.189 7C10.8551 4.41216 13.2042 2.5 16 2.5C19.3137 2.5 22 5.18629 22 8.5C22 11.2957 20.0879 13.6449 17.5001 14.311M14 16.5C14 19.8137 11.3137 22.5 8 22.5C4.68629 22.5 2 19.8137 2 16.5C2 13.1863 4.68629 10.5 8 10.5C11.3137 10.5 14 13.1863 14 16.5Z" stroke={color || '#2E2E2E'} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
         </svg>
      </>
   )
}

export default SufferIcon;