import { getTabObj } from '../../../helpers/parseData';
import { PATH_ADMIN_FOLDER } from '../../route/router';

const FOLDER_TAB_ITEM = {
  FOLDER_LIST: 'カテゴリ一覧',
  FILE_MANAGEMENT: "ファイル管理",
  FILE_LIST: '画像埋め込みタグ一覧',
};

const FOLDER_TAB_DATA = [
  getTabObj(FOLDER_TAB_ITEM.FOLDER_LIST, PATH_ADMIN_FOLDER.FOLDER),
  getTabObj(FOLDER_TAB_ITEM.FILE_LIST, PATH_ADMIN_FOLDER.FILE),
];

export { FOLDER_TAB_ITEM, FOLDER_TAB_DATA };
