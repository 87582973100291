import { useEffect, useState } from "react";
import Logo from "../../components/commons/icons/Logo";
import { HiOutlineUserCircle } from "react-icons/hi";
import { makeRequest, request } from "../../services/axios/axios";
import { API } from "../../services/constants/route/api";
import { useStorage } from "../../hooks/useStorage";
import { useExaminationTestContext } from "../../context/ExaminationTestContext";
import { currentQuestionNumber } from "../../services/utils/candidate/examinationTest";
import { parseExamTimeToMinute } from "../../services/utils/candidate/registerExamination";
import { ExamPartSettingData } from "../../types/admin/exam";
import { isEmptyObj, isUsableArr } from "../../services/helpers/etc";
import { swalError, swalMessage } from "../../services/helpers/swal";
import { useNavigate } from "react-router-dom";
import { PATH_CANDIDATE_HOME } from "../../services/constants/route/router";
import { CANDIDATE_EXAM_MESSAGE, EXAMINATION_STATUS } from "../../services/constants/candidate/examinationTest";
import SpinnerButton from "../../components/admin/commons/SpinnerButton";
import { HTTP_CODE } from "../../services/constants/admin/main";
import City from "../../components/commons/icons/City";

type Organization = {
  name?: string;
  logo_url?: string;
};

const HeaderTest = () => {
  const organizationStorageKey = "organization";
  const subdomain = window.location.hostname.split(".")[0];
  const { getLocalStorage, setLocalStorage } = useStorage();
  const [organization, setOrganization] = useState<Organization>({});
  const [totalTime, setTotalTime] = useState<number>(0);
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [finishTest, setFinishTest] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    registerExamination,
    examPartQuestions,
    filterQuestionKey,
    examPartSelected,
    handleSubmitSaveExamPart,
    setTime,
    examPartProperties,
    candidateExamParts,
    examinationStatus,
    waitingAPI,
    setWaitingAPI,
  } = useExaminationTestContext();
  const currentQuestion = currentQuestionNumber(
    examPartQuestions,
    filterQuestionKey.sectionId,
    filterQuestionKey.itemId
  );

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleSubmitExaminationTest = async () => {
    setWaitingAPI(true);

    const result = await makeRequest({
      method: "post",
      url: API.CANDIDATE_EXAM_HISTORY.SAVE_CANDIDATE_EXAM_HISTORY,
      data: {
        register_examination_id: registerExamination.id,
      },
    });

    setWaitingAPI(false);

    if (result.code === HTTP_CODE.FORBIDDEN) {
      navigate("/404");
    }

    if (!result.status) {
      swalError();
    }

    swalMessage("", CANDIDATE_EXAM_MESSAGE.EXAM_FINISHED, "success", {
      timer: 1500,
      showConfirmButton: false,
    });

    setTimeout(() => {
      navigate(PATH_CANDIDATE_HOME.DEFAULT);
    }, 1500);
  };

  useEffect(() => {
    const organizationStoraged: Organization = getLocalStorage(
      organizationStorageKey
    );
    if (organizationStoraged) {
      setOrganization(organizationStoraged);
      return;
    }

    const fetchOrganizationInfo = async () => {
      await request.get(
        `${API.ORGANIZATION.GET_ORGANIZATION_INFO}?sub_domain=${subdomain}`,
        (data: Organization) => {
          setLocalStorage(organizationStorageKey, data);
          setOrganization(data);
        },
        (errors) => console.log(errors)
      );
    };

    fetchOrganizationInfo();
  }, []);

  useEffect(() => {
    if (timeRemaining) return;
    if (!examPartSelected || !examPartSelected.setting_properties) return;

    const settingProperties: ExamPartSettingData =
      examPartSelected?.setting_properties;
    if (settingProperties) {
      if (isEmptyObj(settingProperties.max_times)) return;

      const convertMinutes = parseExamTimeToMinute(settingProperties.max_times);
      setTotalTime(convertMinutes * 60);
      setTimeRemaining(convertMinutes * 60);
    }
  }, [examPartSelected]);

  useEffect(() => {
    if (!timeRemaining) return;

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        const newTime = prevTime - 1;

        if (
          newTime === 0 &&
          totalTime > 0 &&
          (examPartProperties.is_submission_after_deadline
            ? !examPartProperties.is_submission_after_deadline
            : true)
        ) {
          clearInterval(timer);
          handleSubmitSaveExamPart();
        }

        // Show warning when 20% time remaining
        if (newTime === (totalTime * 20) / 100) {
          swalMessage(
            "",
            CANDIDATE_EXAM_MESSAGE.TIME_REMAINING_20_PERCENT,
            "warning",
            {
              timer: 1500,
              showConfirmButton: false,
            }
          );
        }

        return newTime;
      });
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining]);

  useEffect(() => {
    let finishTestStatus: boolean = true;
    if (isUsableArr(candidateExamParts)) {
      candidateExamParts.forEach((examPart) => {
        if (examPart.status !== 1) {
          finishTestStatus = false;
        }
      });

      setFinishTest(finishTestStatus);
    }
  }, [candidateExamParts]);

  return (
    <>
      <section className="flex items-center justify-between min-h-[100px] pr-[50px]">
        <div className="flex items-center justify-between gap-x-[26px] cursor-pointer ml-[40px]">
          <div className="flex items-center justify-center max-w-[250px] h-[40px] pr-[40px] border-r border-r-secondary-light cursor-pointer">
            <Logo otherStyle="w-[160px] h-[40px]" />
          </div>
          <div className="flex items-center gap-x-[11px]">
            <div className=" text-[12px] w-[40px] h-[40px] flex justify-center items-center">
              {organization && organization.logo_url ? (
                <div className="bg-[#ece8e8]">
                  <img
                    src={organization.logo_url}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              ) : (
                <City color="#BEBEBE" />
              )}
            </div>
          </div>
          {isEmptyObj(registerExamination) ? (
            ""
          ) : (
            <div className="text-[22px] leading-[22px] text-secondary-dark ">
              {registerExamination.exam_name} -{" "}
              {registerExamination.exam_type_name}
            </div>
          )}
        </div>

        {isEmptyObj(examPartSelected) ? (
          <></>
        ) : (
          <div className="flex items-center gap-x-[22px]">
            <div className="p-[10px] border border-secondary-light rounded-[5px]">
              <div>ページ</div>
              <div>
                {currentQuestion}／
                {examPartQuestions.length < 10
                  ? `0${examPartQuestions.length}`
                  : examPartQuestions.length}{" "}
              </div>
            </div>
            <div className="flex flex-col justify-end">
              <div className="flex items-center gap-x-[20px] text-[18px] leading-[24px] mb-[5px]">
                <span>残り時間</span>
                <span>{formatTime(timeRemaining)}</span>
              </div>
              <SpinnerButton
                name="終了する"
                waitingAPI={waitingAPI}
                onSubmit={handleSubmitSaveExamPart}
                className="!bg-danger text-white font-[700] leading-[100%]"
              />
            </div>
          </div>
        )}

        {finishTest && examinationStatus.status === EXAMINATION_STATUS.DOING ? (
          <SpinnerButton
            name="終了する"
            waitingAPI={waitingAPI}
            onSubmit={handleSubmitExaminationTest}
            className="!bg-danger text-white font-[700] leading-[100%]"
          />
        ) : (
          <></>
        )}
      </section>
    </>
  );
};

export default HeaderTest;
