import React, { FC } from 'react';
import logo_login from '../../assests/images/logo_CBT.webp';
import classNames from "classnames";

interface DivProps {
  otherStyle?: string;
}

const HorizontalLogo: FC<DivProps> = (props) => {
  return (
    <div
      className={
        classNames({
          "w-[200px] h-[200px]": true,
          [`${props.otherStyle}`]: props.otherStyle !== null
        })
      }
    >
      <img className="w-full h-full" src={logo_login} alt='None'/>
    </div>
  )
} 

export default HorizontalLogo
