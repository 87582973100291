import { ChangeEvent } from "react";
import InformationDetail from "./InformationDetail";
import { filter } from "jszip";

type radioType = {
   value?: string,
   type?: string,
   label?: string,
}

type CheckedSettingInputProps = {
   label: string;
   name?: string
   messageDetail?: string;
   classNameLabel?: string;
   className?: string;
   arrItems?: radioType[];
   handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
   chosenValue?: string,
} & React.HTMLProps<HTMLInputElement>;

const CheckedSettingInput = ({
   chosenValue,
   label,
   name,
   messageDetail,
   classNameLabel,
   handleChange,
   className,
   arrItems,
}: CheckedSettingInputProps) => {
   return (
      <div className={`pb-[15px] justify-between items-center gap-[15px] flex
         ${className || ''}`}
      >
         <div className="min-[1500px]:flex w-full gap-[15px] items-center">
            <label
               className={`max-[1500px]:my-[10px] text-[14px] cursor-pointer min-w-[100px] ${classNameLabel}`}
            >
               {label}
            </label>
            <div className="max-[1500px]:my-[10px] flex w-full gap-[20px] items-center justify-between">
               {arrItems?.map((item, i) => {
                  let isChecked = false;
                  if (chosenValue && chosenValue == item.value) {
                     isChecked = true;
                  }
                  return (
                     <div key={i} className="flex gap-[5px] items-center justify-start">
                        <input
                           className="w-[24px] h-[24px] text-primary-light bg-gray-100 border-gray-300 focus:ring-primary"
                           type="radio"
                           name={name || ''}
                           value={item?.value}
                           checked={isChecked}
                           onChange={handleChange}
                        />
                        <div className={`text-[14px] min-w-[60px]`}>{item?.label}</div>
                     </div>
                  )
               })}
               <InformationDetail text={messageDetail || ''} />
            </div>
         </div>
      </div>
   )
}

export default CheckedSettingInput;