import React from "react";
import Pagination from "../../commons/Pagination";
import { ExamParticipateType } from "../../../../types/admin/examParticipate";
import { useExamParticipateContext } from "../../../../context/Admin/ExamParticipateContext";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { PATH_ADMIN_EXAM_PARTICIPATE } from "../../../../services/constants/route/router";
import { getCommonDateSlash, getCommonDateTimeSlash } from "../../../../services/helpers/date";

const ExamParticipateTable = ({
  handlePageChange,
  currentPage,
  _examParticipates,
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  _examParticipates: ExamParticipateType[];
}) => {
  const { totalPages } = useExamParticipateContext();
  const navigate = useNavigate();

  const handleStatusExamParticipate = (status: number) => {
    switch (status) {
      case 1:
        return (
          <button className="text-[12px] text-primary-synch font-[500] p-[5px] flex items-center justify-center rounded-[5px] border border-primary-synch bg-white cursor-default pointer-events-none">
            申し込み期間中
          </button>
        );
      case 2:
        return (
          <button className="text-[12px] text-danger font-[500] p-[5px] flex items-center justify-center rounded-[5px] border border-danger bg-white cursor-default pointer-events-none">
            申し込み期限切れ
          </button>
        );
      case 3:
        return (
          <button className="text-[12px] text-white font-[500] p-[5px] flex items-center justify-center rounded-[5px] bg-danger cursor-default pointer-events-none">
            試験中
          </button>
        );
      case 4:
        return (
          <button className="text-[12px] font-[500] p-[5px] flex items-center justify-center rounded-[5px] bg-success-extralight cursor-default pointer-events-none">
            試験終了
          </button>
        );
      case 5:
        return (
          <button className="text-[12px] font-[500] p-[5px] flex items-center justify-center rounded-[5px] bg-success-extralight cursor-default pointer-events-none">
            申し込み期間前
          </button>
        );
    }
  };

  const redirectExamParticipateCandidate = (
    examinationId: number,
    evtId: number | null
  ) => {
    let url = `${PATH_ADMIN_EXAM_PARTICIPATE.CANDIDATE}?examination_id=${examinationId}`;
    if (!!evtId) {
      url += `&evt_id=${evtId}`;
    }

    navigate(url);
  };

  return (
    <>
      <div className="relative overflow-x-auto animate-[show_0.5s_ease-in-out]">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                試験コード
              </th>
              <th
                scope="col"
                className="2xl:px-[110px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                試験
              </th>
              <th
                scope="col"
                className="2xl:px-[100px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                試験（種類）
              </th>
              <th
                scope="col"
                className="2xl:px-[43px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                会場
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                試験日
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                ステータス
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                受験者一覧
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(_examParticipates) &&
              _examParticipates &&
              !!_examParticipates.length &&
              _examParticipates.map((examParticipate, index) => (
                <tr
                  key={index}
                  className="bg-white border-b w-full text-[14px] font-[400] text-[#2E2E2E] leading-[20px]"
                >
                  <th className="px-6 py-4 font-[500] text-primary-synch whitespace-nowrap">
                    {examParticipate.examination_code}
                  </th>
                  <th className="px-6 py-4 font-[500] text-primary-synch whitespace-nowrap">
                    {examParticipate.exam_name}
                  </th>
                  <td className="px-12 py-4 font-[500] whitespace-nowrap text-primary-synch">
                    {examParticipate.examination_type_name}
                  </td>
                  <td className="p-4">
                    {examParticipate.exam_venue_name
                      ? examParticipate.exam_venue_name
                      : "オンライン"}
                  </td>
                  <td className="px-6 py-4 text-center leading-[20px] font-[500] flex flex-col items-start">
                    <span>
                      {examParticipate?.date
                        ? getCommonDateSlash(examParticipate?.date) +
                          " " +
                          dayjs(examParticipate.time).format("HH:mm")
                        : getCommonDateTimeSlash(
                            examParticipate?.start_registration_time
                          ) +
                          " ~ " +
                          getCommonDateTimeSlash(
                            examParticipate?.end_registration_time
                          )
                      }
                    </span>
                  </td>
                  <td className="">
                    <div className="flex items-center justify-center">
                      {handleStatusExamParticipate(examParticipate.status || 0)}
                    </div>
                  </td>
                  <td className="text-center">
                    <button
                      className="min-w-[50px] px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-primary text-white"
                      onClick={() =>
                        redirectExamParticipateCandidate(
                          examParticipate.examination_id || 0,
                          examParticipate.examination_venue_time_id || null
                        )
                      }
                    >
                      編集
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {totalPages && totalPages !== 0 && (
        <Pagination
          totalItems={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default ExamParticipateTable;
