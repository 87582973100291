import { useState } from 'react';
import { FiSquare } from 'react-icons/fi';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { IoEllipse, IoEllipseOutline } from 'react-icons/io5';
import { PiListNumbers } from 'react-icons/pi';
import TextA from '../../../../../../commons/icons/SelectChoiceQuestionType/TextA';
import TextASmall from '../../../../../../commons/icons/SelectChoiceQuestionType/TextASmall';
import { AiOutlineInfo } from 'react-icons/ai';
import TextI from '../../../../../../commons/icons/SelectChoiceQuestionType/TextI';
import { ElementProps } from '../../../../../../../types/globals';
import { NAME_LIST_STYLE_CHOICE_QUESTION } from '../../../../../../../services/constants/admin/pages/question';

type TypeOption = {
  icon: JSX.Element;
  label: string;
  name: string;
};

type RenderChoiceQuestionListDisplayProps = {
  type: TypeOption;
  arrowDisplay?: boolean;
} & ElementProps<HTMLDivElement>;

const RenderChoiceQuestionListDisplay = ({
  type,
  arrowDisplay = false,
  className,
  ...props
}: RenderChoiceQuestionListDisplayProps) => {
  return (
    <div
      className={`h-0 min-h-[32px] flex justify-start gap-[10px] items-center cursor-pointer ${className}`}
      {...props}
    >
      <div className="text-[#c5c5c5] max-w-[20px] text-[20px]">{type.icon}</div>
      <div className="w-full truncate text-[12px] leading-[100%]">{type.label}</div>
      {arrowDisplay ? <MdKeyboardArrowDown /> : <></>}
    </div>
  );
};

type ChoiceQuestionListDisplayProps = {
  onChangeHandle: (selectedType: string) => void;
  selected?: string;
};

const ChoiceQuestionListDisplay = ({
  onChangeHandle,
  selected,
}: ChoiceQuestionListDisplayProps) => {
  const typeData: TypeOption[] = [
    {
      icon: <FiSquare />,
      label: 'チェックボックス',
      name: NAME_LIST_STYLE_CHOICE_QUESTION.CHECKBOX,
    },

    {
      icon: <IoEllipse />,
      label: 'ドット',
      name: NAME_LIST_STYLE_CHOICE_QUESTION.DOT,
    },

    {
      icon: <IoEllipseOutline />,
      label: 'ラジオ',
      name: NAME_LIST_STYLE_CHOICE_QUESTION.RADIO,
    },
    {
      icon: <PiListNumbers />,
      label: '番号',
      name: NAME_LIST_STYLE_CHOICE_QUESTION.LIST.NUMBER,
    },
    {
      icon: <TextA />,
      label: '大文字',
      name: NAME_LIST_STYLE_CHOICE_QUESTION.LIST.ALPHA_UPPERCASE,
    },
    {
      icon: <TextASmall />,
      label: '小文字',
      name: NAME_LIST_STYLE_CHOICE_QUESTION.LIST.ALPHA,
    },
    {
      icon: <AiOutlineInfo />,
      label: '小ローマ番号',
      name: NAME_LIST_STYLE_CHOICE_QUESTION.LIST.ROMAN_NUMBER,
    },
    {
      icon: <TextI />,
      label: '大ローマ番号',
      name: NAME_LIST_STYLE_CHOICE_QUESTION.LIST.ROMAN_NUMBER_UPPERCASE,
    },
  ];
  const getSelected = (selectedName?: string) => {
    if (!selectedName) return false;
    return typeData.find((type) => type.name === selectedName);
  };

  const [selectedType, setSelectedType] = useState<TypeOption>(
    getSelected(selected) || typeData[0],
  );
  const [isClick, setIsClick] = useState(false);

  const triggerSelectHandle = () => {
    setIsClick(!isClick);
  };

  const selectHandle = (type: TypeOption) => {
    setIsClick(false);
    setSelectedType(type);
    onChangeHandle?.(type.name);
  };

  return (
    <div className="">
      <div className="border border-success-lighter rounded-[5px] w-full max-w-[180px] px-[10px] py-[4px]">
        <RenderChoiceQuestionListDisplay
          type={selectedType}
          onClick={triggerSelectHandle}
          arrowDisplay
        />
      </div>
      {isClick ? (
        <div className="mt-[1px] bg-white p-[10px] h-[200px] overflow-y-auto w-full max-w-[180px] shadow-lg border border-secondary-extralight">
          {typeData
            .filter((type) => type.name !== selectedType.name)
            .map((type, index) => (
              <RenderChoiceQuestionListDisplay
                key={index}
                type={type}
                onClick={() => selectHandle(type)}
                className=""
              />
            ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChoiceQuestionListDisplay;
