import * as React from "react";

const HesaWaringOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.524616 13.1749C0.283461 12.7572 0.283462 12.2426 0.524616 11.8249L5.67821 2.89863C5.91936 2.48094 6.36503 2.22363 6.84734 2.22363H17.1545C17.6368 2.22363 18.0825 2.48094 18.3237 2.89863L23.4772 11.8249C23.7184 12.2426 23.7184 12.7572 23.4772 13.1749L18.3237 22.1012C18.0825 22.5189 17.6368 22.7762 17.1545 22.7762H6.84734C6.36503 22.7762 5.91936 22.5189 5.67821 22.1012L0.524616 13.1749ZM6.93394 21.2762H17.0679L22.1349 12.4999L17.0679 3.72363H6.93394L1.86695 12.4999L6.93394 21.2762ZM12.0012 7.74982C12.4154 7.74982 12.7512 8.08561 12.7512 8.49982V12.4998C12.7512 12.914 12.4154 13.2498 12.0012 13.2498C11.587 13.2498 11.2512 12.914 11.2512 12.4998V8.49982C11.2512 8.08561 11.587 7.74982 12.0012 7.74982ZM12.5687 17.0003C12.8458 16.6925 12.8208 16.2182 12.513 15.9411C12.2051 15.664 11.7309 15.689 11.4538 15.9969L11.4438 16.008C11.1667 16.3159 11.1916 16.7901 11.4995 17.0672C11.8074 17.3443 12.2816 17.3193 12.5587 17.0114L12.5687 17.0003Z"
      fill={`${props.color || "#2E2E2E"}`}
    />
  </svg>
);

export default HesaWaringOutline;
