import * as React from 'react';

const HalfPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="8"
    fill="none"
    viewBox="0 0 7 8"
    {...props}
  >
    <path
      stroke={props.color || '#7A7A7A'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.667"
      d="M.5 1v6h6"
    ></path>
  </svg>
);

export default HalfPlus;
