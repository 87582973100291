import React from "react";

const ExaminationImageDefault = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="128"
    height="116"
    viewBox="0 0 128 116"
    fill="none"
    {...props}
  >
    <path
      d="M15.7006 112.549L56.9289 71.3211C59.404 68.846 60.6416 67.6084 62.0686 67.1447C63.3239 66.7369 64.6761 66.7369 65.9314 67.1447C67.3584 67.6084 68.596 68.846 71.0711 71.3211L112.024 112.274M76.5 76.75L94.4289 58.8211C96.904 56.346 98.1416 55.1084 99.5686 54.6447C100.824 54.2369 102.176 54.2369 103.431 54.6447C104.858 55.1084 106.096 56.346 108.571 58.8211L126.5 76.75M51.5 39.25C51.5 46.1536 45.9036 51.75 39 51.75C32.0964 51.75 26.5 46.1536 26.5 39.25C26.5 32.3464 32.0964 26.75 39 26.75C45.9036 26.75 51.5 32.3464 51.5 39.25ZM31.5 114.25H96.5C107.001 114.25 112.251 114.25 116.262 112.206C119.79 110.409 122.659 107.54 124.456 104.012C126.5 100.001 126.5 94.751 126.5 84.25V31.75C126.5 21.249 126.5 15.9985 124.456 11.9877C122.659 8.45964 119.79 5.59125 116.262 3.79363C112.251 1.75 107.001 1.75 96.5 1.75H31.5C20.999 1.75 15.7485 1.75 11.7377 3.79363C8.20964 5.59125 5.34125 8.45964 3.54363 11.9877C1.5 15.9985 1.5 21.249 1.5 31.75V84.25C1.5 94.751 1.5 100.001 3.54363 104.012C5.34125 107.54 8.20964 110.409 11.7377 112.206C15.7485 114.25 20.999 114.25 31.5 114.25Z"
      stroke={`${props.color || '#BEBEBE'}`}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExaminationImageDefault;
