import { PATH_MY_PROFILE, ROUTE } from "../route/router";

const CANDIDATE_MENU = {
  PROFILE: '会員情報',
  MY_PAGE: 'マイページトップ',
  COURSE_LIST: '受講コース一覧',
  LEARNING_HISTORY: '学習履歴',
  LEARNING_REPORT: '学習レポート',
  SCHEDULE: 'スケジュール',
  MEMO: 'メモ',
  REVIEW: '復習',
  NOTICE: 'お知らせ',
  COMMUNITY: 'コミュニティ',
  INBOX: '受信ボックス',
  FUNCTION_LIST: '機能一覧',
  SETTING: '設定',
  LOGOUT: 'ログアウト',
};

const CANDIDATE_MENU_DATA = [
  {
    label: CANDIDATE_MENU.PROFILE,
    href: PATH_MY_PROFILE.DEFAULT
  },
  {
    label: CANDIDATE_MENU.MY_PAGE,
    href: ROUTE.MY_PAGE.DEFAULT
  },
  {
    label: CANDIDATE_MENU.LOGOUT,
    href: '/logout'
  },
];

const CANDIDATE_MENU_MOBILE_DATA = [
  {
    label: CANDIDATE_MENU.PROFILE,
    href: PATH_MY_PROFILE.DEFAULT
  },
  {
    label: CANDIDATE_MENU.MY_PAGE,
    href: ROUTE.MY_PAGE.DEFAULT
  },
  {
    label: CANDIDATE_MENU.LOGOUT,
    href: '/logout'
  },
];

const CANDIDATE_MENU_BORDER_INDEX = [0, 3, 7, 10];

export { CANDIDATE_MENU, CANDIDATE_MENU_BORDER_INDEX, CANDIDATE_MENU_DATA, CANDIDATE_MENU_MOBILE_DATA };
