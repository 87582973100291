import { DISPLAY_TAILWIND } from "../../../../../../services/constants/globals";
import {
  QuestionProblem,
} from "../../../../../../types/admin/question";
import { Dispatch, SetStateAction } from "react";
import { decodeHTMLEntities } from "../../../../../../services/helpers/parseData";
import ModalEditQuestionType from "./ModalEditQuestionType";

type ModalEditScoreType = {
  isShow: boolean;
  questionProblems: QuestionProblem[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setQuestionProblems: Dispatch<SetStateAction<QuestionProblem[]>>;
};

const ModalEditScore = ({
  isShow,
  questionProblems,
  setShowModal,
  setQuestionProblems,
}: ModalEditScoreType) => {
  const handleUpdateProblem = (problem: QuestionProblem) => {
    const newQuestionProblems = questionProblems.map((questionProblem) => {
      if (problem.id === questionProblem.id) {
        return problem;
      }

      return questionProblem;
    });
    setQuestionProblems(newQuestionProblems);
  };
  
  return (
    <div
      className={`${
        isShow == true ? DISPLAY_TAILWIND.SHOW : DISPLAY_TAILWIND.HIDDEN
      } fixed top-0 left-0 w-screen h-screen bg-white z-[100]`}
    >
      <div
        className={`absolute top-0 left-0 w-screen h-screen flex justify-center items-center bg-gray-300 overflow-y-auto`}
      >
        <div className="w-[770px] h-[500px] bg-white !rounded-[5px]">
          <div className="w-full h-[500px] overflow-scroll">
            {questionProblems &&
              questionProblems.length > 0 &&
              questionProblems.map((questionProblem) => (
                <div
                  key={questionProblem.id}
                  className="mb-[20px] last:mb-0 min-h-[500px]"
                >
                  <div className="flex flex-col justify-center">
                    <div className="w-full p-[10px] bg-primary text-white text-[14px] rounded-t-md">
                      設問内容
                    </div>
                    <div
                      className="min-h-[50px] px-[23px] pt-[15px] pb-[28px] text-secondary-dark text-[14px] font-[700]"
                      dangerouslySetInnerHTML={{
                        __html: decodeHTMLEntities(questionProblem.title),
                      }}
                    ></div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <div className="w-full p-[10px] bg-primary text-white text-[14px]">
                      正解・解答・配点
                    </div>
                    <div className="px-[10px] py-[20px] text-[13px]">
                      <ModalEditQuestionType
                        key={questionProblem.id}
                        questionProblem={questionProblem}
                        handleUpdateProblem={handleUpdateProblem}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex items-center justify-center gap-[10px] mt-[20px]">
            <button
              type="button"
              className="px-[24px] py-[12px] bg-secondary-extralight text-secondary text-[14px] rounded-[5px]"
              onClick={() => setShowModal(false)}
            >
              キャンセル
            </button>
            <button
              type="button"
              className="px-[24px] py-[12px] bg-danger text-white text-[14px] rounded-[5px]"
              onClick={() => setShowModal(false)}
            >
              保存
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditScore;
