import React from 'react';
import { WithChildren } from '../../../../types/globals';

const FieldItem = ({children}: WithChildren) => {
    return (
        <div className='border-b border-secondary-lighter mb-[5px]'>
            {children}
        </div>
    );
};

export default FieldItem;