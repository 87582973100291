import {
  PATH_ADMIN_FOLDER,
  PATH_ADMIN_HOME,
  PATH_ADMIN_GROUP,
  ROUTE,
  PATH_ADMIN_QUESTION,
  PATH_ADMIN_EXAM,
  PATH_ADMIN_EXAM_VENUE,
  PATH_ADMIN_EXAMINATION,
  PATH_ADMIN_EXAM_PARTICIPATE,
  PATH_ADMIN_EXAM_RESULT,
} from "../services/constants/route/router";
import { RouteObject } from "react-router";
import CandidateListPage from "../pages/admin/candidate/List";
import CandidateCreatePage from "../pages/admin/candidate/Create";
import CandidateCSVPage from "../pages/admin/candidate/CSV";
import CandidateSettingPage from "../pages/admin/candidate/Setting";
import SettingPage from "../pages/admin/Group/Setting";
import GroupListPage from "../pages/admin/Group/List";
import GroupManagementPage from "../pages/admin/Group/Management";
import CandidateUpdatePage from "../pages/admin/candidate/Details";
import FolderListPage from "../pages/admin/Folder/List";
import FileListPage from "../pages/admin/Folder/Files";
import FoldersFileListPage from "../pages/admin/Folder/FoldersFileList";
import HomePage from "../pages/admin/home/home";
import QuestionListPage from "../pages/admin/question/list";
import QuestionAuthoringPage from "../pages/admin/question/authoring";
import QuestionCreatePage from "../pages/admin/question/create";
import ExamListPage from "../pages/admin/Exam/List";
import CreateExamPage from "../pages/admin/Exam/Create";
import AuthoringExamPage from "../pages/admin/Exam/Authoring";
import CreateExamVenuePage from "../pages/admin/ExamVenue/CreateExamVenue";
import ListExamVenuePage from "../pages/admin/ExamVenue/ListExamVenue";
import UpdateExamVenuePage from "../pages/admin/ExamVenue/UpdateExamVenue";
import CreateExaminationPage from "../pages/admin/Examination/Create";
import DetailExaminationPage from "../pages/admin/Examination/Detail";
import ListExaminationPage from "../pages/admin/Examination/List";
import ExamParticipateListPage from "../pages/admin/ExamParticipate/List";
import ExamParticipateCandidatePage from "../pages/admin/ExamParticipate/Candidate";
import ResultExamListPage from "../pages/admin/ExamResult/ExamList";
import ExamResultListPage from "../pages/admin/ExamResult/ResultList";
import DetailExamResultPage from "../pages/admin/ExamResult/DetailExamResult";

type CustomRouterObject = {};

const adminRouter: Array<RouteObject & CustomRouterObject> = [
  {
    path: ROUTE.ADMIN_PREFIX,
    children: [
      {
        path: PATH_ADMIN_HOME.DEFAULT,
        children: [{ index: true, element: <HomePage /> }],
      },
      {
        path: PATH_ADMIN_GROUP.DEFAULT,
        children: [
          { index: true, element: <GroupListPage /> },
          {
            path: PATH_ADMIN_GROUP.MANAGEMENT,
            element: <GroupManagementPage />,
          },
          {
            path: PATH_ADMIN_GROUP.SETTING,
            element: <SettingPage />,
          },
        ],
      },
      {
        path: ROUTE.CANDIDATE_MANAGEMENT.DEFAULT,
        children: [
          { index: true, element: <CandidateListPage /> },
          {
            path: ROUTE.CANDIDATE_MANAGEMENT.CREATE,
            element: <CandidateCreatePage />,
          },
          {
            path: ROUTE.CANDIDATE_MANAGEMENT.CSV,
            element: <CandidateCSVPage />,
          },
          {
            path: ROUTE.CANDIDATE_MANAGEMENT.SETTING,
            element: <CandidateSettingPage />,
          },
          {
            path: `${ROUTE.CANDIDATE_MANAGEMENT.DETAILS}/:id`,
            element: <CandidateUpdatePage />,
          },
        ],
      },
      {
        path: PATH_ADMIN_QUESTION.DEFAULT,
        children: [
          { index: true, element: <QuestionListPage /> },
          {
            path: PATH_ADMIN_QUESTION.AUTHORING,
            element: <QuestionAuthoringPage />,
          },
          {
            path: PATH_ADMIN_QUESTION.CREATE,
            element: <QuestionCreatePage />,
          },
        ],
      },
      {
        path: PATH_ADMIN_FOLDER.DEFAULT,
        children: [
          { index: true, element: <FolderListPage /> },
          {
            path: PATH_ADMIN_FOLDER.FOLDER,
            children: [
              { index: true, element: <FolderListPage /> },
              {
                path: PATH_ADMIN_FOLDER.FOLDER_FILE,
                element: <FoldersFileListPage />,
              },
            ],
          },
          {
            path: PATH_ADMIN_FOLDER.FILE,
            children: [{ index: true, element: <FileListPage /> }],
          },
        ],
      },
      {
        path: PATH_ADMIN_EXAM.DEFAULT,
        children: [
          { index: true, element: <ExamListPage /> },
          {
            path: PATH_ADMIN_EXAM.AUTHORING,
            element: <AuthoringExamPage />,
          },
          {
            path: PATH_ADMIN_EXAM.CREATE,
            element: <CreateExamPage />,
          },
        ],
      },
      {
        children: [
          {
            path: PATH_ADMIN_EXAMINATION.LIST,
            element: <ListExaminationPage />,
          },
          {
            path: PATH_ADMIN_EXAMINATION.CREATE,
            element: <CreateExaminationPage />,
          },
          {
            path: PATH_ADMIN_EXAMINATION.DETAIL,
            element: <DetailExaminationPage />,
          },
        ],
      },
      {
        path: PATH_ADMIN_EXAM_VENUE.DEFAULT,
        children: [
          {
            path: PATH_ADMIN_EXAM_VENUE.ADD_NEW_VENUE,
            element: <CreateExamVenuePage />,
          },
          {
            path: PATH_ADMIN_EXAM_VENUE.VENUE_LIST,
            element: <ListExamVenuePage />,
          },
          {
            path: `${PATH_ADMIN_EXAM_VENUE.DETAIL_VENUE}`,
            element: <UpdateExamVenuePage />,
          },
        ],
      },
      {
        path: PATH_ADMIN_EXAM_PARTICIPATE.DEFAULT,
        children: [
          {
            path: PATH_ADMIN_EXAM_PARTICIPATE.LIST,
            element: <ExamParticipateListPage />,
          },
          {
            path: PATH_ADMIN_EXAM_PARTICIPATE.CANDIDATE,
            element: <ExamParticipateCandidatePage />,
          },
        ],
      },
      {
        path: PATH_ADMIN_EXAM_RESULT.DEFAULT,
        children: [
          {
            path: PATH_ADMIN_EXAM_RESULT.EXAM_LIST,
            element: <ResultExamListPage />,
          },
          {
            path: `${PATH_ADMIN_EXAM_RESULT.EXAM_RESULT_LIST}/:exam_id/list-candidate`,
            element: <ExamResultListPage />,
          },
          {
            path: `${PATH_ADMIN_EXAM_RESULT.EXAM_RESULT_LIST}/:exam_id/candidate-exam/:exam_result_id`,
            element: <DetailExamResultPage />,
          },
        ],
      },
    ],
  },
];

export default adminRouter;
