import React from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineUser } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Group } from '../../../../types/admin/group';
import '../../../../assests/styles/group.css';
import { DIRECTION } from '../../../../services/constants/globals';
import { Link } from 'react-router-dom';
import { PATH_ADMIN_GROUP } from '../../../../services/constants/route/router';
import { useGroupListContext } from '../../../../context/Group/List';
import StatusButtonDisplay from '../../commons/StatusButtonDisplay';

type GroupItemProps = {
  isUpDisabled?: boolean;
  isDownDisabled?: boolean;
  className?: string;
  data: Group;
  onOrder?: (groupId: Group['id'], direction: 'up' | 'down', isParent: boolean) => void;
  isDropDown?: boolean;
  setIsDropDown?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TableItem = ({
  isUpDisabled = false,
  isDownDisabled = false,
  className = '',
  data,
  isDropDown,
  setIsDropDown,
  onOrder,
  setIsOpenModal,
}: GroupItemProps) => {
  const isParent = !data.parent_group_id;
  const { setSelectedGroup } = useGroupListContext();

  return (
    <div
      className={`min-h-[50px] border border-secondary-light rounded-[5px] flex items-center w-full text-[13px] font-[500] leading-[100%] mb-[4px] ${
        isParent ? 'bg-secondary-morelighter' : 'bg-white justify-end'
      } ${className}`}
    >
      <div className="flex items-center text-secondary-dark w-[40%]">
        <div className={`${isParent ? 'ml-[16px]' : 'w-[50px]'} mr-[16px] cursor-pointer`}>
          {isParent && (
            <>
              {isDropDown ? (
                <IoIosArrowUp size={18} onClick={() => setIsDropDown?.(false)} />
              ) : (
                <IoIosArrowDown size={18} onClick={() => setIsDropDown?.(true)} />
              )}
            </>
          )}
        </div>
        {data.name}
      </div>

      <div className="w-[20%] flex justify-center items-center">
        <Link to={`${PATH_ADMIN_GROUP.MANAGEMENT}?group_id=${data.id}`}>
          <div className="flex items-center justify-center gap-[1.54px] text-[12px] font-[500] leading-[100%] text-primary border border-primary rounded-[5px] py-[4px] pl-[9.5px] pr-[8px] cursor-pointer hover:bg-primary-morelighter">
            <AiOutlineUser />
            管理
          </div>
        </Link>
      </div>

      <div className="flex w-[10%] justify-between items-center pl-[2px] pr-[10px]">
        <div className="w-[30px] h-[24px] appearance-none justify-center text-right p-[5px] border border-secondary-light rounded-[2px]">
          {data.order}
        </div>
        <AiOutlineArrowUp
          className={`items-center ${
            isUpDisabled
              ? 'text-secondary-extralight cursor-not-allowed'
              : 'text-secondary cursor-pointer'
          }`}
          size={22}
          onClick={() => !isUpDisabled && onOrder?.(data.id, DIRECTION.UP, isParent)}
        />
        <AiOutlineArrowDown
          className={` items-center ${
            isDownDisabled
              ? 'text-secondary-extralight cursor-not-allowed'
              : 'text-secondary cursor-pointer'
          }`}
          size={22}
          onClick={() => !isDownDisabled && onOrder?.(data.id, DIRECTION.DOWN, isParent)}
        />
      </div>
      <div className="w-[20%] flex justify-center items-center">
        <StatusButtonDisplay status={data.status} />
      </div>
      <div className="w-[10%] flex justify-center items-center">
        <Link to={`${PATH_ADMIN_GROUP.SETTING}?group_id=${data.id}`}>
          <button className="px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-primary text-white">
            設定
          </button>
        </Link>
      </div>
      <div className=" w-[20%] flex justify-center items-center">
        {isParent && (
          <button
            className="bg-danger rounded-[5px] text-white px-[15px] pt-[3px] pb-[5px]"
            onClick={() => {
              setIsOpenModal?.(true);
              setSelectedGroup(data.id);
            }}
          >
            下層グループを追加
          </button>
        )}
      </div>
    </div>
  );
};

export default TableItem;
