import { NAME_TYPE_QUESTION } from "../../../../../services/constants/admin/pages/question";
import { useAuthoringQuestionContext } from "../../../../../context/Question/AuthoringQuestionContext";
import { BiEditAlt } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { swalConfirm } from "../../../../../services/helpers/swal";
import ChoiceQuestion from "./QuestionTypes/ChoiceQuestion/ChoiceQuestion";
import ChoiceQuestionEdit from "./QuestionTypes/ChoiceQuestion/ChoiceQuestionEdit";
import { ElementProps, SetStateAction } from "../../../../../types/globals";
import {
  EditingProblemStatus,
  QuestionProblem,
} from "../../../../../types/admin/question";
import { filterUniqueArrayByKey } from "../../../../../services/helpers/parseData";
import OrderQuestion from "./QuestionTypes/OrderQuestion/OrderQuestion";
import OrderQuestionEdit from "./QuestionTypes/OrderQuestion/OrderQuestionEdit";
import CombinationQuestion from "./QuestionTypes/MappingQuestion/MappingQuestion";
import SliderQuestion from "./QuestionTypes/SliderQuestion/SliderQuestion";
import SliderQuestionEdit from "./QuestionTypes/SliderQuestion/SliderQuestionEdit";
import MappingQuestionEdit from "./QuestionTypes/MappingQuestion/MappingQuestionEdit";
import MatrixQuestion from "./QuestionTypes/MatrixQuestion/MatrixQuestion";
import MatrixQuestionEdit from "./QuestionTypes/MatrixQuestion/MatrixQuestionEdit";
import FillInBlankQuestion from "./QuestionTypes/FillInBlankQuestion/FillInBlankQuestion";
import FillInBlankQuestionEdit from "./QuestionTypes/FillInBlankQuestion/FillInBlankQuestionEdit";
import WritingQuestion from "./QuestionTypes/WritingQuestion/WritingQuestion";
import WritingQuestionEdit from "./QuestionTypes/WritingQuestion/WritingQuestionEdit";
import TextBlockQuestion from "./QuestionTypes/TextBlockQuestion/TextBlockQuestion";
import TextBlockQuestionEdit from "./QuestionTypes/TextBlockQuestion/TextBlockQuestionEdit";
import MediaToolQuestion from "./QuestionTypes/MediaToolQuestion/MediaToolQuestion";
import MediaToolQuestionEdit from "./QuestionTypes/MediaToolQuestion/MediaToolQuestionEdit";
import { useEffect, useState } from "react";
import TestTakerUploadQuestionEdit from "./QuestionTypes/TestTakerUploadQuestion/TestTakerUploadQuestionEdit";
import TestTakerUploadQuestion from "./QuestionTypes/TestTakerUploadQuestion/TestTakerUploadQuestion";
import TestTalkerRecordVoiceQuestion from "./QuestionTypes/TestTakerRecordVoiceQuestion/TestTakerRecordVoiceQuestion";
import TestTalkerRecordVoiceQuestionEdit from "./QuestionTypes/TestTakerRecordVoiceQuestion/TestTakerRecordVoiceQuestionEdit";

type DroppedQuestionTypeProps = {
  draggedName: string;
  problemId: number;
  currentProblem: QuestionProblem;
} & ElementProps<HTMLDivElement>;

export type RenderDroppedQuestionTypeProps = {
  problemPreview?: DroppedQuestionTypeProps["currentProblem"];
  setOpenModal?: SetStateAction<boolean>;
  editTriggerHandle?: (problemId: number) => void;
} & Omit<DroppedQuestionTypeProps, "problemId" | "currentProblem">;

export type RenderDroppedQuestionTypeEditProps = {
  problemEdit?: DroppedQuestionTypeProps["currentProblem"];
  mediaOpenModal?: boolean;
  setMediaOpenModal?: SetStateAction<boolean>;
} & Omit<DroppedQuestionTypeProps, "problemId" | "currentProblem">;

const RenderDroppedQuestionType = ({
  draggedName,
  editTriggerHandle,
  ...props
}: RenderDroppedQuestionTypeProps) => {
  switch (draggedName) {
    case NAME_TYPE_QUESTION.CHOICE:
      return <ChoiceQuestion {...props} />;

    case NAME_TYPE_QUESTION.ORDER:
      return <OrderQuestion {...props} />;

    case NAME_TYPE_QUESTION.MAPPING:
      return <CombinationQuestion {...props} />;

    case NAME_TYPE_QUESTION.SLIDER:
      return <SliderQuestion {...props} />;

    case NAME_TYPE_QUESTION.WRITTEN_QUESTION:
      return <WritingQuestion {...props} />;

    case NAME_TYPE_QUESTION.MATRIX:
      return <MatrixQuestion {...props} />;

    case NAME_TYPE_QUESTION.FILL_IN_BLANK:
      return <FillInBlankQuestion {...props} />;

    case NAME_TYPE_QUESTION.TEXT_BLOCK:
      return <TextBlockQuestion {...props} />;

    case NAME_TYPE_QUESTION.IMAGE:
    case NAME_TYPE_QUESTION.AUDIO:
    case NAME_TYPE_QUESTION.VIDEO:
      return (
        <MediaToolQuestion
          draggedName={draggedName}
          handleClick={editTriggerHandle}
          {...props}
        />
      );

    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD:
      return <TestTalkerRecordVoiceQuestion {...props} />;

    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD:
      return <TestTakerUploadQuestion {...props} />;
    default:
      return <></>;
  }
};

const RenderDroppedQuestionTypeEdit = ({
  draggedName,
  mediaOpenModal,
  setMediaOpenModal,
  ...props
}: RenderDroppedQuestionTypeEditProps) => {
  switch (draggedName) {
    case NAME_TYPE_QUESTION.CHOICE:
      return <ChoiceQuestionEdit {...props} />;

    case NAME_TYPE_QUESTION.ORDER:
      return <OrderQuestionEdit {...props} />;

    case NAME_TYPE_QUESTION.MAPPING:
      return <MappingQuestionEdit {...props} />;

    case NAME_TYPE_QUESTION.SLIDER:
      return <SliderQuestionEdit {...props} />;

    case NAME_TYPE_QUESTION.WRITTEN_QUESTION:
      return <WritingQuestionEdit {...props} />;

    case NAME_TYPE_QUESTION.MATRIX:
      return <MatrixQuestionEdit {...props} />;

    case NAME_TYPE_QUESTION.FILL_IN_BLANK:
      return <FillInBlankQuestionEdit {...props} />;

    case NAME_TYPE_QUESTION.TEXT_BLOCK:
      return <TextBlockQuestionEdit {...props} />;

    case NAME_TYPE_QUESTION.IMAGE:
    case NAME_TYPE_QUESTION.AUDIO:
    case NAME_TYPE_QUESTION.VIDEO:
      return (
        <MediaToolQuestionEdit
          draggedName={draggedName}
          isOpenModalUploadFile={mediaOpenModal}
          setIsOpenModalUploadFile={setMediaOpenModal}
          {...props}
        />
      );

    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD:
      return <TestTalkerRecordVoiceQuestionEdit {...props} />;

    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD:
      return <TestTakerUploadQuestionEdit {...props} />;

    default:
      return <></>;
  }
};

const DroppedQuestionType = ({
  draggedName,
  problemId,
  currentProblem,
  ...props
}: DroppedQuestionTypeProps) => {
  const {
    authoringData,
    setAuthoringData,
    selectedProblem,
    setSelectedProblem,
    editingProblemStatuses,
    setEditingProblemStatuses,
    setIsEditingProblem
  } = useAuthoringQuestionContext();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const selectedProblemId = selectedProblem.id;
  const currentProblemStatus = editingProblemStatuses.find(
    (problemStatus) => problemStatus.id === selectedProblemId
  );

  const removeHandle = () => {
    swalConfirm(() => {
      setAuthoringData({
        ...authoringData,
        problems: authoringData.problems?.filter(
          (problem) => Number(problem.id) !== Number(selectedProblemId)
        ),
      });
    });
  };

  const editTriggerHandle = (problemId: number) => {
    setIsEditingProblem(true);
    const newEditingProblemStatus: EditingProblemStatus = {
      id: problemId,
      status: true,
    };
    const newEditingProblemStatuses = [
      ...editingProblemStatuses,
      newEditingProblemStatus,
    ];
    setEditingProblemStatuses(
      filterUniqueArrayByKey(newEditingProblemStatuses.reverse())
    );
  };

  useEffect(() => {
    if(currentProblemStatus && currentProblemStatus?.status) {
      setIsEditingProblem(true);
      return;
    }

    setIsEditingProblem(false);
  }, [selectedProblem])
  
  return (
    <div {...props}>
      <div className="flex">
        <div
          className={`${
            selectedProblemId === problemId
              ? "border-primary"
              : "border-[#E5E5E5] cursor-pointer"
          } min-h-[50px] w-full border-[3px] my-[7px] p-[10px] hover:ring-1 hover:ring-primary-light`}
          onClick={() => setSelectedProblem(currentProblem.id)}
        >
          <RenderDroppedQuestionType
            draggedName={draggedName}
            problemPreview={currentProblem}
            editTriggerHandle={editTriggerHandle}
            setOpenModal={setIsOpenModal}
          />
        </div>
        <div className="">
          {selectedProblemId === problemId ? (
            <div className="w-[20px] ml-[10px] text-[20px] flex flex-col gap-[20px]">
              <BiEditAlt
                className="cursor-pointer"
                onClick={() => editTriggerHandle(selectedProblemId)}
              />
              <FiTrash2 className="cursor-pointer" onClick={removeHandle} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {currentProblemStatus?.id === problemId &&
      !!currentProblemStatus?.status &&
      currentProblemStatus ? (
        <RenderDroppedQuestionTypeEdit
          draggedName={draggedName}
          problemEdit={selectedProblem}
          mediaOpenModal={isOpenModal}
          setMediaOpenModal={setIsOpenModal}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DroppedQuestionType;
