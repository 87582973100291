const htmlToString = (value: string) => {
   var span = document?.createElement('span');
   span.innerHTML = value;
   let textRaw = span.textContent || span.innerText;
   return textRaw;
};

const countRows = (value: string) => {
   const regex = new RegExp("<p>|<h1>|<h2>|<h3>|<h4>|\n|<li>", 'g');
   const matches = value.match(regex);;
   const count = matches ? matches.length : 0;
   return count;
}

export {
   htmlToString,
   countRows,
};