import React from 'react';

const TextASmall = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 11.5C10.4102 11.5 9.5 12.4304 9.5 13.375C9.5 14.3196 10.4102 15.25 11.75 15.25C13.0898 15.25 14 14.3196 14 13.375C14 12.4304 13.0898 11.5 11.75 11.5ZM8 13.375C8 11.4201 9.77614 10 11.75 10C13.7239 10 15.5 11.4201 15.5 13.375C15.5 15.3299 13.7239 16.75 11.75 16.75C9.77614 16.75 8 15.3299 8 13.375Z"
        style={{
          fill: 'currentcolor',
        }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9916 8.50015C11.3504 8.49295 10.732 8.73773 10.2694 9.18184C9.97064 9.46871 9.49587 9.45905 9.20899 9.16026C8.92212 8.86147 8.93178 8.38669 9.23057 8.09982C9.97566 7.38444 10.9715 6.9897 12.0042 7.0002C13.9787 7.00221 15.75 8.41811 15.75 10.3752V16.1033C15.75 16.5175 15.4142 16.8533 15 16.8533C14.5858 16.8533 14.25 16.5175 14.25 16.1033V10.3752C14.25 9.42743 13.3426 8.5002 12 8.5002H11.9916L11.9916 8.50015Z"
        style={{
          fill: 'currentcolor',
        }}
      />
    </svg>
  );
};

export default TextASmall;
