const MOVE_QUESTION = {
    NEXT: 1,
    BACK: -1
};

const STATUS_EXAM_TEST_ITEM = {
    UNDONE: 0,
    DONE: 1
}

const CANDIDATE_EXAM_MESSAGE = {
    UNANSWER_IN_EXAM_SECTION: '未回答の質問があります。',
    EXAM_FINISHED: 'おめでとうございます。テストが完了しました！',
    LAST_QUESTION_IN_EXAM_SECTION: 'セクション 1が完了しました。セクション2に進みます。',
    TURN_ANSWER_QUESTION_IS_OVER: '試行回数の上限に達しました',
    CHOOOSE_ANSWER: '必須質問です',
    ANSWER_QUESTION_IN_ORDER: '提示された順番でしか回答できません。' ,
    TIME_REMAINING_20_PERCENT: '時間がもうすぐ終わります。'
}

const EXAMINATION_STATUS = {
    UNDONE: 0,
    DONE: 1,
    DOING: 2
}

export { MOVE_QUESTION, STATUS_EXAM_TEST_ITEM, CANDIDATE_EXAM_MESSAGE, EXAMINATION_STATUS }