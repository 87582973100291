import React, { ChangeEvent } from "react";
import DetailExamGroup from "./DetailExamGroup";
import { WithChildren } from "../../../../types/globals";
import { CandidateExamPartHistory } from "../../../../types/admin/candidateExamHistory";
import { QuestionProblemTest } from "../../../../types/admin/question";
import { isEmptyObj } from "../../../../services/helpers/etc";
import RenderQuestionType from "./RenderQuestionType";
import { NAME_TYPE_QUESTION } from "../../../../services/constants/admin/pages/question";

type DetailExamPartProps = {
  candidateExamPart: CandidateExamPartHistory;
  handleFillScoreAnswer: (
    examPartId: number,
    examSectionId: number,
    examItemId: number,
    questionId: number,
    e: ChangeEvent<HTMLInputElement>
  ) => void;
};

const DetailExamPart = ({
  candidateExamPart,
  handleFillScoreAnswer,
}: DetailExamPartProps) => {
  const candidateExamGroups = candidateExamPart.candidate_exam_sections || [];
  const [problems, setProblems] = React.useState<QuestionProblemTest[]>([]);
  const [problemIds, setProblemIds] = React.useState<number[]>([]);

  const handleNumberQuestion = (questionId: number) => {
    const numberQuestion = problemIds.findIndex(
      (problemId) => questionId === problemId
    );
    if (numberQuestion === -1) return 0;

    return numberQuestion + 1;
  };

  React.useEffect(() => {
    let problemIds: number[] = [];
    candidateExamGroups?.forEach((candidateExamGroup) => {
      const candidateExamItem = candidateExamGroup.candidate_exam_items || [];

      candidateExamItem?.forEach((candidateExamItem) => {
        const problems = candidateExamItem.problems;

        problems?.forEach((problem) => {
          const questionTypesNotShow = [
            NAME_TYPE_QUESTION.VIDEO,
            NAME_TYPE_QUESTION.TEXT_BLOCK,
            NAME_TYPE_QUESTION.IMAGE,
            NAME_TYPE_QUESTION.AUDIO,
          ];
          if (
            isEmptyObj(problem) ||
            questionTypesNotShow.includes(problem.name || "")
          ) {
            return;
          }

          problemIds = [...problemIds, problem.id || 0];
        });
      });
    });

    setProblemIds(problemIds);
  }, [candidateExamGroups]);

  return (
    <>
      <div>
        <div className="text-[18px] font-[600] leading-[24px]">
          {candidateExamPart.name}
        </div>
        <div className="w-full h-[50px] px-[30px] bg-warning-lighter border-b border-b-success-extralight my-[18px] flex items-center justify-end gap-x-[30px]">
          <div className="flex items-start">
            <span className="text-[12px] font-[400] leading-[100%]">計</span>
          </div>
          <div className="flex items-end gap-x-[5px]">
            <span className="text-[20px] text-right font-[500] leading-[100%]">
              {candidateExamPart.score}
            </span>
            <span className="text-[15px] font-[700] leading-[100%] text-secondary-light">
              点/{candidateExamPart.total_score}
            </span>
          </div>
        </div>
      </div>
      <div className="!text-[12px]">
        {candidateExamGroups.map((candidateExamGroup) => (
          <DetailExamGroup
            key={candidateExamGroup.id}
            candidateExamGroup={candidateExamGroup}
          >
            {candidateExamGroup.candidate_exam_items?.map(
              (candidateExamItem) => {
                const problems = candidateExamItem.problems;

                return problems?.map((problem) => (
                  <RenderQuestionType
                    key={problem.id}
                    question={problem}
                    numberQuestion={handleNumberQuestion(problem.id || 0)}
                    handleFillScoreForAnswer={(
                      e: ChangeEvent<HTMLInputElement>
                    ) =>
                      handleFillScoreAnswer(
                        candidateExamPart.id || 0,
                        candidateExamGroup.id || 0,
                        candidateExamItem.id || 0,
                        problem.id || 0,
                        e
                      )
                    }
                  />
                ));
              }
            )}
          </DetailExamGroup>
        ))}
      </div>
    </>
  );
};

export default DetailExamPart;
