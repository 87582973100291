import React from "react";

const Incorrect = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="13.4706"
        y1="13"
        x2="2.00006"
        y2="1.52947"
        stroke="#227C9D"
        strokeWidth="2.1629"
        strokeLinecap="round"
      />
      <line
        x1="1.08145"
        y1="-1.08145"
        x2="17.3032"
        y2="-1.08145"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0.46875 13)"
        stroke="#227C9D"
        strokeWidth="2.1629"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Incorrect;
