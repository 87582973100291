import React from 'react';
import CandidateBase from '.';
import CandidateCreate from '../../../components/admin/Candidate/CandidateCreate';

const CandidateCreatePage = () => {
  return (
    <CandidateBase title="受験者管理 ｜ 受験者新規追加">
      <CandidateCreate/>
    </CandidateBase>
  );
};

export default CandidateCreatePage;
