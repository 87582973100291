import React from 'react';

const RoundedButton = ({
  children,
  isDisabled = false,
  className,
  ...props
}: {
  children: React.ReactNode;
  isDisabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      disabled={isDisabled}
      className={`bg-primary text-white text-[12px] font-[700] leading-[17px] flex items-end pb-[5px] px-[20px] rounded-[20px] min-h-[28px] ${
        isDisabled ? 'bg-secondary-disabled cursor-not-allowed' : 'bg-primary cursor-pointer '
      } ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default RoundedButton;
