import React from "react";

const Correct = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.5" cy="8.5" r="7" stroke="#FA646A" strokeWidth="2" />
    </svg>
  );
};

export default Correct;
