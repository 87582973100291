import React from "react";
import ExamManagementBase from ".";
import ListExamVenue from "../../../components/admin/ExamVenue/ListExamVenue";
import ExamManagementProvider from "../../../context/ExamManagementContext";

const ListExamVenuePage = () => {
   return (
      <ExamManagementBase title="試験管理 ｜ 会場一覧">
         <ExamManagementProvider>
            <ListExamVenue />
         </ExamManagementProvider>
      </ExamManagementBase>
   );
};

export default ListExamVenuePage;
