import katex from "katex";
import { useState } from "react";
import { InlineMath } from "react-katex";
import { SYMBOLS } from "../../../services/constants/admin/formulas";
import SymbolGroupItem from "./SymbolGroupItem";

type LatexSymbolModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (equation: string) => void;
};

const LatexSymbolModal: React.FC<LatexSymbolModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [equation, setEquation] = useState("");

  const symbols = [
    "\\alpha",
    "\\beta",
    "\\gamma",
    "\\Delta",
    "\\Sigma",
    "\\pi",
    "\\sum_{}^{}",
  ];

  const filteredSymbols = symbols.filter((symbol) =>
    symbol.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSymbolSelect = (symbol: string) => {
    setEquation((prevEquation) => prevEquation + symbol);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEquation(e.target.value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit(equation);
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="bg-white p-6 shadow-lg rounded-lg w-[1000px]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold"> 計算式入力</h2>
          <button
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={onClose}
          >
             閉じる
          </button>
        </div>
        {SYMBOLS.map((symbolGroup, index) => (
          <SymbolGroupItem
            key={index}
            symbolGroup={symbolGroup}
            handleSymbolSelect={handleSymbolSelect}
          />
        ))}
        <textarea
          placeholder="計算式入力"
          className="border border-gray-300 rounded-md mb-4 mt-4 h-[70px] text-[15px] with_roboto tracking-wide"
          value={equation}
          onChange={handleInputChange}
        >
          {" "}
        </textarea>
        <div className="mb-4 border px-2 py-4">
          <p className="text-gray-500 mb-[20px]">方程式の表示:</p>
          <p className="font-semibold ">
            <InlineMath math={equation} />
          </p>
        </div>
        <input
          type="button"
          value="保存"
          className="bg-primary text-white px-4 py-2 rounded"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default LatexSymbolModal;
