/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import { LiaSave } from 'react-icons/lia';
import { PiTrash, PiTrashFill } from 'react-icons/pi';
import AuthoringTextEditor from '../../AuthoringTextEditor';
import { isEmptyObj, isUsableArr } from '../../../../../../../services/helpers/etc';
import { MatrixProblem, MatrixCoordinate } from '../../../../../../../types/admin/question';
import { HiPlus } from 'react-icons/hi';
import { swalConfirm, swalSuccess } from '../../../../../../../services/helpers/swal';
import { RenderDroppedQuestionTypeEditProps } from '../../DroppedQuestionType';
import {
  checkLimitChoice,
  getIssueById,
  getIssueIndexById,
  initIssueByType,
  initMatrixCoordinate,
  displayStatusEditorHandle,
} from '../../../../../../../services/utils/admin/question';
import { NAME_TYPE_QUESTION } from '../../../../../../../services/constants/admin/pages/question';
import { useAuthoringQuestionContext } from '../../../../../../../context/Question/AuthoringQuestionContext';
import { groupArrayByProperty } from '../../../../../../../services/helpers/parseData';
import AuthoringMessageBox from '../AuthoringMessageBox';
import classNames from 'classnames';

type MatrixQuestionEditProps = {
  problemEdit?: MatrixProblem;
} & Omit<RenderDroppedQuestionTypeEditProps, 'draggedName' | 'problemEdit'>;

const MatrixQuestionEdit = ({ problemEdit }: MatrixQuestionEditProps) => {
  const {
    authoringData,
    setAuthoringData,
    editingProblemStatuses,
    setEditingProblemStatuses,
    setSelectedProblem,
    setIsEditingProblem
  } = useAuthoringQuestionContext();

  const [editingNode, setEditingNode] = useState('');
  const [editingData, setEditingData] = useState<MatrixProblem>(problemEdit || {});
  const [errors, setErrors] = useState<string[]>([]);

  const currentIssues = editingData.issues || [];
  const currentCoordinates = editingData?.correct_coordinates || [];

  //On change handle for text editor only
  const changeEditorHandle = useCallback(
    (identify: string, content: string) => {
      if (
        content === '<p><br></p>' ||
        !editingData ||
        editingData[identify as keyof MatrixProblem] === content ||
        identify !== editingNode
      ) {
        return;
      }

      if (identify === 'title' || editingNode === 'title') {
        setEditingData((editingData) => ({ ...editingData, title: content }));;
        return;
      }

      const currentIssueIndex = getIssueIndexById(currentIssues, Number(identify));
      let currentIssue = getIssueById(currentIssues, Number(identify));
      currentIssue = {
        ...currentIssue,
        label: content,
      };
      const newIssues = JSON.parse(JSON.stringify(currentIssues)) as typeof currentIssues;
      newIssues.splice(currentIssueIndex, 1, currentIssue || {});

      setEditingData({ ...editingData, issues: newIssues });
    },
    [editingNode],
  );

  const tabOptionChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget || !editingData) return;
    const { name, value } = e.currentTarget;

    let parsedValue: number = Number(value);

    if (Number(value) > 100) {
      parsedValue = 100;
    }

    if (Number(value) < 0) {
      parsedValue = 0;
    }

    if (
      !editingData.hasOwnProperty(name) ||
      editingData[name as keyof MatrixProblem] === parsedValue
    ) {
      return;
    }

    // Addition data for specific cases
    let additionData = {} as typeof editingData;
    if (name === 'default_score') {
      // update default score for all issues
      const newCoordinates: MatrixProblem['correct_coordinates'] = currentCoordinates.map(
        (coordinate) => ({
          ...coordinate,
          point: parsedValue,
        }),
      );

      additionData = {
        ...additionData,
        correct_coordinates: newCoordinates,
      };
    }

    if (name === 'min_choice') {
      // Make min choice must <= max choice
      if (Number(parsedValue) > Number(editingData.max_choice)) {
        additionData = {
          ...additionData,
          max_choice: Number(parsedValue),
        };
      }
    }

    if (name === 'max_choice') {
      // Make max choice must >= min choice
      if (Number(parsedValue) < Number(editingData.min_choice) && !!Number(parsedValue)) {
        additionData = {
          ...additionData,
          min_choice: Number(parsedValue),
        };
      }
    }

    setEditingData({ ...editingData, [name]: parsedValue, ...additionData });
  };

  const submitEditingDataHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);

    const finalEditingData = {
      ...editingData,
      updated_at: new Date().getTime(),
    };

    const problemIndexed =
      authoringData.problems?.findIndex((problem) => problem.id === finalEditingData.id) || 0;

    let newProblems: MatrixProblem[] = authoringData.problems || [];
    newProblems.splice(problemIndexed, 1, finalEditingData);

    // Validating
    const checkedCount = currentCoordinates.filter((coordinate) => !!coordinate.checked).length;
    const { passed } = checkLimitChoice(
      checkedCount,
      editingData.min_choice,
      editingData.max_choice,
    );

    if (!passed) {
      return;
    }

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      'hide',
      Number(editingData.id),
      editingProblemStatuses,
    );
    setEditingProblemStatuses(newEditingProblemStatuses);

    setAuthoringData({ problems: newProblems });
    setErrors([]);
    swalSuccess();
    setSelectedProblem();
  };

  const removeHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    swalConfirm(() => {
      setIsEditingProblem(false);
      setAuthoringData({
        ...authoringData,
        problems: authoringData.problems?.filter(
          (problem) => Number(problem.id) !== Number(editingData.id),
        ),
      });
    });
  };

  const hideEditorHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      'hide',
      Number(editingData.id),
      editingProblemStatuses,
    );
    setEditingProblemStatuses(newEditingProblemStatuses);
    setSelectedProblem();
  };

  const getCoordinateByAxis = (x: number, y: number) => {
    return currentCoordinates.find((coordinate) => coordinate.x === x && coordinate.y === y);
  }
    
  const currentCoordinatesByX = groupArrayByProperty(editingData.correct_coordinates || [], 'x');
  const coordinateKeyXs = Object.keys(currentCoordinatesByX);
  const currentCoordinatesByY = groupArrayByProperty(editingData.correct_coordinates || [], 'y');
  const coordinateKeyYs = Object.keys(currentCoordinatesByY);

  const getLengthCorrectCoordinate = (direction: string): number => {
    const arrDirection: number[] = [];

    currentCoordinates.forEach((currentCoordinate) => {
      if (direction === 'column' && !arrDirection.includes(currentCoordinate.y)) {
        arrDirection.push(currentCoordinate.y);
      }

      if (direction === 'row' && !arrDirection.includes(currentCoordinate.x)) {
        arrDirection.push(currentCoordinate.x);
      }
    })

    return arrDirection.length;
  }
  
  const checkIsLastCoordinate = (direction: 'column' | 'row') => {
    if(direction === 'column') {
      return !(coordinateKeyYs.length > 1);
    }

    if(direction === 'row') {
      return !(coordinateKeyXs.length > 1);
    }
  }

  const addMatrixCoordinate = (direction: 'column' | 'row') => {
    if(getLengthCorrectCoordinate(direction) >= 10) return;
    
    const newIssue = initIssueByType(
      NAME_TYPE_QUESTION.MATRIX,
      currentIssues.length,
      editingData.default_score,
    );
    currentIssues.push(newIssue);

    let newCoordinates = currentCoordinates;

    if (direction === 'column') {
      let index = 0;
      for (const coordinateXId of coordinateKeyXs) {
        index += 1;
        const newCoordinate = initMatrixCoordinate(
          index,
          Number(coordinateXId),
          Number(newIssue.id),
          editingData.default_score,
        );
        newCoordinates.push(newCoordinate);
      }
    } else {
      let index = 0;
      for (const coordinateYId of coordinateKeyYs) {
        index += 1;
        const newCoordinate = initMatrixCoordinate(
          index,
          Number(newIssue.id),
          Number(coordinateYId),
          editingData.default_score,
        );
        newCoordinates.push(newCoordinate);
      }
    }

    setEditingData({
      ...editingData,
      correct_coordinates: newCoordinates,
      issues: currentIssues,
    });
  };

  const removeMatrixCoordinate = (direction: 'column' | 'row', coordinateId: number) => {
    if(checkIsLastCoordinate(direction)) return;

    const newIssues = currentIssues.filter((issue) => issue.id !== coordinateId);
    let newCoordinates = currentCoordinates;

    if (direction === 'column') {
      newCoordinates = newCoordinates.filter((coordinate) => coordinate.y !== coordinateId);
    } else {
      newCoordinates = newCoordinates.filter((coordinate) => coordinate.x !== coordinateId);
    }

    setEditingData({
      ...editingData,
      correct_coordinates: newCoordinates,
      issues: newIssues,
    });
  };

  const matrixChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    coordinate: MatrixCoordinate,
  ) => {
    if (!e || !e.currentTarget) return;
    const { name, value, checked, type } = e.currentTarget;
    let parsedValue: boolean | string | number = Number(value);

    if (type === 'checkbox') {
      parsedValue = checked;
    }

    const newCurrentCoordinates = JSON.parse(
      JSON.stringify(currentCoordinates),
    ) as typeof currentCoordinates;

    const coordinateIndex = newCurrentCoordinates.findIndex(
      (crCoordinate) => crCoordinate.x === coordinate.x && crCoordinate.y === coordinate.y,
    );
    const newCoordinate = {
      ...coordinate,
      [name]: parsedValue,
    };
    newCurrentCoordinates.splice(coordinateIndex, 1, newCoordinate);

    setEditingData({
      ...editingData,
      correct_coordinates: newCurrentCoordinates,
    });
  };

  return (
    <form>
      <div className="mb-[10px] bg-secondary-extralight min-h-[780px] w-full p-[20px] relative">
        <div className="mb-[30px]"></div>
        <div className="flex gap-[13px]">
          <div className="w-[80%]">
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設問内容
              </div>
              <div className="w-full bg-white">
                <div className="p-[10px]" onClick={() => setEditingNode('title')}>
                  <AuthoringTextEditor
                    toolbarId={`toolbar_title`}
                    isReadOnly={editingNode !== 'title' && editingNode !== ''}
                    changeHandler={(htmlContent) => changeEditorHandle('title', htmlContent)}
                    value={editingData?.title || ''}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary h-0 min-h-[34px] flex items-center">
                正解・解答・配点
              </div>
              <div className="bg-white rounded-b-[10px]">
                <AuthoringMessageBox problemData={editingData} />
                <div className="justify-center items-start px-[20px] py-[55px] flex flex-col overflow-auto">
                  <div className="flex bg-success-lighter border-r border-[#C2C2C2]">
                    <div className="bg-white p-[8px]">
                      <PiTrashFill size={22} className="opacity-0" />
                    </div>
                    <div className="border-l border-t border-[#C2C2C2] min-w-[108px] min-h-[52px]"></div>
                    {!isEmptyObj(currentCoordinatesByY) &&
                      Object.keys(currentCoordinatesByY).map((coordinateYId) => (
                        <div
                          className="border-l border-t border-[#C2C2C2] min-w-[108px] min-h-[52px]"
                          onClick={() => setEditingNode(`${coordinateYId}`)}
                          key={`${coordinateYId}_th`}
                        >
                          <div className="relative">
                            <div
                              className={classNames("text-primary-darker cursor-pointer flex items-center absolute top-[-30px] justify-center w-full", {
                                "!cursor-not-allowed text-primary-light": checkIsLastCoordinate('column')
                              })}
                              onClick={() =>
                                removeMatrixCoordinate('column', Number(coordinateYId))
                              }
                            >
                              <PiTrashFill size={22} />
                            </div>
                          </div>
                          <AuthoringTextEditor
                            toolbarId={`toolbar_issue_${coordinateYId}`}
                            className="bg-white rounded-[7px] m-[10px]"
                            changeHandler={(htmlContent) =>
                              changeEditorHandle(`${coordinateYId}`, htmlContent)
                            }
                            isReadOnly={editingNode !== `${coordinateYId}`}
                            value={getIssueById(currentIssues, Number(coordinateYId))?.label}
                            width="w-[100px]"
                          />
                        </div>
                      ))}
                  </div>
                  <div className="">
                    <div className="bg-success-lighter">
                      <div className="flex">
                        <div className="">
                          {coordinateKeyXs.map(
                            (coordinateXId, coordinateXIdIndex, coordinateXIdArr) => (
                              <div key={`${coordinateXId}_td`} className="flex">
                                <div
                                  className={classNames("text-primary-darker bg-white cursor-pointer flex items-center p-[8px]", {
                                    "!cursor-not-allowed text-primary-light": checkIsLastCoordinate('row')
                                  })}
                                  onClick={() =>
                                    removeMatrixCoordinate('row', Number(coordinateXId))
                                  }
                                >
                                  <PiTrashFill size={22} />
                                </div>
                                <div
                                  className={`flex border-[#C2C2C2] ${
                                    coordinateXIdIndex === coordinateXIdArr.length - 1
                                      ? 'border-b'
                                      : ''
                                  }`}
                                >
                                  <div
                                    className="border-l border-t border-[#C2C2C2] min-w-[108px] min-h-[52px]"
                                    onClick={() => setEditingNode(`${coordinateXId}`)}
                                  >
                                    <AuthoringTextEditor
                                      toolbarId={`toolbar_issue_${coordinateXId}`}
                                      className="bg-white rounded-[7px] m-[10px]"
                                      changeHandler={(htmlContent) =>
                                        changeEditorHandle(`${coordinateXId}`, htmlContent)
                                      }
                                      isReadOnly={editingNode !== `${coordinateXId}`}
                                      value={
                                        getIssueById(currentIssues, Number(coordinateXId))?.label
                                      }
                                      width="w-[100px]"
                                    />
                                  </div>
                                  <div className="flex">
                                    {isUsableArr(currentCoordinates) &&
                                      currentCoordinates
                                        ?.filter(
                                          (coordinateY) =>
                                            Number(coordinateY.x) === Number(coordinateXId),
                                        )
                                        ?.map((coordinateY) => (
                                          <div className="" key={coordinateY.id + 'y'}>
                                            <div className="pl-[15px] border-l border-t bg-white border-[#C2C2C2] min-w-[108px] h-full flex gap-[10px] items-center">
                                              <input
                                                type="checkbox"
                                                name="checked"
                                                id=""
                                                checked={
                                                  !!getCoordinateByAxis(
                                                    coordinateY.x,
                                                    coordinateY.y,
                                                  )?.checked
                                                }
                                                onChange={(e) => matrixChangeHandle(e, coordinateY)}
                                              />
                                              <input
                                                type="number"
                                                name="point"
                                                id=""
                                                className="w-[35px] h-[35px] !border-secondary-light !p-[5px]"
                                                placeholder="0"
                                                value={
                                                  getCoordinateByAxis(coordinateY.x, coordinateY.y)
                                                    ?.point || ''
                                                }
                                                onChange={(e) => matrixChangeHandle(e, coordinateY)}
                                              />
                                            </div>
                                          </div>
                                        ))}
                                  </div>
                                </div>
                                <div className="text-primary-darker border-l border-[#C2C2C2] bg-white cursor-pointer flex items-center p-[10px]"></div>
                              </div>
                            ),
                          )}
                        </div>
                        <div className="bg-white">
                          <div
                            className="cursor-pointer pl-[15px]"
                            onClick={() => addMatrixCoordinate('column')}
                          >
                            <div className="flex justify-center">
                              <div className={classNames(
                                "bg-primary rounded-[5px] w-[40px] h-[40px] flex justify-center items-center text-white cursor-pointer mb-[10px]", {
                                  '!cursor-not-allowed': getLengthCorrectCoordinate('column') >= 10
                                }
                              )}>
                                <HiPlus size={40} />
                              </div>
                            </div>
                            <div className="text-center text-[12px] font-[500] leading-[100%] text-secondary-dark">
                              列を追加
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-[50px]">
                      <div className="cursor-pointer" onClick={() => addMatrixCoordinate('row')}>
                        <div className="flex justify-center">
                          <div className={classNames("bg-primary rounded-[5px] w-[40px] h-[40px] flex justify-center items-center text-white cursor-pointer mb-[10px]", 
                          {
                            '!cursor-not-allowed': getLengthCorrectCoordinate('row') >= 10
                          })}>
                            <HiPlus size={40} />
                          </div>
                        </div>
                        <div className="text-center text-[12px] font-[500] leading-[100%] text-secondary-dark">
                          行を追加
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[20%] flex flex-col gap-y-[15px]">
            <div className="flex flex-col">
              <div className="p-[10px] text-[14px] font-[500] leading-[100%] text-white w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設定
              </div>
              <div className="bg-white min-h-[100px] px-[10px]">
                <div className="mb-[15px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    組合せの回数
                  </div>
                  <div className="choice_limit flex items-center gap-[10px] mb-[5px]">
                    <div className="text-[12px] font-[400] leading-[100%]">最小</div>
                    <input
                      type="number"
                      name="min_choice"
                      placeholder="0"
                      className="w-[71px] h-[32px] !pr-0"
                      onChange={tabOptionChangeHandle}
                      value={editingData?.min_choice || ''}
                    />
                  </div>
                  <div className="choice_limit flex items-center gap-[10px]">
                    <div className="text-[12px] font-[400] leading-[100%]">最大</div>
                    <input
                      type="number"
                      name="max_choice"
                      placeholder="0"
                      className="w-[71px] h-[32px] !pr-0"
                      onChange={tabOptionChangeHandle}
                      value={editingData?.max_choice || ''}
                    />
                  </div>
                </div>
                <div className="mb-[20px] pt-[10px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    配点初期値
                  </div>
                  <input
                    type="number"
                    name="default_score"
                    placeholder="0"
                    className="w-[71px] h-[32px]"
                    onChange={tabOptionChangeHandle}
                    value={Number(editingData?.default_score) || ''}
                  />
                </div>
              </div>
            </div>
            <div className="text-white">
              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
                  onClick={submitEditingDataHandle}
                >
                  <LiaSave size={23} /> 保存
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={hideEditorHandle}
                >
                  キャンセル
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer gap-[5px] flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={removeHandle}
                >
                  <PiTrash size={20} />
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MatrixQuestionEdit;
