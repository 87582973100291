import InformationDetail from "./PropertiesSettingComponent/InformationDetail";
import { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import ModalEditScore from "./PropertiesSettingComponent/ModalEditScore";
import { request } from "../../../../../services/axios/axios";
import { API } from "../../../../../services/constants/route/api";
import { swalSuccess } from "../../../../../services/helpers/swal";
import { DETAIL_ITEM_MESSAGES } from "../../../../../services/constants/admin/pages/examSetting/itemSettingExam";
import { QuestionProblem } from "../../../../../types/admin/question";
import "../../../../../assests/styles/settingProperties.css";
import ButtonSubmit from "./PropertiesSettingComponent/ButtonSubmit";
import { useAuthoringExamContext } from "../../../../../context/Exam/AuthoringExamContext";
import { isEmptyObj, isUsableArr } from "../../../../../services/helpers/etc";
import { TYPE_OF_EXAM_DISPLAY } from "../../../../../services/constants/admin/pages/exam";
import { ExamItemType } from "../../../../../types/admin/examAuthoring";
import { handleProblemScoreCalculator } from "../../../Question/AuthoringQuestion/QuestionEditorHandler";
import { handleScoreExamItems } from "../ExamAuthoring/ExamAuthoring";

const ItemSetting = () => {
  const { exam, setExam, settingPropertyOrders } =
    useAuthoringExamContext();
  const [examItem, setExamItem] = useState<ExamItemType>({
    name: "",
    identifier: "",
    order: 0,
    question_id: 0,
    question_resource_id: 0,
  });
  const [questionProblems, setQuestionProblems] = useState<QuestionProblem[]>(
    []
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  
  const handleSubmitUpdate = () => {
    setExam((exam) => {
      const newExamParts = exam.exam_parts.map((examPart) => {
        let totalScoreExamPart = examPart.total_score || 0;

        if (examPart.order === settingPropertyOrders.orderPart) {
          const newExamSections = examPart.exam_sections.map((examSection) => {
            let totalScoreExamGroup = examSection.total_score || 0;

            if (examSection.order === settingPropertyOrders.orderSection) {
              const newExamItems = examSection.exam_items.map((examItem) => {
                if (examItem.order === settingPropertyOrders.orderItem) {
                  let totalScoreExamItem = 0;
                  questionProblems.forEach((question) => totalScoreExamItem += handleProblemScoreCalculator(question));
                  
                  return {
                    ...examItem,
                    question_data: { problems: questionProblems },
                    total_score: totalScoreExamItem
                  };
                }

                return examItem;
              });

              const newTotalScoreExamGroup = handleScoreExamItems(newExamItems);
              totalScoreExamPart = totalScoreExamPart - totalScoreExamGroup + newTotalScoreExamGroup;

              return { ...examSection, exam_items: newExamItems, total_score: newTotalScoreExamGroup };
            }

            return examSection;
          });

          return { ...examPart, exam_sections: newExamSections, total_score: totalScoreExamPart };
        }

        return examPart;
      });

      return { ...exam, exam_parts: newExamParts };
    });

    swalSuccess();
  };

  useEffect(() => {
    if (
      isEmptyObj(exam) ||
      settingPropertyOrders.orderType !== TYPE_OF_EXAM_DISPLAY.ITEM
    ) {
      return;
    }

    const findExamItem = exam.exam_parts
      .find((examPart) => examPart.order === settingPropertyOrders.orderPart)
      ?.exam_sections?.find(
        (examSection) =>
          examSection.order === settingPropertyOrders.orderSection
      )
      ?.exam_items?.find(
        (examItem) => examItem.order === settingPropertyOrders.orderItem
      );

    if (findExamItem) {
      setExamItem(findExamItem);
      setQuestionProblems(findExamItem.question_data?.problems || []);
    }
  }, [settingPropertyOrders]);

  useEffect(() => {
    if (
      !examItem.question_resource_id ||
      isUsableArr(examItem.question_data?.problems)
    )
      return;

    const getQuestionResource = async () => {
      await request.get(
        `${API.ADMIN_QUESTION_RESOURCE.GET_QUESTION_RESOURCE}?question_resource_id=${examItem.question_resource_id}`,
        (data) => {
          const response = JSON.parse(data);
          setQuestionProblems(response.problems || []);
        }
      );
    };

    getQuestionResource();
  }, [examItem.question_resource_id]);

  return (
    <>
      <div className="flex-col  bg-[#EFF1F0] h-auto border-[#BEBEBE] font-[400] min-w-[320px]">
        <div className="w-full flex-col gap-[10px] p-[20px] bg-[#F4F4F4] ">
          <div className="text-[14px] pb-[15px]">{examItem.name}</div>
          <div className="flex pb-[15px] justify-between items-center gap-[15px]">
            <button
              className="flex justify-start items-center gap-[8px] w-full"
              type="button"
              onClick={() => setShowModal(true)}
            >
              <BiEditAlt className="w-[24px] h-[24px]" />
              <div className="text-[14px]">スコア編集</div>
            </button>
            <InformationDetail text={DETAIL_ITEM_MESSAGES.SCORE_EDIT} />
          </div>
        </div>

        <ButtonSubmit handleSubmitUpdate={handleSubmitUpdate} />
      </div>

      <ModalEditScore
        isShow={showModal}
        questionProblems={questionProblems}
        setQuestionProblems={setQuestionProblems}
        setShowModal={setShowModal}
      />
    </>
  );
};
export default ItemSetting;
