import { BiSearchAlt2 } from 'react-icons/bi';
import RoundedButton from '../../commons/RoundButton';
import { HiPlus } from 'react-icons/hi';
import { ChangeEvent, useEffect, useState } from 'react';
import { FILTER } from '../../../../services/constants/admin/main';
import {
  swalClose,
  swalError,
  swalSuccess,
  swalConfirm,
} from '../../../../services/helpers/swal';
import { makeRequest } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { useGroupManagementContext } from '../../../../context/Group/Management';
import { paramizeObject } from '../../../../services/helpers/parseData';
import BaseModal from '../../commons/BaseModal';
import CandidateSearchItem from './CandidateSearchItem';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { User } from '../../../../types/user';
import { Group } from '../../../../types/admin/group';
import { swalMessage } from '../../../../services/helpers/swal';
import { TRANS_CANDIDATE_ACTION } from '../../../../services/constants/admin/pages/group';
import { IoIosCloseCircleOutline } from 'react-icons/io';

type SubmitAddDataType = {
  user_ids?: number[];
  to_group_id?: number;
};

type SubmitMoveDataType = {
  user_ids?: number[];
  to_group_id?: number;
  current_group_id?: number;
};

const TableActionManagement = () => {
  const {
    candidates,
    filter,
    isOpenModal,
    modalStep,
    checkedCandidates,
    mainGroups,
    checkedCandidateIds,
    title,
    setCandidates,
    setFilter,
    setIsOpenModal,
    setModalStep,
    setCheckedCandidates,
    setCheckedCandidateIds,
    setCheckedObj,
    setTriggerRefreshCourseManagement,
  } = useGroupManagementContext();
  const { getParamValue } = useObjectRoutes();
  const groupId = getParamValue('group_id');
  const [name, setName] = useState<string>('');
  const [searchData, setSearchData] = useState<User[]>([]);
  const [targetSubgroup, setTargetSubgroup] = useState<Group['sub_group']>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number>(0);
  const [selectedSubgroupId, setSelectedSubgroupId] = useState<number>(0);
  const [isSubmittable, setIsSubmittable] = useState<boolean>(true);
  const [submitAddData, setSubmitAddData] = useState<SubmitAddDataType>({});
  const [submitMoveData, setSubmitMoveData] = useState<SubmitMoveDataType>({});

  const changeHandle = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleChangeModal = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setName(e.target.value);
  };

  const resetStates = () => {
    setTargetSubgroup([]);
    setCheckedCandidateIds([]);
    setCheckedCandidates([]);
    setSelectedGroupId(0);
    setSelectedSubgroupId(0);
    setCheckedObj({});
    setTriggerRefreshCourseManagement(new Date().getTime());
  };

  useEffect(() => {
    if (!name) return;
    const timer = setTimeout(() => {
      const fetchUser = async () => {
        const result = await makeRequest({
          method: 'get',
          url: `${API.ADMIN_GROUP.SEARCH_CANDIDATE}?not_in_group=${groupId}&search_query=${name}`,
        });

        if (!result.data) return swalError();
        const filteredSearchData = (result.data as User[]).filter(
          (user) => !checkedCandidateIds.includes(user.id || 0),
        );

        setSearchData(filteredSearchData);
        swalClose();
      };

      fetchUser();
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (!groupId || !Object.keys(filter).length) return;

    const timer = setTimeout(async () => {

      const paramsObj = {
        group_id: groupId,
        search: filter?.search ? filter.search.trim() : '',
        limit: filter.limit || '',
      };

      const result = await makeRequest({
        method: 'get',
        url: `${API.ADMIN_GROUP.GET_GROUP_CANDIDATE}?${paramizeObject(paramsObj)}`,
      });

      if (!result.data) return swalError();

      resetStates();
      setIsOpenModal(false);
      if (candidates) setCandidates(result.data);
      swalClose();
    }, 500);

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    const targetGroupId = selectedSubgroupId || selectedGroupId;

    if (targetGroupId === Number(groupId)) return setIsSubmittable(false);

    setIsSubmittable(true);
    setSubmitMoveData({ ...submitMoveData, to_group_id: targetGroupId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupId, selectedSubgroupId]);

  useEffect(() => {
    const dataSet = { ...submitAddData, user_ids: checkedCandidateIds };
    setSubmitAddData(dataSet);
    setSubmitMoveData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedCandidateIds]);

  const handleSelectGroup = async (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.name === 'sub_group') {
      setSelectedSubgroupId(Number(e.target.value));
      return;
    }

    setSelectedGroupId(Number(e.target.value));

    const result = await makeRequest({
      method: 'get',
      url: `${API.ADMIN_GROUP.GET_SUBGROUP_LIST}?main_group_id=${e.target.value}`,
    });

    if (!result.status) return swalError();
    setTargetSubgroup(result.data);
    swalClose();
  };

  const handleMoveCandidate = async () => {
    if (
      !candidates ||
      !checkedCandidates ||
      !checkedCandidates.length ||
      !checkedCandidateIds ||
      !checkedCandidateIds.length ||
      !selectedGroupId
    )
      return;

    if (!isSubmittable)
      return swalMessage('Not submittable', 'Can not add candidate in the same group', 'warning');

    const result = await makeRequest({
      method: 'patch',
      url: API.ADMIN_GROUP.MOVE_USERS_TO_GROUP,
      data: submitMoveData,
    });

    if (!result.status) return swalError();
    setCandidates(candidates.filter((candidate) => !checkedCandidateIds.includes(candidate.id || 0)));

    resetStates();
    swalSuccess();
    setIsOpenModal(false);
  };

  const handleAddCandidate = async () => {
    if (
      !candidates ||
      !checkedCandidates ||
      !checkedCandidates.length ||
      !checkedCandidateIds ||
      !checkedCandidateIds.length
    )
      return;

    const result = await makeRequest({
      method: 'patch',
      url: API.ADMIN_GROUP.ADD_USERS_TO_GROUP,
      data: submitMoveData,
    });

    if (!result.status) return swalError();

    setCandidates([...checkedCandidates, ...candidates]);

    resetStates();
    swalSuccess();
    setIsOpenModal(false);
  };

  const handleRemoveCandidates = async () => {
    if (!checkedCandidateIds || !checkedCandidateIds.length) return;

    swalConfirm(async () => {
      const result = await makeRequest({
        method: 'patch',
        url: API.ADMIN_GROUP.REMOVE_USERS_TO_GROUP,
        data: { user_ids: checkedCandidateIds, group_id: groupId },
      });

      if (!result.status) return swalError();

      const newCandidates = candidates?.filter(
        (candidate) => !checkedCandidateIds.includes(candidate.id || 0),
      );
      setCandidates(newCandidates);

      setCheckedCandidates([]);
      setCheckedCandidateIds([]);
      swalSuccess();
    }, '受験者をこのグループから削除しますか？<br/>（アカウントは削除されません）');
  };

  return (
    <section className="w-full">
      <RoundedButton
        onClick={() => {
          if (!groupId) return;
          setIsOpenModal(true);
          setSubmitAddData({
            to_group_id: Number(groupId),
            user_ids: checkedCandidateIds,
          });
          setModalStep(TRANS_CANDIDATE_ACTION.ADD);
          resetStates();
        }}
        className={`${groupId || '!cursor-not-allowed'}`}
      >
        <div className="flex items-center justify-center">
          <HiPlus size={18} className="mr-[12px]" />
          このグループに受験者を登録する
        </div>
      </RoundedButton>
      <div className="mt-[31px]">
        <div className="flex items-center justify-between font-[500] text-[12px] leading-[100%] text-white h-[28px]">
          <div className="flex items-center justify-start gap-[5px]">
            <div className="text-secondary-dark">チェックした受験者を</div>
            <div
              className={`cursor-pointer bg-danger rounded-[5px] py-[8px] px-[15px] ${
                (groupId && checkedCandidateIds.length) || '!cursor-not-allowed'
              }`}
              onClick={handleRemoveCandidates}
            >
              削除
            </div>
            <div
              className={`cursor-pointer bg-secondary-light rounded-[5px] py-[8px] px-[15px] ${
                (groupId && checkedCandidateIds.length) || '!cursor-not-allowed'
              }`}
              onClick={() => {
                if (!groupId || !checkedCandidateIds.length) return;
                setIsOpenModal(true);
                setSubmitMoveData({
                  to_group_id: selectedGroupId,
                  user_ids: checkedCandidateIds,
                  current_group_id: Number(groupId),
                });
                setModalStep(TRANS_CANDIDATE_ACTION.MOVE);
              }}
            >
              グループを移動
            </div>
          </div>

          {/* limit */}
          <div className="flex items-end gap-[10px]">
            <div className="relative">
              <select
                name="limit"
                id=""
                className="min-h-[30px] pr-[33px] pl-[7px] text-secondary-dark text-[11px] leading-[100%] w-[95px]"
                onChange={changeHandle}
                value={filter?.limit || FILTER.OPTION.RECORD_LIMIT[0].value}
              >
                {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
                  <option value={item.value} className="" key={index}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            {/* search */}
            <div className="relative">
              <input
                type="text"
                name="search"
                id=""
                className="h-full max-w-[200px] pl-[12px] text-[11px] leading-[100%] min-h-[30px] text-secondary-dark"
                placeholder="受験者を検索"
                onChange={changeHandle}
                value={filter?.search || ''}
              />
              <BiSearchAlt2
                className="absolute top-[7px] right-[9.26px] text-secondary-light"
                size={18}
              />
            </div>
          </div>
        </div>
      </div>

      {isOpenModal && (
        <>
          {modalStep === TRANS_CANDIDATE_ACTION.ADD && (
            <BaseModal
              setIsOpen={setIsOpenModal}
              justifyDirection="end"
              onClick={handleAddCandidate}
              height={550}
              submitLabel="新規登録"
            >
              <div className="text-center pt-[103px]">
                <div className="font-[700] font-[16px] leading-[100%] leading-[0.02em] text-primary mb-[44px]">
                  グループ「{title}」に新規受験者を追加します
                </div>
                <div className="flex justify-center mb-[50px]">
                  <div className="max-w-[500px]">
                    <div className="text-[14px] leading-[20px] mb-[8px] text-left">
                      メールアドレスまたは名前からグループに受験者を追加
                    </div>
                    <div className="text-[12px] leading-[17px] text-secondary mb-[8px] text-left">
                      複数人同時に追加可能です
                    </div>
                    <div className="relative">
                      <input
                        type="text"
                        name="name"
                        id=""
                        className="text-[14px] leading-[100%] h-[40px]"
                        placeholder="受験者を検索"
                        onChange={handleChangeModal}
                        value={name}
                      />
                      <BiSearchAlt2
                        className="absolute top-[11.26px] right-[11.26px] text-secondary-light"
                        size={18}
                      />
                      <div className="absolute top-12 left-0 z-[1000] max-h-[250px] overflow-y-auto w-full bg-white shadow-2xl rounded-[5px] text-left pl-[10px]">
                        {name &&
                          searchData.map((candidate) => (
                            <CandidateSearchItem
                              key={candidate.id}
                              candidate={candidate}
                              className="!max-w-[350px] !min-h-[50px] rounded-[5px] mb-[5px] !justify-start !cursor-pointer hover:bg-secondary-lighter"
                              onClick={() => {
                                if (!checkedCandidates || !checkedCandidateIds || !candidate.id) return;
                                setCheckedCandidates([...checkedCandidates, candidate]);
                                setCheckedCandidateIds([...checkedCandidateIds, candidate.id]);
                                setName('');
                                setSearchData([]);
                              }}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mb-[20px]">
                  <div className="w-full max-w-[460px] flex gap-[40px] overflow-auto pb-[10px] pt-[10px]">
                    {checkedCandidates &&
                      checkedCandidates.map((candidate) => (
                        <div className="relative" key={candidate.id}>
                          <IoIosCloseCircleOutline
                            size={20}
                            className="absolute top-[-10px] right-[-10px] text-secondary-light bg-white cursor-pointer hover:w-[22px] hover:bg-secondary-extralight rounded-full"
                            onClick={() => {
                              if (!candidates) return;
                              setCheckedCandidates(
                                checkedCandidates.filter(
                                  (checkedCandidate) => checkedCandidate.id !== candidate.id,
                                ),
                              );

                              setCheckedCandidateIds(
                                checkedCandidateIds.filter(
                                  (checkedCandidateId) => checkedCandidateId !== candidate.id,
                                ),
                              );
                            }}
                          />
                          <CandidateSearchItem key={candidate.id} candidate={candidate} />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </BaseModal>
          )}
          {modalStep === TRANS_CANDIDATE_ACTION.MOVE && (
            <BaseModal
              setIsOpen={setIsOpenModal}
              justifyDirection="end"
              onClick={handleMoveCandidate}
              height={630}
              submitLabel="移動する"
            >
              <div className="text-center pt-[103px]">
                <div className="font-[700] text-[16px] leading-[100%] text-primary mb-[24px]">
                  選択された受験者を別のグループへ移動します
                </div>
                <div className="flex justify-center mb-[20px]">
                  <div className="max-w-[510px] flex gap-[40px] overflow-auto pb-[10px]">
                    {checkedCandidates &&
                      checkedCandidates.map((candidate) => (
                        <CandidateSearchItem key={candidate.id} candidate={candidate} />
                      ))}
                  </div>
                </div>
                <div className="flex justify-center w-full mb-[40px]">
                  <div className="w-full max-w-[372px] bg-secondary-extralight font-[500px] text-[14px] leading-[14px] tracking-[2%] flex items-center gap-[10px] min-h-[34px] pl-[20px]">
                    <div className="text-secondary">現在のグループ：</div>
                    <div className="font-[400]">{title}</div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="w-full max-w-[372px] font-[500] text-[14px] leading-[24px] mb-[5px] text-left">
                  移動先のグループを選択
                  </div>
                </div>

                <div className="mb-[5px]">
                  <select
                    name="group"
                    id=""
                    className="min-h-[50px] w-full max-w-[380px] text-[14px] leading-[16px] text-secondary pl-[17px]"
                    onChange={handleSelectGroup}
                    value={selectedGroupId}
                  >
                    <option value="">グループを選択してください</option>
                    {mainGroups.map((group) => (
                      <option value={group.id} key={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="">
                  <select
                    name="sub_group"
                    id=""
                    className="min-h-[50px] w-full max-w-[380px] text-[14px] leading-[16px] text-secondary pl-[17px]"
                    onChange={handleSelectGroup}
                    value={selectedSubgroupId}
                  >
                    <option value="">サブグループを選択してください</option>
                    {targetSubgroup &&
                      targetSubgroup.map((subgroup) => (
                        <option value={subgroup.id} key={subgroup.id}>
                          {subgroup.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </BaseModal>
          )}
        </>
      )}
    </section>
  );
};

export default TableActionManagement;
