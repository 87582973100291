import React from "react";
import CBTComponent from "./RegisterExaminationComponent/RegisterExaminationStep2/CBTComponent";
import IBTComponent from "./RegisterExaminationComponent/RegisterExaminationStep2/IBTComponent";
import { EXAMINATION_FORMAT } from "../../../services/constants/admin/pages/examination";
import { useExaminationRegisterContext } from "../../../context/Candidate/ExaminationRegisterContext";


const RegisterExaminationStep2 = () => {
  const { examinationType } = useExaminationRegisterContext();
  const displayComponent = () => {
    switch (examinationType) {
      case EXAMINATION_FORMAT.CBT:
        return <CBTComponent />;
      case EXAMINATION_FORMAT.IBT:
        return <IBTComponent />;
      default:
        <></>;
    }
  };

  return <>
    {displayComponent()}
  </>;
};

export default RegisterExaminationStep2;
