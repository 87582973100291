import * as React from 'react';

const WrittenQuestion = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      style={{
        stroke: 'currentcolor',
      }}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
      d="M11 17h7.8c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C22 15.48 22 14.92 22 13.8v-3.6c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C20.48 7 19.92 7 18.8 7H11M7 17H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874C2 15.48 2 14.92 2 13.8v-3.6c0-1.12 0-1.68.218-2.108a2 2 0 01.874-.874C3.52 7 4.08 7 5.2 7H7m0-4v18m-2.5 0h5m-5-18h5"
    ></path>
  </svg>
);
export default WrittenQuestion;
