/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../services/axios/axios";
import { API } from "../../services/constants/route/api";
import { ROUTE } from "../../services/constants/route/router";

const PaletteLogin = () => {
  const navigate = useNavigate();
  let { token } = useParams() || null;
  useEffect(() => {
    const verifyPaletteToken = async () => {
      await request.get(
        `${API.AUTH.VERIFY_PALETTE_LOGIN}?token=${token}`,
        (loginData) => {
          localStorage.setItem("accessToken", loginData.token);
          localStorage.setItem("user", JSON.stringify(loginData.user));
        },
      );
      navigate(ROUTE.LOGIN);
    };

    verifyPaletteToken();
  }, []);

  return <div></div>;
};

export default PaletteLogin;
