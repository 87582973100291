import React, { useEffect, useState } from 'react';
import { swalClose, swalError, swalSuccess } from '../../../services/helpers/swal';
import { makeRequest } from '../../../services/axios/axios';
import { API } from '../../../services/constants/route/api';
import { Candidate } from '../../../types/admin/candidate';
import { TbTrash } from 'react-icons/tb';
import { RiErrorWarningLine } from 'react-icons/ri';
import { ErrorData } from '../../../types/globals';
import ErrorBox from '../../commons/form/ErrorBox';
import { Link, useNavigate } from 'react-router-dom';
import { PATH_ADMIN_CANDIDATE } from '../../../services/constants/route/router';
import { GENDER_DATA } from '../../../services/constants/candidate/pages/profile';
import Spinner from '../../commons/icons/Animations/Spinner';

type MainGroup = {
  code: string;
  id: number;
  name: string;
  order: number;
  parent_group_id: null;
  status: number;
  candidate_count: number;
  sub_groups: SubGroup[];
};

type SubGroup = {
  code: string;
  id: number;
  name: string;
  order: number;
  parent_group_id: number;
  status: number;
  candidate_count: number;
};

type GroupGroup = {
  mainGroupId: number;
  subGroupId: number;
};
const CandidateCreate = () => {
  const [candidateFormData, setCandidateFormData] = useState<Candidate>({
    status: 0,
    gender: GENDER_DATA[2].value, // gender other
  } as any);
  const [errors, setErrors] = useState<ErrorData>({});
  const [mainGroups, setMainGroups] = useState<MainGroup[]>([]);
  const [groupGroups, setGroupGroups] = useState<GroupGroup[]>([{ mainGroupId: 0, subGroupId: 0 }]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const result = await makeRequest({
        method: 'get',
        url: `${API.ADMIN_GROUP.GET_GROUP_LIST}`,
      });

      if (!result.data) return swalError();

      const mainGroups = result.data.map((mainGroupData: any) => {
        const subGroups = mainGroupData.sub_group.map((subGroupData: any) => ({
          id: subGroupData.id,
          name: subGroupData.name,
        }));
        return {
          id: mainGroupData.id,
          name: mainGroupData.name,
          sub_groups: subGroups,
        };
      });
      setMainGroups(mainGroups);
      swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMainGroupChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const newGroupGroups = [...groupGroups];
    newGroupGroups[index].mainGroupId = parseInt(event.target.value);
    newGroupGroups[index].subGroupId = 0;
    setGroupGroups(newGroupGroups);
  };
  const handleSubGroupChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const newGroupGroups = [...groupGroups];
    newGroupGroups[index].subGroupId = parseInt(event.target.value);
    setGroupGroups(newGroupGroups);
  };

  const handleAddGroupGroup = () => {
    setGroupGroups([...groupGroups, { mainGroupId: 0, subGroupId: 0 }]);
  };

  const handleDeleteGroupGroup = (index: number) => {
    const newGroupGroups = [...groupGroups];
    newGroupGroups.splice(index, 1);
    setGroupGroups(newGroupGroups);
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    const file = (e.target as HTMLInputElement).files && (e.target as HTMLInputElement).files?.[0];
    if (file) {
      setCandidateFormData({ ...candidateFormData, [name]: file });
      return;
    }

    setCandidateFormData({ ...candidateFormData, [name]: value });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const filteredGroupGroups = groupGroups
      .flatMap(({ mainGroupId, subGroupId }) =>
        subGroupId !== 0 ? [subGroupId] : mainGroupId !== 0 ? [mainGroupId] : [],
      )
      .filter((num) => num !== 0)
      .filter((num, index, self) => self.indexOf(num) === index);
    const result = await makeRequest({
      method: 'post',
      url: API.ADMIN_CANDIDATE.CREATE_CANDIDATE,
      data: {
        ...candidateFormData,
        group_ids: filteredGroupGroups,
      },
    });
    if (!result.status) {
      setIsSubmitting(false);
      setErrors(result.error as ErrorData);
      return swalError();
    }

    setIsSubmitting(false);
    swalSuccess();
    navigate(PATH_ADMIN_CANDIDATE.DEFAULT);
  };

  return (
    <>
      <div className="mt-[40px]">基本情報</div>
      <div className="mb-[25px] mt-[10px]">
        {errors && Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">メールアドレス</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="text-secondary-dark ml-[10px] w-[85%] flex items-center mx-[5px] mr-[30px]">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] w-[40%] !rounded-[5px]"
              name="email"
              value={candidateFormData.email}
              onChange={handleChange}
            />
            <div className="min-w-[90px] ml-[5px] pl-[12px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
              <RiErrorWarningLine className="absolute top-[4px] left-[4px]" size={18} />
              <p className="pl-[12px]">確認中</p>
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pl-[14px] pt-[10px]">
            <div className="w-full flex justify-between">
              <div className="">セクション</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            {groupGroups.map((groupGroup, index) => (
              <div key={index} className="flex justify-between w-full max-h-[40px] mx-[5px]">
                <div className="text-secondary-dark w-full flex items-center mx-[5px] relative">
                  <select
                    className="h-[30px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark text-[13px] leading-[20px] w-full !py-0 rounded-[5px]"
                    name="group_ids"
                    onChange={(event) => handleMainGroupChange(event, index)}
                  >
                    <option value=""></option>
                    {mainGroups.map((mainGroup) => (
                      <option key={mainGroup.id} value={mainGroup.id}>
                        {mainGroup.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-secondary-dark w-full flex items-center mx-[5px] relative">
                  <select
                    className="h-[30px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark text-[13px] leading-[20px] w-full !py-0 rounded-[5px]"
                    name="group_ids"
                    onChange={(event) => handleSubGroupChange(event, index)}
                  >
                    <option value="0"></option>
                    {mainGroups
                      .find((ms) => ms.id === groupGroup.mainGroupId)
                      ?.sub_groups.map((subGroup) => (
                        <option key={subGroup.id} value={subGroup.id}>
                          {subGroup.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="text-secondary-dark flex items-center w-[25px]">
                  {index > 0 ? (
                    <TbTrash
                      className="mb-[4px] text-[#7A7A7A]"
                      onClick={() => handleDeleteGroupGroup(index)}
                      size={50}
                    />
                  ) : (
                    <TbTrash className="mb-[4px] text-secondary-light invisible" size={50} />
                  )}
                </div>
              </div>
            ))}
            <button
              className={`bg-primary-darker text-white text-[12px] font-[500] leading-[17px] flex items-center justify-center ml-[10px] rounded-[5px] min-h-[25px] w-full max-w-[180px] cursor-pointer mb-[5px]`}
              onClick={handleAddGroupGroup}
            >
              <span className="text-[23px] mb-[3px] mr-[8px]">+</span> 所属グループを追加
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center mb-[60px] mt-[60px] gap-10">
        <Link to={PATH_ADMIN_CANDIDATE.DEFAULT}>
          <button className="bg-[#BEBEBE] px-[64px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[16px] leading-[100%]">
            一覧画面へ戻る
          </button>
        </Link>
        <button
          className={`bg-primary w-full max-w-[272px] h-0 min-h-[38px] text-white rounded-[10px] font-[700] text-[16px] leading-[100%]
          ${isSubmitting ? 'pointer-events-none opacity-50' : ''}
          `}
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? <Spinner /> : '入力内容で登録する'}
        </button>
      </div>
    </>
  );
};

export default CandidateCreate;
