import * as React from 'react';

const FillInBlank = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      style={{
        stroke: 'currentcolor',
      }}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
      d="M13.5 21.997c.675-.009 1.08-.048 1.408-.215a2 2 0 00.874-.874c.167-.328.206-.733.215-1.408M4.5 21.997c-.675-.009-1.08-.048-1.408-.215a2 2 0 01-.874-.874c-.167-.328-.206-.733-.215-1.408m0-9c.009-.675.048-1.08.215-1.408a2 2 0 01.874-.874c.328-.167.733-.206 1.408-.215M2 16v-2m8 8H8M18.8 2h-7.6c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C8 3.52 8 4.08 8 5.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C9.52 16 10.08 16 11.2 16h7.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C22 14.48 22 13.92 22 12.8V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C20.48 2 19.92 2 18.8 2z"
    ></path>
    <path
      style={{
        stroke: 'currentcolor',
      }}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
      d="M12 9h3.6a2 2 0 100-4H12v4zm0 0h4.4a2 2 0 110 4H12V9z"
    ></path>
  </svg>
);
export default FillInBlank;
