import { getTabObj } from "../../../helpers/parseData";
import { PATH_ADMIN_EXAMINATION, PATH_ADMIN_EXAM_VENUE } from "../../route/router";

const EXAM_MANAGEMENT_TAB_ITEM = {
  LIST_EXAMINATION: '試験一覧',
  ADD_NEW_EXAMINATION: "試験新規追加",
  VENUE_LIST: "会場一覧",
  ADD_NEW_VENUE: "新規会場追加 ",
};

const EXAM_MANAGEMENT_TAB_DATA = [
  getTabObj(EXAM_MANAGEMENT_TAB_ITEM.LIST_EXAMINATION, PATH_ADMIN_EXAMINATION.LIST),
  getTabObj(EXAM_MANAGEMENT_TAB_ITEM.ADD_NEW_EXAMINATION, PATH_ADMIN_EXAMINATION.CREATE),
  getTabObj(EXAM_MANAGEMENT_TAB_ITEM.VENUE_LIST, PATH_ADMIN_EXAM_VENUE.VENUE_LIST),
  getTabObj(EXAM_MANAGEMENT_TAB_ITEM.ADD_NEW_VENUE, PATH_ADMIN_EXAM_VENUE.ADD_NEW_VENUE),
];

const EXAMINATION_STATUS = {
  PUBLIC: 1,
  PRIVATE: 0,
};

const EXAMINATION_FORMAT = {
  CBT: 'CBT',
  IBT: 'IBT'
}

export { EXAM_MANAGEMENT_TAB_ITEM, EXAM_MANAGEMENT_TAB_DATA, EXAMINATION_FORMAT, EXAMINATION_STATUS }
