import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <>
      <div className="flex items-center justify-center h-screen bg-warning-extralight">
        <div className="bg-white px-10 pt-[35px] pb-[40px] border border-warning-light rounded-[5px]">
          <div className="flex flex-col items-center">
            <h1 className="font-[500] text-[19px] text-primary mb-[20px]">
              Not Found
            </h1>

            <h6 className="font-[500] text-[19px] text-center text-secondary mb-[35px]">
              お探しのページが見つかりません
            </h6>

            <p className="mb-[40px] text-center text-secondary font-[500] text-[14px]">
              ご指定いただいたアドレスが間違っているか<br/>
              ページが移動または削除された可能性があります。
            </p>

            <Link
              to="/"
              className="px-[30px] py-[10px] rounded-[10px] text-[14px] font-[700] text-white bg-primary hover:bg-primary-light h-[34px] w-full max-w-[200px] flex items-center justify-center"
            >
              ホームへ戻る
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
