/* eslint-disable react-hooks/exhaustive-deps */
import { TbFolderOpen } from "react-icons/tb";
import { PiFolderBold } from "react-icons/pi";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { isUsableArr } from "../../../../services/helpers/etc";
import HalfPlus from "../../../commons/icons/HalfPlus";
import { FiFileText } from "react-icons/fi";
import { useListExamContext } from "../../../../context/Exam/ListExamContext";
import { Exam } from "../../../../types/admin/exam";

type ListItemProps = {
  exam: Exam;
};

const DetailTargetFinderItem = ({ exam }: ListItemProps) => {
  const [isExpand, setIsExpand] = useState(false);
  const { exams, selectedExam, selectedTargetExam, setSelectedTargetExam } =
    useListExamContext();

  const childExams = exams.filter(
    (childExam) =>
      childExam.parent_id === exam.id &&
      childExam.id !== selectedExam.id
  );

  const handleClick = () => {
    setIsExpand(!isExpand);
    setSelectedTargetExam(exam.id);
  };

  return (
    <div className="">
      <div
        className={`hover:bg-primary-extralight px-[5px] text-secondary-light text-[22px] flex gap-[5px] cursor-pointer items-center ${
          !exam.parent_id ? "mb-[14px]" : "mb-[4px]"
        } h-[30px] ${
          selectedTargetExam.id === exam.id
            ? "bg-primary-extralight rounded-[2px]"
            : ""
        }`}
        onClick={handleClick}
      >
        {exam.is_file ? (
          <HalfPlus className="mx-[5px] shrink-0" />
        ) : (
          <div className="text-[18px]">
            {isExpand ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </div>
        )}

        {exam.is_file ? (
          <FiFileText className="mx-[2px] text-[19px] text-primary shrink-0" />
        ) : (
          <div className="">
            {isExpand ? <TbFolderOpen /> : <PiFolderBold />}
          </div>
        )}
        <div className="text-[12px] text-secondary-dark font-[500] leading-[100%] whitespace-nowrap pr-[15px]">
          {exam.name}
        </div>
      </div>
      <div className="pl-[20px]">
        {isExpand &&
          isUsableArr(childExams) &&
          childExams.map((exam, index) => (
            <DetailTargetFinderItem
              exam={exam}
              key={exam.id || index}
            />
          ))}
      </div>
    </div>
  );
};

export default DetailTargetFinderItem;
