import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useTabFinder = (currentTargetPaths: string[]) => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number>();

  useEffect(() => {
    const currentIndex = currentTargetPaths.findIndex((path) => path === location.pathname);
    setTabIndex(currentIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return { tabIndex };
};
