import React from "react";
import ExamParicipateBase from ".";
import ListExamParticipate from "../../../components/admin/ExamParticipate/List";
import ExamParticipateProvider from "../../../context/Admin/ExamParticipateContext";

const ExamParticipateListPage = () => {
  return (
    <ExamParticipateProvider>
      <ExamParicipateBase title="結果管理 ｜ 受験者一覧">
        <ListExamParticipate />
      </ExamParicipateBase>
    </ExamParticipateProvider>
  );
};

export default ExamParticipateListPage;
