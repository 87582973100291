import React, { ChangeEvent, useState } from "react";
import { TestTakerRecordProblemTest } from "../../../../../types/admin/question";
import { BsEye } from "react-icons/bs";
import BaseModal from "../../../commons/BaseModal";
import ResourceContentDisplay from "../../../../candidate/common/ResourceContentDisplay";
import { UNIT_FILE_TYPE } from "../../../../../services/constants/admin/pages/material";

type RecordVoiceQuestionProps = {
  question: TestTakerRecordProblemTest;
  numberQuestion: number;
  handleFillScoreAnswer: (e: ChangeEvent<HTMLInputElement>) => void;
};

const RecordVoiceQuestion = ({
  question,
  numberQuestion,
  handleFillScoreAnswer,
}: RecordVoiceQuestionProps) => {
  const splitAnswerData = question.answer_data?.split("/");
  const fileName = splitAnswerData
    ? splitAnswerData[splitAnswerData?.length - 1 || 0]
    : "No Data";
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="w-full flex items-stretch mb-[30px]">
      <div className="w-[40px]">
        <div className="w-full h-full flex items-center justify-center bg-warning-lighter">
          <span>{numberQuestion}</span>
        </div>
      </div>
      <div className="w-[calc(100%-40px)] flex items-center gap-x-[67px] border-b border-b-danger-light py-[10px]">
        <div className="flex-1 flex items-center gap-x-[10px] px-[15px] text-[12px] font-[500]">
          <BsEye
            size={24}
            onClick={() => setIsOpen(true)}
            className="cursor-pointer"
          />{" "}
          {fileName}
        </div>
        <div className="w-[60px] flex items-center gap-x-[5px]">
          <input
            type="text"
            name="score"
            className="h-[30px] !px-0 text-center font-[500] placeholder:text-secondary-light text-[14px]"
            value={question?.score ? question.score : ""}
            onChange={handleFillScoreAnswer}
          />
          <span className="text-[14px] font-[500]">/{question.point || 0}</span>
        </div>
      </div>
      {isOpen && (
        <BaseModal 
          setIsOpen={setIsOpen}
          withSubmit={false}  
        >
          <div className="mt-[30%] my-[30px]">
            <ResourceContentDisplay
              url={`${process.env.REACT_APP_AWS_S3_URL}/${question.answer_data}`}
              resourceType={UNIT_FILE_TYPE.TYPE_AUDIO}
            />
            <div
              className={`flex gap-[10px] justify-center w-full bg-secondary-extralight items-center h-[80px] absolute bottom-0 rounded-b-[10px] px-[65px]`}
            >
              <button
                type="button"
                className="bg-white text-secondary text-[14px] leading-[20px] w-fit px-[24px] py-[12px] rounded-[5px]"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                閉じる
              </button>
            </div>
          </div>
        </BaseModal>
      )}
    </div>
  );
};

export default RecordVoiceQuestion;
