import React, { useEffect } from 'react';
import { swalClose, swalError, swalLoading } from '../../../../services/helpers/swal';
import { makeRequest } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { useGroupManagementContext } from '../../../../context/Group/Management';
import SidebarGroupItem from './SidebarGroupItem';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { Group } from '../../../../types/admin/group';
import { PATH_ADMIN_GROUP } from '../../../../services/constants/route/router';
import { groupUtils } from '../../../../services/utils/admin/group';

const SidebarManagement = () => {
  const {
    mainGroups,
    groups,
    setSelectedGroup,
    setGroups,
    setCandidates,
    setCheckedObj,
    setTitle,
    triggerRefreshCourseManagement,
    totalCandidateAmount,
    setTotalCandidateAmount,
  } = useGroupManagementContext();
  const { calculateTotalMemberByMainGroups } = groupUtils;
  const { getParamValue, navigate } = useObjectRoutes();
  const groupId = getParamValue('group_id');

  const findGroupById = (
    groups: Group[],
    groupId: number,
  ):
    | {
        currentGroup: Group;
        parentName?: string;
      }
    | undefined => {
    for (const group of groups) {
      if (group.id === groupId) {
        return { currentGroup: group };
      }
      if (group.sub_group) {
        const foundGroup = findGroupById(group.sub_group, groupId);
        if (foundGroup) {
          return {
            currentGroup: foundGroup.currentGroup,
            parentName: group.name,
          };
        }
      }
    }
    return undefined;
  };

  useEffect(() => {
    // if (mainGroups && mainGroups.length > 0) return;

    const groupFetch = async () => {
      const result = await makeRequest({
        method: 'get',
        url: `${API.ADMIN_GROUP.GET_GROUP_LIST}`,
      });

      if (!result.data) return;

      if (!groupId && !Number(groupId) && result.data)
        return navigate(`${PATH_ADMIN_GROUP.MANAGEMENT}?group_id=${result.data[0]?.id}`);

      const foundGroup = findGroupById(result.data, Number(groupId));
      if (foundGroup) {
        const { currentGroup, parentName } = foundGroup;
        if (currentGroup && parentName) {
          setTitle(`${parentName} | ${currentGroup.name}`);
        } else if (currentGroup && !parentName) {
          setTitle(`${currentGroup.name}`);
        } else {
          setTitle('');
        }
      }

      setGroups([...groups, ...result.data]);
      swalClose();
    };

    const totalCandidateCountFetch = async () => {
      const result = await makeRequest({
        method: 'get',
        url: `${API.ADMIN_GROUP.GET_TOTAL_CANDIDATE_AMOUNT_OF_OZ}`,
      });

      if (!result.status) return swalError();

      setTotalCandidateAmount(result.data);
      swalClose();
    };

    const fetch = async () => {
      return Promise.all([groupFetch(), totalCandidateCountFetch()]);
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRefreshCourseManagement, groupId]);

  useEffect(() => {
    if (!groupId || !Number(groupId)) return;
    setSelectedGroup(Number(groupId));

    const fetch = async () => {
      const result = await makeRequest({
        method: 'get',
        url: `${API.ADMIN_GROUP.GET_GROUP_CANDIDATE}?group_id=${groupId}`,
      });

      if (!result.data) return swalError();
      setCheckedObj({});
      setCandidates(result.data);
      swalClose();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  return (
    <section>
      {/* List */}
      <div className="text-secondary-dark max-h-[328px] overflow-auto pr-[10px]">
        <div className="text-[12px] font-[500] leading-[100%] h-[30px] pl-[25px] pr-[15px] mb-[8px]  flex justify-between items-center bg-secondary-morelighter">
          <div className="">全体</div>
          <div className="">{totalCandidateAmount}</div>
        </div>
        {mainGroups && mainGroups.map((group) => <SidebarGroupItem group={group} key={group.id} />)}
      </div>
    </section>
  );
};

export default SidebarManagement;
