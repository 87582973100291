import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { getDaysInMonth } from "../../../services/helpers/date";
import { Candidate } from "../../../types/admin/candidate";
type SelectCandidateBirthDateType = {
  date?: Date;
  candidateFormData: Candidate;
  setBirthDate: React.Dispatch<React.SetStateAction<Candidate>>;
} & React.HTMLProps<HTMLSelectElement>;

const SelectCandidateBirthDate = ({
  date = new Date(),
  candidateFormData,
  setBirthDate,
  ...props
}: SelectCandidateBirthDateType) => {
  const year = date.getFullYear();
  const years = Array.from(new Array(100), (_, index) => year - index);
  const months = Array.from(new Array(12), (_, index) => index + 1);

  const [currentMonth, setCurrentMonth] = useState<number>(NaN);
  const [currentYear, setCurrentYear] = useState<number>(NaN);
  const [currentDay, setCurrentDay] = useState<number>(NaN);
  const [days, setDays] = useState<Date[]>([]);

  useEffect(() => {
    const currentDays = getDaysInMonth(currentMonth, currentYear);
    setDays(currentDays);

    if(!currentYear || !currentMonth || !currentDay) return;

    setBirthDate((prev) => ({
      ...prev,
      birthday: dayjs(
        new Date(currentYear, currentMonth - 1, currentDay)
      ).isValid() ? dayjs(
        new Date(currentYear, currentMonth - 1, currentDay)
      ).format("YYYY-MM-DD") : "",
    }));
  }, [currentDay, currentMonth, currentYear])

  useEffect(() => {
    const currentDate = candidateFormData?.birthday
      ? new Date(candidateFormData.birthday)
      : new Date(NaN);

    const currentDays = getDaysInMonth(
      currentDate.getMonth() + 1,
      currentDate.getFullYear()
    );

    setDays(currentDays);
    setCurrentYear(currentDate.getFullYear());
    setCurrentMonth(currentDate.getMonth() + 1);
    setCurrentDay(currentDate.getDate());
  }, [candidateFormData?.birthday]);

  return (
    <div className="flex items-center">
      <select
        name="year"
        className="appearance-none border border-danger-light focus:outline-none rounded-[5px] pl-[16px] pr-[34px] h-[30px] mr-[4px] my-[5px] py-0 text-[13px]"
        onChange={(e) => setCurrentYear(parseInt(e.currentTarget.value))}
        value={currentYear}
      >
        <option value="">--</option>
        {years.map((year, index) => (
          <option key={`year${index}`} value={year}>
            {year}
          </option>
        ))}
      </select>
      年
      <select
        name="month"
        className="appearance-none border border-danger-light focus:outline-none rounded-[5px] pl-[16px] pr-[34px] h-[30px] mr-[4px] ml-[10px] my-[5px] py-0 text-[13px]"
        onChange={(e) => setCurrentMonth(parseInt(e.currentTarget.value))}
        value={currentMonth}
      >
        <option value="">--</option>
        {months.map((month, index) => (
          <option key={`month${index}`} value={month}>
            {month}
          </option>
        ))}
      </select>
      月
      <select
        name="day"
        className="appearance-none border border-danger-light focus:outline-none rounded-[5px] pl-[16px] pr-[34px] h-[30px] mr-[4px] ml-[10px] my-[5px] py-0 text-[13px]"
        onChange={(e) => setCurrentDay(parseInt(e.currentTarget.value))}
        value={currentDay}
      >
        <option value="">--</option>
        {days.map((day, index) => (
          <option key={`day${index}`} value={day.getDate()}>
            {day.getDate()}
          </option>
        ))}
      </select>
      日
    </div>
  );
};

export default SelectCandidateBirthDate;
