import * as React from "react";

const Search = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 11C6.25 8.1005 8.6005 5.75 11.5 5.75C14.3995 5.75 16.75 8.1005 16.75 11C16.75 12.4475 16.1652 13.7569 15.2172 14.7074C14.266 15.6612 12.9525 16.25 11.5 16.25C8.6005 16.25 6.25 13.8995 6.25 11ZM11.5 4.25C7.77208 4.25 4.75 7.27208 4.75 11C4.75 14.7279 7.77208 17.75 11.5 17.75C13.0935 17.75 14.5588 17.1971 15.7132 16.2739L18.9697 19.5303C19.2626 19.8232 19.7374 19.8232 20.0303 19.5303C20.3232 19.2374 20.3232 18.7626 20.0303 18.4697L16.7739 15.2132C17.6971 14.0588 18.25 12.5934 18.25 11C18.25 7.27208 15.2279 4.25 11.5 4.25Z"
      fill={`${props.color || "#2E2E2E"}`}
    />
  </svg>
);

export default Search;
