import React, { ChangeEvent, useEffect, useState } from "react";
import { PiArrowFatDown, PiArrowFatRight } from "react-icons/pi";
import {
  OrderIssue,
  OrderProblemTest,
} from "../../../../../types/admin/question";
import { isUsableArr } from "../../../../../services/helpers/etc";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import AuthoringTextEditor from "../../../../admin/Question/AuthoringQuestion/QuestionEditorHandler/AuthoringTextEditor";
import { LuXCircle } from "react-icons/lu";
import { useExaminationTestContext } from "../../../../../context/ExaminationTestContext";
import { candidateToolIsOpen } from "../../../../../services/utils/candidate/examinationTest";
import { SETTING_PROPERTY_NAMES } from "../../../../../services/constants/admin/pages/exam";
import classNames from "classnames";

const OrderQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled,
}: {
  questionProblem: OrderProblemTest;
  handleUpdateProblem: (questionProblem: OrderProblemTest) => void;
  isDisabled?: boolean;
}) => {
  const { questionProblems, setQuestionProblems, openingTools } =
    useExaminationTestContext();
  const [editingData, setEditingData] = useState<OrderProblemTest>({
    questionProblem,
  } as OrderProblemTest);
  const [checkingIssue, _setCheckingIssue] = useState<OrderIssue>({});
  const currentIssues = editingData.issues || [];
  const orderQuestion = questionProblem as OrderProblemTest;
  const orderIssues: OrderIssue[] = orderQuestion?.issues || [];
  const answerOrderIssues: OrderIssue[] =
    editingData.answer_data ||
    Array.from({ length: editingData.correct_issues?.length || 0 }, () => ({}));
  const isVerticalArrange = orderQuestion.is_vertical_arrange;

  const setCheckingIssue = (issueId?: number) => {
    if (!issueId) _setCheckingIssue({});
    _setCheckingIssue(
      currentIssues.find((issue) => Number(issue.id) === Number(issueId)) || {}
    );
  };

  const getIssueById = (issueId: number) => {
    return orderIssues.find((issue) => issue.id === issueId);
  };

  const removeCheckedIssueHandle = (name?: string, issueId?: number) => {
    if (!name || !issueId || !Number(issueId)) return;

    const orderedIssueIndex = answerOrderIssues.findIndex(
      (issue) => issue.id === issueId
    );
    answerOrderIssues.splice(orderedIssueIndex, 1, {});

    setEditingData({
      ...editingData,
      answer_data: answerOrderIssues,
    });
  };

  const checkingIssueHandle = (issueId?: number) => {
    const currentOrderedIssue = answerOrderIssues.find(
      (issue) => issue.id === issueId
    );

    if (currentOrderedIssue && currentOrderedIssue.id === issueId) {
      return;
    }
    let resultIssueId = issueId;
    setCheckingIssue(resultIssueId);
  };

  const checkingIssueChoosed = (issueId?: number) => {
    const currentOrderedIssue = answerOrderIssues.find(
      (issue) => issue.id === issueId
    );

    if (currentOrderedIssue && currentOrderedIssue.id === issueId) {
      return true;
    }

    return false;
  };

  const addCorrectOrderHandle = (issueId?: number, index?: number) => {
    if (!issueId || index === undefined) return;

    const orderedIssue: OrderIssue | undefined = currentIssues.find(
      (issue) => issue.id === issueId
    );
    if (!orderedIssue || !orderedIssue.id) return;

    const newOrderedIssues = answerOrderIssues;
    newOrderedIssues.splice(index, 1, {
      id: orderedIssue.id,
      label: orderedIssue.label,
      checked: orderedIssue.checked,
      updated_at: orderedIssue.updated_at,
    });

    const editingDataNew = { ...editingData, answer_data: newOrderedIssues };
    setEditingData(editingDataNew);
    setCheckingIssue();
    handleUpdateProblem(editingDataNew);
  };

  const handleMouseUp = () => {
    if (
      !candidateToolIsOpen(SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT, openingTools)
    )
      return;

    const selector = window.getSelection();
    const textSelected = selector?.toString();
    if (!textSelected) return;
    const span = document.createElement("span");
    span.setAttribute("style", "background-color: yellow; display:inline;");
    span.innerText = textSelected;
    const newText = questionProblem.title?.replace(
      textSelected,
      span.outerHTML
    );

    setQuestionProblems(
      questionProblems.map((question) => {
        if (question.id === questionProblem.id) {
          return { ...question, title: newText };
        }

        return question;
      })
    );
  };

  useEffect(() => {
    if (questionProblem) {
      setEditingData(questionProblem);
    }
  }, [questionProblem]);

  return (
    <>
      <div
        className="w-full text-left"
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(questionProblem.title),
        }}
        onMouseUp={handleMouseUp}
      ></div>
      <div
        className={`flex justify-center items-center gap-[5%] p-[20px] bg-white rounded-b-[10px] ${
          isVerticalArrange ? "" : "flex-col !gap-[28px]"
        }`}
      >
        <div
          className={`h-full p-[15px] ${
            isVerticalArrange ? "w-[270px]" : "w-[90%]"
          }`}
        >
          <ul className="px-0 py-[10px] list-none listbox-dot h-full flex flex-wrap justify-center gap-y-[20px]">
            {isUsableArr(orderIssues) &&
              orderIssues &&
              orderIssues.map((issue, index) => (
                <div
                  className={`flex items-center gap-[10px] px-[10px] ${
                    isVerticalArrange ? "w-full" : "w-[190px]"
                  }
              `}
                  key={index}
                >
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => checkingIssueHandle(issue.id)}
                  >
                    <input
                      type="checkbox"
                      name={`issue_data_checked_${index}`}
                      id=""
                      className="pointer-events-none rounded-full bg-secondary-light w-[20px] h-[20px]"
                      disabled={isDisabled}
                    />
                  </div>
                  <li
                    className={classNames(
                      "w-full px-[10px] py-[6px] border border-secondary-light rounded-[5px]",
                      {
                        "bg-[#C5C5C5] rounded-[7px]":
                          checkingIssue.id === issue.id ||
                          checkingIssueChoosed(issue.id),
                        "pointer-events-none": isDisabled,
                      }
                    )}
                    dangerouslySetInnerHTML={{
                      __html: issue.label || "<div></div>",
                    }}
                  ></li>
                </div>
              ))}
          </ul>
        </div>
        <div className="text-[#EBE4D8]">
          {isVerticalArrange ? (
            <PiArrowFatRight size={50} />
          ) : (
            <PiArrowFatDown size={50} />
          )}
        </div>
        <div
          className={`h-full px-[15px] pt-[40px] pb-[40px] flex flex-wrap justify-center gap-[10px] items-center ${
            isVerticalArrange
              ? "w-[270px] flex-col justify-center"
              : "w-[90%] justify-start"
          }`}
        >
          {isUsableArr(answerOrderIssues) &&
            answerOrderIssues.map((issue, index) => (
              <React.Fragment key={index}>
                {issue.id ? (
                  <div
                    className={`flex justify-start gap-[10px] items-center ${
                      editingData?.is_vertical_arrange ? "w-full" : "w-[190px]"
                    }`}
                  >
                    <div className="w-full border border-dashed border-secondary-light min-h-[50px]">
                      <div className="w-full flex items-center justify-start gap-[7px] p-[5px]">
                        <div className="w-[67%] ml-[10px] mr-[5px]">
                          <div
                            className="px-[10px] py-[6px] border border-secondary-light rounded-[5px]"
                            dangerouslySetInnerHTML={{
                              __html: decodeHTMLEntities(
                                getIssueById(issue.id)?.label || ""
                              ),
                            }}
                          ></div>
                        </div>
                        <div
                          className="text-[20px] text-secondary-light cursor-pointer"
                          onClick={() =>
                            removeCheckedIssueHandle(
                              `issue_data_checked_${
                                currentIssues.findIndex(
                                  (crIssue) => crIssue.id === issue.id
                                ) || 0
                              }`,
                              issue.id
                            )
                          }
                        >
                          <LuXCircle />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`flex justify-start gap-[10px] items-center ${
                      editingData?.is_vertical_arrange ? "w-full" : "w-[190px]"
                    }`}
                    onClick={() =>
                      addCorrectOrderHandle(checkingIssue.id, index)
                    }
                  >
                    <div
                      className={`w-full border border-dashed ${
                        checkingIssue.id
                          ? "border-primary bg-primary-extralight cursor-pointer"
                          : "border-secondary-light pointer-events-none"
                      } min-h-[50px]`}
                    ></div>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    </>
  );
};

export default OrderQuestion;
