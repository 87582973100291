import React, { useState, useContext, useEffect } from "react";
import { ChildrenWithProps } from "../../types/globals";
import { CandidateExamHistoryResult } from "../../types/admin/candidateExamHistory";
import { useStorage } from "../../hooks/useStorage";
import { ExaminationVenueInfoType } from "../../types/admin/examVenue";

interface ExamResultListTypes {
  candidateExamHistories: CandidateExamHistoryResult[];
  setCandidateExamHistories: React.Dispatch<
    React.SetStateAction<CandidateExamHistoryResult[]>
  >;
  totalPages: number;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
  waitingAPI: boolean;
  setWaitingAPI: React.Dispatch<React.SetStateAction<boolean>>;
  examinationVenueInfo: ExaminationVenueInfoType;
  setExaminationVenueInfo: React.Dispatch<React.SetStateAction<ExaminationVenueInfoType>>;
}

const ExamResultListContext = React.createContext<ExamResultListTypes>({
  candidateExamHistories: [],
  setCandidateExamHistories: () => {},
  totalPages: 1,
  setTotalPages: () => {},
  waitingAPI: true,
  setWaitingAPI: () => {},
  examinationVenueInfo: {},
  setExaminationVenueInfo: () => {},
});

const ExamResultListProvider = ({ children }: ChildrenWithProps) => {
  const [candidateExamHistories, setCandidateExamHistories] = useState<CandidateExamHistoryResult[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [waitingAPI, setWaitingAPI] = useState<boolean>(true);
  const [examinationVenueInfo, setExaminationVenueInfo] = useState<ExaminationVenueInfoType>({});
  const storageExaminationVenueKey = "examination_venue_info";
  const {setLocalStorage} = useStorage()
 
  useEffect(() => {
    if(!examinationVenueInfo) return;

    setLocalStorage(storageExaminationVenueKey, examinationVenueInfo);
  }, [examinationVenueInfo])

  return (
    <ExamResultListContext.Provider
      value={{
        candidateExamHistories,
        setCandidateExamHistories,
        totalPages,
        setTotalPages,
        waitingAPI,
        setWaitingAPI,
        examinationVenueInfo,
        setExaminationVenueInfo
      }}
    >
      {children}
    </ExamResultListContext.Provider>
  );
};

export default ExamResultListProvider;

export const useExamResultListContext = () => useContext(ExamResultListContext);
