import React from 'react';

type SubmitButtonType = {
  label?: string;
  onSubmit?: React.FormEventHandler<HTMLButtonElement>;
  className?: string;
  isSubmit?: boolean;
};

const SubmitButton = ({ label, onSubmit, className, isSubmit }: SubmitButtonType) => {
  return (
    <button
      className={`bg-primary text-white px-[50px] py-[10px] text-[16px] font-[700] leading-[100%] rounded-[5px] ${className}`}
      onClick={onSubmit}
      disabled={isSubmit}
    >
      {label || '保存'}
    </button>
  );
};

export default SubmitButton;
