import React, { DragEvent, useState } from "react";
import { TypeAsideQuestion } from "../../../../types/admin/question";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useAuthoringQuestionContext } from "../../../../context/Question/AuthoringQuestionContext";
import { initFakeProblem } from "../../../../services/utils/admin/question";
import { QUESTION_NAME_HAS_SUBMIT_ANSWERS } from "../../../../services/constants/admin/pages/question";
import classNames from "classnames";
import { isUsableArr } from "../../../../services/helpers/etc";

type SideQuestionTypeItemProps = {
  type: TypeAsideQuestion;
};

const SideQuestionTypeItem = ({ type }: SideQuestionTypeItemProps) => {
  const [isExpand, setIsExpand] = useState(true);
  const {
    setIsDragging,
    setDraggedName,
    authoringData,
    setAuthoringData,
    setDraggingDroppedProblem,
  } = useAuthoringQuestionContext();

  const handleExpand = () => {
    setIsExpand(!isExpand);
  };

  const isDisableDragHandle = (problemName: string): boolean => {
    const arrProblemNames = QUESTION_NAME_HAS_SUBMIT_ANSWERS;

    let isProblemExist = false;
    if (!authoringData.problems || !isUsableArr(authoringData.problems)) {
      return isProblemExist;
    }

    authoringData.problems.forEach((problem) => {
      if (
        arrProblemNames.includes(problem?.name || "") &&
        arrProblemNames.includes(problemName)
      ) {
        isProblemExist = true;
      }
    });

    return isProblemExist;
  };

  const dragStartHandle = (e: DragEvent<HTMLElement>, name: string) => {
    setIsDragging(true);
    setDraggedName(name);

    const newDraggedProblem = initFakeProblem(name);
    setDraggingDroppedProblem(newDraggedProblem);
    setAuthoringData({
      ...authoringData,
      problems: [newDraggedProblem, ...(authoringData.problems || [])],
    });
  };

  const dragEndHandle = () => {
    setIsDragging(false);
    setAuthoringData({
      ...authoringData,
      problems: authoringData.problems?.filter((problem) => !problem.is_fake),
    });
  };

  return (
    <div>
      <div
        className="cursor-pointer hover:bg-secondary-lighter text-secondary-dark bg-[#EEE] rounded-[10px] w-full max-w-[247px] h-0 min-h-[45px] flex justify-between p-[10px]"
        onClick={handleExpand}
      >
        <div className="text-[14px] font-[500] leading-[20px] tracking-[0.1px]">
          {type.label}
        </div>
        {isExpand ? <IoIosArrowUp size={22} /> : <IoIosArrowDown size={22} />}
      </div>

      {isExpand ? (
        <div className="flex flex-wrap gap-x-[23px] gap-y-[13px] py-[13px]">
          {type.subTypes.map((subType, index) => (
            <React.Fragment key={index}>
              <div
                className={classNames(
                  "cursor-pointer hover:bg-primary-extralight rounded-[5px] border flex items-center justify-center w-[67px] h-[67px] text-[#6A6D6F] border-[#F6F7F6] bg-[#FAFAFB]",
                  {
                    "bg-secondary-lighter hover:!bg-secondary-lighter !cursor-default":
                      isDisableDragHandle(subType.name),
                  }
                )}
                draggable={!isDisableDragHandle(subType.name)}
                onDragStart={(e) => dragStartHandle(e, subType.name)}
                onDragEnd={() => dragEndHandle()}
              >
                <div className="text-[#6A6D6F]">
                  <div className="flex justify-center">
                    <div className="">{subType.icon}</div>
                  </div>
                  <div className="text-center text-[12px] font-[500] leading-[16px] tracking-[0.5px]">
                    {subType.label}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div className="my-[10px]"></div>
      )}
    </div>
  );
};

export default SideQuestionTypeItem;
