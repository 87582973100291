import { TbFolderOpen } from "react-icons/tb";
import { PiFolderBold } from "react-icons/pi";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { isUsableArr } from "../../../../services/helpers/etc";
import HalfPlus from "../../../commons/icons/HalfPlus";
import { FiFileText } from "react-icons/fi";
import { useListExamContext } from "../../../../context/Exam/ListExamContext";
import { Exam } from "../../../../types/admin/exam";
import { Link } from "react-router-dom";
import { PATH_ADMIN_EXAM } from "../../../../services/constants/route/router";
type ListItemProps = {
  exam: Exam;
};

const ExamItem = ({ exam }: ListItemProps) => {
  const [isExpand, setIsExpand] = useState(false);
  const { exams, selectedExam, setSelectedExam } = useListExamContext();
  const childQuestions = exams.filter(
    (childQuestion) => Number(childQuestion.parent_id) === Number(exam?.id)
  );

  const handleClick = () => {
    setIsExpand(!isExpand);
    setSelectedExam(exam.id);
  };
  
  return (
    <div className="">
      <div
        className={`hover:bg-primary-extralight px-[5px] text-secondary-light text-[22px] flex gap-[5px] cursor-pointer items-center h-[30px] ${
          selectedExam.id === exam.id
            ? "bg-primary-extralight rounded-[2px]"
            : ""
        }`}
        onClick={handleClick}
      >
        {exam.is_file ? (
          <HalfPlus className="mx-[5px] shrink-0" />
        ) : (
          <div className="text-[18px]">
            {isExpand ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </div>
        )}

        {exam.is_file ? (
          <FiFileText className="ml-[0.4px] mr-[1.5px] text-[21px] text-primary shrink-0" />
        ) : (
          <div className="text-[22px]">
            {isExpand ? <TbFolderOpen /> : <PiFolderBold />}
          </div>
        )}
        <div className="text-[12px] text-secondary-dark font-[500] leading-[100%] whitespace-nowrap pr-[15px]">
          <Link to={`${PATH_ADMIN_EXAM.DEFAULT}?id=${exam.id}`}>
            {exam.name}
          </Link>
        </div>
      </div>
      <div className="pl-[20px]">
        {isExpand &&
          isUsableArr(childQuestions) &&
          childQuestions.map((exam, index) => (
            <ExamItem exam={exam} key={exam.id || index} />
          ))}
      </div>
    </div>
  );
};

export default ExamItem;
