import React, { useState, useRef, useEffect } from 'react';
import { SliderProblem } from '../../../../../../../types/admin/question';
import { generateSliderIssues } from '../../../../../../../services/utils/admin/question';
import { getLocationOfThumb } from '../../../../../../../services/utils/admin/sliderQuestion';

type SliderType = {
  answerValue?: number;
  progressLocation?: string;
  thumbLocation?: string;
  min?: number;
  max?: number;
  step?: number;
};

type InputRangeProps = {
  setEditingData?: React.Dispatch<React.SetStateAction<SliderProblem>>;
  editingData?: SliderProblem;
  className?: string;
  answerValue?: number;
  isDisabled?: boolean;
};

const SliderQuestionInputRange = ({
  setEditingData,
  editingData,
  className,
  answerValue,
  isDisabled,
}: InputRangeProps) => {
  const crMin = editingData?.min || 0;
  const crMax = editingData?.max || 100;
  const crStep = editingData?.step || 1;
  const [sliderValue, setSliderValue] = useState<SliderType>({});
  const rangeRef = useRef<HTMLInputElement>(null);

  const handleWindowResize = () => {
    const pixelWidth = rangeRef.current?.offsetWidth;
    const { postionThumb, progressPosition } = getLocationOfThumb(
      Number(sliderValue?.answerValue),
      crMin,
      crMax,
      pixelWidth!,
    );

    setSliderValue({
      ...sliderValue,
      progressLocation: progressPosition,
      thumbLocation: postionThumb,
    });
  };
  const setDataOutPut = (name: string, value: number, min: number, max: number) => {
    const newIssues = generateSliderIssues(name, value, editingData?.issues || [], min, max);
    setEditingData?.({ ...editingData, issues: newIssues });
  };

  useEffect(() => {}, []);

  //That useEffect to change value thumb when zoom website
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  useEffect(() => {
    setDataOutPut('answerValue', Number(sliderValue?.answerValue), crMin, crMax);
  }, [sliderValue]);

  useEffect(() => {
    const pixelWidth = rangeRef.current?.offsetWidth;
    const { postionThumb, progressPosition } = getLocationOfThumb(
      Number(answerValue),
      crMin,
      crMax,
      pixelWidth!,
    );

    setSliderValue({
      ...sliderValue,
      progressLocation: progressPosition || '0%',
      thumbLocation: postionThumb || '-5px',
      answerValue,
      min: crMin,
      max: crMax,
      step: crStep,
    });
  }, [crMin, crMax, crStep]);

  const onChangeValueSlider = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (isDisabled) return;

    let value = Number(e.currentTarget?.value);
    const pixelWidth = rangeRef.current?.offsetWidth;
    const { postionThumb, progressPosition } = getLocationOfThumb(
      value,
      crMin,
      crMax,
      pixelWidth || 0,
    );

    setSliderValue({
      ...sliderValue,
      progressLocation: progressPosition,
      thumbLocation: postionThumb,
      [e.currentTarget.name]: value,
    });
  };

  const trackStyle = {
    background: `linear-gradient(to right, #235FA9 ${
      sliderValue.progressLocation || '0%'
    }, #C1D9F7 ${sliderValue.progressLocation || '0%'})`,
  };

  return (
    <div className={`flex-col items-center justify-center my-[10px] ${className || ''}`}>
      <input
        type="range"
        className={`accent-primary-darker w-full h-[8px] appearance-none rounded-full border-0 cursor-pointer
          [&::-webkit-slider-thumb]:cursor-grab
          [&::-webkit-slider-thumb]:h-[24px]
          bg-gradient-to-r from-primary-darker from-${sliderValue.progressLocation} to-primary-light to-${sliderValue.progressLocation}`}
        style={trackStyle}
        ref={rangeRef}
        min={crMin}
        max={crMax}
        step={crStep}
        value={sliderValue.answerValue || 0}
        name="answerValue"
        onChange={onChangeValueSlider}
      />
      <div
        className={`relative flex justify-center items-center w-full text-[#7A7A7A] text-[12px]`}
      >
        <div
          style={{ left: `${sliderValue.thumbLocation}` }}
          className={` absolute top-[1px] bg-[white] h-[20px] w-[30px] text-center`}
        >
          {sliderValue.answerValue || ''}
        </div>
      </div>

      <div className={`w-full flex items-center justify-center gap-[15px]`}>
        <div className={`w-[100%] flex items-center justify-between text-[#7A7A7A] text-[12px]`}>
          <p>{crMin}</p>
          <p>{crMax}</p>
        </div>
      </div>
    </div>
  );
};

export default SliderQuestionInputRange;
