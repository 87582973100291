import React from "react";
import {
  FillInBlankIssue,
  FillInBlankProblemTest,
} from "../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import { isUsableArr } from "../../../../../services/helpers/etc";
import { renderToString } from "react-dom/server";
import { handleShowIconCheckAnswer } from "../RenderQuestionType";
import { CHECK_ANSWER_STATUS_TYPE } from "../../../../../services/constants/globals";

type FillInBlankQuestionProps = {
  question: FillInBlankProblemTest;
  numberQuestion: number;
};

const AnswerCandidate = ({ answer }: { answer: string }) => {
  return (
    <div className="w-full flex items-center justify-between gap-x-[30px]">
      <div
        className="flex-1 w-full max-w-[400px] px-[10px] py-[4px] border border-secondary-light"
        dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(answer) }}
      ></div>
      <div className="w-[80px] flex items-center"></div>
    </div>
  );
};

const AnswerAdmin = ({ answer }: { answer: string }) => {
  return (
    <div className="w-full flex items-center justify-between gap-x-[30px]">
      <div
        className="flex-1 w-full max-w-[400px] px-[10px] py-[4px] border border-secondary-light"
        dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(answer) }}
      ></div>
    </div>
  );
};

const FillInBlankQuestionResult = ({
  question,
  numberQuestion,
}: FillInBlankQuestionProps) => {
  const [selectingIssue, _setSelectingIssue] = React.useState<FillInBlankIssue>(
    {}
  );
  const answerFills = question.answer_data || [];
  const currentFills = question.correct_fills || [];
  const currentIssues = question.issues || [];

  const handleCheckCorrectAnswer = (indexAnswer: number): number => {
    const currentFill = currentFills[indexAnswer];
    const answerFill = answerFills[indexAnswer];
    if (!currentFill || !answerFill) return CHECK_ANSWER_STATUS_TYPE.INCORRECT;

    if (currentFill.issueId === answerFill.issueId) {
      return CHECK_ANSWER_STATUS_TYPE.CORRECT;
    }

    return CHECK_ANSWER_STATUS_TYPE.INCORRECT;
  };

  const getAnswerLabel = (issueId: number) => {
    const findIssue = currentIssues.find((issue) => issue?.id === issueId);
    if (!findIssue) return "";

    return findIssue?.label || "";
  };

  React.useEffect(() => {
    const targets = document.querySelectorAll(
      `#fill_in_blank_${numberQuestion} .fill_in_blank_target`
    );

    if (!selectingIssue?.id) {
      targets.forEach((target) => {
        target.classList.remove("fill_in_blank_selecting_target");
      });

      return;
    }

    targets.forEach((target) => {
      target.classList.add("fill_in_blank_selecting_target");
    });
  }, [selectingIssue?.id]);

  return (
    <div
      id={`fill_in_blank_${numberQuestion}_${question?.id}`}
      className="w-full flex items-stretch mb-[30px]"
    >
      <div className="w-[40px]">
        <div className="w-full h-full flex items-center justify-center bg-warning-lighter">
          <span>{numberQuestion}</span>
        </div>
      </div>
      <div id="answer" className="w-[calc(100%-40px)]">
        {answerFills.map((answerFill, index) => (
            <div className="w-full flex justify-between items-center border-b border-b-danger-light py-[20px] gap-x-[4px]">
                <div className="pl-[5px] flex-1 flex items-center gap-x-[10px] justify-start">
                    <p className="w-[20px]">{index + 1} : </p>
                    <AnswerCandidate answer={getAnswerLabel(currentFills[index].issueId || 0) || ""} />
                </div>
                <div className="pl-[5px] flex-1 flex items-center gap-x-[10px] justify-start">
                    <p className="w-[20px]">{index + 1} : </p>
                    <AnswerAdmin
                        answer={getAnswerLabel(answerFill.issueId || 0) || ""}
                    />
                </div>
                <div className="w-[80px] flex justify-center">{handleShowIconCheckAnswer(handleCheckCorrectAnswer(index))}</div>
                <div className="w-[40px] text-center text-[14px]">
                  {handleCheckCorrectAnswer(index) === CHECK_ANSWER_STATUS_TYPE.CORRECT ? answerFill.point : 0}
                </div>
            </div>
        ))}
      </div>
    </div>
  );
};

export default FillInBlankQuestionResult;
