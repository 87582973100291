import { LiaSave } from "react-icons/lia";
import DetailTargetFinderItem from "./DetailTargetFinderItem";
import { API } from "../../../../services/constants/route/api";
import { request } from "../../../../services/axios/axios";
import { swalError } from "../../../../services/helpers/swal";
import { Exam } from "../../../../types/admin/exam";
import { useListExamContext } from "../../../../context/Exam/ListExamContext";
import { NAME_ACTION_ASIDE_TEST } from "../../../../services/constants/admin/pages/exam";

type DetailTargetFinderProps = {
  sideAction: string;
};

const DetailTargetFinder = ({ sideAction }: DetailTargetFinderProps) => {
  const {
    exams,
    selectedExam,
    selectedTargetExam,
    setSideAction,
    setExams,
    setSelectedExam,
  } = useListExamContext();
  const primaryExams = exams.filter((exam) => !exam.parent_id && !exam.is_file && selectedExam.id !== exam.id);

  const submitData = {
    id: selectedExam.id,
    target_id: selectedTargetExam.id,
  };

  let apiHandler = "";
  let finderTitle = "";
  let submitFinderTitle = "";
  let requestHandler = request.post;

  switch (sideAction) {
    case NAME_ACTION_ASIDE_TEST.COPY:
      apiHandler = API.ADMIN_EXAM.COPY;
      finderTitle = "他のフォルダーへコピー";
      submitFinderTitle = "コピーする";
      break;
    case NAME_ACTION_ASIDE_TEST.MOVE:
      apiHandler = API.ADMIN_EXAM.MOVE;
      finderTitle = "他のフォルダーへ移動";
      submitFinderTitle = "移動する";
      requestHandler = request.patch;
      break;

    default:
      swalError();
      break;
  }

  const handleSubmitFinder = async () => {
    await requestHandler(apiHandler, submitData, (res: Exam[]) => {
      setExams([...res, ...exams]);
      setSelectedExam();
      setSideAction("");
    });
  };

  return (
    <div className="">
      <div className="text-[22px] leading-[28px] text-secondary-dark mb-[20px]">
        {finderTitle}
      </div>
      <div className="w-full max-w-[311px] bg-success-lighter h-0 min-h-[500px] pt-[20px] px-[20px]">
        <div className="text-[22px] leading-[28px] text-secondary-dark mb-[20px]">
          フォルダーを選択
        </div>
        <div className="w-[271px] h-[350px] overflow-auto bg-white">
          {primaryExams.map((exam, index) => (
            <DetailTargetFinderItem exam={exam} key={index} />
          ))}
        </div>
        <div className="w-full flex justify-center mt-[20px]">
          <button
            className="text-white cursor-pointer flex items-center justify-center w-full max-w-[150px] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
            onClick={handleSubmitFinder}
          >
            <LiaSave size={23} /> {submitFinderTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailTargetFinder;
