import * as React from 'react';

const TestTakerRecord = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      style={{
        fill: 'currentcolor',
      }}
      d="M12 16.5a4.5 4.5 0 004.5-4.5V6a4.5 4.5 0 10-9 0v6a4.5 4.5 0 004.5 4.5z"
    ></path>
    <path
      style={{
        fill: 'currentcolor',
      }}
      d="M18.787 12.01a.75.75 0 00-.825.656 6 6 0 01-11.925 0 .75.75 0 00-.825-.656.741.741 0 00-.666.825 7.472 7.472 0 006.703 6.6v2.316a.75.75 0 001.5 0v-2.316a7.472 7.472 0 006.704-6.6.741.741 0 00-.666-.825z"
    ></path>
  </svg>
);
export default TestTakerRecord;
