import React, { useState, useEffect } from "react";
import TableGroupItem from "./TableGroupItem";
import { useGroupListContext } from "../../../../context/Group/List";
import {
  swalClose,
  swalError,
  swalLoading,
  swalSuccess,
} from "../../../../services/helpers/swal";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import BaseModal from "../../commons/BaseModal";
import TableHeadItem from "../../commons/TableHeadItem";
import LayoutWaitingApi from "../../../commons/layouts/LayoutWaitingAPI";

const GroupTable = ({ ...props }: React.HTMLProps<HTMLElement>) => {
  const { mainGroups, groups, setGroups, selectedGroup } =
    useGroupListContext();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [errors, setErrors] = useState<string>("");
  const [waitingApi, setWaitingApi] = useState<boolean>(true);

  useEffect(() => {
    if (mainGroups && mainGroups.length > 0) return;

    const fetch = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_GROUP.GET_GROUP_LIST}`,
      });

      if (!result.status) return swalError();

      setWaitingApi(false)
      setGroups([...groups, ...result.data]);
      swalClose();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const result = await makeRequest({
      method: "post",
      url: `${API.ADMIN_GROUP.CREATE_SUBGROUP}`,
      data: { name, parent_group_id: selectedGroup.id },
    });

    if (!result.status) {
      if (result.message) {
        setErrors(result.message);
      }

      return swalError();
    }
    const currentParentGroup = groups.find(
      (group) => group.id === selectedGroup.id
    );
    if (!currentParentGroup) return;

    currentParentGroup.sub_group =
      currentParentGroup.sub_group === undefined
        ? [result.data]
        : [...currentParentGroup.sub_group, result.data];

    const newGroups = groups.filter((group) => group.id !== selectedGroup.id);
    setErrors("");
    setGroups([...newGroups, currentParentGroup]);
    swalSuccess();
    setIsOpenModal(false);
  };

  return (
    <LayoutWaitingApi waitingApi={waitingApi} className="w-full h-[200px] mt-[20px]">
      <section {...props}>
        <div className="flex bg-secondary-extralight items-center justify-between w-full">
          <TableHeadItem className="w-[40%] !border-l-0 !justify-start !pl-[10px]">
            受験者グループ名
          </TableHeadItem>
          <TableHeadItem className="w-[20%]">受験者管理</TableHeadItem>
          <TableHeadItem className="w-[10%]">並び順</TableHeadItem>
          <TableHeadItem className="w-[20%]">ステータス</TableHeadItem>
          <TableHeadItem className="w-[10%]">設定</TableHeadItem>
          <TableHeadItem className="w-[20%] !border-r-0 text-secondary-extralight">
            1
          </TableHeadItem>
        </div>
        <div className="mt-[3px]">
          {mainGroups &&
            mainGroups.map((item, index) => (
              <TableGroupItem
                isDownDisabled={index === mainGroups.length - 1}
                isUpDisabled={index === 0}
                data={item}
                key={item.id}
                setIsOpenModal={setIsOpenModal}
              />
            ))}
        </div>

        {isOpenModal && (
          <BaseModal
            setIsOpen={setIsOpenModal}
            justifyDirection="end"
            onClick={handleSubmit}
          >
            <div className="text-center pt-[222px] pb-[167px]">
              <div className="text-[16px] font-[700] leading-[100%] tracking-[0.02em] text-primary mb-[24px]">
                グループを新規登録する
              </div>
              <div className="mb-[12px]">
                「{selectedGroup.name}」グループ内に下層グループを作成します
              </div>

              <div className="">
                <input
                  type="text"
                  name="name"
                  id=""
                  onChange={(e) => setName(e.target.value)}
                  className="max-w-[550px] h-[40px] border !border-secondary-light mb-[18px] pl-[13px] text-[12px] leading-[100%]"
                  placeholder="グループ名を入力してください。"
                />
                {errors && (
                  <div className="text-[12px] font-[500] leading-[190%] text-danger">
                    {errors}
                  </div>
                )}
              </div>
            </div>
          </BaseModal>
        )}
      </section>
    </LayoutWaitingApi>
  );
};

export default GroupTable;
