import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { TbTrash } from "react-icons/tb";
import { MainGroup } from "../../../../../types/admin/examination";
import { useListTestContext } from "../../../../../context/ListTestContext";

const SelectGroup = ({ mainGroupData }: { mainGroupData: MainGroup[] }) => {
  const { groupGroups, setGroupGroups } = useListTestContext();

  const handleMainGroupChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const newGroupGroups = [...groupGroups];
    newGroupGroups[index].mainGroupId = parseInt(event.target.value);
    newGroupGroups[index].subGroupId = 0;
    setGroupGroups(newGroupGroups);
  };
  const handleSubGroupChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const newGroupGroups = [...groupGroups];
    newGroupGroups[index].subGroupId = parseInt(event.target.value);
    setGroupGroups(newGroupGroups);
  };

  const handleAddGroupGroup = () => {
    setGroupGroups([...groupGroups, { mainGroupId: 0, subGroupId: 0 }]);
  };

  const handleDeleteGroupGroup = (index: number) => {
    const newGroupGroups = [...groupGroups];
    newGroupGroups.splice(index, 1);
    setGroupGroups(newGroupGroups);
  };
  return (
    <div className="flex flex-col gap-y-[5px] my-[5px]">
      <div className="flex flex-col w-full">
        {groupGroups.map((groupGroup, index) => (
          <div
            key={index}
            className="flex items-center gap-x-[10px] w-full h-[30px] mb-[5px]"
          >
            <div className="w-[210px] text-secondary-dark flex items-center relative">
              <select
                className="w-full h-[30px] pr-[33px] pl-[15.5px] appearance-none text-secondary-dark leading-[20px] !py-0 rounded-[5px] text-[12px] cursor-pointer"
                name="group_ids"
                onChange={(event) => handleMainGroupChange(event, index)}
                value={groupGroup.mainGroupId}
              >
                {groupGroup.mainGroupId ? <></> : <option value=""></option>}
                {mainGroupData.map((mainGroup) => (
                  <option key={mainGroup.id} value={mainGroup.id}>
                    {mainGroup.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-[210px] text-secondary-dark flex items-center relative">
              <select
                className="w-full h-[30px] pr-[33px] pl-[15.5px] appearance-none text-secondary-dark leading-[20px] !py-0 rounded-[5px] text-[12px] cursor-pointer"
                name="group_ids"
                onChange={(event) => handleSubGroupChange(event, index)}
                value={groupGroup.subGroupId}
              >
                <option value="0"></option>
                {mainGroupData
                  .find((ms) => ms.id === groupGroup.mainGroupId)
                  ?.sub_groups.map((subGroup) => (
                    <option key={subGroup.id} value={subGroup.id}>
                      {subGroup.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="text-secondary-dark flex items-center w-[25px]">
              {index > 0 ? (
                <TbTrash
                  className="mb-[4px] text-[#7A7A7A] cursor-pointer"
                  onClick={() => handleDeleteGroupGroup(index)}
                  size={50}
                />
              ) : (
                <TbTrash
                  className="mb-[4px] text-secondary-light invisible"
                  size={50}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div>
        <button
          className="bg-primary-light flex items-center gap-x-[10px] text-white px-[10px] py-[6px] rounded-[5px]"
          onClick={handleAddGroupGroup}
        >
          <AiOutlinePlus color="#FFFFFF" size={18} />
          <span className="text-[12px]">所属グループを追加</span>
        </button>
      </div>
    </div>
  );
};

export default SelectGroup;
