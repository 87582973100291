import React, { ChangeEvent, useRef, useState } from "react";
import Button from "../components/Login/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import { makeRequest, request } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { swalMessage } from "../services/helpers/swal";
import AuthLayout from "../layouts/AuthLayout";
import { useObjectRoutes } from "../hooks/useObjectRoutes";
import { ErrorData } from "../types/globals";
import ErrorBox from "../components/commons/form/ErrorBox";
import { User } from "../types/user";
import {
  PREFIX_PATH_S3,
  SERVICE_ID,
} from "../services/constants/globals";
import { FOLDER_UPLOAD } from "../services/constants/folderUpload";
import { uploadFileToS3 } from "../services/utils/upload/s3Upload";
import { useAuthContext } from "../context/AuthContext";
import LogoDefault from "../assests/images/default_avatar.webp";
import ImageInternalButton from "../components/commons/buttons/ImageInternalButton";
import { AiOutlineUpload } from "react-icons/ai";

const VerifyEmail = () => {
  let navigate = useNavigate();
  const subdomain = window.location.hostname.split(".")[0];
  const [errors, setErrors] = useState<ErrorData>({});
  // check token for remember or has been login before (session)
  const { getParamValue } = useObjectRoutes();
  let accessToken = getParamValue("token");
  const [token, setToken] = useState(accessToken);
  const [verificationCode, setVerificationCode] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isSubmittingCode, setIsSubmittingCode] = useState<boolean>(false);
  const [isVerify, setIsVerify] = useState<boolean>(false);
  const [userData, setUserData] = useState<User>({});
  const [previewImage, setPreviewImage] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [avatarFile, setAvatarFile] = useState<File>();
  const { user } = useAuthContext();
  const oz_id = user.organization_id;

  const handleSendCodeClick = () => {
    setIsSendingCode(true);
    // send verification code to the user's email
  };

  const handleVerificationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const code = event.target.value;
    setVerificationCode(code);
    if (code.length > 0) {
      setIsSendingCode(false);
      setIsSubmittingCode(true);
    } else {
      setIsSendingCode(true);
      setIsSubmittingCode(false);
    }
  };

  const handleSubmitCodeClick = async () => {
    setIsSubmittingCode(true);

    if (getParamValue("type") && getParamValue("type") === "change_email") {
      const result = await makeRequest({
        method: "post",
        url: API.AUTH.VERIFY_CHANGE_EMAIL,
        data: { code: verificationCode, token: token },
      });

      if (!result.status) {
        setErrors(result.error as ErrorData);
        swalMessage("", result.message, "error");
        return;
      }

      swalMessage("成功", "確認が完了しました。", "success", {
        timer: 1500,
        showConfirmButton: false,
      });
      setTimeout(() => navigate(ROUTE.LOGIN), 1500);
      return;
    }

    const result = await makeRequest({
      method: "post",
      url: API.AUTH.VERIFY_EMAIL_REGISTER,
      data: { code: verificationCode, token: token },
    });
    if (!result.status) {
      setErrors(result.error as ErrorData);
      swalMessage("", result.message, "error");
      return;
    }

    setUserData({ ...userData, email: result.data.email });
    setIsVerify(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const files = e.target.files;

    if (!files[0]) return;
    let file = files[0];
    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024; // Size in MB
    const mimes = ["png", "jpg", "jpeg"];

    // Check file type
    if (!fileType.startsWith("image/")) {
      setErrors({
        image: ["無効なファイルタイプです。画像を選択してください。"],
      });
      return;
    }

    if (!mimes.includes(fileType.split("image/")[1])) {
      setErrors({
        image: [
          "会社ロゴにはjpeg, png, jpgタイプのファイルを指定してください。",
        ],
      });
      return;
    }

    // Check file size
    if (fileSize > 2) {
      setErrors({ image: ["ファイルサイズが2MBの最大制限を超えています。"] });
      return;
    }

    setErrors({});

    setAvatarFile(files[0]);
    const previewUrl = URL.createObjectURL(files[0]);
    const pathFile =
      PREFIX_PATH_S3.FEATURE +
      SERVICE_ID +
      "/" +
      FOLDER_UPLOAD.AVATAR +
      "/" +
      PREFIX_PATH_S3.OZ +
      oz_id +
      files[0].name;

    setUserData({
      ...userData,
      avatar: pathFile,
      avatar_url: `${process.env.REACT_APP_AWS_S3_URL}/${pathFile}`,
    });
    setPreviewImage(previewUrl);
    setFile(files[0]);
  };

  const handleFile = (file: File) => {
    if (!file) return;
    const pathFile =
      PREFIX_PATH_S3.FEATURE +
      SERVICE_ID +
      "/" +
      FOLDER_UPLOAD.AVATAR +
      "/" +
      PREFIX_PATH_S3.OZ +
      oz_id +
      file.name;
    uploadFileToS3(file, pathFile);
  };

  const handleBack = () => {
    setIsVerify(false);
  };

  const handleSubmit = async () => {
    if (file) handleFile(file);

    await request.patch(
      API.CANDIDATE.UPDATE_PROFILE,
      { ...userData, subdomain: subdomain, status: 1 },
      () => navigate(ROUTE.LOGIN),
      (errors) => setErrors(errors),
      { withSuccess: true }
    );
  };

  const handleRedirectResendEmail = () => {
    let url = `/${ROUTE.RESEND_VERIFY}`;
    if (getParamValue("type") && getParamValue("type") === "change_email") {
      const type = getParamValue("type");
      url += `?token=${accessToken}&type=${type}`;
    }

    navigate(url);
  };

  const removeImage = () => {
    setUserData({ ...userData, avatar: undefined, avatar_url: undefined });
    setAvatarFile(undefined);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <AuthLayout>
        <>
          <>
            {isVerify ? (
              <>
                <div className="mb-[45px] mt-[30px] mx-auto max-sm:mx-[10px] max-sm:mt-[25px] text-secondary-dark z-[1000]">
                  <div className="w-[550px] max-sm:w-full bg-[#F8F7F6] px-[25px] py-[15px] border border-warning-light">
                    {errors && Object.keys(errors).length > 0 && (
                      <ErrorBox errors={errors} />
                    )}
                    <p className="mt-[30px] mb-[20px] text-[20px] font-[500] max-sm:mt-[25px]">
                      プロフィール作成（無料）
                    </p>
                    <div className="flex flex-col items-start mb-[10px]">
                      <label htmlFor="" className="text-[14px] font-[700]">
                        メールアドレス
                      </label>
                      <p className="pl-[22px] py-[14px]">{userData.email}</p>
                    </div>
                    <div className="flex flex-col items-start gap-y-[9px] mb-[10px]">
                      <label htmlFor="" className="text-[14px] font-[700]">
                        パスワード<span className="text-danger">*</span>
                        （半角英数・記号のみ８〜20字）
                      </label>
                      <div className="w-full">
                        <input
                          type="password"
                          name="password"
                          className="w-full border border-danger-light rounded-[5px]"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-y-[9px] mb-[10px]">
                      <label htmlFor="" className="text-[14px] font-[700]">
                        パスワード（確認用）
                        <span className="text-danger">*</span>
                      </label>
                      <div className="w-full">
                        <input
                          type="password"
                          name="password_confirmation"
                          className="w-full border border-danger-light rounded-[5px]"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-y-[9px] mb-[10px]">
                      <label htmlFor="" className="text-[14px] font-[700]">
                        ログインID<span className="text-danger">*</span>
                        （半角英数のみ８〜20字）
                      </label>
                      <div className="w-full">
                        <input
                          type="text"
                          name="username"
                          className="w-full"
                          placeholder="loginname"
                          onChange={handleChange}
                          value={userData.username || ""}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-y-[9px] mb-[10px]">
                      <label htmlFor="" className="text-[14px] font-[700]">
                        氏名<span className="text-danger">*</span>
                      </label>
                      <div className="w-full flex items-center justify-between gap-x-[23px]">
                        <input
                          type="text"
                          name="first_name"
                          className="flex-1 placeholder:text-secondary-light"
                          placeholder="鈴木"
                          onChange={handleChange}
                          value={userData.first_name || ""}
                        />
                        <input
                          type="text"
                          name="last_name"
                          className="flex-1 placeholder:text-secondary-light"
                          placeholder="一郎"
                          onChange={handleChange}
                          value={userData.last_name || ""}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-y-[9px] mb-[20px]">
                    <p className="text-[14px] text-left mt-[8px] font-[700]">プロフィール画像</p>
                      <div className="flex justify-start bg-white items-center object-cover border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 w-full pr-[22px] pl-[10px] py-[5px]">
                        <div className="bg-[#F4F4F4] flex justify-center items-center max-w-[40px] max-h-[40px] overflow-hidden">
                          <input
                            type="file"
                            id="image"
                            name="avatar"
                            accept="image/*"
                            className="hidden"
                            onChange={handleFileInputChange}
                            placeholder="選択されていません"
                            ref={fileInputRef}
                          />
                          <img
                            src={
                              avatarFile
                                ? URL.createObjectURL(avatarFile)
                                : LogoDefault
                            }
                            className="object-cover w-full h-full"
                          />
                        </div>
                        {userData.avatar ? (
                          <>
                            <div className="relative ml-[60px] mr-[15px]">
                              <ImageInternalButton
                                removeImage={removeImage}
                                editImage={() => fileInputRef.current?.click()}
                                editButtonOtherStyle="!top-[-10px] !right-[25px]"
                                removeButtonOtherStyle="!top-[-10px] !right-[0px]"
                              />
                            </div>
                            <p className="max-w-[200px] truncate text-[12px]">
                              {avatarFile?.name}
                            </p>
                          </>
                        ) : (
                          <>
                            <button
                              className="bg-[#7E869E] text-white rounded-full mx-[15px]"
                              onClick={() => fileInputRef.current?.click()}
                            >
                              <AiOutlineUpload
                                size={20}
                                className="px-1 py-1"
                              />
                            </button>
                            <p className="text-[10px]">選択されていません</p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-center gap-x-[20px] text-white font-[700]">
                      <button
                        type="button"
                        className="w-[200px] h-[50px] text-center text-[20px] bg-[#ACA9A8] rounded-[10px]"
                        onClick={handleBack}
                      >
                        戻る
                      </button>
                      <button
                        type="button"
                        className="w-[200px] h-[50px] text-center text-[20px] bg-primary-synch rounded-[10px]"
                        onClick={handleSubmit}
                      >
                        完了する
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="mb-[45px] mt-[30px] mx-auto max-sm:mx-[10px] max-sm:mt-[25px] z-[1000]">
                <div className="w-[550px] max-sm:w-full mb-[30px] bg-white px-[25px] border border-warning-light">
                  <p className="mt-[30px] mb-[20px] text-[15px] max-sm:mt-[25px]">
                    メール確認
                  </p>
                  <p className="mt-[30px] mb-[4px] text-[12px] max-sm:mt-[25px]">
                    メールを確認してください。
                  </p>
                  <p className="text-[12px] px-[30px] leading-[22.8px] mb-[20px]">
                    メール受信箱を確認し、下記に確認コードを入力してあなたのメールアドレス
                    <br />
                    を確認してください。
                  </p>
                  <div className="mb-[40px] max-sm:mb-[22px]">
                    <input
                      type="text"
                      placeholder="6桁のコードを入力"
                      onChange={handleVerificationCodeChange}
                      value={verificationCode}
                      name="password"
                      className="min-h-[50px] border border-warning-light max-sm:border-danger-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full py-[14px] placeholder:text-[#9E9E9F] placeholder:text-sm"
                      required
                    />
                  </div>
                  <div className="flex flex-col items-center mb-[15px]">
                    {!isSubmittingCode ? (
                      <Button
                        content={"確認"}
                        otherstyle={
                          "bg-secondary-light text-white mb-[10px] max-sm:mb-[20px] max-sm:text-[18px]"
                        }
                        type={"button"}
                        disabled={!isSubmittingCode}
                      />
                    ) : (
                      <Button
                        content={"確認"}
                        otherstyle={
                          "bg-primary text-white mb-[10px] max-sm:text-[18px]"
                        }
                        type={"button"}
                        disabled={!isSubmittingCode}
                        onClick={handleSubmitCodeClick}
                      />
                    )}
                    <Button
                      content={"コードを再信"}
                      otherstyle={
                        "bg-primary text-white mb-[10px] max-sm:text-[18px]"
                      }
                      type={"button"}
                      onClick={handleRedirectResendEmail}
                    />
                    {errors && Object.keys(errors).length > 0 && (
                      <ErrorBox errors={errors} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        </>
      </AuthLayout>
    </>
  );
};

export default VerifyEmail;
