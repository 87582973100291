import { MODE_EXECUTE_TEST } from "../../candidate/unit";

const KATAKANA_ALPHABET = [
  "ア",
  "イ",
  "ウ",
  "エ",
  "オ",
  "カ",
  "キ",
  "ク",
  "ケ",
  "コ",
  "サ",
  "シ",
  "ス",
  "セ",
  "ソ",
  "タ",
  "チ",
  "ツ",
  "テ",
  "ト",
  "ナ",
  "ニ",
  "ヌ",
  "ネ",
  "ノ",
  "ハ",
  "ヒ",
  "フ",
  "ヘ",
  "ホ",
  "マ",
  "ミ",
  "ム",
  "メ",
  "モ",
  "ヤ",
  "ユ",
  "ヨ",
  "ラ",
  "リ",
  "ル",
  "レ",
  "ロ",
  "ワ",
  "ヲ",
  "ン",
];

const UNIT_TYPE = {
  LESSON: 1,
  TEST: 2,
} as const;

const UNIT_TEST_ORDER_TYPE = {
  ORDERED: 0,
  SHUFFLE: 1,
};

const UNIT_TEST_ORDER_DATA = [
  { label: "1問目から順に出題", value: UNIT_TEST_ORDER_TYPE.ORDERED },
  { label: "ランダムに出題", value: UNIT_TEST_ORDER_TYPE.SHUFFLE },
];

const UNIT_TEST_MODE_TYPE = [
  {
    id: MODE_EXECUTE_TEST.PRACTICE,
    key: "is_practice_mode",
    label: "練習モード",
  },
  { id: MODE_EXECUTE_TEST.MAIN, key: "is_main_mode", label: "本番モード" },
  { id: MODE_EXECUTE_TEST.REVISE, key: "is_revise_mode", label: "復習モード" },
] as const;

const CREATION_UNIT_TEST_TYPE = {
  DRAFT: 2,
  PUBLISH: 1,
};

const UNIT_FILE_TYPE = {
  TYPE_EDITOR: 1,
  TYPE_VIDEO: 2,
  TYPE_AUDIO: 3,
  TYPE_PDF: 4,
  TYPE_IMAGE: 5,
};

const QUESTION_TYPE = {
  PULL_DOWN_SELECTION: 1,
  CHECK_BOX: 2,
  RADIO_BUTTON: 3,
  IMAGE_SELECTION: 4,
  LISTENING: 5,
  FILL_IN_BLANK: 6,
};

const UNIT_TEST_QUESTION_TYPE_DATA = [
  { label: "単一選択式形式（ラジオボタン)", value: QUESTION_TYPE.RADIO_BUTTON },
  { label: "複数選択形式（チェックボックス)", value: QUESTION_TYPE.CHECK_BOX },
  {
    label: "リストから選択形式（プルダウンリスト)",
    value: QUESTION_TYPE.PULL_DOWN_SELECTION,
  },
  { label: "画像から選択", value: QUESTION_TYPE.IMAGE_SELECTION },
  { label: "リスニング問題（単一選択）", value: QUESTION_TYPE.LISTENING },
  { label: "穴埋め形式", value: QUESTION_TYPE.FILL_IN_BLANK },
];
const UNIT_LESSON_MAIN_TYPE_DATA = [
  { label: "編集画面で作成", value: UNIT_FILE_TYPE.TYPE_EDITOR },
  { label: "動画ファイル", value: UNIT_FILE_TYPE.TYPE_VIDEO },
  { label: "音声ファイル", value: UNIT_FILE_TYPE.TYPE_AUDIO },
  { label: "PDFファイル", value: UNIT_FILE_TYPE.TYPE_PDF },
  { label: "画像ファイル（JPG・PNG）", value: UNIT_FILE_TYPE.TYPE_IMAGE },
];

const UNIT_LESSON_SUB_TYPE_DATA = [
  { label: "編集画面で作成", value: UNIT_FILE_TYPE.TYPE_EDITOR },
  { label: "PDFファイル", value: UNIT_FILE_TYPE.TYPE_PDF },
  { label: "画像ファイル（JPG・PNG）", value: UNIT_FILE_TYPE.TYPE_IMAGE },
];

const UNIT_STATUS = {
  DRAFT: 2,
  PUBLIC: 1,
  PRIVATE: 0,
}

export {
  UNIT_TYPE,
  UNIT_TEST_ORDER_TYPE,
  UNIT_TEST_ORDER_DATA,
  UNIT_TEST_MODE_TYPE,
  CREATION_UNIT_TEST_TYPE,
  UNIT_FILE_TYPE,
  UNIT_LESSON_MAIN_TYPE_DATA,
  UNIT_LESSON_SUB_TYPE_DATA,
  QUESTION_TYPE,
  UNIT_TEST_QUESTION_TYPE_DATA,
  KATAKANA_ALPHABET,
  UNIT_STATUS
};
