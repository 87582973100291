import { ChangeEvent, useState } from 'react';
import Button from '../components/Login/Button';
import HelpCircleIcon from '../components/Login/HelpCircleIcon';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { PATH_ADMIN_HOME, PATH_ADMIN_GROUP, ROUTE, PATH_CANDIDATE_HOME, PATH_ADMIN_CANDIDATE } from '../services/constants/route/router';
import { makeRequest } from '../services/axios/axios';
import { API } from '../services/constants/route/api';
import { swalClose, swalError, swalMessage } from '../services/helpers/swal';
import AuthLayout from '../layouts/AuthLayout';
import { getSubdomainFromUrl } from '../services/helpers/domain';
import { useAuthContext } from '../context/AuthContext';
import { ErrorData } from '../types/globals';
import ErrorBox from '../components/commons/form/ErrorBox';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { socialLogin } from '../services/auth/socialLogin';
import { MESSAGE_ERROR } from '../services/constants/message';
import SNSButton from '../components/Login/SNSButton';
import FacebookLoginIcon from '../components/commons/icons/FacebookLoginIcon';
import GoogleIcon from '../components/commons/icons/GoogleIcon';

const Login = () => {
  const [dataLogin, setDataLogin] = useState({
    username: '',
    password: '',
    remember_me: 0,
  });
  let navigate = useNavigate();
  let { setUser } = useAuthContext();
  const [errors, setErrors] = useState<ErrorData>({});
  // check token for remember or has been login before (session)
  let accessToken = localStorage.getItem('accessToken')
    ? localStorage.getItem('accessToken')
    : null;
  const [token, setToken] = useState(accessToken);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let user = localStorage.getItem('user') ? localStorage.getItem('user') : null;
  let is_candidate = false;
  if (user) {
    is_candidate = JSON.parse(user)?.is_candidate;
  }

  const [isCandidate, setIsCandidate] = useState(is_candidate);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'remember_me') {
      setDataLogin({ ...dataLogin, [name]: Number(e.target.checked) });
      return;
    }

    setDataLogin({ ...dataLogin, [name]: value });
  };
  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    let result = await makeRequest({
      method: 'post',
      url: API.AUTH.LOGIN,
      data: {
        ...dataLogin,
        subdomain: getSubdomainFromUrl(),
      },
    });
    swalClose();

    if (result.status && result.data.token) {
    setIsSubmitting(false);
      localStorage.setItem('accessToken', result.data.token);
      localStorage.setItem('user', JSON.stringify(result.data.user));
      setUser(result.data.user);
      if (result.data.user.is_candidate) {
        navigate(PATH_CANDIDATE_HOME.DEFAULT);
        setIsCandidate(true);
      } else {
        navigate(PATH_ADMIN_CANDIDATE.DEFAULT);
        setIsCandidate(false);
      }
      return;
    }

    let error = result.error;
    setIsSubmitting(false);
    setErrors(error || {});
    swalError(result.addition_message || result.message || '', 3000);
  };

  return (
    <>
      {token ? (
        isCandidate ? (<Navigate to={PATH_CANDIDATE_HOME.DEFAULT} replace={true} />) : <Navigate to={PATH_ADMIN_CANDIDATE.DEFAULT} replace={true} />
      ) : (
        <AuthLayout>
          <>
            <form
              className="max-sm:w-full mb-[42px] mx-auto max-sm:mt-[25px] max-sm:px-[10px] max-sm:mb-[32px]"
              onSubmit={handleLogin}
            >
              <div className="sm:w-[450px] max-sm:w-full w-[370px] mb-[30px] max-sm:mb-[25px] bg-[#F8F7F6] px-[25px] border border-warning-light">
                <p className="mt-[30px] max-sm:mt-[25px] mb-[20px] text-[15px] text-[500] text-secondary-dark">
                  ログイン
                </p>
                {errors && !!Object.keys(errors).length && (
                  <div className="mb-[20px] text-left">
                    <ErrorBox errors={errors} />
                  </div>
                )}
                <div className="mb-[20px]">
                  <input
                    type="text"
                    name="username"
                    className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full py-[14px] placeholder:text-[#9E9E9F] placeholder:text-sm"
                    placeholder="メールアドレス　または　ログインID"
                    required
                    onChange={handleChangeInput}
                  />
                </div>
                <div className="mb-[15px] max-sm:mb-[20px]">
                  <input
                    type="password"
                    placeholder="パスワード"
                    onChange={handleChangeInput}
                    name="password"
                    className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full py-[14px] placeholder:text-[#9E9E9F]"
                    required
                  />
                </div>
                <div className="flex items-center text-[12px] mb-[20px]">
                  <input
                    id="remember"
                    type="checkbox"
                    name={'remember_me'}
                    onChange={handleChangeInput}
                    className="w-[15px] h-[15px] border border-warning-light rounded"
                  />
                  <label
                    htmlFor="remember"
                    className="ml-2 text-secondary max-sm:text-[#5A5A5A] max-sm:fornt-[400]"
                  >
                    ログイン状態を保持
                  </label>
                </div>
              </div>

              <Button
                content={'ログイン'}
                otherstyle={'bg-primary text-white max-sm:text-[18px]'}
                type="submit"
                isSubmitting={isSubmitting}
              />

              <Link to={ROUTE.FORGOT_PASSWORD}>
                <div className="flex justify-center items-center mt-[16px] max-sm:mt-[18px]">
                  <HelpCircleIcon />
                  <p className="ml-[10px] text-[14px] align-middle">パスワードをお忘れの方</p>
                </div>
              </Link>
            </form>
            {/* Social Account Login hidden for future phases */}
            {/* <p className="hidden max-sm:block mb-[20px] text-[0.94rem]">
              ほかのアカウントでログインする
            </p> */}

            <div className="hidden w-full justify-center">
              <LoginSocialFacebook
                isOnlyGetToken={true}
                appId={`${process.env.REACT_APP_FB_APP_ID}`}
                onReject={(err) => {
                  swalMessage('Error', 'Error', 'error');
                }}
                onResolve={async (data) => {
                  if (data.data) {
                    let result = (await socialLogin(data.provider, data.data.accessToken)) as any;
                    if (result && result.status) {
                      if (result.data.token) {
                        localStorage.setItem('accessToken', result.data.token);
                        localStorage.setItem('user', JSON.stringify(result.data.user));
                        setUser(result.data.user);
                        if (result.data.user.is_candidate) {
                          navigate(ROUTE.MY_PAGE.DEFAULT);
                        } else {
                          navigate(PATH_ADMIN_GROUP.DEFAULT);
                        }
                      } else {
                        swalMessage('Error', MESSAGE_ERROR.SOMETHINGS_WENT_WRONG, 'error');
                      }
                    } else {
                      swalMessage('Error', result.message, 'error');
                    }
                  }
                }}
              >
                <SNSButton>
                  <>
                    <FacebookLoginIcon className={'block mx-auto'} />
                    <span className="text-[7.5px] max-sm:text-secondary-dark max-sm:font-[400]">
                      facebookアカウント
                    </span>
                  </>
                </SNSButton>
              </LoginSocialFacebook>
              {/* <LoginSocialGoogle
                  isOnlyGetToken={true}
                  client_id={`${process.env.REACT_APP_GG_APP_ID}`}
                  onReject={err => {
                    swalMessage('Error', 'Error', 'error');
                  }}
                  onResolve={async data => {
                    if (data.data) {
                      let result = await socialLogin(data.provider, data.data.access_token) as any;
                      if (result && result.status) {
                        if (result.data.token) {
                          localStorage.setItem('accessToken', result.data.token);
                          localStorage.setItem('user', JSON.stringify(result.data.user));
                          setUser(result.data.user);
                          if (result.data.user.is_candidate) {
                            navigate(ROUTE.MY_PAGE.DEFAULT);
                          } else {
                            navigate(PATH_ADMIN_GROUP.DEFAULT);
                          }
                        } else {
                          swalMessage('Error', MESSAGE_ERROR.SOMETHINGS_WENT_WRONG, 'error');
                        }
                      } else {
                        swalMessage('Error', result.message, 'error');
                      }
                    }
                  }}
                > */}
              <SNSButton>
                <>
                  <GoogleIcon className={'block mx-auto'} />
                  <span className="text-[7.5px] max-sm:text-secondary-dark max-sm:font-[400]">
                    googleアカウント
                  </span>
                </>
              </SNSButton>
              {/* </LoginSocialGoogle>  */}
            </div>
          </>
        </AuthLayout>
      )}
    </>
  );
};

export default Login;
