/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import SideQuestionType from './SideQuestionType';
import QuestionEditorHandler from './QuestionEditorHandler';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { PATH_ADMIN_QUESTION } from '../../../../services/constants/route/router';
import { request } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { useAuthoringQuestionContext } from '../../../../context/Question/AuthoringQuestionContext';

const AuthoringQuestion = () => {
  const { setAuthoringData } = useAuthoringQuestionContext();
  const { getParamValue, navigate } = useObjectRoutes();
  const questionResourceId = getParamValue('resource_id');

  useEffect(() => {
    if (!questionResourceId) navigate(PATH_ADMIN_QUESTION.DEFAULT);

    const fetchResource = async () => {
      await request.get(
        `${API.ADMIN_QUESTION_RESOURCE.DETAIL}?resource_id=${questionResourceId}`,
        (res) => {
          if (!res) return;
          setAuthoringData(JSON.parse(res));
        },
      );
    };
    fetchResource();
  }, []);

  return (
    <div className="w-full flex gap-x-[20px]">
      <SideQuestionType />
      <QuestionEditorHandler />
    </div>
  );
};

export default AuthoringQuestion;
