import React from "react";
import ExamManagementBase from ".";
import UpdateExamVenue from "../../../components/admin/ExamVenue/UpdateExamVenue";

const UpdateExamVenuePage = () => {
   return (
      <ExamManagementBase title="試験管理 ｜ アップデート情報">
         <UpdateExamVenue />
      </ExamManagementBase>
   );
};

export default UpdateExamVenuePage;
