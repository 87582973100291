import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Sidebar from "../../../components/candidate/Profile/Sidebar";
import VerifyingEmailModal from "../../../components/candidate/Profile/VerifyingEmailModal";
import CandidateLayout from "../../../layouts/candidate";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalError, swalMessage } from "../../../services/helpers/swal";
import { User } from "../../../types/user";
import { MESSAGE_ERROR } from "../../../services/constants/message";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../../components/commons/form/ErrorBox";
import LayoutWaitingApi from "../../../components/commons/layouts/LayoutWaitingAPI";
import { StatusCodes } from "http-status-codes";
import { ROUTE } from "../../../services/constants/route/router";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/commons/icons/Animations/Spinner";

const MailChanging = () => {
  const [userData, setUserData] = useState<User>({});
  const [submitData, setSubmitData] = useState<User>({} as User);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [tempEmail, setTempEmail] = useState<string>("");
  const [errors, setErrors] = useState<ErrorData>({});
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const asyncFn = async () => {
      const userResult = await makeRequest({
        method: "get",
        url: API.USER.INFO,
      });

      if (
        userResult.code === StatusCodes.UNAUTHORIZED ||
        userResult.code === StatusCodes.FORBIDDEN
      ) {
        localStorage.clear();
        swalError(userResult.message);
        setTimeout(() => {
          navigate(ROUTE.LOGIN);
        }, 1500);
        return;
      }

      if (!userResult.data) {
        return swalError();
      }
      setWaitingApi(false);
      setUserData(userResult.data);
      Swal.close();
    };

    asyncFn();
  }, [isOpenModal]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSubmitData({
      email: e.target.value,
    });
  };

  const onSubmitHandle = async (email?: string) => {
    let updateMailData = submitData;
    if (email) {
      updateMailData = { email };
    }
    setIsSubmitting(true);
    const result = await makeRequest({
      method: "patch",
      url: API.CANDIDATE.UPDATE_EMAIL,
      data: updateMailData,
    });
    setIsSubmitting(false);

    if (!result.data) {
      setErrors(result.error as any);
      return swalMessage("", "", "error", {
        timer: 1000,
        showConfirmButton: false,
        footer: MESSAGE_ERROR.SOMETHINGS_WENT_WRONG,
      });
    }

    //verify token
    setTempEmail(submitData.email || "");
    setIsOpenModal(true);
    setSubmitData({});
    setErrors({});
  };

  return (
    <>
      <CandidateLayout sidebar={<Sidebar />} cover>
        <div className="w-full text-secondary-dark bg-white rounded-[10px] p-[40px]">
          {/* title */}
          <div className="border-b border-secondary-light pb-[14px] w-full mb-[60px] text-[14px] font-[500] leading-[100%]">
            アカウント情報変更｜メールアドレス変更
          </div>
          <LayoutWaitingApi
            className="w-full h-[400px]"
            waitingApi={waitingApi}
          >
            <div className="pl-[32px]">
              {/* describe */}
              <div className="text-[16px] font-[700] leading-[100%]">
                メールアドレス変更
              </div>
              <div className="text-[12px] leading-[190%] mb-[43px]">
                <div className="">
                  新しいメールアドレスを入力し「送信する」ボタンをクリックしてください。
                </div>
                <div className="">
                  アドレス変更手続きのメールを送信しますので、受信メールに記載されたURLからアドレス変更を完了してください。
                </div>
              </div>

              {Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}

              {/* gmail */}
              <div className="w-full h-[40px] mb-[5px] border-b border-success-extralight">
                <div className="flex items-center h-[40px]">
                  <div className="w-full max-w-[220px] pl-[14px] py-[12px] bg-success-lighter font-[500] text-[14px] leading-[100%]">
                    現在のメールアドレス
                  </div>
                  <div className="w-full max-w-[485px] flex justify-between pl-[13px] text-[13px]">
                    <div className="mb-[-4px]">{userData?.email || ""}</div>
                  </div>
                </div>
              </div>

              <div className="flex items-center h-[40px] mb-[42px]  border-b border-success-extralight">
                <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-success-lighter flex items-center pl-[14px] h-full">
                  <div className="w-full flex justify-between">
                    <div className="">新しいメールアドレス</div>
                    <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                      必須
                    </div>
                  </div>
                </div>
                <div className="text-secondary-dark ml-[13px] w-[55%] flex items-center">
                  <input
                    className="text-[13px] leading-[100%] pl-[10px] h-[34px]"
                    onChange={handleOnChange}
                    value={submitData?.email || ""}
                  />
                </div>
              </div>

              <div className="w-full flex justify-center items-center mb-[42px]">
                <button
                  className="w-[200px] text-center bg-primary-synch px-[64px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]"
                  onClick={() => onSubmitHandle()}
                >
                  <span>{isSubmitting ? <Spinner /> : "送信する"}</span>
                </button>
              </div>
            </div>
          </LayoutWaitingApi>
        </div>
      </CandidateLayout>
      {isOpenModal && (
        <VerifyingEmailModal
          setIsOpen={setIsOpenModal}
          email={tempEmail}
          setSubmitData={setSubmitData}
          resubmitCode={onSubmitHandle}
          userData={userData}
          setUserData={setUserData}
        />
      )}
    </>
  );
};

export default MailChanging;
