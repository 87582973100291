import React from "react";
import ExaminationTestPart from "../../../components/candidate/Examination/ExaminationTestPart";
import ExaminationTestBase from "./ExaminationTestBase";

const ExaminationTestPartPage = () => {
  return (
    <ExaminationTestBase>
      <ExaminationTestPart />
    </ExaminationTestBase>
  );
};

export default ExaminationTestPartPage;
