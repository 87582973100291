import { ChangeEvent, useEffect, useState } from "react";
import ExamVenueTableCommonAction from "./ExamVenueCommonAction";
import ExamVenueFilter from "./ExamVenueFilter";
import ExamVenueTable from "./ExamVenueTable";
import LayoutWaitingApi from "../../../commons/layouts/LayoutWaitingAPI";
import { useExamManagementContext } from "../../../../context/ExamManagementContext";
import { ExamVenue } from "../../../../types/admin/examManagement";
import { swalClose, swalConfirm, swalError, swalSuccess } from "../../../../services/helpers/swal";
import { API } from "../../../../services/constants/route/api";
import { makeRequest } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { EXAM_VENUE_STATUS } from "../../../../services/constants/admin/pages/examVenue";

type FilterType = {
   query: string;
   limit: number;
   page: number;
   status?: string;
   sortType?: number;
   group_id?: number | string;
};

const ListExamVenue = () => {
   const [selectAll, setSelectAll] = useState(false);
   const [selectedIds, setSelectedIds] = useState<number[]>([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [waitingApi, setWaitingApi] = useState<boolean>(true);
   const { exam_venues, setExamVenues, setTotalPages } = useExamManagementContext();
   const [_exam_venues, _setExamVenues] = useState<ExamVenue[]>(exam_venues || []);
   const [filter, setFilter] = useState<FilterType>({
      limit: 10,
      query: "",
      page: 1,
      sortType: 1,
      status: EXAM_VENUE_STATUS.PUBLIC.toString(),
   });
   
   useEffect(() => {
      _setExamVenues(exam_venues);
   }, [exam_venues]);

   useEffect(() => {
      const fetch = async () => {
         const result = await makeRequest({
            method: "get",
            url: `${API.ADMIN_EXAM_VENUE.LIST_EXAM_MANAGEMENT}?${paramizeObject(
               filter
            )}`,
         });

         if (!result.data) return swalError();
         // checkSelectAll(result.data.exam_venue_list);
         setExamVenues(result.data.exam_venue_list);
         setTotalPages(result.data.total_page);
         setSelectAll(false);
         setWaitingApi(false);
         swalClose();
      };

      const timer = setTimeout(() => {
         fetch();
      }, 500);
      return () => clearTimeout(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filter]);

   // const checkSelectAll = (currentExamVenue: ExamManagement[]) => {
   //    let currentPageExamVenueIds = currentExamVenue?.map(
   //       (exam_venue) => exam_venue.id
   //    );
   //    let allCurrentPageExamVenueSelected = currentPageExamVenueIds.every((id) =>
   //       selectedIds.includes(id)
   //    );
   //    if (allCurrentPageExamVenueSelected) {
   //       setSelectAll(true);
   //    } else {
   //       setSelectAll(false);
   //    }
   // };

   const resetTable = () => {
      setSelectedIds([]);
      setSelectAll(false);
      setCurrentPage(1);
   };

   const changeHandle = (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
      if (e.target.type === "checkbox") {
         const target = e.target as HTMLInputElement;
         // let data = fitler;
         if (target.checked) {
            setFilter({
               ...filter,
               status: "",
               page: 1,
            });
            resetTable();
            return;
         } else {
            setFilter({
               ...filter,
               status: EXAM_VENUE_STATUS.PUBLIC.toString(),
               page: 1,
            });
            resetTable();
            return;
         }
      }
      setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
   };

   const handlePageChange = (pageNumber: number) => {
      // update the current page state
      setWaitingApi(true);
      setCurrentPage(pageNumber);
      changePaginate(pageNumber);
   };
   const changePaginate = (number: number) => {
      setFilter({ ...filter, page: number });
   };

   const toggleSelect = (id: number) => {
      if (selectedIds?.includes(id)) {
         setSelectedIds(selectedIds?.filter((sId) => sId !== id));
      } else {
         setSelectedIds([...selectedIds, id]);
      }
   };
   const toggleSelectAll = () => {
      setSelectAll(!selectAll);
      if (!selectAll) {
         setSelectedIds(exam_venues?.map((exam_venue) => Number(exam_venue?.id)));
      } else {
         setSelectedIds([]);
      }
   };

   const isExamVenueSelected = (id: number) => selectedIds?.includes(id);

   const deleteSelected = async () => {
      if (selectedIds.length === 0) {
         return;
      }
      swalConfirm(async () => {
         const result = await makeRequest({
            method: "delete",
            url: API.ADMIN_EXAM_VENUE.DELETE_EXAM_MANAGEMENT,
            data: { exam_venue_ids: selectedIds },
         });
         if (!result.status) {
            return swalError();
         }
         _setExamVenues(_exam_venues?.filter((exam_venue) => !selectedIds.includes(Number(exam_venue?.id))));
         resetTable();
         swalSuccess();
         changePaginate(1);
      }, "消去してもよろしいですか？");
   };


   return (
      <div>
         <div className="w-full flex justify-end">
            <ExamVenueTableCommonAction />
         </div>
         <ExamVenueFilter
            changeHandle={changeHandle}
            deleteSelected={deleteSelected}
         />
         <LayoutWaitingApi waitingApi={waitingApi} className="w-full h-[100px]">
            <ExamVenueTable
               currentPage={currentPage}
               _exam_venues={_exam_venues}
               handlePageChange={handlePageChange}
               toggleSelectAll={toggleSelectAll}
               toggleSelect={toggleSelect}
               selectAll={selectAll}
               isExamVenueSelected={isExamVenueSelected}
            />
         </LayoutWaitingApi>
      </div>
   )
};

export default ListExamVenue;