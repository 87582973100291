import { WithChildren } from '../../../types/globals';

type TableHeadItemProps = {
  className?: string;
};

const TableHeadItem = ({ className, children }: WithChildren<TableHeadItemProps>) => (
  <div
    className={`flex items-center justify-center border-2 border-white min-h-[30px] text-[13px] font-[500] leading-[100%] ${className}`}
  >
    {children}
  </div>
);

export default TableHeadItem;
