import { useEffect } from "react";
import { useExaminationTestContext } from "../../../context/ExaminationTestContext";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { paramizeObject } from "../../../services/helpers/parseData";
import { RegisterExaminationType } from "../../../types/candidate/registerExamination";
import {
  currentQuestionNumber,
  isPropertyExists,
} from "../../../services/utils/candidate/examinationTest";
import "../../../assests/styles/globals.css";
import { isEmptyObj } from "../../../services/helpers/etc";
import {
  CandidateExam,
  CandidateExamSectionTest,
} from "../../../types/candidate/exam";
import { SETTING_PROPERTY_NAMES } from "../../../services/constants/admin/pages/exam";
import { swalMessage } from "../../../services/helpers/swal";
// import "./ExaminationTestComponent/MagnifyingGlass";
import "../../../assests/styles/magnifyGlass.css";
import ListQuestionNumber from "./ExaminationTestComponent/ListQuestionNumber";
import ExaminationTestContent from "./ExaminationTestComponent/ExaminationTestContent";
import ExaminationTestFooter from "./ExaminationTestComponent/ExaminationTestFooter";
import { CANDIDATE_EXAM_MESSAGE } from "../../../services/constants/candidate/examinationTest";
import { useNavigate } from "react-router-dom";
import { useStorage } from "../../../hooks/useStorage";

type ExaminationTestType = {
  register_examination: RegisterExaminationType;
  exam: CandidateExam;
};

const ExaminationTestPart = () => {
  const {
    examPartSelected,
    setRegisterExamination,
    setExamPartSelected,
    examSectionSelected,
    setExamSectionSelected,
    setExamItemSelected,
    filterQuestionKey,
    setFilterQuestionKey,
    setQuestionProblems,
    examSectionProperties,
    isLastQuestionOfSection,
    examPartQuestions,
    registerExamination,
  } = useExaminationTestContext();
  const examPartStorageKey = "exam_part_selected";
  const reStorageKey = "register_examination";
  const { getLocalStorage, setLocalStorage, removeLocalStorage } = useStorage();
  const { searchParamsObject } = useObjectRoutes();
  const navigate = useNavigate();

  const warningIsLastQuestionOfSection = () => {
    if (
      isLastQuestionOfSection(
        filterQuestionKey.sectionId,
        filterQuestionKey.itemId
      ) &&
      isPropertyExists(
        SETTING_PROPERTY_NAMES.NAVIGATE_SECTION_WARNING,
        examSectionProperties.navigation_warning || []
      )
    ) {
      swalMessage(
        "",
        CANDIDATE_EXAM_MESSAGE.LAST_QUESTION_IN_EXAM_SECTION,
        "warning",
        {
          timer: 1500,
          showConfirmButton: false,
        }
      );
    }
  };

  useEffect(() => {
    const examPartSelected = getLocalStorage(examPartStorageKey);
    const registerExamination = getLocalStorage(reStorageKey);
    if (!isEmptyObj(examPartSelected) && !isEmptyObj(registerExamination)) {
      setRegisterExamination(registerExamination);
      setExamPartSelected(examPartSelected);
      setTimeout(() => {
        removeLocalStorage([examPartStorageKey, reStorageKey])
      }, 5000)
      return;
    }

    const fetch = async () => {
      await request.get(
        `${API.EXAMINATION.GET_TEST_PART_DETAIL}?${paramizeObject(
          searchParamsObject
        )}`,
        (data: ExaminationTestType) => {
          setRegisterExamination(data.register_examination);
          if (data.exam?.exam_parts && data.exam?.exam_parts.length) {
            setExamPartSelected(data.exam.exam_parts[0]);
          }
        },
        (error) => {
          if (error == 404) {
            navigate("/404");
          }
        }
      );
    };

    fetch();
  }, []);

  useEffect(() => {
    if (!examPartSelected || !isEmptyObj(filterQuestionKey)) return;

    if (
      examPartSelected.exam_sections &&
      examPartSelected.exam_sections.length
    ) {
      const examSectionFirst: CandidateExamSectionTest =
        examPartSelected.exam_sections[0];
      setExamSectionSelected(examSectionFirst);

      if (examSectionFirst.exam_items && examSectionFirst.exam_items.length) {
        const examItemFirst = examSectionFirst.exam_items[0];

        setFilterQuestionKey({
          sectionId: examSectionFirst.id || 0,
          itemId: examItemFirst.id || 0,
        });
      }
    }
  }, [examPartSelected]);

  useEffect(() => {
    const examItem = examPartSelected.exam_sections
      ?.find((examSection) => examSection.id === filterQuestionKey.sectionId)
      ?.exam_items?.find(
        (examItem) => examItem.id === filterQuestionKey.itemId
      );

    if (examItem) {
      setExamItemSelected(examItem);
      setQuestionProblems(examItem.question_data?.problems || []);
      warningIsLastQuestionOfSection();
    }
  }, [filterQuestionKey]);

  useEffect(() => {
    if (isEmptyObj(registerExamination) || isEmptyObj(examPartSelected)) {
      return;
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      if (registerExamination && examPartSelected) {
        setLocalStorage(reStorageKey, registerExamination);
        setLocalStorage(examPartStorageKey, examPartSelected);
      }

      event.returnValue = "";
      return "Would you like to exit?";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [registerExamination, examPartSelected]);

  return (
    <div className="w-full flex flex-col form_authoring">
      <div className="box-border bg-white w-full h-full px-[30px] py-[50px] text-secondary-dark">
        <p className="text-[24px] leading-[32px] font-[700] mb-[25px]">
          {examPartSelected.name}（{examSectionSelected.name}）
          <span className="font-[400]">
            問題
            {currentQuestionNumber(
              examPartQuestions,
              filterQuestionKey.sectionId,
              filterQuestionKey.itemId
            )}
          </span>
        </p>
        <div className="w-full h-[calc(100%-50px)] max-h-[800px] flex gap-x-[25px] px-[10px]">
          {/* Question number */}
          <ListQuestionNumber />

          {/* Content question */}
          <ExaminationTestContent />
        </div>
      </div>

      {/* Footer */}
      <ExaminationTestFooter />
    </div>
  );
};

export default ExaminationTestPart;
