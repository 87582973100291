import FolderBase from '.';
import AllFileList from '../../../components/admin/Folder/List/AllFileList';

const FileListPage = () => {
  return (
    <FolderBase title='ファイル管理 ｜ 画像埋め込みタグ一覧'>
        <AllFileList/>
    </FolderBase>
  );
};

export default FileListPage;