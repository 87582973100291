import * as React from 'react';

const Mapping = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#clip0_6124_8262)">
      <path
        style={{
          stroke: 'currentcolor',
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
        d="M1.5 4.357c0-1 0-1.5.195-1.882.17-.336.444-.61.78-.78.382-.195.882-.195 1.882-.195h6.786c1 0 1.5 0 1.882.195.336.17.61.444.78.78.195.382.195.882.195 1.882v6.786c0 1 0 1.5-.195 1.882-.17.336-.444.61-.78.78-.382.195-.882.195-1.882.195H4.357c-1 0-1.5 0-1.882-.195a1.786 1.786 0 01-.78-.78c-.195-.382-.195-.882-.195-1.882V4.357z"
      ></path>
      <path
        style={{
          fill: 'currentcolor',
        }}
        d="M9.145 3.637V12H7.63V5.111h-.049l-1.956 1.25V4.972l2.079-1.335h1.441zM15.652 20.121c-.38 0-.722-.068-1.027-.203a1.678 1.678 0 01-.719-.61c-.174-.268-.261-.598-.261-.992 0-.338.062-.618.187-.84a1.45 1.45 0 01.512-.53 2.58 2.58 0 01.73-.302c.274-.07.556-.12.848-.152.351-.036.637-.069.855-.098.22-.03.378-.078.477-.14.101-.065.152-.166.152-.3v-.024c0-.295-.087-.522-.261-.684-.175-.161-.426-.242-.754-.242-.347 0-.621.075-.825.226-.2.152-.336.33-.406.536l-1.32-.188c.104-.364.276-.67.515-.914.24-.247.533-.432.88-.555.346-.125.729-.187 1.148-.187.289 0 .577.034.863.101.287.068.548.18.785.336.237.154.427.364.57.63.146.265.22.597.22.995V20h-1.36v-.824h-.047a1.729 1.729 0 01-.363.469c-.154.143-.348.259-.582.347a2.348 2.348 0 01-.817.13zm.368-1.039c.283 0 .53-.056.738-.168.208-.115.368-.266.48-.453.115-.188.172-.392.172-.613v-.707a.665.665 0 01-.226.101 3.213 3.213 0 01-.352.082c-.13.024-.26.044-.387.063l-.332.047c-.21.028-.4.075-.566.14a.941.941 0 00-.395.274.668.668 0 00-.144.445c0 .26.095.457.285.59.19.133.432.199.727.199z"
      ></path>
      <path
        style={{
          stroke: 'currentcolor',
        }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
        d="M10.5 13.243c0-.96 0-1.44.187-1.807.164-.322.427-.585.749-.75.367-.186.847-.186 1.807-.186h6.514c.96 0 1.44 0 1.807.187.323.164.585.427.75.749.186.367.186.847.186 1.807v6.514c0 .96 0 1.44-.187 1.807a1.716 1.716 0 01-.749.75c-.367.186-.847.186-1.807.186h-6.514c-.96 0-1.44 0-1.807-.187a1.716 1.716 0 01-.75-.749c-.186-.367-.186-.847-.186-1.807v-6.514z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_6124_8262">
        <path fill="#fff" d="M0 0H24V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
export default Mapping;
