import React, { useEffect, useState } from 'react';
import FormItem from '../../commons/FormItem';
import StatusBox from '../../commons/StatusBox';
import { FiTrash2 } from 'react-icons/fi';
import CancelButton from '../../commons/CancelButton';
import SubmitButton from '../../commons/SubmitButton';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import {
  swalClose,
  swalError,
  swalLoading,
  swalSuccess,
  swalConfirm,
} from '../../../../services/helpers/swal';
import { makeRequest } from '../../../../services/axios/axios';
import { PATH_ADMIN_GROUP } from '../../../../services/constants/route/router';
import { API } from '../../../../services/constants/route/api';
import { Group } from '../../../../types/admin/group';
import { scrollToTop } from '../../../../services/helpers/effect';
import ErrorBox from '../../../commons/form/ErrorBox';
import { ErrorData } from '../../../../types/globals';

type FormProps = {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};

const Form = ({ setTitle }: FormProps) => {
  const { getParamValue, navigate } = useObjectRoutes();
  const groupId = getParamValue('group_id');
  const [currentGroup, setCurrentGroup] = useState<Group>({});
  const [parentGroups, setParentGroups] = useState<Group[]>([]);
  const [errors, setErrors] = useState<ErrorData>({});

  useEffect(() => {
    if (!groupId) return;
    scrollToTop();

    const fetch = async () => {
      const groupInfoPromise = makeRequest({
        method: 'get',
        url: `${API.ADMIN_GROUP.GET_GROUP_INFO}?group_id=${groupId}`,
      });

      const parentGroupPromise = makeRequest({
        method: 'get',
        url: API.ADMIN_GROUP.GET_MAIN_GROUP,
      });

      const result = await Promise.all([groupInfoPromise, parentGroupPromise]);
      const [groupInfoResult, parentGroupInfoResult] = result;

      if (!groupInfoResult.status || !parentGroupInfoResult.status) return swalError();

      const mainGroups = parentGroupInfoResult.data as Group[];
      const currentGroup = groupInfoResult.data as Group;

      let title = '';
      if (!currentGroup.parent_group_id) {
        title = currentGroup?.name || '';
      } else {
        const parentCurrentGroup = mainGroups.find(
          (group) => group.id === currentGroup?.parent_group_id,
        );
        title = `${parentCurrentGroup?.name}｜${currentGroup?.name}`;
      }

      setTitle(title);
      setCurrentGroup(groupInfoResult.data);
      setParentGroups(parentGroupInfoResult.data);
      swalClose();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    setCurrentGroup({
      ...currentGroup,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    if (!groupId) return;

    const result = await makeRequest({
      method: 'patch',
      url: `${
        currentGroup.parent_group_id
          ? API.ADMIN_GROUP.UPDATE_SUBGROUP_INFO
          : API.ADMIN_GROUP.UPDATE_GROUP_INFO
      }`,
      data: { ...currentGroup, group_id: currentGroup.id },
    });

    if (!result.status) {
      setErrors(result.error as ErrorData);
      return swalError();
    }
    swalSuccess();

    navigate(PATH_ADMIN_GROUP.DEFAULT);
  };

  const handleDelete = async () => {
    swalConfirm(
      async () => {
        const result = await makeRequest({
          method: 'delete',
          url: API.ADMIN_GROUP.DELETE_GROUP,
          data: { group_id: currentGroup.id },
        });

        if (!result.status) return swalError();
        swalSuccess();

        navigate(PATH_ADMIN_GROUP.DEFAULT);
      },
      'このグループを削除してよろしいですか？',
    );
  };

  return (
    <section className={`mt-[20px] ${!groupId && 'pointer-events-none'}`}>
      {Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}

      <FormItem label="グループ名" withRequired>
        <input
          type="text"
          name="name"
          id=""
          required
          value={currentGroup.name}
          onChange={handleOnChange}
        />
      </FormItem>

      {currentGroup.parent_group_id && (
        <FormItem label="親セクション">
          <select
            name="parent_group_id"
            id=""
            className="w-full max-w-[320px] py-[10px] pl-[13px] text-[12px] leading-[100%] h-[33px]"
            value={currentGroup.parent_group_id}
            onChange={handleOnChange}
          >
            <option value="" disabled>
              選択してください
            </option>
            {parentGroups.map((group, index) => (
              <option value={group.id} key={index}>
                {group.name}
              </option>
            ))}
          </select>
        </FormItem>
      )}

      <FormItem label="グループID" withRequired>
        <input
          type="text"
          name="code"
          id=""
          required
          value={currentGroup.code}
          onChange={handleOnChange}
        />
      </FormItem>

      <FormItem label="ステータス" withRequired>
        <StatusBox handleOnChange={handleOnChange} value={currentGroup.status} />
      </FormItem>

      <FormItem label="グループ概要">
        <textarea
          name="description"
          id=""
          className="min-h-[90px]"
          onChange={handleOnChange}
          value={currentGroup.description}
        />
      </FormItem>

      <div className="flex justify-center gap-[60px] mt-[66px] mb-[8px]">
        <div
          className="border border-secondary text-secondary rounded-[5px] text-[16px] font-[500] leading-[100%] flex gap-[15px] min-h-[36px] w-full max-w-[254px] justify-center items-center cursor-pointer"
          onClick={handleDelete}
        >
          <div className="">
            <FiTrash2 />
          </div>
          <div className="">このグループを削除</div>
        </div>

        <div className="flex gap-[15px]">
          <CancelButton navigateTo={PATH_ADMIN_GROUP.DEFAULT} />
          <SubmitButton onSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  );
};

export default Form;
