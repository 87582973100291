import React from 'react';
import SettingsConfig from '../../../components/admin/Candidate/SettingsConfig';
import CandidateBase from '.';

const CandidateSettingPage = () => {
  return (
    <CandidateBase title="受講者管理  ｜ 受講者設定">
      <SettingsConfig />
    </CandidateBase>
  );
};

export default CandidateSettingPage;