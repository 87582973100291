const TITLE_STEP_REGISTER_EXAMINATIONS = {
   STEP1: '試験選択',
   STEP2_CBT: '日時・会場の選択',
   STEP2_IBT: '日時の選択',
   STEP3: '予約確認・お支払い',
   STEP4: '予約完了',
};

const INDEX_STEP_REGISTER_EXAMINATIONS = {
   STEP_1: 1,
   STEP_2: 2,
   STEP_3: 3,
   STEP_4: 4
}

const ERROR_MESSAGE_REGISTER_EXAMINATIONS = {
   EXAM_NAME_ID: '試験を選択してください。',
   EXAMINATION_ID: '試験の種類を選択してください。',
   SELECTED_DATE: 'テスト日を選択してください',
   SELECTED_TIME: 'テスト時間を選択してください',
   SELECTED_DATE_AFTER_NOW: '今日より後の日付を指定してください。'
};


export {
   TITLE_STEP_REGISTER_EXAMINATIONS,
   ERROR_MESSAGE_REGISTER_EXAMINATIONS,
   INDEX_STEP_REGISTER_EXAMINATIONS
}