import * as React from 'react';

const Choice = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      style={{
        fill: 'currentcolor',
      }}
      fillRule="evenodd"
      d="M17.25 11.8A4.002 4.002 0 0016 4a4.002 4.002 0 00-3.8 2.75h1.962a3.088 3.088 0 013.088 3.088v1.963z"
      clipRule="evenodd"
    ></path>
    <path
      style={{
        stroke: 'currentcolor',
      }}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
      d="M5.714 15.5l1.857 1.857 4.179-4.178M4.971 22h7.058c1.04 0 1.56 0 1.957-.202.35-.178.633-.463.812-.812.202-.397.202-.917.202-1.957V11.97c0-1.04 0-1.56-.202-1.957a1.858 1.858 0 00-.812-.812C13.589 9 13.069 9 12.029 9H4.97c-1.04 0-1.56 0-1.957.202-.35.178-.634.463-.812.812C2 10.411 2 10.931 2 11.971v7.058c0 1.04 0 1.56.202 1.957.178.35.463.633.812.812.397.202.917.202 1.957.202z"
    ></path>
    <path
      style={{
        fill: 'currentcolor',
      }}
      fillRule="evenodd"
      d="M9.187 7c.4 0 .734-.292.841-.677a6.2 6.2 0 117.647 7.647c-.384.108-.676.442-.676.841 0 .49.433.873.908.753a7.8 7.8 0 10-9.473-9.473c-.12.476.263.908.753.908z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default Choice;
