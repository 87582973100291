import * as React from "react";

const Share = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 2.75C17.2574 2.75 16.25 3.75736 16.25 5C16.25 6.24264 17.2574 7.25 18.5 7.25C19.7426 7.25 20.75 6.24264 20.75 5C20.75 3.75736 19.7426 2.75 18.5 2.75ZM14.75 5C14.75 2.92893 16.4289 1.25 18.5 1.25C20.5711 1.25 22.25 2.92893 22.25 5C22.25 7.07107 20.5711 8.75 18.5 8.75C17.3998 8.75 16.4102 8.27618 15.7242 7.52147L10.0429 10.7679C10.1771 11.1538 10.25 11.5684 10.25 12C10.25 12.4316 10.1771 12.8462 10.0429 13.2322L15.7242 16.4786C16.4101 15.7238 17.3997 15.25 18.5 15.25C20.5711 15.25 22.25 16.9289 22.25 19C22.25 21.0711 20.5711 22.75 18.5 22.75C16.4289 22.75 14.75 21.0711 14.75 19C14.75 18.5684 14.8229 18.1538 14.9571 17.7679L9.27579 14.5214C8.58981 15.2762 7.60024 15.75 6.5 15.75C4.42893 15.75 2.75 14.0711 2.75 12C2.75 9.92893 4.42893 8.25 6.5 8.25C7.60028 8.25 8.58988 8.72386 9.27586 9.47863L14.9571 6.23219C14.8229 5.84626 14.75 5.43165 14.75 5ZM18.5 16.75C17.2574 16.75 16.25 17.7574 16.25 19C16.25 20.2426 17.2574 21.25 18.5 21.25C19.7426 21.25 20.75 20.2426 20.75 19C20.75 17.7574 19.7426 16.75 18.5 16.75ZM4.25 12C4.25 10.7574 5.25736 9.75 6.5 9.75C7.74264 9.75 8.75 10.7574 8.75 12C8.75 13.2426 7.74264 14.25 6.5 14.25C5.25736 14.25 4.25 13.2426 4.25 12Z"
      fill={`${props.color || "#7A7A7A"}`}
    />
  </svg>
);

export default Share;
