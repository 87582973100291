import React from "react";
import { TextBlockProblem } from "../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import { useExaminationTestContext } from "../../../../../context/ExaminationTestContext";
import { candidateToolIsOpen } from "../../../../../services/utils/candidate/examinationTest";
import { SETTING_PROPERTY_NAMES } from "../../../../../services/constants/admin/pages/exam";

const TextBlockQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled
}: {
  questionProblem: TextBlockProblem;
  handleUpdateProblem: (problem: TextBlockProblem) => void;
  isDisabled?: boolean;
}) => {
  const { questionProblems, setQuestionProblems, openingTools } = useExaminationTestContext();

  const handleMouseUp = () => {
    if(!candidateToolIsOpen(SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT, openingTools)) return;

    const selector = window.getSelection();
    const textSelected = selector?.toString();
    if (!textSelected) return;
    const span = document.createElement("span");
    span.setAttribute("style", "background-color: yellow; display:inline;");
    span.innerText = textSelected;
    const newText = questionProblem.title?.replace(
      textSelected,
      span.outerHTML
    );

    setQuestionProblems(
      questionProblems.map((question) => {
        if (question.id === questionProblem.id) {
          return { ...question, title: newText };
        }

        return question;
      })
    );
  };

  return (
    <div
      className="leading-[24px] font-[500]"
      dangerouslySetInnerHTML={{
        __html: decodeHTMLEntities(questionProblem.title),
      }}
      onMouseUp={handleMouseUp}
    ></div>
  );
};

export default TextBlockQuestion;
