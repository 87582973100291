const NAME_ACTION_DETAIL_QUESTION = {
  EDIT: 'edit',
  PREVIEW: 'preview',
  AUTHORING: 'authoring',
};

const NAME_ACTION_ASIDE_QUESTION = {
  CREATE_FILE: 'create-file',
  CREATE_FOLDER: 'create-folder',
  DUPLICATE: 'duplicate',
  COPY: 'copy',
  DELETE: 'delete',
  MOVE: 'move',
};

const NAME_TYPE_QUESTION = {
  CHOICE: 'choice',
  ORDER: 'order',
  MAPPING: 'mapping',
  MATRIX: 'matrix',
  FILL_IN_BLANK: 'fill-in-blank',
  SLIDER: 'slider',
  WRITTEN_QUESTION: 'written-question',
  TEXT_BLOCK: 'text-block',

  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  TEST_TAKER_UPLOAD: 'upload',
  TEST_TAKER_UPLOAD_RECORD: 'record',
};

const NAME_LIST_STYLE_CHOICE_QUESTION = {
  CHECKBOX: 'checkbox',
  DOT: 'dot',
  RADIO: 'radio',
  LIST: {
    NUMBER: 'number',
    ALPHA: 'a',
    ALPHA_UPPERCASE: 'A',
    ROMAN_NUMBER: 'i',
    ROMAN_NUMBER_UPPERCASE: 'I',
  },
};

const FILL_IN_BLANK_CONTENT_RENDER_TYPE = {
  HIGHLIGHTED: 'highlight',
  BLANKED: 'blanked',
  FILLED: 'filled',
};

const FILL_IN_BLANK_BUTTON_TYPE = {
  CLOSE: 'close',
  REMOVE: 'remove',
  SETTING: 'setting',
  CREATE: 'create',
};

const QUESTION_VALIDATE_MESSAGE_TYPE = {
  INFO: 'info',
  ERROR: 'error',
  SATISFIED: 'satisfied',
};

const RESTRICTED_TYPE_NAME = {
  DOCUMENT: 'document',
  SPREAD_SHEET: 'spread_sheet',
  PDF: 'pdf',
  MOVIE: 'movie',
  IMAGE: 'image',
  AUDIO: 'audio',
};

const RESTRICTED_TYPE_DATA = [
  {
    name: RESTRICTED_TYPE_NAME.DOCUMENT,
    label: 'ドキュメント',
  },
  {
    name: RESTRICTED_TYPE_NAME.SPREAD_SHEET,
    label: 'スプレッドシート',
  },
  {
    name: RESTRICTED_TYPE_NAME.PDF,
    label: 'PDF',
  },
  {
    name: RESTRICTED_TYPE_NAME.MOVIE,
    label: '動画',
  },
  {
    name: RESTRICTED_TYPE_NAME.IMAGE,
    label: '画像',
  },
  {
    name: RESTRICTED_TYPE_NAME.AUDIO,
    label: '音声',
  },
];

const MODE_QUESTION_CATEGORY_SELECTOR = {
  SELECT: 'select',
  CREATE: 'create',
};

const QUESTION_NAME_HAS_SUBMIT_ANSWERS = [
  NAME_TYPE_QUESTION.CHOICE,
  NAME_TYPE_QUESTION.ORDER,
  NAME_TYPE_QUESTION.MAPPING,
  NAME_TYPE_QUESTION.MATRIX,
  NAME_TYPE_QUESTION.FILL_IN_BLANK,
  NAME_TYPE_QUESTION.SLIDER,
  NAME_TYPE_QUESTION.WRITTEN_QUESTION,
  NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD,
  NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD,
];

export {
  NAME_ACTION_DETAIL_QUESTION,
  NAME_ACTION_ASIDE_QUESTION,
  NAME_TYPE_QUESTION,
  NAME_LIST_STYLE_CHOICE_QUESTION,
  FILL_IN_BLANK_CONTENT_RENDER_TYPE,
  FILL_IN_BLANK_BUTTON_TYPE,
  QUESTION_VALIDATE_MESSAGE_TYPE,
  RESTRICTED_TYPE_NAME,
  RESTRICTED_TYPE_DATA,
  MODE_QUESTION_CATEGORY_SELECTOR,
  QUESTION_NAME_HAS_SUBMIT_ANSWERS
};
