import GroupList from '../../../components/admin/Group/List/List';
import GroupListProvider from '../../../context/Group/List';
import GroupBase from '.';

const GroupListPage = () => {
  return (
    <GroupBase title='グループ管理｜ グループ一覧'>
      <GroupListProvider>
        <GroupList />
      </GroupListProvider>
    </GroupBase>
  );
};

export default GroupListPage;
