import React from 'react';
import type {FC} from 'react';
import classNames from "classnames";
import Spinner from '../commons/icons/Animations/Spinner';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  otherstyle: string | null;
  children?: JSX.Element;
  content: string;
  disabled?: boolean;
  isSubmitting?: boolean;
}

const Button: FC<ButtonProps> = (props) => {
  return (
    <button
      {...props}
      className={
        classNames({
          "w-full sm:max-w-[430px] max-w-[230px] h-[50px] max-sm:h-[40px] rounded-lg font-semibold text-[1.25rem]": true,
          [`${props.otherstyle}`]: props.otherstyle !== null,
          'opacity-50 pointer-events-none': props.isSubmitting,
        })
      }
      disabled={props.isSubmitting}
    >

      <span className="align-middle flex justify-center items-center">
      {props.isSubmitting ? <Spinner className="text-right" /> : <>{props.content}</>}
      </span>
    </button>
  )
}

export default Button
