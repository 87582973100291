import * as React from "react";

const Page = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 21.75V3.25H15.75V5.9C15.75 6.64558 16.3544 7.25 17.1 7.25H19.75V21.75H5.25ZM16.7515 1.75C16.7693 1.75 16.7871 1.75035 16.8049 1.75106C16.821 1.75035 16.8372 1.75 16.8536 1.75C17.1462 1.75 17.4269 1.86627 17.6339 2.07322L17.7061 2.14541L20.8546 5.29393L20.8553 5.29461L20.9268 5.36612C21.1337 5.57307 21.25 5.85377 21.25 6.14645C21.25 6.16276 21.2496 6.179 21.2489 6.19514C21.2496 6.21289 21.25 6.23069 21.25 6.24853V21.9C21.25 22.6456 20.6456 23.25 19.9 23.25H5.1C4.35441 23.25 3.75 22.6456 3.75 21.9V3.1C3.75 2.35441 4.35442 1.75 5.1 1.75H16.7515ZM8.5 9.75C8.08579 9.75 7.75 10.0858 7.75 10.5C7.75 10.9142 8.08579 11.25 8.5 11.25L16.5 11.25C16.9142 11.25 17.25 10.9142 17.25 10.5C17.25 10.0858 16.9142 9.75 16.5 9.75L8.5 9.75ZM7.75 18.5C7.75 18.0858 8.08579 17.75 8.5 17.75H16.5C16.9142 17.75 17.25 18.0858 17.25 18.5C17.25 18.9142 16.9142 19.25 16.5 19.25H8.5C8.08579 19.25 7.75 18.9142 7.75 18.5ZM8.5 13.75C8.08579 13.75 7.75 14.0858 7.75 14.5C7.75 14.9142 8.08579 15.25 8.5 15.25H12.5C12.9142 15.25 13.25 14.9142 13.25 14.5C13.25 14.0858 12.9142 13.75 12.5 13.75H8.5Z"
      fill={`${props.color || "#2E2E2E"}`}
    />
  </svg>
);

export default Page;
