import { ChildrenWithProps } from "../../types/globals";
import Header from "./Header";
import RequiredAuth from "../../middleware/auth";
import CheckRoleCandidate from "../../middleware/checkRoleCandidate";
import { useObjectRoutes } from "../../hooks/useObjectRoutes";
import { PATH_CANDIDATE_HOME } from "../../services/constants/route/router";
import { CANDIDATE_SIDEBAR_DATA } from "../../services/constants/candidate/sidebar";
import HeaderTest from "./HeaderTest";

const LayoutExaminationDetail = ({
  sidebar,
  children,
  classNameChild,
  gap = false,
  cover = false,
  ...props
}: {
  sidebar?: JSX.Element;
  gap?: boolean;
  cover?: boolean;
  classNameChild?: string;
} & ChildrenWithProps) => {
  const { pathname } = useObjectRoutes();
  let breadcrumbLabel = "";
  let childBreadcrumbLabel = "";
  let breadcrumbLabelHref = "";

  for (const sidebarItem of CANDIDATE_SIDEBAR_DATA) {
    const breadcrumb = sidebarItem.childs.find(
      (childSidebarItem) => childSidebarItem.href === pathname
    );
    childBreadcrumbLabel = breadcrumb?.label || "";
    breadcrumbLabel = sidebarItem.label;

    // Home
    if (pathname === PATH_CANDIDATE_HOME.DEFAULT) breadcrumbLabel = "";
    //Register Examination
    if (pathname.includes("/my-page/register-examination")) {
      breadcrumbLabel = "試験申込";
      breadcrumbLabelHref = PATH_CANDIDATE_HOME.REGISTER_EXAMINATION;
    }

    if (breadcrumb) break;
  }

  return (
    <RequiredAuth>
      <CheckRoleCandidate>
        <section className="min-h-screen" {...props}>
          <div
            className=""
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              position: "relative",
              zIndex: "10",
            }}
          >
            <HeaderTest />
          </div>
          <div className={`w-full flex bg-success-lighter px-[40px] pt-[25px] pb-[40px] ${classNameChild}`}>
            {children}
          </div>
        </section>
      </CheckRoleCandidate>
    </RequiredAuth>
  );
};

export default LayoutExaminationDetail;
