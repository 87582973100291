import { useEffect, useState } from 'react';
import { Group } from '../../../../types/admin/group';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import HalfPlus from '../../../commons/icons/HalfPlus';
import { useGroupManagementContext } from '../../../../context/Group/Management';
import { PATH_ADMIN_GROUP } from '../../../../services/constants/route/router';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { sortArrayByKey } from '../../../../services/helpers/parseData';
import { isEmptyArr } from '../../../../services/helpers/etc';

type SidebarItemProps = {
  group: Group;
};

const SidebarGroupItem = ({ group }: SidebarItemProps) => {
  const { setFilter, setTitle } = useGroupManagementContext();
  const [isDropDown, setIsDropDown] = useState<boolean>(true);
  const { navigate, getParamValue } = useObjectRoutes();
  const [ subGroups, setSubGroups ] = useState<Group[]>([]);
  const groupId = getParamValue('group_id');

  useEffect(() => {
    const orderedSubGroup = sortArrayByKey(group.sub_group || [], 'order')
    setSubGroups(orderedSubGroup);
  }, [group])

  const handleFetchCandidate = async (
    subGroupId?: number,
    groupName?: string,
    subGroupName?: string,
  ) => {
    if (!subGroupId || !groupName) return;

    setFilter({});
    setTitle(`${groupName} ${subGroupName ? `｜${subGroupName}` : ''}`);
    navigate(`${PATH_ADMIN_GROUP.MANAGEMENT}?group_id=${subGroupId}`);
  };
  
  return (
    <div>
      <div
        className={`flex justify-between items-center bg-secondary-morelighter mb-[8px] pr-[15px] cursor-pointer min-h-[30px] ${
          groupId && parseInt(groupId) === group.id && '!bg-danger-lighter rounded-[5px]'
        }`}
        onClick={() => handleFetchCandidate(group.id, group.name)}
      >
        <div className="flex items-center text-[12px] font-[500] leading-[100%] pl-[5px]">
          {isDropDown ? (
            <IoMdArrowDropdown
              className="text-secondary-light"
              size={20}
              onClick={() => setIsDropDown(!isDropDown)}
            />
          ) : (
            <IoMdArrowDropright
              className="text-secondary-light"
              size={20}
              onClick={() => setIsDropDown(!isDropDown)}
            />
          )}
          <div className="ml-[8px] w-full">{group.name}</div>
        </div>
        <div className="text-[10px] font-[500] leading-[100%]">{group.candidate_count}</div>
      </div>
      <div className="ml-[10px]">
        {isDropDown &&
          subGroups && !isEmptyArr(subGroups) &&
          subGroups.map((subGroup) => (
            <div
              className={`flex items-center text-[11px] font-[500] leading-[100%] min-h-[20px] pl-[18px] justify-between pr-[15px] cursor-pointer mb-[6px] min-h-[20px] ${
                groupId &&
                parseInt(groupId) === subGroup.id &&
                'bg-danger-lighter rounded-[5px]'
              }`}
              key={subGroup.id}
              onClick={() => handleFetchCandidate(subGroup.id, group.name, subGroup.name)}
            >
              <div className="flex items-center justify-center">
                <HalfPlus className="mr-[16.5px]" /> {subGroup.name}
              </div>
              <div className="">{subGroup.child_count}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SidebarGroupItem;
