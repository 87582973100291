import React, { useState } from 'react';
import { swalError, swalLoading, swalSuccess } from '../../../services/helpers/swal';
import { makeRequest } from '../../../services/axios/axios';
import { API } from '../../../services/constants/route/api';
import BaseModal from '../commons/BaseModal';
import { useGroupListContext } from '../../../context/Group/List';
import { useGroupManagementContext } from '../../../context/Group/Management';
import { HiPlus } from 'react-icons/hi';

type TitleProps = {
  content: string;
  withButton?: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenModal: boolean;
  withSubtitle?: boolean;
};

const GroupTitle = ({
  content,
  withButton,
  isOpenModal,
  setIsOpenModal,
  withSubtitle,
}: TitleProps) => {
  const { groups: groupsFromList, setGroups: setGroupsFromList } = useGroupListContext();
  const { groups: groupsFromManagement, setGroups: setGroupsFromManagement } =
    useGroupManagementContext();
  const [name, setName] = useState<string>('');
  const [errors, setErrors] = useState<string>('');

  const handleSubmit = async () => {
    if (!withButton) return;

    const result = await makeRequest({
      method: 'post',
      url: `${API.ADMIN_GROUP.CREATE_GROUP}`,
      data: { name },
    });

    if (!result.status) {
      if (result.message) {
        setErrors(result.message);
      }
      return swalError();
    }

    setErrors('');
    setGroupsFromList([...groupsFromList, result.data]);
    setGroupsFromManagement([...groupsFromManagement, result.data]);
    swalSuccess();
    setIsOpenModal(false);
  };

  return (
    <div className="text-[13px] font-[500] leading-[100%] text-secondary-dark">
      {withButton ? <hr className="mt-[30px] mb-[20px] h-[1px] bg-secondary-light" /> : <div className='mt-[50px]'></div>} 
      {withSubtitle && <div className="mt-[20px] pl-[5px] mb-[8px]">選択中のグループ</div>}
      <div className="min-h-[50px] w-full rounded-[5px] text-[18px] font-[700] leading-[100%] bg-primary text-white flex justify-between items-center">
        <div className="w-[40%] pl-[21px]">{content}</div>
        <div className="w-[20%]"></div>
        <div className="w-[10%]"></div>
        <div className="w-[20%]"></div>
        <div className="w-[10%]"></div>
        <div className="w-[20%] flex justify-center">
          {withButton && (
            <button
              className="flex items-center justify-center gap-[10px] rounded-[20px] cursor-pointer text-[12px] font-[500] leading-[100%] bg-danger text-white w-full max-w-[210px] h-0 min-h-[30px] text-center mr-[20px]"
              onClick={() => setIsOpenModal(true)}
            >
              <HiPlus size={20} />
              <div className="">新規グループを追加</div>
            </button>
          )}
        </div>
      </div>
      {isOpenModal && (
        <BaseModal setIsOpen={setIsOpenModal} justifyDirection="end" onClick={handleSubmit}>
          <div className="text-center pt-[222px] pb-[167px]">
            <div className="text-[16px] font-[700] leading-[100%] tracking-[0.02em] text-primary mb-[24px]">
              グループを新規登録する
            </div>
            <div className="mb-[12px]">新規グループを作成します</div>

            <div className="">
              <input
                type="text"
                name="name"
                id=""
                onChange={(e) => setName(e.target.value)}
                className="max-w-[550px] h-[40px] border !border-secondary-light mb-[18px] pl-[13px] text-[12px] leading-[100%]"
                placeholder="グループ名を入力してください。"
              />
              {errors && (
                <div className="text-[12px] font-[500] leading-[190%] text-danger">{errors}</div>
              )}
            </div>
          </div>
        </BaseModal>
      )}
    </div>
  );
};

export default GroupTitle;
