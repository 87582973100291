import React from "react";
import TestPartList from "../../../components/candidate/Examination/TestPartList";
import ExaminationTestBase from "./ExaminationTestBase";

const ExaminationPartListPage = () => {
  return (
    <ExaminationTestBase>
      <TestPartList />
    </ExaminationTestBase>
  );
};

export default ExaminationPartListPage;
