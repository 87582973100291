import { NAME_TYPE_QUESTION } from "../../../../../../../services/constants/admin/pages/question";
import Image from "../../../../../../commons/icons/QuestionType/Image";
import Audio from "../../../../../../commons/icons/QuestionType/Audio";
import { swalError } from "../../../../../../../services/helpers/swal";
import Video from "../../../../../../commons/icons/QuestionType/Video";
import { RenderDroppedQuestionTypeProps } from "../../DroppedQuestionType";
import { MediaToolProblem } from "../../../../../../../types/admin/question";
import ResourceContentDisplay from "../../../../../../candidate/common/ResourceContentDisplay";
import { getFileTypeRestricted } from "../../../../../../../services/utils/admin/question";
import { SetStateAction } from "../../../../../../../types/globals";
import { SyntheticEvent } from "react";

type MediaToolQuestionProps = {
  problemPreview?: MediaToolProblem;
  actionable?: boolean;
  handleClick?: (problemId: number) => void;
  setOpenModal?: SetStateAction<boolean>;
  handleOnloadImage?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
} & Omit<RenderDroppedQuestionTypeProps, "problemPreview">;

const MediaToolQuestion = ({
  problemPreview,
  draggedName,
  className,
  actionable = false,
  handleClick,
  setOpenModal,
  handleOnloadImage,
}: MediaToolQuestionProps) => {
  let fileTypeRestricted: number = getFileTypeRestricted(draggedName);
  let icon = <></>;
  let label = "";
  let previewRender = <></>;

  switch (draggedName) {
    case NAME_TYPE_QUESTION.IMAGE:
      icon = <Image />;
      label = "画像を追加";

      if (!problemPreview?.media_url) break;

      previewRender = (
        <div className="media_tool flex justify-center overflow-auto">
          <img
            id="mediaImage"
            src={problemPreview?.media_url}
            alt={problemPreview?.media_name}
            style={{
              maxWidth: `${
                problemPreview?.media_width
                  ? `${problemPreview?.media_width}px`
                  : "100%"
              }`,
              width: `${
                problemPreview?.media_width
                  ? `${problemPreview?.media_width}px`
                  : "100%"
              }`,
              height: `${
                problemPreview?.media_height
                  ? `${problemPreview?.media_height}px`
                  : "100%"
              }`,
            }}
            onLoad={handleOnloadImage}
          />
        </div>
      );
      break;

    case NAME_TYPE_QUESTION.AUDIO:
      icon = <Audio />;
      label = "音声を追加";

      if (!problemPreview?.media_url) break;
      previewRender = (
        <div className="flex justify-center my-[30px]">
          <ResourceContentDisplay
            url={problemPreview?.media_url}
            resourceType={fileTypeRestricted}
          />
        </div>
      );
      break;

    case NAME_TYPE_QUESTION.VIDEO:
      icon = <Video />;
      label = "映像を追加";

      if (!problemPreview?.media_url) break;
      previewRender = (
        <div className="flex justify-center my-[20px] mb-[40px]">
          <ResourceContentDisplay
            url={problemPreview?.media_url}
            resourceType={fileTypeRestricted}
          />
        </div>
      );
      break;
    default:
      swalError();
      return <></>;
  }

  return (
    <div className="">
      {!problemPreview?.media_url ? (
        <div
          className={`flex flex-col justify-center items-center gap-[7px] my-[8px] text-[#6A6D6F] ${className}`}
        >
          <div className="">{icon}</div>
          <div
            className="text-[12px] font-[500] leading-[16px] tracking-[0.5px] z-[999] cursor-pointer"
            onClick={() => {
              if (handleClick) {
                handleClick(problemPreview?.id || 0);
              }

              if (setOpenModal) {
                setOpenModal(true);
              }
            }}
          >
            {label}
          </div>
        </div>
      ) : (
        <div className={""}>{previewRender}</div>
      )}
    </div>
  );
};

export default MediaToolQuestion;
