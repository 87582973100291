import * as React from 'react';

const Video = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      style={{
        fill: 'currentcolor',
      }}
      d="M20.25 3.75H3.75a1.5 1.5 0 00-1.5 1.5v13.5a1.5 1.5 0 001.5 1.5h16.5a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5zm-3 1.5h3v1.5h-3v-1.5zm-4.5 0h3v1.5h-3v-1.5zm-4.5 0h3v1.5h-3v-1.5zm-1.5 13.5h-3v-1.5h3v1.5zm0-12h-3v-1.5h3v1.5zm1.5 12v-1.378h3v1.378h-3zm4.5 0v-1.378h3v1.378h-3zm7.5 0h-3v-1.378h.863v-.122h2.137v1.5z"
    ></path>
  </svg>
);
export default Video;
