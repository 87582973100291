import React, { ChangeEvent } from "react";
import {
  ChoiceIssue,
  ChoiceProblem,
  QuestionProblem,
} from "../../../../../../types/admin/question";
import { isUsableArr } from "../../../../../../services/helpers/etc";
import { decodeHTMLEntities } from "../../../../../../services/helpers/parseData";
import {
  generateListStyleChoice,
  isListBoxStyle,
} from "../../../../../../services/utils/admin/question";
import { handleProblemScoreCalculator } from "../../../../Question/AuthoringQuestion/QuestionEditorHandler";

const ChoiceQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled,
}: {
  questionProblem: QuestionProblem;
  handleUpdateProblem: (problem: QuestionProblem) => void;
  isDisabled: boolean;
}) => {
  const choiceQuestion: ChoiceProblem = questionProblem;
  const choiceIssues: ChoiceIssue[] = questionProblem?.issues || [];

  const handleChangePoint = (
    e: ChangeEvent<HTMLInputElement>,
    issueId: number
  ) => {
    const point = Number(e.target.value);
    if (isNaN(point)) return;

    const newOrderQuestion = {
      ...choiceQuestion,
      issues: choiceIssues?.map((issue) => {
        if (issue.id === issueId) {
          return { ...issue, point: point };
        }

        return issue;
      }),
    };
    const handleScore = handleProblemScoreCalculator(newOrderQuestion)

    handleUpdateProblem({...newOrderQuestion, total_score: handleScore});
  };

  return (
    <div className="form_authoring">
      {isUsableArr(choiceIssues) && choiceIssues.length ? (
        <ul
          className={`min-h-[100px] pt-[10px] pb-[30px] px-[10px] ${generateListStyleChoice(
            choiceQuestion?.list_style
          )} ${!choiceQuestion?.is_vertical_arrange ? "flex flex-wrap" : ""}`}
        >
          {isUsableArr(choiceIssues) &&
            choiceIssues &&
            choiceIssues.map((issue, index) => (
              <div
                className="mb-[15px] flex items-center gap-[10px] px-[10px]"
                key={index}
              >
                <div
                  className={`flex items-center ${
                    !isListBoxStyle(choiceQuestion?.list_style)
                      ? "mr-[25px]"
                      : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    name={`issue_data_checked_${index}`}
                    id=""
                    className=""
                    checked={issue.checked}
                    onChange={() => {}}
                  />
                </div>
                <li
                  className={`${
                    !choiceQuestion?.is_vertical_arrange
                      ? "w-[150px]"
                      : "w-[80%]"
                  } `}
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(issue.label || ""),
                  }}
                ></li>
                <div className="">
                  <input
                    type="text"
                    name="point"
                    className="w-[45px] h-[40px] text-[14px] !border-secondary-light"
                    placeholder="0"
                    value={issue.point || ""}
                    onChange={(e) => handleChangePoint(e, issue.id || 0)}
                    disabled={isDisabled}
                  />
                </div>
              </div>
            ))}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChoiceQuestion;
