import React, { useState, useEffect } from 'react';
import TableItem from './TableItem';
import { Group } from '../../../../types/admin/group';
import { swalClose, swalError, swalLoading } from '../../../../services/helpers/swal';
import { makeRequest } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { useGroupListContext } from '../../../../context/Group/List';
import { DIRECTION } from '../../../../services/constants/globals';
import { sortArrayByKey } from '../../../../services/helpers/parseData';

type GroupGroupItemProps = {
  isUpDisabled?: boolean;
  isDownDisabled?: boolean;
  data: Group;
  setIsOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type DroppedRecord = {
  [key: string]: boolean;
};

const TableGroupItem = ({
  isUpDisabled,
  isDownDisabled,
  data,
  setIsOpenModal,
}: GroupGroupItemProps) => {
  const { groups, setGroups } = useGroupListContext();
  const [childs, _setChilds] = useState<Group['sub_group']>([]);
  const [isDropDown, setIsDropDown] = useState<boolean>(true);

  const setChilds = (childs: Group['sub_group']) => {
    if (!childs) return [];
    _setChilds(sortArrayByKey(childs, 'order'));
  };

  useEffect(() => {
    setChilds(sortArrayByKey(data.sub_group || [], 'order'));
  }, [data.sub_group]);

  const orderHandle = async (
    groupId: Group['id'],
    direction: 'up' | 'down' = DIRECTION.UP,
    isParent: boolean,
  ) => {
    if (!childs || !groups) return;

    //Down for current record
    const currentArray = isParent ? groups : childs;
    const currentGroup = currentArray.find((childGroup) => childGroup.id === groupId);
    if (!currentGroup) return;

    const result = await makeRequest({
      method: 'patch',
      url: `${API.ADMIN_GROUP.UPDATE_GROUP}`,
      data: {
        group_id: groupId,
        order:
          direction === DIRECTION.DOWN
            ? (currentGroup?.order || 0) + 1
            : (currentGroup?.order || 0) - 1,
      },
    });

    if (!result.data) swalError();

    let groupsParsed: Group[] = [];
    let childsParsed: Group[] = [];

    if (!result.data.parent_group_id) {
      groupsParsed = [
        result.data,
        ...groups.filter((group) => group.id !== (result.data as Group).id),
      ];
      setGroups(groupsParsed);
    } else {
      childsParsed = [
        result.data,
        ...childs.filter((group) => group.id !== (result.data as Group).id),
      ];

      setChilds(childsParsed);
    }

    //Up for below record
    const currentGroupIndex = currentArray.findIndex((group) => group.id === groupId);
    const siblingGroup =
      direction === DIRECTION.DOWN
        ? currentArray[currentGroupIndex + 1]
        : currentArray[currentGroupIndex - 1];

    const siblingResult = await makeRequest({
      method: 'patch',
      url: `${API.ADMIN_GROUP.UPDATE_GROUP}`,
      data: {
        group_id: siblingGroup.id,
        order:
          direction === DIRECTION.DOWN
            ? (siblingGroup?.order || 0) - 1
            : (siblingGroup?.order || 0) + 1,
      },
    });
    if (!siblingResult.data) return swalError();

    if (!siblingResult.data.parent_group_id) {
      groupsParsed = [
        siblingResult.data,
        ...groupsParsed.filter((group) => group.id !== (siblingResult.data as Group).id),
      ];
      setGroups(groupsParsed);
    } else {
      childsParsed = [
        siblingResult.data,
        ...childsParsed.filter((group) => group.id !== (siblingResult.data as Group).id),
      ];
      setChilds(childsParsed);
    }

    swalClose();
  };

  return (
    <div className="">
      <TableItem
        data={data}
        isDownDisabled={isDownDisabled}
        isUpDisabled={isUpDisabled}
        isDropDown={isDropDown}
        setIsDropDown={setIsDropDown}
        onOrder={orderHandle}
        setIsOpenModal={setIsOpenModal}
      />

      <div className="">
        {isDropDown &&
          childs &&
          childs.map((item, index) => (
            <TableItem
              data={item}
              key={item.id}
              isDownDisabled={index === childs.length - 1}
              isUpDisabled={index === 0}
              onOrder={orderHandle}
            />
          ))}
      </div>
    </div>
  );
};

export default TableGroupItem;
