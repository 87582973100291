import React, {FC} from 'react'

interface SNSButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: JSX.Element;
}

const SNSButton: FC<SNSButtonProps> = ({
                                         children
                                       }) => {
  return (
    <button
      className="w-[90px] h-[90px] rounded bg-white border-[1px] border-warning-light mx-[10px] text-center text-gray-400">

      {children}
    </button>
  )
}

export default SNSButton
