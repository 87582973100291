import React, { ChangeEvent, useEffect, useState } from "react";
import TestTakerRecord from "../../../../../commons/icons/QuestionType/TestTakerRecord";
import { TestTakerRecordProblem } from "../../../../../../types/admin/question";
import { handleProblemScoreCalculator } from "../../../../Question/AuthoringQuestion/QuestionEditorHandler";

const RecordVoiceQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled
}: {
  questionProblem: TestTakerRecordProblem;
  handleUpdateProblem: (problem: TestTakerRecordProblem) => void;
  isDisabled: boolean;
}) => {
  const [voiceQuestion, setVoiceQuestion] =
    useState<TestTakerRecordProblem>(questionProblem);

  const handleChangePoint = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const point = Number(value);
    if(isNaN(point)) return;

    const newVoiceQuestion = { ...voiceQuestion, [name]: point };
    const handleScore = handleProblemScoreCalculator(newVoiceQuestion);

    setVoiceQuestion({...newVoiceQuestion, total_score: handleScore});
    handleUpdateProblem({...newVoiceQuestion, total_score: handleScore})
  };

  // useEffect(() => {
  //   handleUpdateProblem(voiceQuestion);
  // }, [voiceQuestion]);

  return (
    <div className="flex items-center justify-center gap-x-[30px] mt-[50px]">
      <div className="text-[#235FA9] border border-[#235FA9] rounded-full w-[44px] h-[44px] flex justify-center items-center gap-x-[10px] shadow-[2px_2px_2px_0_rgba(0, 0, 0, 0.10)]">
        <TestTakerRecord className="cursor-pointer" />
      </div>
      <div>
        <input
          type="text"
          name="point"
          className="w-[45px] h-[40px] text-[14px] !border-secondary-light"
          onChange={handleChangePoint}
          value={voiceQuestion?.point}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default RecordVoiceQuestion;
