import dayjs from "dayjs";
import React, { Dispatch, SetStateAction } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";
import ja from "date-fns/locale/ja";

registerLocale("ja", ja);

type CalendarDatePickerProps = {
  name: string;
  date: Date | null;
  setDate: Dispatch<SetStateAction<Date | null>>;
  value: string;
  handleChange?: (date: Date | null, name: string) => void;
  showTimeInput?: boolean;
};

const CalendarDatePicker = ({
  name,
  date,
  setDate,
  value,
  handleChange,
  showTimeInput = true,
}: CalendarDatePickerProps) => {
  return (
    <div className="shadow-xl w-fit">
      <div className="flex items-center justify-between text-secondary">
        <TbChevronLeft
          onClick={() => setDate(dayjs(date).subtract(1, "M").toDate())}
          className="cursor-pointer"
        />
        <div className="font-[500] text-[14px] leading-[150%] text-secondary-dark">
          {dayjs(date).format("YYYY年M月")}
        </div>
        <TbChevronRight
          onClick={() => setDate(dayjs(date).add(1, "M").toDate())}
          className="cursor-pointer"
        />
      </div>
      <ReactDatePicker
        renderCustomHeader={() => <></>}
        calendarClassName="!border-none !w-auto !bg-white"
        selected={date}
        onChange={(date) => {
          if (handleChange) {
            handleChange(date, name);
          }

          setDate(date);
        }}
        inline
        calendarStartDay={0}
        locale={"ja"}
        fixedHeight
        disabled
        showTimeInput={showTimeInput}
      />
    </div>
  );
};

export default CalendarDatePicker;
