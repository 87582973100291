import React from 'react';
import { useNavigate } from 'react-router-dom';

type CancelButtonType = {
  label?: string;
  navigateTo: string;
  className?: string;
};

const CancelButton = ({ label, navigateTo, className }: CancelButtonType) => {
  const navigate = useNavigate();

  return (
    <button
      className={`bg-secondary-light text-white px-[50px] py-[10px] text-[16px] font-[700] leading-[100%] rounded-[5px] ${className}`}
      onClick={() => navigate(`${navigateTo}` || '/')}
    >
      {label || 'キャンセル'}
    </button>
  );
};

export default CancelButton;
