import { useEffect, useState } from "react";
import LayoutSetting from "./PropertiesSettingComponent/LayoutSetting";
import { swalSuccess } from "../../../../../services/helpers/swal";
import {
  DETAIL_SECTION_MESSAGES,
  SECTION_SETTING_ALERTS,
} from "../../../../../services/constants/admin/pages/examSetting/sectionSettingExam";
import { ExamSectionSettingData } from "../../../../../types/admin/exam";
import ItemSettingInput from "./PropertiesSettingComponent/ItemSettingInput";
import "../../../../../assests/styles/settingProperties.css";
import HesaWaringOutline from "../../../../commons/icons/HesaWarningOutline";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useAuthoringExamContext } from "../../../../../context/Exam/AuthoringExamContext";
import SufferIcon from "../../../../commons/icons/Exam/SufferIcon";
import ButtonSubmit from "./PropertiesSettingComponent/ButtonSubmit";
import {
  SETTING_FEATURES,
  SETTING_PROPERTY_NAMES,
  TYPE_OF_EXAM_DISPLAY,
} from "../../../../../services/constants/admin/pages/exam";
import CheckboxProperties from "./PropertiesSettingComponent/CheckboxProperties";
import { isEmptyObj } from "../../../../../services/helpers/etc";
import {
  ExamSectionType,
  ExamType,
} from "../../../../../types/admin/examAuthoring";

const SectionSetting = () => {
  const { setExam, exam, settingPropertyOrders } = useAuthoringExamContext();
  const [examSection, setExamSection] = useState<ExamSectionType>({
    exam_id: 0,
    name: "",
    order: 0,
    parent_id: 0,
    exam_items: [],
  });
  const [settingSectionData, setSettingSectionData] =
    useState<ExamSectionSettingData>();
  const [error, setError] = useState<string>("");

  const handleExamSectionData = (exam: ExamType, object: object) => {
    const newExamParts = exam.exam_parts.map((examPart) => {
      if (examPart.order === settingPropertyOrders.orderPart) {
        const newExamSections = examPart.exam_sections.map((examSection) => {
          if (examSection.order === settingPropertyOrders.orderSection) {
            return { ...examSection, ...object };
          }

          return examSection;
        });

        return { ...examPart, exam_sections: newExamSections };
      }

      return examPart;
    });

    return { ...exam, exam_parts: newExamParts };
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let currentTarget = e.target;
    const { name, value, type } = currentTarget;

    switch (name) {
      case SETTING_PROPERTY_NAMES.NAME:
        if (!value) {
          setError("タイトルを指定してください。");
        } else {
          setError("");
        }
        setExamSection({ ...examSection, [name]: value });
        setExam((exam) => handleExamSectionData(exam, { name: value }));
        break;

      case SETTING_PROPERTY_NAMES.IS_SHUFFLE:
        setSettingSectionData({
          ...settingSectionData,
          [name]: currentTarget.checked,
        });
        break;

      // navigation warning
      case SETTING_PROPERTY_NAMES.NAVIGATE_SECTION_WARNING:
      case SETTING_PROPERTY_NAMES.UNANSWERED_WARNING:
        let navigationWarning = settingSectionData?.navigation_warning || [];
        let navigationWarningData;
        if (navigationWarning?.includes(name)) {
          navigationWarningData = {
            navigation_warning: navigationWarning.filter(
              (item) => item !== name
            ),
          };
        } else {
          navigationWarningData = {
            navigation_warning: [...navigationWarning, name],
          };
        }
        setSettingSectionData({
          ...settingSectionData,
          ...navigationWarningData,
        });
        break;

      // item session control
      case SETTING_PROPERTY_NAMES.MAX_TIME_ANSWER:
      case SETTING_PROPERTY_NAMES.IS_SKIP:
      case SETTING_PROPERTY_NAMES.IS_MIN_ANSWER:
        let sectionSessionControls =
          settingSectionData?.item_session_controls || {};
        let sectionSessionControlsData;
        if (type === "checkbox") {
          sectionSessionControlsData = {
            ...sectionSessionControls,
            [name]: currentTarget.checked,
          };
        }

        if (type === "number") {
          sectionSessionControlsData = {
            ...sectionSessionControls,
            [name]: Number(value),
          };
        }

        setSettingSectionData({
          ...settingSectionData,
          item_session_controls: { ...sectionSessionControlsData },
        });
        break;
    }
  };

  const handleSubmitUpdate = () => {
    setExam((exam) =>
      handleExamSectionData(exam, { setting_properties: settingSectionData })
    );

    swalSuccess();
  };

  useEffect(() => {
    if (
      isEmptyObj(exam) ||
      settingPropertyOrders.orderType !== TYPE_OF_EXAM_DISPLAY.SECTION
    )
      return;

    const findExamSection = exam.exam_parts
      .find((examPart) => examPart.order === settingPropertyOrders.orderPart)
      ?.exam_sections.find(
        (examSection) =>
          examSection.order === settingPropertyOrders.orderSection
      );

    if (findExamSection) {
      setExamSection(findExamSection);
      setSettingSectionData(findExamSection.setting_properties);
    }
  }, [settingPropertyOrders]);

  return (
    <>
      <div className="flex-col  bg-[#EFF1F0] h-auto border-[#BEBEBE] font-[400] min-w-[320px]">
        <div className="w-full flex-col gap-[10px] p-[20px] bg-[#F4F4F4] ">
          <div className="text-[14px] pb-[15px]">{examSection.name}</div>
          <ItemSettingInput
            label="タイトル"
            messageDetail={DETAIL_SECTION_MESSAGES.NAME}
            classNameLabel="basis-[28%]"
            handleChange={handleChangeValue}
            name="name"
            type="text"
            className="basis-[60%] rounded-none"
            value={examSection?.name}
            placeholder={examSection?.name}
            error={error}
          />
        </div>

        <LayoutSetting
          icon={<HesaWaringOutline className="w-[24px] h-[24px]" />}
          label={SETTING_FEATURES.NAVIGATION_WARNING}
        >
          <CheckboxProperties
            selectedValues={settingSectionData?.navigation_warning || []}
            arrCheckbox={SECTION_SETTING_ALERTS}
            handleChangeValue={handleChangeValue}
          />
        </LayoutSetting>

        <LayoutSetting
          icon={<IoIosCheckmarkCircleOutline className="w-[24px] h-[24px]" />}
          label={SETTING_FEATURES.ITEM_SESSION_CONTROL}
        >
          <div className={` w-full flex-col gap-[10px] p-[20px] bg-[#F4F4F4] `}>
            <ItemSettingInput
              label="最大試行回数"
              messageDetail={DETAIL_SECTION_MESSAGES.MAX_TIMES_ANSWER}
              handleChange={handleChangeValue}
              name={SETTING_PROPERTY_NAMES.MAX_TIME_ANSWER}
              value={
                settingSectionData?.item_session_controls?.max_times_answer || 0
              }
              min={0}
              max={10}
              type="number"
              className="rounded-none w-[40px] pl-[4px] text-[14px]"
            />
            <ItemSettingInput
              label="スキップを許可"
              messageDetail={DETAIL_SECTION_MESSAGES.IS_SKIP}
              handleChange={handleChangeValue}
              name={SETTING_PROPERTY_NAMES.IS_SKIP}
              type="checkbox"
              className="input_check_red checked:ring-transparent focus:ring-transparent cursor-pointer w-[24px] h-[24px] rounded-[5px]"
              checked={
                settingSectionData?.item_session_controls?.is_skip || false
              }
            />
          </div>
        </LayoutSetting>

        <LayoutSetting
          icon={<SufferIcon />}
          label={SETTING_FEATURES.ORDER_OF_QUESTIONS}
        >
          <div className={` w-full flex-col gap-[10px] p-[20px] bg-[#F4F4F4] `}>
            <ItemSettingInput
              label="シャッフル"
              messageDetail={DETAIL_SECTION_MESSAGES.IS_SHUFFLE}
              handleChange={handleChangeValue}
              name={SETTING_PROPERTY_NAMES.IS_SHUFFLE}
              type="checkbox"
              className="input_check_red checked:ring-transparent focus:ring-transparent cursor-pointer w-[24px] h-[24px] rounded-[5px]"
              checked={settingSectionData?.is_shuffle || false}
            />
          </div>
        </LayoutSetting>

        <ButtonSubmit error={error} handleSubmitUpdate={handleSubmitUpdate} />
      </div>
    </>
  );
};
export default SectionSetting;
