import React from "react";

const FileTitle = ({folderTitle} : {folderTitle?: string}) => {
  return (
    <div>
      <div className="mt-[5px] pl-[5px] mb-[8px]">選択中のカテゴリ</div>
      <div className="min-h-[50px] w-full rounded-[5px] text-[18px] font-[700] leading-[100%] bg-primary text-white flex justify-between items-center">
        <div className="pl-[21px]">{folderTitle || ""}</div>
      </div>
    </div>
  );
};

export default FileTitle;
