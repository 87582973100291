import React, { useCallback, useEffect, useState } from "react";
import ChildrenStep from "./RegisterExaminationComponent/ChildrenStep";
import {
  INDEX_STEP_REGISTER_EXAMINATIONS,
  TITLE_STEP_REGISTER_EXAMINATIONS,
} from "../../../services/constants/candidate/registerExamination";
import { useExaminationRegisterContext } from "../../../context/Candidate/ExaminationRegisterContext";
import { EXAMINATION_FORMAT } from "../../../services/constants/admin/pages/examination";
import { IoPrintOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PATH_CANDIDATE_HOME } from "../../../services/constants/route/router";
import ItemExaminationRegisterInfo from "./RegisterExaminationComponent/ItemExaminationRegisterInfo";
import { User } from "../../../types/user";
import { useStorage } from "../../../hooks/useStorage";
import {
  decodeHTMLEntities,
  formatNumberWithCommas,
  paramizeObject,
} from "../../../services/helpers/parseData";
import { formatDateTime } from "../../../services/helpers/formatTime";
import {
  PAYMENT_TYPE,
  TAX,
  TYPE_DATE_FORMAT,
} from "../../../services/constants/globals";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { isEmptyObj } from "../../../services/helpers/etc";
import {
  ExaminationVenueType,
  RegisterExaminationType,
} from "../../../types/candidate/registerExamination";
import { Examination } from "../../../types/admin/examination";
import { getTimeBeforeEntryTime } from "../../../services/utils/candidate/registerExamination";
import { PAYMENT_STATUS } from "../../../services/constants/candidate/payment";
import RefreshDouble from "../../commons/icons/RefreshDouble";
import classNames from "classnames";

const RegisterExaminationStep4 = () => {
  const { getLocalStorage } = useStorage();
  const user: User = getLocalStorage("user");
  const { searchParamsObject } = useObjectRoutes();
  const navigate = useNavigate();
  const [examination, setExamination] = useState<Examination>({});
  const [examinationVenue, setExaminationVenue] =
    useState<ExaminationVenueType>({});
  const [registerExamination, setRegisterExamination] =
    useState<RegisterExaminationType>({});

  const PrintButton = () => {
    if (isEmptyObj(searchParamsObject)) return;

    navigate(
      `${PATH_CANDIDATE_HOME.PRINT_REGISTER_EXAMINATION}?${paramizeObject(
        searchParamsObject
      )}`
    );
  };

  const titlePaymentStatus = useCallback(() => {
    if (registerExamination.payment_status === PAYMENT_STATUS.PAID) {
      return "予約が完了しました";
    }

    if (registerExamination.payment_status === PAYMENT_STATUS.WAITTING_PAID) {
      return "入金待ちです";
    }

    return "";
  }, [registerExamination.payment_status]);

  const fetchRegisterExaminationInfo = async () => {
    await request.get(
      `${API.REGISTER_EXAMINATION.GET_DETAIL}?${paramizeObject(
        searchParamsObject
      )}`,
      (data: RegisterExaminationType) => {
        setRegisterExamination(data);

        if (data.examination && !isEmptyObj(data.examination)) {
          setExamination(data.examination);
        }

        if (data.exam_venue && !isEmptyObj(data.exam_venue)) {
          setExaminationVenue(data.exam_venue);
        }
      }
    );
  };

  useEffect(() => {
    if (isEmptyObj(searchParamsObject)) return;

    fetchRegisterExaminationInfo();
  }, []);

  return (
    <div>
      <div className="h-full min-w-[155px] text-[black] flex justify-start items-center mb-[10px] mt-[20px] gap-[20px]">
        <div className="w-[30px] h-[30px] bg-primary-synch text-[22px] text-[white] flex justify-center items-center">
          {INDEX_STEP_REGISTER_EXAMINATIONS.STEP_4}
        </div>
        {TITLE_STEP_REGISTER_EXAMINATIONS.STEP4}
      </div>
      <div>
        <ChildrenStep
          step={1}
          title="予約を受け付けました。お支払い状況をご確認ください。"
        >
          <div
            className={classNames("w-full flex flex-col rounded-[10px]", {
              "border-[1px] border-[#BEBEBE] px-[40px] py-[30px] gap-y-[30px]":
                registerExamination.payment_status !== PAYMENT_STATUS.UNPAID,
            })}
          >
            <div className="w-fit text-[16px] border-primary-synch text-primary-synch border-b-[2px] ">
              {titlePaymentStatus()}
            </div>
            <div className="text-[14px]">
              {registerExamination?.payment_method_type &&
              registerExamination?.payment_method_type ===
                PAYMENT_TYPE.KONBINI ? (
                <>
                  {registerExamination.payment_status ===
                  PAYMENT_STATUS.UNPAID ? (
                    <button
                      className="px-[15px] py-[8px] bg-primary-synch text-[16] text-white leading-[24px] flex items-center gap-x-[5px] rounded-[5px]"
                      onClick={fetchRegisterExaminationInfo}
                    >
                      <RefreshDouble />
                      <span>情報を更新</span>
                    </button>
                  ) : (
                    <>
                      <p>{`${user.email}へメールを送りしましたので、ご確認ください。`}</p>
                      <p>
                        お支払い番号をお控えのうえ、店頭でお支払いください。
                        支払い完了後に受験可能です
                      </p>
                      <p>以下の内容で受験予約の受付が完了しました</p>
                    </>
                  )}
                </>
              ) : (
                <p>以下の内容で受験予約の受付が完了しました</p>
              )}
            </div>
          </div>

          <div>
            <p className="py-[20px] text-[16px] font-bold">予約内容</p>

            <div className="border-y-[1px] w-full border-[#BEBEBE] flex flex-col">
              <ItemExaminationRegisterInfo title="受験者名">
                <>
                  {user.first_name}　{user.last_name}
                </>
              </ItemExaminationRegisterInfo>
              <ItemExaminationRegisterInfo title="メールアドレス">
                <>{user.email}</>
              </ItemExaminationRegisterInfo>
              <ItemExaminationRegisterInfo title="試験">
                <>{registerExamination.exam_name}</>
              </ItemExaminationRegisterInfo>
              <ItemExaminationRegisterInfo title="試験名">
                <>
                  {examination.type}{" "}
                  {Number(registerExamination.time) > 0 &&
                    `(${registerExamination.time}分)`}
                </>
              </ItemExaminationRegisterInfo>
              <ItemExaminationRegisterInfo title="試験番号">
                <>{examination.code}</>
              </ItemExaminationRegisterInfo>
              <ItemExaminationRegisterInfo title="試験料金（税込）">
                <>
                  {examination.is_free
                    ? "無料"
                    : formatNumberWithCommas(
                        Math.floor(Number(examination.price) * TAX)
                      ) + "円"}
                  {registerExamination.payment_method_type ===
                    PAYMENT_TYPE.KONBINI &&
                    (registerExamination.payment_status ===
                      PAYMENT_STATUS.UNPAID ||
                      registerExamination.payment_status ===
                        PAYMENT_STATUS.WAITTING_PAID) &&
                    "（決済待ち中）"}
                </>
              </ItemExaminationRegisterInfo>
              {registerExamination.exam_format === EXAMINATION_FORMAT.CBT ? (
                <ItemExaminationRegisterInfo title="会場">
                  <div className="text-[16px]">
                    <p>{examinationVenue.name}</p>
                    <div className="py-[20px]">
                      {examinationVenue.postal_code?.first &&
                        examinationVenue.postal_code?.last && (
                          <p>
                            〒{examinationVenue.postal_code.first}-
                            {examinationVenue.postal_code.last}
                          </p>
                        )}
                      <p className="text-[14px] "></p>
                      <p className="text-[14px] mb-[20px]">
                        {examinationVenue.address?.prefecture},{" "}
                        {examinationVenue.address?.city},{" "}
                        {examinationVenue.address?.area},{" "}
                        {examinationVenue.address?.other}
                      </p>
                    </div>
                    <p>
                      これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。
                    </p>
                  </div>
                </ItemExaminationRegisterInfo>
              ) : (
                <></>
              )}
              <ItemExaminationRegisterInfo title="試験日">
                <span className="text-[16px]">
                  {formatDateTime(
                    registerExamination?.exam_full_date || "",
                    TYPE_DATE_FORMAT.SHORT_DEFAULT
                  )}
                </span>
              </ItemExaminationRegisterInfo>
              {registerExamination.exam_format === EXAMINATION_FORMAT.CBT && (
                <ItemExaminationRegisterInfo title="入室時間">
                  <span className="text-[16px]">
                    {getTimeBeforeEntryTime(
                      registerExamination.exam_time || "",
                      examination.entry_time || 0
                    )}{" "}
                    ~ {registerExamination.exam_time?.slice(0, 5)}
                  </span>
                </ItemExaminationRegisterInfo>
              )}
              <ItemExaminationRegisterInfo title="試験開始時間">
                <span className="text-[16px]">
                  {registerExamination.exam_time?.slice(0, 5)}
                </span>
              </ItemExaminationRegisterInfo>
              {registerExamination.exam_format === EXAMINATION_FORMAT.CBT && (
                <ItemExaminationRegisterInfo title="地図">
                  <div
                    className="px-[6px] py-[20px] text-left basis-[60%]"
                    dangerouslySetInnerHTML={{
                      __html: decodeHTMLEntities(examinationVenue.map_embed),
                    }}
                  ></div>
                </ItemExaminationRegisterInfo>
              )}
            </div>
          </div>
        </ChildrenStep>
      </div>
      <div className="w-full flex justify-end items-center">
        <button
          className="w-[140px] h-[40px] flex justify-center items-center gap-[10px] bg-[#BEBEBE] rounded-[5px]"
          onClick={PrintButton}
        >
          <IoPrintOutline className="w-[24px] h-[24px]" />
          印刷する
        </button>
      </div>
    </div>
  );
};

export default RegisterExaminationStep4;
