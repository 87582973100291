/* eslint-disable react-hooks/exhaustive-deps */
import { isUsableArr } from '../../../../../../../services/helpers/etc';
import { decodeHTMLEntities, shuffle } from '../../../../../../../services/helpers/parseData';
import {
  generateListStyleChoice,
  isListBoxStyle,
} from '../../../../../../../services/utils/admin/question';
import { ChoiceProblem } from '../../../../../../../types/admin/question';
import { RenderDroppedQuestionTypeProps } from '../../DroppedQuestionType';
import { useMemo } from 'react';

type ChoiceQuestionProps = {
  problemPreview?: ChoiceProblem;
} & Omit<RenderDroppedQuestionTypeProps, 'draggedName' | 'problemPreview'>;

const ChoiceQuestion = ({ problemPreview }: ChoiceQuestionProps) => {
  const currentIssues = useMemo(
    () =>
      problemPreview?.is_shuffled ? shuffle(problemPreview?.issues || []) : problemPreview?.issues,
    [problemPreview?.updated_at],
  );

  return (
    <div className="text-secondary-dark">
      <div className="">
        <div
          dangerouslySetInnerHTML={{
            __html: decodeHTMLEntities(problemPreview?.title || ''),
          }}
        ></div>
      </div>
      <ul
        className={`!pl-[10px] ${generateListStyleChoice(problemPreview?.list_style)} ${
          !problemPreview?.is_vertical_arrange ? 'flex flex-wrap gap-[10px]' : ''
        }`}
      >
        {isUsableArr(currentIssues) &&
          currentIssues?.map((choice, index) => (
            <div className="flex justify-start gap-[15px] items-center my-[10px]" key={index}>
              <input
                type="checkbox"
                name="choice"
                id={`${choice.id}`}
                className={`${!isListBoxStyle(problemPreview?.list_style) ? 'mr-[20px]' : ''}`}
                disabled
              />
              <li
                className={`mt-[-5px] ${
                  !problemPreview?.is_vertical_arrange ? 'w-[150px]' : 'w-[80%]'
                }`}
              >
                <label htmlFor={`${choice.id}`} className="text-[12px] leading-[100%]">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeHTMLEntities(choice.label),
                    }}
                  ></div>
                </label>
              </li>
            </div>
          ))}
      </ul>
    </div>
  );
};

export default ChoiceQuestion;
