import React from "react";

const Resource = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props.color || "#BEBEBE"}
        fillRule="evenodd"
        d="M1.75 18.25V1.75h16.5v16.5H1.75zM1.6.25A1.35 1.35 0 00.25 1.6v16.8c0 .746.604 1.35 1.35 1.35h16.8a1.35 1.35 0 001.35-1.35V1.6A1.35 1.35 0 0018.4.25H1.6zm11.9 4.5a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zm3.25 1.75a3.25 3.25 0 10-6.5 0 3.25 3.25 0 006.5 0zM3.25 7A.75.75 0 014 6.25h4a.75.75 0 010 1.5H4A.75.75 0 013.25 7zM7 4.75a.75.75 0 000-1.5H4a.75.75 0 000 1.5h3zm9.624 11.666a.75.75 0 01-1.04.208l-4.044-2.696-2.072 1.658a.75.75 0 01-.937 0l-5-4a.75.75 0 01.937-1.172L9 14.04l2.031-1.626a.75.75 0 01.885-.038l4.5 3a.75.75 0 01.208 1.04z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Resource;
