import React from 'react';

interface Props {
  result: string;
}

const ResultComponent: React.FC<Props> = ({ result }) => {
  return (
    <div className="w-full max-w-[360px] h-[60px] bg-gray-300 truncate">
      <p className='text-[40px] m-[5px]'>{result}</p>
    </div>
  );
}

export default ResultComponent;
