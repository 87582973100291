import React, { ChangeEvent, useEffect, useState } from "react";
import { WritingProblemTest } from "../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import { useExaminationTestContext } from "../../../../../context/ExaminationTestContext";
import { candidateToolIsOpen } from "../../../../../services/utils/candidate/examinationTest";
import { SETTING_PROPERTY_NAMES } from "../../../../../services/constants/admin/pages/exam";

const WritingQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled
}: {
  questionProblem: WritingProblemTest;
  handleUpdateProblem: (problem: WritingProblemTest) => void;
  isDisabled?: boolean;
}) => {
  const { questionProblems, setQuestionProblems, openingTools } = useExaminationTestContext();
  const { textSize } = useExaminationTestContext();
  const [editingData, setEditingData] = useState<WritingProblemTest>(
    questionProblem as WritingProblemTest
  );

  const handleChangeAnswer = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (
      editingData.charactersAmount &&
      editingData.charactersAmount > 0 &&
      value.length <= editingData.charactersAmount
    ) {
      setEditingData({ ...editingData, [name]: value });
      handleUpdateProblem({ ...editingData, [name]: value });
    }
  };

  const handleMouseUp = () => {
    if(!candidateToolIsOpen(SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT, openingTools)) return;

    const selector = window.getSelection();
    const textSelected = selector?.toString();
    if (!textSelected) return;
    const span = document.createElement("span");
    span.setAttribute("style", "background-color: yellow; display:inline;");
    span.innerText = textSelected;
    const newText = questionProblem.title?.replace(
      textSelected,
      span.outerHTML
    );

    setQuestionProblems(
      questionProblems.map((question) => {
        if (question.id === questionProblem.id) {
          return { ...question, title: newText };
        }

        return question;
      })
    );
  };

  useEffect(() => {
    if (questionProblem) {
      setEditingData(questionProblem);
    }
  }, [questionProblem]);

  return (
    <div className="w-full bg-white rounded-b-[10px] flex flex-col items-start justify-center gap-x-[5px]">
      <div
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(editingData.title || ""),
        }}
        className="mb-[80px] text-[16px] leading-6 font-[500]"
        onMouseUp={handleMouseUp}
      ></div>
      <textarea
        name="answer_data"
        cols={30}
        rows={editingData.rowsAmount}
        className={`w-full min-h-[200px] border rounded-[5px] p-[10px] text-[${textSize}px] placeholder:text-black placeholder:text-[12px] leading-4`}
        onChange={handleChangeAnswer}
        value={editingData.answer_data || ""}
        placeholder="回答を入力してください"
        disabled={isDisabled}
      ></textarea>
    </div>
  );
};

export default WritingQuestion;
