import { useEffect, useState } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import "../../../../../assests/styles/recordVoice.css";
import { TestTakerRecordProblemTest } from "../../../../../types/admin/question";
import { uploadFileToS3 } from "../../../../../services/utils/upload/s3Upload";
import TestTakerRecord from "../../../../commons/icons/QuestionType/TestTakerRecord";
import UploaderButton from "../../../../admin/Question/AuthoringQuestion/QuestionEditorHandler/QuestionTypes/TestTakerRecordVoiceQuestion/TestTakerRecordVoiceComponent/UploaderButton";
import UploaderTimer from "../../../../admin/Question/AuthoringQuestion/QuestionEditorHandler/QuestionTypes/TestTakerRecordVoiceQuestion/TestTakerRecordVoiceComponent/UploaderTimer";
import { useAuthContext } from "../../../../../context/AuthContext";
import { formattedTimeIntoMiliseconds } from "../../../../../services/helpers/formatTime";
import { request } from "../../../../../services/axios/axios";
import { API } from "../../../../../services/constants/route/api";
import classNames from "classnames";

type TestTakerRecordVoiceUploaderProps = {
  problemData: TestTakerRecordProblemTest;
  actionable?: boolean;
  updateAnswerQuestionVoice: (pathFile: string) => void;
};

const TestTakerRecordVoiceUploader = ({
  problemData,
  actionable = false,
  updateAnswerQuestionVoice,
}: TestTakerRecordVoiceUploaderProps) => {
  const primaryDarkColor: string = "#235FA9";
  const dangerColor: string = "#FA646A";
  const primaryColor: string = "#75A1D3";
  const secondaryColor: string = "#B2B2B2";
  const [recording, setRecording] = useState(true);
  const [color, setColor] = useState<string>(dangerColor);
  const [pause, setPause] = useState<boolean>(true);
  const [isShowRecording, setIsShowRecording] = useState<boolean>(false);
  const recorderControls = useVoiceVisualizer();
  const { recordedBlob, error, audioRef, currentAudioTime, duration } =
    recorderControls;
  const { user } = useAuthContext();
  const [uploadData, setUploadData] = useState<object>({});

  const startRecording = () => {
    if (actionable) return;

    recorderControls.startRecording();
    setIsShowRecording(true);
    setPause(false);
  };

  const stopRecording = () => {
    setColor(primaryDarkColor);
    setRecording(false);
    setPause(true);
    recorderControls.stopRecording();
  };

  const togglePauseResume = () => {
    setPause(!pause);
    recorderControls.togglePauseResume();
  };

  const resetRecord = () => {
    recorderControls.clearCanvas();
    setRecording(true);
    setIsShowRecording(false);
    setColor(dangerColor);
  };

  const handleUploadFile = async (data: object) => {
    await request.post(
      API.EXAMINATION.UPLOAD_FILE_ANSWER,
      data,
      (data) => {
        console.log("Save file success!");
      },
      (errors) => {
        console.log("Handle upload file error: ", errors);
      }
    );
  };

  useEffect(() => {
    if (
      recordedBlob &&
      problemData.upload_folder &&
      problemData.upload_folder.path
    ) {
      const fileName =
        new Date().getTime() + (`_${user.username}` || "") + ".mp3";
      const pathFile = problemData.upload_folder.path + fileName;
      uploadFileToS3(recordedBlob, pathFile);
      updateAnswerQuestionVoice(pathFile);

      const dataSaveFile = {
        resource_folder_id: problemData.upload_folder.id,
        filename: fileName,
        origin_filename: fileName,
        content_type: "mp3",
        size: (recordedBlob.size / (1024 * 1024)).toFixed(2),
        path: pathFile,
        time: formattedTimeIntoMiliseconds(duration, "second"),
      };
      setUploadData(dataSaveFile);
    }
  }, [recordedBlob, error]);

  useEffect(() => {
    if (recordedBlob && uploadData && duration) {
      const newUploadData = {
        ...uploadData,
        time: formattedTimeIntoMiliseconds(duration, "second"),
      };
      handleUploadFile(newUploadData);
    }
  }, [duration, uploadData]);

  return (
    <>
      <div className="flex items-center justify-center gap-x-[30px] mb-[10px]">
        <div
          className={classNames(
            "text-[#235FA9] border border-[#235FA9] rounded-full w-[44px] h-[44px] flex justify-center items-center gap-x-[10px] shadow-[2px_2px_2px_0_rgba(0, 0, 0, 0.10)]",
            {
              "pointer-events-none": actionable,
            }
          )}
        >
          <TestTakerRecord
            className="cursor-pointer"
            onClick={startRecording}
          />
        </div>
      </div>
      {problemData.answer_data && pause && !isShowRecording ? (
        <audio
          src={process.env.REACT_APP_AWS_S3_URL + "/" + problemData.answer_data}
          controls
        ></audio>
      ) : (
        <></>
      )}

      {isShowRecording && (
        <div className="w-full text-white border border-primary px-[30px] py-[15px] rounded-[10px] flex items-center justify-between gap-x-[40px]">
          <UploaderButton
            recording={recording}
            pause={pause}
            color={color}
            recorderControls={recorderControls}
            togglePauseResume={togglePauseResume}
            stopRecording={stopRecording}
            resetRecord={resetRecord}
          />
          <VoiceVisualizer
            ref={audioRef}
            controls={recorderControls}
            isControlPanelShown={false}
            width={363}
            height={103}
            barWidth={6}
            gap={3}
            speed={3}
            rounded={5}
            mainBarColor={primaryColor}
            secondaryBarColor={secondaryColor}
            progressIndicatorClassName="progress_indicator"
            canvasContainerClassName="canvas_container"
            isProgressIndicatorTimeShown={true}
          />
          <UploaderTimer
            recording={recording}
            pause={pause}
            problemData={problemData}
            recorderControls={recorderControls}
            stopRecording={stopRecording}
          />
        </div>
      )}
    </>
  );
};

export default TestTakerRecordVoiceUploader;
