import React, { useContext, useState } from "react";
import { ChildrenWithProps } from "../../types/globals";
import {
  CurrentStep,
  ExaminationVenueType,
  RegisterExaminationDataType,
} from "../../types/candidate/registerExamination";
import { TITLE_STEP_REGISTER_EXAMINATIONS } from "../../services/constants/candidate/registerExamination";
import { ExamName } from "../../types/admin/examName";
import { Examination } from "../../types/admin/examination";

type CBTFilterSearchType = {
  region?: string | null;
  prefecture?: string | null;
  start_date?: Date | string | null;
  end_date?: Date | string | null;
};

interface ExaminationRegisterContextType {
  filterSearchCBT: CBTFilterSearchType;
  setFilterSearchCBT: React.Dispatch<React.SetStateAction<CBTFilterSearchType>>;
  currentStep: CurrentStep;
  setStepCurrent: React.Dispatch<React.SetStateAction<CurrentStep>>;
  registerExaminationData: RegisterExaminationDataType;
  setRegisterExaminationData: React.Dispatch<
    React.SetStateAction<RegisterExaminationDataType>
  >;
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  examinationType: string;
  setExaminationType: React.Dispatch<React.SetStateAction<string>>;
  examinationVenues: ExaminationVenueType[];
  setExaminationVenues: React.Dispatch<
    React.SetStateAction<ExaminationVenueType[]>
  >;
  examinationSelected: Examination;
  setExaminationSelected: React.Dispatch<React.SetStateAction<Examination>>;
  examinationVenueSelected: ExaminationVenueType;
  setExaminationVenueSelected: (examinationVenueId: number) => void;
  examinationNameSelected: ExamName;
  setExaminationNameSelected: React.Dispatch<React.SetStateAction<ExamName>>;
}

const ExaminationRegisterContext =
  React.createContext<ExaminationRegisterContextType>({
    filterSearchCBT: {},
    setFilterSearchCBT: () => {},
    currentStep: { step: 1, name: TITLE_STEP_REGISTER_EXAMINATIONS.STEP1 },
    setStepCurrent: () => {},
    registerExaminationData: {},
    setRegisterExaminationData: () => {},
    errors: [],
    setErrors: () => {},
    examinationType: "",
    setExaminationType: () => {},
    examinationVenues: [],
    setExaminationVenues: () => {},
    examinationVenueSelected: {},
    setExaminationVenueSelected: () => {},
    examinationNameSelected: {},
    setExaminationNameSelected: () => {},
    examinationSelected: {},
    setExaminationSelected: () => {},
  });

const ExaminationRegisterProvider = ({ children }: ChildrenWithProps) => {
  const [filterSearchCBT, setFilterSearchCBT] = useState<CBTFilterSearchType>(
    {}
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [examinationType, setExaminationType] = useState<string>("");
  const [currentStep, setStepCurrent] = useState<CurrentStep>({
    step: 1,
    name: TITLE_STEP_REGISTER_EXAMINATIONS.STEP1,
  });
  const [examinationVenues, setExaminationVenues] = useState<
    ExaminationVenueType[]
  >([]);
  const [registerExaminationData, setRegisterExaminationData] =
    useState<RegisterExaminationDataType>({});

  const [examinationVenueSelected, _setExaminationVenueSelected] =
    useState<ExaminationVenueType>({});
  const [examinationNameSelected, setExaminationNameSelected] =
    useState<ExamName>({});
  const [examinationSelected, setExaminationSelected] = useState<ExamName>({});

  const setExaminationVenueSelected = (examinationVenueId: number) => {
    const findExaminationVenue = examinationVenues.find(
      (examinationVenue) => examinationVenue.id === examinationVenueId
    );
    if (!findExaminationVenue) return;

    _setExaminationVenueSelected(findExaminationVenue);
  };

  return (
    <ExaminationRegisterContext.Provider
      value={{
        filterSearchCBT,
        setFilterSearchCBT,
        currentStep,
        setStepCurrent,
        registerExaminationData,
        setRegisterExaminationData,
        errors,
        setErrors,
        examinationType,
        setExaminationType,
        examinationVenues,
        setExaminationVenues,
        examinationVenueSelected,
        setExaminationVenueSelected,
        examinationNameSelected,
        setExaminationNameSelected,
        examinationSelected,
        setExaminationSelected,
      }}
    >
      {children}
    </ExaminationRegisterContext.Provider>
  );
};

export default ExaminationRegisterProvider;
export const useExaminationRegisterContext = () =>
  useContext(ExaminationRegisterContext);
