import { getAdminPath, getCandidatePath, getPath } from '../../helpers/router';

const ROUTE = {
  //home
  HOME: '/',
  DOMAIN_LOGIN: '/site-login',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_EMAIL: '/verify-email',
  UPDATE_PROFILE: '/update-profile',
  GG_CALLBACK: '/google.callback',
  REDIRECT: '/redirect-to-subdomain',
  RESEND_VERIFY: 'resend-verify-email',
  SUBMIT_FORGOT_PASSWORD_DONE: '/email-has-been-sent',
  UPDATE_NEW_PWD: '/update-new-pwd',
  PALETTE_LOGIN: '/palette-login',
  MY_PAGE: {
    DEFAULT: '/home',
    COURSE_LIST: 'course-list',
    STUDY_HISTORY: 'study-history',
  },
  // CANDIDATE

  //my-page
  MY_PAGE_PREFIX: 'home',
  //courses
  COURSES: {
    DEFAULT: 'courses',
    LECTURES: 'lectures',
    UNITS: 'units',
    DETAIL: 'units/:id',
    EXECUTE_TEST: 'execute-test',
    CONTINUE_TEST: 'continue-test',
    EXPLANATION: 'explanation',
  },
  STUDY_HISTORY: 'study-history',

  //profile
  PROFILE: {
    DEFAULT: 'profile',
    MAIL_CHANGING: 'mail-changing',
    PASSWORD_CHANGING: 'password-changing',
  },

  STUDY_TOOL: {
    DEFAULT: 'study-tool',
    SCHEDULE: {
      DEFAULT: 'schedule',
      CALENDAR: {
        DEFAULT: 'calendar',
      },
      EVENT: {
        DEFAULT: 'event',
      },
    },

    REPORT: {
      DEFAULT: 'report',
    },

    REVISION_REVIEW: {
      DEFAULT: 'revision-review',
    },

    MEMO: {
      DEFAULT: 'memo',
      CREATE: 'create',
      EDIT: 'edit',
      DETAIL: 'detail',
    },
  },

  // ADMIN
  ADMIN_PREFIX: 'admin',
  //home
  HOME_MANAGEMENT: {
    DEFAULT: 'home',
  },
  //group
  GROUP_MANAGEMENT: {
    DEFAULT: 'group',
    SETTING: 'setting',
    DETAIL: 'detail',
  },
  //candidate
  CANDIDATE_MANAGEMENT: {
    DEFAULT: 'candidate',
    SETTING: 'setting',
    CREATE: 'create',
    CSV: 'csv',
    DETAILS: 'details',
  },
  //course
  COURSE_MANAGEMENT: {
    DEFAULT: 'course',
    COURSE: 'course',
    LECTURE: 'lecture',
    CHAPTER: 'chapter',
    BULK_REGISTRATION: 'bulk-registration',
  },
  //folder
  FOLDER_MANAGEMENT: {
    DEFAULT: 'resource-management',
    FOLDER: 'folder',
    FILE: 'file',
  },
  //material
  MATERIAL_MANAGEMENT: {
    DEFAULT: 'material',
    UNIT: 'unit',
    UPSERT: 'upsert',
  },
  //question
  QUESTION_MANAGEMENT: {
    DEFAULT: 'question',
    CREATE: 'create',
    DETAIL: 'detail',
    EDIT: 'edit',
    AUTHORING: 'authoring',
  },
  //question resource
  QUESTION_RESOURCE_MANAGEMENT: {
    DEFAULT: 'question-resource',
  },

  //exam
  EXAM: {
    DEFAULT: 'exam',
    CREATE: 'create',
    AUTHORING: 'authoring',
    EXAM_PART: 'exam-part',
    EXAM_ITEM: 'exam-item'
  },

  EXAM_NAME: {
    DEFAULT: 'exam-name',
    CREATE: 'create-exam-name',
    UPDATE: 'update-exam-name',
    LIST: 'list-exam-name',
    DELETE: 'delete-exam-name'
  },

  EXAM_MANAGEMENT: {
    DEFAULT: 'exam-venue',
    CREATE: 'create',
    VENUE_LIST: 'list',
    DETAIL: 'detail',
  },

  EXAMINATION_MANAGEMENT: {
    DEFAULT: 'examination',
    LIST: 'list',
    CREATE: 'create',
    DETAIL: 'detail',
  },

  //Candidate
  CANDIDATE_PREFIX: 'home',

  EXAM_REGISTER: {
    DEFAULT: 'register-examination',
    PRINT_EXAMINATION_REGISTER: 'print'
  },

  EXAMINATION: {
    DEFAULT: 'examination',
    DETAIL: 'detail',
    ENTER_CODE: 'cbt-enter-code',
    PART_LIST: 'test-part-list',
    TEST_PART: 'test-part'
  },

  EXAM_PARTICIPATE: {
    DEFAULT: 'exam-participate',
    LIST: 'list',
    CANDIDATE: 'candidates'
  },

  EXAM_RESULT: {
    DEFAULT: 'exam-result',
    EXAM_LIST: 'exams',
    RESULT_LIST: 'results',
    RESULT_DETAIL: 'detail'
  }
};

const PATH_ADMIN_HOME = {
  DEFAULT: getAdminPath(ROUTE.HOME_MANAGEMENT.DEFAULT),
};


//FULL_PATH
const PATH_ADMIN_COURSE = {
  DEFAULT: getAdminPath(ROUTE.COURSE_MANAGEMENT.DEFAULT),
  COURSE_REGISTRATION: getAdminPath(
    ROUTE.COURSE_MANAGEMENT.DEFAULT,
    ROUTE.COURSE_MANAGEMENT.COURSE,
  ),
  LECTURE_REGISTRATION: getAdminPath(
    ROUTE.COURSE_MANAGEMENT.DEFAULT,
    ROUTE.COURSE_MANAGEMENT.LECTURE,
  ),
  CHAPTER_REGISTRATION: getAdminPath(
    ROUTE.COURSE_MANAGEMENT.DEFAULT,
    ROUTE.COURSE_MANAGEMENT.CHAPTER,
  ),
  BULK_REGISTRATION: getAdminPath(
    ROUTE.COURSE_MANAGEMENT.DEFAULT,
    ROUTE.COURSE_MANAGEMENT.BULK_REGISTRATION,
  ),
};

const PATH_ADMIN_FOLDER = {
  FOLDER: getAdminPath(ROUTE.FOLDER_MANAGEMENT.DEFAULT, ROUTE.FOLDER_MANAGEMENT.FOLDER),
  FILE: getAdminPath(ROUTE.FOLDER_MANAGEMENT.DEFAULT, ROUTE.FOLDER_MANAGEMENT.FILE),
  FOLDER_FILE: getAdminPath(
    ROUTE.FOLDER_MANAGEMENT.DEFAULT,
    ROUTE.FOLDER_MANAGEMENT.FOLDER,
    ROUTE.FOLDER_MANAGEMENT.FILE,
  ),
  EMBED_FILE: getAdminPath(ROUTE.FOLDER_MANAGEMENT.DEFAULT, ROUTE.FOLDER_MANAGEMENT.FILE),
  DEFAULT: getAdminPath(ROUTE.FOLDER_MANAGEMENT.DEFAULT),
};

const PATH_ADMIN_LECTURE = {
  DEFAULT: getAdminPath(ROUTE.COURSE_MANAGEMENT.LECTURE),
};

const PATH_ADMIN_CHAPTER = {
  DEFAULT: getAdminPath(ROUTE.COURSE_MANAGEMENT.CHAPTER),
};

const PATH_ADMIN_UNIT = {
  DEFAULT: getAdminPath(ROUTE.MATERIAL_MANAGEMENT.UNIT),
};

const PATH_ADMIN_GROUP = {
  DEFAULT: getAdminPath(ROUTE.GROUP_MANAGEMENT.DEFAULT),
  MANAGEMENT: getAdminPath(ROUTE.GROUP_MANAGEMENT.DEFAULT, ROUTE.GROUP_MANAGEMENT.DETAIL),
  SETTING: getAdminPath(ROUTE.GROUP_MANAGEMENT.DEFAULT, ROUTE.GROUP_MANAGEMENT.SETTING),
};

const PATH_ADMIN_CANDIDATE = {
  DEFAULT: getAdminPath(ROUTE.CANDIDATE_MANAGEMENT.DEFAULT),
  CREATE: getAdminPath(ROUTE.CANDIDATE_MANAGEMENT.DEFAULT, ROUTE.CANDIDATE_MANAGEMENT.CREATE),
  // Tab hidden for future phases
  // CSV: getAdminPath(
  //   ROUTE.CANDIDATE_MANAGEMENT.DEFAULT,
  //   ROUTE.CANDIDATE_MANAGEMENT.CSV
  // ),
  // SETTING: getAdminPath(
  //   ROUTE.CANDIDATE_MANAGEMENT.DEFAULT,
  //   ROUTE.CANDIDATE_MANAGEMENT.SETTING
  // ),
};

const PATH_ADMIN_QUESTION = {
  DEFAULT: getAdminPath(ROUTE.QUESTION_MANAGEMENT.DEFAULT),
  CREATE: getAdminPath(ROUTE.QUESTION_MANAGEMENT.DEFAULT, ROUTE.QUESTION_MANAGEMENT.CREATE),
  DETAIL: getAdminPath(ROUTE.QUESTION_MANAGEMENT.DEFAULT, ROUTE.QUESTION_MANAGEMENT.DETAIL),
  EDIT: getAdminPath(ROUTE.QUESTION_MANAGEMENT.DEFAULT, ROUTE.QUESTION_MANAGEMENT.EDIT),
  AUTHORING: getAdminPath(ROUTE.QUESTION_MANAGEMENT.DEFAULT, ROUTE.QUESTION_MANAGEMENT.AUTHORING),
};

const PATH_ADMIN_QUESTION_RESOURCE = {
  DEFAULT: getAdminPath(ROUTE.QUESTION_RESOURCE_MANAGEMENT.DEFAULT),
};

const PATH_MYPAGE_COURSES = {
  DEFAULT: getPath(ROUTE.MY_PAGE_PREFIX, ROUTE.COURSES.DEFAULT),
  LECTURES: getAdminPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.LECTURES),
};

const PATH_ADMIN_MATERIAL = {
  DEFAULT: getAdminPath(ROUTE.MATERIAL_MANAGEMENT.DEFAULT),
  UNIT: getAdminPath(ROUTE.MATERIAL_MANAGEMENT.DEFAULT, ROUTE.MATERIAL_MANAGEMENT.UNIT),
  UNIT_UPSERT: getAdminPath(
    ROUTE.MATERIAL_MANAGEMENT.DEFAULT,
    ROUTE.MATERIAL_MANAGEMENT.UNIT,
    ROUTE.MATERIAL_MANAGEMENT.UPSERT,
  ),
};

const PATH_ADMIN_EXAM = {
  DEFAULT: getAdminPath(ROUTE.EXAM.DEFAULT),
  EXAM_PART: getAdminPath(ROUTE.EXAM.EXAM_PART),
  CREATE: getAdminPath(ROUTE.EXAM.DEFAULT, ROUTE.EXAM.CREATE),
  AUTHORING: getAdminPath(ROUTE.EXAM.DEFAULT, ROUTE.EXAM.AUTHORING)
};

const PATH_ADMIN_EXAM_ITEM = {
  DEFAULT: getAdminPath(ROUTE.EXAM.EXAM_ITEM)
}

const PATH_ADMIN_EXAM_VENUE = {
  DEFAULT: getAdminPath(ROUTE.EXAM_MANAGEMENT.DEFAULT),
  // EXAM_LIST: "",
  // ADD_NEW_EXAM: "",
  VENUE_LIST: getAdminPath(ROUTE.EXAM_MANAGEMENT.DEFAULT, ROUTE.EXAM_MANAGEMENT.VENUE_LIST),
  ADD_NEW_VENUE: getAdminPath(ROUTE.EXAM_MANAGEMENT.DEFAULT, ROUTE.EXAM_MANAGEMENT.CREATE),
  DETAIL_VENUE: getAdminPath(ROUTE.EXAM_MANAGEMENT.DEFAULT, ROUTE.EXAM_MANAGEMENT.DETAIL),

}

const PATH_ADMIN_EXAM_NAME = {
  LIST: getAdminPath(ROUTE.EXAM_NAME.DEFAULT, ROUTE.EXAM_NAME.LIST),
  CREATE: getAdminPath(ROUTE.EXAM_NAME.DEFAULT, ROUTE.EXAM_NAME.CREATE),
  UPDATE: getAdminPath(ROUTE.EXAM_NAME.DEFAULT, ROUTE.EXAM_NAME.UPDATE),
  DELETE: getAdminPath(ROUTE.EXAM_NAME.DEFAULT, ROUTE.EXAM_NAME.DELETE),
}

const PATH_ADMIN_EXAMINATION = {
  DEFAULT: getAdminPath(ROUTE.EXAMINATION_MANAGEMENT.DEFAULT),
  LIST: getAdminPath(ROUTE.EXAMINATION_MANAGEMENT.DEFAULT, ROUTE.EXAMINATION_MANAGEMENT.LIST),
  CREATE: getAdminPath(ROUTE.EXAMINATION_MANAGEMENT.DEFAULT, ROUTE.EXAMINATION_MANAGEMENT.CREATE),
  DETAIL: getAdminPath(ROUTE.EXAMINATION_MANAGEMENT.DEFAULT, ROUTE.EXAMINATION_MANAGEMENT.DETAIL),
}

const PATH_ADMIN_EXAM_PARTICIPATE = {
  DEFAULT: getAdminPath(ROUTE.EXAM_PARTICIPATE.DEFAULT),
  LIST: getAdminPath(ROUTE.EXAM_PARTICIPATE.DEFAULT, ROUTE.EXAM_PARTICIPATE.LIST),
  CANDIDATE: getAdminPath(ROUTE.EXAM_PARTICIPATE.DEFAULT, ROUTE.EXAM_PARTICIPATE.CANDIDATE)
}

const PATH_ADMIN_EXAM_RESULT = {
  DEFAULT: getAdminPath(ROUTE.EXAM_RESULT.DEFAULT),
  EXAM_LIST: getAdminPath(ROUTE.EXAM_RESULT.DEFAULT, ROUTE.EXAM_RESULT.EXAM_LIST),
  EXAM_RESULT_LIST: getAdminPath(ROUTE.EXAM_RESULT.DEFAULT, ROUTE.EXAM.DEFAULT),
  // EXAM_RESULT_DETAIL: getAdminPath(ROUTE.EXAM_RESULT.DEFAULT, ROUTE.EXAM_RESULT.RESULT_LIST, ROUTE.EXAM_RESULT.RESULT_DETAIL)
}

// Candidate
const PATH_COURSE = {
  DEFAULT: getPath(ROUTE.COURSES.DEFAULT),
  EXECUTE_TEST: getPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.EXECUTE_TEST),
  CONTINUE_TEST: getPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.CONTINUE_TEST),
  LECTURE_DETAIL: getPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.LECTURES),
  UNIT_DETAIL: getPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.LECTURES, ROUTE.COURSES.UNITS),
  EXPLANATION: getPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.EXPLANATION),
};

const PATH_STUDY_TOOL = {
  DEFAULT: getPath(ROUTE.MY_PAGE_PREFIX, ROUTE.STUDY_TOOL.DEFAULT),

  SCHEDULE: {
    DEFAULT: getPath(
      ROUTE.MY_PAGE_PREFIX,
      ROUTE.STUDY_TOOL.DEFAULT,
      ROUTE.STUDY_TOOL.SCHEDULE.DEFAULT,
    ),
    CALENDAR: {
      DEFAULT: getPath(
        ROUTE.MY_PAGE_PREFIX,
        ROUTE.STUDY_TOOL.DEFAULT,
        ROUTE.STUDY_TOOL.SCHEDULE.DEFAULT,
        ROUTE.STUDY_TOOL.SCHEDULE.CALENDAR.DEFAULT,
      ),
    },
    EVENT: {
      DEFAULT: getPath(
        ROUTE.MY_PAGE_PREFIX,
        ROUTE.STUDY_TOOL.DEFAULT,
        ROUTE.STUDY_TOOL.SCHEDULE.DEFAULT,
        ROUTE.STUDY_TOOL.SCHEDULE.EVENT.DEFAULT,
      ),
    },
  },
  MEMO: getPath(ROUTE.MY_PAGE_PREFIX, ROUTE.STUDY_TOOL.DEFAULT, ROUTE.STUDY_TOOL.MEMO.DEFAULT),
  CREATE_MEMO: getPath(
    ROUTE.MY_PAGE_PREFIX,
    ROUTE.STUDY_TOOL.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.CREATE,
  ),
  DETAIL_MEMO: getPath(
    ROUTE.MY_PAGE_PREFIX,
    ROUTE.STUDY_TOOL.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.DETAIL,
  ),
  EDIT_MEMO: getPath(
    ROUTE.MY_PAGE_PREFIX,
    ROUTE.STUDY_TOOL.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.EDIT,
  ),

  // Report
  REPORT: getPath(ROUTE.MY_PAGE_PREFIX, ROUTE.STUDY_TOOL.DEFAULT, ROUTE.STUDY_TOOL.REPORT.DEFAULT),

  // Revision review
  REVISION_REVIEW: getPath(
    ROUTE.MY_PAGE_PREFIX,
    ROUTE.STUDY_TOOL.DEFAULT,
    ROUTE.STUDY_TOOL.REVISION_REVIEW.DEFAULT,
  ),
};

const PATH_CANDIDATE_HOME = {
  DEFAULT: getPath(ROUTE.MY_PAGE_PREFIX),
  REGISTER_EXAMINATION: getCandidatePath(ROUTE.EXAM_REGISTER.DEFAULT),
  PRINT_REGISTER_EXAMINATION: getCandidatePath(ROUTE.EXAM_REGISTER.DEFAULT, ROUTE.EXAM_REGISTER.PRINT_EXAMINATION_REGISTER),
};

const PATH_MY_PROFILE = {
  DEFAULT: getCandidatePath(ROUTE.PROFILE.DEFAULT),
  MAIL_CHANGING: getCandidatePath(ROUTE.PROFILE.DEFAULT, ROUTE.PROFILE.MAIL_CHANGING),
  PASSWORD_CHANGING: getCandidatePath(ROUTE.PROFILE.DEFAULT, ROUTE.PROFILE.PASSWORD_CHANGING),
};

const PATH_EXAMINATION = {
  ENTER_CODE: getPath(ROUTE.EXAMINATION.DEFAULT, ROUTE.EXAMINATION.ENTER_CODE),
  EXAMINATION_VIEW: getPath(ROUTE.EXAMINATION.DEFAULT, ROUTE.EXAMINATION.DETAIL),
  EXAMINATION_PART_LIST: getPath(ROUTE.EXAMINATION.DEFAULT, ROUTE.EXAMINATION.PART_LIST),
  EXAMINATION_TEST_PART: getPath(ROUTE.EXAMINATION.DEFAULT, ROUTE.EXAMINATION.TEST_PART),
}


export {
  ROUTE,
  PATH_ADMIN_COURSE,
  PATH_ADMIN_GROUP,
  PATH_ADMIN_CANDIDATE,
  PATH_MYPAGE_COURSES,
  PATH_ADMIN_MATERIAL,
  PATH_ADMIN_LECTURE,
  PATH_ADMIN_CHAPTER,
  PATH_ADMIN_UNIT,
  PATH_ADMIN_FOLDER,
  PATH_COURSE,
  PATH_STUDY_TOOL,
  PATH_ADMIN_HOME,
  PATH_ADMIN_QUESTION,
  PATH_ADMIN_QUESTION_RESOURCE,
  PATH_ADMIN_EXAM,
  PATH_ADMIN_EXAM_ITEM,
  PATH_ADMIN_EXAM_NAME,
  PATH_ADMIN_EXAM_VENUE,
  PATH_ADMIN_EXAMINATION,
  PATH_ADMIN_EXAM_PARTICIPATE,
  PATH_ADMIN_EXAM_RESULT,
  PATH_CANDIDATE_HOME,
  PATH_MY_PROFILE,
  PATH_EXAMINATION,
};
