import * as React from "react";

const PenEdit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6851 1.85225L15.3974 5.56456C15.6171 5.78423 15.6171 6.14039 15.3974 6.36006L9.07115 12.6863C8.82501 12.9325 8.49116 13.0708 8.14307 13.0708L4.74142 13.0707C4.43076 13.0708 4.17892 12.8189 4.17892 12.5082L4.17892 9.1066C4.17892 8.75851 4.3172 8.42467 4.56334 8.17852L10.8896 1.85225C11.1093 1.63258 11.4654 1.63258 11.6851 1.85225ZM12.8784 7.28814L14.2042 5.96231L11.2874 3.0455L9.96153 4.37132L12.8784 7.28814ZM12.0829 8.08363L9.16604 5.16682L5.35884 8.97402C5.32367 9.00918 5.30392 9.05687 5.30392 9.1066L5.30392 11.9458L8.14307 11.9458C8.1928 11.9458 8.24049 11.926 8.27565 11.8908L12.0829 8.08363ZM2.25 15.1874C1.93934 15.1874 1.6875 15.4392 1.6875 15.7499C1.6875 16.0606 1.93934 16.3124 2.25 16.3124H15.75C16.0607 16.3124 16.3125 16.0606 16.3125 15.7499C16.3125 15.4392 16.0607 15.1874 15.75 15.1874H2.25Z"
      fill={props.color || "white"}
    />
  </svg>
);

export default PenEdit;
