import { useParams } from 'react-router-dom';

const RedirectToSubdomain = () => {
  const subdomain = localStorage.getItem('subdomain');
  let { path } = useParams();

  if (subdomain && subdomain !== window.location.host) {
    window.location.href = window.location.protocol + '//' + subdomain + `/${path}`;
  }

  return <div>Redirecting, please wait ...</div>;
};

export default RedirectToSubdomain;
