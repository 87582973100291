import React, { useEffect, useState } from "react";

type TimerProps = {
  currentTime?: number;
  milliseconds: number;
  isPause: boolean;
  isStop: boolean;
  handleStop: () => void;
};

const Timer = ({
  milliseconds,
  isPause,
  isStop,
  currentTime,
  handleStop,
}: TimerProps) => {
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    if (currentTime) {
      setTime(currentTime * 1000);
    }
  }, [currentTime]);
  
  useEffect(() => {
    if (isStop) {
      setTime(0);
    }
  }, [isStop]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isPause) {
      return () => clearInterval(interval);
    }
    if (!milliseconds) return;

    if (time < milliseconds) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 100);
      }, 100);
    }

    if ((time > milliseconds && !isStop) || time >= milliseconds) {
      setTime(0);
      handleStop();
    }

    return () => clearInterval(interval);
  }, [isPause, time, milliseconds]);

  const formatTime = (timeInMilliseconds: number): string => {
    const totalSeconds = Math.floor(timeInMilliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return <div>{formatTime(time)}</div>;
};

export default Timer;
