import React from "react";
import { RiSaveLine } from "react-icons/ri";

type ButtonSubmitProps = {
  error?: string;
  handleSubmitUpdate?: () => void;
};

const ButtonSubmit = ({
  error = "",
  handleSubmitUpdate,
}: ButtonSubmitProps) => {
  return (
    <div className="pt-[20px] flex pb-[15px] justify-center items-center gap-[15px]">
      <button
        type="button"
        className={`flex gap-[10px] justify-center items-center ${
          !!error ? "bg-primary-light hover:bg-none" : "bg-primary"
        }  text-white rounded-[10px] w-[100px] h-[38px]`}
        onClick={handleSubmitUpdate}
        disabled={!!error}
      >
        <RiSaveLine className="w-[24px] h-[24px]" />
        保存
      </button>
    </div>
  );
};

export default ButtonSubmit;
