import { getTabObj } from "../../../helpers/parseData";
import { PATH_ADMIN_EXAM_RESULT } from "../../route/router";

const EXAM_RESULT_TAB_ITEM = {
  EXAM_LIST: '受験結果一覧',
};

const EXAM_PARTICIPATE_TAB_DATA = [
  getTabObj(EXAM_RESULT_TAB_ITEM.EXAM_LIST, PATH_ADMIN_EXAM_RESULT.EXAM_LIST),
];


export { EXAM_RESULT_TAB_ITEM, EXAM_PARTICIPATE_TAB_DATA}
