import React, { ChangeEvent, useEffect } from "react";
import { decodeHTMLEntities } from "../../../../../../services/helpers/parseData";
import { isUsableArr } from "../../../../../../services/helpers/etc";
import {
  CorrectFillInBlankIssue,
  FillInBlankProblem,
  QuestionProblem,
} from "../../../../../../types/admin/question";
import { handleProblemScoreCalculator } from "../../../../Question/AuthoringQuestion/QuestionEditorHandler";

const FillInBlankQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled,
  disabledSubmit = false,
}: {
  questionProblem: QuestionProblem;
  disabledSubmit?: boolean;
  handleUpdateProblem: (problem: QuestionProblem) => void;
  isDisabled: boolean;
}) => {
  const fillInQuestion: FillInBlankProblem = questionProblem;
  const fillInIssues = fillInQuestion.issues;
  const correctFills: CorrectFillInBlankIssue[] =
    fillInQuestion.correct_fills || [];
  const formId = `fill_in_blank_content_form_${fillInQuestion.id}`;

  const submitEditingDataHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    let newCorrectFills = correctFills;
    const currentFormInputs = document.querySelectorAll(
      `#${formId} input[name='point']`
    );

    currentFormInputs.forEach((input) => {
      const identifier = input.getAttribute("data-identifier");
      const point = Number((input as HTMLInputElement).value);
      (input as HTMLInputElement).value = String(point);

      newCorrectFills = newCorrectFills?.map((fill) => {
        if (identifier === fill.identifier) {
          return {
            ...fill,
            point,
          };
        }

        return fill;
      });
    });

    const finalEditingData: FillInBlankProblem = {
      ...fillInQuestion,
      correct_fills: newCorrectFills,
      updated_at: new Date().getTime(),
    };
    const handleScore = handleProblemScoreCalculator(finalEditingData);

    handleUpdateProblem({...finalEditingData, total_score: handleScore});
  };

  useEffect(() => {
    const currentFormInputs = document.querySelectorAll(
      `#${formId} input[name='point']`
    );

    currentFormInputs.forEach((input) => {
      const identifier = input.getAttribute("data-identifier");

      correctFills.forEach((correctFill) => {
        if (correctFill.identifier === identifier) {
          let className = "w-[45px] h-[32px] text-[14px] !border-secondary-light";
          if (isDisabled) {
            className += " pointer-events-none";
          }

          input.setAttribute("class", className);
          input.setAttribute("value", String(correctFill.point));
          input.setAttribute("type", "text");
        }
      });
    });
  }, [fillInQuestion]);

  useEffect(() => {
    const currentFormInputs = document.querySelectorAll<HTMLInputElement>(
      `#${formId} input[name='point']`
    );

    const handleChange = (event: Event) => {
      submitEditingDataHandle(
        event as unknown as ChangeEvent<HTMLInputElement>
      );
    };

    currentFormInputs.forEach((input) => {
      input.addEventListener("change", handleChange);

      return () => {
        input.removeEventListener("change", handleChange);
      };
    });
  }, []);

  return (
    <form
      id={formId}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        if (disabledSubmit) return e.preventDefault();
      }}
    >
      <div className="bg-white py-[20px] text-secondary-dark rounded-b-[10px]">
        <div className="pt-[20px] px-[10px] flex flex-wrap gap-[5px]">
          {isUsableArr(fillInIssues) &&
            fillInIssues &&
            fillInIssues.map((issue, index) => (
              <div
                className="mb-[15px] flex items-center gap-[10px] px-[10px]"
                key={index}
              >
                <div className={`w-[80%]`}>
                  <div
                    className="w-[100px] border rounded-[7px] px-[15px] py-[10px]"
                    dangerouslySetInnerHTML={{
                      __html: decodeHTMLEntities(issue.label || ""),
                    }}
                  ></div>
                </div>
              </div>
            ))}
        </div>

        <div className="px-[20px]">
          <div className="my-[32px] border-t-[2px] border-secondary-light w-full text-[12px] leading-[190%] text-secondary-light"></div>
        </div>

        <div className="px-[4px] mb-[100px]">
          <div className="leading-[190%] no_border_editor">
            <div className="">
              <div
                className="px-[15px] focus:outline-none focus:ring focus:border-primary focus:rounded-[5px] highlight_content"
                suppressContentEditableWarning
                dangerouslySetInnerHTML={{
                  __html: decodeHTMLEntities(fillInQuestion.content || ""),
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FillInBlankQuestion;
