import React from "react";

const Group = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M15.75 6a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zm4.732 2.812a3.75 3.75 0 10-4.963 0 5.762 5.762 0 00-3.105 3.817 7.732 7.732 0 00-1.556-.835 4.75 4.75 0 10-5.717 0A7.753 7.753 0 00.25 19v1a.75.75 0 001.5 0v-1a6.25 6.25 0 1112.5 0v1a.75.75 0 001.5 0v-1a7.721 7.721 0 00-1.996-5.191A4.25 4.25 0 0122.25 14v.5a.75.75 0 001.5 0V14a5.75 5.75 0 00-3.268-5.188zM8 4.75a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5z"
        clipRule="evenodd"
        fill={props.color || "#BEBEBE"}
      ></path>
    </svg>
  );
};

export default Group;
