import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  PATH_CANDIDATE_HOME,
  PATH_EXAMINATION,
} from "../../../services/constants/route/router";
import { makeRequest, request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { RegisterExaminationDetailType } from "../../../types/candidate/registerExamination";
import dayjs from "dayjs";
import { swalError } from "../../../services/helpers/swal";
import Spinner from "../../commons/icons/Animations/Spinner";

const CBTEnterCode = () => {
  const navigate = useNavigate();
  const { getParamValue } = useObjectRoutes();
  const registerExaminationId = getParamValue("id");
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [fullCode, setFullCode] = useState<string>("");
  const [waitingAPI, setWaitingAPI] = useState<boolean>(false);
  const [keyDownCount, setKeyDownCount] = useState<number>(0);
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  useEffect(() => {
    let isFinishCode = true;
    code.map((code) => {
      if (code === "") {
        return (isFinishCode = false);
      }
    });
    if (!isFinishCode) return;

    setFullCode(code.join(''));
    handleInputComplete(code.join(''));
  }, [code]);

  const handleInputChange = (index: number, value: string) => {
    if (/^\d+$/.test(value) && value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;

      if (value !== "" && index < code.length - 1) {
        inputRefs[index + 1].current?.focus();
      }
      setCode(newCode);
    }

    if (value === "") {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
    }
  };

  const handleInputComplete = async (fullCode: string) => {
    setWaitingAPI(true);
    const result = await makeRequest({
      method: "post",
      url: API.EXAMINATION.CBT_ENTER_CODE,
      data: {
        code: fullCode,
      },
    });
    setWaitingAPI(false);

    if (!result.status) {
      swalError(result.message);
      return;
    }

    navigate(
      `${PATH_EXAMINATION.EXAMINATION_PART_LIST}?id=${registerExaminationId}`
    );
  };

  useEffect(() => {
    if (!registerExaminationId) {
      navigate("/404");
      return;
    }

    const fetch = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.REGISTER_EXAMINATION.GET_DETAIL}?register_examination_id=${registerExaminationId}`,
      });

      if (!result.status) {
        navigate("/404");
        return;
      }

      const data: RegisterExaminationDetailType = result.data;

      if (
        (data.examination?.entry_time &&
          dayjs(data.exam_full_date).diff(dayjs(), "minute") >
            data.examination?.entry_time) ||
        (!data?.examination?.entry_time &&
          dayjs(data.exam_full_date).diff(dayjs(), "minute") > 0)
      ) {
        alert("開始時間の前です。");
        setTimeout(() => {
          navigate(PATH_CANDIDATE_HOME.DEFAULT);
        }, 1000);
      }
    };

    fetch();
  }, []);

  const handlePasteCode = (value: string, i: number) => {
    if (value.length === 6 && !isNaN(Number(value))) {
      const valueArr = value.split("");
      valueArr.forEach((value, index) => {
        if (inputRefs[index].current !== null) {
          (inputRefs[index]?.current as HTMLInputElement).value = value;
        }
      });
      setCode(valueArr);
      setFullCode(value);
    }
  };

  return (
    <div className="w-full h-full bg-white rounded-[10px] flex items-center justify-center">
      <div className="">
        <p className="text-center text-[28px] font-[500] mb-[40px]">
          受験コードを入力してください
        </p>
        <div className="flex items-center justify-center gap-x-[18px] mb-[44px]">
          {code.map((value, index) => (
            <input
              disabled={waitingAPI}
              type="text"
              className="w-[72px] h-[72px] border border-secondary-light rounded-[12px] text-center !text-[30px]"
              key={index}
              ref={inputRefs[index]}
              maxLength={6}
              value={value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handlePasteCode(e.target.value, index);
              }}
              onKeyDown={(e) => {
                const key = e.key;
                if (key === "Delete" || key === "Backspace") {
                  setKeyDownCount(keyDownCount + 1);
                  const newCode = [...code];
                  newCode[index] = "";
                  setCode(newCode);
                  if (keyDownCount >= 1) {
                    if (index - 1 < 0) return;
                    inputRefs[index - 1].current?.focus();
                    setKeyDownCount(0);
                  }
                } else {
                  handleInputChange(index, e.key);
                }
              }}
              onFocus={() => {
                setKeyDownCount(keyDownCount + 1);
              }}
            />
          ))}
        </div>
        <div className="flex flex-col items-center gap-y-[25px]">
          <button
            className={`w-[140px] text-[16px] text-white leading-[24px] font-[500] bg-danger rounded-[5px] px-[15px] py-[8px]
            {
              "bg-secondary-light cursor-default": buttonTime.status === 0,
              "bg-danger": buttonTime.status === 1,
              "bg-[#8BC0D3] cursor-default": buttonTime.status === 2,
            }`}
            onClick={() => handleInputComplete(fullCode)}
            disabled={waitingAPI}
          >
            {waitingAPI ? <Spinner /> : "試験スタート"}
          </button>
          <Link
            to={PATH_CANDIDATE_HOME.DEFAULT}
            className="w-[140px] text-[16px] text-secondary-dark bg-[#E0E0E0] text-center px-[15px] py-[8px] rounded-[5px]"
          >
            戻る
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CBTEnterCode;
