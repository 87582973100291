import React from "react";
import {
  isEmptyObj,
  isUsableArr,
} from "../../../../../../services/helpers/etc";
import {
  decodeHTMLEntities,
  groupArrayByProperty,
} from "../../../../../../services/helpers/parseData";
import {
  MatrixCoordinate,
  MatrixIssue,
  MatrixProblem,
  QuestionProblem,
} from "../../../../../../types/admin/question";
import { handleProblemScoreCalculator } from "../../../../Question/AuthoringQuestion/QuestionEditorHandler";

const MatrixQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled
}: {
  questionProblem: QuestionProblem;
  handleUpdateProblem: (problem: QuestionProblem) => void;
  isDisabled: boolean;
}) => {
  const matrixQuestion: MatrixProblem = questionProblem;
  const matrixIssues = matrixQuestion.issues || [];
  const correctCoordinates = matrixQuestion.correct_coordinates || [];
  const currentCoordinatesByX = groupArrayByProperty(correctCoordinates, "x");
  const coordinateKeyXs = Object.keys(currentCoordinatesByX);
  const currentCoordinatesByY = groupArrayByProperty(correctCoordinates, "y");

  const getMatrixIssueById = (fillInIssues: MatrixIssue[], issueId: number) => {
    return matrixIssues.find((issue) => issue.id === issueId) ?? {};
  };

  const getCoordinateByAxis = (x: number, y: number) =>
    correctCoordinates.find(
      (coordinate) => coordinate.x === x && coordinate.y === y
    );

  const matrixChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    coordinate: MatrixCoordinate
  ) => {
    if (!e || !e.currentTarget) return <></>;
    const { name, value } = e.currentTarget;
    let parsedValue: string | number = Number(value);
    if(isNaN(parsedValue)) return;
    
    const coordinateIndex = correctCoordinates.findIndex(
      (crCoordinate) =>
        crCoordinate.x === coordinate.x && crCoordinate.y === coordinate.y
    );
    const newCoordinate = {
      ...coordinate,
      [name]: parsedValue,
    };
    correctCoordinates.splice(coordinateIndex, 1, newCoordinate);
    const newMatrixQuestion = {
      ...matrixQuestion,
      correct_coordinates: correctCoordinates,
    };
    const handleScore = handleProblemScoreCalculator(newMatrixQuestion);

    handleUpdateProblem({...newMatrixQuestion, total_score: handleScore});
  };

  return (
    <div className="justify-center items-center px-[20px] py-[55px] flex flex-col overflow-auto">
      <div className="flex bg-success-lighter border-r border-[#C2C2C2]">
        <div className="border-l border-t border-[#C2C2C2] min-w-[108px] min-h-[52px]"></div>
        {!isEmptyObj(currentCoordinatesByY) &&
          Object.keys(currentCoordinatesByY).map((coordinateYId) => (
            <div
              className="border-l border-t border-[#C2C2C2] w-[108px] min-h-[52px] px-[15px] py-[10px]"
              key={`${coordinateYId}_th`}
              dangerouslySetInnerHTML={{
                __html: decodeHTMLEntities(
                  getMatrixIssueById(matrixIssues, Number(coordinateYId))?.label || ""
                ),
              }}
            ></div>
          ))}
      </div>
      <div className="">
        <div className="bg-success-lighter">
          <div className="flex">
            <div className="">
              {coordinateKeyXs.map(
                (coordinateXId, coordinateXIdIndex, coordinateXIdArr) => (
                  <div key={`${coordinateXId}_td`} className="flex">
                    <div
                      className={`flex border-[#C2C2C2] ${
                        coordinateXIdIndex === coordinateXIdArr.length - 1
                          ? "border-b"
                          : ""
                      }`}
                    >
                      <div
                        className="border-l border-t border-[#C2C2C2] w-[108px] min-h-[52px] px-[15px] py-[10px]"
                        dangerouslySetInnerHTML={{
                          __html: decodeHTMLEntities(
                            getMatrixIssueById(
                              matrixIssues,
                              Number(coordinateXId)
                            )?.label || ""
                          ),
                        }}
                      ></div>
                      <div className="flex">
                        {isUsableArr(correctCoordinates) &&
                          correctCoordinates
                            ?.filter(
                              (coordinateY) =>
                                Number(coordinateY.x) === Number(coordinateXId)
                            )
                            ?.map((coordinateY) => (
                              <div className="" key={coordinateY.id + "y"}>
                                <div className="pl-[15px] border-l border-t border-r bg-white border-[#C2C2C2] min-w-[108px] h-full flex gap-[10px] items-center">
                                  <input
                                    type="checkbox"
                                    name="checked"
                                    id=""
                                    checked={
                                      !!getCoordinateByAxis(
                                        coordinateY.x,
                                        coordinateY.y
                                      )?.checked
                                    }
                                    onChange={() => {}}
                                  />
                                  <input
                                    type="text"
                                    name="point"
                                    id=""
                                    className="w-[45px] h-[40px] text-[14px] !border-secondary-light"
                                    placeholder="0"
                                    value={
                                      getCoordinateByAxis(
                                        coordinateY.x,
                                        coordinateY.y
                                      )?.point || ""
                                    }
                                    onChange={(e) =>
                                      matrixChangeHandle(e, coordinateY)
                                    }
                                    disabled={isDisabled}
                                  />
                                </div>
                              </div>
                            ))}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatrixQuestion;
