import React, { useState } from "react";
import { BsEye } from "react-icons/bs";
import { File } from "../../../../../types/admin/folder";
import BaseModal from "../../../commons/BaseModal";
import ResourceContentDisplay from "../../../../candidate/common/ResourceContentDisplay";
import { UNIT_FILE_TYPE } from "../../../../../services/constants/admin/pages/material";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";

const UploadQuestionFileItem = ({ answer }: { answer: File }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const checkAnswerUploadFileType = (contentType: string) => {
    if (["png", "jpg", "jpeg"].includes(contentType)) {
      return UNIT_FILE_TYPE.TYPE_IMAGE;
    }

    if (["doc", "pdf", "xlsx"].includes(contentType)) {
      return UNIT_FILE_TYPE.TYPE_PDF;
    }

    if (contentType === "mp3") {
      return UNIT_FILE_TYPE.TYPE_AUDIO;
    }

    if (contentType === "mp4") {
      return UNIT_FILE_TYPE.TYPE_VIDEO;
    }

    return null;
  };

  return (
    <div className="flex-1 flex items-center gap-x-[10px] px-[15px] text-[12px] font-[500]">
      <div className="flex items-center gap-x-[10px]">
        <BsEye
          size={24}
          onClick={() => setIsOpen(true)}
          className="cursor-pointer"
        />{" "}
        {answer.origin_filename}
      </div>
      {isOpen && (
        <BaseModal setIsOpen={setIsOpen} cancelLabel="閉じる" submitLabel="">
          <div className="w-full h-[500px] max-h-[500px] overflow-y-auto flex items-center justify-center py-[30px]">
            {checkAnswerUploadFileType(answer.content_type || "") ? (
              <ResourceContentDisplay
                url={`${answer.resource_link}`}
                resourceType={checkAnswerUploadFileType(answer.content_type || "")}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeHTMLEntities(answer.embed_code || "<></>"),
                }}
              ></div>
            )}
          </div>
        </BaseModal>
      )}
    </div>
  );
};

export default UploadQuestionFileItem;
