import React, { ChangeEvent } from "react";
import {
  QuestionProblem,
  SliderProblem,
} from "../../../../../../types/admin/question";
import SliderQuestionInputRange from "../../../../Question/AuthoringQuestion/QuestionEditorHandler/QuestionTypes/SliderQuestion/SliderQuestionInputRange";
import { handleProblemScoreCalculator } from "../../../../Question/AuthoringQuestion/QuestionEditorHandler";

const SliderQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled,
}: {
  questionProblem: QuestionProblem;
  handleUpdateProblem: (problem: QuestionProblem) => void;
  isDisabled: boolean;
}) => {
  const sliderProblem: SliderProblem = questionProblem;
  const sliderIssues = sliderProblem.issues || [];

  const handleChangePoint = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const point = Number(value);
    if (isNaN(point)) return;

    const sliderIssue = sliderIssues[0];
    const newSliderProblem = {
      ...sliderProblem,
      default_score: point,
      issues: [{ ...sliderIssue, point: point }],
    };
    const handleScore = handleProblemScoreCalculator(newSliderProblem);
    handleUpdateProblem({ ...newSliderProblem, total_score: handleScore });
  };

  return (
    <div className="flex justify-center items-center p-[20px] bg-white flex-col !gap-[28px] min-h-[100px] rounded-b-[10px]">
      <div className="w-[75%] flex gap-[10px] justify-center items-center">
        <SliderQuestionInputRange
          className={"basis-[90%]"}
          editingData={sliderProblem}
          answerValue={sliderIssues?.[0].answerValue || 0}
          isDisabled={true}
        />
        <input
          value={sliderProblem.default_score || sliderIssues[0].point || 0}
          type="text"
          name="point"
          className="w-[45px] h-[40px] text-[14px] !border-secondary-light"
          onChange={handleChangePoint}
          placeholder="0"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default SliderQuestion;
