import { getTabObj } from '../../../helpers/parseData';
import { PATH_ADMIN_GROUP } from '../../route/router';

const GROUP_TAB_ITEM = {
  LIST: 'グループ一覧',
  MANAGEMENT: 'グループ新規追加',
  SETTING: 'グループ一括追加',
};

const GROUP_TAB_DATA = [
  getTabObj(GROUP_TAB_ITEM.LIST, PATH_ADMIN_GROUP.DEFAULT),
  getTabObj(GROUP_TAB_ITEM.MANAGEMENT, PATH_ADMIN_GROUP.MANAGEMENT),
];

const TRANS_CANDIDATE_ACTION = {
  ADD: 1,
  MOVE: 2,
};

export { GROUP_TAB_DATA, TRANS_CANDIDATE_ACTION, GROUP_TAB_ITEM };
