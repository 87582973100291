import { ReactNode } from "react";
import { WithChildren } from "../../../../types/globals";

type ChildrenStepProps = {
  step: number;
  title: string;
} & WithChildren;

const ChildrenStep = ({ step, title, children }: ChildrenStepProps) => {
  return (
    <>
      <div className="w-full rounded-[10px] p-[40px] bg-white text-[16px] mb-[20px]">
        <div className="w-full flex justify-start items-center gap-[20px] mb-[20px]">
          <div className="text-[22px] text-[#0F6788]">{step}</div>
          <div>{title}</div>
        </div>
        {children}
      </div>
    </>
  );
};

export default ChildrenStep;
