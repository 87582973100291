import React, { ChangeEvent } from "react";

type FieldInputRadioProps = {
  label: string;
  id: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
} & React.HTMLProps<HTMLInputElement>;

const FieldInputRadio = ({
  id,
  label,
  handleChange,
  ...props
}: FieldInputRadioProps) => {
  return (
    <div className="flex items-center gap-x-[8px]">
      <input
        id={id}
        type="radio"
        className="checked:bg-primary-light cursor-pointer"
        onChange={handleChange}
        {...props}
      />
      <label htmlFor={id} className="text-[12px] cursor-pointer">
        {label}
      </label>
    </div>
  );
};

export default FieldInputRadio;
