import { RxCaretSort } from 'react-icons/rx';
import CandidateStatusButton from './CandidateStatusButton';
import { useGroupManagementContext } from '../../../../context/Group/Management';
import { useState, useEffect } from 'react';
import { Group } from '../../../../types/admin/group';
import { sortArrayByKey } from '../../../../services/helpers/parseData';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';

const TableManagement = () => {
  const {
    mainGroups,
    candidates,
    checkedCandidateIds,
    checkedObj,
    setCheckedCandidates,
    setCheckedCandidateIds,
    setCheckedObj,
    setCandidates,
  } = useGroupManagementContext();
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [sortedName, setSortedName] = useState<boolean>(false);
  const [sortedUsername, setSortedUsername] = useState<boolean>(false);
  const [sortedStatus, setSortedStatus] = useState<boolean>(false);
  const [sortedGroupName, setSortedGroupName] = useState<boolean>(false);
  const { getParamValue } = useObjectRoutes();
  const groupId = getParamValue('group_id');

  const handleCheckedCandidate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentCheckedChildId = e.target.getAttribute('data-id');
    const isChecked = e.target.checked;

    if (currentCheckedChildId) {
      setCheckedObj({ ...checkedObj, [currentCheckedChildId]: isChecked });
    } else {
      setCheckedAll(!checkedAll);
      const newCheckedObj = checkedObj;

      candidates &&
        candidates.forEach((candidate) => {
          if (!candidate?.is_disabled) newCheckedObj[candidate.id as number] = isChecked;
        });

      setCheckedObj({ ...checkedObj, ...newCheckedObj });
    }
  };

  const isCheckGroupMain = (groupId: Number) => {
    let group = mainGroups?.find((group) => group.id === groupId);
    return typeof group !== 'undefined';
  };

  useEffect(() => {
    if (checkedCandidateIds && !!checkedCandidateIds.length) return;
    setCheckedAll(false);
  }, [checkedCandidateIds]);

  useEffect(() => {
    if (!candidates) return;
    const checkedArray = Object.entries(checkedObj);
    const filteredEntries = checkedArray.filter(([key, value]) => value === true);
    const filteredIds = Object.keys(Object.fromEntries(filteredEntries));

    const parsedFilteredIds: number[] = [];
    const filteredCandidates: Group['candidates'] = [];

    filteredIds.forEach((id) => {
      const candidateById = candidates.find((candidate) => candidate.id === Number(id));

      if (candidateById) {
        parsedFilteredIds.push(Number(id));
        filteredCandidates.push(candidateById);
      }
    });

    setCheckedCandidateIds(parsedFilteredIds);
    setCheckedCandidates(filteredCandidates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedObj]);

  useEffect(() => {
    if (!candidates) return;
    const sortedArr = sortArrayByKey(candidates, 'first_name');

    if (sortedName) return setCandidates(sortedArr);
    return setCandidates(sortedArr.reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedName]);

  useEffect(() => {
    if (!candidates) return;
    const sortedArr = sortArrayByKey(candidates, 'username');

    if (sortedUsername) return setCandidates(sortedArr);
    return setCandidates(sortedArr.reverse());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedUsername]);

  useEffect(() => {
    if (!candidates) return;
    const sortedArr = sortArrayByKey(candidates, 'status');

    if (sortedStatus) return setCandidates(sortedArr);
    return setCandidates(sortedArr.reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedStatus]);

  useEffect(() => {
    if (!candidates) return;
    const sortedArr = sortArrayByKey(candidates, 'group_name');

    if (sortedStatus) return setCandidates(sortedArr);
    return setCandidates(sortedArr.reverse());
  }, [sortedGroupName]);

  return (
    <section className="w-full mt-[30px]">
      <table className="candidate_table">
        <thead>
          <tr className="text-[13px]">
            <th className="w-[5%] !text-center !pl-0">
              <input
                type="checkbox"
                name="check_candidate"
                id=""
                data-id={null}
                onChange={handleCheckedCandidate}
                checked={checkedAll}
              />
            </th>
            <th className="w-[30%]">
              <div className="flex items-center gap-[11px]">
                氏名
                <RxCaretSort
                  className="cursor-pointer text-secondary"
                  size={20}
                  onClick={() => setSortedName(!sortedName)}
                />
              </div>
            </th>
            {groupId && isCheckGroupMain(parseInt(groupId)) && (
              <th className="w-[25%]">
                <div className="flex items-center gap-[11px]">
                  下位グループ
                  <RxCaretSort
                    className="cursor-pointer text-secondary"
                    size={20}
                    onClick={() => setSortedGroupName(!sortedGroupName)}
                  />
                </div>
              </th>
            )}
            <th className="w-[25%]">
              <div className="flex items-center gap-[11px]">
                ログインID
                <RxCaretSort
                  className="cursor-pointer text-secondary"
                  size={20}
                  onClick={() => setSortedUsername(!sortedUsername)}
                />
              </div>
            </th>
            <th className="w-[15%]">
              <div className="flex items-center gap-[11px]">
                ステータス
                <RxCaretSort
                  className="cursor-pointer text-secondary"
                  size={20}
                  onClick={() => setSortedStatus(!sortedStatus)}
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {candidates && candidates.length ? (
            <>
              {candidates.map((candidate) => (
                <tr key={candidate.id}>
                  <td className="!text-center !pl-0">
                    <input
                      type="checkbox"
                      name="check_candidate"
                      id=""
                      className={`${
                        candidate.is_disabled ? 'bg-gray-300 pointer-events-none' : 'cursor-pointer'
                      }`}
                      data-id={candidate.id}
                      onChange={handleCheckedCandidate}
                      checked={checkedObj[candidate.id as number]}
                      disabled={candidate.is_disabled}
                    />
                  </td>
                  <td className="text-[14px] font-[500] leading-[100%] text-primary">{`${candidate.first_name} ${candidate.last_name}`}</td>
                  {groupId && isCheckGroupMain(parseInt(groupId)) && (
                    <td className="text-[12px] leading-[100%]">{candidate.group_name ?? '-'}</td>
                  )}
                  <td className="text-[12px] leading-[100%]">{candidate.username}</td>
                  <td>
                    <div className="w-full flex justify-center">
                      <CandidateStatusButton status={candidate.status || 0} />
                    </div>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr className="">
              <td colSpan={6}>
                <div className="text-center">データがありません</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
};

export default TableManagement;
