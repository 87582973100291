import React, { ChangeEvent, useEffect, useState } from "react";
import ExamParticipateFilter from "./ExamParticipateFilter";
import ExamParticipateTable from "./ExamParticipateTable";
import { useExamParticipateContext } from "../../../../context/Admin/ExamParticipateContext";
import { ExamParticipateType } from "../../../../types/admin/examParticipate";
import LayoutWaitingApi from "../../../commons/layouts/LayoutWaitingAPI";
import { makeRequest } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { swalClose, swalError } from "../../../../services/helpers/swal";
import { API } from "../../../../services/constants/route/api";

type FilterType = {
  search: string;
  limit: number;
  page: number;
};

const ListExamParticipate = () => {
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    search: "",
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { examParticipates, setExamParticipates, setTotalPages } =
    useExamParticipateContext();
  const [_examParticipates, _setExamParticipates] = useState<
    ExamParticipateType[]
  >(examParticipates || []);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);

  const resetTable = () => {
    setCurrentPage(1);
  };

  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setWaitingApi(true);
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  const changeHandle = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };

  useEffect(() => {
    _setExamParticipates(examParticipates);
  }, [examParticipates]);

  useEffect(() => {
    const fetch = async () => {
        setWaitingApi(true);

      const result = await makeRequest({
        method: "get",
        url: `${
          API.ADMIN_EXAM_PARTICIPATE.GET_LIST_EXAMINATION
        }?${paramizeObject(filter)}`,
      });

      setWaitingApi(false);

      if (!result.data) return swalError();
      setExamParticipates(result.data.exam_participates);
      setTotalPages(result.data.total_page);
      setWaitingApi(false);
      swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);

    return () => clearTimeout(timer);
  }, [filter]);

  return (
    <div>
      {/* Filter */}
      <ExamParticipateFilter changeHandle={changeHandle} />
      <LayoutWaitingApi waitingApi={waitingApi} className="w-full h-[100px]">
        <ExamParticipateTable
          _examParticipates={_examParticipates}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </LayoutWaitingApi>
    </div>
  );
};

export default ListExamParticipate;
