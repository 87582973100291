/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { LiaSave } from "react-icons/lia";
import { PiArrowFatDown, PiArrowFatRight, PiTrash } from "react-icons/pi";
import AuthoringTextEditor from "../../AuthoringTextEditor";
import {
  isEmptyObj,
  isUsableArr,
} from "../../../../../../../services/helpers/etc";
import {
  OrderProblem,
  OrderIssue,
} from "../../../../../../../types/admin/question";
import { HiPlus } from "react-icons/hi";
import {
  swalConfirm,
  swalSuccess,
} from "../../../../../../../services/helpers/swal";
import { RenderDroppedQuestionTypeEditProps } from "../../DroppedQuestionType";
import {
  generateOrderIssues,
  initIssueByType,
  displayStatusEditorHandle,
} from "../../../../../../../services/utils/admin/question";
import { NAME_TYPE_QUESTION } from "../../../../../../../services/constants/admin/pages/question";
import { useAuthoringQuestionContext } from "../../../../../../../context/Question/AuthoringQuestionContext";
import { LuXCircle } from "react-icons/lu";

type OrderQuestionEditProps = {
  problemEdit?: OrderProblem;
} & Omit<RenderDroppedQuestionTypeEditProps, "draggedName" | "problemEdit">;

const OrderQuestionEdit = ({ problemEdit }: OrderQuestionEditProps) => {
  const [editingNode, setEditingNode] = useState("");
  const [editingData, setEditingData] = useState<OrderProblem>(
    problemEdit || {}
  );
  const currentIssues = editingData.issues || [];
  const orderedIssues = editingData.correct_issues || [];

  const {
    authoringData,
    setAuthoringData,
    editingProblemStatuses,
    setEditingProblemStatuses,
    setSelectedProblem,
    setIsEditingProblem,
  } = useAuthoringQuestionContext();

  //On change handle for text editor only
  const changeEditorHandle = useCallback(
    (identify: string, content: string) => {
      if (
        content === "<p><br></p>" ||
        !editingData ||
        editingData[identify as keyof OrderProblem] === content ||
        identify !== editingNode
      ) {
        return;
      }

      if (identify === "title" || editingNode === "title") {
        setEditingData((editingData) => ({ ...editingData, title: content }));
        return;
      }

      const newIssues = generateOrderIssues(
        identify,
        content,
        editingData.issues || []
      );
      setEditingData({ ...editingData, issues: newIssues });
    },
    [editingNode]
  );

  const [checkingIssue, _setCheckingIssue] = useState<OrderIssue>({});
  const setCheckingIssue = (issueId?: number) => {
    if (!issueId) _setCheckingIssue({});
    _setCheckingIssue(
      currentIssues.find((issue) => Number(issue.id) === Number(issueId)) || {}
    );
  };

  const tabOptionChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget || !editingData) return;
    const { name, value, type } = e.currentTarget;

    let parsedValue: string | number | boolean = value;

    if (type === "radio") {
      parsedValue = !!Number(value);
    }

    if (type === "number") {
      parsedValue = Number(value);
    }

    if (Number(value) > 100) {
      parsedValue = 100;
    }

    if (Number(value) < 0) {
      parsedValue = 0;
    }

    if (
      !editingData.hasOwnProperty(name) ||
      editingData[name as keyof OrderProblem] === parsedValue
    ) {
      return;
    }

    let additionData = {} as typeof editingData;
    if (name === "default_score") {
      // update default score for all issues
      const newIssues = editingData.issues?.map((issue) => ({
        ...issue,
        point: Number(parsedValue),
      }));
      const newCorrectIssues = editingData.correct_issues?.map((issue) => ({
        ...issue,
        point: Number(parsedValue),
      }));
      additionData = { issues: newIssues, correct_issues: newCorrectIssues };
    }

    setEditingData({ ...editingData, [name]: parsedValue, ...additionData });
  };

  const issueChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget) return;
    const { name, value } = e.currentTarget;

    let parsedValue: string | boolean = String(Number(value));

    if (Number(value) > 100) {
      parsedValue = "100";
    }

    if (Number(value) < 0) {
      parsedValue = "0";
    }

    const newIssues = generateOrderIssues(
      name,
      parsedValue,
      editingData.issues || []
    );

    const newCorrectIssues = editingData.correct_issues?.map((correctIssue) => {
      if (isEmptyObj(correctIssue)) {
        return correctIssue;
      }

      const findIssue = newIssues.find((issue) => issue.id === correctIssue.id);
      if (findIssue) {
        return { ...correctIssue, point: Number(findIssue.point) };
      }

      return {};
    });

    setEditingData({
      ...editingData,
      issues: newIssues,
      correct_issues: newCorrectIssues,
    });
  };

  const addIssueHandle = () => {
    const issueValue = currentIssues.length || 0;
    const newIssue = initIssueByType(
      NAME_TYPE_QUESTION.CHOICE,
      issueValue,
      editingData?.default_score || 0
    );
    const newCorrectIssue = [...orderedIssues, {}];

    setEditingData({
      ...editingData,
      issues: [...currentIssues, newIssue as OrderIssue],
      correct_issues: newCorrectIssue,
    });
  };

  const removeIssueHandle = (targetId: number | string) => {
    if (!targetId) return;

    swalConfirm(() => {
      const newIssueData = currentIssues.filter(
        (issue) => Number(issue?.id) !== Number(targetId)
      );
      const findIndexCorrectIssue = orderedIssues.findIndex(
        (orderedIssue) => orderedIssue.id === targetId
      );
      let newCorrectIssues = orderedIssues;
      if (findIndexCorrectIssue !== -1) {
        newCorrectIssues = orderedIssues.filter(
          (orderedIssue, index) => index != findIndexCorrectIssue
        );
      } else {
        const findIndexCorrectIssueEmpty = orderedIssues.findIndex(
          (orderedIssue, index) => isEmptyObj(orderedIssue)
        );
        newCorrectIssues = orderedIssues.filter(
          (orderedIssue, index) => index != findIndexCorrectIssueEmpty
        );
      }

      setEditingData({
        ...editingData,
        issues: newIssueData,
        correct_issues: newCorrectIssues,
      });
      setEditingNode("");
    });
  };

  const submitEditingDataHandle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsEditingProblem(false);

    const finalEditingData = {
      ...editingData,
      updated_at: new Date().getTime(),
    };

    const problemIndexed =
      authoringData.problems?.findIndex(
        (problem) => problem.id === finalEditingData.id
      ) || 0;

    let newProblems: OrderProblem[] = authoringData.problems || [];
    newProblems.splice(problemIndexed, 1, finalEditingData);

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      "hide",
      Number(editingData.id),
      editingProblemStatuses
    );
    setEditingProblemStatuses(newEditingProblemStatuses);

    setAuthoringData({ problems: newProblems });
    swalSuccess();
    setSelectedProblem();
  };

  const removeHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    swalConfirm(() => {
      setIsEditingProblem(false);
      setAuthoringData({
        ...authoringData,
        problems: authoringData.problems?.filter(
          (problem) => Number(problem.id) !== Number(editingData.id)
        ),
      });
    });
  };

  const hideEditorHandle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsEditingProblem(false);

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      "hide",
      Number(editingData.id),
      editingProblemStatuses
    );
    setEditingProblemStatuses(newEditingProblemStatuses);
    setSelectedProblem();
  };

  const removeCheckedIssueHandle = (name?: string, issueId?: number) => {
    if (!name || !issueId || !Number(issueId)) return;

    const orderedIssueIndex = orderedIssues.findIndex(
      (issue) => issue.id === issueId
    );
    orderedIssues.splice(orderedIssueIndex, 1, {});

    let newIssues = currentIssues.map((issue) => {
      if (issue.id === issueId) {
        return {
          ...issue,
          checked: false,
        };
      }

      return issue;
    });

    setEditingData({
      ...editingData,
      correct_issues: orderedIssues,
      issues: newIssues,
    });
  };

  const checkingIssueHandle = (issueId?: number) => {
    const currentOrderedIssue = orderedIssues.find(
      (issue) => issue.id === issueId
    );
    if (currentOrderedIssue && currentOrderedIssue.id === issueId) {
      return;
    }

    const currentIssue = currentIssues.find((issue) => issue.id === issueId);
    let resultIssueId = issueId;
    if (issueId === currentIssue?.id && currentIssue?.checked) {
      resultIssueId = 0;
    }

    let newIssues = currentIssues.map((issue) => {
      if (issue.id === issueId) {
        return {
          ...issue,
          checked: !issue.checked,
        };
      }

      return issue;
    });

    setEditingData({ ...editingData, issues: newIssues });
    setCheckingIssue(resultIssueId);
  };

  const addCorrectOrderHandle = (issueId?: number, index?: number) => {
    if (!issueId || index === undefined) return;

    const orderedIssue: OrderIssue | undefined = currentIssues.find(
      (issue) => issue.id === issueId
    );
    if (!orderedIssue || !orderedIssue.id) return;

    const newOrderedIssues = JSON.parse(
      JSON.stringify(orderedIssues)
    ) as typeof orderedIssues;
    newOrderedIssues.splice(index, 1, orderedIssue);

    setEditingData({ ...editingData, correct_issues: newOrderedIssues });
    setCheckingIssue();
  };

  const getIssueById = (issueId: number) =>
    currentIssues.find((issue) => issue.id === issueId);

  return (
    <form>
      <div className="mb-[10px] bg-secondary-extralight min-h-[780px] w-full p-[20px] relative">
        <div className="mb-[30px]"></div>
        <div className="flex gap-[13px]">
          <div className="w-[80%]">
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設問内容
              </div>
              <div className="w-full bg-white">
                <div
                  className="p-[10px]"
                  onClick={() => setEditingNode("title")}
                >
                  <AuthoringTextEditor
                    toolbarId={`toolbar_title`}
                    isReadOnly={editingNode !== "title" && editingNode !== ""}
                    changeHandler={(htmlContent) =>
                      changeEditorHandle("title", htmlContent)
                    }
                    value={editingData?.title || ""}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary h-0 min-h-[34px] flex items-center">
                正解・解答・配点
              </div>
              <div
                className={`flex justify-center items-center gap-[5%] p-[20px] bg-white rounded-b-[10px] ${
                  editingData?.is_vertical_arrange ? "" : "flex-col !gap-[28px]"
                }`}
              >
                <div
                  className={`border-[2px] border-[#E5E5E5] h-full p-[15px] ${
                    editingData?.is_vertical_arrange ? "w-[270px]" : "w-[90%]"
                  }`}
                >
                  <ul className="px-0 py-[10px] list-none listbox-dot h-full flex flex-wrap gap-y-[20px]">
                    {isUsableArr(currentIssues) &&
                      currentIssues &&
                      currentIssues.map((issue, index) => (
                        <div
                          className={`flex items-center gap-[10px] px-[10px] ${
                            editingData?.is_vertical_arrange
                              ? "w-full"
                              : "w-[190px]"
                          }
                          `}
                          key={index}
                        >
                          <div
                            className="flex items-center cursor-pointer"
                            onClick={() => checkingIssueHandle(issue.id)}
                          >
                            <input
                              type="checkbox"
                              name={`issue_data_checked_${index}`}
                              id=""
                              className="pointer-events-none"
                              disabled
                            />
                          </div>
                          <li
                            className="w-full"
                            onClick={() =>
                              setEditingNode(`issue_data_label_${index}`)
                            }
                          >
                            <AuthoringTextEditor
                              toolbarId={`toolbar_issue_${index}`}
                              isReadOnly={
                                editingNode !== `issue_data_label_${index}`
                              }
                              changeHandler={(htmlContent) =>
                                changeEditorHandle(
                                  `issue_data_label_${index}`,
                                  htmlContent
                                )
                              }
                              value={issue.label || ""}
                              className={
                                checkingIssue.id === issue.id || issue.checked
                                  ? "bg-[#C5C5C5] text-secondary rounded-[7px]"
                                  : ""
                              }
                            />
                          </li>

                          <div
                            className="cursor-pointer text-secondary"
                            onClick={() => removeIssueHandle(issue?.id || 0)}
                          >
                            <PiTrash size={22} />
                          </div>
                        </div>
                      ))}
                    <div className="w-full flex justify-center mt-[20px]">
                      <div className="cursor-pointer" onClick={addIssueHandle}>
                        <div className="flex justify-center">
                          <div className="bg-primary rounded-[5px] w-[40px] h-[40px] flex justify-center items-center text-white cursor-pointer mb-[10px]">
                            <HiPlus size={40} />
                          </div>
                        </div>
                        <div className="text-center text-[12px] font-[500] leading-[100%] text-secondary-dark">
                          選択肢を追加
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
                <div className="text-[#EBE4D8]">
                  {editingData?.is_vertical_arrange ? (
                    <PiArrowFatRight size={50} />
                  ) : (
                    <PiArrowFatDown size={50} />
                  )}
                </div>
                <div
                  className={`border-[2px] border-[#E5E5E5] h-full px-[15px] pt-[40px] pb-[130px] flex flex-wrap gap-[10px] items-center  ${
                    editingData?.is_vertical_arrange
                      ? "w-[270px] flex-col justify-center"
                      : "w-[90%] justify-start"
                  }`}
                >
                  {isUsableArr(orderedIssues) &&
                    orderedIssues.map((issue, index) => (
                      <React.Fragment key={index}>
                        {issue.id ? (
                          <div
                            className={`flex justify-start gap-[10px] items-center ${
                              editingData?.is_vertical_arrange
                                ? "w-full"
                                : "w-[190px]"
                            }`}
                          >
                            <div className="w-full border border-dashed border-secondary-light min-h-[50px]">
                              <div className="w-full flex items-center justify-start gap-[7px] p-[5px]">
                                <div className="w-[67%] ml-[10px] mr-[5px]">
                                  <AuthoringTextEditor
                                    toolbarId={`toolbar_issue_${
                                      currentIssues.findIndex(
                                        (crIssue) => crIssue.id === issue.id
                                      ) || 0
                                    }_disabled_${issue.id}`}
                                    isReadOnly
                                    value={getIssueById(issue.id)?.label || ""}
                                  />
                                </div>
                                <div
                                  className="text-[20px] text-secondary-light cursor-pointer"
                                  onClick={() =>
                                    removeCheckedIssueHandle(
                                      `issue_data_checked_${
                                        currentIssues.findIndex(
                                          (crIssue) => crIssue.id === issue.id
                                        ) || 0
                                      }`,
                                      issue.id
                                    )
                                  }
                                >
                                  <LuXCircle />
                                </div>
                              </div>
                            </div>

                            <div className="">
                              <input
                                type="number"
                                name={`issue_data_point_${
                                  currentIssues.findIndex(
                                    (crIssue) => crIssue.id === issue.id
                                  ) || 0
                                }`}
                                id=""
                                className="w-[35px] h-[35px] !border-secondary-light !p-[5px]"
                                placeholder="0"
                                onChange={issueChangeHandle}
                                value={getIssueById(issue.id)?.point || ""}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`flex justify-start gap-[10px] items-center ${
                              editingData?.is_vertical_arrange
                                ? "w-full"
                                : "w-[190px]"
                            }`}
                            onClick={() =>
                              addCorrectOrderHandle(checkingIssue.id, index)
                            }
                          >
                            <div
                              className={`w-full border border-dashed ${
                                checkingIssue.id
                                  ? "border-primary bg-primary-extralight cursor-pointer"
                                  : "border-secondary-light pointer-events-none"
                              } min-h-[50px]`}
                            ></div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-[20%] flex flex-col gap-y-[15px]">
            <div className="flex flex-col">
              <div className="p-[10px] text-[14px] font-[500] leading-[100%] text-white w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設定
              </div>
              <div className="bg-white min-h-[100px] px-[10px]">
                <div className="mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    配点初期値
                  </div>
                  <input
                    type="number"
                    name="default_score"
                    placeholder="0"
                    className="w-[71px] h-[32px]"
                    onChange={tabOptionChangeHandle}
                    value={Number(editingData?.default_score) || ""}
                  />
                </div>

                <div className="mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    選択肢の並び方
                  </div>
                  <div className="flex gap-[8px] items-center font-[400] text-[14px] leading-[20px] mb-[5px]">
                    <input
                      type="radio"
                      name="is_vertical_arrange"
                      value={1}
                      onChange={tabOptionChangeHandle}
                      checked={!!editingData.is_vertical_arrange}
                      id="arrange_type_vertical"
                    />
                    <label htmlFor="arrange_type_vertical">縦</label>
                  </div>

                  <div className="flex gap-[8px] items-center font-[400] text-[14px] leading-[20px]">
                    <input
                      type="radio"
                      name="is_vertical_arrange"
                      value={0}
                      onChange={tabOptionChangeHandle}
                      checked={!editingData.is_vertical_arrange}
                      id="arrange_type_horizontal"
                    />
                    <label htmlFor="arrange_type_horizontal">横</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-white">
              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
                  onClick={submitEditingDataHandle}
                >
                  <LiaSave size={23} /> 保存
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={hideEditorHandle}
                >
                  キャンセル
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer gap-[5px] flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={removeHandle}
                >
                  <PiTrash size={20} />
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default OrderQuestionEdit;
