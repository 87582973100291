import React from "react";
import ExamManagementBase from ".";
import CreateExamVenue from "../../../components/admin/ExamVenue/CreateExamVenue";

const CreateExamVenuePage = () => {
  return (
    <ExamManagementBase title="試験管理 ｜ 会場追加">
      <CreateExamVenue />
    </ExamManagementBase>
  );
};

export default CreateExamVenuePage;
