import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { DIRECTION } from "../../services/constants/globals";

const SidebarDirection = ({
  onDirection,
  color,
}: {
  onDirection: "up" | "down" | "right" | "left";
  color?: string;
}) => {
  switch (onDirection) {
    case DIRECTION.UP:
      return <IoIosArrowUp color={color || "#5A5A5A"} />;

    default:
      return <IoIosArrowDown color={color || "#5A5A5A"} />;
  }
};

export default SidebarDirection;
