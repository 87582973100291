/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useRef } from 'react';
import { LiaSave } from 'react-icons/lia';
import { PiTrash } from 'react-icons/pi';
import AuthoringTextEditor from '../../AuthoringTextEditor';
import { EditingProblemStatus, SliderProblem } from '../../../../../../../types/admin/question';
import { generateSliderIssues } from '../../../../../../../services/utils/admin/question';
import { swalConfirm, swalError, swalSuccess } from '../../../../../../../services/helpers/swal';
import { RenderDroppedQuestionTypeEditProps } from '../../DroppedQuestionType';
import { useAuthoringQuestionContext } from '../../../../../../../context/Question/AuthoringQuestionContext';
import { filterUniqueArrayByKey } from '../../../../../../../services/helpers/parseData';
import AuthoringErrorBox from '../AuthoringErrorBox';
import SliderQuestionInputRange from './SliderQuestionInputRange';
import { validateLimit } from '../../../../../../../services/utils/admin/sliderQuestion';

type SliderQuestionEditProps = {
  problemEdit?: SliderProblem;
} & Omit<RenderDroppedQuestionTypeEditProps, 'draggedName' | 'problemEdit'>;

const SliderQuestionEdit = ({ problemEdit }: SliderQuestionEditProps) => {
  const [editingNode, setEditingNode] = useState('');
  const [editingData, setEditingData] = useState<SliderProblem>(problemEdit || {});
  const [errors, setErrors] = useState<string[]>([]);

  const valueScore = useRef<HTMLInputElement>(null);
  const minInput = useRef<HTMLInputElement>(null);
  const maxInput = useRef<HTMLInputElement>(null);
  const stepInput = useRef<HTMLInputElement>(null);
  const defaultInput = useRef<HTMLInputElement>(null);
  const {
    authoringData,
    setAuthoringData,
    editingProblemStatuses,
    setEditingProblemStatuses,
    setSelectedProblem,
    setIsEditingProblem
  } = useAuthoringQuestionContext();
  const currentIssues = editingData.issues || [];

  //On change handle for text editor only
  const changeEditorHandle = useCallback(
    (identify: string, content: string) => {
      if (content === '<p><br></p>' || !editingData || identify !== editingNode) {
        return;
      }
      if (identify === 'title' || editingNode === 'title') {
        setEditingData((editingData) => ({ ...editingData, title: content }));
        return;
      }
      const newIssues = generateSliderIssues(identify, content, editingData.issues || []);
      setEditingData({ ...editingData, issues: newIssues });
    },
    [editingNode],
  );

  const validateSlider = (min: number, max: number, step: number) => {
    let errorArr: string[] = [];
    if (min >= max) {
      errorArr.push('最小値と最大値が間違っています');
    } else if (step > max - min) {
      errorArr.push('足音が長すぎる');
    }
    if (min) {
    }
    if (errorArr.length) {
      setErrors(errorArr);
      swalError();
      return false;
    }
    return true;
  };

  const removeHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    swalConfirm(() => {
      setIsEditingProblem(false);
      setAuthoringData({
        ...authoringData,
        problems: authoringData.problems?.filter(
          (problem) => Number(problem.id) !== Number(editingData.id),
        ),
      });
    });
  };

  const hideEditorHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);
    const newEditingProblemStatus: EditingProblemStatus = {
      id: editingData.id || 0,
      status: false,
    };
    const newEditingProblemStatuses = [...editingProblemStatuses, newEditingProblemStatus];
    setEditingProblemStatuses(filterUniqueArrayByKey(newEditingProblemStatuses.reverse()));
    setSelectedProblem();
  };

  const submitEditingDataHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditingProblem(false);
    let min = Number(minInput?.current?.value);
    let max = Number(maxInput?.current?.value);
    let step = Number(stepInput?.current?.value);

    let isError = validateSlider(min, max, step);
    if (!isError) {
      return;
    }

    const finalEditingData = {
      ...editingData,
      updated_at: new Date().getTime(),
    };

    const problemIndexed =
      authoringData.problems?.findIndex((problem) => problem.id === finalEditingData.id) || 0;

    let newProblems: SliderProblem[] = authoringData.problems || [];
    newProblems.splice(problemIndexed, 1, finalEditingData);

    setAuthoringData({ problems: newProblems });
    swalSuccess();
    setSelectedProblem();
  };

  const onChangeValueSlider = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
    name: string,
    isIssue: boolean,
  ) => {
    let value = Number(e.currentTarget?.value);
    let min = Number(editingData.min);
    let max = Number(editingData.max);
    //this is ref of input
    let minRef = Number(minInput?.current?.value);
    let maxRef = Number(maxInput?.current?.value);
    let newValue = Number(value);
    if (name === 'default_score') {
      newValue = validateLimit(value, 0, 100, 0, 100);
      const newIssues = editingData.issues?.map((issue) => ({
        ...issue,
        point: Number(newValue),
      }));
      setEditingData({ ...editingData, [name]: newValue, issues: newIssues });
    }
    if (name === 'min' || name === 'max') {
      const maxSlider = 99999;
      newValue = validateLimit(value, 0, maxSlider, 0, maxSlider);
      const newIssues = generateSliderIssues(
        'answerValue',
        Number(currentIssues[0].answerValue),
        editingData.issues || [],
        minRef,
        maxRef,
      );
      setEditingData({ ...editingData, issues: newIssues, [name]: newValue });
    }

    if (name === 'step') {
      newValue = validateLimit(value, 0, 100, 1, 100);
      setEditingData({ ...editingData, [name]: Number(newValue) });
    }

    if (isIssue === true) {
      if (name === 'point') {
        newValue = validateLimit(value, 0, 100, 1, 100);
      }
      const newIssues = generateSliderIssues(name, newValue, editingData.issues || [], min, max);
      setEditingData({ ...editingData, issues: newIssues });
    }
  };

  return (
    <form>
      <div className="mb-[10px] bg-secondary-extralight min-h-[500px] w-full p-[20px] relative">
        <div className="mb-[30px]"></div>
        <div className="flex gap-[13px]">
          <div className="w-[80%]">
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設問内容
              </div>
              <div className="w-full bg-white">
                <div className="p-[10px]" onClick={() => setEditingNode('title')}>
                  <AuthoringTextEditor
                    toolbarId={`toolbar_title`}
                    isReadOnly={editingNode !== 'title' && editingNode !== ''}
                    changeHandler={(htmlContent) => changeEditorHandle('title', htmlContent)}
                    value={editingData?.title || ''}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px]  w-full bg-primary h-0 min-h-[34px] flex items-center">
                正解・解答・配点
              </div>
              <div className="flex justify-center items-center p-[20px] bg-white flex-col !gap-[28px] min-h-[100px] rounded-b-[10px]">
                <div className=" w-full">
                  <AuthoringErrorBox errors={errors} />
                </div>
                <div className="w-[75%] flex gap-[10px] justify-center items-center">
                  <SliderQuestionInputRange
                    className={'basis-[90%]'}
                    answerValue={currentIssues?.[0].answerValue || 0}
                    setEditingData={setEditingData}
                    editingData={editingData}
                  />
                  <input
                    value={currentIssues?.[0].point || ''}
                    type="number"
                    name="point"
                    className={`flex basis-[10%] h-[32px] w-[34px] px-1 text-[12px] text-[400] text-center`}
                    ref={valueScore}
                    onChange={(e) => onChangeValueSlider(e, 'point', true)}
                    placeholder="0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-[20%] flex flex-col gap-y-[15px]">
            <div className="flex flex-col">
              <div className="p-[10px] text-[14px] font-[500] leading-[100%] text-white w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                設定
              </div>
              <div className="bg-white min-h-[100px] px-[10px]">
                <div className="mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">範囲を設定</div>
                  <div className={`flex gap-[15px] items-center mb-[5px]`}>
                    <div className="choice_limit flex items-center gap-[10px] mb-[5px] w-full">
                      <label className={`text-[12px] w-[25%]`}>下限</label>
                      <input
                        ref={minInput}
                        type="number"
                        name="min_Slider"
                        placeholder="0"
                        className="w-[71px] h-[32px] !pr-0"
                        onChange={(e) => {
                          onChangeValueSlider(e, 'min', false);
                        }}
                        value={editingData?.min || ''}
                      />
                    </div>
                  </div>
                  <div className={`flex gap-[15px] items-center`}>
                    <div className="choice_limit flex items-center gap-[10px] mb-[5px] w-full">
                      <label className={`text-[12px] w-[25%]`}>上限</label>
                      <input
                        ref={maxInput}
                        type="number"
                        name="max_slider"
                        placeholder="0"
                        className="w-[71px] h-[32px] !pr-0"
                        onChange={(e) => onChangeValueSlider(e, 'max', false)}
                        value={editingData?.max || ''}
                      />
                    </div>
                  </div>
                </div>

                <div className="choice_limit mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">ステップ</div>
                  <input
                    ref={stepInput}
                    type="number"
                    value={editingData?.step || ''}
                    className="w-[71px] h-[32px] !pr-0"
                    onChange={(e) => onChangeValueSlider(e, 'step', false)}
                    placeholder="1"
                  />
                </div>
                <div className="mb-[25px] m-[10px]">
                  <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                    配点初期値
                  </div>
                  <div className="flex gap-[8px] items-center font-[400] text-[14px] leading-[20px] mb-[5px]">
                    <input
                      ref={defaultInput}
                      type="number"
                      value={editingData?.default_score || ''}
                      className="w-[71px] h-[32px] !pr-0"
                      onChange={(e) => onChangeValueSlider(e, 'default_score', false)}
                      placeholder="0"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-white">
              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
                  onClick={submitEditingDataHandle}
                >
                  <LiaSave size={23} /> 保存
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={hideEditorHandle}
                >
                  キャンセル
                </button>
              </div>

              <div className="flex justify-center w-full mb-[20px]">
                <button
                  className="cursor-pointer gap-[5px] flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                  onClick={removeHandle}
                >
                  <PiTrash size={20} />
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SliderQuestionEdit;
