import React, { useState, useContext } from "react";
import { ChildrenWithProps, SetStateAction } from "../../types/globals";
import { sortArrayByKey } from "../../services/helpers/parseData";
import { useStorage } from "../../hooks/useStorage";
import { Exam } from "../../types/admin/exam";
import { NAME_ACTION_DETAIL_EXAM } from "../../services/constants/admin/pages/exam";

interface ListExamContextType {
  exams: Exam[];
  setExams: (exams: Exam[]) => void;
  selectedExam: Exam;
  setSelectedExam: (examId?: Exam["id"]) => void;
  selectedTargetExam: Exam;
  setSelectedTargetExam: (examId?: Exam["id"]) => void;
  sideAction: string;
  setSideAction: SetStateAction<string>;
  detailAcionName: string;
  setDetailActionName: SetStateAction<string>;
}

const ListExamContext = React.createContext<ListExamContextType>({
  exams: [],
  setExams: () => {},
  selectedExam: {},
  setSelectedExam: () => {},
  selectedTargetExam: {},
  setSelectedTargetExam: () => {},
  sideAction: "",
  setSideAction: () => {},
  detailAcionName: NAME_ACTION_DETAIL_EXAM.EDIT,
  setDetailActionName: () => {},
});

const ListExamProvider = ({ children }: ChildrenWithProps) => {
  const [exams, _setExams] = useState<Exam[]>([]);
  const [selectedExam, _setSelectedExam] = useState<Exam>({});
  const [selectedTargetExam, _setSelectedTargetExam] = useState<Exam>({});
  const [sideAction, setSideAction] = useState<string>("");
  const [detailAcionName, setDetailActionName] = useState<string>(
    NAME_ACTION_DETAIL_EXAM.EDIT
  );
  const { setLocalStorage } = useStorage();
  const storageKey = "selectedExam";

  const setExams = (exams: Exam[]) => {
    const uniqueExams = exams.filter(
      (exam, index, a) => a.findIndex((_exam) => _exam.id === exam.id) === index
    );
    _setExams(sortArrayByKey(uniqueExams));
  };

  const setSelectedExam = (examId?: Exam["id"]) => {
    setLocalStorage(storageKey, String(examId) || "");

    if (!examId) {
      _setSelectedExam({});
      return;
    }
    _setSelectedExam(exams.find((exam) => exam.id === examId) || {});
  };

  const setSelectedTargetExam = (examId?: Exam["id"]) => {
    if (!examId) {
      _setSelectedExam({});
      return;
    }
    _setSelectedTargetExam(exams.find((exam) => exam.id === examId) || {});
  };

  return (
    <ListExamContext.Provider
      value={{
        exams,
        setExams,
        selectedExam,
        setSelectedExam,
        selectedTargetExam,
        setSelectedTargetExam,
        sideAction,
        setSideAction,
        detailAcionName,
        setDetailActionName
      }}
    >
      {children}
    </ListExamContext.Provider>
  );
};

export default ListExamProvider;

export const useListExamContext = () => useContext(ListExamContext);
