import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { PiArrowFatDown, PiGitCommit } from "react-icons/pi";
import {
  MappingIssue,
  MappingPair,
  MappingProblemTest,
} from "../../../../../types/admin/question";
import { isUsableArr } from "../../../../../services/helpers/etc";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import { LuXCircle } from "react-icons/lu";
import AuthoringTextEditor from "../../../../admin/Question/AuthoringQuestion/QuestionEditorHandler/AuthoringTextEditor";
import {
  checkMappingDuplicated,
  reformatMapping,
} from "../../../../../services/utils/admin/question";
import { swalError } from "../../../../../services/helpers/swal";
import { MESSAGE_SPECIFIED } from "../../../../../services/constants/message";
import { useExaminationTestContext } from "../../../../../context/ExaminationTestContext";
import classNames from "classnames";

const MappingQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled,
}: {
  questionProblem: MappingProblemTest;
  handleUpdateProblem: (problem: MappingProblemTest) => void;
  isDisabled?: boolean;
}) => {
  const { questionProblems, setQuestionProblems } = useExaminationTestContext();
  const [editingNode, setEditingNode] = useState("");
  const [checkingIssue, _setCheckingIssue] = useState<MappingIssue>({});
  const [editingData, setEditingData] = useState<MappingProblemTest>(
    questionProblem || {}
  );
  // const [isShuffled, setIsShuffled] = useState<boolean>(false);
  const mappingCorrectPairs: MappingPair[] = editingData.correct_pairs || [];
  const currentIssues = editingData.issues || [];
  const currentPairs = editingData?.answer_data || [];

  const setCheckingIssue = (issueId?: number) => {
    if (!issueId) _setCheckingIssue({});
    _setCheckingIssue(
      currentIssues.find((issue) => Number(issue.id) === Number(issueId)) || {}
    );
  };

  const getIssueById = (issueId: number) =>
    currentIssues.find((issue) => issue.id === issueId);

  const issueAppearedCount = (issueId?: number) => {
    const appearedLeftPairs = currentPairs.filter(
      (pair) => pair.left === issueId
    );
    const appearedRightPairs = currentPairs.filter(
      (pair) => pair.right === issueId
    );
    return appearedLeftPairs.length + appearedRightPairs.length;
  };

  const isIssueAppearLimitExceed = (issueId?: number) => {
    if (!issueId) return false;
    const appearedCount = issueAppearedCount(issueId);
    const currentIssue = getIssueById(issueId);

    if (
      !!appearedCount &&
      appearedCount >= Number(currentIssue?.appear_limit)
    ) {
      return true;
    }

    return false;
  };

  const checkingIssueHandle = (issueId?: number) => {
    let resultIssueId = issueId;
    if (issueId === checkingIssue.id) {
      resultIssueId = 0;
    }
    setCheckingIssue(resultIssueId);
  };

  const addPairHandle = (direction: "left" | "right", pairPosition: number) => {
    if (!checkingIssue?.id || !currentPairs) return;
    const backupCurrentPairs = Array.from(currentPairs);
    const pairValue: MappingPair = {
      ...currentPairs[pairPosition],
      [direction]: checkingIssue?.id,
      // id: new Date().getTime(),
    };

    currentPairs.splice(pairPosition, 1);
    currentPairs.splice(pairPosition, 0, pairValue);

    if (!checkMappingDuplicated(currentPairs)) {
      swalError(MESSAGE_SPECIFIED.QUESTION.NO_DUPLICATE_ALLOWED);

      const editingDataNew = {
        ...editingData,
        answer_data: backupCurrentPairs,
      };
      setEditingData(editingDataNew);
      handleUpdateProblem(editingDataNew);
      return;
    }

    setEditingData({ ...editingData, answer_data: currentPairs });
    handleUpdateProblem({ ...editingData, answer_data: currentPairs });
    setCheckingIssue();
  };

  const removeCheckedIssueHandle = (
    direction: "left" | "right",
    pairPosition: number
  ) => {
    if (!direction || pairPosition === undefined || !currentPairs) return;
    const pairValue: MappingPair = {
      ...currentPairs[pairPosition],
      [direction]: undefined,
      // id: new Date().getTime(),
    };
    let newCurrentPairs = currentPairs;
    if (currentPairs.length > Number(editingData.min_choice)) {
      currentPairs.splice(pairPosition, 1);
      currentPairs.splice(pairPosition, 0, pairValue);
      newCurrentPairs = reformatMapping(currentPairs) || [];
    } else {
      currentPairs.splice(pairPosition, 1);
      currentPairs.splice(pairPosition, 0, pairValue);
    }

    setEditingData({ ...editingData, answer_data: newCurrentPairs });
    handleUpdateProblem({ ...editingData, answer_data: newCurrentPairs });
  };

  const shuffleArray = (array: MappingIssue[]) => {
    if (editingData.is_shuffled) {
      return array.sort(() => Math.random() - 0.5);
    }
  };

  useEffect(() => {
    if (questionProblem) {
      setEditingData(questionProblem);
    }
  }, [questionProblem]);

  useEffect(() => {
    if (!editingData) return;
    if (editingData.answer_data) return;

    let answerData: object[] = [];
    mappingCorrectPairs.forEach((mappingCorrectPair: MappingPair) => {
      if (mappingCorrectPair.id) {
        answerData.push({ id: mappingCorrectPair.id });
      }
    });

    setEditingData({ ...editingData, answer_data: answerData });
  }, [editingData]);

  const handleMouseUp = () => {
    const selector = window.getSelection();
    const textSelected = selector?.toString();
    if (!textSelected) return;
    const span = document.createElement("span");
    span.setAttribute("style", "background-color: yellow; display:inline;");
    span.innerText = textSelected;
    const newText = questionProblem.title?.replace(
      textSelected,
      span.outerHTML
    );

    setQuestionProblems(
      questionProblems.map((question) => {
        if (question.id === questionProblem.id) {
          return { ...question, title: newText };
        }

        return question;
      })
    );
  };

  return (
    <div className="max-h-full overflow-y-auto">
      <div
        className="mb-[50px]"
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(editingData.title || "<div></div>"),
        }}
        onMouseUp={handleMouseUp}
      ></div>
      <div className="justify-center items-center flex flex-col gap-[28px]">
        <div className="h-full px-[10px] py-[20px] w-[90%]">
          <ul className="px-0 list-none listbox-dot h-full flex flex-wrap justify-center">
            {isUsableArr(currentIssues) &&
              currentIssues &&
              currentIssues.map((issue, index) => (
                <div
                  className={`mb-[15px] flex items-center gap-[10px] px-[10px] w-[190px] 
                          ${
                            isIssueAppearLimitExceed(issue.id)
                              ? "pointer-events-none"
                              : ""
                          }`}
                  key={index}
                >
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => !isDisabled && checkingIssueHandle(issue.id)}
                  >
                    <input
                      type="checkbox"
                      name={`issue_data_checked_${index}`}
                      className="pointer-events-none rounded-full bg-secondary-light w-[20px] h-[20px]"
                      id=""
                    />
                  </div>
                  <li
                    className="w-full"
                    onClick={() => setEditingNode(`issue_data_label_${index}`)}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeHTMLEntities(
                          issue.label ?? "<div></div>"
                        ),
                      }}
                      className={classNames(
                        "px-[20px] py-[8px] border border-secondary-lighter rounded-[5px]",
                        {
                          "bg-secondary-light pointer-events-none":
                            isIssueAppearLimitExceed(issue.id) ||
                            checkingIssue?.id === issue.id,
                        }
                      )}
                    ></div>
                  </li>
                </div>
              ))}
          </ul>
        </div>
        <div className="text-[#EBE4D8]">
          <PiArrowFatDown size={50} />
        </div>
        <div className="h-full px-[15px] py-[20px] flex flex-wrap gap-[10px] items-center w-[90%] justify-start">
          {isUsableArr(currentPairs) &&
            currentPairs?.map((pair, index) => (
              <div
                className="flex items-center justify-center gap-[15px] w-full"
                key={index}
              >
                <div className="flex justify-start gap-[10px] items-center w-[190px]">
                  <div
                    className={`w-full border border-dashed ${
                      checkingIssue?.id
                        ? "bg-primary-extralight border-primary-light"
                        : "border-secondary-light"
                    } min-h-[50px] ${
                      checkingIssue.id ? "cursor-pointer" : "cursor-default"
                    }`}
                    onClick={() => addPairHandle("left", index)}
                  >
                    {pair.left ? (
                      <div className="w-full flex items-center justify-start gap-[7px] p-[5px]">
                        <div className="w-[67%] ml-[10px] mr-[5px]">
                          <div
                            className="px-[10px] py-[6px] border border-secondary-light rounded-[5px]"
                            dangerouslySetInnerHTML={{
                              __html: decodeHTMLEntities(
                                getIssueById(pair.left)?.label ?? "<div></div>"
                              ),
                            }}
                          ></div>
                        </div>
                        <div
                          className="text-[20px] text-secondary-light cursor-pointer"
                          onClick={() =>
                            removeCheckedIssueHandle("left", index)
                          }
                        >
                          <LuXCircle />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <PiGitCommit size={26} />
                <div className="flex justify-start gap-[10px] items-center w-[190px]">
                  <div
                    className={`w-full border border-dashed ${
                      checkingIssue?.id
                        ? "bg-primary-extralight border-primary-light"
                        : "border-secondary-light"
                    } min-h-[50px] ${
                      checkingIssue.id ? "cursor-pointer" : "cursor-default"
                    }`}
                    onClick={() => addPairHandle("right", index)}
                  >
                    {pair.right ? (
                      <div className="w-full flex items-center justify-start gap-[7px] p-[5px]">
                        <div className="w-[67%] ml-[10px] mr-[5px]">
                          <div
                            className="px-[10px] py-[6px] border border-secondary-light rounded-[5px]"
                            dangerouslySetInnerHTML={{
                              __html: decodeHTMLEntities(
                                getIssueById(pair.right)?.label ?? "<div></div>"
                              ),
                            }}
                          ></div>
                        </div>
                        <div
                          className="text-[20px] text-secondary-light cursor-pointer"
                          onClick={() =>
                            removeCheckedIssueHandle("right", index)
                          }
                        >
                          <LuXCircle />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MappingQuestion;
