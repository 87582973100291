import * as React from "react";

const List = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.06748 6.50075C5.34457 6.19287 5.31961 5.71865 5.01173 5.44156C4.70385 5.16447 4.22963 5.18942 3.95254 5.49731L3.94254 5.50842C3.66544 5.8163 3.6904 6.29052 3.99828 6.56761C4.30616 6.84471 4.78038 6.81975 5.05748 6.51186L5.06748 6.50075ZM8.5 5.25012C8.08579 5.25012 7.75 5.58591 7.75 6.00012C7.75 6.41434 8.08579 6.75012 8.5 6.75012L20.5 6.75012C20.9142 6.75012 21.25 6.41434 21.25 6.00012C21.25 5.58591 20.9142 5.25012 20.5 5.25012L8.5 5.25012ZM5.01173 11.4416C5.31961 11.7187 5.34457 12.1929 5.06748 12.5008L5.05748 12.5119C4.78038 12.8197 4.30616 12.8447 3.99828 12.5676C3.6904 12.2905 3.66544 11.8163 3.94254 11.5084L3.95254 11.4973C4.22963 11.1894 4.70385 11.1645 5.01173 11.4416ZM5.06748 18.5008C5.34457 18.1929 5.31961 17.7187 5.01173 17.4416C4.70385 17.1645 4.22963 17.1894 3.95254 17.4973L3.94254 17.5084C3.66544 17.8163 3.6904 18.2905 3.99828 18.5676C4.30616 18.8447 4.78038 18.8197 5.05748 18.5119L5.06748 18.5008ZM7.75 12.0001C7.75 11.5859 8.08579 11.2501 8.5 11.2501L20.5 11.2501C20.9142 11.2501 21.25 11.5859 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501L8.5 12.7501C8.08579 12.7501 7.75 12.4143 7.75 12.0001ZM8.5 17.2501C8.08579 17.2501 7.75 17.5859 7.75 18.0001C7.75 18.4143 8.08579 18.7501 8.5 18.7501L20.5 18.7501C20.9142 18.7501 21.25 18.4143 21.25 18.0001C21.25 17.5859 20.9142 17.2501 20.5 17.2501L8.5 17.2501Z"
      fill={`${props.color || "#7A7A7A"}`}
    />
  </svg>
);

export default List;
