import React, { ChangeEvent, useEffect, useState } from "react";
import {
  QuestionProblem,
  WritingProblem,
} from "../../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../../services/helpers/parseData";
import AuthoringTextEditor from "../../../../Question/AuthoringQuestion/QuestionEditorHandler/AuthoringTextEditor";
import { handleProblemScoreCalculator } from "../../../../Question/AuthoringQuestion/QuestionEditorHandler";

const WritingQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled
}: {
  questionProblem: QuestionProblem;
  handleUpdateProblem: (problem: WritingProblem) => void;
  isDisabled: boolean;
}) => {
  const [writingQuestion, setWritingQuestion] =
    useState<WritingProblem>(questionProblem);

  const handleChangePoint = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const point = Number(value);
    if(isNaN(point)) return;
    
    const newWritingQuestion = { ...writingQuestion, [name]: point, total_score: point };
    setWritingQuestion(newWritingQuestion);
    handleUpdateProblem(newWritingQuestion);
  };

  // useEffect(() => {
  //   handleUpdateProblem(writingQuestion);
  // }, [writingQuestion]);

  return (
    <div className="w-full bg-white rounded-b-[10px] flex items-start justify-center gap-x-[5px]">
      <AuthoringTextEditor
        className={"w-full min-h-[200px] border rounded-[5px] p-[10px]"}
        toolbarId={`answerValue_${questionProblem.id}`}
        isReadOnly={true}
        value={writingQuestion?.answerValue || ""}
      />
      <input
        type="text"
        name="point"
        className="w-[45px] h-[40px] text-[14px] !border-secondary-light"
        value={writingQuestion.point}
        onChange={handleChangePoint}
        disabled={isDisabled}
      />
    </div>
  );
};

export default WritingQuestion;
