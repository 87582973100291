import React, { ChangeEvent, useEffect } from "react";
import { useState } from "react";
import CandidateTable from "./CandidateTable";
import CandidateFilter from "./CandidateFilter";
import { useCandidateContext } from "../../../context/CandidateContext";
import { USER_STATUS } from "../../../services/constants/user";
import CandidateTableCommonAction from "./CandidateTableCommonAction";
import { makeRequest } from "../../../services/axios/axios";
import {
  swalClose,
  swalError,
  swalLoading,
  swalSuccess,
  swalConfirm,
} from "../../../services/helpers/swal";
import { paramizeObject } from "../../../services/helpers/parseData";
import { API } from "../../../services/constants/route/api";
import { Candidate } from "../../../types/admin/candidate";
import LayoutWaitingApi from "../../commons/layouts/LayoutWaitingAPI";

type FilterType = {
  query: string;
  limit: number;
  page: number;
  status?: string;
  sortType?: number;
  group_id?: number | string;
};

const CandidateList = () => {
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    query: "",
    page: 1,
    sortType: 1,
    status: USER_STATUS.ACTIVE.toString(),
  });
  const [currentPage, setCurrentPage] = useState(1);
  const { candidates, setCandidates } = useCandidateContext();
  const [_candidates, _setCandidates] = useState<Candidate[]>(candidates || []);
  const { setTotalPages } = useCandidateContext();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMainGroup, setSelectedMainGroup] = useState<string>("");
  const [waitingApi, setWaitingApi] = useState<boolean>(true);

  const resetTable = () => {
    setSelectedIds([]);
    setSelectAll(false);
    setCurrentPage(1);
  };

  const checkSelectAll = (currentCandidates: Candidate[]) => {
    let currentPageCandidateIds = currentCandidates?.map(
      (candidate) => candidate.id
    );
    let allCurrentPageCandidatesSelected = currentPageCandidateIds.every((id) =>
      selectedIds.includes(id)
    );
    if (allCurrentPageCandidatesSelected) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedIds(candidates?.map((s) => s.id));
    } else {
      setSelectedIds([]);
    }
  };

  const deleteSelected = async () => {
    if (selectedIds.length === 0) {
      return;
    }
    swalConfirm(async () => {
      const result = await makeRequest({
        method: "delete",
        url: API.ADMIN_CANDIDATE.DELETE_CANDIDATE,
        data: { candidate_ids: selectedIds },
      });
      if (!result.status) {
        return swalError();
      }
      _setCandidates(_candidates?.filter((s) => !selectedIds.includes(s.id)));
      resetTable();
      swalSuccess();
      changePaginate(1);
    }, "チェックした受験者を削除して<br>よろしいですか？ ");
  };

  const toggleSelect = (id: number) => {
    if (selectedIds?.includes(id)) {
      setSelectedIds(selectedIds?.filter((sId) => sId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const isCandidateSelected = (id: number) => selectedIds?.includes(id);

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setWaitingApi(true);
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };
  useEffect(() => {
    _setCandidates(candidates);
  }, [candidates]);

  useEffect(() => {
    const fetch = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_CANDIDATE.GET_CANDIDATE_LIST}?${paramizeObject(
          filter
        )}`,
      });

      if (!result.data) return swalError();
      checkSelectAll(result.data.candidates);
      setCandidates(result.data.candidates);
      setTotalPages(result.data.total_page);
      setWaitingApi(false);
      swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  const changeHandle = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target.type === "checkbox") {
      const target = e.target as HTMLInputElement;
      if (target.checked) {
        setFilter({
          ...filter,
          status: "",
          page: 1,
        });
        resetTable();
        return;
      } else {
        setFilter({
          ...filter,
          status: USER_STATUS.ACTIVE.toString(),
          page: 1,
        });
        resetTable();
        return;
      }
    }
    if (e.target.name === "mainGroup") {
      setSelectedMainGroup(e.target.value);
      setFilter({ ...filter, group_id: e.target.value, page: 1 });
      resetTable();
      return;
    }
    if (e.target.name === "subGroup") {
      if (e.target.value === "") {
        setFilter({ ...filter, group_id: selectedMainGroup, page: 1 });
        resetTable();
        return;
      }
      setFilter({ ...filter, group_id: e.target.value, page: 1 });
      resetTable();
      return;
    }
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };
  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };
  return (
    <div>
      <CandidateTableCommonAction />
      <CandidateFilter
        changeHandle={changeHandle}
        deleteSelected={deleteSelected}
        selectedMainGroup={selectedMainGroup}
      />
      <LayoutWaitingApi waitingApi={waitingApi} className="w-full h-[100px]">
        <CandidateTable
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          _candidates={_candidates}
          toggleSelect={toggleSelect}
          selectAll={selectAll}
          toggleSelectAll={toggleSelectAll}
          isCandidateSelected={isCandidateSelected}
        />
      </LayoutWaitingApi>
    </div>
  );
};

export default CandidateList;
