import React from 'react';

type StatusButtonDisplayProps = {
  status?: number;
};

const StatusButtonDisplay = ({ status }: StatusButtonDisplayProps) => {
  if (status)
    return (
      <div className="px-[6px] pt-[3px] pb-[5px] text-danger border border-danger rounded-[5px] cursor-default">
        有効
      </div>
    );
  return (
    <div className="px-[6px] pt-[3px] pb-[5px] text-white bg-secondary-light rounded-[5px] cursor-default">
      無効
    </div>
  );
};

export default StatusButtonDisplay;
