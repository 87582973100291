/* eslint-disable react-hooks/exhaustive-deps */
import { PiArrowFatDown, PiGitCommit } from "react-icons/pi";
import {
  isEmptyObj,
  isUsableArr,
} from "../../../../../../../services/helpers/etc";
import {
  decodeHTMLEntities,
  shuffle,
} from "../../../../../../../services/helpers/parseData";
import {
  checkMappingDuplicated,
  checkMappingFilled,
  clearEmptyMapping,
  generateOrderIssues,
} from "../../../../../../../services/utils/admin/question";
import {
  MappingIssue,
  MappingPair,
  MappingProblem,
} from "../../../../../../../types/admin/question";
import AuthoringTextEditor from "../../AuthoringTextEditor";
import { RenderDroppedQuestionTypeProps } from "../../DroppedQuestionType";
import { useState, useCallback, useEffect, useMemo } from "react";
import { LuXCircle } from "react-icons/lu";
import AuthoringErrorBox from "../AuthoringMessageBox";
import { swalError } from "../../../../../../../services/helpers/swal";
import { MESSAGE_SPECIFIED } from "../../../../../../../services/constants/message";

type MappingQuestionProps = {
  problemPreview?: MappingProblem;
  actionable?: boolean;
} & Omit<RenderDroppedQuestionTypeProps, "draggedName" | "problemPreview">;

const MappingQuestion = ({
  problemPreview,
  actionable = false,
}: MappingQuestionProps) => {
  const [editingNode, setEditingNode] = useState("");
  const [editingData, setEditingData] = useState<MappingProblem>(
    problemPreview || {}
  );
  const [currentPairs, setCurrentPairs] = useState<MappingPair[]>(
    actionable ? editingData?.correct_pairs || [] : [{}]
  );
  const [errors] = useState<string[]>([]);
  const currentIssues = useMemo(
    () =>
      problemPreview?.is_shuffled
        ? shuffle(problemPreview?.issues || [])
        : problemPreview?.issues || [],
    [problemPreview?.updated_at]
  );

  useEffect(() => {
    setEditingData(problemPreview || {});
  }, [problemPreview]);

  useEffect(() => {
    const minChoice = editingData.min_choice;
    const maxChoice = editingData.max_choice;
    let lengthCorrectPair = 1;

    if (minChoice && !maxChoice) lengthCorrectPair = minChoice;

    if (maxChoice) lengthCorrectPair = maxChoice;

    setCurrentPairs(Array(lengthCorrectPair).fill({}));
  }, [editingData]);

  const [checkingIssue, _setCheckingIssue] = useState<MappingIssue>({});
  const setCheckingIssue = (issueId?: number) => {
    if (!issueId) _setCheckingIssue({});
    _setCheckingIssue(
      currentIssues.find((issue) => Number(issue.id) === Number(issueId)) || {}
    );
  };

  //On change handle for text editor only
  const changeEditorHandle = useCallback(
    (identify: string, content: string) => {
      if (
        content === "<p><br></p>" ||
        !editingData ||
        editingData[identify as keyof MappingProblem] === content ||
        identify !== editingNode
      ) {
        return;
      }

      if (identify === "title" || editingNode === "title") {
        setEditingData({ ...editingData, title: content });
        return;
      }

      const newIssues = generateOrderIssues(
        identify,
        content,
        editingData.issues || []
      );
      setEditingData({ ...editingData, issues: newIssues });
    },
    [editingNode]
  );

  const pairChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    pairId?: number
  ) => {
    if (!e.currentTarget) return;
    const { value } = e.currentTarget;

    let parsedValue: string | boolean = value;

    if (Number(value) > 100) {
      parsedValue = "100";
    }

    if (Number(value) < 0) {
      parsedValue = "0";
    }

    const currentPair = currentPairs?.find((pair) => pair.id === pairId);
    const newCurrentPair = {
      ...currentPair,
      point: Number(parsedValue),
    };
    const currentPairIndex =
      currentPairs?.findIndex((pair) => pair.id === pairId) || 0;
    currentPairs?.splice(currentPairIndex, 1, newCurrentPair);

    setEditingData({ ...editingData, correct_pairs: currentPairs });
  };

  const addPairHandle = (direction: "left" | "right", pairPosition: number) => {
    if (!checkingIssue?.id || !currentPairs) return;
    const backupCurrentPairs = Array.from(currentPairs);
    const pairValue: MappingPair = {
      ...currentPairs[pairPosition],
      [direction]: checkingIssue?.id,
      id: new Date().getTime(),
    };

    currentPairs.splice(pairPosition, 1);
    currentPairs.splice(pairPosition, 0, pairValue);

    if (!checkMappingDuplicated(currentPairs)) {
      swalError(MESSAGE_SPECIFIED.QUESTION.NO_DUPLICATE_ALLOWED);

      setEditingData({ ...editingData, correct_pairs: backupCurrentPairs });
      return;
    }

    // full filled, then add pair space
    if (checkMappingFilled(currentPairs)) {
      currentPairs.splice(pairPosition + 1, 0, {});
    }

    setEditingData({ ...editingData, correct_pairs: currentPairs });
    setCheckingIssue();
  };

  const removeCheckedIssueHandle = (
    direction: "left" | "right",
    pairPosition: number
  ) => {
    if (!direction || pairPosition === undefined || !currentPairs) return;
    const pairValue: MappingPair = {
      ...currentPairs[pairPosition],
      [direction]: undefined,
      id: new Date().getTime(),
    };

    currentPairs.splice(pairPosition, 1);
    currentPairs.splice(pairPosition, 0, pairValue);

    const newCurrentPairs = clearEmptyMapping(currentPairs);

    setEditingData({ ...editingData, correct_pairs: newCurrentPairs });
  };

  const checkedIssueHandle = (issueId?: number) => {
    let resultIssueId = issueId;
    if (issueId === checkingIssue.id) {
      resultIssueId = 0;
    }
    setCheckingIssue(resultIssueId);
  };

  const getIssueById = (issueId: number) =>
    currentIssues.find((issue) => issue.id === issueId);

  return (
    <div
      className={`text-secondary-dark ${
        actionable ? "" : "pointer-events-none"
      }`}
    >
      <div className="">
        <div
          dangerouslySetInnerHTML={{
            __html: decodeHTMLEntities(editingData?.title || ""),
          }}
        ></div>
      </div>
      <div className="justify-center items-center p-[20px] bg-white flex flex-col gap-[28px]">
        <div className="border-[2px] border-[#E5E5E5] h-full px-[10px] py-[20px] w-[90%]">
          <ul className="px-0 list-none listbox-dot h-full flex flex-wrap">
            {isUsableArr(currentIssues) &&
              currentIssues &&
              currentIssues.map((issue, index) => (
                <div
                  className="mb-[15px] flex items-center gap-[10px] px-[10px] w-[190px]"
                  key={index}
                >
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => checkedIssueHandle(issue.id)}
                  >
                    <input
                      type="checkbox"
                      name={`issue_data_checked_${index}`}
                      className="pointer-events-none"
                      id=""
                      disabled
                    />
                  </div>
                  <li
                    className="w-full"
                    onClick={() => setEditingNode(`issue_data_label_${index}`)}
                  >
                    <AuthoringTextEditor
                      toolbarId={`toolbar_issue_${index}_preview_${issue.id}`}
                      isReadOnly={editingNode !== `issue_data_label_${index}`}
                      changeHandler={(htmlContent) =>
                        changeEditorHandle(
                          `issue_data_label_${index}`,
                          htmlContent
                        )
                      }
                      value={issue.label || ""}
                      isDisabled={checkingIssue?.id === issue.id}
                    />
                  </li>
                </div>
              ))}
          </ul>
        </div>
        <div className="text-[#EBE4D8]">
          <PiArrowFatDown size={50} />
        </div>
        <div className="border-[2px] border-[#E5E5E5] h-full px-[15px] py-[20px] flex flex-wrap gap-[10px] items-center w-[90%] justify-start">
          {isUsableArr(currentPairs) &&
            currentPairs?.map((pair, index) => (
              <div
                className="flex items-center justify-center gap-[15px] w-full"
                key={index}
              >
                <div className="flex justify-start gap-[10px] items-center w-[190px]">
                  <div
                    className={`w-full border border-dashed ${
                      checkingIssue?.id
                        ? "bg-primary-extralight border-primary-light"
                        : "border-secondary-light"
                    } min-h-[50px] ${
                      checkingIssue.id ? "cursor-pointer" : "cursor-default"
                    }`}
                    onClick={() => addPairHandle("left", index)}
                  >
                    {pair.left ? (
                      <div className="w-full flex items-center justify-start gap-[7px] p-[5px]">
                        <div className="w-[67%] ml-[10px] mr-[5px]">
                          <AuthoringTextEditor
                            toolbarId={`toolbar_issue_${
                              currentIssues.findIndex(
                                (crIssue) => crIssue.id === pair.left
                              ) || 0
                            }_${index}_left_disabled_preview_${pair.id}`}
                            isReadOnly
                            value={getIssueById(pair.left)?.label || ""}
                          />
                        </div>
                        <div
                          className="text-[20px] text-secondary-light cursor-pointer"
                          onClick={() =>
                            removeCheckedIssueHandle("left", index)
                          }
                        >
                          <LuXCircle />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <PiGitCommit size={26} />
                <div className="flex justify-start gap-[10px] items-center w-[190px]">
                  <div
                    className={`w-full border border-dashed ${
                      checkingIssue?.id
                        ? "bg-primary-extralight border-primary-light"
                        : "border-secondary-light"
                    } min-h-[50px] ${
                      checkingIssue.id ? "cursor-pointer" : "cursor-default"
                    }`}
                    onClick={() => addPairHandle("right", index)}
                  >
                    {pair.right ? (
                      <div className="w-full flex items-center justify-start gap-[7px] p-[5px]">
                        <div className="w-[67%] ml-[10px] mr-[5px]">
                          <AuthoringTextEditor
                            toolbarId={`toolbar_issue_${
                              currentIssues.findIndex(
                                (crIssue) => crIssue.id === pair.right
                              ) || 0
                            }_${index}_right_disabled_preview_${pair.id}`}
                            isReadOnly
                            value={getIssueById(pair.right)?.label || ""}
                          />
                        </div>
                        <div
                          className="text-[20px] text-secondary-light cursor-pointer"
                          onClick={() =>
                            removeCheckedIssueHandle("right", index)
                          }
                        >
                          <LuXCircle />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {actionable ? (
                  <div className="">
                    <input
                      type="number"
                      name="point"
                      id=""
                      className="w-[35px] h-[35px] !border-secondary-light !p-[5px]"
                      placeholder="0"
                      onChange={(e) => pairChangeHandle(e, pair.id)}
                      value={pair.point || ""}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MappingQuestion;
