import React from "react";
import CBTEnterCode from "../../../components/candidate/Examination/CBTEnterCode";
import ExaminationTestBase from "./ExaminationTestBase";

const ExaminationEnterCodePage = () => {
  return (
    <ExaminationTestBase>
      <CBTEnterCode />
    </ExaminationTestBase>
  );
};

export default ExaminationEnterCodePage;
