import React, { useContext, useEffect, useState } from "react";
import {
  ExamPartSettingData,
  ExamSectionSettingData,
} from "../types/admin/exam";
import { ChildrenWithProps } from "../types/globals";
import { ExamTestType } from "../types/candidate/examination";
import { RegisterExaminationType } from "../types/candidate/registerExamination";
import { QuestionProblem, QuestionProblemTest } from "../types/admin/question";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { useNavigate } from "react-router-dom";
import { PATH_EXAMINATION } from "../services/constants/route/router";
import { PartialCandidateExamHistory } from "../types/candidate/candidateExamHistory";
import {
  CandidateExamItemTest,
  CandidateExamPartTest,
  CandidateExamSectionTest,
} from "../types/candidate/exam";
import { isEmptyObj, isUsableArr } from "../services/helpers/etc";
import { useStorage } from "../hooks/useStorage";
import { HTTP_CODE } from "../services/constants/admin/main";
import { handleCheckAnswerExamSection } from "../services/utils/candidate/examinationTest";

type FilterQuestionKey = {
  sectionId: number;
  itemId: number;
};

type ExaminationStatusType = {
  status: number;
  message: string;
};

interface ExaminationTestContextType {
  candidateExamParts: PartialCandidateExamHistory[];
  setCandidateExamParts: React.Dispatch<
    React.SetStateAction<PartialCandidateExamHistory[]>
  >;
  examParts: CandidateExamPartTest[];
  setExamParts: React.Dispatch<React.SetStateAction<CandidateExamPartTest[]>>;
  examPartSelected: CandidateExamPartTest;
  setExamPartSelected: React.Dispatch<
    React.SetStateAction<CandidateExamPartTest>
  >;
  examSectionSelected: CandidateExamSectionTest;
  setExamSectionSelected: React.Dispatch<
    React.SetStateAction<CandidateExamSectionTest>
  >;
  examItemSelected: CandidateExamItemTest;
  setExamItemSelected: React.Dispatch<
    React.SetStateAction<CandidateExamItemTest>
  >;
  exam: ExamTestType;
  setExam: React.Dispatch<React.SetStateAction<ExamTestType>>;
  registerExamination: RegisterExaminationType;
  setRegisterExamination: React.Dispatch<
    React.SetStateAction<RegisterExaminationType>
  >;
  filterQuestionKey: FilterQuestionKey;
  setFilterQuestionKey: React.Dispatch<React.SetStateAction<FilterQuestionKey>>;
  questionProblems: QuestionProblemTest[];
  setQuestionProblems: React.Dispatch<
    React.SetStateAction<QuestionProblemTest[]>
  >;
  examPartQuestions: CandidateExamItemTest[];
  setExamPartQuestions: React.Dispatch<
    React.SetStateAction<CandidateExamItemTest[]>
  >;
  handleSubmitSaveExamPart: () => void;
  time: number;
  setTime: React.Dispatch<React.SetStateAction<number>>;
  examPartProperties: ExamPartSettingData;
  setExamPartProperties: React.Dispatch<
    React.SetStateAction<ExamPartSettingData>
  >;
  examSectionProperties: ExamSectionSettingData;
  setExamSectionProperties: React.Dispatch<
    React.SetStateAction<ExamSectionSettingData>
  >;
  textSize: number;
  setTextSize: React.Dispatch<React.SetStateAction<number>>;
  isLastQuestionOfSection: (
    examSectionId: number,
    examItemId: number
  ) => boolean;
  isFirstQuestionOfSection: (
    examSectionId: number,
    examItemId: number
  ) => boolean;
  isLastSectionOfExamPart: () => boolean;
  isFirstSectionOfExamPart: () => boolean;
  openingTools: string[];
  setOpeningTools: React.Dispatch<React.SetStateAction<string[]>>;
  isChangeAnswer: boolean;
  setIsChangeAnswer: React.Dispatch<React.SetStateAction<boolean>>;
  isMaxTimesAnswer: boolean;
  setIsMaxTimesAnswer: React.Dispatch<React.SetStateAction<boolean>>;
  examinationStatus: ExaminationStatusType;
  setExaminationStatus: React.Dispatch<
    React.SetStateAction<ExaminationStatusType>
  >;
  waitingAPI: boolean;
  setWaitingAPI: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExaminationTestContext = React.createContext<ExaminationTestContextType>({
  candidateExamParts: [],
  setCandidateExamParts: () => {},
  examParts: [],
  setExamParts: () => {},
  examPartSelected: {},
  setExamPartSelected: () => {},
  exam: {},
  setExam: () => {},
  registerExamination: {},
  setRegisterExamination: () => {},
  examSectionSelected: {},
  setExamSectionSelected: () => {},
  examItemSelected: {},
  setExamItemSelected: () => {},
  filterQuestionKey: { sectionId: 0, itemId: 0 },
  setFilterQuestionKey: () => {},
  questionProblems: [],
  setQuestionProblems: () => {},
  examPartQuestions: [],
  setExamPartQuestions: () => {},
  handleSubmitSaveExamPart: () => {},
  time: 0,
  setTime: () => {},
  examSectionProperties: {},
  setExamSectionProperties: () => {},
  examPartProperties: {},
  setExamPartProperties: () => {},
  textSize: 12,
  setTextSize: () => {},
  isLastQuestionOfSection: () => false,
  isFirstQuestionOfSection: () => false,
  isLastSectionOfExamPart: () => false,
  isFirstSectionOfExamPart: () => false,
  openingTools: [],
  setOpeningTools: () => {},
  isChangeAnswer: false,
  setIsChangeAnswer: () => {},
  isMaxTimesAnswer: false,
  setIsMaxTimesAnswer: () => {},
  examinationStatus: {
    status: 0,
    message: "",
  },
  setExaminationStatus: () => {},
  waitingAPI: false,
  setWaitingAPI: () => {},
});

const ExaminationTestProvider = ({ children }: ChildrenWithProps) => {
  const [candidateExamParts, setCandidateExamParts] = useState<
    PartialCandidateExamHistory[]
  >([]);
  const [examParts, setExamParts] = useState<CandidateExamPartTest[]>([]);
  const [examPartSelected, setExamPartSelected] =
    useState<CandidateExamPartTest>({});
  const [examSectionSelected, setExamSectionSelected] =
    useState<CandidateExamSectionTest>({});
  const [examItemSelected, setExamItemSelected] =
    useState<CandidateExamItemTest>({});
  const [exam, setExam] = useState<ExamTestType>({});
  const [registerExamination, setRegisterExamination] =
    useState<RegisterExaminationType>({});
  const [filterQuestionKey, setFilterQuestionKey] = useState<FilterQuestionKey>(
    { sectionId: 0, itemId: 0 }
  );
  const [questionProblems, setQuestionProblems] = useState<QuestionProblem[]>(
    []
  );
  const [examPartQuestions, setExamPartQuestions] = useState<
    CandidateExamItemTest[]
  >([]);
  const [time, setTime] = useState<number>(1);
  const [examPartProperties, setExamPartProperties] =
    useState<ExamPartSettingData>({});
  const [examSectionProperties, setExamSectionProperties] =
    useState<ExamSectionSettingData>({});
  const [textSize, setTextSize] = useState<number>(16);
  const [openingTools, setOpeningTools] = useState<string[]>([]);
  const [isChangeAnswer, setIsChangeAnswer] = useState<boolean>(false);
  const [isMaxTimesAnswer, setIsMaxTimesAnswer] = useState<boolean>(false);
  const [examinationStatus, setExaminationStatus] =
    useState<ExaminationStatusType>({
      status: 0,
      message: "",
    });
  const [waitingAPI, setWaitingAPI] = useState<boolean>(false);
  const navigate = useNavigate();
  const { removeLocalStorage } = useStorage();

  const handleSubmitSaveExamPart = async () => {
    setWaitingAPI(true);
    const handleScoreExamPart = handleCheckAnswerExamSection(
      examPartSelected?.exam_sections || []
    );
    
    const result = await makeRequest({
      method: "post",
      url: API.CANDIDATE_EXAM_HISTORY.SAVE_CANDIDATE_EXAM_PART_HISTORY,
      data: {
        register_examination_id: registerExamination.id,
        test_part_id: examPartSelected.id,
        exam_sections: handleScoreExamPart.examSections,
        total_score: handleScoreExamPart.totalScore,
        total_answer_correct: handleScoreExamPart.totalAnswerCorrect,
        time: time,
      },
    });

    setWaitingAPI(false);

    if (result.code === HTTP_CODE.FORBIDDEN) {
      navigate("/404");
      return;
    }

    navigate(
      `${PATH_EXAMINATION.EXAMINATION_PART_LIST}?id=${registerExamination.id}`
    );

    removeLocalStorage(["register_examination", "exam_part_selected"]);
  };

  const isLastQuestionOfSection = (
    examSectionId: number,
    examItemId: number
  ): boolean => {
    if (examSectionSelected) {
      const questionSectionLength = examSectionSelected.exam_items?.length || 0;
      const indexCurrentQuestion = examSectionSelected.exam_items?.findIndex(
        (question) => {
          return (
            question.id === examItemId &&
            question.exam_part_id === examSectionId
          );
        }
      );

      if (indexCurrentQuestion === -1) return false;

      return questionSectionLength - 1 === indexCurrentQuestion;
    }

    return false;
  };

  const isFirstQuestionOfSection = (
    examSectionId: number,
    examItemId: number
  ): boolean => {
    if (examSectionSelected) {
      const indexCurrentQuestion = examSectionSelected.exam_items?.findIndex(
        (question) => {
          return (
            question.id === examItemId &&
            question.exam_part_id === examSectionId
          );
        }
      );

      if (indexCurrentQuestion === -1) return false;

      return indexCurrentQuestion === 0;
    }

    return false;
  };

  const isLastSectionOfExamPart = (): boolean => {
    if (examSectionSelected) {
      const examPartSectionLength = examPartSelected.exam_sections?.length || 0;
      const indexCurrentSection = examPartSelected.exam_sections?.findIndex(
        (section) => {
          return section.id === examSectionSelected.id;
        }
      );

      if (indexCurrentSection === -1) return false;

      return examPartSectionLength - 1 === indexCurrentSection;
    }

    return false;
  };

  const isFirstSectionOfExamPart = (): boolean => {
    if (examSectionSelected) {
      const indexCurrentSection = examPartSelected.exam_sections?.findIndex(
        (section) => {
          return section.id === examSectionSelected.id;
        }
      );

      return indexCurrentSection === 0;
    }

    return false;
  };

  useEffect(() => {
    if (examPartSelected) {
      let examQuestions: CandidateExamItemTest[] = [];

      examPartSelected?.exam_sections?.map((examSection) => {
        if (examSection.exam_items && examSection.exam_items) {
          let newExamItems = examSection.exam_items || [];

          examQuestions = [...examQuestions, ...newExamItems];
        }
      });

      setExamPartQuestions(examQuestions);
      setExamPartProperties(examPartSelected.setting_properties || {});
    }
  }, [examPartSelected]);

  useEffect(() => {
    if (
      isUsableArr(examPartQuestions) &&
      filterQuestionKey.sectionId === 0 &&
      filterQuestionKey.itemId === 0
    ) {
      const examSections = examPartSelected.exam_sections || [];
      const examItemFirst = examPartQuestions[0];
      if (isUsableArr(examSections) && !isEmptyObj(examItemFirst)) {
        const findExamSection = examSections.find(
          (examSection) => examSection.id === examItemFirst.exam_part_id
        );
        if (findExamSection) {
          setExamSectionSelected(findExamSection);
          setExamItemSelected(examItemFirst);
          setFilterQuestionKey({
            sectionId: examItemFirst.exam_part_id || 0,
            itemId: examItemFirst.id || 0,
          });
        }
      }
    }
  }, [examPartQuestions]);

  useEffect(() => {
    if (examSectionSelected) {
      setExamSectionProperties(examSectionSelected.setting_properties || {});
    }
  }, [examSectionSelected]);

  return (
    <ExaminationTestContext.Provider
      value={{
        candidateExamParts,
        setCandidateExamParts,
        examParts,
        setExamParts,
        exam,
        setExam,
        examPartSelected,
        setExamPartSelected,
        registerExamination,
        setRegisterExamination,
        examSectionSelected,
        setExamSectionSelected,
        examItemSelected,
        setExamItemSelected,
        filterQuestionKey,
        setFilterQuestionKey,
        questionProblems,
        setQuestionProblems,
        examPartQuestions,
        setExamPartQuestions,
        handleSubmitSaveExamPart,
        time,
        setTime,
        examPartProperties,
        setExamPartProperties,
        examSectionProperties,
        setExamSectionProperties,
        textSize,
        setTextSize,
        isLastQuestionOfSection,
        isFirstQuestionOfSection,
        isLastSectionOfExamPart,
        isFirstSectionOfExamPart,
        openingTools,
        setOpeningTools,
        isChangeAnswer,
        setIsChangeAnswer,
        isMaxTimesAnswer,
        setIsMaxTimesAnswer,
        examinationStatus,
        setExaminationStatus,
        waitingAPI,
        setWaitingAPI,
      }}
    >
      {children}
    </ExaminationTestContext.Provider>
  );
};

export default ExaminationTestProvider;

export const useExaminationTestContext = () =>
  useContext(ExaminationTestContext);
