import classNames from "classnames";
import React from "react";
import Spinner from "../../commons/icons/Animations/Spinner";
import { BiSave } from "react-icons/bi";

type SpinnerButtonProps = {
  name?: string;
  waitingAPI: boolean;
  className?: string;
  onSubmit: () => void;
  type?: 'submit' | 'reset' | 'button' | undefined;
};

const SpinnerButton = ({
  name,
  waitingAPI,
  className,
  onSubmit,
  type,
}: SpinnerButtonProps) => {
  return (
    <button
      className={classNames(
        `min-w-[156px] max-w-[160px] flex items-center justify-center gap-x-2 px-[30px] py-[8px] bg-primary text-white text-[16px] font-bold rounded-[5px] ${className}`,
        {
          "opacity-80 hover:bg-none": waitingAPI,
        }
      )}
      onClick={onSubmit}
      disabled={waitingAPI}
      type={type}
    >
      {waitingAPI ? (
        <Spinner />
      ) : (
        <>
          <BiSave size={24} />
          <span>{name ?? "保存する"}</span>
        </>
      )}
    </button>
  );
};

export default SpinnerButton;
