import AWS, { S3 } from "aws-sdk";
import { swalMessage } from "../../helpers/swal";
import { DeleteObjectOutput } from "aws-sdk/clients/s3";

const s3 = new S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const uploadFileToS3 = async (file: File | Blob, pathFile: string) => {
    if (!file || !pathFile.length) return;

    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
    AWS.config.update({
        accessKeyId,
        secretAccessKey,
    });

    // Upload file on S3.
    let contentType = file.type;
    const upload = new S3.ManagedUpload({
        params: {
            Bucket: process.env.REACT_APP_AWS_BUCKET || "",
            Key: pathFile,
            Body: file,
            ContentType: contentType,
            ACL: "public-read",
        },
    });

    try {
        await upload.promise();
    } catch (error) {
        swalMessage("画像アップロードエラー", "画像がアップロードできません。もう一度アップロードしてください。", "error");
        return;
    }
};

const deleteFile = async (pathFile: string): Promise<void> => {
    if (!pathFile) {
        console.error('Path to the file is missing.');
        return;
    }

    const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET || "",
        Key: pathFile,
    };

    try {
        const data: DeleteObjectOutput = await s3.deleteObject(params).promise();
        console.log('File deleted successfully');
    } catch (error) {
        console.error('Error deleting file:', error);
        throw new Error('File deletion failed.');
    }
};

const GbToB = (gb: number) => {
    if(!gb || typeof gb !== 'number') return 0;

    return gb * 1073741824
}

export { uploadFileToS3, deleteFile, GbToB }