import React, { ReactNode } from "react";
import { ElementProps } from "../../../../../../../../types/globals";

type RecordButtonType = {
  icon?: ReactNode;
  label?: string;
  className?: string;
  handleClick?: () => void;
} & ElementProps<HTMLButtonElement>;

const RecordButton = ({
  icon,
  label,
  className,
  handleClick,
  ...props
}: RecordButtonType) => {
  return (
    <button
      type="button"
      className={`w-[100px] flex items-center gap-[10px] p-[10px] rounded-[50px] border ${className}`}
      onClick={handleClick}
      {...props}
    >
      {icon}
      <span className="text-[14px] font-[500] leading-[20px] tracking-[0.1px]">
        {label}
      </span>
    </button>
  );
};

export default RecordButton;
