import React, { useEffect, useRef, useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { TestTakerUploadProblemTest } from "../../../../../types/admin/question";
import {
  decodeHTMLEntities,
  truncate,
} from "../../../../../services/helpers/parseData";
import {
  getAllowedFileType,
  getFileExtensionFromName,
  isValidFileType,
} from "../../../../../services/utils/admin/question";
import {
  MESSAGE_COMMON,
  MESSAGE_ERROR,
} from "../../../../../services/constants/message";
import { bytesToMegabytes } from "../../../../../services/helpers/etc";
import { getUploadFilePath } from "../../../../../services/helpers/uploadFIle";
import { useAuthContext } from "../../../../../context/AuthContext";
import { File, FileUploading } from "../../../../../types/admin/folder";
import { S3 } from "aws-sdk";
import { request } from "../../../../../services/axios/axios";
import { API } from "../../../../../services/constants/route/api";
import {
  swalClose,
  swalConfirm,
  swalError,
  swalLoading,
} from "../../../../../services/helpers/swal";
import RenderUploadedIcon from "../../../../admin/Question/AuthoringQuestion/QuestionEditorHandler/QuestionTypes/TestTakerUploadQuestion/RenderUploadedIcon";
import ProgressBar from "../../../common/ProgressBar";
import { BsDot } from "react-icons/bs";
import { useExaminationTestContext } from "../../../../../context/ExaminationTestContext";
import { candidateToolIsOpen } from "../../../../../services/utils/candidate/examinationTest";
import { SETTING_PROPERTY_NAMES } from "../../../../../services/constants/admin/pages/exam";
import classNames from "classnames";

const UploadQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled,
}: {
  questionProblem: TestTakerUploadProblemTest;
  handleUpdateProblem: (problem: TestTakerUploadProblemTest) => void;
  isDisabled?: boolean;
}) => {
  const { questionProblems, setQuestionProblems, openingTools } =
    useExaminationTestContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const oz_id = user.organization_id;
  const [editingData, setEditingData] = useState<TestTakerUploadProblemTest>(questionProblem);
  const uploadedFiles: TestTakerUploadProblemTest["answer_data"] =
    editingData.answer_data || [];
  const [uploadingFiles, setUploadingFiles] = useState<FileUploading[]>([]);

  const uploadHandle = async (files: FileList | null) => {
    if (!files) return;

    if (
      !!editingData.file_quantity_limit &&
      uploadedFiles.length >= Number(editingData.file_quantity_limit)
    ) {
      setError(MESSAGE_ERROR.FILE_QUANTITY_EXCEEDS_THE_ALLOWED_LIMIT);
      return;
    }

    if (
      !editingData ||
      !editingData.upload_folder ||
      !editingData.upload_folder?.code
    ) {
      setError(`ファイルをアップロードする用カテゴリを選択してください。`);
      return;
    }

    if (!editingData.upload_folder) {
      setError(MESSAGE_COMMON.INVALID_FILE_TYPE);
      return;
    }

    const file = files?.[0];
    // Validates
    if (!file) return;

    if (
      !!editingData.is_restricted_file_type &&
      !isValidFileType(file.type, editingData.restricted_file_types)
    ) {
      setError(MESSAGE_COMMON.INVALID_FILE_TYPE);
      return;
    }
    if (
      Number(bytesToMegabytes(file.size)) > Number(editingData.file_size_limit)
    ) {
      setError(MESSAGE_ERROR.FILE_SIZE_EXCEEDS_THE_ALLOWED_LIMIT);
      return;
    }

    if (
      !!editingData.file_quantity_limit &&
      uploadedFiles.length > Number(editingData.file_quantity_limit)
    ) {
      setError(MESSAGE_ERROR.FILE_QUANTITY_EXCEEDS_THE_ALLOWED_LIMIT);
      return;
    }

    const fileExtension = getFileExtensionFromName(file.name);
    const fileName = `${Date.now()}.${fileExtension}`;
    const folderPath = getUploadFilePath(oz_id, editingData.upload_folder.code);

    const fileKey = `${folderPath}${fileName}`;
    const uploadingData: FileUploading = {
      id: new Date().getTime(),
      originFilename: file.name,
      uploadProcess: 0,
      fileKey: fileKey,
      size: Number(bytesToMegabytes(file.size)),
    };

    uploadingFiles.push(uploadingData);
    setUploadingFiles([...uploadingFiles]);

    setError("");

    // S3 upload begin
    const s3 = new S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    await s3
      .putObject({
        Bucket: process.env.REACT_APP_AWS_BUCKET || "",
        Key: fileKey,
        Body: file,
        // ContentType: contentType,
        ACL: "public-read",
      })
      .on("httpUploadProgress", (progress) => {
        if (progress && progress.loaded && progress.total) {
          const currentUploadingIndex = uploadingFiles.findIndex(
            (file) => file.fileKey === fileKey
          );
          const currentUploadingFile = uploadingFiles?.[currentUploadingIndex];
          const currentProgress = Math.round(
            (progress.loaded / progress.total) * 100
          );

          if (!currentUploadingFile) {
            return;
          }

          uploadingFiles.splice(currentUploadingIndex, 1, {
            ...currentUploadingFile,
            uploadProcess: currentProgress,
          });

          setUploadingFiles([...uploadingFiles]);
        }
      })
      .promise();

    const data = {
      resource_folder_id: editingData.upload_folder.id,
      filename: fileName,
      origin_filename: file.name,
      content_type: fileExtension,
      size: bytesToMegabytes(file.size),
      path: fileKey,
    };

    await request.post(
      API.EXAMINATION.UPLOAD_FILE_ANSWER,
      data,
      (resourceFile: File) => {
        // setResourceLink?.(resourceFile.resource_link || "");
        const uploadedFilesNew = [resourceFile, ...uploadedFiles];
        const newEditingData = {
          ...editingData,
          answer_data: uploadedFilesNew,
        };
        setEditingData(newEditingData);
        handleUpdateProblem(newEditingData);

        const newUploadingFile = uploadingFiles.filter(
          (file) => file.fileKey !== resourceFile.path
        );
        setUploadingFiles(newUploadingFile);

        // clear ref
        if (fileInputRef.current) {
          fileInputRef.current.files = null;
        }
      },
      async (error) => {
        s3.deleteObject({
          Bucket: process.env.REACT_APP_AWS_BUCKET || "",
          Key: fileKey,
        }).promise();
        setError("Upload false");

        setUploadingFiles(
          uploadingFiles.filter((file) => file.fileKey !== fileKey)
        );

        return swalError();
      },
      { withLoading: false }
    );

    setError("");
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    uploadHandle(files);
  };

  const { acceptedFileTypeString } = getAllowedFileType(
    editingData.restricted_file_types
  );

  const deleteUploadedHandle = (resourceId: number, keyPath: string) => {
    if (!resourceId || !keyPath || !keyPath.trim()) return;

    swalConfirm(async () => {
      swalLoading();
      const s3 = new S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      });

      const s3Delete = s3
        .deleteObject({
          Bucket: process.env.REACT_APP_AWS_BUCKET || "",
          Key: keyPath,
        })
        .promise();

      const resourceDelete = request.delete(
        API.EXAMINATION.DELETE_FILE_ANSWER,
        {
          resource_ids: [resourceId],
        },
        () => {
          const uploadFilesNew = uploadedFiles.filter(
            (file) => file.id !== resourceId
          );
          // setUploadedFiles(uploadFilesNew);
          const newEditingData = {
            ...editingData,
            answer_data: uploadFilesNew,
          };
          setEditingData(newEditingData);
          handleUpdateProblem(newEditingData);
        }
      );

      await Promise.all([s3Delete, resourceDelete]);
      swalClose();
    });
  };

  const handleMouseUp = () => {
    if (
      !candidateToolIsOpen(SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT, openingTools)
    )
      return;

    const selector = window.getSelection();
    const textSelected = selector?.toString();
    if (!textSelected) return;
    const span = document.createElement("span");
    span.setAttribute("style", "background-color: yellow; display:inline;");
    span.innerText = textSelected;
    const newText = editingData.title?.replace(textSelected, span.outerHTML);

    setQuestionProblems(
      questionProblems.map((question) => {
        if (question.id === editingData.id) {
          return { ...question, title: newText };
        }

        return question;
      })
    );
  };

  const dropHandler = (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      const files = e.dataTransfer.files;
      uploadHandle(files);
    }
  };

  const dragOverHandler = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (questionProblem) {
      setEditingData(questionProblem);
    }
  }, [questionProblem]);

  return (
    <div className="max-h-full overflow-y-auto">
      <div
        className="leading-[24px] font-[500] mb-[80px]"
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(editingData.title),
        }}
        onMouseUp={handleMouseUp}
      ></div>
      {/* Uploading section */}
      <div className="mt-[16px]">
        {uploadingFiles.map((file, index) => (
          <div
            className="mt-[16px]"
            key={`uploading_${index}`}
            title={file.originFilename}
          >
            <div className="flex gap-[2px] items-center">
              <div className="text-primary-dark opacity-40 text-[22px]">
                <RenderUploadedIcon
                  extension={String(
                    getFileExtensionFromName(file.originFilename)
                  )}
                />
              </div>
              <div className="ml-[12px] w-full">
                <div className="">
                  <div className="w-full flex justify-between text-[12px] leading-[16px] tracking-[0.4px]">
                    <div className="opacity-40">
                      {truncate(file.originFilename, 55)}
                    </div>
                    <div className="">{file.size}MB</div>
                  </div>
                </div>
                <div className="mt-[5px]">
                  <ProgressBar
                    width={"100%"}
                    height={4}
                    progress={file.uploadProcess}
                    progressClass="bg-primary-dark"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Uploaded section */}
      <div className="mt-[16px]">
        {uploadedFiles.map((file, index) => (
          <div
            className="mt-[16px]"
            key={`uploaded_${index}`}
            title={file.origin_filename}
          >
            <div className="flex gap-[2px] items-center">
              <div className="text-primary-dark text-[22px]">
                <RenderUploadedIcon
                  extension={String(
                    getFileExtensionFromName(file.origin_filename || "")
                  )}
                />
              </div>
              <div className="ml-[12px] w-full">
                <div className="">
                  <div className="w-full flex justify-between text-[12px] leading-[16px] tracking-[0.4px]">
                    <div className="flex gap-[2px] items-center">
                      <div className="">
                        {truncate(file.origin_filename, 55)}
                      </div>
                      <BsDot />
                      <div
                        className="text-[11px] font-[500] leading-[16px] tracking-[0.5px] text-danger cursor-pointer"
                        onClick={() =>
                          deleteUploadedHandle(file.id, String(file.path))
                        }
                      >
                        削除
                      </div>
                    </div>
                    <div className="">{file.size}MB</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {error ? (
        <div className="max-w-[531px] text-danger mb-[10px] mt-[16px]">
          {error}
        </div>
      ) : (
        <></>
      )}

      <div className="flex items-center justify-center gap-x-[30px]">
        <div className="max-w-[531px] border border-dashed border-secondary-light rounded-[10px] mt-[16px] py-[16px] px-[10px]">
          <div
            className="flex gap-[56px] items-center"
            id="drop-zone"
            onDrop={dropHandler}
            onDragOver={dragOverHandler}
          >
            <div className="flex gap-[13px] items-center ml-[22px]">
              <div className="">
                <FiUploadCloud
                  size={39}
                  className="text-secondary font-[100]"
                />
              </div>
              <div className="font-[500] leading-[16px] tracking-[0.5px]">
                <div className="text-[11px]">
                  ファイルを選択するか、ここにドラッグ＆ドロップしてください。
                </div>
                <div className="text-[12px] text-secondary-light">
                  ファイル容量は{editingData.file_size_limit}
                  MB以下をアップロードしてください。
                </div>
              </div>
            </div>
            <div className="">
              <div
                className={classNames(
                  `text-primary-dark border border-primary-dark rounded-[5px] w-[117px] flex items-center justify-center h-[44px] text-[14px] font-[500] leading-[20px] tracking-[0.1px] cursor-pointer`,
                  {
                    "pointer-events-none": isDisabled || (!!editingData.file_quantity_limit &&
                      uploadedFiles.length >= Number(editingData.file_quantity_limit)),
                  }
                )}
                onClick={() => {
                  if (isDisabled || (!!editingData.file_quantity_limit &&
                    uploadedFiles.length >= Number(editingData.file_quantity_limit))) return;

                  fileInputRef?.current?.click();
                }}
              >
                ファイル選択
              </div>
            </div>
            <input
              ref={fileInputRef}
              id="file-input"
              type="file"
              className="hidden"
              onChange={handleFileInputChange}
              accept={
                !!editingData?.is_restricted_file_type
                  ? acceptedFileTypeString
                  : "*"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadQuestion;
