import React from "react";

const StretchVertical = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 2.41211H6C4.89543 2.41211 4 3.30754 4 4.41211V20.4121C4 21.5167 4.89543 22.4121 6 22.4121H8C9.10457 22.4121 10 21.5167 10 20.4121V4.41211C10 3.30754 9.10457 2.41211 8 2.41211Z"
        fill={`${props.color || "#FA646A"}`}
      />
      <path
        d="M18 2.41211H16C14.8954 2.41211 14 3.30754 14 4.41211V20.4121C14 21.5167 14.8954 22.4121 16 22.4121H18C19.1046 22.4121 20 21.5167 20 20.4121V4.41211C20 3.30754 19.1046 2.41211 18 2.41211Z"
        fill={`${props.color || "#FA646A"}`}
      />
    </svg>
  );
};

export default StretchVertical;
