import React from "react";
import { useExaminationTestContext } from "../../../../context/ExaminationTestContext";
import QuestionChooserButton from "./QuestionChooserButton";
import {
  currentQuestionNumber,
  isPropertyExists,
} from "../../../../services/utils/candidate/examinationTest";
import { swalMessage } from "../../../../services/helpers/swal";
import {
  ANSWER_IN_ORDER_VALUE,
  SETTING_PROPERTY_NAMES,
} from "../../../../services/constants/admin/pages/exam";
import {
  CANDIDATE_EXAM_MESSAGE,
  STATUS_EXAM_TEST_ITEM,
} from "../../../../services/constants/candidate/examinationTest";
import { isEmptyObj } from "../../../../services/helpers/etc";

const ListQuestionNumber = () => {
  const {
    examPartProperties,
    examSectionProperties,
    examPartSelected,
    examPartQuestions,
    filterQuestionKey,
    examSectionSelected,
    setFilterQuestionKey,
    setExamSectionSelected,
    setExamItemSelected,
    setQuestionProblems,
    isLastQuestionOfSection,
    isChangeAnswer,
    setIsChangeAnswer,
    examItemSelected,
    setExamPartSelected,
  } = useExaminationTestContext();

  const isChooseQuestion = (examSectionId: number, examItemId: number) => {
    return (
      filterQuestionKey.sectionId === examSectionId &&
      filterQuestionKey.itemId === examItemId
    );
  };

  const isQuestionUnanswered = () => {
    if (
      isPropertyExists(
        SETTING_PROPERTY_NAMES.UNANSWERED_WARNING,
        examSectionProperties.navigation_warning || []
      )
    ) {
      examPartQuestions.forEach((question) => {
        if (
          question.exam_part_id === filterQuestionKey.sectionId &&
          question.status !== STATUS_EXAM_TEST_ITEM.DONE
        ) {
          throw new Error(CANDIDATE_EXAM_MESSAGE.UNANSWER_IN_EXAM_SECTION);
        }
      });
    }
  };

  const handleClickChooserQuestion = (
    examSectionId: number,
    examItemId: number
  ) => {
    try {
      if (
        filterQuestionKey.sectionId === examSectionId &&
        filterQuestionKey.itemId === examItemId
      )
        return;
        
      if (
        examPartProperties.answer_in_order &&
        examPartProperties.answer_in_order ===
          ANSWER_IN_ORDER_VALUE.SEQUENTIAL &&
        filterQuestionKey.sectionId !== 0 &&
        filterQuestionKey.itemId !== 0
      ) {
        // Find index current question.
        const findIndexCurrentQuestion = examPartQuestions.findIndex(
          (question) => {
            return (
              question.id === filterQuestionKey.itemId &&
              question.exam_part_id === filterQuestionKey.sectionId
            );
          }
        );

        // Find index question choosed.
        const findIndexQuestion = examPartQuestions.findIndex((question) => {
          return (
            question.id === examItemId &&
            question.exam_part_id === examSectionId
          );
        });
        const questionChoosed = examPartQuestions.find((question) => {
          return (
            question.id === examItemId &&
            question.exam_part_id === examSectionId
          );
        });

        if (findIndexCurrentQuestion === -1 || findIndexQuestion === -1) {
          return;
        }

        if (
          findIndexQuestion - findIndexCurrentQuestion > 1 &&
          questionChoosed?.status !== STATUS_EXAM_TEST_ITEM.DONE
        ) {
          throw new Error(CANDIDATE_EXAM_MESSAGE.ANSWER_QUESTION_IN_ORDER);
        }
      }

      // If exam section properties has is_skip properties is false.
      if (
        (isEmptyObj(examSectionProperties) ||
          isEmptyObj(examSectionProperties.item_session_controls) ||
          !examSectionProperties.item_session_controls?.is_skip) &&
        !isEmptyObj(examItemSelected) &&
        examItemSelected.status !== STATUS_EXAM_TEST_ITEM.DONE
      ) {
        throw new Error(CANDIDATE_EXAM_MESSAGE.CHOOOSE_ANSWER);
      }

      // Increase the number of responses for the question with changed answer.
      const newExamSections = examPartSelected.exam_sections?.map(
        (examSection) => {
          if (examSection.id === filterQuestionKey.sectionId) {
            const examItems = examSection.exam_items?.map((examItem) => {
              if (examItem.id === filterQuestionKey.itemId && isChangeAnswer) {
                const timesAnswer = (examItem.times_answer || 0) + 1;

                return { ...examItem, times_answer: timesAnswer };
              }

              return examItem;
            });

            return { ...examSection, exam_items: examItems };
          }

          return examSection;
        }
      );

      setExamPartSelected({
        ...examPartSelected,
        exam_sections: newExamSections,
      });

      const findExamSection = examPartSelected.exam_sections?.find(
        (examSection) => examSection.id === examSectionId
      );

      if (!findExamSection || !findExamSection.exam_items?.length) return;

      const findExamItem = findExamSection.exam_items.find(
        (examItem) => examItem.id === examItemId
      );

      if (!findExamItem) return;

      if (
        isLastQuestionOfSection(examSectionId, examItemId) &&
        examSectionId !== filterQuestionKey.sectionId
      ) {
        isQuestionUnanswered();
      }
      setFilterQuestionKey({
        sectionId: findExamSection?.id || 0,
        itemId: findExamItem?.id || 0,
      });
      setIsChangeAnswer(false);
      setExamSectionSelected(findExamSection);
      setExamItemSelected(findExamItem);
      setQuestionProblems(findExamItem.question_data?.problems || []);
    } catch (error) {
      const message = (error as Error).message;
      swalMessage("", message, "warning", {
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  return (
    <>
      {examPartProperties.show_review ? (
        <div className="w-[15%] h-full max-w-full overflow-y-auto bg-secondary-extralight px-[5px] py-[20px]">
          <p className="text-center text-[12px] font-[700] leading-3">
            解答一覧
          </p>
          {examPartSelected.exam_sections &&
            examPartSelected.exam_sections.length &&
            examPartSelected.exam_sections.map((examSection) => {
              return (
                <div className="px-[5px] mt-[18px]" key={examSection.id}>
                  <div className="text-[12px] leading-[100%] font-[500] mb-[9px]">
                    {examSection.name}
                  </div>
                  <div className="grid grid-cols-2 gap-x-[15px] gap-y-[9px]">
                    {examSection.exam_items &&
                      examSection.exam_items.length &&
                      examSection.exam_items.map((examItem) => (
                        <QuestionChooserButton
                          isChoose={isChooseQuestion(
                            examSection.id || 0,
                            examItem.id || 0
                          )}
                          questionNumber={currentQuestionNumber(
                            examPartQuestions,
                            examSection.id || 0,
                            examItem.id || 0
                          )}
                          onClick={() =>
                            handleClickChooserQuestion(
                              examSection.id || 0,
                              examItem.id || 0
                            )
                          }
                          status={examItem.status || 0}
                          key={examItem.id}
                        />
                      ))}
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ListQuestionNumber;
