import React from 'react';
import {
  ChoiceProblem,
  MappingProblem,
  MatrixProblem,
} from '../../../../../../types/admin/question';
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { isUsableArr } from '../../../../../../services/helpers/etc';
import { IoWarningOutline } from 'react-icons/io5';
import { NAME_TYPE_QUESTION } from '../../../../../../services/constants/admin/pages/question';

type AuthoringMessageBoxProps = {
  problemData?: ChoiceProblem | MappingProblem | MatrixProblem;
};

const AuthoringMessageBox = ({ problemData }: AuthoringMessageBoxProps) => {
  if (!problemData || !problemData.id || (!problemData.min_choice && !problemData.max_choice)) {
    return <></>;
  }

  let messages = [];
  let checkedCount = 0;

  switch (problemData.name) {
    case NAME_TYPE_QUESTION.CHOICE:
      const choiceProblem: ChoiceProblem = problemData;
      checkedCount = Number(choiceProblem?.issues?.filter((issue) => !!issue.checked)?.length);
      break;

    case NAME_TYPE_QUESTION.MAPPING:
      const mappingProblem: MappingProblem = problemData;
      checkedCount = Number(
        mappingProblem?.correct_pairs?.filter((pair) => pair.left && pair.right)?.length,
      );
      break;
    case NAME_TYPE_QUESTION.MATRIX:
      const matrixProblem: MatrixProblem = problemData;
      checkedCount = Number(
        matrixProblem?.correct_coordinates?.filter((coordinate) => !!coordinate.checked)?.length,
      );
      break;
    default:
      break;
  }

  if (problemData.min_choice) {
    if (Number(checkedCount) >= problemData.min_choice) {
      const msgObj = {
        themeClasses: 'bg-[#d2fce3] border-[#20b35c] text-[#20b35c]',
        themeIcon: <AiOutlineCheckCircle size={20} />,
        message: `最低${problemData.min_choice}つ選択してください`,
      };
      messages.push(msgObj);
    } else {
      const msgObj = {
        themeClasses: 'bg-[#FBF5C6] border-[#E1BC00] text-[#E1BC00]',
        themeIcon: <IoWarningOutline size={20} />,
        message: `最低${problemData.min_choice}つ選択してください`,
      };
      messages.push(msgObj);
    }
  }

  if (problemData.max_choice) {
    if (Number(checkedCount) === problemData.max_choice) {
      const msgObj = {
        themeClasses: 'bg-[#d2fce3] border-[#20b35c] text-[#20b35c]',
        themeIcon: <AiOutlineCheckCircle size={20} />,
        message: `最大${problemData.max_choice}個の選択肢を選択することができます。`,
      };
      messages.push(msgObj);
    } else if (Number(checkedCount) < problemData.max_choice) {
      const msgObj = {
        themeClasses: 'bg-primary-morelighter border-primary text-primary',
        themeIcon: <AiOutlineInfoCircle size={20} />,
        message: `最大${problemData.max_choice}個の選択肢を選択することができます。`,
      };
      messages.push(msgObj);
    } else {
      const msgObj = {
        themeClasses: 'bg-danger-lighter border-danger text-danger',
        themeIcon: <AiOutlineCloseCircle size={20} />,
        message: '選択数が最大数に達しました。これ以上選択できません。',
      };
      messages.push(msgObj);
    }
  }

  return (
    <div className="px-[10px]">
      {isUsableArr(messages) ? (
        <>
          {messages.map((msgObj, index) => (
            <div
              className={`w-full border rounded-[7px] py-[8px] my-[7px] ${msgObj.themeClasses}`}
              key={index}
            >
              <ul className="flex items-center gap-[7px] !pl-[12px] text-[14px]">
                {msgObj.themeIcon}
                {msgObj.message}
              </ul>
            </div>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AuthoringMessageBox;
