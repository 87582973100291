import { useEffect, useState } from "react";
import ChildrenStep from "./RegisterExaminationComponent/ChildrenStep";
import { FiRefreshCw } from "react-icons/fi";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useExaminationRegisterContext } from "../../../context/Candidate/ExaminationRegisterContext";
import { EXAMINATION_FORMAT } from "../../../services/constants/admin/pages/examination";
import { formatDateTime } from "../../../services/helpers/formatTime";
import {
  PAYMENT_TYPE,
  TAX,
  TYPE_DATE_FORMAT,
} from "../../../services/constants/globals";
import { useStorage } from "../../../hooks/useStorage";
import { formatNumberWithCommas } from "../../../services/helpers/parseData";
import { User } from "../../../types/user";
import { axios, makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalMessage } from "../../../services/helpers/swal";
import classNames from "classnames";
import ItemExaminationRegisterInfo from "./RegisterExaminationComponent/ItemExaminationRegisterInfo";
import {
  INDEX_STEP_REGISTER_EXAMINATIONS,
  TITLE_STEP_REGISTER_EXAMINATIONS,
} from "../../../services/constants/candidate/registerExamination";
import { useNavigate } from "react-router-dom";
import {
  PATH_CANDIDATE_HOME,
  ROUTE,
} from "../../../services/constants/route/router";
import { getTimeBeforeEntryTime } from "../../../services/utils/candidate/registerExamination";
import { isEmptyArr } from "../../../services/helpers/etc";
import dayjs from "dayjs";
import LoadingCircle from "../../commons/icons/LoadingCircle";
import { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";

const RegisterExaminationStep3 = () => {
  const {
    setStepCurrent,
    setRegisterExaminationData,
    examinationType,
    currentStep,
    examinationSelected,
    examinationVenueSelected,
    examinationNameSelected,
    registerExaminationData,
  } = useExaminationRegisterContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<string[]>([]);
  const { getLocalStorage } = useStorage();
  const user: User = getLocalStorage("user");

  const handleBackStep = () => {
    if (!currentStep.step || currentStep.step === 1) return;
    let stepBack = currentStep.step - 1;

    setStepCurrent({
      ...currentStep,
      step: stepBack,
    });

    delete registerExaminationData.payment_method_type;
    setRegisterExaminationData(registerExaminationData);
  };

  const handleChangePaymentType = (paymentType: string): void => {
    setRegisterExaminationData({
      ...registerExaminationData,
      payment_method_type: paymentType,
    });
  };

  const handleConfirmRedirectURLPayment = async (paymentLink: string, urlNextStep: string) => {
    return Swal.fire({
      title: `<div style='font-weight: 600; font-size: 18px; line-height: 24px; letter-spacing: 0.2px; color: #2E2E2E;'>コンビニ決済の手続き画面へ遷移します</div>`,
      html: `<div style='font-weight: 500; font-size: 14px; line-height: 100%; letter-spacing: 0.1px; overflow: hidden; line-height: 19.8px'><div><div style="color: #FA646A; text-align:center; font-weight:500; line-height: 20px; font-family: 'Noto Sans JP';"><p>まだ決済は完了していません。</p><p>遷移先の画面で必要項目を入力し</p><p>コンビニでの支払い手続きを完了させてください。</p></div><div style="text-align:center; margin-top: 20px; font-weight:500; line-height: 20px; font-family: 'Noto Sans JP';"><p>お支払いに必要な「お支払い番号」などの詳細は</p><p>手続き完了後の説明画面にてご案内いたします。</p><p>必ずご確認ください。</p></div></div></div>`,
      showCancelButton: true,
      cancelButtonText: "キャンセル",
      confirmButtonText: "決済手続き画面へ",
      confirmButtonColor: "#FE6D73",
      focusConfirm: false,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(paymentLink, "_blank");
        setTimeout(() => {
          setStepCurrent({
            step: INDEX_STEP_REGISTER_EXAMINATIONS.STEP_4,
            name: TITLE_STEP_REGISTER_EXAMINATIONS.STEP4,
          });
          navigate(urlNextStep);
        }, 5000);
      } 
    });
  };

  const handleRedirectToPaymentLink = async () => {
    setIsLoading(true);

    try {
      if (
        !examinationSelected.is_free &&
        !registerExaminationData.payment_method_type
      ) {
        throw new Error("決済方法を指定してください。");
      }
      const dataPost = {
        ...registerExaminationData,
        selected_date: dayjs(
          `${registerExaminationData.selected_date} ${registerExaminationData.selected_time}`
        ),
        time: examinationSelected.exam_times,
        success_url: `${window.location.origin}/${ROUTE.CANDIDATE_PREFIX}/${ROUTE.EXAM_REGISTER.DEFAULT}?step=${INDEX_STEP_REGISTER_EXAMINATIONS.STEP_4}`,
        cancel_url: `${window.location.origin}/${ROUTE.CANDIDATE_PREFIX}`,
      };

      const result = await makeRequest({
        method: "post",
        url: API.PAYMENT.GET_URL_PAYMENT_LINK,
        data: dataPost,
      });

      if (!result.status) throw new Error(result.message);

      const response = result.data;
      const redictectStep4 = `${PATH_CANDIDATE_HOME.REGISTER_EXAMINATION}?step=${INDEX_STEP_REGISTER_EXAMINATIONS.STEP_4}&register_examination_id=${response.register_examination_id}`;
      if (examinationSelected.is_free) {
        setStepCurrent({
          step: INDEX_STEP_REGISTER_EXAMINATIONS.STEP_4,
          name: TITLE_STEP_REGISTER_EXAMINATIONS.STEP4,
        });
        navigate(redictectStep4);
      } else {
        if (
          registerExaminationData.payment_method_type === PAYMENT_TYPE.KONBINI
        ) {
          handleConfirmRedirectURLPayment(response.payment_link, redictectStep4);
          return;
        }

        window.location.href = response.payment_link;
      }
    } catch (error) {
      swalMessage("", String(error).replace("Error:", ""), "error", {
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleFetchPaymentMethod = async () => {
      setIsLoading(true);

      const subdomain = window.location.hostname.split(".")[0];
      await axios
        .get(
          process.env.REACT_APP_API_PALETTE +
            `/api/payment-setting/get-payment-method?sub_domain=${subdomain}`
        )
        .then((response) => {
          const data = response as AxiosResponse;
          setPaymentMethods(data.data.result.data);
        })
        .catch((error: AxiosError) => {})
        .finally(() => {
          setIsLoading(false);
        });
    };

    handleFetchPaymentMethod();
  }, []);

  return (
    <div className="text-secondary-dark">
      <div className="h-full min-w-[155px] flex justify-start items-center mb-[10px] mt-[20px] gap-[20px]">
        <div className="w-[30px] h-[30px] bg-primary-synch text-[22px] text-[white] flex justify-center items-center">
          {INDEX_STEP_REGISTER_EXAMINATIONS.STEP_3}
        </div>
        予約確認・お支払い
      </div>
      <ChildrenStep step={1} title="予約内容の確認">
        <p>
          予約内容にお間違いがある場合は「戻る」をクリック、お間違いのない場合は、お支払い方法を選択後「確定」をクリックしてください。
        </p>
        <div className="w-full mt-[20px] border-y border-y-secondary-light">
          <ItemExaminationRegisterInfo title="受験者名">
            <>
              {user.first_name}　{user.last_name}
            </>
          </ItemExaminationRegisterInfo>
          <ItemExaminationRegisterInfo title="メールアドレス">
            <>{user.email}</>
          </ItemExaminationRegisterInfo>
          <ItemExaminationRegisterInfo title="試験">
            <>{examinationNameSelected.name}</>
          </ItemExaminationRegisterInfo>
          <ItemExaminationRegisterInfo title="試験名">
            <>
              {examinationSelected.type}{" "}
              {examinationSelected.exam_times &&
                `(${examinationSelected?.exam_times}分)`}
            </>
          </ItemExaminationRegisterInfo>
          <ItemExaminationRegisterInfo title="試験番号">
            <>{examinationSelected.code}</>
          </ItemExaminationRegisterInfo>
          <ItemExaminationRegisterInfo title="試験料金（税込）">
            <>
              {examinationSelected.is_free
                ? "無料"
                : formatNumberWithCommas(
                    Math.floor(Number(examinationSelected.price) * TAX)
                  ) + "円"}
            </>
          </ItemExaminationRegisterInfo>
          {examinationType === EXAMINATION_FORMAT.CBT ? (
            <ItemExaminationRegisterInfo title="会場">
              <div className="text-[16px]">
                <p>{examinationVenueSelected.name}</p>
                <div className="py-[20px]">
                  {examinationVenueSelected.postal_code?.first &&
                    examinationVenueSelected.postal_code?.last && (
                      <p>
                        〒{examinationVenueSelected.postal_code.first}-
                        {examinationVenueSelected.postal_code.last}
                      </p>
                    )}
                  <p className="text-[14px] mb-[20px]">
                    {examinationVenueSelected.address?.prefecture},{" "}
                    {examinationVenueSelected.address?.city},{" "}
                    {examinationVenueSelected.address?.area},{" "}
                    {examinationVenueSelected.address?.other}
                  </p>
                </div>
                <p>
                  これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。
                </p>
              </div>
            </ItemExaminationRegisterInfo>
          ) : (
            <></>
          )}
          <ItemExaminationRegisterInfo title="試験日">
            <span className="text-[16px]">
              {formatDateTime(
                registerExaminationData?.selected_date || "",
                TYPE_DATE_FORMAT.SHORT_DEFAULT
              )}
            </span>
          </ItemExaminationRegisterInfo>
          {examinationType === EXAMINATION_FORMAT.CBT && (
            <ItemExaminationRegisterInfo title="入室時間">
              <span className="text-[16px]">
                {getTimeBeforeEntryTime(
                  registerExaminationData.selected_time || "",
                  examinationSelected.entry_time || 0
                )}{" "}
                ~ {registerExaminationData.selected_time?.slice(0, 5)}
              </span>
            </ItemExaminationRegisterInfo>
          )}
          <ItemExaminationRegisterInfo title="試験開始時間">
            <span className="text-[16px]">
              {registerExaminationData.selected_time?.slice(0, 5)}
            </span>
          </ItemExaminationRegisterInfo>
        </div>
        <ItemExaminationRegisterInfo title="注意">
          <p>{examinationSelected.info_attendance}</p>
        </ItemExaminationRegisterInfo>
        <button
          className="bg-secondary-light px-[15px] py-[8px] w-[140px] flex items-center text-white gap-x-[30px] rounded-[5px] mt-[20px]"
          onClick={handleBackStep}
        >
          <span className="w-[8px] h-[8px] border-2 border-white border-t-transparent border-r-transparent rotate-45"></span>
          <span>戻る</span>
        </button>
      </ChildrenStep>
      {/* <ChildrenStep step={2} title="キャンペーンコード入力">
        <p className="w-full mt-[20px]">
          これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。ダミーテキストはダミー文書やダミー文章とも呼ばれることがあります。
        </p>
        <div className="flex items-center gap-x-[5px] mt-[20px]">
          <div>
            <input
              type="text"
              placeholder="ここに入力してください"
              className="w-[250px] h-[40px] px-[15px] py-[9px] placeholder:text-secondary-light placeholder:text-[14px] placeholder:font-[400] !bg-[#FBFBFB] border border-[#E0E0E0]"
            />
          </div>
          <button className="w-[140px] px-[15px] py-[8px] bg-primary-synch text-white flex items-center justify-center gap-x-[8px] rounded-[5px]">
            <FiRefreshCw />
            <span className="text-[16px] font-[500]">情報を更新</span>
          </button>
        </div>
        <div className="mt-[20px]">
          <span className="text-[14px] font-[500] text-danger">注意事項</span>
          <ul className="mt-[10px] text-[14px] font-[400]">
            <li>これは正式な文章の代わりに入れて使うダミーテキストです</li>
            <li>これは正式な文章の代わりに入れて使うダミーテキストです</li>
            <li>これは正式な文章の代わりに入れて使うダミーテキストです</li>
            <li>これは正式な文章の代わりに入れて使うダミーテキストです</li>
          </ul>
        </div>
      </ChildrenStep> */}
      {!examinationSelected.is_free && (
        <ChildrenStep step={2} title="お支払い方法の選択">
          {isEmptyArr(paymentMethods) ? (
            <>
              {isLoading ? (
                <div className="w-full flex justify-center">
                  <LoadingCircle classNames="!w-[24px] !h-[24px]" />
                </div>
              ) : (
                <p className="text-center">
                  エラー発生しました。決済方法は設定されていません。運用者に連絡してください。
                </p>
              )}
            </>
          ) : (
            <>
              {/* <p className="mt-[20px]">
              これは正式な文章の代わりに入れて使うダミーテキストです。
            </p>
            <p className="mt-[20px] p-[20px] bg-[rgba(250,100,106,0.20)] text-[14px] font-[500] leading-[20px]">
              ダミーテキスト約250
              文字。これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。ダミーテキストはダミー文書やダミー文章とも呼ばれることがあります。このダミーテキストは自由に改変することが出来ます。この組見本は自由に複製したり頒布することができます。書体やレイアウトなどを確認するために用います。これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのダミーデザインを作成する時によく使われます。
            </p> */}
              {/* Card */}
              {!!paymentMethods.includes(PAYMENT_TYPE.CARD) ? (
                <div
                  className={`mt-[20px] border border-[#E0E0E0] px-[40px] py-[30px] rounded-[10px] hover:bg-[rgba(250,100,106,0.20)] cursor-pointer ${
                    registerExaminationData.payment_method_type ===
                    PAYMENT_TYPE.CARD
                      ? "bg-[rgba(250,100,106,0.20)]"
                      : ""
                  }`}
                  onClick={() => handleChangePaymentType(PAYMENT_TYPE.CARD)}
                >
                  <span className="pb-[8px] text-primary-synch text-[18px] leading-[24px] font-[400]">
                    クレジットカードでお支払い
                  </span>
                  {/* <p className="mt-[30px] text-[14px] font-[500] leading-[20px]">
                  ダミーテキスト約250
                  文字。これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。ダミーテキストはダミー文書やダミー文章とも呼ばれることがあります。このダミーテキストは自由に改変することが出来ます。この組見本は自由に複製したり頒布することができます。書体やレイアウトなどを確認するために用います。これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのダミーデザインを作成する時によく使われます。
                </p> */}
                </div>
              ) : (
                <></>
              )}
              {/* Konbini */}
              {!!paymentMethods.includes(PAYMENT_TYPE.KONBINI) ? (
                <div
                  className={`mt-[20px] border border-[#E0E0E0] px-[40px] py-[30px] rounded-[10px] hover:bg-[rgba(250,100,106,0.20)] cursor-pointer ${
                    registerExaminationData.payment_method_type ===
                    PAYMENT_TYPE.KONBINI
                      ? "bg-[rgba(250,100,106,0.20)]"
                      : ""
                  }`}
                  onClick={() => handleChangePaymentType(PAYMENT_TYPE.KONBINI)}
                >
                  <span className="pb-[8px] text-primary-synch text-[18px] leading-[24px] font-[400]">
                    コンビニでお支払い
                  </span>
                  {/* <p className="mt-[30px] text-[14px] font-[500] leading-[20px]">
                  ダミーテキスト約250
                  文字。これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのデザインを作成する時によく使われます。ダミーテキストはダミー文書やダミー文章とも呼ばれることがあります。このダミーテキストは自由に改変することが出来ます。この組見本は自由に複製したり頒布することができます。書体やレイアウトなどを確認するために用います。これは正式な文章の代わりに入れて使うダミーテキストです。主に書籍やウェブページなどのダミーデザインを作成する時によく使われます。
                </p> */}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </ChildrenStep>
      )}

      {isEmptyArr(paymentMethods) ? (
        <></>
      ) : (
        <div className="w-full">
          <button
            className={classNames(
              "mx-auto w-[140px] rounded-[5px] flex items-center justify-center gap-x-[10px] text-white bg-danger px-[15px] py-[8px]",
              {
                "opacity-80 hover:pointer-events-none": isLoading,
              }
            )}
            onClick={handleRedirectToPaymentLink}
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
            ) : (
              <>
                <IoIosCheckmarkCircleOutline size={24} color="#FFFFFF" />
                <span>確定</span>
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default RegisterExaminationStep3;
