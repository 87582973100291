import { NAME_TYPE_QUESTION } from '../../../../services/constants/admin/pages/question';
import Choice from '../../../commons/icons/QuestionType/Choice';
import Order from '../../../commons/icons/QuestionType/Order';
import Mapping from '../../../commons/icons/QuestionType/Mapping';
import FillInBlank from '../../../commons/icons/QuestionType/FillInBlank';
import Slider from '../../../commons/icons/QuestionType/Slider';
import WrittenQuestion from '../../../commons/icons/QuestionType/WrittenQuestion';
import TextBlock from '../../../commons/icons/QuestionType/TextBlock';
import Image from '../../../commons/icons/QuestionType/Image';
import Audio from '../../../commons/icons/QuestionType/Audio';
import TestTakerUpload from '../../../commons/icons/QuestionType/TestTakerUpload';
import SideQuestionTypeItem from './SideQuestionTypeItem';
import Matrix from '../../../commons/icons/QuestionType/Matrix';
import Video from '../../../commons/icons/QuestionType/Video';
import TestTakerRecord from '../../../commons/icons/QuestionType/TestTakerRecord';

const SideQuestionType = () => {
  const typeData = [
    {
      label: '基本ツール',
      subTypes: [
        {
          label: '選択肢',
          icon: <Choice />,
          name: NAME_TYPE_QUESTION.CHOICE,
        },
        {
          label: '並べ替え',
          icon: <Order />,
          name: NAME_TYPE_QUESTION.ORDER,
        },
        {
          label: '組合せ',
          icon: <Mapping />,
          name: NAME_TYPE_QUESTION.MAPPING,
        },
        {
          label: 'マッチ',
          icon: <Matrix />,
          name: NAME_TYPE_QUESTION.MATRIX,
        },
        {
          label: '穴埋め',
          icon: <FillInBlank />,
          name: NAME_TYPE_QUESTION.FILL_IN_BLANK,
        },
        {
          label: 'スライダー',
          icon: <Slider />,
          name: NAME_TYPE_QUESTION.SLIDER,
        },
        {
          label: '記述問題',
          icon: <WrittenQuestion />,
          name: NAME_TYPE_QUESTION.WRITTEN_QUESTION,
        },
      ],
    },
    {
      label: '提出ツール ',
      subTypes: [
        {
          label: 'アップロード',
          icon: <TestTakerUpload />,
          name: NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD,
        },
        {
          label: '録音',
          icon: <TestTakerRecord />,
          name: NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD,
        },
      ],
    },
    {
      label: '補助ツール ',
      subTypes: [
        {
          label: '文字',
          icon: <TextBlock />,
          name: NAME_TYPE_QUESTION.TEXT_BLOCK,
        },
        {
          label: '画像',
          icon: <Image />,
          name: NAME_TYPE_QUESTION.IMAGE,
        },
        {
          label: '音声',
          icon: <Audio />,
          name: NAME_TYPE_QUESTION.AUDIO,
        },
        {
          label: '映像',
          icon: <Video />,
          name: NAME_TYPE_QUESTION.VIDEO,
        },
      ],
    },
  ];

  return (
    <div className="w-[35%] min-w-[290px] max-w-[290px] bg-white rounded-[10px] p-[20px] h-full">
      {typeData.map((type, index) => (
        <SideQuestionTypeItem type={type} key={index} />
      ))}
    </div>
  );
};

export default SideQuestionType;
