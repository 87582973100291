import { ChangeEvent, useState } from "react";
import ChildrenStep from "../ChildrenStep";
import { useExaminationRegisterContext } from "../../../../../context/Candidate/ExaminationRegisterContext";
import { formatDateTime } from "../../../../../services/helpers/formatTime";
import { TYPE_DATE_FORMAT } from "../../../../../services/constants/globals";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import dayjs from "dayjs";
import CalendarDatePicker from "../../../../admin/Examination/Upsert/UpsertComponent/CalendarDatePicker";
import classNames from "classnames";

const IBTComponent = () => {
  const [date, setDate] = useState<Date | null>(new Date());
  const {
    registerExaminationData: registerExamination,
    setRegisterExaminationData: setRegisterExamination,
  } = useExaminationRegisterContext();
  const [showSelectName, setShowSelectName] = useState<string>();
  const selectedDateName = "selectedDate";
  const selectedTimeName = "selectedTime";
  
  const timeArray: string[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedTime = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}:00`;
      timeArray.push(formattedTime);
    }
  }

  const handleChooseRegistrationDate = (date: Date | null, name: string) => {
    setRegisterExamination({
      ...registerExamination,
      [name]: dayjs(date).format("YYYY-MM-DD"),
    });
    setShowSelectName("");
  };

  const handleChangeShowSelectName = (name: string) => {
    if (showSelectName === name) {
      setShowSelectName("");
      return;
    }

    setShowSelectName(name);
  };

  return (
    <div>
      <div className="h-full min-w-[155px] text-[black] flex justify-start items-center mb-[10px] mt-[20px] gap-[20px]">
        <div className="w-[30px] h-[30px] bg-primary-synch text-[22px] text-[white] flex justify-center items-center">
          2
        </div>
        日時の選択
      </div>
      <div className="flex-col items-center ">
        <ChildrenStep step={1} title="時間選択">
          <div className="mb-[20px]">
            <div className="w-[40%] flex flex-col gap-y-[10px]">
              <div
                className={`w-full h-[40px] flex items-center justify-between px-[15px] border border-[#ebe4d8] rounded-[5px] cursor-pointer }`}
                onClick={() => handleChangeShowSelectName(selectedDateName)}
              >
                {registerExamination?.selected_date ? (
                  <span className="text-[14px]">
                    {registerExamination?.selected_date &&
                      formatDateTime(
                        registerExamination?.selected_date,
                        TYPE_DATE_FORMAT.SHORT_DEFAULT
                      )}
                  </span>
                ) : (
                  <span>ご希望の受験日を選択してください</span>
                )}

                {showSelectName === selectedDateName ? (
                  <IoIosArrowDown color="#5A5A5A" />
                ) : (
                  <IoIosArrowUp color="#5A5A5A" />
                )}
              </div>
              {showSelectName === selectedDateName ? (
                <CalendarDatePicker
                  name="selected_date"
                  date={date}
                  setDate={setDate}
                  value={String(registerExamination?.selected_date)}
                  showTimeInput={false}
                  handleChange={handleChooseRegistrationDate}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="mt-[20px]">
              <div
                className={classNames(
                  "w-[40%] h-[40px] px-[15px] py-[7px] border border-[#ebe4d8] rounded-[5px] text-secondary-light cursor-pointer flex items-center justify-between",
                  {
                    "!text-secondary-dark": registerExamination.selected_time,
                  }
                )}
                onClick={() => {
                  if (showSelectName === selectedTimeName) {
                    setShowSelectName("");
                  } else {
                    setShowSelectName(selectedTimeName);
                  }
                }}
              >
                <span>
                  {registerExamination.selected_time
                    ? registerExamination.selected_time.slice(0, 5)
                    : "選択してください"}
                </span>
                {showSelectName === selectedTimeName ? (
                  <IoIosArrowDown />
                ) : (
                  <IoIosArrowUp />
                )}
              </div>
              {showSelectName === selectedTimeName ? (
                <div className="w-[40%] mt-[10px] max-h-[250px] border border-secondary-light rounded-[5px] overflow-y-auto">
                  {timeArray.map((time, index) => (
                    <div
                      key={index}
                      className={classNames(
                        "px-[15px] py-[7px] cursor-pointer hover:bg-secondary-extralight",
                        {
                          "bg-secondary-lighter":
                            registerExamination.selected_time === time,
                        }
                      )}
                      onClick={() => {
                        setRegisterExamination({
                          ...registerExamination,
                          selected_time: time,
                        });
                        setShowSelectName("");
                      }}
                    >
                      {time.slice(0, 5)}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </ChildrenStep>
      </div>
    </div>
  );
};

export default IBTComponent;
