import { useExaminationTestContext } from "../../../../context/ExaminationTestContext";
import TestItemQuestionType from "./TestItemQuestionType";
import { currentQuestionNumber } from "../../../../services/utils/candidate/examinationTest";
import {
  ChoiceProblemTest,
  FillInBlankProblemTest,
  MappingProblemTest,
  MatrixProblemTest,
  OrderProblemTest,
  QuestionProblemTest,
  SliderProblemTest,
  TestTakerRecordProblemTest,
  TestTakerUploadProblemTest,
  WritingProblemTest,
} from "../../../../types/admin/question";
import { STATUS_EXAM_TEST_ITEM } from "../../../../services/constants/candidate/examinationTest";
import { NAME_TYPE_QUESTION } from "../../../../services/constants/admin/pages/question";
import { isEmptyObj, isUsableArr } from "../../../../services/helpers/etc";
import { swalMessage } from "../../../../services/helpers/swal";

const ExaminationTestContent = () => {
  const {
    examPartQuestions,
    filterQuestionKey,
    questionProblems,
    textSize,
    setQuestionProblems,
    setExamPartSelected,
    examPartSelected,
    setExamItemSelected,
    examItemSelected,
    examSectionProperties,
    setIsChangeAnswer,
  } = useExaminationTestContext();

  const checkTimesAnswer = () => {
    const question = examPartQuestions.find(
      (question) =>
        question.id === filterQuestionKey.itemId &&
        question.exam_part_id === filterQuestionKey.sectionId
    );
    const maxTimesAnswer =
      examSectionProperties.item_session_controls?.max_times_answer || 0;
    if (!question || !maxTimesAnswer) return;

    if (question.times_answer === maxTimesAnswer) {
      throw new Error("試行回数の制限を超えました。");
    }
  };

  const handleStatusProblemQuestion = (problem: QuestionProblemTest): number => {
    let status = STATUS_EXAM_TEST_ITEM.UNDONE;

    switch (problem.name) {
      case NAME_TYPE_QUESTION.CHOICE:
        const choiceQuestionProblem = problem as ChoiceProblemTest;
        if (
          choiceQuestionProblem.answer_data &&
          choiceQuestionProblem.answer_data.length
        ) {
          choiceQuestionProblem.answer_data.forEach((answer) => {
            if (answer.checked) {
              status = STATUS_EXAM_TEST_ITEM.DONE;
            }
          });
        }
        break;

      case NAME_TYPE_QUESTION.ORDER:
        const orderQuestionProblem = problem as OrderProblemTest;
        if (
          orderQuestionProblem.answer_data &&
          orderQuestionProblem.answer_data.length
        ) {
          status = STATUS_EXAM_TEST_ITEM.DONE;
          orderQuestionProblem.answer_data.forEach((answer) => {
            if (isEmptyObj(answer)) {
              status = STATUS_EXAM_TEST_ITEM.UNDONE;
            }
          });
        }
        break;

      case NAME_TYPE_QUESTION.MAPPING:
        const mappingQuestionProblem = problem as MappingProblemTest;
        if (
          mappingQuestionProblem.answer_data &&
          mappingQuestionProblem.answer_data.length
        ) {
          mappingQuestionProblem.answer_data.forEach((answer) => {
            if (Object.keys(answer).length >= 3) {
              status = STATUS_EXAM_TEST_ITEM.DONE;
            }
          });
        }
        break;

      case NAME_TYPE_QUESTION.MATRIX:
        const matrixQuestionProblem = problem as MatrixProblemTest;
        if (
          matrixQuestionProblem.answer_data &&
          matrixQuestionProblem.answer_data.length
        ) {
          matrixQuestionProblem.answer_data.forEach((answer) => {
            if (answer.checked) {
              status = STATUS_EXAM_TEST_ITEM.DONE;
            }
          });
        }

        break;

      case NAME_TYPE_QUESTION.WRITTEN_QUESTION:
        const writingQuestionProblem = problem as WritingProblemTest;
        if (
          writingQuestionProblem.answer_data &&
          writingQuestionProblem.answer_data.length
        ) {
          status = STATUS_EXAM_TEST_ITEM.DONE;
        }

        break;

      case NAME_TYPE_QUESTION.SLIDER:
        const sliderQuestionProlem = problem as SliderProblemTest;
        if (sliderQuestionProlem.answer_data) {
          if (sliderQuestionProlem.answer_data.answerValue) {
            status = STATUS_EXAM_TEST_ITEM.DONE;
          }
        }

        break;

      case NAME_TYPE_QUESTION.FILL_IN_BLANK:
        const fillInBlankQuestionProblem = problem as FillInBlankProblemTest;
        if (
          fillInBlankQuestionProblem.answer_data &&
          fillInBlankQuestionProblem.answer_data.length
        ) {
          const answerData = fillInBlankQuestionProblem.answer_data;
          answerData.forEach((answer) => {
            if (!answer.issueId) {
              status = STATUS_EXAM_TEST_ITEM.UNDONE;
            } else {
              status = STATUS_EXAM_TEST_ITEM.DONE;
            }
          });
        }
        break;

      case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD:
        const uploadQuestionProblem = problem as TestTakerUploadProblemTest;
        if (isUsableArr(uploadQuestionProblem.answer_data)) {
          status = STATUS_EXAM_TEST_ITEM.DONE;
        }

        break;
      case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD:
        const recordVoiceQuestionProblem =
          problem as TestTakerRecordProblemTest;
        if (
          recordVoiceQuestionProblem &&
          recordVoiceQuestionProblem.answer_data
        ) {
          status = status = STATUS_EXAM_TEST_ITEM.DONE;
        }

        break;
      default:
        break;
    }

    return status;
  };

  const handleUpdateProblem = (problem: QuestionProblemTest) => {
    try {
      // If has max_times_answer propert check number of times answer.
      checkTimesAnswer();

      setIsChangeAnswer(true);

      const newQuestionProblems = questionProblems.map((questionProblem) => {
        if (problem.id === questionProblem.id) {
          return problem;
        }

        return questionProblem;
      });

      const status = handleStatusProblemQuestion(problem);

      setQuestionProblems(newQuestionProblems);

      setExamPartSelected({
        ...examPartSelected,
        exam_sections: (examPartSelected.exam_sections || []).map(
          (examSection) => {
            if (examSection.id === filterQuestionKey.sectionId) {
              const newExamItems = (examSection.exam_items || []).map(
                (examItem) => {
                  if (examItem.id === filterQuestionKey.itemId) {
                    return {
                      ...examItem,
                      question_data: {
                        problems: newQuestionProblems,
                      },
                      status: status,
                    };
                  }

                  return examItem;
                }
              );

              return {
                ...examSection,
                exam_items: newExamItems,
              };
            }

            return examSection;
          }
        ),
      });

      setExamItemSelected({
        ...examItemSelected,
        status: status,
        question_data: {
          problems: newQuestionProblems,
        },
      });
    } catch (error) {
      const message = (error as Error).message;
      swalMessage("エラー!!!", message, "warning", {
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div
      id="question_content"
      className="flex-1 w-full h-full max-h-full overflow-y-auto border border-secondary-light p-[30px]"
    >
      <div className="w-full">
        <p className="text-[24px] font-[600] leading-[32px] mb-[26px]">
          問
          <span className="px-[3px]">
            {currentQuestionNumber(
              examPartQuestions,
              filterQuestionKey.sectionId,
              filterQuestionKey.itemId
            )}
          </span>
        </p>
        {questionProblems && questionProblems.length ? (
          questionProblems.map((questionProblem, index) => (
            <div className={`text-[${textSize}px] max-h-full overflow-auto pr-[5px]`} key={questionProblem.id}>
              <TestItemQuestionType
                handleUpdateProblem={handleUpdateProblem}
                questionProblem={questionProblem}
              />
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ExaminationTestContent;
