import { ROUTE } from '../constants/route/router';

const getPath = (...routePrefixes: string[]) => {
  return `/${routePrefixes.join('/')}`;
};

const getAdminPath = (...routePrefixes: string[]) => {
  return `/${ROUTE.ADMIN_PREFIX}/${routePrefixes.join('/')}`;
};

const getCandidatePath = (...routePrefixes: string[]) => {
  return `/${ROUTE.MY_PAGE_PREFIX}/${routePrefixes.join('/')}`;
};

export { getPath, getAdminPath, getCandidatePath };
