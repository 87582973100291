import dayjs from '../dayjs';
import { TimeObject } from '../../types/globals';
import { TYPE_DATE_FORMAT } from '../constants/globals';
import { isValidDate } from './date';

// format milliseconds to hh:mm:ss string format
const formatTime = (milliseconds: number): string => {
  if (!milliseconds) return '-';

  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

// format milliseconds to hh:mm:ss string format
const minimizeFormatTime = (milliseconds: number): string => {
  if (!milliseconds) return '-';

  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${Number(formattedHours) ? `${formattedHours}:` : ''
    }${formattedMinutes}:${formattedSeconds}`;
};

const formatEstimationRequiredTime = (milliseconds?: number): string => {
  if (!milliseconds) return '';

  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}時間${formattedMinutes}分`;
};

const formatTotalTestTime = (milliseconds?: number) => {
  if (!milliseconds) return '-';

  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  const displayHours = Number(formattedHours) > 0 ? `${formattedHours}時間` : '';
  const displayMinutes = Number(formattedMinutes) > 0 ? `${formattedMinutes}分` : '0分';
  const displaySeconds = Number(formattedSeconds) > 0 ? `${formattedSeconds}秒` : '';

  return `${displayHours}${displayMinutes}${displaySeconds}`;
};

const formatReviewTestTime = (milliseconds?: number) => {
  if (!milliseconds) return '-';

  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  const displayHours = Number(formattedHours) > 0 ? `${formattedHours}時間` : '';
  const displayMinutes = Number(formattedMinutes) > 0 ? `${formattedMinutes}分` : '0分';
  const displaySeconds = Number(formattedSeconds) > 0 ? `${formattedSeconds}秒` : '0秒`';

  return `${displayHours}${displayMinutes}${displaySeconds}`;
};

const getTimeObjectFromMilliseconds = (milliseconds?: number): TimeObject => {
  if (!milliseconds) return {};

  const totalSeconds = Math.floor(milliseconds / 1000),
    hours = Math.floor(totalSeconds / 3600),
    minutes = Math.floor((totalSeconds % 3600) / 60),
    seconds = totalSeconds % 60;

  return { hours: hours, minutes: minutes, seconds: seconds };
};

const getMillisecondsFromTimeObject = ({ hours, minutes, seconds }: TimeObject): number => {
  const parsedHours = hours || 0,
    parsedMinutes = minutes || 0,
    parsedSeconds = seconds || 0;

  return (parsedHours * 60 * 60 + parsedMinutes * 60 + parsedSeconds) * 1000;
};

const convertTimestampToDateTime = (timestamp?: string | Date, short?: boolean, long?: boolean) => {
  if (!timestamp) return;
  const date = new Date(timestamp);
  const year = String(date.getFullYear());
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  if (long) return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  if (!short) return `${year}/${month}/${day} ${hours}:${minutes}`;
  return `${year}/${month}/${day}`;
};

//formatDateTime with input is ms or date string
const formatDateTime = (input?: number | string | Date, type?: number) => {
  if (
    (typeof input !== 'number' && typeof input !== 'string' && typeof input !== 'object') ||
    !input ||
    !dayjs(input).isValid
  )
    return '-';

  switch (type) {
    case TYPE_DATE_FORMAT.YEAR_MONTH:
      return dayjs(input).format('YYYY年M月');

    case TYPE_DATE_FORMAT.REGULAR_GLOBAL:
      return dayjs(input).format('YYYY/MM/DD');

    case TYPE_DATE_FORMAT.TIME_HOUR_MINUTE:
      return dayjs(input).format('H:mm');

    case TYPE_DATE_FORMAT.TIME_HOUR_MINUTE_24H:
      return dayjs(input).format('HH:mm');

    case TYPE_DATE_FORMAT.MONTH_DAY:
      return dayjs(input).format('M月D日');

    case TYPE_DATE_FORMAT.SHORT_DEFAULT:
      return dayjs(input).format('YYYY年M月D日');

    case TYPE_DATE_FORMAT.FULL_TO_MINUTE_DEFAULT:
      return dayjs(input).format('YYYY年 M月 D日 HH：mm');

    case TYPE_DATE_FORMAT.REGULAR_GLOBAL_FULL:
      return dayjs(input).format('YYYY/MM/DD HH:mm:ss');

    case TYPE_DATE_FORMAT.FULL_TO_MINUTE:
      return dayjs(input).format('YYYY-MM-DD HH:mm');

    // Other cases;
    default:
      return dayjs(input).format('YYYY年MM月DD日');
  }
};

const getFullDate = (date?: string | Date) => {
  const newDate = !date || !isValidDate(date) ? new Date() : new Date(date);
  return newDate.toISOString().slice(0, 10);
};

const formattedMinutesFromMilliseconds = (milliseconds: number) => {
  const convertSeconds = milliseconds / 1000;
  const minutes = Math.floor(convertSeconds / 60);
  const seconds = Math.floor(convertSeconds % 60);

  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${formattedMinutes}:${formattedSeconds}`;
}

const formattedTimeIntoMiliseconds = (time: number, type: 'hour' | 'minute' | 'second') => {
  switch (type) {
    case 'hour':
      return time * 60 * 60 * 1000;
    case "minute":
      return time * 60 * 1000;
    case "second":
      return time * 1000;
    default:
      return time;
  }
}

export {
  formatTime,
  formatEstimationRequiredTime,
  getTimeObjectFromMilliseconds,
  getMillisecondsFromTimeObject,
  convertTimestampToDateTime,
  formatTotalTestTime,
  formatDateTime,
  minimizeFormatTime,
  formatReviewTestTime,
  getFullDate,
  formattedMinutesFromMilliseconds,
  formattedTimeIntoMiliseconds
};
