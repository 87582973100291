import React, { HTMLProps } from 'react';

const Loading = ({
  color,
  size,
  ...props
}: { color?: ''; size?: '' } & HTMLProps<HTMLDivElement>) => {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default Loading;
