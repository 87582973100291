import React from "react";
import { MatrixProblemTest } from "../../../../../types/admin/question";
import { isEmptyObj, isUsableArr } from "../../../../../services/helpers/etc";
import {
  decodeHTMLEntities,
  groupArrayByProperty,
} from "../../../../../services/helpers/parseData";
import { getIssueById } from "../../../../../services/utils/admin/question";
import { CHECK_ANSWER_STATUS_TYPE } from "../../../../../services/constants/globals";
import Correct from "../../../../commons/icons/Learning/Correct";
import Incorrect from "../../../../commons/icons/Learning/Incorrect";
import { handleShowIconCheckAnswer } from "../RenderQuestionType";

type MatrixQuestionProps = {
  question: MatrixProblemTest;
  numberQuestion: number;
};

const MatrixQuestion = ({ question, numberQuestion }: MatrixQuestionProps) => {
  // Correct coordinates
  const currentCoordinatesByX = groupArrayByProperty(
    question.correct_coordinates || [],
    "x"
  );
  const coordinateKeyXs = Object.keys(currentCoordinatesByX);
  const currentCoordinatesByY = groupArrayByProperty(
    question.correct_coordinates || [],
    "y"
  );
  const coordinateKeyYs = Object.keys(currentCoordinatesByY);

  // Answer coordinates
  const answerCoordinatesByX = groupArrayByProperty(
    question.correct_coordinates || [],
    "x"
  );
  const answerCoordinateKeyXs = Object.keys(answerCoordinatesByX);
  const answerCoordinatesByY = groupArrayByProperty(
    question.correct_coordinates || [],
    "y"
  );
  const answerCoordinateKeyYs = Object.keys(answerCoordinatesByY);

  const currentIssues = question.issues || [];
  const currentCoordinates = question?.correct_coordinates || [];
  const answerDataCoordinates = question.answer_data || [];

  const getCoordinateByAxis = (x: number, y: number) => {
    return currentCoordinates.find(
      (coordinate) => coordinate.x === x && coordinate.y === y
    );
  };

  const getAnswerCoordinateByAxis = (x: number, y: number) => {
    return answerDataCoordinates.find(
      (coordinate) => coordinate.x === x && coordinate.y === y
    );
  };

  const handleCheckCorrectAnswer = (
    coordinateX: number,
    coordinateY: number
  ): number | null => {
    const correctCoordinate = currentCoordinates.find(
      (coordinate) =>
        coordinate.x === coordinateX && coordinate.y === coordinateY
    );
    const answerCoordinate = answerDataCoordinates.find(
      (coordinate) =>
        coordinate.x === coordinateX && coordinate.y === coordinateY
    );

    if (!correctCoordinate || !answerCoordinate) {
      return null;
    }

    if (
      correctCoordinate.checked &&
      correctCoordinate.checked === answerCoordinate.checked
    ) {
      return CHECK_ANSWER_STATUS_TYPE.CORRECT;
    }

    if (
      !correctCoordinate.checked &&
      correctCoordinate.checked === answerCoordinate.checked
    ) {
      return null;
    }

    return CHECK_ANSWER_STATUS_TYPE.INCORRECT;
  };

  const handleShowPointAnswer = (coordinateX: number, coordinateY: number) => {
    const answerStatus = handleCheckCorrectAnswer(coordinateX, coordinateY);

    if (answerStatus === CHECK_ANSWER_STATUS_TYPE.CORRECT) {
      return getCoordinateByAxis(coordinateX, coordinateY)?.point || 0;
    }

    if (answerStatus === CHECK_ANSWER_STATUS_TYPE.INCORRECT) {
      return 0;
    }
  };

  return (
    <div className="w-full flex items-stretch mb-[30px]">
      <div className="w-[40px]">
        <div className="w-full h-full flex items-center justify-center bg-warning-lighter">
          <span>{numberQuestion}</span>
        </div>
      </div>
      <div className="w-[calc(100%-40px)]">
        <div className="w-full flex items-center border-b border-b-danger-light py-[20px] max-2xl:flex-col justify-between">
          <div className="flex items-center gap-x-[10px] px-[10px] overflow-x-auto w-full max-w-[520px]">
            <div className="justify-center items-start px-[20px] py-[55px] flex flex-col">
              <div className="flex bg-success-lighter border-r border-[#C2C2C2]">
                <div className="border-l border-t border-[#C2C2C2] w-[120px] min-h-[52px]"></div>
                {!isEmptyObj(answerCoordinatesByY) &&
                  Object.keys(answerCoordinatesByY).map((coordinateYId) => (
                    <div key={coordinateYId} className="border-l border-t border-[#C2C2C2] max-w-[120px] min-h-[52px]">
                      <div
                        className="w-[100px] bg-white rounded-[7px] m-[10px] p-[5px]"
                        dangerouslySetInnerHTML={{
                          __html: decodeHTMLEntities(
                            getIssueById(currentIssues, Number(coordinateYId))
                              ?.label || "<div></div>"
                          ),
                        }}
                      ></div>
                    </div>
                  ))}
              </div>
              <div className="">
                <div className="bg-success-lighter">
                  <div className="flex">
                    <div className="">
                      {answerCoordinateKeyXs.map(
                        (
                          coordinateXId,
                          coordinateXIdIndex,
                          coordinateXIdArr
                        ) => (
                          <div key={`${coordinateXId}_td`} className="flex">
                            <div
                              className={`flex border-[#C2C2C2] ${
                                coordinateXIdIndex ===
                                coordinateXIdArr.length - 1
                                  ? "border-b"
                                  : ""
                              }`}
                            >
                              <div className="border-l border-t border-[#C2C2C2] max-w-[120px] min-h-[52px]">
                                <div
                                  className="w-[100px] bg-white rounded-[7px] m-[10px] p-[5px]"
                                  dangerouslySetInnerHTML={{
                                    __html: decodeHTMLEntities(
                                      getIssueById(
                                        currentIssues,
                                        Number(coordinateXId)
                                      )?.label || "<div></div>"
                                    ),
                                  }}
                                ></div>
                              </div>
                              <div className="flex">
                                {isUsableArr(answerDataCoordinates) &&
                                  answerDataCoordinates
                                    ?.filter(
                                      (coordinateY) =>
                                        Number(coordinateY.x) ===
                                        Number(coordinateXId)
                                    )
                                    ?.map((coordinateY) => (
                                      <div
                                        className=""
                                        key={coordinateY?.id + "y"}
                                      >
                                        <div className="pl-[15px] border-l border-t bg-white border-[#C2C2C2] w-[120px] h-full flex gap-x-[10px] items-center">
                                          <input
                                            type="checkbox"
                                            name="checked"
                                            id=""
                                            checked={
                                              !!getAnswerCoordinateByAxis(
                                                coordinateY.x,
                                                coordinateY.y
                                              )?.checked || false
                                            }
                                            className="w-[20px] h-[20px]"
                                            onChange={() => {}}
                                          />
                                        </div>
                                      </div>
                                    ))}
                              </div>
                            </div>
                            <div className="text-primary-darker border-l border-[#C2C2C2] bg-white cursor-pointer flex items-center p-[10px]"></div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-[10px] px-[10px] overflow-x-auto w-full max-w-[520px]">
            <div className="justify-center items-start px-[20px] py-[55px] flex flex-col">
              <div className="flex bg-success-lighter border-r border-[#C2C2C2]">
                <div className="border-l border-t border-[#C2C2C2] w-[120px] min-h-[52px]"></div>
                {!isEmptyObj(currentCoordinatesByY) &&
                  Object.keys(currentCoordinatesByY).map((coordinateYId) => (
                    <div key={coordinateYId} className="border-l border-t border-[#C2C2C2] w-[120px] min-h-[52px]">
                      <div
                        className="w-[100px] bg-white rounded-[7px] m-[10px] p-[5px]"
                        dangerouslySetInnerHTML={{
                          __html: decodeHTMLEntities(
                            getIssueById(currentIssues, Number(coordinateYId))
                              ?.label || "<div></div>"
                          ),
                        }}
                      ></div>
                    </div>
                  ))}
              </div>
              <div className="">
                <div className="bg-success-lighter">
                  <div className="flex">
                    <div className="">
                      {coordinateKeyXs.map(
                        (
                          coordinateXId,
                          coordinateXIdIndex,
                          coordinateXIdArr
                        ) => (
                          <div key={`${coordinateXId}_td`} className="flex">
                            <div
                              className={`flex border-[#C2C2C2] ${
                                coordinateXIdIndex ===
                                coordinateXIdArr.length - 1
                                  ? "border-b"
                                  : ""
                              }`}
                            >
                              <div className="border-l border-t border-[#C2C2C2] max-w-[120px] min-h-[52px]">
                                <div
                                  className="w-[100px] bg-white rounded-[7px] m-[10px] p-[5px]"
                                  dangerouslySetInnerHTML={{
                                    __html: decodeHTMLEntities(
                                      getIssueById(
                                        currentIssues,
                                        Number(coordinateXId)
                                      )?.label || "<div></div>"
                                    ),
                                  }}
                                ></div>
                              </div>
                              <div className="flex">
                                {isUsableArr(currentCoordinates) &&
                                  currentCoordinates
                                    ?.filter(
                                      (coordinateY) =>
                                        Number(coordinateY.x) ===
                                        Number(coordinateXId)
                                    )
                                    ?.map((coordinateY) => (
                                      <div
                                        className=""
                                        key={coordinateY?.id + "y"}
                                      >
                                        <div className="pl-[15px] border-l border-t bg-white border-[#C2C2C2] w-[120px] h-full flex gap-[10px] items-center">
                                          <input
                                            type="checkbox"
                                            name="checked"
                                            id=""
                                            checked={
                                              !!getCoordinateByAxis(
                                                coordinateY.x,
                                                coordinateY.y
                                              )?.checked || false
                                            }
                                            className="w-[20px] h-[20px]"
                                            onChange={() => {}}
                                          />
                                          {handleShowIconCheckAnswer(
                                            handleCheckCorrectAnswer(
                                              coordinateY.x,
                                              coordinateY.y
                                            )
                                          )}
                                          <span className="text-[14px]">
                                            {handleShowPointAnswer(
                                              coordinateY.x,
                                              coordinateY.y
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                              </div>
                            </div>
                            <div className="text-primary-darker border-l border-[#C2C2C2] bg-white cursor-pointer flex items-center p-[10px]"></div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white cursor-pointer flex justify-center items-center p-[10px] w-[10%]">{question.score}</div>
        </div>
      </div>
    </div>
  );
};

export default MatrixQuestion;
