const getLocationOfThumb = (value: number, min: number, max: number, offsetWidth: number) => {
   const thumbHalfWidth = 10;
   const balancePixel = 15;
   const progress = Number(((Number(value) - Number(min)) / (Number(max) - Number(min))) * (100));
   const thumb = (((value - min) / (max - min)) * ((offsetWidth - thumbHalfWidth) - thumbHalfWidth)) + thumbHalfWidth;
   let thumbPosition = `${thumb - balancePixel}px`;
   let progressPosition = `${progress}%`;
   return { postionThumb: thumbPosition, progressPosition: progressPosition };
};

const validateLimit = ( value: number,
   startLimit: number, endLimit: number,
   startDefaultValue: number, endDefaultValue: number) => {

   if (value > endLimit) {
      return endDefaultValue
   }
   if (value < startLimit) {
      return  startDefaultValue
   }
   else{
      return value;
   }
}

export {
   getLocationOfThumb,
   validateLimit
};