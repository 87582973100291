import React, { ChangeEvent } from "react";
import { WithChildren } from "../../../../../types/globals";

type FieldInputProps = {
  label: string;
  type?: string;
  required?: boolean;
  name?: string;
  placeholder?: string;
  className?: string;
  value?: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  hasChildren?: boolean;
} & WithChildren;

const FieldInput = ({
  label,
  type = "text",
  name,
  placeholder,
  hasChildren = false,
  required = true,
  className,
  value,
  handleChange,
  children,
}: FieldInputProps) => {
  return (
    <div className="flex border-b border-secondary-lighter mt-[3px]">
      <div
        className={`${className} min-h-[40px] flex items-start justify-between px-[10px] py-[12px] text-[12px] basis-[25%] bg-success-lighter`}
      >
        <span className="text-[14px] font-medium">{label}</span>
        {required && (
          <span className="text-white text-[9px] px-[6px] py-[3px] bg-danger rounded-[3px]">
            必須
          </span>
        )}
      </div>
      {hasChildren ? (
        <div className="w-full pl-[10px] flex-1 pt-[5px]">{children}</div>
      ) : (
        <div className="pl-[10px] flex-1 pt-[5px]">
          <input
            type={type}
            name={name}
            className="w-full h-[30px] px-[13px] py-[5px] text-[12px] placeholder:text-secondary-light placeholder:text-[12px]"
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};

export default FieldInput;
