import React from "react";
import { IoIosArrowBack } from "react-icons/io";

const ButtonBackStep = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="w-full flex justify-start items-center">
      <button
        onClick={onClick}
        className="w-[140px] h-[40px] flex justify-start items-center bg-secondary-light rounded-[5px] text-white"
      >
        <div className="w-[70%] flex justify-between items-center px-[15px]">
          <IoIosArrowBack />
          戻る
        </div>
      </button>
    </div>
  );
};

export default ButtonBackStep;
