import React, { useState, useContext } from "react";
import { ChildrenWithProps } from "../types/globals";
import { Examination, GroupGroup } from "../types/admin/examination";

interface ExaminationContextType {
  groupGroups: GroupGroup[];
  setGroupGroups: React.Dispatch<React.SetStateAction<GroupGroup[]>>;
  totalPages: number;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
  examinations: Examination[];
  setExaminations: React.Dispatch<React.SetStateAction<Examination[]>>;
}

const ExaminationContext = React.createContext<ExaminationContextType>({
  groupGroups: [],
  setGroupGroups: () => {},
  totalPages: 1,
  setTotalPages: () => { },
  examinations: [],
  setExaminations: () => {}
});

const ExaminationProvider = ({ children }: ChildrenWithProps) => {
  const [groupGroups, setGroupGroups] = useState<GroupGroup[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [examinations, setExaminations] = useState<Examination[]>([]);

  return (
    <ExaminationContext.Provider value={{ groupGroups, setGroupGroups, totalPages, setTotalPages, examinations, setExaminations }}>
      {children}
    </ExaminationContext.Provider>
  );
};

export default ExaminationProvider;

export const useExaminationContext = () => useContext(ExaminationContext);
