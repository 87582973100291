import InformationDetail from "./PropertiesSettingComponent/InformationDetail";
import { useEffect, useState } from "react";
import { PiClock } from "react-icons/pi";
import LayoutSetting from "./PropertiesSettingComponent/LayoutSetting";
import { swalError, swalSuccess } from "../../../../../services/helpers/swal";
import { ExamPartSettingData } from "../../../../../types/admin/exam";
import ItemSettingInput from "./PropertiesSettingComponent/ItemSettingInput";
import "../../../../../assests/styles/settingProperties.css";
import { useAuthoringExamContext } from "../../../../../context/Exam/AuthoringExamContext";
import CheckedSettingInput from "./PropertiesSettingComponent/CheckedSettingInput";
import { DETAIL_PART_MESSAGES } from "../../../../../services/constants/admin/pages/examSetting/partSettingExam";
import ButtonSubmit from "./PropertiesSettingComponent/ButtonSubmit";
import {
  ANSWER_IN_ORDER_VALUE,
  SETTING_FEATURES,
  SETTING_PROPERTY_NAMES,
  TYPE_OF_EXAM_DISPLAY,
} from "../../../../../services/constants/admin/pages/exam";
import InputTimeProperties from "./PropertiesSettingComponent/InputTimeProperties";
import { LiaUserSolid } from "react-icons/lia";
import CheckboxProperties from "./PropertiesSettingComponent/CheckboxProperties";
import {
  DETAIL_SECTION_MESSAGES,
  SECTION_SETTING_TOOLS,
} from "../../../../../services/constants/admin/pages/examSetting/sectionSettingExam";
import { AiOutlineEye } from "react-icons/ai";
import { isEmptyObj } from "../../../../../services/helpers/etc";
import {
  ExamPartType,
  ExamType,
} from "../../../../../types/admin/examAuthoring";

type radioType = {
  value?: string;
  type?: string;
  label?: string;
};

const ANSWER_IN_ORDER: radioType[] = [
  {
    value: ANSWER_IN_ORDER_VALUE.SEQUENTIAL,
    type: "radio",
    label: "順次式",
  },
  {
    value: ANSWER_IN_ORDER_VALUE.NON_SEQUENTIAL,
    type: "radio",
    label: "非順次式",
  },
];

const PartSetting = () => {
  const [examPart, setExamPart] = useState<ExamPartType>({
    name: "",
    exam_id: 0,
    exam_sections: [],
    order: 0,
    parent_id: 0,
  });
  const [settingPartData, setSettingPartData] = useState<ExamPartSettingData>();
  const [error, setError] = useState<string>("");
  const { exam, settingPropertyOrders, setExam } = useAuthoringExamContext();

  const validateTime = (nameInput: string, value: number) => {
    const timeName = nameInput.split("_")[1];
    let timeValue = value;

    if (value > 60) {
      timeValue = 60;
    }

    if (value < 0) {
      timeValue = 0;
    }

    if (nameInput.split("_")[0] == "max") {
      setSettingPartData({
        ...settingPartData,
        max_times: { ...settingPartData?.max_times, [timeName]: timeValue },
      });
    }
  };

  const handleExamPartData = (exam: ExamType, object: object) => {
    const newExamParts = exam.exam_parts.map((exPart) => {
      if (exPart.order === examPart.order) {
        return { ...exPart, ...object };
      }

      return exPart;
    });

    return { ...exam, exam_parts: newExamParts };
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let currentTarget = e.target;
    const { name, value, checked, type } = currentTarget;
    switch (name) {
      case SETTING_PROPERTY_NAMES.NAME:
        if (!value) {
          setError("タイトルを指定してください。");
        } else {
          setError("");
        }
        setExamPart({ ...examPart, [name]: value });
        setExam((exam) => handleExamPartData(exam, { name: value }));

        break;
      case SETTING_PROPERTY_NAMES.SHOW_REVIEW:
        setSettingPartData({
          ...settingPartData,
          [name]: currentTarget.checked,
        });

        break;
      case SETTING_PROPERTY_NAMES.REMOVE_ANSWER:
      case SETTING_PROPERTY_NAMES.SHOW_CALCULATOR:
      case SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT:
      case SETTING_PROPERTY_NAMES.SHOW_ZOOM_GLASS:
      case SETTING_PROPERTY_NAMES.SHOW_ZOOM_TOOL:
        let candidateTools = settingPartData?.candidate_tools || [];
        let candidateToolsData;
        if (candidateTools?.includes(name)) {
          candidateTools = candidateTools.filter((item) => item !== name);
          candidateToolsData = { candidate_tools: candidateTools };
        } else {
          candidateToolsData = {
            candidate_tools: [...candidateTools, name],
          };
        }

        setSettingPartData({ ...settingPartData, ...candidateToolsData });
        break;

      case SETTING_PROPERTY_NAMES.MAX_HOUR:
      case SETTING_PROPERTY_NAMES.MAX_MINUTE:
      case SETTING_PROPERTY_NAMES.MAX_SECOND:
        validateTime(name, Number(value));
        break;

      case SETTING_PROPERTY_NAMES.SCORING_TYPE:
      case SETTING_PROPERTY_NAMES.ANSWER_IN_ORDER:
        setSettingPartData({
          ...settingPartData,
          [name]: value,
        });
        break;

      case SETTING_PROPERTY_NAMES.IS_SUBMISSION_AFTER_DEADLINE:
      case SETTING_PROPERTY_NAMES.SHOW_TIMEOUT_WARNING:
        setSettingPartData({
          ...settingPartData,
          [name]: checked,
        });
        break;
    }
  };

  const handleCheckSelectedMaxTime = () => {
    if (
      settingPartData?.max_times?.hours === 0 &&
      settingPartData?.max_times?.minutes === 0 &&
      settingPartData?.max_times?.seconds === 0
    ) {
      throw new Error("大問の制限時間を指定してください。");
    }
  };

  const handleSubmitUpdate = () => {
    try {
      handleCheckSelectedMaxTime();
      setExam((exam) =>
        handleExamPartData(exam, { setting_properties: settingPartData })
      );

      swalSuccess();
    } catch (error) {
      swalError((error as Error).message);
    }
  };

  useEffect(() => {
    if (
      isEmptyObj(exam) ||
      settingPropertyOrders.orderType !== TYPE_OF_EXAM_DISPLAY.PART
    ) {
      return;
    }

    const findExamPart = exam.exam_parts.find(
      (examPart) => examPart.order === settingPropertyOrders.orderPart
    );
    if (!findExamPart) return;

    setExamPart(findExamPart);
    setSettingPartData(findExamPart.setting_properties);
  }, [settingPropertyOrders]);

  return (
    <>
      <div className="flex-col bg-[#EFF1F0] h-auto border-[#BEBEBE] font-[400] min-w-[320px]">
        <div className="w-full flex-col gap-[10px] p-[20px] bg-[#F4F4F4] ">
          <div className="text-[14px] mb-[15px]">{examPart.name}</div>
          <ItemSettingInput
            label="タイトル"
            messageDetail={DETAIL_PART_MESSAGES.NAME}
            classNameLabel="basis-[28%]"
            handleChange={handleChangeValue}
            name="name"
            type="text"
            className="basis-[60%] rounded-none"
            value={examPart?.name}
            placeholder={examPart?.name}
            error={error}
          />
          <CheckedSettingInput
            label="ナビゲーション"
            name={SETTING_PROPERTY_NAMES.ANSWER_IN_ORDER}
            messageDetail={DETAIL_PART_MESSAGES.ANSWER_IN_ORDER}
            arrItems={ANSWER_IN_ORDER}
            classNameLabel="basis-[40%]"
            chosenValue={settingPartData?.answer_in_order || ""}
            handleChange={handleChangeValue}
          />
        </div>
        <LayoutSetting
          icon={<AiOutlineEye className="w-[24px] h-[24px]" />}
          label={SETTING_FEATURES.TEST_NAVIGATION}
        >
          <div className={`w-full flex-col gap-[10px] p-[20px] bg-[#F4F4F4] `}>
            <div className="flex pb-[15px] justify-between items-center gap-[15px]">
              <div className="flex justify-start items-center gap-[8px]">
                <input
                  id={SETTING_PROPERTY_NAMES.SHOW_REVIEW}
                  name={SETTING_PROPERTY_NAMES.SHOW_REVIEW}
                  type="checkbox"
                  className="input_check_red checked:ring-transparent focus:ring-transparent cursor-pointer w-[24px] h-[24px] rounded-[5px]"
                  onChange={handleChangeValue}
                  checked={settingPartData?.show_review || false}
                />
                <label
                  htmlFor={SETTING_PROPERTY_NAMES.SHOW_REVIEW}
                  className="text-[14px] cursor-pointer"
                >
                  見直しスクリーン有効
                </label>
              </div>
              <InformationDetail text={DETAIL_SECTION_MESSAGES.SHOW_REVIEW} />
            </div>
          </div>
        </LayoutSetting>
        <LayoutSetting
          icon={<LiaUserSolid className="w-[24px] h-[24px]" />}
          label={SETTING_FEATURES.EXAMINEE_TOOLS}
        >
          <CheckboxProperties
            selectedValues={settingPartData?.candidate_tools || []}
            arrCheckbox={SECTION_SETTING_TOOLS}
            handleChangeValue={handleChangeValue}
          />
        </LayoutSetting>
        <LayoutSetting
          icon={<PiClock className="w-[24px] h-[24px]" />}
          label={SETTING_FEATURES.TIME_LIMIT}
        >
          <div className={`w-full flex-col gap-[10px] p-[20px] bg-[#F4F4F4] `}>
            <InputTimeProperties
              type="max"
              label="最大時間"
              times={settingPartData?.max_times || {}}
              messageDetail={DETAIL_PART_MESSAGES.MAX_PART_TIME}
              handleChangeValue={handleChangeValue}
            />
            <ItemSettingInput
              label="締切後の送信を許可"
              messageDetail={DETAIL_PART_MESSAGES.IS_SUBMISSION_AFTER_DEADLINE}
              handleChange={handleChangeValue}
              name={SETTING_PROPERTY_NAMES.IS_SUBMISSION_AFTER_DEADLINE}
              type="checkbox"
              className="input_check_red checked:ring-transparent focus:ring-transparent cursor-pointer w-[24px] h-[24px] rounded-[5px]"
              checked={settingPartData?.is_submission_after_deadline || false}
            />
            <ItemSettingInput
              label="時間切れ警告を表示"
              messageDetail={DETAIL_PART_MESSAGES.SHOW_TIMEOUT_WARNING}
              handleChange={handleChangeValue}
              name={SETTING_PROPERTY_NAMES.SHOW_TIMEOUT_WARNING}
              type="checkbox"
              className="input_check_red checked:ring-transparent focus:ring-transparent cursor-pointer w-[24px] h-[24px] rounded-[5px]"
              checked={settingPartData?.show_timeout_warning || false}
            />
          </div>
        </LayoutSetting>
        <ButtonSubmit error={error} handleSubmitUpdate={handleSubmitUpdate} />
      </div>
    </>
  );
};
export default PartSetting;
