import React from "react";
import ExamBase from ".";
import ListExam from "../../../components/admin/Exam/List";
import ListExamProvider from "../../../context/Exam/ListExamContext";

const ExamListPage = () => {
  return (
    <ExamBase>
      <ListExamProvider>
        <ListExam />
      </ListExamProvider>
    </ExamBase>
  );
};

export default ExamListPage;
