import React, { ChangeEvent } from "react";
import { WritingProblemTest } from "../../../../../types/admin/question";
import { handleShowIconCheckAnswer } from "../RenderQuestionType";
import { CHECK_ANSWER_STATUS_TYPE } from "../../../../../services/constants/globals";
import { htmlToString } from "../../../../../services/utils/admin/writingQuestion";

type WritingQuestionProps = {
  question: WritingProblemTest;
  numberQuestion: number;
  handleFillScoreAnswer: (e: ChangeEvent<HTMLInputElement>) => void;
};

const WritingQuestion = ({
  question,
  numberQuestion,
  handleFillScoreAnswer,
}: WritingQuestionProps) => {
  return (
    <div className="w-full flex items-stretch mb-[30px]">
      <div className="w-[40px]">
        <div className="w-full h-full flex items-center justify-center bg-warning-lighter">
          <span>{numberQuestion}</span>
        </div>
      </div>
      <div className="w-full flex items-center border-b border-b-danger-light py-[10px]">
        <div className="flex-1 px-[15px] text-[12px] font-[500]">
          {question.answer_data || ""}
        </div>
        <div className="flex-1 w-full flex items-center gap-x-[15px] px-[15px]">
          {htmlToString(question.answerValue || "")}
        </div>
        <div className="w-[120px] justify-end flex items-center gap-x-[5px]">
          <input
            type="text"
            name="score"
            className="h-[30px] !px-0 text-center font-[500] placeholder:text-secondary-light text-[14px] w-[60px]"
            value={question?.score ? question.score : ""}
            onChange={handleFillScoreAnswer}
          />
          <span className="text-[14px] font-[500]">/{question.point || 0}</span>
        </div>
      </div>
    </div>
  );
};

export default WritingQuestion;
