import { PATH_CANDIDATE_HOME, PATH_MY_PROFILE } from "../route/router";

type redirectButtonType = {
    title: string;
    content: string;
    url_image?: string;
    href: string;
}

const REDIRECT_BUTTONS: redirectButtonType[] = [
    {
        title: "受験申込",
        content: "受験申し込みが可能なIBT・CBT試験の日時・会場の確認、受験予約手続きはこちら",
        url_image: "",
        href: PATH_CANDIDATE_HOME.REGISTER_EXAMINATION
    },
    {
        title: "アカウント情報変更",
        content: "登録メールアドレスやプロフィールの確認・編集はこちら",
        url_image: "",
        href: PATH_MY_PROFILE.DEFAULT
    },
    // {
    //     title: "試験履歴",
    //     content: "テキストダミーテキストタイトルダミーテキストこれは正式な文章の代わりに入れて使うダミーテキストです。",
    //     url_image: "",
    //     href: ""
    // },
];

const EXAMINATION_TIME_STATUS = {
    END: 0,
    START: 1,
    REMAINING: 2,
}

const EXAMINATION_HOME_STATUS = {
    INCOMING: 5,
    FINISHED: 6
}

const EXAMINATION_TIME_STATUS_NAME = {
    END: "終了",
    START: "スタート",
}

export { REDIRECT_BUTTONS, EXAMINATION_TIME_STATUS, EXAMINATION_TIME_STATUS_NAME, EXAMINATION_HOME_STATUS }