import React from "react";
import PDFViewer from "../../candidate/common/PDFViewer";
import AudioPlayer from "../../candidate/common/AudioPlayer";
import VideoPlayer from "../../candidate/common/VideoPlayer";
import { AiFillCloseSquare } from "react-icons/ai";

type FileModalProps = {
  src?: string;
  fileType?: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fileName?: string;
};

const PreviewFileModal = ({
  setIsOpen,
  fileType = "pdf",
  src,
  fileName,
}: FileModalProps) => {
  const renderFile = (fileType: String) => {
    switch (fileType) {
      case "jpeg":
      case "png":
      case "jpg":
        return <img src={`${src}?${Date.now()}`} width={800} />;
      case "pdf":
        return (
          <PDFViewer
            src={`${src}?${Date.now()}`}
            className="min-w-[800px] min-h-[500px]"
          />
        );
      case "mp3":
        return (
          <div className="min-w-[810px] min-h-[335px] flex flex-col items-center justify-center">
            <div className="text-[18px] font-[400] text-black">{fileName}</div>
            <AudioPlayer src={`${src}?${Date.now()}`} />
          </div>
        );
      case "mp4":
      case "mov":
        return (
          <VideoPlayer
            source={`${src}?${Date.now()}`}
            width={900}
            height={506}
          />
        );
      default:
        return;
    }
  };
  return (
    <section className="">
      <div className="dark_bg" onClick={() => setIsOpen(false)} />
      <div className="centered z-[1001]">
        <div
          className="absolute top-[-15px] right-[-35px] text-black cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <AiFillCloseSquare size={35} />
        </div>
        <div className="modal">{renderFile(fileType)}</div>
      </div>
    </section>
  );
};

export default PreviewFileModal;
