import React, { FC, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PATH_ADMIN_CANDIDATE, ROUTE } from "../services/constants/route/router";
import Logo from "../components/Login/Logo";
import { checkIsSubdomain } from "../services/helpers/domain";
import YellowBlock from "../components/Login/YellowBlock";
import BeigeBlock from "../components/Login/BeigeBlock";
import OrangeBlock from "../components/Login/OrangeBlock";
import BlueBlock from "../components/Login/BlueBlock";
import GreenBlock from "../components/Login/GreenBlock";
import PurpleBlock from "../components/Login/PurpleBlock";

interface LayoutProps {
  children?: JSX.Element;
  disableTokenCheck?: boolean;
}

const AuthLayout: FC<LayoutProps> = (props) => {
  let accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;
  const [token, setToken] = useState(accessToken);
  const isSubdomain = checkIsSubdomain();
  const navigate=useNavigate();

  // if (!isSubdomain) {
  //   return <Navigate to={ROUTE.HOME} replace={true}/>
  // }

  return (
    <>
      {token && !props.disableTokenCheck ? (
        <Navigate to={PATH_ADMIN_CANDIDATE.DEFAULT} replace={true} />
      ) : (
        <div className="bg-white w-full min-h-screen text-center relative flex flex-col">
          <div onClick={()=>navigate(`${ROUTE.LOGIN}`)}>
            <Logo
              otherStyle={
                "mt-[70px] mx-auto max-sm:mt-[35px] max-sm:!w-[100px] max-sm:!h-[74px]"
              }
            />
          </div>
          <div className="z-[1000] flex flex-col">{props.children}</div>

          <div className="fixed top-0 left-0 z-10">
            <YellowBlock />
          </div>
          <div className="fixed top-[91px] left-[146px]">
            <BeigeBlock />
          </div>
          <div className="fixed bottom-0 left-0">
            <OrangeBlock />
          </div>
          <div className="fixed bottom-[-13px] right-[133px]">
            <BlueBlock />
          </div>
          <div className="fixed bottom-[38%] right-0">
            <GreenBlock />
          </div>
          <div className="fixed bottom-[27%] right-0">
            <PurpleBlock />
          </div>
        </div>
      )}
    </>
  );
};

export default AuthLayout;
