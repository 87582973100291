/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import LayoutWaitingApi from "../../../commons/layouts/LayoutWaitingAPI";
import ExamItem from "./ExamItem";
import { useListExamContext } from "../../../../context/Exam/ListExamContext";

const ListExam = () => {
  const { exams, setExams, selectedExam, setSelectedExam, setSideAction } =
    useListExamContext();
  const examsFilter = exams.filter((exam) => !exam.parent_id);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);

  const unselectHandle = () => {
    setSelectedExam();
    setSideAction("");
  };

  useEffect(() => {
    if (exams.length > 0) {
      setWaitingApi(false);
      return;
    }

    const fetchExams = async () =>
      await request.get(API.ADMIN_EXAM.LIST, (data) => {
        setExams(data);
        setWaitingApi(false);
      });
    fetchExams();
  }, [exams.length]);

  return (
    <>
      {selectedExam && selectedExam.id ? (
        <div className="flex justify-between text-[12px] bg-primary-extralight px-[10px] py-[3px] rounded-t-[5px]">
          <div className="text-secondary">選択中</div>
          <div
            className="text-blue-400 cursor-pointer"
            onClick={unselectHandle}
          >
            選択を外す
          </div>
        </div>
      ) : (
        <></>
      )}
      <LayoutWaitingApi waitingApi={waitingApi} className="w-[265px] h-[400px]">
        <div
          className={`pl-[10px] pt-[5px] w-[265px] bg-white h-[400px] overflow-auto mb-[10px] rounded-[2px] animate-[show_0.5s_ease-in-out]`}
        >
          {examsFilter.map((exam, index) => (
            <ExamItem exam={exam} key={index} />
          ))}
        </div>
      </LayoutWaitingApi>
    </>
  );
};

export default ListExam;
