import classNames from "classnames";

type QuestionSelectorButtonProps = {
  isChoose: boolean;
  questionNumber: string;
  onClick: () => void;
  status: number;
};

const QuestionChooserButton = ({
  isChoose,
  questionNumber,
  onClick,
  status,
}: QuestionSelectorButtonProps) => {
  return (
    <button
      className={classNames(
        "bg-white p-[5px] flex items-center gap-x-[8px] border border-success-extralight",
        {
          "!border-primary-synch": isChoose,
        }
      )}
      onClick={onClick}
    >
      <div className="w-[35px] h-[30px] bg-warning-lighter text-center text-[14px] py-[5px]">
        <span className="border-b border-secondary-dark">{questionNumber}</span>
      </div>
      <div
        className={classNames(
          "mx-auto border-b  text-[12px] text-center font-[500] leading-3 pb-[2px]",
          {
            "text-danger border-b-danger": !status,
            "border-b-secondary-dark": !!status
          }
        )}
      >
        {!!status ? "済" : "未"}
      </div>
    </button>
  );
};

export default QuestionChooserButton;
