import React from "react";
import {
  AuthoringData,
  QuestionProblem,
} from "../../../../types/admin/question";
import { swalLoading } from "../../../../services/helpers/swal";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { useQuestionListContext } from "../../../../context/Question/ListQuestionContext";
import { decodeHTMLEntities } from "../../../../services/helpers/parseData";
import ModalEditQuestionType from "../../Exam/Create/PropertiesSetting/PropertiesSettingComponent/ModalEditQuestionType";
import { NAME_ACTION_DETAIL_QUESTION } from "../../../../services/constants/admin/pages/question";
import { isUsableArr } from "../../../../services/helpers/etc";
import TestItemQuestionType from "../../../candidate/Examination/ExaminationTestComponent/TestItemQuestionType";

const DetailPreview = () => {
  const { selectedQuestion, setDetailActionName } = useQuestionListContext();
  const [authoringData, setAuthoringData] = React.useState<AuthoringData>({});
  const handleUpdateExamItem = (examItem: QuestionProblem) => {};

  React.useEffect(() => {
    if (!selectedQuestion.is_file) {
      setDetailActionName(NAME_ACTION_DETAIL_QUESTION.EDIT);
      setAuthoringData({});
      return;
    }

    const fetchQuestionDetail = async () => {
      swalLoading();
      await request.get(
        `${API.ADMIN_QUESTION_RESOURCE.DETAIL}?resource_id=${selectedQuestion.question_resource_id}`,
        (res) => {
          if (!res) {
            setAuthoringData({});
            return;
          }

          setAuthoringData(JSON.parse(res));
        }
      );

      document.getElementById("question_preview")?.scrollTo(0, 0);
    };

    fetchQuestionDetail();
  }, [selectedQuestion]);

  return (
    <div className="">
      <div className="mb-[17px]">問題プレビュー</div>
      <div
        id="exam_preview"
        className="max-h-[700px] overflow-auto shadow-xl p-[20px] animate-[show_0.5s_ease-in-out]"
      >
        {authoringData.problems && isUsableArr(authoringData.problems) ? (
          authoringData.problems.map((problem) => {
            return (
              <div className="py-[20px] border-b border-secondary-light" key={problem.id}>
                <TestItemQuestionType
                  key={problem.id}
                  questionProblem={problem}
                  handleUpdateProblem={handleUpdateExamItem}
                  isDisabled={true}
                />
              </div>
            );
          })
        ) : (
          <div className="text-center text-[16px]">データがありません。</div>
        )}
      </div>
    </div>
  );
};

export default DetailPreview;
