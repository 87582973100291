import React from "react";
import { Link } from "react-router-dom";
import {
  ADMIN_MENU_BORDER_INDEX,
  ADMIN_MENU_DATA,
} from "../../services/constants/admin/menu";

const Menu = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => {
  return (
    <div
      {...props}
      className={`w-[220px] bg-primary text-white px-[10px] py-[33px] rounded-[5px] ${props.className}`}
    >
      {ADMIN_MENU_DATA.map((item, index) => (
        <div
          key={index}
          className={`mb-[15px] cursor-pointer ${
            ADMIN_MENU_BORDER_INDEX.includes(index) &&
            "pb-[15px] border-b border-white"
          }`}
        >
          <Link to={item.href} className="hover:text-secondary-lighter">{item.label}</Link>
        </div>
      ))}
    </div>
  );
};

export default Menu;
