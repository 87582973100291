const SYMBOLS = [
    {
      MAIN_SYMBOLS: [
        {
          label: "x^{n}",
          value: "^{}",
        },
        {
          label: "x_{n}",
          value: "_{}",
        },
        {
          label: "\\sqrt{x}",
          value: "\\sqrt{}",
        },
        {
          label: "\\sum_{}^{}",
          value: "\\sum_{}^{}",
        },
        {
          label: "\\prod_{}^{}",
          value: "\\prod_{}^{}",
        },
        {
          label: "\\int_{}^{}",
          value: "\\int_{}^{}",
        },
        {
          label: "\\frac{a}{b}",
          value: "\\frac{}{}",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\sqrt[n]{x}",
          value: "\\sqrt[]{}",
        },
        {
          label: "\\oint_{}^{}",
          value: "\\oint_{}^{}",
        },
        {
          label: "\\frac{d }{dx}",
          value: "\\frac{d }{dx}",
        },
        {
          label: "\\frac{\\partial }{\\partial x}",
          value: "\\frac{\\partial }{\\partial x}",
        },
        {
          label: "\\implies",
          value: "\\implies",
        },
        {
          label: "\\iff",
          value: "\\iff",
        },
        {
          label: "\\times",
          value: "\\times",
        },
        {
          label: "\\#",
          value: "\\#",
        },
        {
          label: "\\wedge",
          value: "\\wedge",
        },
        {
          label: "\\vee",
          value: "\\vee",
        },
        {
          label: "\\in",
          value: "\\in",
        },
        {
          label: "\\notin",
          value: "\\notin",
        },
        {
          label: "\\lim_{x \\to 0}",
          value: "\\lim_{x \\to 0}",
        },
        {
          label: "\\infty",
          value: "\\infty",
        },
        {
          label: "\\{  \\} ",
          value: "\\{  \\} ",
        },
        {
          label: "\\pm",
          value: "\\pm",
        },
        {
          label: "\\mp",
          value: "\\mp",
        },
        {
          label: "\\emptyset",
          value: "\\emptyset",
        },
        {
          label: "\\forall",
          value: "\\forall",
        },
        {
          label: "\\exists",
          value: "\\exists",
        },
        {
          label: "\\exists",
          value: "\\exists",
        },
        {
          label: "\\neq",
          value: "\\neq",
        },
        {
          label: "\\Delta",
          value: "\\Delta",
        },
        {
          label: "\\to",
          value: "\\to",
        },
        {
          label: "\\frac{a}{b}",
          value: "\\frac{}{}",
        },
        {
          label: "|",
          value: "|",
        },
        {
          label: "\\nmid",
          value: "\\nmid",
        },
        {
          label: "\\neg",
          value: "\\neg",
        },
        {
          label: "\\approx",
          value: "\\approx",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\lt",
          value: "\\lt",
        },
        {
          label: "\\gt",
          value: "\\gt",
        },
        {
          label: "\\le",
          value: "\\le",
        },
        {
          label: "\\ge",
          value: "\\ge",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\leqslant",
          value: "\\leqslant",
        },
        {
          label: "\\geqslant",
          value: "\\geqslant",
        },
        {
          label: "\\ll",
          value: "\\ll",
        },
        {
          label: "\\gg",
          value: "\\gg",
        },
        {
          label: "\\prec",
          value: "\\prec",
        },
        {
          label: "\\succ",
          value: "\\succ",
        },
        {
          label: "\\lesssim",
          value: "\\lesssim",
        },
        {
          label: "\\gtrsim",
          value: "\\gtrsim",
        },
        {
          label: "\\nless",
          value: "\\nless",
        },
        {
          label: "\\ngtr",
          value: "\\ngtr",
        },
        {
          label: "\\nleq",
          value: "\\nleq",
        },
        {
          label: "\\ngeq",
          value: "\\ngeq",
        },
        {
          label: "\\nleqslant",
          value: "\\nleqslant",
        },
        {
          label: "\\ngeqslant",
          value: "\\ngeqslant",
        },
        {
          label: "\\nprec",
          value: "\\nprec",
        },
        {
          label: "\\nsucc",
          value: "\\nsucc",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\equiv",
          value: "\\equiv",
        },
        {
          label: "\\sim",
          value: "\\sim",
        },
        {
          label: "\\asymp",
          value: "\\asymp",
        },
        {
          label: "\\simeq",
          value: "\\simeq",
        },
        {
          label: "\\cong",
          value: "\\cong",
        },
        {
          label: "\\nabla",
          value: "\\nabla",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\div",
          value: "\\div",
        },
        {
          label: "\\vdash",
          value: "\\vdash",
        },
        {
          label: "\\models",
          value: "\\models",
        },
        {
          label: "\\propto",
          value: "\\propto",
        },
        {
          label: "\\Re",
          value: "\\Re",
        },
        {
          label: "\\Im",
          value: "\\Im",
        },
        {
          label: "\\star",
          value: "\\star",
        },
        {
          label: "\\ast",
          value: "\\ast",
        },
        {
          label: "\\circ",
          value: "\\circ",
        },
        {
          label: "\\bullet",
          value: "\\bullet",
        },
        {
          label: "\\imath",
          value: "\\imath",
        },
        {
          label: "\\jmath",
          value: "\\jmath",
        },
        {
          label: "\\oplus",
          value: "\\oplus",
        },
        {
          label: "\\ominus",
          value: "\\ominus",
        },
        {
          label: "\\otimes",
          value: "\\otimes",
        },
        {
          label: "\\oslash",
          value: "\\oslash",
        },
        {
          label: "\\cdot",
          value: "\\cdot",
        },
        {
          label: "\\hbar",
          value: "\\hbar",
        },
        {
          label: "\\cdots",
          value: "\\cdots",
        },
        {
          label: "\\vdots",
          value: "\\vdots",
        },
        {
          label: "\\ddots",
          value: "\\ddots",
        },
        {
          label: "\\angle",
          value: "\\angle",
        },
        {
          label: "\\bot",
          value: "\\bot",
        },
        {
          label: "\\bowtie",
          value: "\\bowtie",
        },
        {
          label: "\\parallel",
          value: "\\parallel",
        },
        {
          label: "\\not\\equiv",
          value: "\\not\\equiv",
        },
        {
          label: "\\nsim",
          value: "\\nsim",
        },
        {
          label: "\\ncong",
          value: "\\ncong",
        },
        {
          label: "\\nexists",
          value: "\\nexists",
        },
        {
          label: "\\measuredangle",
          value: "\\measuredangle",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\alpha",
          value: "\\alpha",
        },
        {
          label: "\\beta",
          value: "\\beta",
        },
        {
          label: "\\gamma",
          value: "\\gamma",
        },
        {
          label: "\\delta",
          value: "\\delta",
        },
        {
          label: "\\epsilon",
          value: "\\epsilon",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\varepsilon",
          value: "\\varepsilon",
        },
        {
          label: "\\zeta",
          value: "\\zeta",
        },
        {
          label: "\\eta",
          value: "\\eta",
        },
        {
          label: "\\theta",
          value: "\\theta",
        },
        {
          label: "\\vartheta",
          value: "\\vartheta",
        },
        {
          label: "\\iota",
          value: "\\iota",
        },
        {
          label: "\\kappa",
          value: "\\kappa",
        },
        {
          label: "\\lambda",
          value: "\\lambda",
        },
        {
          label: "\\mu",
          value: "\\mu",
        },
        {
          label: "\\nu",
          value: "\\nu",
        },
        {
          label: "\\xi",
          value: "\\xi",
        },
        {
          label: "o",
          value: "o",
        },
        {
          label: "\\pi",
          value: "\\pi",
        },
        {
          label: "\\varpi",
          value: "\\varpi",
        },
        {
          label: "\\rho",
          value: "\\rho",
        },
        {
          label: "\\varrho",
          value: "\\varrho",
        },
        {
          label: "\\sigma",
          value: "\\sigma",
        },
        {
          label: "\\varsigma",
          value: "\\varsigma",
        },
        {
          label: "\\tau",
          value: "\\tau",
        },
        {
          label: "\\upsilon",
          value: "\\upsilon",
        },
        {
          label: "\\phi",
          value: "\\phi",
        },
        {
          label: "\\varphi",
          value: "\\varphi",
        },
        {
          label: "\\chi",
          value: "\\chi",
        },
        {
          label: "\\psi",
          value: "\\psi",
        },
        {
          label: "\\omega",
          value: "\\omega",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\acute{e}",
          value: "\\acute{}",
        },
        {
          label: "\\grave{e}",
          value: "\\grave{}",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\hat{e}",
          value: "\\hat{}",
        },
        {
          label: "\\tilde{e}",
          value: "\\tilde{}",
        },
        {
          label: "\\ddot{e}",
          value: "\\ddot{}",
        },
        {
          label: "\\bar{e}",
          value: "\\bar{}",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\Gamma",
          value: "\\Gamma",
        },
        {
          label: "\\Delta",
          value: "\\Delta",
        },
        {
          label: "\\Theta",
          value: "\\Theta",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\Lambda",
          value: "\\Lambda",
        },
        {
          label: "\\Xi",
          value: "\\Xi",
        },
        {
          label: "\\Pi",
          value: "\\Pi",
        },
        {
          label: "\\Sigma",
          value: "\\Sigma",
        },
        {
          label: "\\Upsilon",
          value: "\\Upsilon",
        },
        {
          label: "\\Phi",
          value: "\\Phi",
        },
        {
          label: "\\Psi",
          value: "\\Psi",
        },
        {
          label: "\\Omega",
          value: "\\Omega",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\bigcup_{}^{}",
          value: "\\bigcup_{}^{}",
        },
        {
          label: "\\bigcap_{}^{}",
          value: "\\bigcap_{}^{}",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\bigvee_{}^{}",
          value: "\\bigvee_{}^{}",
        },
        {
          label: "\\bigwedge_{}^{}",
          value: "\\bigwedge_{}^{}",
        },
        {
          label: "\\bigoplus_{}^{}",
          value: "\\bigoplus_{}^{",
        },
        {
          label: "\\bigotimes_{}^{}",
          value: "\\bigotimes_{}^{}",
        },
        {
          label: "\\coprod_{}^{}",
          value: "\\coprod_{}^{}",
        },
        {
          label: "\\bigsqcup_{}^{}",
          value: "\\bigsqcup_{}^{}",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\subset",
          value: "\\subset",
        },
        {
          label: "\\cup",
          value: "\\cup",
        },
        {
          label: "\\cap",
          value: "\\cap",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\subseteq",
          value: "\\subseteq",
        },
        {
          label: "\\subsetneq",
          value: "\\subsetneq",
        },
        {
          label: "\\nsubseteq",
          value: "\\nsubseteq",
        },
        {
          label: "\\bigtriangleup",
          value: "\\bigtriangleup",
        },
        {
          label: "\\bigtriangledown",
          value: "\\bigtriangledown",
        },
        {
          label: "\\bigcirc",
          value: "\\bigcirc",
        },
        {
          label: "\\triangleleft",
          value: "\\triangleleft",
        },
        {
          label: "\\triangleright",
          value: "\\triangleright",
        },
        {
          label: "\\square",
          value: "\\square",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\gets",
          value: "\\gets",
        },
        {
          label: "\\to",
          value: "\\to",
        },
        {
          label: "\\longleftarrow",
          value: "\\longleftarrow",
        },
        {
          label: "\\longrightarrow",
          value: "\\longrightarrow",
        },
        {
          label: "\\leftrightarrow",
          value: "\\leftrightarrow",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\leftharpoonup",
          value: "\\leftharpoonup",
        },
        {
          label: "\\rightharpoonup",
          value: "\\rightharpoonup",
        },
        {
          label: "\\mapsto",
          value: "\\mapsto",
        },
        {
          label: "\\longmapsto",
          value: "\\longmapsto",
        },
        {
          label: "\\longleftrightarrow",
          value: "\\longleftrightarrow",
        },
        {
          label: "\\leftharpoondown",
          value: "\\leftharpoondown",
        },
        {
          label: "\\rightharpoondown",
          value: "\\rightharpoondown",
        },
        {
          label: "\\leftrightharpoons",
          value: "\\leftrightharpoons",
        },
        {
          label: "\\rightleftharpoons",
          value: "\\rightleftharpoons",
        },
        {
          label: "\\updownarrow",
          value: "\\updownarrow",
        },
        {
          label: "\\uparrow",
          value: "\\uparrow",
        },
        {
          label: "\\downarrow",
          value: "\\downarrow",
        },
        {
          label: "\\swarrow",
          value: "\\swarrow",
        },
        {
          label: "\\searrow",
          value: "\\searrow",
        },
        {
          label: "\\upuparrows",
          value: "\\upuparrows",
        },
        {
          label: "\\curvearrowleft",
          value: "\\curvearrowleft",
        },
        {
          label: "\\curvearrowright",
          value: "\\curvearrowright",
        },
        {
          label: "\\nwarrow",
          value: "\\nwarrow",
        },
        {
          label: "\\nearrow",
          value: "\\nearrow",
        },
        {
          label: "\\rightrightarrows",
          value: "\\rightrightarrows",
        },
        {
          label: "\\circlearrowright",
          value: "\\circlearrowright",
        },
        {
          label: "\\rightarrowtail",
          value: "\\rightarrowtail",
        },
        {
          label: "\\rightsquigarrow",
          value: "\\rightsquigarrow",
        },
        {
          label: "\\looparrowright",
          value: "\\looparrowright",
        },
        {
          label: "\\nrightarrow",
          value: "\\nrightarrow",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\Leftarrow",
          value: "\\Leftarrow",
        },
        {
          label: "\\Rightarrow",
          value: "\\Rightarrow",
        },
        {
          label: "\\Leftrightarrow",
          value: "\\Leftrightarrow",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\Longleftarrow",
          value: "\\Longleftarrow",
        },
        {
          label: "\\Longrightarrow",
          value: "\\Longrightarrow",
        },
        {
          label: "\\Longleftrightarrow",
          value: "\\Longleftrightarrow",
        },
        {
          label: "\\Uparrow",
          value: "\\Uparrow",
        },
        {
          label: "\\Downarrow",
          value: "\\Downarrow",
        },
        {
          label: "\\Updownarrow",
          value: "\\Updownarrow",
        },
        {
          label: "\\nLeftarrow",
          value: "\\nLeftarrow",
        },
        {
          label: "\\nRightarrow",
          value: "\\nRightarrow",
        },
        {
          label: "\\nLeftrightarrow",
          value: "\\nLeftrightarrow",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\overrightarrow{AB}",
          value: "\\overrightarrow{}",
        },
        {
          label: "\\underrightarrow{AB}",
          value: "\\underrightarrow{}",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\widehat{abc}",
          value: "\\widehat{}",
        },
        {
          label: "\\widetilde{abc}",
          value: "\\widetilde{}",
        },
        {
          label: "\\overline{abc}",
          value: "\\overline{}",
        },
        {
          label: "\\underline{abc}",
          value: "\\underline",
        },
        {
          label: "\\overbrace{abc}",
          value: "\\overbrace{}",
        },
        {
          label: "\\underbrace{abc}",
          value: "\\underbrace{}",
        },
        {
          label: "\\overset{abc}{AB}",
          value: "\\overset{}{}",
        },
        {
          label: "\\underset{abc}{AB}",
          value: "\\underset{}{}",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\mathbb{QWERTY}",
          value: "\\mathbb{}",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\mathrm{QWERTY}",
          value: "\\mathrm{}",
        },
        {
          label: "\\mathcal{QWERTY}",
          value: "\\mathcal{}",
        },
        {
          label: "\\mathfrak{QWERTY}",
          value: "\\mathfrak{}",
        },
        {
          label: "\\mathscr{QWERTY}",
          value: "\\mathscr{}",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\left(  \\right)",
          value: "\\left(  \\right)",
        },
        {
          label: "\\left\\lfloor  \\right\\rfloor",
          value: "\\left\\lfloor  \\right\\rfloor",
        },
      ],
      SUB_SYMBOLS: [
        {
          label: "\\left\\{  \\right\\}",
          value: "\\left\\{  \\right\\}",
        },
        {
          label: "\\left\\lceil  \\right\\rceil",
          value: "\\left\\lceil  \\right\\rceil",
        },
        {
          label: "\\left|  \\right|",
          value: "\\left|  \\right|",
        },
        {
          label: "\\left[  \\right]",
          value: "\\left[  \\right]",
        },
        {
          label: "\\left\\|  \\right\\|",
          value: "\\left\\|  \\right\\|",
        },
        {
          label: "\\left\\langle  \\right\\rangle",
          value: "\\left\\langle  \\right\\rangle",
        },
      ],
    },
    {
      MAIN_SYMBOLS: [
        {
          label: "\\mathrm{C}_{n}^{k}",
          value: "\\mathrm{C}_{}^{}",
        },
        {
          label: "\\binom{n}{k}",
          value: "\\binom{}{}",
        },
      ],
    },
  ];
  export { SYMBOLS };