import React from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { SetStateAction } from "../../../../../types/globals";

type ButtonOrderProps = {
    isOpen: boolean;
    setIsOpen: SetStateAction<boolean>;
    color?: string;
}

const ButtonOpen = ({isOpen, setIsOpen, color}: ButtonOrderProps) => {
  return isOpen ? (
    <IoIosArrowForward
      size={24}
      color={color|| "#7A7A7A"}
      className="-rotate-90 cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    />
  ) : (
    <IoIosArrowDown
      size={24}
      color={color|| "#7A7A7A"}
      className="cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    />
  );
};

export default ButtonOpen;
