import { SliderProblem } from '../../../../../../../types/admin/question';
import { RenderDroppedQuestionTypeProps } from '../../DroppedQuestionType';
import { decodeHTMLEntities } from '../../../../../../../services/helpers/parseData';
import SliderQuestionInputRange from './SliderQuestionInputRange';

type SliderQuestionProps = {
  problemPreview?: SliderProblem;
} & Omit<RenderDroppedQuestionTypeProps, 'draggedName' | 'problemPreview'>;

const SliderQuestion = ({ problemPreview }: SliderQuestionProps) => {
  return (
    <div className={`w-[100%] flex-col items-center gap-y-[20px] mx-auto`}>
      <div className={`mb-[20px] font-['Noto Sans JP'] `}>
        <div
          dangerouslySetInnerHTML={{
            __html: decodeHTMLEntities(problemPreview?.title || ''),
          }}
        ></div>
      </div>
      <div className="flex items-center justify-center my-[10px]">
        <SliderQuestionInputRange className={'basis-[70%]'} editingData={problemPreview} answerValue={0} isDisabled/>
      </div>
    </div>
  );
};
export default SliderQuestion;
