import * as React from 'react';

const Slider = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      style={{
        fill: 'currentcolor',
      }}
      fillRule="evenodd"
      d="M6.069 7.177A1.25 1.25 0 017.306 5.75h3.388c.76 0 1.345.674 1.237 1.427l-.672 4.705a.848.848 0 00-.003.023l-.753 5.272a1.25 1.25 0 01-1.238 1.073h-.53a1.25 1.25 0 01-1.238-1.073l-.753-5.272a.69.69 0 00-.003-.024l-.672-4.704zm-.934 4.073l-.551-3.861A2.75 2.75 0 017.306 4.25h3.388a2.75 2.75 0 012.722 3.139l-.551 3.861H22a.75.75 0 010 1.5h-9.35l-.662 4.639a2.75 2.75 0 01-2.723 2.361h-.53a2.75 2.75 0 01-2.723-2.361L5.35 12.75H2a.75.75 0 010-1.5h3.135z"
      clipRule="evenodd"
    ></path>
  </svg>
);
export default Slider;
