import { getTabObj } from '../../../helpers/parseData';
import { PATH_ADMIN_CANDIDATE } from '../../route/router';

const CANDIDATE_TAB_ITEM = {
  CANDIDATE_LIST: '受験者一覧',
  CANDIDATE_CREATE: '受験者新規追加',
  // CANDIDATE_CSV: '受験者一括追加',
  SETTINGS_CONFIG: '受験者設定',
};

const CANDIDATE_TAB_DATA = [
  getTabObj(CANDIDATE_TAB_ITEM.CANDIDATE_LIST, PATH_ADMIN_CANDIDATE.DEFAULT),
  getTabObj(CANDIDATE_TAB_ITEM.CANDIDATE_CREATE, PATH_ADMIN_CANDIDATE.CREATE),
  // Tab hidden for future phases
  // getTabObj(CANDIDATE_TAB_ITEM.CANDIDATE_CSV, PATH_ADMIN_CANDIDATE.CSV),
  // getTabObj(CANDIDATE_TAB_ITEM.SETTINGS_CONFIG, PATH_ADMIN_CANDIDATE.SETTING),
];

const CANDIDATE_STATUS_DATA = {
  CONFIRMING: 0,
  ACTIVE: 1,
  DEACTIVE: 2,
};

const CANDIDATE_GENDER = {
  MALE: 1,
  FEMALE: 0,
  OTHER: 2
}

export { CANDIDATE_TAB_ITEM, CANDIDATE_TAB_DATA, CANDIDATE_STATUS_DATA, CANDIDATE_GENDER };
