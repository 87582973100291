import { Navigate, useNavigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { isEmptyObj } from "../services/helpers/etc";
import { StatusCodes } from "http-status-codes";
import { swalError } from "../services/helpers/swal";

type RequiredAuthProps = {
  children: JSX.Element | JSX.Element[]
}

const RequiredAuth = ({ children }: RequiredAuthProps) => {
  let [tokenValid, setTokenValid] = useState(true);
  let { setUser } = useAuthContext();
  let token = localStorage.getItem('accessToken');
  let storedUser = localStorage.getItem('user');
  const storagedUser = storedUser ? JSON.parse(storedUser) : {};
  let navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      let result = await makeRequest({
        method: "get",
        url: API.USER.INFO
      })

      if (result.code === StatusCodes.UNAUTHORIZED || result.code === StatusCodes.FORBIDDEN) {
        localStorage.clear();
        swalError(result.message);
        setTimeout(() => {
          navigate(ROUTE.LOGIN);
        }, 1500);
        return;
      }

      setUser(!isEmptyObj(storagedUser) && !storagedUser.is_candidate ? storagedUser : result.data);
      // setUser(!isEmptyObj(storagedUser) ? storagedUser : result.data);

    }

    if (token) {
      verifyToken();
    } else {
      navigate(ROUTE.LOGIN);
    }
  }, []);


  return (
    <>
      {
        tokenValid ? children : <Navigate to={ROUTE.LOGIN} replace={true} />
      }
    </>
  )
}

export default RequiredAuth;
