import React, { useState } from "react";
import BaseModal from "../../admin/commons/BaseModal";
import { MdOutlineZoomInMap, MdOutlineZoomOutMap } from "react-icons/md";

type IframeContent = {
  isZoomed?: boolean;
};

const PDFViewer = ({
  className = "",
  src = "",
  onLoad,
  downloadable = true,
}: {
  className?: string;
  src?: string;
  onLoad?: () => void;
  downloadable?: number | boolean | null;
}) => {
  const handleContextMenu = (
    e: React.MouseEvent<HTMLIFrameElement, MouseEvent>
  ) => {
    e.preventDefault();
  };

  const [isZoom, setIsZoom] = useState<boolean>(false);

  const IframeContent = ({ isZoomed = false }: IframeContent) => {
    let classNameParsed = className ? className : "";
    if (isZoomed) classNameParsed = `${className} !w-full !h-full`;

    return (
      <>
        {/* iframe */}
        <iframe
          className={classNameParsed}
          src={
            downloadable ? `${src}#navpanes=0` : `${src}#toolbar=0&navpanes=0`
          }
          onContextMenu={handleContextMenu}
          onLoad={onLoad}
          title={`PDFViewer_${isZoom}`}
        ></iframe>
        {/* zoom button */}
        <div
          className={`absolute right-[35px] w-[40px] h-[40px] bg-secondary-morelighter rounded-full shadow-xl flex justify-center items-center ${
            downloadable ? "top-[75px]" : "top-[20px]"
          }`}
          onClick={() => setIsZoom(!isZoom)}
        >
          {isZoom ? (
            <MdOutlineZoomInMap
              size={28}
              className="text-secondary-medium cursor-pointer"
            />
          ) : (
            <MdOutlineZoomOutMap
              size={28}
              className="text-secondary-medium cursor-pointer"
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {/* By default */}
      <div className="relative">
        <IframeContent />
      </div>

      {/* By clicked on iframe */}
      {isZoom && (
        <BaseModal setIsOpen={setIsZoom} height={800} withSubmit={false}>
          <IframeContent isZoomed />
        </BaseModal>
      )}
    </>
  );
};

export default PDFViewer;
