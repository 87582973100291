import { decodeHTMLEntities } from "../../../services/helpers/parseData";

const TextEditorDisplayWithTable = ({
  htmlData,
  className,
}: {
  htmlData: string;
  className?: string;
}) => {
  return (
    <div className={`overflow-y-auto overflow-x-auto border-solid border-2 border-secondary-lighter py-[10px] px-[15px] rounded ${className}`}>
      <div
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(htmlData),
        }}
      ></div>
    </div>
  );
};

export default TextEditorDisplayWithTable;
