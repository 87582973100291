import React from 'react';
import { ErrorData } from '../../../types/globals';

const ErrorBox = ({ errors }: { errors: ErrorData }) => {
  return (
    <div className="w-full bg-danger-lighter border border-danger rounded-[7px] py-[10px] px-[15px] my-[7px] text-danger">
      <ul>
        {Object.keys(errors).map((errorKey, index) => (
          <li key={index} className="mb-[3px]">
            {errors[errorKey][0]}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorBox;
