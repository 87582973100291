import { LiaSave } from 'react-icons/lia';
import { useQuestionListContext } from '../../../../context/Question/ListQuestionContext';
import DetailTargetFinderItem from './DetailTargetFinderItem';
import { API } from '../../../../services/constants/route/api';
import { request } from '../../../../services/axios/axios';
import { Question } from '../../../../types/admin/question';
import { NAME_ACTION_ASIDE_QUESTION } from '../../../../services/constants/admin/pages/question';
import { swalError } from '../../../../services/helpers/swal';

type DetailTargetFinderProps = {
  sideAction: string;
};

const DetailTargetFinder = ({ sideAction }: DetailTargetFinderProps) => {
  const {
    questions,
    selectedQuestion,
    selectedTargetQuestion,
    setSideAction,
    setQuestions,
    setSelectedQuestion,
  } = useQuestionListContext();
  const primaryQuestions = questions.filter(
    (question) => !question.parent_id && question.id !== selectedQuestion.id,
  );

  const submitData = {
    id: selectedQuestion.id,
    target_id: selectedTargetQuestion.id,
  };

  let apiHandler = '';
  let finderTitle = '';
  let submitFinderTitle = '';
  let requestHandler = request.post;

  switch (sideAction) {
    case NAME_ACTION_ASIDE_QUESTION.COPY:
      apiHandler = API.ADMIN_QUESTION.COPY;
      finderTitle = '他のフォルダーへコピー';
      submitFinderTitle = 'コピーする';
      break;
    case NAME_ACTION_ASIDE_QUESTION.MOVE:
      apiHandler = API.ADMIN_QUESTION.MOVE;
      finderTitle = '他のフォルダーへ移動';
      submitFinderTitle = '移動する';
      requestHandler = request.patch;
      break;

    default:
      swalError();
      break;
  }

  const handleSubmitFinder = async () => {
    await requestHandler(apiHandler, submitData, (res: Question[]) => {
      setQuestions([...res, ...questions]);
      setSelectedQuestion();
      setSideAction('');
    });
  };

  return (
    <div className="">
      <div className="text-[22px] leading-[28px] text-secondary-dark mb-[20px]">{finderTitle}</div>
      <div className="w-full max-w-[311px] bg-success-lighter h-0 min-h-[500px] pt-[20px] px-[20px]">
        <div className="text-[22px] leading-[28px] text-secondary-dark mb-[20px]">
          フォルダーを選択
        </div>
        <div className="w-[271px] h-[350px] overflow-auto bg-white">
          {primaryQuestions.map((question, index) => (
            <DetailTargetFinderItem question={question} key={index} />
          ))}
        </div>
        <div className="w-full flex justify-center gap-x-[5px] mt-[20px]">
          <button
            className="text-white cursor-pointer flex items-center justify-center w-full max-w-[150px] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
            onClick={handleSubmitFinder}
          >
            <LiaSave size={23} /> {submitFinderTitle}
          </button>
          <button 
            className='text-secondary-dark cursor-pointer flex items-center justify-center w-full max-w-[150px] h-0 min-h-[36px] gap-[5px] rounded-[10px] border border-secondary-light'
            onClick={() => setSideAction("")}>キャンセル</button>
        </div>
      </div>
    </div>
  );
};

export default DetailTargetFinder;
