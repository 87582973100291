import React from "react";
import { SETTING_PROPERTY_NAMES } from "../../../../../../services/constants/admin/pages/exam";
import InformationDetail from "./InformationDetail";

type InputTimePropertiesProps = {
  type: "min" | "max";
  label: string;
  times: {
    hours?: number;
    minutes?: number;
    seconds?: number;
  };
  messageDetail: string;
  handleChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputTimeProperties = ({
  type,
  label,
  times,
  messageDetail,
  handleChangeValue,
}: InputTimePropertiesProps) => {
  let hourName: string = "";
  let minuteName: string = "";
  let secondName: string = "";

  switch (type) {
    case "max":
      hourName = SETTING_PROPERTY_NAMES.MAX_HOUR;
      minuteName = SETTING_PROPERTY_NAMES.MAX_MINUTE;
      secondName = SETTING_PROPERTY_NAMES.MAX_SECOND;
      break;
    case "min":
      hourName = SETTING_PROPERTY_NAMES.MIN_HOUR;
      minuteName = SETTING_PROPERTY_NAMES.MIN_MINUTE;
      secondName = SETTING_PROPERTY_NAMES.MIN_SECOND;
      break;
    default:
      break;
  }
  return (
    <div className="flex pb-[15px] justify-between items-center gap-x-[6px]">
      <div className="flex w-full gap-x-[6px] items-center">
        <div className="text-[14px] min-[1400px]:basis-[51%] max-[1400px]:mr-[10px]">{label}</div>
        <div className="justify-start items-center min-[1400px]:flex gap-x-[6px]">
          <input
            name={hourName}
            value={times?.hours || 0}
            type="number"
            className="rounded-none w-[40px] !pl-[5px] text-[14px]"
            onChange={handleChangeValue}
          />
          :
          <input
            name={minuteName}
            value={times?.minutes || 0}
            type="number"
            className="rounded-none w-[40px] !pl-[5px] text-[14px]"
            onChange={handleChangeValue}
          />
          :
          <input
            name={secondName}
            value={times?.seconds || 0}
            type="number"
            className="rounded-none w-[40px] !pl-[5px] text-[14px]"
            onChange={handleChangeValue}
          />
        </div>
      </div>
      <InformationDetail text={messageDetail} />
    </div>
  );
};

export default InputTimeProperties;
