import React, { useState, useContext } from "react";
import { ChildrenWithProps } from "../types/globals";
import { ExamVenue } from "../types/admin/examManagement";

interface ExamManagementContextType {
   exam_venues: ExamVenue[];
   setExamVenues: React.Dispatch<React.SetStateAction<ExamVenue[]>>;
   totalPages: number;
   setTotalPages: React.Dispatch<React.SetStateAction<number>>;
}

const ExamManagementContext = React.createContext<ExamManagementContextType>({
   exam_venues: [],
   setExamVenues: () => { },
   totalPages: 1,
   setTotalPages: () => { },
});

const ExamManagementProvider = ({ children }: ChildrenWithProps) => {
   const [exam_venues, setExamVenues] = useState<ExamVenue[]>([]);
   const [totalPages, setTotalPages] = useState<number>(1);
   return (
      <ExamManagementContext.Provider
         value={{ exam_venues, setExamVenues, totalPages, setTotalPages }}
      >
         {children}
      </ExamManagementContext.Provider>
   );
};

export default ExamManagementProvider;

export const useExamManagementContext = () => useContext(ExamManagementContext);
