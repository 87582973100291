import React from "react";
import { HiPlus } from "react-icons/hi";
import RoundedButton from "../../commons/RoundButton";
import { Link } from "react-router-dom";
import { PATH_ADMIN_EXAMINATION } from "../../../../services/constants/route/router";

const ExamVenueTableCommonAction = ({
  ...props
}: React.HTMLProps<HTMLElement>) => {
  return (
    <div className="w-full flex justify-end">
      <section {...props}>
        <div className="flex justify-end gap-[10px]">
          <Link to={PATH_ADMIN_EXAMINATION.CREATE}>
            <RoundedButton className="bg-primary-light">
              <div className="flex items-center justify-center">
                <HiPlus size={20} />
                <div className="ml-[12px]">新規試験登録</div>
              </div>
            </RoundedButton>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default ExamVenueTableCommonAction;
