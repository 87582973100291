import React, { useEffect, useState } from "react";
import {
  CorrectFillInBlankIssue,
  FillInBlankIssue,
  FillInBlankProblemTest,
  QuestionProblemTest,
} from "../../../../../types/admin/question";
import { isEmptyArr, isUsableArr } from "../../../../../services/helpers/etc";
import { decodeHTMLEntities } from "../../../../../services/helpers/parseData";
import AuthoringTextEditor from "../../../../admin/Question/AuthoringQuestion/QuestionEditorHandler/AuthoringTextEditor";
import {
  extractHTMLTextToText,
  extractIdByIdentifier,
  getIssueById,
  getIssueIndexById,
} from "../../../../../services/utils/admin/question";
import { renderToString } from "react-dom/server";
import {
  FILL_IN_BLANK_BUTTON_TYPE,
  FILL_IN_BLANK_CONTENT_RENDER_TYPE,
} from "../../../../../services/constants/admin/pages/question";
import {
  BlankFillRenderTest,
  FilledFillRenderTest,
  PreviewBlankFillRenderTest,
} from "../QuestionTypeComponent/FillInBlankContentRender";
import { candidateToolIsOpen } from "../../../../../services/utils/candidate/examinationTest";
import { SETTING_PROPERTY_NAMES } from "../../../../../services/constants/admin/pages/exam";
import { useExaminationTestContext } from "../../../../../context/ExaminationTestContext";

const FillInBlankQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled,
}: {
  questionProblem: QuestionProblemTest;
  handleUpdateProblem: (problem: QuestionProblemTest) => void;
  isDisabled?: boolean;
}) => {
  const { openingTools } = useExaminationTestContext();
  const [editingData, setEditingData] = useState<FillInBlankProblemTest>(
    questionProblem as FillInBlankProblemTest
  );
  const [originProblemContent, setOriginProblemContent] = useState<string>(
    editingData?.content || ""
  );
  const [selectingIssue, _setSelectingIssue] = useState<FillInBlankIssue>({});
  const currentIssues = editingData.issues || [];
  const currentFills = editingData.correct_fills || [];
  const answerFills = editingData.answer_data || [];
  const textEditorId = `fill_in_blank_content_editor_${editingData.id}`;
  const formId = `fill_in_blank_content_form_${editingData.id}`;

  const setSelectingIssue = (issueId?: number) => {
    if (!issueId) return _setSelectingIssue({});
    _setSelectingIssue(getIssueById(currentIssues, issueId) || {});
  };

  const issueAppearedCount = (issueId?: number) => {
    const appearedFilleds = answerFills.filter(
      (fill) => fill.issueId === issueId
    );
    return appearedFilleds.length;
  };

  const isIssueAppearLimitExceed = (issueId?: number) => {
    if (!issueId) return false;
    const appearedCount = issueAppearedCount(issueId);
    const currentIssue = getIssueById(
      currentIssues,
      issueId
    ) as FillInBlankIssue;

    if (
      !!appearedCount &&
      appearedCount >= Number(currentIssue?.appear_limit) &&
      !!Number(currentIssue?.appear_limit)
    ) {
      return true;
    }

    return false;
  };

  // CLick block
  const selectingIssueHandle = (issueId?: number, index?: number) => {
    if (!issueId || index === undefined || isDisabled) return;
    setSelectingIssue(selectingIssue.id === issueId ? 0 : issueId);
  };

  const contentProblemBlurHandle = () => {};

  const submitHandle = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentSubmitter = (e.nativeEvent as SubmitEvent)?.submitter;
    if (!currentSubmitter) return;

    const submitTargetIdentifier = currentSubmitter.getAttribute(
      "data-target-identifier"
    );
    const submitTargetContent = currentSubmitter.getAttribute(
      "data-target-content"
    );
    const submitButtonType = currentSubmitter.getAttribute("data-button-type");
    const submitRenderType = currentSubmitter.getAttribute("data-render-type");
    let newContent: string | undefined;

    if (
      submitButtonType === FILL_IN_BLANK_BUTTON_TYPE.SETTING &&
      !selectingIssue.id
    ) {
      return;
    }

    if (
      submitRenderType === FILL_IN_BLANK_CONTENT_RENDER_TYPE.BLANKED &&
      !selectingIssue.id
    ) {
      return;
    }

    // In case: Filled to Blank section
    if (submitTargetIdentifier) {
      let newCorrectFills = JSON.parse(
        JSON.stringify(answerFills)
      ) as typeof answerFills;
      const currentFilledId = extractIdByIdentifier(submitTargetIdentifier);
      const currentFilledIndex = getIssueIndexById(
        currentFills,
        currentFilledId
      );
      const currentFilled = getIssueById(
        currentFills,
        currentFilledId
      ) as CorrectFillInBlankIssue;
      let newFilled: CorrectFillInBlankIssue = {
        point: editingData.default_score,
      };

      let replaceTargetContent = renderToString(
        <FilledFillRenderTest
          identifier={submitTargetIdentifier || ""}
          content={extractHTMLTextToText(selectingIssue.label || "") || ""}
          defaultPoint={editingData.default_score || 0}
        />
      );

      const submitTargetWrapper = document.querySelector(
        `#${submitTargetIdentifier}`
      );

      if (
        submitButtonType === FILL_IN_BLANK_BUTTON_TYPE.CLOSE &&
        !selectingIssue.id
      ) {
        replaceTargetContent = renderToString(
          <BlankFillRenderTest
            identifier={submitTargetIdentifier || ""}
            content={submitTargetContent || ""}
            defaultPoint={editingData.default_score || 0}
          />
        );

        newFilled = {
          ...currentFilled,
          issueId: undefined,
        };
      } else {
        newFilled = {
          ...currentFilled,
          issueId: Number(selectingIssue.id),
        };
      }

      newCorrectFills.splice(currentFilledIndex, 1, newFilled);

      const currentTextEditorContent = document.querySelector(
        `#${textEditorId}`
      );
      if (!currentTextEditorContent) return;

      newContent = currentTextEditorContent?.innerHTML?.replace(
        String(submitTargetWrapper?.outerHTML),
        replaceTargetContent
      );

      currentTextEditorContent.innerHTML = newContent;
      const newEditingData = {
        ...editingData,
        answer_data: newCorrectFills,
      };

      setOriginProblemContent(newContent);
      setEditingData(newEditingData);
      handleUpdateProblem(newEditingData);
      setSelectingIssue();
      return;
    }
  };

  const handleMouseUp = () => {
    if (
      !candidateToolIsOpen(SETTING_PROPERTY_NAMES.SHOW_HIGH_LIGHT, openingTools)
    )
      return;

    const selector = window.getSelection();
    const textSelected = selector?.toString();
    const rangeEl = selector?.getRangeAt(0);

    if (!rangeEl || !textSelected) return;

    // Check selection span tag
    if (rangeEl.startContainer !== rangeEl.endContainer) return;

    const span = document.createElement("span");
    span.setAttribute("style", "background-color: yellow; display:inline;");
    span.innerText = textSelected || "";
    rangeEl.deleteContents();
    rangeEl.insertNode(span);
  };

  useEffect(() => {
    if (questionProblem) {
      setEditingData(questionProblem as FillInBlankProblemTest);
    }
  }, [questionProblem]);

  useEffect(() => {
    const targets = document.querySelectorAll(".fill_in_blank_target");

    if (!selectingIssue.id) {
      targets.forEach((target) => {
        target.classList.remove("fill_in_blank_selecting_target");
      });

      return;
    }

    targets.forEach((target) => {
      target.classList.add("fill_in_blank_selecting_target");
    });
  }, [selectingIssue.id]);

  useEffect(() => {
    if (!editingData) return;

    if (isUsableArr(answerFills)) {
      answerFills.forEach((answerFill) => {
        if (!answerFill.identifier) return;
        const findIssue = currentIssues.find(
          (issue) => issue.id === answerFill.issueId
        );

        const renderComponentToHTMLString = !!findIssue ? (
          <FilledFillRenderTest
            content={extractHTMLTextToText(findIssue.label || "")}
            identifier={answerFill.identifier}
            defaultPoint={0}
          />
        ) : (
          <PreviewBlankFillRenderTest identifier={answerFill.identifier} />
        );

        const findElement = document.getElementById(answerFill.identifier);
        if (findElement) {
          findElement.innerHTML = renderToString(renderComponentToHTMLString);
        }
      });

      return;
    }

    if (isUsableArr(currentFills) && isEmptyArr(answerFills)) {
      currentFills.forEach((correctFill) => {
        if (!correctFill.identifier) return;

        const renderComponentToHTMLString = renderToString(
          <PreviewBlankFillRenderTest identifier={correctFill.identifier} />
        );

        const findElement = document.getElementById(correctFill.identifier);
        if (findElement) {
          findElement.innerHTML = renderComponentToHTMLString;
        }
      });
    }
  }, [answerFills]);

  return (
    <>
      <div
        className="w-full text-left"
        dangerouslySetInnerHTML={{
          __html: decodeHTMLEntities(editingData.title),
        }}
        onMouseUp={handleMouseUp}
      ></div>
      <form onSubmit={submitHandle} id={formId}>
        <div className="mb-[10px] min-h-[300px] w-full relative">
          <div className="mb-[30px]"></div>
          <div className="flex justify-center gap-[13px]">
            <div className="w-full">
              <div className="">
                <div className="bg-white py-[20px] text-secondary-dark rounded-b-[10px]">
                  <div className="pt-[20px] px-[10px] flex flex-wrap gap-[5px]">
                    {isUsableArr(currentIssues) &&
                      currentIssues &&
                      currentIssues.map((issue, index) => (
                        <div
                          className="mb-[15px] flex items-center px-[10px] rounded-[5px]"
                          key={index}
                        >
                          <div
                            className={`w-full rounded-[5px] cursor-pointer ${
                              selectingIssue.id === issue.id
                                ? "selecting_border_editor"
                                : ""
                            } ${
                              isIssueAppearLimitExceed(issue.id)
                                ? "pointer-events-none bg-secondary-light rounded-[5px]"
                                : ""
                            }`}
                            onClick={() =>
                              selectingIssueHandle(issue.id, index)
                            }
                          >
                            <div
                              className="w-[100px] px-[10px] py-[6px] rounded-[5px] border border-secondary-light !cursor-pointer"
                              dangerouslySetInnerHTML={{
                                __html: decodeHTMLEntities(
                                  issue.label || "<div></div>"
                                ),
                              }}
                            ></div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="my-[15px] w-full border-t-2 border-secondary-light"></div>

                  <div className="px-[4px] mb-[100px]">
                    <div
                      className="text-[12px] leading-[190%] no_border_editor"
                      onBlur={contentProblemBlurHandle}
                    >
                      <div className="">
                        <div
                          className="px-[15px] focus:outline-none focus:ring focus:border-primary focus:rounded-[5px] highlight_content leading-[20px]"
                          id={textEditorId}
                          dangerouslySetInnerHTML={{
                            __html: decodeHTMLEntities(originProblemContent),
                          }}
                          onMouseUp={handleMouseUp}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default FillInBlankQuestion;
