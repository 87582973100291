import { parsePercent } from '../../../services/helpers/parseData';

type ProgressbarProps = {
  width?: number | string;
  height?: number | string;
  progress?: number;
  progressClass?: string;
};

const ProgressBar = ({
  width = 240,
  height = 8,
  progress = 0,
  progressClass = 'bg-primary',
}: ProgressbarProps) => {
  return (
    <div className="w-full" style={{ width: typeof width === 'string' ? width : `${width}px` }}>
      <div
        className="w-full rounded-full bg-success-extralight"
        style={{ height: typeof height === 'string' ? height : `${height}px` }}
      >
        <div
          className={`rounded-full ${progressClass}`}
          style={{ width: `${parsePercent(progress)}%`, height: `${height}px` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
