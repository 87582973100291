import * as React from 'react';

const Image = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      style={{
        fill: 'currentcolor',
      }}
      d="M9.375 9.75a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
    ></path>
    <path
      style={{
        fill: 'currentcolor',
      }}
      d="M19.5 3h-15A1.5 1.5 0 003 4.5v15A1.5 1.5 0 004.5 21h15a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0019.5 3zm-2.69 7.5a1.51 1.51 0 00-2.12 0l-4.19 4.19-1.94-1.94a1.51 1.51 0 00-2.12 0L4.5 14.69V4.5h15v8.69l-2.69-2.69z"
    ></path>
  </svg>
);
export default Image;
