import { PiArrowFatDown, PiArrowFatRight } from 'react-icons/pi';
import { isUsableArr } from '../../../../../../../services/helpers/etc';
import { decodeHTMLEntities, sortArrayByKey } from '../../../../../../../services/helpers/parseData';
import { generateOrderIssues } from '../../../../../../../services/utils/admin/question';
import { OrderIssue, OrderProblem } from '../../../../../../../types/admin/question';
import AuthoringTextEditor from '../../AuthoringTextEditor';
import { RenderDroppedQuestionTypeProps } from '../../DroppedQuestionType';
import { useState, useEffect } from 'react';
import { LuXCircle } from 'react-icons/lu';

type OrderQuestionProps = {
  problemPreview?: OrderProblem;
  actionable?: boolean;
} & Omit<RenderDroppedQuestionTypeProps, 'draggedName' | 'problemPreview'>;
 
const OrderQuestion = ({ problemPreview, actionable = false }: OrderQuestionProps) => {
  const [editingData, setEditingData] = useState<OrderProblem>(problemPreview || {});

  const currentIssues = editingData.issues || [];
  const currentCheckedIssues = sortArrayByKey(
    currentIssues.filter((issue) => !!issue.checked),
    'checked_at',
  );
  const [checkedIssues, setCheckedIssues] = useState<OrderIssue[]>(currentCheckedIssues || []);

  useEffect(() => {
    if (!problemPreview?.id) return;
    setEditingData(problemPreview || {});
    setCheckedIssues([]);
  }, [problemPreview]);

  const removeCheckedIssueHandle = (name?: string, issueId?: number) => {
    if (!name || !issueId || !Number(issueId)) return;
    const newIssues = generateOrderIssues(name, false, editingData.issues || []);
    setEditingData({ ...editingData, issues: newIssues });

    //For right-side
    const newCheckedIssues = sortArrayByKey(
      newIssues.filter((issue) => !!issue.checked),
      'checked_at',
    );
    setCheckedIssues(newCheckedIssues);
  };

  const checkedIssueHandle = (name?: string, issueId?: number) => {
    if (!name || !issueId || !Number(issueId)) return;

    const newIssues = generateOrderIssues(name, true, editingData.issues || [], true);
    setEditingData({ ...editingData, issues: newIssues });

    //For right-side
    const newCheckedIssues = sortArrayByKey(
      newIssues.filter((issue) => !!issue.checked),
      'checked_at',
    );
    setCheckedIssues(newCheckedIssues);
  };

  return (
    <div className={`text-secondary-dark ${actionable ? '' : 'pointer-events-none'}`}>
      <div className="">
        <div
          dangerouslySetInnerHTML={{
            __html: decodeHTMLEntities(editingData?.title || ''),
          }}
        ></div>
      </div>
      <div
        className={`flex justify-center items-center gap-[5%] p-[20px] bg-white ${
          editingData?.is_vertical_arrange ? '' : 'flex-col !gap-[28px]'
        }`}
      >
        <div
          className={`border-[2px] border-[#E5E5E5] h-full p-[15px] ${
            editingData?.is_vertical_arrange ? 'w-[270px]' : 'w-[90%]'
          }`}
        >
          <ul className="px-0 py-[10px] list-none listbox-dot h-full flex flex-wrap gap-y-[20px]">
            {isUsableArr(currentIssues) &&
              currentIssues &&
              currentIssues.map((issue, index) => (
                <div
                  className={`flex items-center gap-[10px] px-[10px] ${
                    editingData?.is_vertical_arrange ? 'w-full' : 'w-[190px]'
                  }`}
                  key={index}
                >
                  <div
                    className={`flex items-center ${
                      issue.checked ? 'cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (!issue.checked)
                        checkedIssueHandle(`issue_data_checked_${index}`, issue.id);
                    }}
                  >
                    <input
                      type="checkbox"
                      name={`issue_data_checked_${index}_preview`}
                      id=""
                      className="pointer-events-none"
                      disabled
                    />
                  </div>
                  <li className="w-full">
                    <AuthoringTextEditor
                      toolbarId={`toolbar_issue_${index}_preview_${problemPreview?.id}`}
                      isReadOnly
                      value={issue.label || ''}
                      isDisabled={issue.checked && actionable}
                    />
                  </li>
                </div>
              ))}
          </ul>
        </div>
        <div className="text-[#EBE4D8]">
          {editingData?.is_vertical_arrange ? (
            <PiArrowFatRight size={50} />
          ) : (
            <PiArrowFatDown size={50} />
          )}
        </div>
        <div
          className={`border-[2px] border-[#E5E5E5] h-full px-[15px] py-[40px] flex flex-wrap gap-[10px] items-center ${
            editingData?.is_vertical_arrange
              ? 'w-[270px] flex-col justify-center'
              : 'w-[90%] justify-start'
          }`}
        >
          {isUsableArr(checkedIssues) &&
            checkedIssues.map((issue, index) => (
              <div
                className={`flex justify-start gap-[10px] items-center ${
                  editingData?.is_vertical_arrange ? 'w-full' : 'w-[190px]'
                }`}
                key={index}
              >
                <div className="w-full border border-dashed border-secondary-light min-h-[50px]">
                  <div className="w-full flex items-center justify-start gap-[7px] p-[5px]">
                    <div className="w-[75%] ml-[10px] mr-[5px]">
                      <AuthoringTextEditor
                        toolbarId={`toolbar_issue_${
                          currentIssues.findIndex((crIssue) => crIssue.id === issue.id) || 0
                        }_disabled_preview_${problemPreview?.id}`}
                        isReadOnly
                        value={issue.label || ''}
                      />
                    </div>
                    <div
                      className="text-[20px] text-secondary-light cursor-pointer ml-[3px]"
                      onClick={() =>
                        removeCheckedIssueHandle(
                          `issue_data_checked_${
                            currentIssues.findIndex((crIssue) => crIssue.id === issue.id) || 0
                          }`,
                          issue.id,
                        )
                      }
                    >
                      <LuXCircle />
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {Array.from({ length: currentIssues.length - checkedIssues.length }).map((_, index) => (
            <div
              className={`flex justify-start gap-[10px] items-center ${
                editingData?.is_vertical_arrange ? 'w-full' : 'w-[190px]'
              }`}
              key={index}
            >
              <div className="w-full border border-dashed border-secondary-light min-h-[50px]"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderQuestion;
