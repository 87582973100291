import { TbFolderOpen } from "react-icons/tb";
import { PiFolderBold } from "react-icons/pi";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import HalfPlus from "../../../../commons/icons/HalfPlus";
import { FiFileText } from "react-icons/fi";
import { isUsableArr } from "../../../../../services/helpers/etc";
import { Exam } from "../../../../../types/admin/exam";
import { useListTestContext } from "../../../../../context/ListTestContext";

type ListItemProps = {
  test: Exam;
  handleChangeExamTest: (examId: number) => void;
};

const TestItem = ({ test, handleChangeExamTest }: ListItemProps) => {
  const [isExpand, setIsExpand] = useState(false);
  const { tests, setSelectedTest } = useListTestContext();
  const childQuestions = tests.filter(
    (childQuestion) => Number(childQuestion.parent_id) === Number(test?.id)
  );

  const handleClick = () => {
    setIsExpand(!isExpand);
    if (!!test.is_file) {
      setSelectedTest(test.id);
      handleChangeExamTest(test.id || 0)
    }
  };

  return (
    <div className="">
      <div
        className={`hover:bg-primary-extralight px-[5px] text-secondary-light text-[22px] flex gap-[5px] cursor-pointer items-center h-[30px] bg-white rounded-[2px]`}
        onClick={handleClick}
      >
        {test.is_file ? (
          <HalfPlus className="mx-[5px] shrink-0" />
        ) : (
          <div className="text-[18px]">
            {isExpand ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </div>
        )}

        {test.is_file ? (
          <FiFileText className="ml-[0.4px] mr-[1.5px] text-[21px] text-primary shrink-0" />
        ) : (
          <div className="text-[22px]">
            {isExpand ? <TbFolderOpen /> : <PiFolderBold />}
          </div>
        )}
        <div className="text-[12px] text-secondary-dark font-[500] leading-[100%] whitespace-nowrap pr-[15px]">
          {test.name}
        </div>
      </div>
      <div className="pl-[20px]">
        {isExpand &&
          isUsableArr(childQuestions) &&
          childQuestions.map((test, index) => (
            <TestItem
              key={test.id || index}
              test={test}
              handleChangeExamTest={handleChangeExamTest}
            />
          ))}
      </div>
    </div>
  );
};

export default TestItem;
