import { ChangeEvent, useEffect, useState, useRef } from "react";
import FieldItem from "./FieldItem";
import InputItem from "./InputItem";
import {
  ExamVenue,
  ExamVenueAddressDetail,
  PostalCodeType,
  RegionType,
} from "../../../../types/admin/examManagement";
import postal_code from "japan-postal-code";
import SubmitButton from "../../commons/SubmitButton";
import japanGeography from "../../../../japanGeography.json";
import { EXAM_VENUE_STATUS } from "../../../../services/constants/admin/pages/examVenue";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import ErrorBox from "../../../commons/form/ErrorBox";
import { ErrorData } from "../../../../types/globals";
import {
  swalError,
  swalMessage,
  swalSuccess,
} from "../../../../services/helpers/swal";
import { useNavigate } from "react-router-dom";
import { removeECharacter } from "../../../../services/utils/helpers";
import { PATH_ADMIN_EXAM_VENUE } from "../../../../services/constants/route/router";
import { isEmptyObj } from "../../../../services/helpers/etc";

const CreateExamVenue = () => {
  const [errors, setErrors] = useState<ErrorData>({});
  const [examVenue, setExamVenue] = useState<ExamVenue>({
    status: EXAM_VENUE_STATUS.PUBLIC,
  });
  const [regions] = useState<RegionType[]>(japanGeography.data);
  const [prefectures, setPrefectures] = useState<string[]>([]);
  const [address, setAddress] = useState<ExamVenueAddressDetail>({});
  const [postalCode, setPostalCode] = useState<PostalCodeType>({
    first: "",
    last: "",
  });
  const [isSyncingPostal, setIsSyncingPostal] = useState(false);
  const PostalCodeLast = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;

    switch (name) {
      case "region":
        const prefecturesFilter = regions.filter(
          (region) => region.name === value
        );
        if (!!prefecturesFilter.length) {
          setPrefectures(prefecturesFilter[0].prefectures);
        }
        setExamVenue({ ...examVenue, [name]: value });
        break;
      case "status":
        setExamVenue({ ...examVenue, [name]: parseInt(value) });
        break;
      case "name":
        if (value.length > 250) return;
        setExamVenue({ ...examVenue, [name]: value });
        break;
      default:
        setExamVenue({ ...examVenue, [name]: value });
        break;
    }
  };

  const handleSearchByPostalCode = () => {
    const postalCodeFull = postalCode.first + postalCode.last;
    setIsSyncingPostal(true);
    let postalData = {};

    postal_code.get(postalCodeFull as string, (object) => {
      setAddress({
        prefecture: object.prefecture,
        city: object.city,
        area: object.area,
      });
      postalData = object;
    });

    setTimeout(() => {
      if (isEmptyObj(postalData)) {
        swalMessage("", "郵便番号は存在しません。", "error", {
          timer: 1500,
          showConfirmButton: false,
        });
        setIsSyncingPostal(false);
      }
    }, 1000);
  };

  const handleChangePostalCode = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "first" && value.length > 3) return;
    if (name === "first" && value.length === 3 && PostalCodeLast.current) {
      PostalCodeLast.current.focus();
    }
    if (name === "last" && value.length > 4) return;

    setPostalCode({ ...postalCode, [name]: value });
  };

  const handleChangeAddress = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const handleSubmit = async () => {
    const data = { ...examVenue, postal_code: postalCode, address: address };
    const result = await makeRequest({
      method: "post",
      url: API.ADMIN_EXAM_VENUE.CREATE_EXAM_VENUE,
      data: data,
    });
    if (!result.status) {
      setErrors(result.error as ErrorData);
      return swalError();
    }

    swalSuccess();
    navigate(PATH_ADMIN_EXAM_VENUE.VENUE_LIST);
  };

  return (
    <div className="text-secondary-dark text-[12px] flex flex-col gap-y-[20px]">
      <div>
        {errors && Object.keys(errors).length > 0 && (
          <ErrorBox errors={errors} />
        )}
        <div className="mb-[10px] font-medium leading-none">基本情報</div>
        <FieldItem>
          <InputItem label="会場名">
            <input
              type="text"
              name="name"
              placeholder="会場名を入力してください"
              className="placeholder:text-secondary-light h-[30px]"
              onChange={handleChange}
              value={examVenue.name}
            />
          </InputItem>
        </FieldItem>
        <FieldItem>
          <InputItem label="ステータス">
            <div className="flex items-center gap-x-[15px] text-[12px] font-[400] px-[12px]">
              <div className="flex items-center gap-x-[10px]">
                <input
                  type="radio"
                  name="status"
                  id="public"
                  className="checked:bg-primary focus:bg-primary"
                  value={EXAM_VENUE_STATUS.PUBLIC}
                  checked={
                    Number(examVenue.status) === EXAM_VENUE_STATUS.PUBLIC
                  }
                  onChange={handleChange}
                />
                <label htmlFor="public">公開</label>
              </div>
              <div className="flex items-center gap-x-[10px]">
                <input
                  type="radio"
                  name="status"
                  id="private"
                  className="checked:bg-primary focus:bg-primary"
                  value={EXAM_VENUE_STATUS.PRIVATE}
                  checked={
                    Number(examVenue.status) === EXAM_VENUE_STATUS.PRIVATE
                  }
                  onChange={handleChange}
                />
                <label htmlFor="private">非公開</label>
              </div>
            </div>
          </InputItem>
          <InputItem label="エリア">
            <select
              name="region"
              className="w-full rounded-[5px] px-[10px] text-[12px] h-[30px] !py-0"
              onChange={handleChange}
              // defaultValue={''}
              value={examVenue.region}
            >
              <option hidden value=""></option>
              {regions.map((region) => (
                <option value={region.name} key={region.id}>
                  {region.name}
                </option>
              ))}
            </select>
          </InputItem>
          <InputItem label="都道府県">
            <select
              name="prefecture"
              className="w-full rounded-[5px] px-[10px] text-[12px] h-[30px] !py-0"
              onChange={handleChange}
              value={examVenue.prefecture}
            >
              <option hidden value=""></option>
              {prefectures.map((prefecture, index) => (
                <option value={prefecture} key={++index}>
                  {prefecture}
                </option>
              ))}
            </select>
          </InputItem>
          <InputItem label="郵便番号" required={false}>
            <div className="flex items-center gap-x-[5px]">
              <input
                type="number"
                className="w-[80px] h-[30px] text-[13px] text-center"
                name="first"
                value={postalCode.first}
                onChange={handleChangePostalCode}
                onKeyDown={(e) => removeECharacter(e)}
              />
              <div className="w-[12px] h-[1px] bg-secondary-light"></div>
              <input
                type="number"
                name="last"
                className="w-[80px] h-[30px] text-[13px] text-center"
                value={postalCode.last}
                onChange={handleChangePostalCode}
                onKeyDown={(e) => removeECharacter(e)}
                ref={PostalCodeLast}
              />
              <button
                className="text-white bg-secondary-light px-[5px] py-[6px] text-[9px] rounded-[5px] ml-[5px] font-medium"
                onClick={handleSearchByPostalCode}
                disabled={isSyncingPostal}
              >
                自動入力
              </button>
            </div>
          </InputItem>
          <InputItem label="住所" required={false} className="h-[160px]">
            <input
              type="text"
              name="prefecture"
              className="my-[5px] h-[30px]"
              placeholder="都道府県を入力"
              value={address.prefecture}
              onChange={handleChangeAddress}
            />
            <input
              type="text"
              name="city"
              className="my-[5px] h-[30px]"
              placeholder="例）港区"
              value={address.city}
              onChange={handleChangeAddress}
            />
            <input
              type="text"
              name="area"
              className="my-[5px] h-[30px]"
              placeholder="例）赤坂1-2-34"
              value={address.area}
              onChange={handleChangeAddress}
            />
            <input
              type="text"
              name="other"
              className="my-[5px] h-[30px]"
              placeholder="例)3階"
              value={address.other}
              onChange={handleChangeAddress}
            />
          </InputItem>
          <InputItem label="地図埋め込む" required={false}>
            <input
              type="text"
              name="map_embed"
              onChange={handleChange}
              value={examVenue.map_embed}
              className="h-[30px]"
            />
          </InputItem>
        </FieldItem>
      </div>
      <div>
        <div className="mb-[10px] font-medium leading-none">注意文</div>
        <FieldItem>
          <InputItem label="注意文" required={false} className="!items-start">
            <textarea
              className="text-[13px]"
              name="exam_venue_info"
              cols={30}
              rows={10}
              onChange={handleChange}
            >
              {examVenue.exam_venue_info}
            </textarea>
          </InputItem>
        </FieldItem>
      </div>
      <div className="flex items-center justify-center gap-x-[20px] mt-[20px]">
        <SubmitButton
          label="一覧画面へ戻る"
          className="bg-secondary-light !rounded-[10px]"
          onSubmit={() => navigate(PATH_ADMIN_EXAM_VENUE.VENUE_LIST)}
        ></SubmitButton>
        <SubmitButton
          label="入力内容を登録"
          className="bg-primary-light !rounded-[10px]"
          onSubmit={handleSubmit}
        ></SubmitButton>
      </div>
    </div>
  );
};

export default CreateExamVenue;
