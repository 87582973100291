const SERVICE_FEATURE = "tokite";

const BREADCRUMB_SEPARATOR = "　〉　";

const WEEKDAYS = ["月", "火", "水", "木", "金", "土", "日"];

const WEEKDAYS_ORDER = ["日", "月", "火", "水", "木", "金", "土"];

const BASE_LOCAL = "ja";

const DIRECTION = {
  UP: "up",
  DOWN: "down",
  RIGHT: "right",
  LEFT: "left",
  CENTER: "center",
} as const;

const STATUS = {
  ACTIVE: "有効",
  INACTIVE: "無効",
};

const PUBLIC_STATUS = {
  PUBLIC: "公開",
  PRIVATE: "非公開",
};

const STATUS_DATA = [
  { value: 1, label: STATUS.ACTIVE },
  { value: 0, label: STATUS.INACTIVE },
];

const PUBLIC_STATUS_DATA = [
  { value: 1, label: PUBLIC_STATUS.PUBLIC },
  { value: 0, label: PUBLIC_STATUS.PRIVATE },
];

const STEP = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};

const DECISION = {
  YES: 1,
  NO: 0,
};

const BASIC_PASSPHRASE = "passphrase";

const TYPE_DATE_FORMAT = {
  REGULAR: 1,
  YEAR_MONTH: 2,
  REGULAR_GLOBAL: 3,
  TIME_HOUR_MINUTE: 4,
  TIME_HOUR_MINUTE_24H: 5,
  MONTH_DAY: 6,
  SHORT_DEFAULT: 7,
  FULL_TO_MINUTE_DEFAULT: 8,
  REGULAR_GLOBAL_FULL: 9,
  FULL_TO_MINUTE: 10,
};

const DISPLAY_TAILWIND = {
  HIDDEN: "hidden",
  SHOW: "",
};

const ROLE_TOKITE = {
  ROLE_TEACHER: 2,
  ROLE_STUDENT: 1,
};

const URL_AWS = "https://manabite-staging.s3.amazonaws.com";
const PAYMENT_TYPE = {
  CARD: "card",
  KONBINI: "konbini",
};

const SERVICE_ID = 1;

const PREFIX_PATH_S3 = {
  FEATURE: "feature_",
  OZ: "organization_",
};

const DOM_NODE_TYPE = {
  ELEMENT: 1,
  ATTRIBUTE: 2,
  TEXT: 3,
};

const TAX = 1.1;

const CHECK_ANSWER_STATUS_TYPE = {
  UNKNOWN: 0,
  CORRECT: 1,
  INCORRECT: 2
}

const PICTURE_TYPE = ["image/jpeg", "image/png"];

export {
  BREADCRUMB_SEPARATOR,
  WEEKDAYS,
  BASE_LOCAL,
  DIRECTION,
  STATUS_DATA,
  STEP,
  PUBLIC_STATUS_DATA,
  DECISION,
  BASIC_PASSPHRASE,
  TYPE_DATE_FORMAT,
  DISPLAY_TAILWIND,
  SERVICE_FEATURE,
  ROLE_TOKITE,
  WEEKDAYS_ORDER,
  URL_AWS,
  PAYMENT_TYPE,
  SERVICE_ID,
  PREFIX_PATH_S3,
  TAX,
  DOM_NODE_TYPE,
  CHECK_ANSWER_STATUS_TYPE,
  PICTURE_TYPE,
};
