import React from "react";

const PurpleBlock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="192"
      viewBox="0 0 90 192"
      fill="none"
    >
      <path
        opacity="0.8"
        d="M0 192H192V0H95.9781C43.0413 0 0 43.0413 0 95.9781C0 97.5544 0.0437856 99.0869 0.131357 100.619C0.131357 101.364 0.131357 102.108 0 102.809V191.956V192Z"
        fill="#8D7DB4"
      />
    </svg>
  );
};

export default PurpleBlock;
