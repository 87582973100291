import { ReactNode } from 'react';

type DetailFormFieldProps = {
  label?: string;
  children: ReactNode;
};

const DetailFormField = ({ label, children }: DetailFormFieldProps) => {
  return (
    <div className="flex items-center h-0 min-h-[40px] border-b border-success-extralight gap-[7px]">
      <div className="flex items-center h-full w-full max-w-[240px] bg-success-lighter text-[14px] font-[500] leading-[100%] text-secondary-dark pl-[10px]">
        {label}
      </div>
      <div className="w-full text-[14px] leading-[20px] text-secondary-dark">{children}</div>
    </div>
  );
};

export default DetailFormField;
