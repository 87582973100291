import { WritingProblem } from '../../../../../../../types/admin/question';
import { RenderDroppedQuestionTypeProps } from '../../DroppedQuestionType';
import { decodeHTMLEntities } from '../../../../../../../services/helpers/parseData';
import AuthoringTextEditor from '../../AuthoringTextEditor';

type WritingQuestionProps = {
  problemPreview?: WritingProblem;
} & Omit<RenderDroppedQuestionTypeProps, 'draggedName' | 'problemPreview'>;

const WritingQuestion = ({ problemPreview }: WritingQuestionProps) => {
  return (
    <div className={`w-[100%] h-fit flex-col items-center gap-y-[20px] mx-auto`}>
      <div className="">
        <div
          dangerouslySetInnerHTML={{
            __html: decodeHTMLEntities(problemPreview?.title || ''),
          }}
        ></div>
      </div>
      <div className={`flex items-center justify-center my-[10px] w-full`}>
        <div className={`flex items-center justify-center my-[10px] w-[75%]`}>
          <AuthoringTextEditor
            className={'w-full h-[105px]'}
            toolbarId={`writing_preview_${problemPreview?.id}`}
            isReadOnly={true}
            value={problemPreview?.answerValue}
          />
        </div>
      </div>
    </div>
  );
};
export default WritingQuestion;
