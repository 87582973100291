import { useEffect, useState } from "react";
import { ADMIN_SIDEBAR_DATA } from "../../services/constants/admin/sidebar";
import SidebarItem from "./SidebarItem";
import { useLocation } from "react-router-dom";
import Hamburger from "../../components/commons/icons/Sidebar/Hamburger";
import { useSidebarContext } from "../../context/SidebarContext";
import classNames from "classnames";

const Sidebar = () => {
  const location = useLocation();
  const storedOpenItem = localStorage.getItem("openItem");
  const [openItem, setOpenItem] = useState<number | null>(
    storedOpenItem ? Number(storedOpenItem) : null
  );
  const { showSidebar, setShowSidebar } = useSidebarContext();

  useEffect(() => {
    localStorage.setItem("openItem", openItem?.toString() || "");
  }, [openItem]);

  const handleItemClick = (index: number) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };
  
  return (
    <section
      className={
        classNames("text-secondary-light h-full transition-all bg-[#FBFDFC] w-full", {
          "max-w-[60px]": showSidebar,
          "max-w-[250px]": !showSidebar
        })
      }
    >
      <div className="h-0 min-h-[38px] flex justify-end items-center cursor-pointer">
        <Hamburger
          onClick={() => setShowSidebar(!showSidebar)}
          isOpen={!showSidebar}
        />
      </div>
      <div className="whitespace-nowrap">

        {ADMIN_SIDEBAR_DATA.map((data, index) => (
          <SidebarItem
            data={data}
            key={index}
            currentPath={location.pathname}
            isOpen={index === openItem}
            onItemClick={() => {
              handleItemClick(index);
              setShowSidebar(false);
            }}
            isMinimize={!!showSidebar}
          />
        ))}
      </div>
      {/* Sidebar item hidden for later phases */}
      {/* <div className="bg-secondary-lesslight text-primary-light text-[11px] font-[500] leading-[100%] tracking-[0.03em] min-h-[40px] pl-[15px] border-t border-secondary-medium flex items-center cursor-pointer">
        追加機能設定
      </div> */}
    </section>
  );
};

export default Sidebar;
