import React, { ChangeEvent, useEffect } from "react";
import { HiOutlineUserCircle } from "react-icons/hi";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { useCandidateContext } from "../../../context/CandidateContext";
import Pagination from "../commons/Pagination";
import { Candidate } from "../../../types/admin/candidate";
import { Link, useNavigate } from "react-router-dom";
import { CANDIDATE_STATUS_DATA } from "../../../services/constants/admin/pages/candidate";
import dayjs from "dayjs";
import { swalMessage } from "../../../services/helpers/swal";

const CandidateTable = ({
  handlePageChange,
  currentPage,
  _candidates,
  toggleSelect,
  selectAll,
  toggleSelectAll,
  isCandidateSelected,
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  _candidates: Candidate[];
  toggleSelect: (id: number) => void;
  selectAll: boolean;
  toggleSelectAll: () => void;
  isCandidateSelected: (id: number) => boolean;
}) => {
  const navigate = useNavigate();
  const { totalPages } = useCandidateContext();

  const formatLastLoginTime = (lastLoginTime?: string) => {
    if (!lastLoginTime) return "";
    return dayjs(lastLoginTime).format("YYYY/MM/DD HH:mm");
  };

  const redirectDetailCandidate = (id: number) => {
    const candidate = _candidates.find((candidate) => candidate.id === id);
    if (!candidate) return;

    if (!candidate.status) {
      swalMessage(
        "メール確認待機中です！",
        "メール確認が完了するまで編集できません。",
        "warning"
      );
      return;
    }

    navigate(`./details/${candidate.id}`);
  };
  return (
    <>
      <div className="relative overflow-x-auto animate-[show_0.5s_ease-in-out]">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <th
                scope="col"
                className="px-[10px] py-[2px] border-x-4 border-white whitespace-nowrap "
              >
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                />
              </th>
              <th
                scope="col"
                className="2xl:px-[25px] py-[5px] xl:px-[5px] lg:px-0 px-0 border-x-4 border-white whitespace-nowrap"
              ></th>
              <th
                scope="col"
                className="2xl:px-[110px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                氏名
              </th>
              <th
                scope="col"
                className="2xl:px-[110px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                メールアドレス
              </th>
              <th
                scope="col"
                className="2xl:px-[130px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                ログインID
              </th>
              <th
                scope="col"
                className="2xl:px-[43px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                ステータス
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                最終ログイン
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              ></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(_candidates) &&
              _candidates &&
              !!_candidates.length &&
              _candidates.map((candidate) => (
                <tr
                  key={candidate.id}
                  className="bg-white border-b w-full text-[12px] font-[400] text-[#2E2E2E]"
                >
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={isCandidateSelected(candidate.id)}
                      onChange={() => toggleSelect(candidate.id)}
                    />
                  </td>
                  <td className="">
                    {candidate.avatar_url === null ? (
                      <div className="flex justify-center items-center">
                        <HiOutlineUserCircle color="#BEBEBE" size={40} />
                      </div>
                    ) : (
                      <div className="flex justify-center items-center">
                        <img
                          className="object-cover w-[40px] h-[40px]"
                          src={candidate.avatar_url}
                          width={40}
                          alt={candidate?.first_name}
                        />
                      </div>
                    )}
                  </td>

                  <th className="px-6 py-4 text-[14px] font-[500] text-primary whitespace-nowrap">
                    <Link to={`./details/${candidate.id}`}>
                      {`${candidate?.first_name || ""} ${
                        candidate?.last_name || ""
                      }`}
                    </Link>
                  </th>
                  <th className="px-6 py-4 text-[14px] font-[500] whitespace-nowrap">
                    {candidate?.email || ""}
                  </th>
                  <td className="px-12 py-4 whitespace-nowrap">
                    {candidate.username}
                  </td>
                  <td className="py-4 text-center m-auto">
                    {candidate.status === CANDIDATE_STATUS_DATA.CONFIRMING && ( // button for isActive=0
                      <div className="min-w-[110px] px-[2px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <RiErrorWarningLine
                          className="absolute top-[2px] left-[8px]"
                          size={18}
                        />
                        <p className="pl-[12px] cursor-default">確認中</p>
                      </div>
                    )}
                    {candidate.status === CANDIDATE_STATUS_DATA.ACTIVE && ( // button for isActive=1
                      <div className="flex justify-center">
                        <div className="max-w-[50px] max-h-[25px] px-[6px] pb-[5px] text-danger border border-danger rounded-[5px] whitespace-nowrap cursor-default">
                          有効
                        </div>
                      </div>
                    )}
                    {candidate.status === CANDIDATE_STATUS_DATA.DEACTIVE && ( // button for isActive=2
                      <div className="min-w-[100px] px-[2px] pt-[3px] pb-[5px] text-[#7A7A7A] bg-neutral-100 rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <IoMdRemoveCircleOutline
                          className="absolute top-[2px] left-[4px]"
                          size={18}
                        />
                        <p className="pl-[20px]">停止中</p>
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {formatLastLoginTime(candidate.last_login_at)}
                  </td>
                  <td className="text-center">
                    <button
                      className="min-w-[50px] text-[14px] px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-primary text-white"
                      onClick={() => redirectDetailCandidate(candidate.id)}
                    >
                      編集
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {totalPages && totalPages !== 0 && (
        <Pagination
          totalItems={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default CandidateTable;
