import * as React from "react";

const Brusher = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M16.6693 8.33464V3.0013C16.6693 2.53459 16.6693 2.30124 16.5784 2.12298C16.4985 1.96617 16.3711 1.83869 16.2143 1.7588C16.036 1.66797 15.8026 1.66797 15.3359 1.66797H4.66927C4.20256 1.66797 3.96921 1.66797 3.79095 1.7588C3.63414 1.83869 3.50666 1.96617 3.42677 2.12298C3.33594 2.30124 3.33594 2.53459 3.33594 3.0013V8.33464M16.6693 8.33464H3.33594M16.6693 8.33464V8.5013C16.6693 9.90143 16.6693 10.6015 16.3968 11.1363C16.1571 11.6067 15.7747 11.9891 15.3042 12.2288C14.7695 12.5013 14.0694 12.5013 12.6693 12.5013H7.33594C5.93581 12.5013 5.23574 12.5013 4.70096 12.2288C4.23056 11.9891 3.8481 11.6067 3.60842 11.1363C3.33594 10.6015 3.33594 9.90143 3.33594 8.5013V8.33464M12.0859 12.5013V16.2513C12.0859 17.4019 11.1532 18.3346 10.0026 18.3346C8.85201 18.3346 7.91927 17.4019 7.91927 16.2513V12.5013"
      stroke={props.color || "#2E2E2E"}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Brusher;
