import React from "react";
import { useListExamContext } from "../../../../context/Exam/ListExamContext";
import {
  NAME_ACTION_ASIDE_TEST,
  NAME_ACTION_DETAIL_EXAM,
} from "../../../../services/constants/admin/pages/exam";
import DetailAction from "./DetailAction";
import DetailForm from "./DetailForm";
import DetailTargetFinder from "./DetailTargetFinder";
import { useNavigate } from "react-router-dom";
import { PATH_ADMIN_EXAM } from "../../../../services/constants/route/router";
import DetailPreview from "./DetailPreview";

const DetailQuestion = () => {
  const { selectedExam, sideAction, setSelectedExam, detailAcionName } =
    useListExamContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (detailAcionName === NAME_ACTION_DETAIL_EXAM.AUTHORING) {
      return navigate(
        `${PATH_ADMIN_EXAM.AUTHORING}?exam_id=${selectedExam.id}`
      );
    }
  }, [detailAcionName]);

  return (
    <>
      <div className="w-full h-0 min-h-[897px] bg-white rounded-[2px] px-[20px] py-[40px]">
        {[NAME_ACTION_ASIDE_TEST.COPY, NAME_ACTION_ASIDE_TEST.MOVE].includes(
          sideAction
        ) ? (
          <>
            <DetailTargetFinder sideAction={sideAction} />
          </>
        ) : (
          <>
            {selectedExam.id ? (
              <>
                {selectedExam.is_file ? <DetailAction /> : <></>}
                {detailAcionName === NAME_ACTION_DETAIL_EXAM.EDIT ? <DetailForm exam={selectedExam} /> : ""}
                {detailAcionName === NAME_ACTION_DETAIL_EXAM.PREVIEW ? <DetailPreview /> : ""}
              </>
            ) : (
              <div className=""></div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DetailQuestion;
