import { ExamItemWithStatus } from "../../../types/admin/exam";
import { ChoiceProblemTest, FillInBlankProblemTest, MappingProblemTest, MatrixProblemTest, OrderProblemTest, QuestionProblemTest, SliderProblemTest } from "../../../types/admin/question";
import { CandidateExamSectionTest } from "../../../types/candidate/exam";
import { NAME_TYPE_QUESTION } from "../../constants/admin/pages/question";
import { isEmptyObj } from "../../helpers/etc";

const currentQuestionNumber = (
  examPartQuestions: ExamItemWithStatus[],
  examSectionId: number,
  examItemId: number
): string => {
  const findIndexCurrentQuestion = examPartQuestions.findIndex(
    (examPartQuestion) =>
      examPartQuestion.id === examItemId &&
      examPartQuestion.exam_part_id === examSectionId
  );

  if (findIndexCurrentQuestion >= 0) {
    const numberCurrentQuestion = findIndexCurrentQuestion + 1;
    return numberCurrentQuestion < 10
      ? `0${numberCurrentQuestion}`
      : `${numberCurrentQuestion}`;
  }

  return "00";
};

const isPropertyExists = (property: string, properties: string[]) => {
  return properties.includes(property);
};

const candidateToolIsOpen = (toolName: string, openingTools: string[]): boolean => {
  return openingTools.includes(toolName);
};

type ResponseCheckAnswer = {
  totalScore: number;
  isCorrect: boolean;
}

const handleCheckCorrectAnswer = (question: QuestionProblemTest): ResponseCheckAnswer => {
  switch (question.name) {
    case NAME_TYPE_QUESTION.CHOICE:
      return handleCheckCorrectChoiceQuestion(question as ChoiceProblemTest);
    case NAME_TYPE_QUESTION.ORDER:
      return handleCheckCorrectOrderQuestion(question as OrderProblemTest);
    case NAME_TYPE_QUESTION.MAPPING:
      return handleCheckCorrectMappingQuestion(question as MappingProblemTest);
    case NAME_TYPE_QUESTION.MATRIX:
      return handleCheckCorrectMatrixQuestion(question as MatrixProblemTest)
    case NAME_TYPE_QUESTION.SLIDER:
      return handleCheckCorrectSliderQuestion(question as SliderProblemTest)
    case NAME_TYPE_QUESTION.FILL_IN_BLANK:
      return handleCheckCorrectFillInBlankQuestion(question as FillInBlankProblemTest)
    default:
      return { totalScore: 0, isCorrect: false };
  }
}

const handleCheckCorrectChoiceQuestion = (choiceQuestion: ChoiceProblemTest): ResponseCheckAnswer => {
  const answerData = choiceQuestion?.answer_data || [];
  const issues = choiceQuestion?.issues || [];
  let isCorrect: boolean = true;
  let totalScore: number = 0;

  issues.forEach((issue) => {
    const findAnswerIssue = answerData.find((answer) => answer.id === issue.id);
    if (!findAnswerIssue) return;

    if (findAnswerIssue.checked && issue.checked) {
      totalScore += Number(issue.point || 0);
    }

    if ((findAnswerIssue.checked && !issue.checked) || (!findAnswerIssue.checked && issue.checked)) {
      isCorrect = false;
    }
  });

  return {
    totalScore: totalScore,
    isCorrect: isCorrect
  }
}

const handleCheckCorrectOrderQuestion = (orderQuestion: OrderProblemTest): ResponseCheckAnswer => {
  const answerData = orderQuestion?.answer_data || [];
  const correctIssues = orderQuestion?.correct_issues || [];
  let totalScore: number = 0;
  let isCorrect: boolean = true;

  correctIssues.forEach((correctIssue, index) => {
    const findAnswer = answerData[index];

    if (findAnswer && correctIssue.checked && correctIssue.id === findAnswer.id) {
      totalScore += Number(correctIssue.point || 0);
    } else {
      isCorrect = false;
    }
  })

  return {
    totalScore: totalScore,
    isCorrect: isCorrect
  };
}

const handleCheckCorrectMappingQuestion = (mappingQuestion: MappingProblemTest): ResponseCheckAnswer => {
  const answerData = mappingQuestion?.answer_data || [];
  const correctPairs = mappingQuestion?.correct_pairs || [];
  let totalScore = 0;
  let isCorrect: boolean = true;

  correctPairs.forEach((correctPair) => {
    if(!Object.keys(correctPair).length || !correctPair.id) {
      return
    };

    const findCorrectAnswer = answerData.find((answer) => {
      if (correctPair.left === answer.left && correctPair.right === answer.right) {
        return answer;
      }

      if (correctPair.right === answer.left && correctPair.left === answer.right) {
        return answer
      }
    });

    if (findCorrectAnswer) {
      totalScore += Number(correctPair.point || 0);
    } else {
      isCorrect = false;
    }
  })

  return {
    totalScore: totalScore,
    isCorrect: isCorrect
  };
}

const handleCheckCorrectMatrixQuestion = (matrixQuestion: MatrixProblemTest): ResponseCheckAnswer => {
  const answerData = matrixQuestion?.answer_data || [];
  const correctCoordinates = matrixQuestion?.correct_coordinates || [];
  let totalScore: number = 0;
  let isCorrect: boolean = true;

  correctCoordinates.forEach((correctCoordinate, index) => {
    const findAnswer = answerData.find((answer) => correctCoordinate.id === answer.id);
    if (!findAnswer) return;

    if (correctCoordinate.checked === findAnswer.checked) {
      totalScore += Number(correctCoordinate?.point || 0);
    }

    if (findAnswer.checked && !correctCoordinate.checked || !findAnswer.checked && correctCoordinate.checked) {
      isCorrect = false;
    }
  })

  return {
    totalScore: totalScore,
    isCorrect: isCorrect
  };
}

const handleCheckCorrectSliderQuestion = (sliderQuestion: SliderProblemTest): ResponseCheckAnswer => {
  const answerData = sliderQuestion.answer_data;
  const issues = sliderQuestion?.issues || [];
  let totalScore = 0;
  let isCorrect: boolean = true;

  if (isEmptyObj(answerData) || !answerData) {
    return {
      totalScore: totalScore,
      isCorrect: false
    };
  }

  issues.forEach((issue) => {
    if (issue.answerValue === answerData.answerValue) {
      totalScore += Number(issue.point || 0);
    } else {
      isCorrect = false;
    }
  });

  return {
    totalScore: totalScore,
    isCorrect: isCorrect
  };
}

const handleCheckCorrectFillInBlankQuestion = (fillInBlankQuestion: FillInBlankProblemTest): ResponseCheckAnswer => {
  const answerData = fillInBlankQuestion?.answer_data || [];
  const correctFills = fillInBlankQuestion?.correct_fills || [];
  let totalScore = 0;
  let isCorrect: boolean = true;

  correctFills.forEach((correctFill) => {
    const findAnswer = answerData.find((answer) => answer.id === correctFill.id);

    if (!findAnswer) return;

    if (correctFill.issueId === findAnswer.issueId) {
      totalScore += Number(correctFill?.point || 0);
    } else {
      isCorrect = false;
    }
  })

  return {
    totalScore: totalScore,
    isCorrect: isCorrect
  };
}

const handleCheckAnswerExamSection = (examSections: CandidateExamSectionTest[]) => {
  let totalScoreExamPart = 0;
  let totalAnswerCorrect = 0;

  const newExamSections = examSections.map((examSection) => {
    const examItems = examSection?.exam_items || [];

    const newExamItems = examItems.map((examItem) => {
      const problems = examItem.question_data?.problems || [];

      const newProblems = problems?.map((problem, index) => {
        const handleScore = handleCheckCorrectAnswer(problem);
        totalScoreExamPart += handleScore.totalScore;
        if (handleScore.isCorrect) {
          totalAnswerCorrect += 1;
        }

        return { ...problem, score: handleScore.totalScore }
      });

      return { ...examItem, question_data: { problems: newProblems } }
    });

    return { ...examSection, exam_items: newExamItems }
  });

  return { totalScore: totalScoreExamPart, examSections: newExamSections, totalAnswerCorrect: totalAnswerCorrect }
}

export { currentQuestionNumber, isPropertyExists, candidateToolIsOpen, handleCheckCorrectAnswer, handleCheckAnswerExamSection };
