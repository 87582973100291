import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { ExamName } from "../../../../../types/admin/examName";
import { LuClock3 } from "react-icons/lu";
import Trash from "../../../../commons/icons/Trash";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";
import dayjs from "dayjs";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import {
  TYPE_DATE_FORMAT,
  WEEKDAYS_ORDER,
} from "../../../../../services/constants/globals";
import { ExamVenueSelected } from "../../../../../types/admin/examination";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../assests/styles/scheduleCalendar.css";
import ja from "date-fns/locale/ja";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { formatDateTime } from "../../../../../services/helpers/formatTime";

registerLocale("ja", ja);

type SelectExamVenueProps = {
  date: Date | null;
  examVenues: ExamName[];
  examVenueIndex: number;
  examVenueSelected: ExamVenueSelected;
  setDate: Dispatch<SetStateAction<Date | null>>;
  handleDeleteExamVenue: (index: number) => void;
  handleSelectExamVanue: (
    e: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => void;
  handleChooseDateTime: (datetime: Date | null, index: number) => void;
  handleDeleteExamVenueTime: (
    examVenueId: number,
    dateIndex: number,
    timeIndex: number | undefined
  ) => void;
};

const SelectExamVenue = ({
  date,
  examVenues,
  examVenueIndex,
  examVenueSelected,
  setDate,
  handleDeleteExamVenue,
  handleSelectExamVanue,
  handleChooseDateTime,
  handleDeleteExamVenueTime,
}: SelectExamVenueProps) => {
  const [showDate, setShowDate] = useState<boolean>(false);

  return (
    <div className="flex flex-col gap-y-[10px] w-full mb-[10px]">
      <div className="flex items-start gap-x-[10px]">
        <div className="flex flex-col gap-y-[5px] w-[60%]">
          <select
            name=""
            className="w-full h-[30px] rounded-[5px] text-[12px] pl-[10px] py-0 pr-[30px]"
            onChange={(e) => handleSelectExamVanue(e, examVenueIndex)}
            value={examVenueSelected.exam_venue_id}
          >
            {examVenueSelected.exam_venue_id ? (
              <></>
            ) : (
              <option value=""></option>
            )}

            {examVenues.map((examVenue) => (
              <option value={examVenue.id} key={examVenue.id}>
                {examVenue.name}
              </option>
            ))}
          </select>
        </div>
        <button
          className="bg-primary-light px-[6px] py-[5px] rounded-[5px] flex items-center gap-x-[5px]"
          onClick={() => setShowDate(!showDate)}
        >
          <LuClock3 size={12} color="#FFFFFF" />
          <span className="text-[12px] text-white text-center font-[500]">
            日時を追加
          </span>
        </button>
        {!!examVenueIndex && (
          <Trash
            onClick={() => handleDeleteExamVenue(examVenueIndex)}
            className="cursor-pointer"
          />
        )}
      </div>
      <div className="flex-1 flex justify-center">
        {showDate && (
          <div className="w-[298px] bg-white shadow-xl py-[10px] px-[5px] my-[10px]">
            <div className="flex items-center justify-between text-secondary">
              <TbChevronLeft
                onClick={() => setDate(dayjs(date).subtract(1, "M").toDate())}
                className="cursor-pointer"
              />
              <div className="font-[500] text-[14px] leading-[150%] text-secondary-dark">
                {dayjs(date).format("YYYY年M月")}
              </div>
              <TbChevronRight
                onClick={() => setDate(dayjs(date).add(1, "M").toDate())}
                className="cursor-pointer"
              />
            </div>
            <ReactDatePicker
              renderCustomHeader={() => <></>}
              calendarClassName="!border-none !w-auto !bg-white"
              selected={date}
              onChange={setDate}
              inline
              calendarStartDay={0}
              locale={"ja"}
              fixedHeight
              disabled
              showTimeInput={true}
            />
            <div className="flex justify-end items-center gap-x-[10px]">
              <button
                className="bg-[#F5F7FA] rounded-[4px] text-[14px] px-[8px] py-[4px] text-[#494E50]"
                onClick={() => setShowDate(false)}
              >
                閉じる
              </button>
              <button
                className="bg-primary-light text-white text-[14px] rounded-[4px] px-[8px] py-[4px]"
                onClick={() => {
                  handleChooseDateTime(date, examVenueIndex);
                  setShowDate(false);
                }}
              >
                追加
              </button>
            </div>
          </div>
        )}
      </div>
      {examVenueSelected?.time && examVenueSelected.time?.length ? (
        <div
          className={`flex gap-x-[10px] max-w-[642px] min-h-[114px] ${
            examVenueSelected.time?.length > 7 ? "overflow-x-scroll" : ""
          }`}
        >
          {examVenueSelected.time &&
            examVenueSelected.time?.length &&
            examVenueSelected.time?.map((examVenueSelectedTime, dateIndex) => (
              <div key={dateIndex}>
                <div
                  className={classNames(
                    `w-[83px] h-[64px] py-[20px] px-[8px] p relative flex flex-col items-center justify-center bg-white shadow-inner text-[16px] font-medium leading-[150%]`,
                    {
                      "!bg-[#AAE2F7]": examVenueSelectedTime.day === 6,
                      "!bg-[rgba(250,100,106,0.20)]":
                        examVenueSelectedTime.day === 0,
                    }
                  )}
                >
                  <IoIosRemoveCircleOutline
                    size={16}
                    className="absolute top-[-8px] right-[-8px] cursor-pointer"
                    onClick={() =>
                      handleDeleteExamVenueTime(
                        examVenueSelected.exam_venue_id || 0,
                        dateIndex,
                        undefined
                      )
                    }
                  />
                  <span>{examVenueSelectedTime.date}</span>
                  <span>
                    ({WEEKDAYS_ORDER[examVenueSelectedTime?.day || 0]})
                  </span>
                </div>
                {examVenueSelectedTime.time?.map((time, timeIndex) => (
                  <div
                    className="relative w-[83px] h-[50px] px-[20px] py-[15px] bg-secondary-extralight text-center text-[#777676] mt-[2px]"
                    key={timeIndex}
                  >
                    <IoIosRemoveCircleOutline
                      size={16}
                      className="absolute top-[-6px] right-[-8px] cursor-pointer"
                      onClick={() =>
                        handleDeleteExamVenueTime(
                          examVenueSelected.exam_venue_id || 0,
                          dateIndex,
                          timeIndex
                        )
                      }
                    />
                    {formatDateTime(
                      time,
                      TYPE_DATE_FORMAT.TIME_HOUR_MINUTE_24H
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SelectExamVenue;
