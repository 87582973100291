import React from "react";
import InformationDetail from "./InformationDetail";
import { isSelected } from "../../../../../../services/utils/admin/exam";

type CheckboxPropertiesProps = {
  selectedValues: string[];
  arrCheckbox: {
    title: string;
    name: string;
    detail: string;
  }[];
  handleChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxProperties = ({
  selectedValues,
  arrCheckbox,
  handleChangeValue,
}: CheckboxPropertiesProps) => {
  return (
    <div className={`w-full flex-col gap-[10px] p-[20px] bg-[#F4F4F4] `}>
      {arrCheckbox.map((item) => {
        return (
          <div
            key={item.name}
            className="flex pb-[15px] justify-between items-center gap-[15px]"
          >
            <div className="flex justify-start items-center gap-[8px]">
              <input
                id={item.name}
                name={item.name}
                type="checkbox"
                className="input_check_red checked:ring-transparent focus:ring-transparent cursor-pointer w-[24px] h-[24px] rounded-[5px]"
                onChange={handleChangeValue}
                checked={isSelected(selectedValues || [], item.name)}
              />
              <label htmlFor={item.name} className="text-[14px] cursor-pointer">
                {item.title}
              </label>
            </div>
            <InformationDetail text={item.detail} />
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxProperties;
