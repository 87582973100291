import React, { ChangeEvent } from "react";
import { PiArrowFatDown, PiGitCommit } from "react-icons/pi";
import {
  isEmptyObj,
  isUsableArr,
} from "../../../../../../services/helpers/etc";
import {
  MappingIssue,
  MappingPair,
  MappingProblem,
  QuestionProblem,
} from "../../../../../../types/admin/question";
import { decodeHTMLEntities } from "../../../../../../services/helpers/parseData";
import { handleProblemScoreCalculator } from "../../../../Question/AuthoringQuestion/QuestionEditorHandler";

const MappingQuestion = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled,
}: {
  questionProblem: QuestionProblem;
  handleUpdateProblem: (problem: QuestionProblem) => void;
  isDisabled: boolean;
}) => {
  const mappingQuestion: MappingProblem = questionProblem || {};
  const mappingIssues: MappingIssue[] = mappingQuestion.issues || [];
  const mappingCorrectPairs: MappingPair[] =
    mappingQuestion.correct_pairs || [];

  const getMappingIssueById = (issueId: number) => {
    return mappingIssues.find((issue) => issue.id === issueId) ?? {};
  };

  const handleChangePoint = (
    e: ChangeEvent<HTMLInputElement>,
    issueId: number
  ) => {
    const point = Number(e.target.value);
    if (isNaN(point)) return;

    const mappingQuestion = {
      ...questionProblem,
      correct_pairs: mappingCorrectPairs.map((correctIssue) => {
        if (correctIssue.id === issueId) {
          return { ...correctIssue, point: point };
        }

        return correctIssue;
      }),
    };
    const handleScore = handleProblemScoreCalculator(mappingQuestion);

    handleUpdateProblem({...mappingQuestion, total_score: handleScore});
  };

  return (
    <div className="justify-center items-center flex flex-col gap-[28px] text-[13px]">
      <div className="border-[2px] border-[#E5E5E5] h-full px-[10px] py-[20px] w-[90%]">
        <div className="px-0 h-full flex flex-wrap">
          {isUsableArr(mappingIssues) &&
            mappingIssues &&
            mappingIssues.map((issue, index) => (
              <div
                className={`mb-[15px] flex items-center gap-[10px] px-[10px] w-[190px]`}
                key={index}
              >
                <div className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="pointer-events-none bg-secondary-light rounded-full w-[20px] h-[20px]"
                    id=""
                    disabled
                  />
                </div>
                <label
                  className="w-full"
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(issue.label),
                  }}
                ></label>
              </div>
            ))}
        </div>
      </div>
      <div className="text-[#EBE4D8]">
        <PiArrowFatDown size={50} />
      </div>
      <div className="border-[2px] border-[#E5E5E5] h-full px-[15px] py-[20px] flex flex-wrap gap-[10px] items-center w-[90%] justify-start">
        {isUsableArr(mappingCorrectPairs) &&
          mappingCorrectPairs
            ?.filter(
              (pair) => !isEmptyObj(pair) && Object.keys(pair).length > 2
            )
            .map((pair, index) => (
              <div
                className="flex items-center justify-center gap-[15px] w-full"
                key={index}
              >
                <div className="flex justify-start gap-[10px] items-center w-[190px]">
                  <div
                    className={`w-full border border-dashed border-secondary-light min-h-[50px] cursor-default`}
                  >
                    {pair.left ? (
                      <div className="flex items-center justify-center py-[10px]">
                        <div
                          className="w-[67%] text-center"
                          dangerouslySetInnerHTML={{
                            __html: decodeHTMLEntities(
                              getMappingIssueById(pair.left)?.label || ""
                            ),
                          }}
                        ></div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <PiGitCommit size={26} />
                <div className="flex justify-start gap-[10px] items-center w-[190px]">
                  <div
                    className={`w-full border border-dashed border-secondary-light min-h-[50px] cursor-default`}
                  >
                    {pair.right ? (
                      <div className="w-full flex items-center justify-center py-[10px]">
                        <div
                          className="w-[67%] text-center"
                          dangerouslySetInnerHTML={{
                            __html: decodeHTMLEntities(
                              getMappingIssueById(pair.right)?.label || ""
                            ),
                          }}
                        ></div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="">
                  <input
                    type="text"
                    name="mapping"
                    className="w-[45px] h-[40px] text-[14px] !border-secondary-light"
                    placeholder="0"
                    onChange={(e) => handleChangePoint(e, pair.id || 0)}
                    value={pair.point || ""}
                    disabled={isDisabled}
                  />
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default MappingQuestion;
