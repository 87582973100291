import {
  IoIosAddCircleOutline,
  IoIosArrowDown,
  IoIosArrowForward,
} from "react-icons/io";
import ArrowDown from "../../../../commons/icons/Arrow/ArrowDown";
import { BiTrash } from "react-icons/bi";
import { PropsWithChildren, useState } from "react";
import { useAuthoringExamContext } from "../../../../../context/Exam/AuthoringExamContext";
import { UPDATE_ORDER } from "../../../../../services/constants/admin/pages/exam";
import { ExamSectionType } from "../../../../../types/admin/examAuthoring";

type SectionExamProps = {
  examSection: ExamSectionType;
  totalExamSection: number;
  maxOrder: number;
  isChooseSetting: boolean;
  handleAddExamItem: () => void;
  handleDelete: () => void;
  handleUpdateOrderSection: (orderType: number) => void;
  handleChooseSettingProperty: () => void;
} & PropsWithChildren;

const SectionExam = ({
  totalExamSection,
  examSection,
  maxOrder,
  isChooseSetting,
  handleDelete,
  handleAddExamItem,
  handleUpdateOrderSection,
  handleChooseSettingProperty,
  children,
}: SectionExamProps) => {
  const { id, name, order, total_score } = examSection;
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { selectedItems } = useAuthoringExamContext();

  return (
    <>
      <div className={`flex-col w-full mt-[10px]`}>
        <div
          className={`${
            isChooseSetting ? "bg-primary-light " : "bg-white rounded-[5px]"
          } flex items-center h-[50px] mt-[5px] gap-[3px] border-[#BEBEBE] border-[1px] rounded-[5px]`}
        >
          <div className="text-[13px] h-[30px] flex justify-start items-center basis-[60%] px-[10px]">
            {isOpen ? (
              <IoIosArrowForward
                size={24}
                className={`${
                  isChooseSetting ? "text-white" : "text-[#7A7A7A]"
                } -rotate-90 cursor-pointer`}
                onClick={() => setIsOpen(!isOpen)}
              />
            ) : (
              <IoIosArrowDown
                size={24}
                className={` ${
                  isChooseSetting ? "text-white" : "text-[#7A7A7A]"
                } cursor-pointer`}
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
            <div
              onClick={handleChooseSettingProperty}
              className={` ${
                isChooseSetting ? "text-white" : ""
              } text-[13px] px-[10px] w-full h-full flex items-center cursor-pointer`}
            >
              {name}
            </div>
          </div>

          <div className="text-[13px] h-[30px] flex justify-center items-center gap-x-[30px] basis-[26%] px-[10px] gap-[10px] ml-[-9px]">
            <div className="border-[1px] border-[#BEBEBE] h-[24px] w-[30px] bg-[white] text-[14px] flex justify-end items-center p-[5px] rounded-[2px] ml-[2px]">
              {order}
            </div>
            <ArrowDown
              className={`rotate-180 ${order === 1 ? "" : "cursor-pointer"}`}
              color={
                order === 1
                  ? "#BEBEBE"
                  : "" || isChooseSetting
                  ? "white"
                  : "#7A7A7A"
              }
              onClick={() => {
                if ((order || 0) > 1) {
                  handleUpdateOrderSection(UPDATE_ORDER.UP);
                }
              }}
            />
            <ArrowDown
              className={`${order === maxOrder ? "" : "cursor-pointer"}`}
              color={
                order === maxOrder
                  ? "#BEBEBE"
                  : "" || isChooseSetting
                  ? "white"
                  : "#7A7A7A"
              }
              onClick={() => {
                if ((order || 0) < totalExamSection) {
                  handleUpdateOrderSection(UPDATE_ORDER.DOWN);
                }
              }}
            />
          </div>
          <div className="text-[13px] h-[30px] flex justify-center items-center basis-[15%] px-[10px] ml-[0px] border-box ">
            <BiTrash
              size={24}
              color={isChooseSetting ? "white" : "#7A7A7A"}
              className={`cursor-pointer`}
              onClick={handleDelete}
            />
          </div>
          <div className="text-[16px] h-[30px] flex justify-center items-center basis-[15%] px-[10px] ml-[0px] border-box ">
            {total_score ?? 0}
          </div>
        </div>
        {isOpen ? (
          <div className="w-full flex items-center border-l-[2px] border-primary bg-secondary-extralight pb-[20px] px-[10px]">
            <div className="bg-white w-[10px]"></div>
            <div className="w-full mt-[20px] ">
              {children}
              {selectedItems.length > 0 && (
                <div className="mt-[5px]">
                  <button
                    className="w-full h-[50px] bg-primary-extralight bg-opacity-25 px-[10px] py-[7px] rounded-[5px] flex justify-start items-center text-[13px] text-primary gap-[10px] outline-dashed outline-[1px]"
                    onClick={handleAddExamItem}
                  >
                    <IoIosAddCircleOutline size={24} color="#75A1D3" />
                    選択した問題アイテムを追加
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SectionExam;
