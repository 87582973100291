import React from "react";
import RegisterExamBase from "./Index";
import RegisterExamination from "../../../components/candidate/RegisterExamination/Index";

const RegisterExamPage = () => {
  return (
    <RegisterExamBase>
      <RegisterExamination />
    </RegisterExamBase>
  );
};

export default RegisterExamPage;
