import { Controls } from "react-voice-visualizer/dist/types/types";
import Microphone from "../../../../../../../commons/icons/Microphone";
import RecordButton from "./RecordButton";
import StretchVertical from "../../../../../../../commons/icons/StretchVertical";
import Triangle from "../../../../../../../commons/icons/Triangle";
import { formattedMinutesFromMilliseconds } from "../../../../../../../../services/helpers/formatTime";

type UploaderButtonProps = {
  recording: boolean;
  pause: boolean;
  color: string;
  recorderControls: Controls;
  togglePauseResume: () => void;
  stopRecording: () => void;
  resetRecord: () => void;
};

const UploaderButton = ({
  recording,
  pause,
  color,
  recorderControls,
  togglePauseResume,
  stopRecording,
  resetRecord,
}: UploaderButtonProps) => {
  return (
    <div className="flex flex-col justify-center gap-y-[15px]">
      {recording ? (
        pause ? (
          <RecordButton
            icon={<Microphone width={24} height={24} color={color} />}
            label="続き"
            className={`border-danger text-danger`}
            handleClick={() => togglePauseResume()}
          />
        ) : (
          <RecordButton
            icon={<StretchVertical width={24} height={24} color={color} />}
            label="録音中"
            className={`border-danger text-danger`}
            handleClick={() => togglePauseResume()}
          />
        )
      ) : pause ? (
        <RecordButton
          icon={
            <Triangle
              width={24}
              height={24}
              color={color}
              className="p-[3px]"
            />
          }
          label={formattedMinutesFromMilliseconds(
            recorderControls.currentAudioTime * 1000
          )}
          className={`border-primary-darker text-primary-darker`}
          handleClick={() => togglePauseResume()}
        />
      ) : (
        <RecordButton
          icon={<StretchVertical width={24} height={24} color={color} />}
          label="録音中"
          className={`border-primary-darker text-primary-darker`}
          handleClick={() => togglePauseResume()}
        />
      )}

      <RecordButton
        style={{ borderColor: color, color: color }}
        icon={
          <div
            style={{
              backgroundColor: color,
            }}
            className={`w-[20px] h-[20px] rounded-[5px]`}
          ></div>
        }
        label="終了"
        handleClick={() => {
          recording ? stopRecording() : resetRecord();
        }}
      />
    </div>
  );
};

export default UploaderButton;
