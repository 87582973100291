/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, ChangeEvent, useCallback, useRef, SyntheticEvent } from "react";
import { MediaToolProblem } from "../../../../../../../types/admin/question";
import { RenderDroppedQuestionTypeEditProps } from "../../DroppedQuestionType";
import { LiaSave } from "react-icons/lia";
import { PiTrash } from "react-icons/pi";
import {
  displayStatusEditorHandle,
  getFileTypeRestricted,
} from "../../../../../../../services/utils/admin/question";
import { useAuthoringQuestionContext } from "../../../../../../../context/Question/AuthoringQuestionContext";
import {
  swalConfirm,
  swalSuccess,
} from "../../../../../../../services/helpers/swal";
import { BiEditAlt } from "react-icons/bi";
import MediaToolQuestion from "./MediaToolQuestion";
import BaseModal from "../../../../../commons/BaseModal";
import UploadQuestionImage from "../../../../../commons/UploadQuestionImage";
import { NAME_TYPE_QUESTION } from "../../../../../../../services/constants/admin/pages/question";
import { Resource } from "../../../../../../../types/admin/resource";
import { isEmptyObj } from "../../../../../../../services/helpers/etc";
import { SetStateAction } from "../../../../../../../types/globals";
import classNames from "classnames";

type MediaToolQuestionEditProps = {
  problemEdit?: MediaToolProblem;
  isOpenModalUploadFile?: boolean;
  setIsOpenModalUploadFile?: SetStateAction<boolean>;
} & Omit<RenderDroppedQuestionTypeEditProps, "problemEdit">;

const MediaToolQuestionEdit = ({
  draggedName,
  problemEdit,
  isOpenModalUploadFile,
  setIsOpenModalUploadFile,
}: MediaToolQuestionEditProps) => {
  const {
    editingProblemStatuses,
    authoringData,
    setEditingProblemStatuses,
    setSelectedProblem,
    setAuthoringData,
    setIsEditingProblem
  } = useAuthoringQuestionContext();
  const [editingData, setEditingData] = useState<MediaToolProblem>(
    problemEdit || {}
  );
  const [resourceLink, setResourceLink] = useState<string>("");
  const [resource, setResource] = useState<Resource>({});
  const [isOpenModal, setIsOpenModal] = useState<boolean>(
    isOpenModalUploadFile || false
  );
  const [mediaData, setMediaData] = useState<MediaToolProblem>(
    editingData || {}
  );
  const [dimensionType, setDimensionType] = useState<string>("");
  const [imageDefaultSize, setImageDefaultSize] = useState<{
    width: number;
    height: number;
  }>({ width: 1, height: 1 });
  let fileTypeRestricted: number = getFileTypeRestricted(draggedName);

  const tabOptionChangeHandle = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e || !e.currentTarget) return;
    const { name, type, value } = e.currentTarget;

    let parsedValue: number | string = value;

    if (name === "media_width") {
      const parseValue = Number(parsedValue);
      const imageRatioWidth = Number((imageDefaultSize.height / imageDefaultSize.width).toFixed(2));
      let data: object = {};

      if (dimensionType !== "width") {
        data = { [name]: parseValue };
      }

      if (dimensionType !== "height") {
        data = {
          ...data,
          media_height: Math.round(parseValue * imageRatioWidth),
        };
      }

      setEditingData({
        ...editingData,
        ...data,
      });
      return;
    }

    if (name === "media_height") {
      const parseValue = Number(parsedValue);
      const imageRatioWidth = Number((imageDefaultSize.width / imageDefaultSize.height).toFixed(2));
      let data: object = {};

      if (dimensionType !== "height") {
        data = { [name]: parseValue };
      }

      if (dimensionType !== "width") {
        data = { ...data, media_width: Math.round(parseValue * imageRatioWidth) };
      }

      setEditingData({
        ...editingData,
        ...data,
      });
      return;
    }

    if (type === "number") {
      parsedValue = Number(value);
    }

    setEditingData({
      ...editingData,
      [name]: parsedValue,
    });
  };

  const submitEditingDataHandle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsEditingProblem(false);

    const finalEditingData = {
      ...editingData,
      updated_at: new Date().getTime(),
    };

    const problemIndexed =
      authoringData.problems?.findIndex(
        (problem) => problem.id === finalEditingData.id
      ) || 0;

    let newProblems: MediaToolProblem[] = authoringData.problems || [];
    newProblems.splice(problemIndexed, 1, finalEditingData);

    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      "hide",
      Number(editingData.id),
      editingProblemStatuses
    );
    setEditingProblemStatuses(newEditingProblemStatuses);

    setAuthoringData({ problems: newProblems });
    swalSuccess();
    setSelectedProblem();
  };

  const hideEditorHandle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsEditingProblem(false);
    // reset editing status
    const newEditingProblemStatuses = displayStatusEditorHandle(
      "hide",
      Number(editingData.id),
      editingProblemStatuses
    );
    setEditingProblemStatuses(newEditingProblemStatuses);
    setSelectedProblem();
  };

  const removeHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    swalConfirm(() => {
      setIsEditingProblem(false);
      setAuthoringData({
        ...authoringData,
        problems: authoringData.problems?.filter(
          (problem) => Number(problem.id) !== Number(editingData.id)
        ),
      });
    });
  };

  const handleFixedImage = (type: string) => {
    setDimensionType(dimensionType === type ? "" : type);
  };

  const handleOnloadImage = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const img = e.target as HTMLImageElement;
    const { offsetHeight, offsetWidth } = img;
    
    setEditingData((editingData) => ({
      ...editingData,
      media_width: offsetWidth,
      media_height: offsetHeight,
    }));
    setImageDefaultSize({ width: offsetWidth, height: offsetHeight });
  };

  useEffect(() => {
    if (isEmptyObj(resource)) return;

    if (!resource.origin_filename) return;
    const splitted = resource.origin_filename.split(".")[0];
    // const mediaExtension = splitted[splitted.length - 1];

    setEditingData({
      ...editingData,
      media_name: splitted,
      media_url: resource.resource_link,
      media_extension: resource.content_type || "",
      media_width: 0,
      media_height: 0,
    });
  }, [resource]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMediaData(editingData);
    }, 400);

    return () => clearTimeout(timer);
  }, [editingData]);

  useEffect(() => {
    if (!isOpenModal && setIsOpenModalUploadFile) {
      setIsOpenModalUploadFile(isOpenModal);
    }
  }, [isOpenModal]);

  const changeMediaHandle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    setIsOpenModal(true);
  };

  return (
    <>
      <form>
        <div className="mb-[10px] bg-secondary-extralight min-h-[780px] w-full p-[20px] relative">
          <div className="mb-[30px]"></div>
          <div className="flex gap-[13px]">
            <div className="w-[80%]">
              <div className="">
                <div className="text-[14px] font-[500] leading-[100%] text-white p-[10px] w-full bg-primary h-0 min-h-[34px] flex items-center rounded-t-[10px]">
                  設問内容
                </div>
                <div className="bg-white pt-[10px] rounded-b-[10px]">
                  <div className="p-[10px] overflow-x-auto">
                    <MediaToolQuestion
                      draggedName={draggedName}
                      problemPreview={mediaData}
                      className="min-h-[300px] bg-success-lighter rounded-[5px]"
                      actionable
                      handleOnloadImage={handleOnloadImage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[20%] flex flex-col gap-y-[15px]">
              {draggedName === NAME_TYPE_QUESTION.IMAGE && (
                <div className="flex flex-col">
                  <div className="p-[10px] text-[14px] font-[500] leading-[100%] text-white w-full bg-primary rounded-t-[10px] h-0 min-h-[34px] flex items-center">
                    設定
                  </div>
                  <div className="bg-white min-h-[100px] px-[10px]">
                    <div className="my-[10px] mx-[5px]">
                      <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                        ファイル名
                      </div>
                      <div className="flex items-center text-[12px]">
                        <input
                          type="text"
                          name="media_name"
                          placeholder="Name"
                          className="text-[14px] max-w-[115px] h-[32px]"
                          onChange={tabOptionChangeHandle}
                          value={editingData?.media_name || ""}
                          disabled={true}
                        />

                        <span className="text-secondary ml-[5px]">
                          {editingData.media_extension
                            ? `.${editingData.media_extension}`
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className="mb-[25px] mt-[10px] mx-[5px]">
                      <div className="text-[14px] font-[500] leading-[100%] mb-[10px]">
                        サイズ
                      </div>
                      <div className="choice_limit flex items-center justify-between mb-[5px] text-[12px]">
                        <div className="flex items-center">
                          <div className="font-[400] leading-[100%] mr-[5px]">
                            縦
                          </div>
                          <input
                            type="number"
                            name="media_height"
                            placeholder="0"
                            className={classNames(
                              "w-[50px] h-[32px] !pr-0 !text-[13px] px-[5px] placeholder:text-center",
                              {
                                "bg-secondary-extralight":
                                  dimensionType === "height",
                              }
                            )}
                            onChange={tabOptionChangeHandle}
                            value={editingData?.media_height || ""}
                            disabled={dimensionType === "height"}
                          />
                          <span className="text-secondary ml-[5px]">px</span>
                        </div>
                        <div className="flex items-center gap-x-[3px] text-[10px]">
                          <input
                            type="radio"
                            name="fixed"
                            id="dimension_height"
                            onClick={() => handleFixedImage("height")}
                            checked={dimensionType === "height"}
                            className="cursor-pointer"
                          />
                          <label htmlFor="dimension_height" className="cursor-pointer">固定</label>
                        </div>
                      </div>
                      <div className="choice_limit flex items-center justify-between text-[12px]">
                        <div className="flex items-center">
                          <div className="font-[400] leading-[100%] mr-[5px]">
                            横
                          </div>
                          <input
                            type="number"
                            name="media_width"
                            placeholder="0"
                            className={classNames(
                              "w-[50px] h-[32px] !pr-0 !text-[13px] px-[5px] placeholder:text-center",
                              {
                                "bg-secondary-extralight":
                                  dimensionType === "width",
                              }
                            )}
                            onChange={tabOptionChangeHandle}
                            value={editingData?.media_width || ""}
                            disabled={dimensionType === "width"}
                          />
                          <span className="text-secondary ml-[5px]">px</span>
                        </div>
                        <div className="flex items-center gap-x-[3px] text-[10px]">
                          <input
                            type="radio"
                            name="fixed"
                            id="dimenstion_width"
                            onClick={() => handleFixedImage("width")}
                            checked={dimensionType === "width"}
                            className="cursor-pointer"
                          />
                          <label htmlFor="dimenstion_width" className="cursor-pointer">固定</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="text-white">
                <div className="flex justify-center w-full mb-[20px]">
                  <button
                    className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] gap-[5px] bg-danger rounded-[10px] border border-secondary-light"
                    onClick={submitEditingDataHandle}
                  >
                    <LiaSave size={23} /> 保存
                  </button>
                </div>

                {draggedName !==
                  NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD && (
                  <div className="flex justify-center w-full mb-[20px]">
                    <button
                      className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] gap-[5px] bg-white rounded-[10px] border border-danger text-danger"
                      onClick={changeMediaHandle}
                    >
                      <BiEditAlt size={23} /> 変更
                    </button>
                  </div>
                )}

                <div className="flex justify-center w-full mb-[20px]">
                  <button
                    className="cursor-pointer flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                    onClick={hideEditorHandle}
                  >
                    キャンセル
                  </button>
                </div>

                <div className="flex justify-center w-full mb-[20px]">
                  <button
                    className="cursor-pointer gap-[5px] flex items-center justify-center w-[70%] h-0 min-h-[36px] bg-secondary-light rounded-[10px] border border-secondary-light"
                    onClick={removeHandle}
                  >
                    <PiTrash size={20} />
                    削除
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {isOpenModal && (
        <BaseModal
          setIsOpen={setIsOpenModal}
          width={1010}
          height={718}
          justifyDirection="end"
        >
          <UploadQuestionImage
            fileType={fileTypeRestricted}
            setIsOpen={setIsOpenModal}
            setResourceLink={setResourceLink}
            setResource={setResource}
          />
        </BaseModal>
      )}
    </>
  );
};

export default MediaToolQuestionEdit;
