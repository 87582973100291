import DetailsInfo from "../../../../../commons/icons/Exam/DetailsInfor";

const InformationDetail = ({ text }: { text?: string }) => {
   return (
      <>
         <div className="relative group w-[20px]">
            <div className=" hover:cursor-pointer">
               <DetailsInfo color="#2E2E2E" />
            </div>
            <div className="hidden group-hover:block absolute bottom-4 right-[0]">
               <div className="
                  text-sm opacity-0 group-hover:opacity-100  transition-opacity duration-300 bg-white break-all
                  min-h-[50px] min-w-[200px] px-[10px] py-[7px] rounded-[5px] text-[8px] border-[2px] border-primary text-center
                  "
               >
                  {text}
               </div>
               <div className="w-16 overflow-hidden mb-[10px] ml-[150px]">
                  <div className="h-[9px] w-[9px] bg-primary -rotate-45 transform origin-top-left ml-[45px]"></div>
               </div>
            </div>

         </div>
      </>
   )
}

export default InformationDetail;