import { QuestionProblem } from "../../../../../../types/admin/question";
import { NAME_TYPE_QUESTION } from "../../../../../../services/constants/admin/pages/question";
import ChoiceQuestion from "../QuestionTypes/ChoiceQuestion";
import OrderQuestion from "../QuestionTypes/OrderQuestion";
import MappingQuestion from "../QuestionTypes/MappingQuestion";
import MatrixQuestion from "../QuestionTypes/MatrixQuestion";
import FillInBlankQuestion from "../QuestionTypes/FillInBlankQuestion";
import SliderQuestion from "../QuestionTypes/SliderQuestion";
import WritingQuestion from "../QuestionTypes/WritingQuestion";
import RecordVoiceQuestion from "../QuestionTypes/RecordVoiceQuestion";
import UploadQuestion from "../QuestionTypes/UploadQuestion";
import MediaToolQuestion from "../QuestionTypes/MediaToolQuestion";

type ModalEditQuestionTypeProps = {
  questionProblem: QuestionProblem;
  handleUpdateProblem: (problem: QuestionProblem) => void;
  isDisabled?: boolean;
};

const ModalEditQuestionType = ({
  questionProblem,
  handleUpdateProblem,
  isDisabled = false
}: ModalEditQuestionTypeProps) => {
  const questionType = questionProblem.name;

  switch (questionType) {
    case NAME_TYPE_QUESTION.CHOICE:
      return (
        <ChoiceQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.ORDER:
      return (
        <OrderQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.MAPPING:
      return (
        <MappingQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.MATRIX:
      return (
        <MatrixQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.FILL_IN_BLANK:
      return (
        <FillInBlankQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          disabledSubmit={true}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.SLIDER:
      return (
        <SliderQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.WRITTEN_QUESTION:
      return (
        <WritingQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD_RECORD:
      return (
        <RecordVoiceQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.TEST_TAKER_UPLOAD:
      return (
        <UploadQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
          isDisabled={isDisabled}
        />
      );
    case NAME_TYPE_QUESTION.AUDIO:
    case NAME_TYPE_QUESTION.IMAGE:
    case NAME_TYPE_QUESTION.VIDEO:
      return (
        <MediaToolQuestion
          questionProblem={questionProblem}
          handleUpdateProblem={handleUpdateProblem}
        />
      );
    default:
      return <></>;
  }
};

export default ModalEditQuestionType;
